import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Valued} from "../../../../data/va/Valued";
import {ValueOption, ValueTypeEnum} from "../../../../data/va/Extractor";
import {VaAttribute} from "../../../../data/va/Attribute";
import {VaTag} from "../../../../data/va/Tag";
import {VAProcedure} from "../../../../data/va/Script";
import {InfoTypeEnum} from "../model/dialog-context.model";
import {ConfirmInfoType, ExtractedInfoSource, ExtractedValue, ExtractedValueType} from "../model/extracted-value.model";
import {DialogCorrectionDTO, DialogCorrectionTypeEnum} from "../model/correction.model";
import {ReplyView, ScriptCall} from "../model/reply.model";
import {CorrectionMenuBottomSheetComponent} from "../correction-menu/correction-menu-bottom-sheet.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {ReplyExtractedValueCorrectionBottomSheetComponent} from "./reply-extracted-value-correction-bottom-sheet.component";
import * as cloneDeep from "lodash/cloneDeep";
import * as moment from 'moment';

@Component({
    selector: 'reply-extracted-value',
    template: require('./reply-extracted-value.component.html'),
    styles: [require('./reply-extracted-value.component.less'), require('./../correction.less')]
})
export class ReplyExtractedValueComponent implements OnInit {

    @Input()
    extractedValue: ExtractedValue;

    @Input()
    private reply: ReplyView;

    @Input()
    correction: DialogCorrectionDTO;

    @Input()
    private type: ExtractedValueType;

    @Input()
    private entity: VaAttribute | VaTag | VAProcedure;

    @Input()
    nodeId: string;

    @Input()
    isEditable: boolean;

    @Input()
    mode: Mode;

    @Input()
    access: boolean;

    @Output()
    private onCorrectionDelete: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    private onCorrectionSave: EventEmitter<DialogCorrectionDTO> = new EventEmitter<DialogCorrectionDTO>();

    /**
     * Вызов скрипта, извлекший это значение
     */
    scriptCall: ScriptCall | undefined;

    constructor(private bottomSheet: MatBottomSheet) {
    }

    ngOnInit(): void {
        if (this.extractedValue?.callId) {
            this.scriptCall = this.reply.reply.scriptCalls?.find(call => call.id == this.extractedValue.callId);
        }
    }

    isCorrectionAllowed(): boolean {
        return this.reply.correctionAllowed &&
            this.isEditable && this.access && [Mode.CREATE, Mode.CORRECT, Mode.MISSED].indexOf(this.mode) >= 0 &&
            // В просмотре диалога у атрибута, который был явно задан в сценарии, и для атрибута, извлеченного скриптом,
            // не должна быть возможность выбрать "неверное значение атрибута" или "Лишнее извлечение атрибута"
            (!this.extractedValue ||
                [ExtractedInfoSource.SET_BY_SCENARIO.name, ExtractedInfoSource.USER_THEN_FUNCTION.name, ExtractedInfoSource.HANDLER_FUNCTION, ExtractedInfoSource.USER_SPEC_FORM]
                    .indexOf(this.extractedValue.source?.name) < 0);
    }

    openCorrectionMenu(): void {
        if (!this.isCorrectionAllowed()) {
            return;
        }
        let bottomSheetRef;
        if (this.correction) {
            // если уже есть корректировка, то редактируем ее, а не открываем меню
            bottomSheetRef = this.bottomSheet.open(ReplyExtractedValueCorrectionBottomSheetComponent, {
                data: {
                    reply: cloneDeep(this.reply),
                    extractedValue: cloneDeep(this.extractedValue),
                    correction: cloneDeep(this.correction)
                },
            });
        } else {
            bottomSheetRef = this.bottomSheet.open(CorrectionMenuBottomSheetComponent, {
                data: {
                    reply: cloneDeep(this.reply),
                    extractedValue: cloneDeep(this.extractedValue),
                    correction: cloneDeep(this.correction),
                    correctionTypes: [DialogCorrectionTypeEnum.WRONG_EXTRACTION, DialogCorrectionTypeEnum.EXCESS_EXTRACTION],
                    onCorrectionSave: this.onCorrectionSave,
                },
            });
        }
        bottomSheetRef.afterDismissed().subscribe((result: DialogCorrectionDTO) => {
                if (result) {
                    this.onCorrectionSave.emit(result);
                }
            }
        );
    }

    /**
     * Берем тип из извлечения или корректировки или передан напрямую
     */
    getType(): ExtractedValueType {
        if (this.extractedValue) {
            return this.extractedValue.type;
        }
        if (this.correction?.extractionAttempt) {
            return this.correction.extractionAttempt.type;
        }
        if (this.type) {
            return this.type;
        }
        throw new Error("Type is undefined");
    }

    /**
     * Берем сущность из извлечения или корректировки или передан напрямую
     */
    getEntity(): VaAttribute | VaTag | VAProcedure {
        if (this.extractedValue) {
            return this.extractedValue.entity;
        }
        if (this.correction?.extractionAttempt) {
            if (this.correction.extractionAttempt.type === ExtractedValueType.ATTRIBUTE) {
                return this.correction.extractionAttempt.attribute;
            }
            if (this.correction.extractionAttempt.type === ExtractedValueType.CONFIRMATION) {
                return this.correction.extractionAttempt.confirmInfoType.name === ConfirmInfoType.TAG.name ? this.correction.extractionAttempt.tag : this.correction.extractionAttempt.attribute;
            }
            throw new Error("Unsupported correction attempt type");
        }
        if (this.entity) {
            return this.entity;
        }
        throw new Error("Entity is undefined");
    }

    /**
     * Берем тип подтверждения из извлечения или корректировки
     */
    getConfirmInfoType(): InfoTypeEnum {
        if (this.extractedValue) {
            return this.extractedValue.confirmInfoType?.name;
        }
        if (this.correction?.extractionAttempt) {
            if (!this.correction.extractionAttempt.confirmInfoType) {
                return null;
            }
            return this.correction.extractionAttempt.confirmInfoType.name === ConfirmInfoType.TAG.name ? InfoTypeEnum.TAG_CONFIRMATION : InfoTypeEnum.ATTRIBUTE_CONFIRMATION;
        }
        throw new Error("Confirm info type is undefined");
    }

    /**
     * Берем значение извлечения из извлечения или корректировки
     */
    getValue(): string {
        if (this.extractedValue) {
            return this.extractedValue.value;
        }
        if (this.correction?.extractionAttempt) {
            return this.correction.extractionAttempt.strValue;
        }
        throw new Error("Value is undefined");
    }

    /**
     * Берем значение извлечения из извлечения или корректировки
     */
    getValueOption(): ValueOption {
        if (this.extractedValue) {
            if (this.extractedValue.valueOption) {
                return this.extractedValue.valueOption;
            } else {
                const valueOption = new ValueOption();
                valueOption.title = this.extractedValue.value;
                return valueOption;
            }
        }
        if (this.correction?.extractionAttempt) {
            return this.correction.extractionAttempt.value;
        }
        throw new Error("Value option is undefined");
    }

    /**
     * Отображение извлечения файла
     */
    getFileName(): string {
        if (this.extractedValue) {
            const fileName = this.reply.reply.attachments?.find(attachment => attachment.url == this.extractedValue.value)?.fileName;
            return fileName ? fileName : this.extractedValue.value;
        }
        throw new Error("Value is undefined");
    }

    /**
     * Заголовок для атрибутов
     */
    getAttributeTitle(): string {
        switch (this.mode) {
            case Mode.RESET:
                return "Сброшен атрибут";
            case Mode.MISSED:
                return "Пропущен атрибут";
            case Mode.CORRECT:
                // в случае корректировки атрибута с экстрактором типа "Дата" или "Адрес" текст другой
                return this.isDateCorrection()
                    ? "Некорректное извлечение даты"
                    : this.isAddressCorrection()
                        ? "Некорректное извлечение адреса"
                        : "Корректный атрибут";
            case Mode.CREATE:
                if (!this.extractedValue) {
                    throw new Error("Get attribute title, mode = create, extracted value is empty");
                }
                switch (this.extractedValue.source.name) {
                    case ExtractedInfoSource.USER_FREE_FORM.name:
                        return "Извлечён атрибут";
                    case  ExtractedInfoSource.USER_SPEC_FORM.name:
                        return "Выбран атрибут";
                    case  ExtractedInfoSource.EXTERNAL_FUNCTION.name:
                        return "API атрибут";
                    case  ExtractedInfoSource.HANDLER_FUNCTION.name:
                        return `Скриптом${this.scriptCall ? ' ' + this.scriptCall.scriptName: ''} извлечён атрибут`;
                    case  ExtractedInfoSource.USER_THEN_FUNCTION.name:
                        return `Скриптом${this.scriptCall ? ' ' + this.scriptCall.scriptName: ''} из реплики извлечён атрибут`;
                    case  ExtractedInfoSource.SET_BY_SCENARIO.name:
                        return "Задано значение атрибута";
                    default:
                        return "Появился атрибут"
                }
            default:
                throw new Error("Unsupported extracted attribute mode")
        }
    }

    /**
     * Текущая корректировка = корректировка даты?
     */
    isDateCorrection() {
        const attempt = this.correction?.extractionAttempt;
        return attempt?.extractor?.valueType.name == ValueTypeEnum.DATE;
    }

    /**
     * Текущая корректировка = корректировка адреса?
     */
    isAddressCorrection() {
        const attempt = this.correction?.extractionAttempt;
        return attempt?.extractor?.valueType.name == ValueTypeEnum.ADDRESS;
    }

    /**
     * Заголовок для процедур
     */
    getProcedureTitle(): string {
        switch (this.mode) {
            case Mode.RESET:
                return "Сброшено значение процедуры";
            case Mode.CREATE:
                return "Выход из процедуры";
            case Mode.ENTER:
                return "Вход в процедуру";
            default:
                throw new Error("Unsupported extracted procedure mode")
        }
    }

    /**
     * Заголовок для подтверждений
     */
    getConfirmationTitle(): string {
        let action;
        switch (this.mode) {
            case Mode.RESET:
                action = "Сброшено значение ";
                break;
            case Mode.MISSED:
                action = "Пропущено значение ";
                break;
            case Mode.CORRECT:
                action = "Корректное значение ";
                break;
            case Mode.CREATE:
                action = "Получено значение ";
                break;
            default:
                throw new Error("Unexpected extracted confirmation mode")
        }
        let userAction = this.getValue() === Valued.YES ? "подтверждения " : "отрицания ";
        let entity;
        switch (this.getConfirmInfoType()) {
            case InfoTypeEnum.ATTRIBUTE_CONFIRMATION:
                entity = "значения атрибута";
                break;
            case InfoTypeEnum.TAG_CONFIRMATION:
                entity = "выбора тематики";
                break;
            default:
                throw new Error(`Unsupported confirmInfoType: ${this.getConfirmInfoType()}`);
        }
        return action + userAction + entity;
    }

    /**
     * Клик на крестик у корректировки
     */
    onCorrectionDeleteClick() {
        this.onCorrectionDelete.emit(this.correction.correction.key.id);
    }

    /**
     * Тип экстрактора у атрибута
     * @param attribute атрибут
     */
    getValueType(attribute: any) {
        return (attribute as VaAttribute).extractor.valueType.name;
    }

    /**
     * Форматирование отображения даты
     */
    formatDate() {
        return moment(this.getValue(), "YYYY-MM-DD,HH:mm:ss.SSS")
            .format("DD.MM.YYYY HH:mm");
    }

    /**
     * Отображать ссылку на сущность?
     */
    displayEntityLink(): boolean {
        const entity: VaAttribute | VaTag | VAProcedure = this.getEntity();
        return entity != null && !this.hideCorrectionInfo() && !entity.deleted && (entity as VaAttribute).apiKey !== 'channel';
    }

    /**
     * Отображать название сущности?
     */
    displayEntityName(): boolean {
        const entity: VaAttribute | VaTag | VAProcedure = this.getEntity();
        return entity != null && !this.hideCorrectionInfo() && (entity.deleted || (entity as VaAttribute).apiKey === 'channel');
    }

    /**
     * Спрятать информацию о корректировке
     */
    hideCorrectionInfo(): boolean {
        const isDate = this.getValueType(this.getEntity()) == ValueTypeEnum.DATE;
        const isAddress = this.getValueType(this.getEntity()) == ValueTypeEnum.ADDRESS;
        return (isDate || isAddress) && this.mode == Mode.CORRECT;
    }
}

export enum Mode {
    CREATE = 'CREATE',
    RESET = 'RESET',
    MISSED = 'MISSED',
    CORRECT = 'CORRECT',
    ENTER = 'ENTER'
}



