function TrendDetectionFilterCheckboxDirective() {
    return {
        restrict: "EA",
        bindToController: true,
        scope: {
            title: "@",
            model : "=",
        },
        templateUrl: "/pages/workplace/va/common/directives/filterCheckbox.html",
        controller: function () {
            const that = this;
            that.toggle = function () {
                that.model = !that.model;
            }
        },
        controllerAs: "checkbox",
    };
}

export default TrendDetectionFilterCheckboxDirective;