import {Reply, ReplyView, TagState} from "./model/reply.model";
import {ExtractedInfoSource} from "./model/extracted-value.model";

/**
 * Возможна ли корректировка "Пропущена группа"
 */
export function isMissedTagAllowed(reply: ReplyView): boolean {
    if (reply.missedTagCorrection) {
        // корректировка уже есть
        return false;
    }
    if (isTagClassified(reply.reply)) {
        // группа уже есть, будет плашка под репликой
        return false;
    }
    // не ответ кнопкой
    return !reply.reply.extractedValues.some(value => value.source.name == ExtractedInfoSource.USER_SPEC_FORM.name);
}

/**
 * Есть ли в реплике классификация группы
 */
export function isTagClassified(reply: Reply): boolean {
    return reply.classifiedTagId && (reply.tagState == TagState.SURE || reply.tagState == TagState.CONFIRMED)
        || (reply.userAct != null && reply.userAct.smallTalkTagId != null);
}