module.exports = [
    {
        state: "settings",
        options: {
            url: "/settings",
            parent: "authorized"
        }
    },
    // правила блокировки
    {
        state: "settings.antifraudrules",
        options: {
            title: "Правила блокировки",
            url: "/antifraudrules",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/settings/antifraudrules/rules.html",
                    controller: "AntifraudRulesController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                details: "settings.antifraudrules.edit"
            }
        }
    },
    {
        state: "settings.antifraudrules.edit",
        options: {
            title: "Правила блокировки | ",
            url: "/:ruleId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@settings.antifraudrules": {
                    templateUrl: "/pages/workplace/settings/antifraudrules/ruleEdit.html",
                    controller: "AntifraudRuleEditController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                meta: {
                    parameters: ["ruleId"],
                    sectionFunction: function ([ruleId]) {
                        return "Общие настройки";
                    },
                    subSectionFunction: function ([ruleId]) {
                        return ruleId ? "Редактирование правила блокировки" : "Создание нового правила блокировки";
                    }
                }
            }
        }
    },
    // блокировка пользователей
    {
        state: "settings.antifraudblockusers",
        options: {
            title: "Заблокированные пользователи",
            url: "/antifraudblockusers",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/settings/antifraudblockusers/blockUsers.html",
                    controller: "AntifraudBlockUsersController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                details: "settings.antifraudblockusers.edit"
            }
        }
    },
    {
        state: "settings.antifraudblockusers.edit",
        options: {
            title: "Заблокированные пользователи | ",
            url: "/:ip",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@settings.antifraudblockusers": {
                    templateUrl: "/pages/workplace/settings/antifraudblockusers/blockUserEdit.html",
                    controller: "AntifraudBlockUserEditController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                meta: {
                    parameters: ["ip"],
                    sectionFunction: function ([ip]) {
                        return "Общие настройки";
                    },
                    subSectionFunction: function ([ip]) {
                        return "Заблокированный пользователь";
                    }
                }
            }
        }
    },
    {
        state: "settings.security",
        options: {
            title: "Доступ к интерфейсу",
            url: "/security",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/settings/security/security.html",
                    controller: "SecurityController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                details: "settings.security.edit"
            }
        }
    },

    {
        state: "settings.security.edit",
        options: {
            title: "Доступ к интерфейсу | ",
            url: "/:role",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@settings.security": {
                    templateUrl: "/pages/workplace/settings/security/securityEdit.html",
                    controller: "SecurityEditController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                meta: {
                    parameters: ["role"],
                    sectionFunction: function ([role]) {
                        return "Общие настройки";
                    },
                    subSectionFunction: function ([role]) {
                        return role ? ("Редактирование списка IP адресов для роли: " + role) : ("Создание списка IP адресов для роли: " + role);
                    }
                }
            }
        }
    }
];
