import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {VAProjectVersion} from "../../../../data/va/VAProjectVersion";
import {StateService} from "@uirouter/core";
import {ProjectVersionService} from "../../project-version/project-version.service";

@Component({
    selector: 'dialog-autocheck',
    template: require('./dialog-autocheck.component.html'),
    styles: [require('./dialog-autocheck.component.less')]
})
export class DialogAutocheckComponent implements OnInit {

    /**
     * Список всех версий проекта
     */
    private projectVersions: VAProjectVersion[];

    /**
     * Текущая выбранная версия
     */
    private projectVersion: VAProjectVersion;

    @Output()
    private checkChange = new EventEmitter<string>();

    constructor(private stateService: StateService, private projectVersionService: ProjectVersionService) {
    }

    async ngOnInit(): Promise<void> {
        this.projectVersions = await this.projectVersionService.getVersionsOfProject();
        this.projectVersion = this.projectVersions.find(version => version.id == this.stateService.params['projectVersionId']);
    }

    /**
     * Отправить родителю сообщению с идентификатором выбранной версии
     */
    check() {
        this.checkChange.emit(this.projectVersion.id);
    }

}