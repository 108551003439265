import {ScriptViewerComponent} from "../script-viewer.component";
import * as joint from '@naumen/rappid';
import {ui} from '@naumen/rappid';
import {GRID_SIZE} from "../ScriptBuilderUtils";
import JointAdapter from "../JointAdapter";
import {CheckpointableElement} from "../element-view/interface/CheckpointableElement";
import RectangleElement from "../element-view/rectangle/RectangleElement";

export default class ScriptKeyboardController {
    public keyboard: ui.Keyboard;

    constructor(private component: ScriptViewerComponent, private adapter: JointAdapter) {
        // хотекеи
        this.keyboard = new joint.ui.Keyboard();
        this.keyboard.on({

            // зум
            'ctrl+plus': (evt) => this.updateZoom(evt, 0.2),
            'meta+plus': (evt) => this.updateZoom(evt, 0.2),
            'ctrl+minus': (evt) => this.updateZoom(evt, -0.2),
            'meta+minus': (evt) => this.updateZoom(evt, -0.2),

            // двигаем кликнутый элемент
            'up': (evt) => this.moveHandler(evt, 0, -GRID_SIZE),
            'down': (evt) => this.moveHandler(evt, 0, GRID_SIZE),
            'left': (evt) => this.moveHandler(evt, -GRID_SIZE, 0),
            'right': (evt) => this.moveHandler(evt, GRID_SIZE, 0),

            // медленно двигаем кликнутый элемент
            'shift+up': (evt) => this.moveHandler(evt, 0, -1),
            'shift+down': (evt) => this.moveHandler(evt, 0, 1),
            'shift+left': (evt) => this.moveHandler(evt, -1, 0),
            'shift+right': (evt) => this.moveHandler(evt, 1, 0),

            // пакетное изменение кончается при отпускании клавиш передвижения
            'keyup:up': (evt) => this.adapter.changesController.storeBatchChange(),
            'keyup:down': (evt) => this.adapter.changesController.storeBatchChange(),
            'keyup:left': (evt) => this.adapter.changesController.storeBatchChange(),
            'keyup:right': (evt) => this.adapter.changesController.storeBatchChange(),

            // удаляем выбранный элемент
            'delete': (evt) => this.deleteHandler(evt),
            'backspace': (evt) => this.deleteHandler(evt),

            'ctrl+z': (evt) => this.component.undo(true),
            'meta+z': (evt) => this.component.undo(true),
            'shift+ctrl+z': (evt) => this.component.redo(true),
            'shift+meta+z': (evt) => this.component.redo(true),

            // в зависимости от режима редактирования открываем диалог редактирования, либо переключаем чекпойнт
            'enter': (evt) => this.editCell(evt),

            // открываем сущность в новом окне, если выбран узел
            'ctrl+enter': (evt) => this.openEntityTab(evt),
            'meta+enter': (evt) => this.openEntityTab(evt),

            // удаляем изломы с ребра, если выбрано ребро или со всех ребер узла, если выбран узел
            'alt+enter': (evt) => this.deleteVertices(evt),

            // зумируемся в контент
            'alt+a': (evt) => this.zoomToFit(),

            // копи-паст
            'ctrl+c': () => this.adapter.copyPasteController.copyCells(),
            'meta+c': () => this.adapter.copyPasteController.copyCells(),
            'ctrl+x': () => this.adapter.copyPasteController.cutCells(),
            'meta+x': () => this.adapter.copyPasteController.cutCells(),
            'ctrl+v': () => this.adapter.copyPasteController.pasteCells(),
            'meta+v': () => this.adapter.copyPasteController.pasteCells(),

            // выделить все
            'ctrl+a': (evt) => this.selectAll(evt),
            'meta+a': (evt) => this.selectAll(evt),

            // сохранить
            'ctrl+s': (evt) => this.save(evt),
            'meta+s': (evt) => this.save(evt),

        }, this);
    }

    /**
     * Выбрать все элементы
     */
    selectAll(evt: any) {
        evt.preventDefault();
        this.adapter.copyPasteController.selectAll();
    }

    /**
     * Сохранить скрипт
     */
    save(evt: any) {
        evt.preventDefault();
        this.adapter.component.save();
    }

    private updateZoom(evt, zoom: number) {
        evt.preventDefault();
        this.component.paperScroller.zoom(zoom, {max: 5, grid: 0.2});
    }

    /**
     * Двигаем кликнутый элемент/линк/выбранное
     */
    public moveHandler(event: any, dx: number, dy: number) {
        if (!this.adapter.copyPasteController.selection.collection.isEmpty()) {
            this.prepareForMove(event);
            this.adapter.copyPasteController.selection.translateSelectedElements(dx, dy);
            this.adapter.copyPasteController.restoreSelectionFrame();
        } else if (this.component.nowEditedElement != null) {
            this.prepareForMove(event);
            this.component.nowEditedElement.translate(dx, dy);
        } else if (this.component.nowEditedLink) {
            this.prepareForMove(event);
            this.component.nowEditedLink.translate(dx, dy);
        }
    }

    private prepareForMove(event: any) {
        event.preventDefault();
        if (!this.adapter.changesController.isBatchInProgress) {
            // включаем пакетное изменение,если еще нет
            this.adapter.changesController.initBatchChange();
        }
    }

    /**
     * Удаляем кликнутый/выделенные элемент(ы), ребро(а)
     */
    public deleteHandler(event: any) {
        if (!this.adapter.copyPasteController.selection.collection.isEmpty()) {
            event.preventDefault();
            // удаляем выделенное
            this.adapter.changesController.initBatchChange();
            this.adapter.copyPasteController.selection.collection
                .filter(cell => cell.isElement() && cell.isDeletable())
                .filter(cell => cell.isDeletable())
                .forEach(cell => cell.remove());
            this.adapter.changesController.storeBatchChange();
        } else if (this.component.nowEditedElement != null) {
            event.preventDefault();
            if (this.component.nowEditedElement.isDeletable()) {
                // если можно, удаляем элемент
                this.component.nowEditedElement.onElementRemove();
            }
        } else if (this.component.nowEditedLink != null) {
            event.preventDefault();
            // если выбрано ребро - удаляем
            this.component.nowEditedLink.remove();
        }
    }

    /**
     * Редактируем - диалог либо свитч чекпойнта
     */
    private editCell(evt: any) {
        if (this.component.nowEditedElement != null) {
            evt.preventDefault();
            if (this.component.checkpointMode) {
                this.adapter.checkpointController.onNodeCheckpointClicked(this.component.nowEditedElement as CheckpointableElement);
            } else {
                this.adapter.dialogController.openDialog(this.component.nowEditedElement as RectangleElement);
            }

        } else if (this.component.nowEditedLink != null) {
            evt.preventDefault();
            if (this.component.checkpointMode) {
                this.adapter.checkpointController.onEdgeCheckpointClicked(this.component.nowEditedLink);
            } else {
                this.component.nowEditedLink.onConditionButton()
            }
        }
    }

    /**
     * Открываем сущность в новом окне/удаляем изломы с ребра
     */
    private openEntityTab(evt: any) {
        if (this.component.nowEditedElement != null) {
            evt.preventDefault();
            this.adapter.entityNavigationController.navigateTo(this.component.nowEditedElement);
        }
    }


    /**
     * Открываем сущность в новом окне/удаляем изломы с ребра
     */
    private deleteVertices(evt: any) {
        if (this.component.nowEditedLink != null) {
            evt.preventDefault();
            this.component.nowEditedLink.deleteVertices();
            this.component.nowEditedLink.redraw();
            this.adapter.changesController.storeBatchChange();
        }

        if (this.component.nowEditedElement != null) {
            evt.preventDefault();
            this.adapter.changesController.initBatchChange();
            this.component.nowEditedElement.deleteVertices();
            this.adapter.redrawGraph();
            this.adapter.changesController.storeBatchChange();
        }
    }

    /**
     * Зумируемся в контент
     */
    private zoomToFit() {
        this.component.zoomToFit();
    }

    /**
     * При уходе
     */
    public onDestroy(): void {
        this.keyboard.disable();
        this.keyboard = null;
    }
}