import {Component} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {ChangesService} from "../changes.service";
import {ChangesAction} from "../../journals.model";
import {JournalsDetailsComponent} from "../../journals-details/journals-details.component";

@Component({
    selector: 'changes-details',
    template: require('./changes-details.component.html'),
    styles: [require('./changes-details.component.less')]
})
export class ChangesDetailsComponent extends JournalsDetailsComponent<ChangesAction> {

    constructor(titleService: Title,
                protected stateService: StateService,
                protected dataService: ChangesService) {
        super(titleService, stateService);
    }

    async ngOnInit() {
        if (this.filter) {
            let result = await this.dataService.getChanges(this.filter);
            if (result) {
                this.page = result.page;
                this.objects = result.list;
                this.objects.forEach(object => {
                    object.showNetworkInfo = false;
                    object.cells.DETAILS.valueObject = this.fromJson(object.cells.DETAILS.value);
                });
            }
        }
    }

}
