import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";

import {TagTreeComponent} from "../../../tag/tree/tag-tree.component";
import WebSocketService from "../../../../../../services/WebSocketService";
import {ClusterTagTreeService} from "./cluster-tag-tree.service";
import {StateService} from "@uirouter/core";
import {ClusterEditComponent} from "../cluster-edit.component";
import {ClusterVaTag, TreeOptions} from "../../../model/tb-params.model";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {VaTBMessageCluster} from "../../../../../../data/va/Message";

@Component({
    selector: 'cluster-tag-tree',
    template: require('./cluster-tag-tree.component.html'),
    styles: [require('./cluster-tag-tree.component.less')]
})
export class ClusterTagTreeComponent extends TagTreeComponent {

    dataSource = new MatTreeNestedDataSource<ClusterVaTag>();
    treeControl = new NestedTreeControl<ClusterVaTag>(tag => tag.children);

    @Output()
    markSentToTag = new EventEmitter<any>();
    @Input()
    messagesList: number[];

    access: boolean;
    tagTree: ClusterVaTag;
    isLoading: boolean;
    projectVersionId: string;
    sourceTagId: string;
    sourceTagIdRequestParam: string;
    focusedTags: ClusterVaTag;
    rememberedTags: ClusterVaTag[] = [];
    rememberedTagsIds: number[] = [];
    treeOptions: TreeOptions;
    clearFocusAvailable: boolean;
    hasTree: boolean = true;

    constructor(public tagService: ClusterTagTreeService,
                protected webSocketService: WebSocketService,
                protected stateService: StateService,
                public changeDetector: ChangeDetectorRef,
                protected parentController: ClusterEditComponent) {
        super(tagService, webSocketService, changeDetector);

        const that = this;
        this.treeOptions = new TreeOptions(this.onDrop.bind(this), this.rememberTag.bind(this), this.focusTag.bind(this))


        this.projectVersionId = stateService.params['projectVersionId'];
        this.sourceTagId = stateService.params['sourceTagId'];
        this.sourceTagIdRequestParam = stateService.params['sourceTagId'];

        // подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_TAG_CREATED,VA_TAG_CHANGED,VA_TAG_DELETED",
            fn: function (event) {
                switch (event.type) {
                    case "VA_TAG_CREATED":
                    case "VA_TAG_CHANGED":
                    case "VA_TAG_DELETED":
                        let projectVersionId = that.webSocketService.tryGetProjectVersionId(event, 'object');
                        if (projectVersionId !== that.projectVersionId) {
                            break;
                        }
                        that.updateTags();
                        break;
                }
            }
        });

    }

    async ngOnInit() {
        this.updateTags();
        await super.ngOnInit();
        this.treeControl.expandAll();

        this.access = (await this.tagService.getAccess()).access;
        this.treeOptions.access = this.access;
    }

    /**
     * Обновить дерево тематик
     */
    updateTags() {
        // загружаем дерево тематик
        this.tagService.getSmallTalksAndBusinessTagsTree().then((data: ClusterVaTag) => {
            this.tagTree = data;
            this.parentController.selectMessageTag(this.parentController.messageTagsIds, [], false, [this.tagTree]);
            this.rebuildRememberedTags();
            this.switchTags();
        });

    }

    /**
     * Фокус тематики
     * @param tag
     */
    focusTag(tag: ClusterVaTag) {
        this.setTreeData(tag);
        this.clearFocusAvailable = true;
    }

    /**
     * вернуться из фокуса тематики
     */
    clearFocus() {
        this.switchTags();
        this.clearFocusAvailable = false;
    }

    /**
     * Сменить отображаемое дерево
     */
    switchTags() {
        let tagMode = this.treeOptions.tagMode;
        if (!tagMode || tagMode == 'ALL') {
            this.hasTree = !!this.tagTree;
            this.setTreeData(this.tagTree);
        } else if (tagMode == 'REMEMBER') {
            let tree = this.tagService.createRootCluster(this.rememberedTags);
            this.hasTree = tree.children.length > 0
            this.setTreeData(tree);
        } else if (tagMode == 'CLUSTER') {
            let tree = this.tagService.createRootCluster(this.getMessageTags());
            this.hasTree = tree.children.length > 0
            this.setTreeData(tree);
        }
    }

    private getMessageTags(): ClusterVaTag[] {
        let resultTree = [];
        TagTreeComponent.treeWalk(this.tagTree, (tag) => {
            if (this.messagesList.find((id) => id == tag.key.id)) {
                resultTree.push(tag);
            }
        })
        return resultTree;
    }

    /**
     * Обновляем дерево
     * @param tree
     * @private
     */
    private setTreeData(tree: ClusterVaTag): void {
        this.initTree(tree, null);
    }

    /**
     * Сменить отображение дерева
     * @param tagMode
     */
    switchTagMode(tagMode: string) {
        this.treeOptions.tagMode = tagMode;
        this.clearFocus();
    }

    /**
     * Закрепить тематику
     * @param tag
     */
    rememberTag(tag) {
        let flag = !tag.remembered;
        tag.remembered = flag;

        let id = tag.key.id;
        //определяем закрепленность тематики, если закреплена, отправляем ее в массив, иначе удаляем из массива
        if (flag) {
            this.rememberedTagsIds.push(id);
            this.rememberedTags.push(tag);
        } else {
            const index = this.rememberedTagsIds.indexOf(id);
            if (index !== -1) {
                this.rememberedTagsIds.splice(index, 1);
                this.rememberedTags.splice(tag, 1);
            }
        }
    }

    /**
     * Перестраивание закрепленных тематик
     */
    rebuildRememberedTags() {
        this.rememberedTags = [];
        let tags = [this.tagTree];
        for (let tag of tags) {
            Array.prototype.push.apply(tags, tag.children);
            if (this.rememberedTagsIds.indexOf(tag.key.id) !== -1) {
                tag.remembered = true;
                this.rememberedTags.push(tag);
            } else {
                tag.remembered = false;
            }
        }
    }

    async ngOnDestroy() {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    /**
     * Сохранить кластер
     * @param tag
     * @param cluster
     */
    saveCluster(tag, cluster) {
        let prevId = cluster.tagId;

        cluster.tagId = tag.key.id;
        this.tagService.updateCluster(cluster).then(() => {
            cluster.justSentToTag = false;
            setTimeout(function () {
                cluster.justSentToTag = true;
            }, 1);
        }, () => {
            // покажем старые значения
            cluster.tagId = prevId;
        })

    }

    /**
     * Сохранить сообщение
     * @param tag
     * @param message
     */
    saveMessage(tag, message) {

        let prevId = message.tagId;
        message.tagId = tag.key.id;

        this.tagService.sendToTag(message.key.id, message.tagId, this.sourceTagId).then(() => {
            // если сообщение успешно перенесено в другую тематику, то выкидываем его из кластера
            // и закрываем диалог
            this.markSentToTag.emit(message);
        }, () => {
            message.tagId = prevId;
        })
    }

    /**
     * Сохранить ответ
     * @param tag
     * @param reply
     */
    saveReply(tag, reply) {
        reply.tagId = tag.key.id;
        this.tagService.sendReplyToTag(reply.key.id, reply.tagId, this.sourceTagId, reply.clusterId).then(() => {
            this.markSentToTag.emit(reply);
        })
    }


    /**
     * Отфильтровать "неважные" дочерние тематики
     * @param node
     */
    anyUnImportantChildren(node) {
        return node.children.filter(child => !child.important).length > 0;
    };


    /**
     * Ивент скидывания объекта в дерево
     * @param data
     */
    onDrop(data: { cluster: VaTBMessageCluster, tag: ClusterVaTag }) {
        this.parentController.selectMessageTag([data.tag.key.id], [data.cluster.tagId]);
        if (data.cluster.hasOwnProperty("messageCount")) {
            this.saveCluster(data.tag, data.cluster);
        } else if (data.cluster.hasOwnProperty("replyType")) {
            this.saveReply(data.tag, data.cluster);
        } else {
            this.saveMessage(data.tag, data.cluster);
        }
    }

}