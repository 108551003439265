import {VABaseFormController} from "../../../../../../js/workplace/controllers/va/VABaseFormController";
import * as restangular from "restangular";
import {DKBField} from "../../../../data/va/Dkb";
import * as urls from "../../../../../../js/workplace/urls";

// контроллер страницы редактирования дкб поля
export default class VaDkbFieldEditController extends VABaseFormController<DKBField> {

    static $inject = ["Restangular", "$scope", "$stateParams", "$state", "TitleService"];

    constructor(Restangular: restangular.IService, $scope: ng.IScope, $stateParams: ng.ui.IStateParamsService,
                $state: ng.ui.IStateService, TitleService: any) {
        // id объекта из state
        const objectId = $stateParams['dkbFieldId'];
        if (!objectId) {
            TitleService.setTitle("Новоее свойство сущности в базе знаний")
        }

        // объект для формы
        const formObj = {
            name: "",
            entityId: $stateParams['dkbEntityId'],
        };

        // параметры формы
        const formOptions = {"create": {title: "Новое свойство сущности в базе знаний"}};

        // инициализируем родительский контроллер
        super(Restangular, $scope, $state, $stateParams, objectId, formObj, formOptions, (field) => {
            TitleService.setTitle(field.name);
            Restangular.one(urls.va.entityUsages, 'DKB_FIELD')
                .get({entityId: objectId})
                .then(result => {
                    this.form.usages = result;
                })
        });

        if (this.objId) {
            // доступ
            this.getAccess();
            // текущий аккаунт
            this.loadAccount();
        }
    }

    save() {
        this.commonSave((isNew, data) => {
            if (isNew) {
                this.$state.go('robot.dkb.edit.dkb_field.edit', {
                    dkbEntityId: this.listController.entityId,
                    dkbFieldId: data.key.id, notifySuccess: true
                });
            }
        });
    }

    remove() {
        this.commonRemove(() => {
            this.$state.go('robot.dkb.edit.dkb_field', {
                dkbEntityId: this.listController.entityId
            }, {reload: true});
        });
    }
}