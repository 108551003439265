declare global {
    const angular: ng.IAngularStatic;
    // function require(path: string): any;
    const REVISION: string;
    const ICONS: { [key: string]: string  };
    const d3: any;
}

export const Component = (options: ng.IComponentOptions) => {
    return function (controller) {
        const extend = angular.extend(angular.extend(options, {controller}), controller);
        return extend;
    };
};