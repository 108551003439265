/**
 * Директива для <input type="text">, которая разрешает ввод только целых чисел
 */

export default function NumbersOnlyDirective() {
    /**
     * Получить значение для отображения
     * @param maxValue максимальное значение
     * @param minValue минимальное значение
     * @param text текст из инпута
     */
    const getValue = (maxValue, minValue, text) => {
        let transformedInput = parseInt(text.replace(/[^0-9]/g, ''));
        let currentValue;
        // Если это "-", то разрешаем дальше вводить
        if (Number.isNaN(transformedInput)) {
            return transformedInput;
        } else {
            // Если число, то
            currentValue = transformedInput;
        }
        if (maxValue && currentValue > maxValue) {
            return maxValue.toString();
        } else if (minValue && currentValue < minValue) {
            return minValue.toString()
        }
        return currentValue.toString();
    };
    return {
        require: 'ngModel',
        scope: {
            maxValue: '<?',
            minValue: '<?',
        },
        link: (scope, element, attr, ngModelCtrl) => {
            function fromUser(text) {
                if (text) {
                    let value = getValue(scope.maxValue, scope.minValue, text);

                    if (text !== value) {
                        ngModelCtrl.$setViewValue(value);
                        ngModelCtrl.$render();
                    }
                    return value;
                }
                return undefined;
            }

            ngModelCtrl.$parsers.push(fromUser);
        },
        controller: () => {
        }
    };
}