export default function VaFilterDirective() {
    return {
        restrict: "EA",
        replace: true,
        transclude: {
            'body': 'filterBody',
            'tool': '?filterTool'
        },
        scope: {
            enabled: "=",
            title: "@",
            required: "=",
            disableSlide: "=",
            key: "@?",
            valueKey: '@?',
            values: "<?",
            singleValue: "<?",
            collectionValue: "<?",
            setSingleValue: '&?'
        },
        bindToController: true,
        templateUrl: "/pages/workplace/va/common/directives/filter.html",
        controllerAs: "filter",
        controller: ["$scope", "$element", VaFilterDirectiveController]
    };
}

class VaFilterDirectiveController {

    private $scope: angular.IScope;
    private $element: ng.IRootElementService;
    private enabled: boolean;
    private title: string;
    private key: string;
    private valueKey: string;
    private values: any[];
    private singleValue: any;
    private collectionValue: any[];
    private hasValue: boolean;
    private readonly hasValues: boolean;
    private setSingleValue: (argument: { key: string, valueKey?: string, values: any }) => void;

    constructor($scope: angular.IScope, $element: ng.IRootElementService) {
        this.$scope = $scope;
        this.$element = $element;
        // Смотрим переданы ли значения
        this.hasValues = this.$element.get(0).attributes.hasOwnProperty('data-values');

        if (this.$element.get(0).attributes.hasOwnProperty('data-collection-value')) {
            // Если кто-то начинает удалять значения из списка, то если их не осталось, то нужно закрыть фильтр
            this.$scope.$watchCollection('filter.collectionValue', () => {
                if (this.collectionValue.length == 0) {
                    this.enabled = false;
                }
            });
        }

        if (this.$element.get(0).attributes.hasOwnProperty('data-single-value')) {
            // очистка инпута - закрываем фильтр
            this.$scope.$watchCollection('filter.singleValue', () => {
                if (!this.singleValue) {
                    this.enabled = false;
                }
            });
        }
    }

    toggleEnable() {
        // По нажатию на блок у которого нет перечисляемых значений - ничего не делать (и скрыть квадратик)
        if (this.hasValues && this.values.length == 0) {
            return
        }
        this.enabled = !this.enabled;
        if (this.setSingleValue && this.values.length === 1) {
            this.setSingleValue({key: this.key, valueKey: this.valueKey, values: this.values[0]});
        }
    }

    toggleCheck(value) {
        alert(value);
        if (!value) {
            this.enabled = false;
        }
    }
}
