import {NgModule} from "@angular/core";
import {CollectionComponent} from "./collection.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ScrollableItemDirective} from './scrollable-item.directive';
import {MatProgressSpinnerModule} from "@angular/material";
import {ServerCollectionComponent} from "./server-collection.component";
import {SearchBarComponent} from "./search-bar/search-bar.component";
import {EruditeIconModule} from "../erudite-icon/erudite-icon.module";

@NgModule({
    declarations: [
        CollectionComponent,
        ServerCollectionComponent,
        ScrollableItemDirective,
        SearchBarComponent
    ],
    imports: [
        NgSelectModule,
        NgbPaginationModule,
        FormsModule,
        CommonModule,
        InfiniteScrollModule,
        MatProgressSpinnerModule,
        EruditeIconModule
    ],
    exports: [
        CollectionComponent,
        ServerCollectionComponent,
        SearchBarComponent
    ]
})
export class CollectionModule {

}