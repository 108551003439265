import {getShowAllOptionId} from "../../../js/workplace/common";

/**
 * Директива для добавления опции "Показать всё" в дропдаун с тэггируемыми сущностями
 * @constructor
 */
export default function ShowAllDirective() {
    return {
        require: 'uiSelect',
        link: (scope, element, attrs, $select) => {
            let showAllOption = {
                key: {
                    id: getShowAllOptionId()
                },
                deleted: false,
                tagged: true
            };

            /**
             * При открытии ui-select'a добавляем опцию "Показать всё"
             */
            scope.$on("uis:activate", (event, item) => {
                let defaultItems = scope.$eval(attrs.defaultItems);
                let items = scope.$eval(attrs.items);

                items.splice(0, items.length);
                
                // Запрашиваем у контроллера - а есть ли вообще скрытые элементы?
                let hasHiddenItems = scope.$eval(attrs.hasHiddenItems);
                // Если да, то получаем список доступных элементов
                if (hasHiddenItems) {
                    items.push(...defaultItems);
                    showAllOption[attrs.titleKey] = '<b>Показать все</b>';
                    items.push(showAllOption);
                } else {
                    let allItems = scope.$eval(attrs.allItems);
                    items.push(...allItems);
                }
            });

            /**
             * Действие при выборе элемента в списке ui-select
             */
            scope.$on("uis:select", (event, item) => {
                // Если нажали на "Показать все"
                if (item.key && item.key.id == showAllOption.key.id) {
                    let allItems = scope.$eval(attrs.allItems);
                    let items = scope.$eval(attrs.items);
                    // Запускаем функцию, которая подготовит нам список который мы подставим в селект (передаётся в директиву)
                    // let items = scope.$eval(attrs.onShowAllSelect);
                    items.splice(0, items.length);
                    items.push(...allItems);
                    // Сбрасываем выбранное
                    $select.selected = undefined;
                } else {
                    $select.close();
                }
            });
        }
    };
}

