import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {CollectionModule} from "../../common/collection/collection.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {ExpertModule} from "../../common/expert/expert.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {EditableListModule} from "../../common/editable-list/editable-list.module";
import {SettingsEditComponent} from "./details/settings-edit.component";
import {SettingsMasterComponent} from "./master/settings-master.component";
import {SettingsService} from "./settings.service";
import {SettingsAsrComponent} from "./asr/settings-asr.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {EruditeIconModule} from "../../common/erudite-icon/erudite-icon.module";


@NgModule({
    declarations: [
        SettingsMasterComponent,
        SettingsEditComponent,
        SettingsAsrComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        UIRouterUpgradeModule,
        CollectionModule,
        FormControlModule,
        ExpertModule,
        BoilerplateModule,
        EditableListModule,
        NgSelectModule,
        EruditeIconModule
    ],
    exports: [
        SettingsMasterComponent,
        SettingsEditComponent,
        SettingsAsrComponent
    ],
    providers: [
        SettingsService
    ]
})
export class SettingsModule {

    static states = [
        {
            name: "robot.settings",
            url: "/settings",
            views: {
                'content@authorized': {
                    component: SettingsMasterComponent
                }
            },
            data: {
                title: "Настройки",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.settings.edit',
            url: "/:groupName",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.settings': {
                    component: SettingsEditComponent
                }
            },
            data: {
                title: "Настройки | ",
                newObjectTitle: "",
                editObjectTitle: "Редактирование настройки",
                successfulSaveMessage: "Настройка сохранена",
                meta: {
                    parameters: ["groupName"],
                    sectionFunction: ([groupName]) => "Обучение робота",
                    subSectionFunction: ([groupName]) => groupName ? "Редактирование настройки" : ""
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];

}