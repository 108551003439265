/**
 * директива управления временными периодами
 * Created by Olesya
 */
function TimeInputDirective() {
    return {
        restrict: "EA",
        scope: {
            timeData: "=",
            disabled:"="
        },
        templateUrl: "/pages/workplace/elements/timeInput.html",
        controller: function () {
        },
        bindToController: true,
        controllerAs: "dCtrl"
    };
}

export default TimeInputDirective;