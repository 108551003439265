import {Component} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {BaseMasterComponent} from "../../base/base-master.component";
import {CustomizationScriptService} from "../customization-script.service";
import {CustomizationScript} from "../../../../../data/va/CustomizationScript";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'customization-script-master',
    template: require('./customization-script-master.component.html'),
    styles: [require('./customization-script-master.component.less')]
})
export class CustomizationScriptMasterComponent extends BaseMasterComponent<CustomizationScript, CompositeKey<number>> {

    constructor(titleService: Title,
                stateService: StateService,
                dataService: CustomizationScriptService,
                notificationService: NotificationService,
                httpClient: HttpClient) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`d`), new SortField(`Название`, `name`));
        this.getAccess();
    }
}
