import {Component} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {LoginsService} from "../logins.service";
import {LoginAction} from "../../journals.model";
import {JournalsDetailsComponent} from "../../journals-details/journals-details.component";

@Component({
    selector: 'logins-details',
    template: require('./logins-details.component.html'),
    styles: [require('./logins-details.component.less')]
})
export class LoginsDetailsComponent extends JournalsDetailsComponent<LoginAction> {

    constructor(titleService: Title,
                protected stateService: StateService,
                protected dataService: LoginsService) {
        super(titleService, stateService);
    }

    async ngOnInit() {
        if (this.filter) {
            let result = await this.dataService.getChanges(this.filter);
            if (result) {
                this.page = result.page;
                this.objects = result.list;
                this.objects.forEach(object => object.showNetworkInfo = false);
            }
        }
    }
}
