import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {SmaMasterComponent} from "./master/sma-master.component";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {CollectionModule} from "../../common/collection/collection.module";
import {MacroModule} from "../../common/macro/macro.module";
import {ExtractableValuesModule} from "../extractable-values/extractable-values.module";
import {SmaEditComponent} from "./details/sma-edit.component";
import {FormControlModule} from "../../common/form/form-control.module";
import {ExpertModule} from "../../common/expert/expert.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {FormulationModule} from "../../formulation/formulation.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {SmaService} from "./sma.service";


@NgModule({
    declarations: [
        SmaMasterComponent,
        SmaEditComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        UIRouterUpgradeModule,
        CollectionModule,
        MacroModule,
        ExtractableValuesModule,
        FormControlModule,
        ExpertModule,
        EntityUsageModule,
        FormulationModule,
        BoilerplateModule
    ],
    exports: [
        SmaMasterComponent,
        SmaEditComponent
    ],
    providers: [
        SmaService
    ]
})
export class SmaModule {

    static states = [
        {
            name: "robot.sma",
            url: "/sma",
            views: {
                'content@authorized': {
                    component: SmaMasterComponent
                }
            },
            data: {
                title: "Реакции",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.sma.edit',
            url: "/sma/edit/:smaId",
            views: {
                'details@robot.sma': {
                    component: SmaEditComponent
                }
            },
            data: {
                title: "Реакции | ",
                newObjectTitle: "Новая реакция",
                successfulSaveMessage: "Реакция сохранена",
                meta: {
                    parameters: ["smaId"],
                    sectionFunction: ([smaId]) => "Обучение робота",
                    subSectionFunction: ([smaId]) => smaId ? "Редактирование пеакции" : "Новая реакция"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];

}