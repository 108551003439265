import {Component, EventEmitter, Inject} from "@angular/core";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ReplyView} from "../model/reply.model";
import {DialogCorrectionDTO, DialogCorrectionTypeEnum} from "../model/correction.model";
import {MatDialog} from "@angular/material/dialog";
import {ReplyTagCorrectionDialogComponent} from "../reply-tag/reply-tag-correction-dialog.component";
import * as cloneDeep from "lodash/cloneDeep";
import {ReplyExtractedValueCorrectionBottomSheetComponent} from "../reply-extracted-value/reply-extracted-value-correction-bottom-sheet.component";
import {ExtractedValue, ExtractedValueType} from "../model/extracted-value.model";
import {DialogService} from "../dialog.service";

@Component({
    selector: 'correction-menu-bottom-sheet',
    template: require('./correction-menu-bottom-sheet.component.html'),
    styles: [require('./correction-menu-bottom-sheet.component.less')]
})

export class CorrectionMenuBottomSheetComponent {

    private onCorrectionSave: EventEmitter<DialogCorrectionDTO>;

    constructor(private bottomSheetRef: MatBottomSheetRef<CorrectionMenuBottomSheetComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
                    reply: ReplyView,
                    extractedValue: ExtractedValue,
                    correction: DialogCorrectionDTO,
                    correctionTypes: DialogCorrectionTypeEnum[],
                    onCorrectionSave: EventEmitter<DialogCorrectionDTO>
                },
                private dialog: MatDialog,
                private bottomSheet: MatBottomSheet,
                private dialogService: DialogService) {
        this.onCorrectionSave = this.data.onCorrectionSave;
    }

    openTagCorrectionDialog(): void {
        const dialogRef = this.dialog.open(ReplyTagCorrectionDialogComponent, {
            data: {reply: cloneDeep(this.data.reply), correction: null, type: DialogCorrectionTypeEnum.MISSED_TAG},
            width: '500px',
        });
        dialogRef.afterClosed()
            .subscribe((correction: DialogCorrectionDTO) => {
                this.bottomSheetRef.dismiss(correction);
            });
    }

    openExtractedValueCorrectionBottomSheet(type: DialogCorrectionTypeEnum): void {
        // если это извлечение даты или адреса, то корректировка формальная = сразу создаём DTO и сохраняем
        if (this.isExtractionCorrectionWithoutDetails()) {
            const reply = this.data.reply.reply;
            const extractedValueId = this.data.extractedValue ? this.data.extractedValue.id : null;
            this.dialogService
                .initCorrection(reply.dialogId, reply.id, type, extractedValueId)
                .then(correction => {
                    // сразу исключим из обучения
                    this.onCorrectionSave.emit(correction);
                    this.bottomSheetRef.dismiss();
                });
        } else {
            const bottomSheetRef = this.bottomSheet.open(ReplyExtractedValueCorrectionBottomSheetComponent, {
                data: {
                    reply: cloneDeep(this.data.reply),
                    extractedValue: this.data.extractedValue,
                    correction: this.data.correction,
                    tagId: this.data.reply.reply.tag ? this.data.reply.reply.tag.key.id : this.data.reply.reply.userAct?.smallTalkTagId,
                    type: type
                },
            });
            bottomSheetRef.afterDismissed()
                .subscribe((correction: DialogCorrectionDTO) => {
                    if (correction) {
                        this.onCorrectionSave.emit(correction);
                    }
                });
        }
    }

    /**
     * Корректировка о неверном извлечении без деталей?
     */
    private isExtractionCorrectionWithoutDetails() {
        return this.data?.extractedValue?.type == ExtractedValueType.ATTRIBUTE
            && (ExtractedValue.isDateValue(this.data.extractedValue) || ExtractedValue.isAddressValue(this.data.extractedValue));
    }

    canCheckReply() {
        if (this.data.reply.reply.apiTagId) {
            return false;
        }
        return true;
    }
}



