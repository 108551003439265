import {NgModule} from "@angular/core";
import {DkbFieldService} from "./dkb-field.service";

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [
        DkbFieldService
    ]
})
export class DkbModule {

}