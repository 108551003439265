module.exports =
{
    chat: {
        accounts: "/account/account",

        journal:"/account/journal",
        loginjournal:"/account/loginjournal",
        antifraudrule:"/account/antifraudrule",
        antifraudblockuser:"/account/antifraudblockuser",

        supervisor: "/account/supervisor"
    }
};