import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BaseAudioPlayerFunctions} from "./base-audio-player-components";
import {MatButton} from "@angular/material/button";

@Component({
    selector: 'erudite-audio-player',
    template: require('./erudite-audio-player.component.html'),
    styles: [require('./erudite-audio-player.component.less')]
})
export class EruditeAudioPlayerComponent extends BaseAudioPlayerFunctions implements OnInit {

    /**
     * Заголовок аудио
     */
    @Input()
    title: string;

    /**
     * Урл аудио-данных
     */
    @Input()
    audioUrl: string;

    /**
     * Отображение "Без тени"
     */
    @Input()
    withoutBoxShadow: boolean;

    /**
     * Отображать заголовок
     */
    @Input()
    displayTitle = false;

    /**
     * Автопроигрывание при изменении времени
     */
    @Input()
    autoPlay = false;

    /**
     * Отображать контрол громкости
     */
    @Input()
    displayVolumeControls = true;

    /**
     * Показывать кнопку закрытия
     */
    @Input()
    showCloseButton: boolean = false;

    /**
     * Начать проигрывать с этого момента
      */
    @Input()
    autoPlayTime: number = 0;

    /**
     * Эмиттер закрытия
     */
    @Output()
    onClose = new EventEmitter();

    /**
     * Кнопка проигрывания
     */
    @ViewChild('playButton', {static: true})
    public playButton: MatButton;

    constructor() {
        super();
    }

    /**
     * Нажатие на кнопку закрытия
     */
    close() {
        if (this.showCloseButton) {
            this.onClose.emit(null);
        }
    }

    /**
     * Сработал автостоп
     */
    autoStop() {
        // закрываем плеер, если задано
        this.onClose.emit(null);
    }

    ngOnInit() {
        // биндим события на аудио
        this.bindPlayerEvent();
        if (this.autoPlay) {
            // запускаем проигрывание
            this.play();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.audioUrl && !changes.audioUrl.firstChange && changes.audioUrl.currentValue != null) {
            this.resetAudio();
            if (this.autoPlay) {
                this.play();
            }
        }
        if (changes.autoPlayTime && !changes.autoPlayTime.firstChange) {
            if (this.isPlaying) {
                this.pause();
            }
            this.currentTime = this.autoPlayTime;
            this.currTimePostChanged({value: this.autoPlayTime});
            this.play();

        }
    }

    resetAudio(): void {
        this.player.nativeElement.src = this.audioUrl;
    }

    play(): void {
        setTimeout(() => {
            this.player.nativeElement.play();
            // возвращаем фокус на кнопку play, чтобы пробел и enter работали как play/pause
            setTimeout(() => this.playButton.focus(), 50);
        }, 0);
    }

}