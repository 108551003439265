import * as met from "../../../controllers/va/ml/models/VaModelEvalTextFunctions";

const EvalResultComponent = {
    bindings: {
        title: "@",
        results: "<",
        // css класс панели
        pclass: "@",
        // количество элементов на странице
        ppcount: "@",
        // false - чтобы по умолчанию раскрыть панель
        rcollapsed: "@",
        // название модели
        modelName: "<",
        // name of result popover file
        popoverFile: "@",
        // key for get text function for item (default it's same as model name)
        textFuncKey: "@"
    },
    templateUrl: "/pages/workplace/va/ml/models/eval-result.html",
    controller: function () {
        const that = this;

        that.currentPage = 1;
        that.perPageCount = that.ppcount == null ? 5 : that.ppcount;
        that.isCollapsed = that.rcollapsed == null ? true : (that.rcollapsed != 'false');
        that.popoverFile = that.popoverFile == null ? "result-popover.html" : that.popoverFile;
        that.textFuncKey = that.textFuncKey == null ? that.modelName : that.textFuncKey;

        const templatePrefix = "/pages/workplace/va/ml/models/";
        that.popoverTemplateUrl = templatePrefix + that.modelName + "/evaluate/" + that.popoverFile;

        // функция обрезки массива результатов для отображения на странице
        that.slice = function (array) {
            if (!array) {
                return [];
            }
            return array.slice((that.currentPage - 1) * that.perPageCount, that.currentPage * that.perPageCount)
        };

        // функция получени текста (ответа, реплики и т. д. ) из результатов
        that.text = function (t) {
            return met.getTextFunc(that.textFuncKey)(t);
        };
    }
};

export default EvalResultComponent;