import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {HttpClient} from "@angular/common/http";
import {StepLabelEntity, VaScriptNode} from "../../../../../data/va/Script";
import {ValueTypeEnum} from "../../../../../data/va/Extractor";
import * as urls from "../../../../../../../js/workplace/urls";
import * as moment from 'moment';

@Component({
    selector: 'edit-erudite-element-dialog',
    template: require('./edit-erudite-element-dialog.component.html'),
    styles: [require('./edit-erudite-element-dialog.component.less')]
})
export class EditEruditeElementDialogComponent {

    value: any;
    attribute: StepLabelEntity;
    errorMessage: string;
    private static INT_PATTERN = new RegExp(/^([-]?[1-9]\d*|0)$/, "im");
    private static FLOAT_PATTERN = new RegExp(/^([-]?[0\.]|[1-9]*[\.]?\d*?)$/, "im");
    format = `YYYY-MM-DD,HH:mm:ss`;

    constructor(public dialogRef: MatDialogRef<EditEruditeElementDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { node: VaScriptNode }, private http: HttpClient) {
        this.attribute = data.node.entity;
        this.value = data.node.stepLabel.value;
        if (this.attribute.valueType.name === ValueTypeEnum.DATE && this.value) {
            // создаем дату для пикера
            this.value = moment(this.value, this.format).toDate();
        }
    }

    onDeny(): void {
        this.dialogRef.close({deny: true});
    }

    async onSave(): Promise<void> {
        // теперь value всегда строка, а ввод чисел мы ограничили регулярками
        let hasValue = this.value?.length > 0;
        if (hasValue) {
            if (this.attribute.valueType.name === ValueTypeEnum.INT) {
                const numValue: number = +this.value;
                if (numValue > Math.pow(2, 31) - 1 || numValue < -Math.pow(2, 31)) {
                    this.errorMessage = 'Значение должно быть между -2 147 483 648 и 2 147 483 647';
                    return;
                }
                if (!Number.isInteger(numValue)) {
                    this.errorMessage = 'Значение должно быть целым числом';
                    return;
                }
            }
            const result = await this.isValidExtractorValue();
            if (!result.valid) {
                this.errorMessage = result.message;
                return;
            }
        }
        this.dialogRef.close(hasValue ? this.value : '');
    }


    /**
     * Валидно ли значение экстрактора
     */
    private async isValidExtractorValue(): Promise<{ valid: boolean, message: string }> {
        return await this.http.get<{ valid: boolean, message: string }>(`${urls.va.attributes}validateValue`, {
            params: {
                value: `${this.value}`,
                attributeId: `${this.attribute.key.id}`
            }
        }).toPromise()
    }

    /**
     * Изменение поля с числами
     * @param event событие
     * @param type  тип атрибута
     */
    numberChange(event: any, type: ValueTypeEnum) {
        event.preventDefault();
        // выбираем регулярку
        const regExp: RegExp = ValueTypeEnum.INT == type ?
            EditEruditeElementDialogComponent.INT_PATTERN : EditEruditeElementDialogComponent.FLOAT_PATTERN;
        // складываем с текущим значением, либо просто берём символ с клавиши
        let result = this.value?.length ? this.value + event.key : event.key;
        // если не подходит - ничего не делаем
        if (!regExp.test(result)) {
            return;
        }
        // если подошло -- обновляем value и меняем вид input'a
        this.value = result.match(regExp)[1].trim();
        event.target.value = this.value;
    }
}
