import {VABaseFormController} from "../../../../../../js/workplace/controllers/va/VABaseFormController";
import * as restangular from "restangular";
import * as urls from "../../../../../../js/workplace/urls.js";
import * as common from "../../../../../../js/workplace/common.js";
import {DKBValue} from "../../../../data/va/Dkb";
import {VaAttribute} from "../../../../data/va/Attribute";

// контроллер страницы редактирования дкб поля
export default class VaDkbValueEditController extends VABaseFormController<DKBValue> {

    static $inject = ["Restangular", "$scope", "$stateParams", "$state", "TitleService"];
    private usedAttributes: VaAttribute[];

    constructor(Restangular: restangular.IService, $scope: ng.IScope, $stateParams: ng.ui.IStateParamsService,
                $state: ng.ui.IStateService, TitleService: any) {
        // id объекта из state
        const objectId = $stateParams['dkbValueId'];
        if (!objectId) {
            TitleService.setTitle("Новое значение свойства в базе знаний")
        }

        // объект для формы
        const formObj = {
            propertyValue: "",
            fieldId: $stateParams['dkbFieldId'],
            attributeConditionsDTOs: []
        };

        // параметры формы
        const formOptions = {"create": {title: "Новое значение свойства в базе знаний"}};

        // инициализируем родительский контроллер
        super(Restangular, $scope, $state, $stateParams, objectId, formObj, formOptions, (value) => {
            TitleService.setTitle(value.propertyValue);
            Restangular.one(urls.va.entityUsages, 'DKB_VALUE')
                .get({entityId: objectId})
                .then(result => {
                    this.form.usages = result;
                });
            this.form.object = new DKBValue(Restangular.stripRestangular(value));
        });

        if (this.objId) {
            // доступ
            this.getAccess();
            // текущий аккаунт
            this.loadAccount();
        }

        // загружаем атрибуты значения которых можно задавать
        common.loadLinkedValues(this, urls.va.dkb, this.listController.entityId, "usedAttributes", () => {
            this.usedAttributes = this.usedAttributes.map(a => new VaAttribute(a));
        });
    }

    /**
     * Отправить сообщение на вызов метода finishEdit, чтобы зафиксировать все значения атрибутов из формы
     */
    beforeSave() {
        window.postMessage({
            'func': 'callFinishSave'
        }, "*");
        setTimeout(() => {
            this.save();
        }, 0);
    }

    save() {
        // заполним map-у условий на атрибуты перед сохранением
        this.fillAttributeConditionField();

        this.commonSave((isNew, data) => {
            if (isNew) {
                this.$state.go('robot.dkb.edit.dkb_field.edit.dkb_value.edit', {
                    dkbEntityId: this.listController.entityId,
                    dkbFieldId: this.listController.fieldId,
                    dkbValueId: data.key.id, notifySuccess: true
                }, {reload: true});
            }
            this.form.object = new DKBValue(data);
        });
    }

    remove() {
        this.commonRemove(() => {
            this.$state.go('robot.dkb.edit.dkb_field.edit.dkb_value', {
                dkbEntityId: this.listController.entityId,
                dkbFieldId: this.listController.fieldId
            }, {reload: true});
        });
    }

    /**
     * Метод для заполнения поля attributeCondition в DKB-значении перед отправкой на сервер
     */
    fillAttributeConditionField() {
        // заполним у values-ов attributeCondition поле
        // ATTR-ID___VALUE&VALUE&VALUE
        const attributeCondition = [];
        if (this.form.object.attributeConditionsDTOs != null) {
            for (let i = 0; i < this.form.object.attributeConditionsDTOs.length; i++) {
                const attrValue = this.form.object.attributeConditionsDTOs[i];
                let elem = attrValue.info.key.id + "___";
                for (let m = 0; m < attrValue.values.length; m++) {
                    elem += common.getAttributeValue(attrValue.values[m], attrValue.info.extractor, false);
                    if (m != attrValue.values.length - 1) {
                        elem += "&";
                    }
                }
                attributeCondition.push(elem);
            }
        }
        this.form.object.attributeCondition = attributeCondition;
    }
}