/**
 * Created by semyon on 21.09.16.
 */

export default function AttributeValueFilter() {

    function getValByKey(key, availableValues) {
        for (var i = 0; i < availableValues.length; i++) {
            var obj = availableValues[i];
            if (obj.key == key) {
                return obj;
            }
        }
        return null;
    }

    return function (value, type, availableValues) {
        var output = "";
        switch (type) {
            case "RATING":
            case "STRING_SELECT":
            case "SELECT":
                output = getValByKey(value.key, availableValues);
                if (output) {
                    output = output.title;
                }
                break;
            case "STRING_COLLECTION":
            case "COLLECTION":
                var arr = [];
                for (var j = 0; j < value.length; j++) {
                    var obj = value[j];
                    let valByKey = getValByKey(obj.key, availableValues);
                    if (valByKey) {
                        valByKey = valByKey.title;
                    }
                    arr.push(valByKey);
                }
                output = arr.join(",");
                break;

            default:
                output = value;
                break;
        }
        return output;
    };
}
