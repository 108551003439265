import {Component, EventEmitter} from "@angular/core";
import {AttemptDTO, AttemptFilter, VaExtractionContext} from "../../../dialog/model/correction.model";
import {StateService} from "@uirouter/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {AttemptService} from "../attempt.service";
import {BaseMasterComponent} from "../../base/base-master.component";
import {FileSaverService} from "ngx-filesaver";
import {FileItem} from "../../../common/file-uploader/uploader-vendor/file-item.class";
import {Extractor, ValueOption} from "../../../../../data/va/Extractor";
import {extractFileName} from "../../../../../../util/Utils";
import {MassOperationButton} from "../../../common/collection/collection.component";
import {MatDialog} from "@angular/material/dialog";
import {MassOperationDialogComponent} from "../../mass-operation/dialog/mass-operation-dialog.component";
import {getUnitCase, UnitCase} from "../../../../../../utils";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {MassOperation, MassOperationType} from "../../../../../data/va/MassOperation";
import {SortField} from "../../../../../data/va/Common";

@Component({
    selector: 'va-attempts',
    template: require('./attempt-master.component.html'),
    styles: [require('./attempt-master.component.less')]
})

export class AttemptMasterComponent extends BaseMasterComponent<AttemptDTO, number> {

    private readonly projectVersionId: string;
    private clearSearchStringChange: EventEmitter<any> = new EventEmitter<any>();
    private readonly extractorId: number;
    extractorTitle;
    showUpload: boolean = false;
    private form: any;
    importFile: File;
    isUploading: boolean;
    uploadForm: any = {
        success: false,
        errors: new Map<string, string>()
    };

    valueOptions: ValueOption[];
    extractionContexts: Extractor[];
    allContexts: VaExtractionContext[];

    filter: AttemptFilter = new AttemptFilter();

    readonly massOperationButtons: MassOperationButton[] = [
        {
            title: "Изменить контекст извлечения",
            class: 'btn btn-accent',
            operation: selectedItems => this.openMassOperationDialog(selectedItems, MassOperationType.CHANGE_CONTEXT)
        },
        {
            title: "Удалить выбранное",
            class: 'btn btn-plain',
            operation: selectedItems => this.openMassOperationDialog(selectedItems, MassOperationType.DELETE)
        }
    ];

    constructor(protected titleService: Title,
                protected stateService: StateService,
                protected dataService: AttemptService,
                notificationService: NotificationService,
                protected httpClient: HttpClient,
                protected fileSaver: FileSaverService,
                private dialog: MatDialog) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`d`), new SortField("Текст", "fullText"));
        this.form = {
            isLoading: false,
            hideSuccess: true,
        };

        this.projectVersionId = stateService.params['projectVersionId'];
        this.extractorId = stateService.params['id'];
        // noinspection JSIgnoredPromiseFromCall
        this.getAccess();
    }

    async ngOnInit() {
        this.extractorTitle = (await this.dataService.getInfoExtractor(this.extractorId)).name;

        await super.ngOnInit();
        await this.getAccess();

        // Заполняем фильтр вариантами
        try {
            let filterData = await this.dataService.getAttemptFilterData(this.extractorId);
            this.setFilterData(filterData);

            let selectedValueOption = this.stateService.params['selectedValueOption'];
            if (selectedValueOption) {
                let reqValueOption = this.valueOptions.find(valueOption => valueOption.apiKey == selectedValueOption);
                this.checkFilterData(reqValueOption);
            }
        } catch (e) {
            throw new Error(e);
        }
        this.allContexts = await this.dataService.loadExtractionContexts();
        this.setLoading(false);
    }

    async loadList() {
        this.setLoading(true);
        this.objects = await this.dataService.findAll(new HttpParams().set('extractorId', this.stateService.params['id']));
        this.setLoading(false);
    }

    setFilterData(data) {
        this.valueOptions = data.values;
        this.extractionContexts = data.extractionContexts;
    }

    async loadAttemptsList() {
        await this.loadList();
    }

    /**
     * Экспортировать примеры извлечения
     */
    async exportAttempts() {
        const errorMessage = "Не удалось получить примеры обучения";
        try {
            let response = await this.dataService.getExport(this.extractorId);
            let fileName = extractFileName(response.headers.get('Content-Disposition'));
            if (!fileName) {
                this.notificationService.error(errorMessage);
                return;
            }
            const file = new Blob([response.body], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            this.fileSaver.save(file, fileName);
        } catch (e) {
            this.notificationService.error(errorMessage);
        }
    }

    onAttemptsFileChange(items: FileItem[]) {
        this.importFile = items ? this.importFile = items.pop()._file : null;
    }

    /**
     * Показать/убрать форму загрузки файла
     */
    toggleUploadAttempts() {
        this.uploadForm.errors.clear();
        this.showUpload = !this.showUpload;
        this.importFile = null;
    }

    /**
     * Загрузить примеры извлечения
     */
    async uploadAttempts() {
        let formData = new FormData();
        formData.append('file', this.importFile);
        this.isUploading = true;
        try {
            await this.dataService.import(formData, this.extractorId)
            this.toggleUploadAttempts();
            await this.loadAttemptsList();
        } catch (e) {
            e.errors.forEach(error => this.uploadForm.errors.set("#main", error.message));
        } finally {
            this.isUploading = false;
        }
    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     */
    onFileAddFailed($event: string) {
        this.uploadForm.errors.set("#main", $event);
        setTimeout(() => this.form.errors.set("#main", null), 5000);
    }

    /**
     * Если мы обновляли страницу, фильтр мог слететь -> надо восстановить
     */
    checkFilterData(value) {
        if (this.filter.selectedValueOption == null)
            this.filter.selectedValueOption = value;
    }

    clearFilters() {
        this.clearSearchStringChange.next();
        this.filter = new AttemptFilter();
    }

    filterChange(searchString: string, attempt: AttemptDTO, filter: AttemptFilter): boolean {
        if (filter.selectedValueOption && attempt.value.apiKey != filter.selectedValueOption.apiKey) {
            return false;
        }

        if (filter.selectedExtractionContext && attempt.extractionContext.name != filter.selectedExtractionContext.name) {
            return false;
        }

        if (filter.fromDialog == 'Из диалога' && attempt.dialogId == 0) {
            return false;
        }

        if (filter.fromDialog == 'Не из диалога' && attempt.dialogId != 0) {
            return false;
        }

        return !(searchString && attempt.fullText.toLowerCase().indexOf(searchString.toLowerCase()) < 0);
    }

    public isUsingFilter(filter: AttemptFilter): boolean {
        return filter.selectedExtractionContext != null || filter.selectedValueOption != null || filter.fromDialog == 'Из диалога' || filter.fromDialog == 'Не из диалога';
    }

    updateFilter(value: any, key: string) {
        this.filter[key] = value;
        this.filter = Object.assign({}, this.filter);
    }

    /**
     * Массово поменять контекст
     */
    async openMassOperationDialog(items: AttemptDTO[], type: MassOperationType) {
        // открываем диалог
        const operation = await this.dialog.open(MassOperationDialogComponent, {
            width: '500px',
            data: {
                operation: {ids: items.map(item => item.id), type: type},
                contexts: this.allContexts
            }
        }).afterClosed().toPromise<MassOperation>();

        if (!operation) {
            // отмена
            return;
        }
        if (this.stateService.current.name.endsWith('edit')) {
            // выходим из редактирования элемента, если было открыто
            this.stateService.go('^');
        }
        // массовая операция на бэке
        await this.massOperationRequest(operation);
        // перегружаем список элементов
        await this.loadAttemptsList();
    }

    /**
     * Сделать запрос массовой операции, получить описание результата
     */
    private async massOperationRequest(operation: MassOperation): Promise<void> {
        try {
            await this.dataService.massOperation(operation);
        } catch (e) {
            this.notificationService.error(`Произошла ошибка: ${e}`);
        }

        let message;
        switch (operation.type) {
            case MassOperationType.CHANGE_CONTEXT:
                message = `Контекст извлечения изменен на "${operation.context.title}"`;
                break;
            case MassOperationType.DELETE:
                switch (getUnitCase(operation.ids.length)) {
                    case UnitCase.ONE:
                        message = `Удален ${operation.ids.length} пример`;
                        break;
                    case UnitCase.TWO_TO_FOUR:
                        message = `Удалено ${operation.ids.length} примера`;
                        break;
                    case UnitCase.FIVE_AND_MORE:
                        message = `Удалено ${operation.ids.length} примеров`;
                        break;
                    default:
                        throw new Error("unsupported");
                }
                break;
            default:
                throw new Error("unsupported");
        }
        this.notificationService.success(message);
    }

    /**
     * Переключить состояние выбранности элемента
     */
    switchSelected(attempt: AttemptDTO) {
        attempt.selected = !attempt.selected;
    }

    /**
     * Ничего не делать при клике
     */
    doNothing($event: MouseEvent) {
        $event.stopPropagation();
        return false;
    }
}

