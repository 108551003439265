import {CompositeKey} from "./Common";

/**
 * Описание: {@link ru.naumen.chat.va.data.entity.voice.VAASRSettings}
 */
export class VaAsrSettings {
    key: CompositeKey<number>;
    extractorId: number;
    grammar: string;
    noInputTimeout: number;
    recognitionTimeout: number;
    bargeInWords: string;
    allowBargeIn: boolean;
    deleted: boolean;
    speechCompleteTimeout: number;
    speechIncompleteTimeout: number;
    vendor: LevitanVendor;
    grammarAttributeId: number;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.extractorId = object.extractorId;
        this.grammar = object.grammar;
        this.noInputTimeout = object.noInputTimeout;
        this.recognitionTimeout = object.recognitionTimeout;
        this.allowBargeIn = object.allowBargeIn;
        this.deleted = object.deleted;
        this.speechCompleteTimeout = object.speechCompleteTimeout;
        this.speechIncompleteTimeout = object.speechIncompleteTimeout;
        this.vendor = object.vendor;
        this.grammarAttributeId = object.grammarAttributeId;
    }
}

export class TtsSynthesisRequest {
    text: string;
    settings: VaTtsSettings
}

/**
 * Описание: {@link ru.naumen.chat.va.data.entity.voice.VATTSSettings}
 */
export class VaTtsSettings {
    key: CompositeKey<number>;
    attributeId: number;
    voiceName: string;
    emotion: string;
    voiceSpeed: number;
    deleted: boolean;
    vendor: LevitanVendor;
    audioRecordsUseTts: boolean;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.attributeId = object.attributeId;
        this.voiceName = object.voiceName;
        this.voiceSpeed = object.voiceSpeed;
        this.deleted = object.deleted;
        this.vendor = object.vendor;
        this.emotion = object.emotion;
    }
}

export class LevitanVendor {
    name: string;
    title: string;
    fileExtension: string;
    contentType: string;
}
