import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {Extractor, ValueTypeEnum} from "../../../../data/va/Extractor";
import {AttributeService} from "../../va/attribute/attribute.service";
import {HttpClient} from "@angular/common/http";
import {ProjectService} from "../../project/project.service";
import {LevitanVendor, VaAsrSettings} from "../../../../data/va/Voice";
import {VaAttribute} from "../../../../data/va/Attribute";
import {TextToSpeechService} from "../../media-libraries/text-to-speech/text-to-speech.service";
import {ExtractorService} from "../../va/extractor/extractor.service";


@Component({
    selector: 'va-asr',
    template: require('./va-asr.component.html'),
    styles: [require('./va-asr.component.less')]
})
export class VaAsrComponent implements OnInit {
    @Input()
    public object: Extractor;

    @Input()
    public onlyPreview: boolean;

    @Input()
    public asrSettingsOpen: boolean = false;

    @Input()
    access: boolean = false;

    @ViewChild('textAreaElement', {static: false}) textAreaElement: ElementRef;

    @Input()
    defaultAsrSettings: VaAsrSettings;

    public grammarRepresentation: string | null = null;
    public vendors: LevitanVendor[] = [];
    public availableGrammarAttributes: VaAttribute[] | null = null;
    grammars: string[];

    constructor(private ttsService: TextToSpeechService,
                private attributeService: AttributeService,
                protected httpClient: HttpClient,
                protected projectService: ProjectService,
                public dataService: ExtractorService,
                private changeDetector: ChangeDetectorRef) {
    }

    async ngOnInit(): Promise<void> {
        if (!this.object?.key?.projectVersionId) {
            return;
        }

        if (!this.defaultAsrSettings) {
            this.defaultAsrSettings = await this.dataService.getDefaultAsrSettings();
        }

        if (!this.isDefaultAsrSettings()) {
            // если уже открыто окно редактирования настроек, то изменим размер у textarea
            this.changeDetector.detectChanges();

            if (!this.textAreaElement.nativeElement.value) {
                // если не успели подгрузить текст, то добавим так
                this.textAreaElement.nativeElement.value = this.object.asrSettings?.bargeInWords;
            }

            this.resizeTextArea();
        }

        this.httpClient.get(`${this.projectService.baseUrl}/getByName/${this.object.key.projectVersionId.substring(0, this.object.key.projectVersionId.length - 4)}`).toPromise()
            .then((project: any) => {
                if (project.channels.Assistant) {
                    this.onlyPreview = false;
                }
            });

        let attributes = await this.attributeService.findAll();
        this.availableGrammarAttributes = await this.attributeService.findByExtractorValueType(ValueTypeEnum.ANY);

        this.vendors = await this.ttsService.getVendors(false);
        await this.loadGrammars(false);
        this.grammarRepresentation = this.object.asrSettings?.grammarAttributeId ?
            attributes.filter((attribute) => attribute.key.id == this.object.asrSettings.grammarAttributeId)[0].apiKey
            : this.object.asrSettings?.grammar;
    }

    openAsrSettingsModal() {
        this.asrSettingsOpen = !this.asrSettingsOpen;
        if (this.asrSettingsOpen) {
            this.changeDetector.detectChanges();

            if (!this.textAreaElement.nativeElement.value) {
                // если не успели подгрузить текст, то добавим так
                this.textAreaElement.nativeElement.value = this.object.asrSettings?.bargeInWords;
            }

            this.resizeTextArea();
        }
    }

    isDefaultAsrSettings(): boolean {
        return this.object.asrSettings.allowBargeIn == this.defaultAsrSettings.allowBargeIn
            && this.object.asrSettings.grammar === this.defaultAsrSettings.grammar
            && this.object.asrSettings.noInputTimeout === this.defaultAsrSettings.noInputTimeout
            && this.object.asrSettings.recognitionTimeout === this.defaultAsrSettings.recognitionTimeout
            && this.object.asrSettings.speechCompleteTimeout === this.defaultAsrSettings.speechCompleteTimeout
            && this.object.asrSettings.speechIncompleteTimeout === this.defaultAsrSettings.speechIncompleteTimeout
            && this.object.asrSettings.bargeInWords === this.defaultAsrSettings.bargeInWords
            && this.object.asrSettings.vendor?.name === this.defaultAsrSettings.vendor?.name;
    }

    /**
     * Загрузить грамматики
     *
     * @param reload сбросить текущий выбор
     */
    async loadGrammars(reload: boolean): Promise<void> {
        // загружаем список грамматик
        const vendor = this.object.asrSettings?.vendor || this.vendors[0];
        this.grammars = await this.dataService.getGrammars(vendor.name);
        if (this.object.asrSettings.grammarAttributeId) {
            // грамматика формируется атрибутом
            return;
        }
        if (reload) {
            // сбрасываем текущий выбор
            this.object.asrSettings.grammar = null;
        }
        if (this.object.asrSettings.grammar) {
            // грамматика уже выбрана
            return;
        }
        // выбираем первую в списке
        this.object.asrSettings.grammar = this.grammars[0];
    }

    /**
     * Вендор ли ЦРТ
     */
    isCrt(): boolean {
        return this.object.asrSettings.vendor.name == 'CRT_VOICE_KEY' || this.object.asrSettings.vendor.name == 'CRT_VOICE_NAVIGATOR';
    }

    resizeTextArea() {
        if (this.textAreaElement?.nativeElement) {
            this.textAreaElement.nativeElement.style.height = 'auto';
            this.textAreaElement.nativeElement.style.height = (this.textAreaElement.nativeElement.scrollHeight + 2) + 'px';
        }
    }
}
