import {Component} from "@angular/core";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {BaseDetailsComponent} from "../../va/base/base-details.component";
import {ProjectVersionMasterComponent} from "../master/project-version-master.component";
import {ProjectVersionService} from "../project-version.service";
import {State, StateEnum, VAProjectVersion} from "../../../../data/va/VAProjectVersion";
import {FileSaverService} from "ngx-filesaver";
import {ProjectService} from "../../project/project.service";
import {Project, ProjectExportParams} from "../../project/project.model";
import {ErrorResponseData} from "../../../../../../js/workplace/controllers/va/VABaseFormController";
import {FileItem} from "../../common/file-uploader/uploader-vendor/file-item.class";
import WebSocketService from "../../../../services/WebSocketService";
import {Valued} from "../../../../data/va/Valued";
import {Extractor, ValueOption} from "../../../../data/va/Extractor";
import {ExtractorService} from "../../va/extractor/extractor.service";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {
    LongOperationService,
    LongOperationType
} from "../../common/snackbar/notification/long-operation/long-operation.service";

@Component({
    selector: 'project-version-edit',
    template: require('./project-version-edit.component.html'),
    styles: [require('./project-version-edit.component.less')]
})
export class ProjectVersionEditComponent extends BaseDetailsComponent<VAProjectVersion, string> {

    objectIdKey = 'editProjectVersionId';
    projects: Project[] = [];
    versionNumbers: string[] = [];
    copyFromProjectVersions: VAProjectVersion[] = [];
    states: State[] = [];
    importFile: File;
    projectExportParams = new ProjectExportParams();
    blockExportButton;
    private subscriptionId: string;
    private unblockExportSubscriptionId: string;
    private defaultChannel: ValueOption;
    educatedProjectVersionId: string;
    nextUrl: string;
    hasVoiceChannel: boolean = false;

    constructor(protected stateService: StateService,
                protected master: ProjectVersionMasterComponent,
                protected httpClient: HttpClient,
                public dataService: ProjectVersionService,
                protected titleService: Title,
                protected notificationService: NotificationService,
                private fileSaver: FileSaverService,
                private projectService: ProjectService,
                private webSocketService: WebSocketService,
                private longOperationService: LongOperationService,
                private extractorService: ExtractorService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    get formTitle(): string {
        return this.form?.object?.id;
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        // для перехода к обучению
        this.educatedProjectVersionId = this.stateService.params['projectVersionId'];
        this.nextUrl = this.stateService.params['nextUrl'] ? this.stateService.params['nextUrl'] : "robot";
        // рейндж дат экспорта диалогов

        // Подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_VERSIONING_PROGRESS_FINISH",
            fn: (event) => {
                switch (event.type) {
                    case "VA_VERSIONING_PROGRESS_FINISH":
                        if (this.isModeNew()) {
                            // если закончился импорт, то надо обновить текущи проект
                            this.httpClient.get(`${this.dataService.baseUrl}/${this.form.object.id}`).toPromise()
                                .then((projectVersion: VAProjectVersion) => {
                                    this.form.object = new VAProjectVersion(projectVersion);
                                    this.notificationService.success("Успешно");
                                    this.setModeEdit();
                                    // обновляем id объекта
                                    this.objId = projectVersion.id;
                                    this.master.selectedId = this.objId;
                                    let newParams = Object.assign({}, this.stateService.params);
                                    newParams[this.objectIdKey] = this.objId;
                                    this.stateService.go('.', newParams, {notify: false});
                                    this.loadStates();
                                }, () => {
                                    this.setLoading(false);
                                });
                        }
                        break;
                }
            }
        });

        // событие разблокировки кнопки экспорта
        const operationKey = {type: LongOperationType.EXPORT_VERSION, id: this.objId};
        this.unblockExportSubscriptionId = this.longOperationService.subscribeToFinish(operationKey, () => {
            return this.blockExportButton = false;
        });
        this.blockExportButton = await this.dataService.isExportInProgress(this.objId);
    }

    /**
     * Инициализация диапазона дат выборки диалогов
     */
    private initDialogDateRange() {
        const current = new Date();
        const monthAgo = new Date();
        monthAgo.setMonth(monthAgo.getMonth() - 1);

        this.projectExportParams.dialogDateRange = {
            fromDate: new NgbDate(monthAgo.getFullYear(), monthAgo.getMonth() + 1, monthAgo.getDate()),
            toDate: new NgbDate(current.getFullYear(), current.getMonth() + 1, current.getDate())
        }
    }

    /**
     * Уведомить бэкенд о выборе версии
     */
    async onStartEducation() {
        await this.httpClient.get(`${this.dataService.baseUrl}/onStartEdu`).toPromise();
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
        this.webSocketService.removeListener(this.unblockExportSubscriptionId);
    }

    async onObjectLoaded() {
        this.form.object = new VAProjectVersion(this.form.object);
        this.loadProjects();
        this.loadStates();
        await this.loadDefaultChannel();
        // упорядочиваем каналы
        this.sortProjectChannels();
        this.hasVoiceChannel = Object.keys(this.form.object.channels).some(key => key === Valued.VOICE_API_KEY || key === Valued.ASSISTANT_VOICE_API_KEY);
    }

    /**
     * Сортировка списка каналов
     */
    sortProjectChannels() {
        this.form.object.project.channelList = this.form.object.project.channelList.filter(channel=>channel.key !== Valued.ASSISTANT_VOICE_API_KEY);

        this.form.object.project.channelList.sort((channel1, channel2) => {
            // сначала по дефолтности
            return this.isDefaultChannel(channel1.key)
                ? -1
                : this.isDefaultChannel(channel2.key)
                    ? 1
                    // потом по названию
                    : channel1.value < channel2.value
                        ? -1
                        : channel1.value > channel2.value
                            ? 1
                            // потом по апикею
                            : channel1.key < channel1.key
                                ? -1
                                : channel1.key > channel1.key
                                    ? 1
                                    : 0;
        });
    }

    onProjectChanged(): void {
        this.form.object.projectId = this.form.object.project.name;
        this.form.object.id = this.form.object.projectId + ".";
        this.form.object.versionNumber = null;
        this.form.object.copyFromPVId = null;
        this.importFile = null;
        this.setChannelsByProject();
        this.httpClient.get(`${this.dataService.baseUrl}/${this.form.object.projectId}/versionNumbers`)
            .toPromise()
            .then((versionNumbers: string[]) => this.versionNumbers = versionNumbers);
        this.httpClient.get(`${this.dataService.baseUrl}/${this.form.object.projectId}/copyFromProjectVersions`)
            .toPromise()
            .then((copyFromProjectVersions: VAProjectVersion[]) => this.copyFromProjectVersions = copyFromProjectVersions);
    }


    /**
     * Проставить все каналы и проекта в версии
     */
    private setChannelsByProject(): void {
        this.form.object.channels = this.form.object.project.channels;
    }

    onVersionNumberChanged(): void {
        this.form.object.id = this.form.object.projectId + "." + this.form.object.versionNumber;
    }

    onChannelToggle(channelKey: string, channelTitle: string): void {
        const index = Object.keys(this.form.object.channels).indexOf(channelKey);
        if (index >= 0) {
            let channels = {};
            Object.keys(this.form.object.channels).filter(key => key !== channelKey)
                .forEach(key => channels[key] = this.form.object.channels[key]);
            this.form.object.channels = channels;
        } else {
            this.form.object.channels[channelKey] = channelTitle;
        }

        this.hasVoiceChannel = Object.keys(this.form.object.channels).some(key => key === Valued.VOICE_API_KEY || key === Valued.ASSISTANT_VOICE_API_KEY);
    }

    isChannelSelected(channelKey: string): boolean {
        return this.form.object.channels && Object.keys(this.form.object.channels).indexOf(channelKey) >= 0;
    }

    isDefaultChannel(channelKey: string) {
        return this.defaultChannel?.apiKey === channelKey;
    }

    isDisabledChannel(channelKey: string): boolean {
        if (this.isModeNew()) {
            // при создании версии каналы не выбираем
            return true;
        }
        if (this.form.object.state && this.form.object.state.name !== StateEnum.TRAINING) {
            // если состояние версии не обучение, то каналы нельзя менять
            return true;
        }
        if (this.isDefaultChannel(channelKey)) {
            // дефолтный канал нельзя редактировать
            return true;
        }
        if (channelKey !== Valued.VOICE_API_KEY && channelKey !== Valued.WEB_API_KEY) {
            // недефолные каналы редактируемые
            return false;
        }
        // если оба дефолтных канала есть, то один можно снять
        const defaultChannels = Object.keys(this.form.object.channels)
            .filter(channelKey => channelKey === Valued.WEB_API_KEY || channelKey === Valued.VOICE_API_KEY);
        return defaultChannels.length === 1 && defaultChannels[0] === channelKey;
    }

    get entityTitle(): string {
        if (this.form.object.id) {
            return 'Редактирование версии проекта';
        }
        return this.form.object.id;
    }

    generateFormObject(): VAProjectVersion {
        this.form.object = new VAProjectVersion();
        this.loadProjects();
        this.loadStates();
        return this.form.object;
    }

    loadStates(): void {
        this.httpClient.get(`${this.dataService.baseUrl}/states${this.objId ? ("?id=" + this.objId) : ""}`)
            .toPromise()
            .then((states: State[]) => this.states = states,
                () => {
                    this.states = [];
                    if (this.form.object) {
                        this.states.push(this.form.object.state);
                    }
                });
    }

    loadProjects(): void {
        this.projectService.findAll().then((projects: Project[]) => {
            this.projects = projects.map(project => new Project(project));
            if (this.isModeNew() && projects.length === 1) {
                // если проект в системе один, выбираем его
                this.form.object.project = projects[0];
                this.onProjectChanged();
            }
        });
    }

    async loadDefaultChannel() {
        return this.extractorService.getProjectChannel(this.form.object.projectId).then((channel: Extractor) => {
            this.defaultChannel = channel.valueOptions.find(option => option.isDefault);
        });
    }

    onImportFileSelected(items: FileItem[]): void {
        let file: File = items.pop()._file;
        if (file.size > 0) {
            this.importFile = file;
        }
    }

    onImportFileRemoved(): void {
        this.importFile = null;
    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     * @param $event
     */
    onImportFileAddFailed($event: string) {
        this.form.errors.set("#main", $event);
        setTimeout(() => this.form.errors.set("#main", null), 5000);
    }

    activate() {
        const previousActive = this.master.objects.filter(version => version.active && version.projectId == this.form.object.projectId);
        previousActive.forEach(version => version.active = false)
        this.doRequest("activate");
    }

    doRequest(urlCommand: string): void {
        if (this.objId) {
            this.setLoading(true);
            this.httpClient.get(`${this.dataService.baseUrl}/${urlCommand}/${this.objId}`).toPromise()
                .then((projectVersion: VAProjectVersion) => {
                        super.onSuccess();
                        this.form.object = new VAProjectVersion(projectVersion);
                        // заменяем в мастере объект, который редактировали
                        this.master.replaceObj(this.form.object);
                    },
                    (error: ErrorResponseData) => {
                        this.handleError(error);

                    });
        }
    }


    async save(preserveState?: boolean): Promise<void> {

        if (this.isModeNew()) {
            this.form.errors.clear();
            this.setLoading(true);
            // отправить запрос и поймать ошибки - результат будет через сокет
            if (this.importFile) {
                let formData = new FormData() as any;
                formData.append('projectVersion', new Blob([JSON.stringify(this.form.object)], {
                    type: "application/json"
                }));
                formData.append("file", this.importFile);
                this.httpClient.post(`${this.dataService.baseUrl}/import`, formData)
                    .toPromise()
                    .then(() => {
                    }, (error: ErrorResponseData) => {
                        this.handleError(error);
                    });
            } else {
                if (!this.form.object.copyFromPVId) {
                    this.form.errors.set("#main", "Необходимо выбрать версию проекта, на основе которой будет создана новая версия проекта");
                    this.setLoading(false);
                    return;
                }
                this.httpClient.post(`${this.dataService.baseUrl}`, this.form.object)
                    .toPromise()
                    .then(() => {
                    }, (error: ErrorResponseData) => {
                        this.handleError(error);
                    });
            }
        } else {
            await super.save(preserveState);
            this.loadStates();
        }
    }

    async finishSave(savePromise, isNew: boolean, preserveState: boolean): Promise<void> {
        await super.finishSave(savePromise, isNew, preserveState);
        this.form.object = new VAProjectVersion(this.form.object);
    }

    async export(): Promise<void> {
        this.blockExportButton = true;
        this.projectExportParams.projectVersionId = this.form.object.id;
        await this.dataService.export(this.projectExportParams);
    }

    isSaveWithConfirm(): boolean {
        if (this.isModeNew()) {
            return false;
        }
        // отфильтрyем, есть ли удаленные
        const removedChannelsWithChannelText = Object.keys(this.form.object.copyChannels)
            .filter(apiKey => !this.form.object.channels[apiKey])
            .filter(apiKey => this.form.object.project.hasChannelTextByApiKey[apiKey] === true);
        return removedChannelsWithChannelText.length > 0;
    }


    get saveConfirmMessage(): string {
        return "После удаления канала сохраненные формулировки удалятся. Продолжить?";
    }

    isSaveButtonAccess(): boolean {
        if (this.isModeNew()) {
            const isVersionFill = this.form.object && (this.form.object.copyFromPVId || this.importFile);
            return isVersionFill != null;
        } else {
            return this.access;
        }

    }

    /***
     * Клик на чекбокс экспорта диалогов
     */
    onDialogsToggle() {
        if (this.projectExportParams.withDialogs && (!this.projectExportParams.dialogDateRange?.fromDate || !this.projectExportParams.dialogDateRange?.toDate)) {
            // если включение экспорта диалогов и даты не ини
            this.initDialogDateRange();
        }
    }
}