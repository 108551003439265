declare function minErr(text: string);
declare function startingTag(element: any);

export default function InjectScopeDirective () {
    return {
        link: function($scope, $element, $attrs, controller, $transclude) {
            if (!$transclude) {
                throw minErr('ngTransclude')('orphan',
                    'Illegal use of ngTransclude directive in the template! ' +
                    'No parent directive that requires a transclusion found. ' +
                    'Element: {0}',
                    startingTag($element));
            }
            const innerScope = $scope.$new();
            $transclude(innerScope, function(clone) {
                $element.empty();
                $element.append(clone);
                $element.on('$destroy', function() {
                    innerScope.$destroy();
                });
            });
        }
    };
}