import {mergeDeep} from "../../../../../functions/UtilFunctions";
import RectangleElement from "../rectangle/RectangleElement";
import {ElementAttributeId, setElementAttribute} from "../../controller/ScriptAttributeUtils";

/**
 * Элемент, которому можно поставить чекпоинт
 */
export class CheckpointableElement extends RectangleElement {

    /**
     *  Callback на проставление чекпоинта
     */
    public onCheckpoint: (viewModel: CheckpointableElement) => void;

    /**
     * Вёрстка
     */
    public addCheckpointMarkUp() {
        mergeDeep(this.attributes, {
            attrs: {
                checkpointIcon: {
                    fill: '#fff',
                    ref: 'wrapper',
                    refX: this.checkPointRefX,
                    refY: 0,
                    visibility: this.node.checkpoint ? 'visible' : 'hidden'
                }
            }
        });
        this.markup.push({
            tagName: 'path',
            selector: 'checkpointIcon',
            attributes: {
                'd': 'M20.8437 4.00893C20.8437 3.67411 20.7097 3.33928 20.4687 3.09821L18.6472 1.27678C18.4062 1.03571 18.0713 0.901785 17.7365 0.901785C17.4017 0.901785 17.0669 1.03571 16.8258 1.27678L8.0401 10.0759L4.1026 6.125C3.86152 5.88393 3.5267 5.75 3.19188 5.75C2.85706 5.75 2.52224 5.88393 2.28117 6.125L0.459739 7.94643C0.218667 8.1875 0.0847387 8.52232 0.0847387 8.85714C0.0847387 9.19196 0.218667 9.52679 0.459739 9.76786L5.30795 14.6161L7.12938 16.4375C7.37045 16.6786 7.70527 16.8125 8.0401 16.8125C8.37492 16.8125 8.70974 16.6786 8.95081 16.4375L10.7722 14.6161L20.4687 4.91964C20.7097 4.67857 20.8437 4.34375 20.8437 4.00893Z'
            }
        });
    }

    /**
     * Смещение иконки чекпойнта
     */
    get checkPointRefX() {
        return this.node.stepLabel.followingExtraction ? -22 : 0;
    }

    onCheckpointableElementClick() {
        this.onCheckpoint(this);
        return null;
    }

    /**
     * Обновить представление иконки чекпойнта
     */
    adjustCheckpointIcon() {
        this.attr({
            checkpointIcon: {
                visibility: this.node.checkpoint ? 'visible' : 'hidden',
                refX: this.checkPointRefX
            }
        });
    }

    /**
     * Замена instanceof для mixin'ов
     */
    get isCheckpointable(): boolean {
        return true;
    }

    set checkpoint(value: boolean) {
        this.node.checkpoint = value;
        setElementAttribute(this, ElementAttributeId.CHECKPOINT, value);
    }

}