import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Model, ModelStatusEnum} from "../../../../../data/va/Model";
import * as urls from "../../../../../../../js/workplace/urls.js";
import {extractFileName} from "../../../../../../util/Utils";
import {FileSaverService} from "ngx-filesaver";
import WebSocketService from "../../../../../services/WebSocketService";
import * as Ajv from 'ajv';
import {FileItem} from "../../../common/file-uploader/uploader-vendor/file-item.class";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'intent-model-edit',
    template: require('./intent-model-edit.component.html'),
    styles: [require('./intent-model-edit.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class IntentModelEditComponent implements OnInit {

    public baseUrl: string;
    public modelId: any;
    public modelType: string;
    public errorJSON: string;
    public model: Model;
    public ajv = new Ajv({allErrors: true, jsonPointers: true});
    /**
     * Опции редактора
     */
    options: any = {
        minLines: 29,
        maxLines: 30
    };

    /**
     * Мапа заголовков результатов обучения
     */
    public titles: any[];

    /**
     * Мапа значений результатов обучения
     */
    public values: { [key: string]: string };

    /**
     * Мапа файлов результатов обучения
     */
    public files: { [key: string]: string };

    showUploader: boolean = false;
    rebuildInProgress: boolean = false;
    public subscriptionId: string;

    public access: boolean;

    public schema;

    classificationStatus: ClassificationStatus = ClassificationStatus.NOT_STARTED;

    constructor(private titleService: Title,
                private stateService: StateService,
                private httpClient: HttpClient,
                private fileSaver: FileSaverService,
                private webSocketService: WebSocketService,
                private notificationService: NotificationService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    async ngOnInit() {
        this.modelType = this.stateService.params["type"];
        this.baseUrl = `${urls.va.ml}${this.modelType}`;
        this.modelId = this.stateService.params["modelId"];
        this.model = await this.httpClient.get<Model>(`${this.baseUrl}/${this.modelId}`).toPromise();
        await this.loadResults();
        if ((this.model.status as any).name == 'BUILD_IN_PROGRESS') {
            this.rebuildInProgress = true;
        }

        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_REBUILD_MODEL_FINISHED",
            fn: async (event) => {
                let types = JSON.parse(event.details);
                if (types.length && types[0].projectVersionId === this.stateService.params["projectVersionId"]) {
                    this.model = await this.httpClient.get<Model>(`${this.baseUrl}/${this.modelId}`).toPromise();
                    this.rebuildInProgress = false;
                    await this.loadResults();
                }
            }
        });

        await this.getAccess();

        this.changeDetectorRef.markForCheck();

        try {
            this.model.modelParameters = JSON.stringify(JSON.parse(this.model.modelParameters), null, 4);
        } catch (e) {
            this.validateJson(e);
        }

        // Получить json schema и проверить json
        await this.httpClient.get<any>(`${this.baseUrl}/getSchema/` + this.modelType + `_model_schema.json`)
            .toPromise().then((results) => {
            this.schema = JSON.parse(results);
            this.changeDetectorRef.markForCheck();
            this.onChange();
        });
    }

    /**
     * При обновлении json проверить синтаксис и соответствие json schema
     * Изменить размер textarea, если надо
     */
    async onChange() {
        try {
            // В случае ошибки запишет в errorJSON
            let data = JSON.parse(this.model.modelParameters);
            let validate = this.ajv.compile(this.schema);
            // Если найдены ошибки
            if (!validate(data)) {
                this.errorJSON = ""
                let jsonMap = require('json-source-map');
                const result = jsonMap.parse(this.model.modelParameters);
                validate.errors.forEach(error => {
                    this.errorJSON += "Line: " + JSON.stringify(result.pointers[error.dataPath].value.line) +
                        ' - ' + error.dataPath + ': ' + error.keyword + " " + error.message + '\n';
                });
            } else this.errorJSON = "";
        } catch (e) {
            this.validateJson(e);
        }
    }

    validateJson(e: any) {
        if (this.model.modelParameters == null || e == null) {
            return;
        }
        this.errorJSON = "";

        // Добавим номер строки по позиции ошибки
        let match = e.toString().match('at position (\\d*)');
        let errLine = match ? match[1] : null;
        if (errLine) {
            let errLineNum = parseInt(errLine);
            errLineNum = this.model.modelParameters.substring(0, errLineNum).split(/\r\n|\r|\n/).length;
            this.errorJSON = "Line: " + errLineNum + " - ";
        }

        this.errorJSON += e;
    }

    async getAccess() {
        this.access = await this.httpClient.get<boolean>(`${urls.va.ml}access`).toPromise();
    }

    private async loadResults() {
        this.httpClient.get<any>(`${this.baseUrl}/results/${this.modelId}`)
            .toPromise().then((results) => {
            this.titles = results.titles;
            this.values = results.values;
            this.files = results.files;
        }, () => {

        });

    }

    /**
     * Скачать файл результата модели (картинку, текстовый файл, xls)
     * @param filePath  путь до файла на фс
     */
    downloadResult(filePath: string) {
        this.httpClient.post(`${this.baseUrl}/downloadResultFile`, {}, {
            responseType: 'blob',
            observe: "response",
            params: {path: filePath}
        }).subscribe((data) => {
            const fileName = extractFileName(data.headers.get('Content-Disposition'));
            this.fileSaver.save(data.body, fileName)
        });
    }

    isAcceptAccess(): boolean {
        return this.model.status.name === ModelStatusEnum.FINISH && this.access;
    }

    isRebuildAccess(): boolean {
        return (this.model.status.name != ModelStatusEnum.ACCEPTED && this.model.status.name != ModelStatusEnum.PHASE_BUILDING) && this.access;
    }

    accept() {
        this.httpClient.get<Model>(`${this.baseUrl}/${this.model.key.id}/acceptModel`)
            .subscribe((model: Model) => this.model = model, () => {
            });
    }

    rebuild() {
        this.httpClient.post<Model>(`${this.baseUrl}/${this.model.key.id}/rebuild`, this.model.modelParameters)
            .subscribe(() => this.rebuildInProgress = true, () => {
            });
    }

    copy() {
        this.httpClient.get<Model>(`${this.baseUrl}/${this.model.key.id}/copyModel`)
            .subscribe((modelId) => {
                this.stateService.go('robot.intent_model', {
                    type: this.modelType,
                    modelId: modelId
                }, {reload: true})
            }, () => {
            });
    }

    /**
     *
     * @param item
     */
    async onFileChange(item: FileItem) {
        if (!item) {
            return;
        }
        this.classificationStatus = ClassificationStatus.IN_PROGRESS
        // только 1 файл может быть
        const file = item[0]._file;
        let formData = new FormData();
        formData.append('replies', file, file.name);
        try {
            const response = await this.httpClient.post(`${this.baseUrl}/classifyReplies`, formData, {
                responseType: 'blob',
                observe: "response",
            }).toPromise();
            // успешно, сохраняем файл
            this.classificationStatus = ClassificationStatus.COMPLETED;

            this.notificationService.success('Готово');
            const fileName = extractFileName(response.headers.get('Content-Disposition'));
            this.fileSaver.save(response.body, fileName)
        } catch (exception) {
            // в случае ошибки показываем красную плашку
            this.classificationStatus = ClassificationStatus.ERROR;
            this.notificationService.error('Не удалось классифицировать реплики, проверьте формат файла');
            console.log(exception)
        }
        setTimeout(() => {
            this.classificationStatus = ClassificationStatus.NOT_STARTED;
        }, 5000)
    }
}

enum ClassificationStatus {
    NOT_STARTED = "NOT_STARTED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR"
}
