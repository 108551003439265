import {TransitionService} from "@uirouter/core";
import JointAdapter from "../JointAdapter";
import {VAScript} from "../../../../data/va/Script";
import {dia} from '@naumen/rappid';
import {ScriptViewerComponent} from "../script-viewer.component";
import {CHECKABLE_DATA_STRUCTURE, deepEqual, restoreElementObject, restoreLinkObject} from "./ScriptAttributeUtils";

/**
 * Включить лог
 */
export const LOG_ENABLED = false;
export const DETAILED_LOG_ENABLED = false;

/**
 * Часть функционала script-viewer-component для обработки изменений в скрипт-билдере
 */
export default class ScriptChangesController {

    /**
     * Текст конфирма при несохраненных изменениях
     */
    private static readonly LEAVE_CONFIRM_MESSAGE: string = "В сценарии есть несохранённые изменения, вы действительно хотите покинуть страницу?";

    /**
     * Функция разрегистрации листенера ухода с билдера
     */
    private unregisterLeaveConfirm: Function;

    /**
     * Менеджер undo/redo
     */
    private readonly commandManager: joint.dia.CommandManager;

    /**
     * Данные сценария/процедуры, которые сохранены
     */
    savedScript: VAScript;

    public logChangesEnabled: boolean = false;

    /**
     * Удалявшееся
     */
    private cellsById: Map<string, dia.Cell> = new Map<string, dia.Cell>();

    /**
     * В процессе запись пачки для одного шага undo
     */
    private batchInProgress;

    /**
     * В этой пачке изменений есть добавление/удаление элементов и/или линков
     */
    private cellChangeBatch;

    /**
     * Счетчик кастомных команд
     */
    private commandCount: number = 0;

    constructor(private adapter: JointAdapter, private transitionService: TransitionService, private component: ScriptViewerComponent) {
        // приделываем листенер изменений, чтобы уметь выключать запись и подменять события добавления/удаления на свою реализацию undo/redo

        // noinspection JSUnusedGlobalSymbols
        this.commandManager = new dia.CommandManager({
            graph: adapter.getGraph(),
            cmdBeforeAdd: (cmdName: string, cell: dia.Cell, graph: dia.Graph, options: any) => {
                if (!this.logChangesEnabled) {
                    // запись отключена, скипаем чендж
                    return false;
                }
                if (cell.isLink() && !cell.erudite) {
                    // это изменения временного линка который еще не дотянули до конечного узла
                    return false;
                }
                // новое изменение - появилось анду, реду исчезло
                this.component.hasUndo = true;
                this.component.hasRedo = false;
                if (cmdName == 'add' || cmdName == 'remove') {
                    logString(`CHANGE ${cmdName}, cell.id: ${cell.id}, cell.isElement(): ${cell.isElement()}`);

                    // если это добавление/удаление, используем кастомное запоминание удаляемого,
                    // потому что в библиотеке восстанавливается из json без нужно типа и всех erudite-полей и методов
                    this.onCellAddRemove(cell, cmdName == 'add');
                    return false;
                }
                if (DETAILED_LOG_ENABLED) {
                    logObject(`cmdName ${cmdName},  opts`, options);
                }
                return true;
            }
        });

        // добавочный листенер на undo/redo
        this.commandManager.on('stack:undo', (commands, opt) => this.undoRedo(commands, opt, true));
        this.commandManager.on('stack:redo', (commands, opt) => this.undoRedo(commands, opt, false));
    }

    /**
     * Дополнительный листенер с кастомными апдейтами по erudite-полям, добавлением/удалением элементов и линков
     *
     * @param commands перечень команд для анду/реду
     * @param opt опции
     * @param undo true, если undo, false, если redo
     */
    private undoRedo(commands: any[], opt: any, undo: boolean) {
        logString(`${undo ? 'UNDO' : 'REDO'}, commands: ${DETAILED_LOG_ENABLED ? '\n' + JSON.stringify(commands, undefined, 4) : commands.length}`)
        // тут ищем добавления/удаления селлов
        const changeAttrCommands = commands.filter(command => command.action === 'change:attrs').filter(command => {
            if (command.data.next.attrs.cellChange) {
                // есть добавление/удаление
                this.undoRedoCell(JSON.parse(command.data.next.attrs.cellChange), undo);
                // команду обработали, убираем из списка
                return false;
            }
            return true;
        });
        logString(`attr commands ${changeAttrCommands.length}`)
        // по оставшимся командам ищем изменения кастомных атрибутов
        changeAttrCommands.filter(command => command.action === 'change:attrs').forEach(command => {
            // в зависимости от направления применения выбираем соответствующий кусов json-данных
            let source = undo ? command.data.previous : command.data.next;

            // если есть изменения кастомных атрибутов
            let eruditeChanges = source.attrs.erudite;
            if (eruditeChanges) {
                if (eruditeChanges.element) {
                    // есть по элементам
                    const cell = this.adapter.getGraph().getCell(command.data.id);
                    restoreElementObject(cell, eruditeChanges.element);
                } else if (eruditeChanges.link) {
                    // есть по линкам
                    const cell = this.adapter.getGraph().getCell(command.data.id);
                    restoreLinkObject(cell, eruditeChanges.link);
                }
            }
        });
    }

    /**
     * Откат/повтор добавления/удаления элемента или линка.
     * Так как у erudite-частей графа свои методы и данные, которые библиотечным движком не восстанавливаются,
     * достаем все из мапы, куда положили в листенере изменений
     *
     * @param infos массив с инфой по добавлению/удалению элементов/линков
     * @param undo true, если undo, false, если redo
     */
    private undoRedoCell(infos: CellAddRemoveInfo[], undo: boolean) {
        logObject('undo/redo cell', infos);
        // выключаем запись лога изменений
        this.disableLog();
        infos.sort((c1, c2) => {
            // если undo, то с последней команды
            return undo ? c2.count - c1.count : c1.count - c2.count;
        }).forEach(info => {
            // достаем из мапы ячейку с данными и методами наших классов
            const cell = this.cellsById.get(info.cellId);
            // добавлять или удалять
            const add = !info.isAdd && undo || info.isAdd && !undo;
            if (add) {
                logString(`adding cell ${cell.id}, cell.isElement(): ${cell.isElement()}`)
                // добавляем
                this.adapter.getGraph().addCell(cell);
                if (cell.isElement()) {
                    // кликаем на элемент
                    this.component.selectCell(cell);
                }
            } else {
                logString(`removing cell ${cell.id}, cell.isElement(): ${cell.isElement()}`)
                // удаляем
                this.adapter.getGraph().removeCells([cell]);
            }
        });
        // включаем лог
        this.enableLog();
    }

    /**
     * Действия при добавлении/удалении элемента/линка
     *
     * @param cell элемент/линк
     * @param add true, если добавление
     */
    public onCellAddRemove(cell: dia.Cell, add: boolean) {
        // запоминаем данные
        this.cellsById.set(cell.id, cell);

        // делаем запись о добавлении/удалении в виде значения кастомного атрибута у корневого элемента графа
        const info: CellAddRemoveInfo = {
            cellId: cell.id,
            type: cell.isElement() ? cell.node.stepLabel.type.name : 'link',
            isAdd: add,
            count: ++this.commandCount
        };
        // если это батч и добавления/удаления ячеек уже были, дописываем туда еще
        const infos: CellAddRemoveInfo[] = this.batchInProgress && this.cellChangeBatch
            ? JSON.parse(this.adapter.rootElement.attr('cellChange'))
            : [];
        infos.push(info);

        if (this.batchInProgress) {
            // если батч, запоминаем, что факт обновления состава графа
            this.cellChangeBatch = true;
        }
        // пишем апдейт кастомного атрибута рутового элемента
        this.adapter.rootElement.attr('cellChange', JSON.stringify(infos));
    }

    /**
     * Включить запись изменений
     */
    public enableLog() {
        logString(`ENABLE LOG CHANGE`);
        this.logChangesEnabled = true;
    }

    /**
     * Выключить запись изменений
     */
    public disableLog() {
        logString(`DISABLE LOG CHANGE`);
        this.logChangesEnabled = false;
    }

    /**
     * Обновить инфу по наличию undo/redo
     */
    private updateHasUndoRedo() {
        this.component.hasUndo = this.commandManager.hasUndo();
        this.component.hasRedo = this.commandManager.hasRedo();
    }

    /**
     * Отменить изменения
     */
    public undo() {
        if (this.commandManager.hasUndo()) {
            logString(`UNDO`);
            this.commandManager.undo();
        }
        this.updateHasUndoRedo();
    }

    /**
     * Вернуть изменения
     */
    public redo() {
        if (this.commandManager.hasRedo()) {
            logString(`REDO`);
            this.commandManager.redo();
        }
        this.updateHasUndoRedo();
    }

    /**
     * Начало изменения, которое нужо возвращать одним нажатием на undo
     */
    public initBatchChange() {
        if (this.commandManager && !this.batchInProgress) {
            logString(`INIT BATCH CHANGE`);
            this.batchInProgress = true;
            this.commandManager.initBatchCommand();
        }
    }

    /**
     * Конец изменения, которое нужо возвращать одним нажатием на undo
     */
    public storeBatchChange() {
        if (this.commandManager && this.batchInProgress) {
            logString(`STORE BATCH CHANGE`);
            this.commandManager.storeBatchCommand();
            this.batchInProgress = false;
            this.cellChangeBatch = false;
        }
    }

    /**
     * Пометить текущее состояние как неизмененное
     */
    markUnchanged() {
        logString(`MARK UNCHANGED`);
        // копируем начальные данные для вывода конфирма о несохраненных данных
        this.savedScript = JSON.parse(JSON.stringify(this.adapter.getCurrentScript()));
    }

    /**
     * Регистрируем листенер для конфирма выхода при несохраненных изменениях
     */
    registerLeaveConfirm() {
        // Помечаем текущее состояние как начальное
        this.markUnchanged();

        // Перед переходом проверям наличие изменений
        this.unregisterLeaveConfirm = this.transitionService.onStart({}, () => {
            if (this.isScriptChanged()) {
                // есть изменения - конфирм
                return confirm(ScriptChangesController.LEAVE_CONFIRM_MESSAGE);
            } else {
                // нет измененений - ок
                return true;
            }
        });
    }

    /**
     * При закрытии страницы
     */
    onDestroy() {
        if (this.unregisterLeaveConfirm) {
            // разрегистрируем листенер ухода из скрипт-билдера
            this.unregisterLeaveConfirm();
        }
    }

    /**
     * Запросить подтверждение ухода из билдера, если есть изменения
     */
    confirmChanges($event: any) {
        if (this.isScriptChanged()) {
            $event.returnValue = ScriptChangesController.LEAVE_CONFIRM_MESSAGE;
        }
    }

    /**
     * Проверка, были ли внесены изменения в скрипт
     */
    private isScriptChanged() {
        let result = deepEqual(this.savedScript, this.adapter.getCurrentScript(), CHECKABLE_DATA_STRUCTURE, 'script');
        return !result.equals;
    }

    /**
     * В процессе ли пакетное изменение
     */
    get isBatchInProgress() {
        return this.batchInProgress;
    }
}

/**
 * Запись в кастомном атрибуте про добавление/удаление элементов/линков
 */
interface CellAddRemoveInfo {
    cellId: string;
    type: string;
    isAdd: boolean;
    count: number;
}

/**
 * Логирование строки
 *
 * @param message строка
 */
export function logString(message: string) {
    if (LOG_ENABLED) {
        console.log(message);
    }
}

/**
 * Логирование  объекта
 *
 * @param prefix префикс
 * @param object объект
 */
export function logObject(prefix: string, object: any) {
    if (LOG_ENABLED) {
        console.log(prefix + '\n' + JSON.stringify(object, undefined, 4));
    }
}