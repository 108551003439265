import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ProjectVersionService} from "../../project-version/project-version.service";
import {VAProjectVersion} from "../../../../data/va/VAProjectVersion";
import {StateService} from "@uirouter/core";

@Component({
    selector: 'dialog-relevance',
    template: require('./dialog-relevance.component.html'),
    styles: [require('./dialog-relevance.component.less')]
})

export class DialogRelevanceComponent implements OnInit {

    @Input()
    isLoading: boolean;

    /**
     * Ошибка проверки
     */
    @Input()
    relevanceError;

    @Output()
    private onCheckRelevance: EventEmitter<string> = new EventEmitter<string>();

    projectVersions: VAProjectVersion[];
    selectedProjectVersion: VAProjectVersion;

    constructor(private projectVersionService: ProjectVersionService, private stateService: StateService) {
    }

    ngOnInit(): void {
        const projectVersionId = this.stateService.params['projectVersionId'];
        this.projectVersionService.getVersionsOfProject().then(projectVersions => {
            this.projectVersions = projectVersions;
            this.selectedProjectVersion = this.projectVersions.find(projectVersion => projectVersion.id === projectVersionId);
        });
    }

    /**
     * Клик на кнопку "Сравнить"
     */
    onCheckRelevanceClick() {
        if (this.selectedProjectVersion) {
            this.onCheckRelevance.emit(this.selectedProjectVersion.id);
            return;
        }
    }

}

