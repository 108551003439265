import * as urls from "../../../../../../js/workplace/urls.js";
import restangular from "restangular";
import TitleService from "../../../../../../js/workplace/services/TitleService";

const firstReplyGridCell =  require(`raw-loader!../../../../components/analysis/gridCellTemplates/firstReplyGridCell.html`);
const tagsInDialogGridCell =  require(`raw-loader!../../../../components/analysis/gridCellTemplates/tagsInDialogGridCell.html`);
const finishTimeGridCell =  require(`raw-loader!../../../../components/analysis/gridCellTemplates/finishTimeGridCell.html`);
const idealDialogCheckResultGridCell =  require(`raw-loader!../../../../components/analysis/gridCellTemplates/idealDialogCheckResultGridCell.html`);


export default class VaIdealDialogResultController {

    private baseUrl: string;
    private restAngular: restangular.IService;
    private scope: angular.IScope;
    private state: ng.ui.IStateService;
    private stateParams: ng.ui.IStateParamsService;


    /**
     * Настройки ui-grid'a
     */
    private gridOptions: uiGrid.IGridOptions;

    /**
     * Константы ui-grid'a
     */
    private gridConstants: uiGrid.IUiGridConstants;

    /**
     * API ui-grid'a
     */
    private gridApi: uiGrid.IGridApi;

    private data: any[] = [];
    private element: any;

    constructor(titleService: TitleService, restAngular: restangular.IService, scope: angular.IScope, element, state: ng.ui.IStateService,
                stateParams: ng.ui.IStateParamsService, uiGridConstants: uiGrid.IUiGridConstants) {
        titleService.setTitle();
        this.baseUrl = urls.va.ml;
        this.restAngular = restAngular;
        this.scope = scope;
        this.element = element;
        this.state = state;
        this.stateParams = stateParams;
        this.gridConstants = uiGridConstants;
        this.initGrid();
        this.loadResults();
    }

    loadResults() {
        this.restAngular.one(this.baseUrl, "autocheckResults").get().then((dialogCheckResults: any) => {
            let ar: any[] = dialogCheckResults.plain();
            this.data.push(...ar);
        });
    }

    /**
     * Ициализация грида
     */
    private initGrid(): void {
        this.gridOptions = {
            // данные, по которым строится отчет
            data: this.data,
            // доступ к grid api (для обработки предвыбранной группы)
            onRegisterApi: (gridApi) => {
                this.gridApi = gridApi;
            },
            // можно изменять размер колонок
            enableColumnResizing: true,
            // Можно фильтровать
            enableFiltering: true,
            // Достуно меню грида справа сверху в углу
            enableGridMenu: true,
            // Показыаать футер грида (с общим числом элементов)
            showGridFooter: true,
            // Быстрый просмотр (т. к. данные ее меняются динамически)
            fastWatch: true,
            // Минимальное кол-во отображаемых строк
            minRowsToShow: 5,
            // Scope-provider для доступа к методам контроллера
            appScopeProvider: this,
            // Настройки постраничного перехода (варианты для отображения в дропдауне)
            paginationPageSizes: [5, 10, 25, 50],
            // Настройки размера страницы по умолчанию
            paginationPageSize: 10,
            // Отключить вертикальный скролл (вместо этого использовать скроллинг страницы, а таблицу будем ресайзить сами)
            enableVerticalScrollbar: this.gridConstants.scrollbars.NEVER,
            // Включить горизонтальный скроллбар, когда он нужен
            enableHorizontalScrollbar: this.gridConstants.scrollbars.WHEN_NEEDED,
            // описание колонок
            columnDefs: [
                {
                    name: 'Дата/время',
                    field: 'finishTime',
                    type: 'date',
                    width: '15%',
                    cellTemplate: finishTimeGridCell
                },
                {
                    name: 'Тематика',
                    field: 'tagsInDialog',
                    width: '15%',
                    cellTemplate: tagsInDialogGridCell
                },
                {
                    name: 'Первая реплика',
                    field: 'firstReply',
                    width: '*',
                    cellTemplate: firstReplyGridCell
                },
                {
                    name: 'Результат',
                    field: 'result',
                    width: '15%',
                    cellTemplate: idealDialogCheckResultGridCell
                }
            ],
            // @ts-ignore
            selectResultClass: this.selectResultClass.bind(this)
        };
    }

    /**
     * 
     * @param checkResult
     */
    public selectResultClass(checkResult: CheckResult) {
        switch (checkResult.name) {
            case "ERROR":
            case "DIFFERENT":
                return "text-red";
            case "SAME":
            default:
                return "";
        }
    }
    
}



VaIdealDialogResultController.$inject = ["TitleService", "Restangular", "$scope", "$element", "$state", "$stateParams", "uiGridConstants"];


interface CheckResult {

    name: "SAME" | "ERROR" | "DIFFERENT";

    title: string

}