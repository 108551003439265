import {Component, EventEmitter, OnInit} from "@angular/core";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {BaseMasterComponent} from "../../base/base-master.component";
import {FileService} from "../file.service";
import {EruditeFile, EruditeFileFilter} from "../../../../models/erudite-file.model";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {VAProjectVersion} from "../../../../../data/va/VAProjectVersion";
import {ProjectVersionService} from "../../../project-version/project-version.service";
import {AppConfigService} from "../../../../service/app-config.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {SortField} from "../../../../../data/va/Common";


@Component({
    selector: 'file-master',
    template: require('./file-master.component.html'),
})
export class FileMasterComponent extends BaseMasterComponent<EruditeFile, string> implements OnInit {

    /**
     * Фильтр сверху коллекции
     */
    filter: EruditeFileFilter = new EruditeFileFilter();

    /**
     * Эмиттер события "Фильтр очищен"
     */
    filterCleared: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Версии проектов, доступные эксперту
     */
    projectVersions: VAProjectVersion[];

    /**
     * Таймзона сервера
     */
    timezone: string;

    constructor(titleService: Title,
                stateService: StateService,
                dataService: FileService,
                private projectVersionService: ProjectVersionService,
                httpClient: HttpClient,
                appConfigService: AppConfigService,
                notificationService: NotificationService) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`creationDate`), new SortField(`Название`, `fileName`));
        this.timezone = appConfigService.interfaceConfig.timezone;
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        this.projectVersions = await this.projectVersionService.findAll();
        this.projectVersions?.sort((a, b) => a.projectId > b.projectId ? 1 : -1);
    }

    /**
     * Функция для фильтрации файлов в списке
     *
     * @param searchString поисковая строка
     * @param item         файл
     * @param filter       текущий фильтр
     */
    public filterFile(searchString: string, item: EruditeFile, filter: EruditeFileFilter): boolean {
        if (searchString && (item.fileName.toString().toLowerCase().indexOf(searchString) < 0)) {
            // поиск текта
            return false;
        }
        if (filter.fromDate || filter.toDate) {
            // фильтр по дате
            if (!item.creationDate) {
                // даты у айтема нет, не подходит
                return false;
            }
            // переводим в нужный формат
            const date = (new Date(item.creationDate));
            const itemDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            if (filter.fromDate && itemDate.before(filter.fromDate)) {
                // старше минимальной даты
                return false;
            }
            if (filter.toDate && itemDate.after(filter.toDate)) {
                // моложе максимальной даты
                return false;
            }
        }
        // noinspection RedundantIfStatementJS
        if (filter.projectVersion && filter.projectVersion.id != item.projectVersionId) {
            // id версии проекта не подходит
            return false;
        }
        return true;
   }

    /**
     * Функция для проверки используется ли фильтр
     */
    public isUsingFilter(filter: EruditeFileFilter): boolean {
        return !!filter.fromDate || !!filter.toDate || !!filter.projectVersion;
    }


    /**
     * Сбросить текущие фильтры в дефолтное положение
     */
    clearFilters() {
        this.filterCleared.next();
        this.filter = new EruditeFileFilter();
    }

    /**
     * Обновить поле фильтра
     */
    updateFilter(value: any, key: string) {
        this.filter[key] = value;
        this.filter = Object.assign({}, this.filter);
    }

    /**
     * Ссылка на загрузку файла
     */
    downloadLink(item: EruditeFile) {
        return `/account/expert/file/${item.id}`;
    }

    /**
     * Удалить файл
     */
    async delete(item: EruditeFile) {
        await this.dataService.delete(item);
        this.deleteObj(item);
    }
}
