import {Component, Inject, OnInit} from "@angular/core";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ReplyView} from "../model/reply.model";
import {DialogCorrectionDTO, DialogCorrectionTypeEnum} from "../model/correction.model";
import {DialogService} from "../dialog.service";
import {ExtractedValue, ExtractedValueType} from "../model/extracted-value.model";

@Component({
    selector: 'reply-extracted-value-correction-bottom-sheet',
    template: require('./reply-extracted-value-correction-bottom-sheet.component.html'),
    styles: [require('./reply-extracted-value.component.less')]
})

export class ReplyExtractedValueCorrectionBottomSheetComponent implements OnInit {

    correction: DialogCorrectionDTO;
    isLoading: boolean = true;

    constructor(private bottomSheetRef: MatBottomSheetRef<ReplyExtractedValueCorrectionBottomSheetComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
                    reply: ReplyView,
                    extractedValue: ExtractedValue,
                    correction: DialogCorrectionDTO,
                    type: DialogCorrectionTypeEnum,
                    tagId: string
                },
                private dialogService: DialogService) {
    }

    ngOnInit(): void {
        if (this.data.correction) {
            this.correction = this.data.correction;
            this.isLoading = false;
        } else {
            this.dialogService.initCorrection(this.data.reply.reply.dialogId, this.data.reply.reply.id, this.data.type,
                this.data.extractedValue ? this.data.extractedValue.id : null)
                .then((correction => {
                    this.correction = correction;
                    if (this.data.type === DialogCorrectionTypeEnum.EXCESS_EXTRACTION) {
                        // для лишнео извлечения доп настроек не надо
                        this.correction.correction.extractedValueId = this.data.extractedValue.id;
                        this.bottomSheetRef.dismiss(this.correction);
                    }
                }))
                .finally(() => this.isLoading = false);
        }
    }

    dismiss(): void {
        this.bottomSheetRef.dismiss();
    }

    save(): void {
        this.correction.extractionAttempt.errors = new Map<string, string>();
        if (!this.correction.extractionAttempt.selectedText) {
            this.correction.extractionAttempt.errors.set("selectedText", "Необходимо выделить текст");
        }
        if (this.correction.extractionAttempt.type === ExtractedValueType.ATTRIBUTE) {
            if (!this.correction.extractionAttempt.attribute) {
                this.correction.extractionAttempt.errors.set("attribute", "Необходимо выбрать атрибут");
            }
            if (!this.correction.extractionAttempt.value && !this.correction.extractionAttempt.strValue) {
                this.correction.extractionAttempt.errors.set("value", "Необходимо выбрать значение");
            }
            if (!this.correction.extractionAttempt.extractionContext) {
                this.correction.extractionAttempt.errors.set("extractionContext", "Необходимо задать контекст извлечения");
            }
        }
        if (this.correction.extractionAttempt.type === ExtractedValueType.CONFIRMATION) {
            if (!this.correction.extractionAttempt.confirmInfoType) {
                this.correction.extractionAttempt.errors.set("confirmation-type", "Необходимо выбрать тип подтверждения");
            }
            if (!this.correction.extractionAttempt.strValue) {
                this.correction.extractionAttempt.errors.set("confirmationValueSelection", "Необходимо выбрать значение");
            }
        }
        if (this.correction.extractionAttempt.errors.size === 0) {
            this.bottomSheetRef.dismiss(this.correction);
        }
    }


}



