import {Form} from "../../io/components/va/base/base-details.component";
import * as moment from 'moment';
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

export class CompositeKey<T> {
    id: T;
    projectVersionId: string;
}

export interface DatePickerOptions {
    locale: { [key: string]: string[] | string | number };
    ranges: { [key: string]: Date[] };
}

export const DateStringFormat = "DD/MM/YYYY HH:mm:ss";

export function dateStringObject(date: Date) {
    return {dateString: moment(date).format(DateStringFormat)};
}

export interface HeaderElement {
    title: string;
    field: string;
}

export class SortField {

    title: string;
    reversedTitle?: string;
    field: string;
    alphabetical: boolean = true;
    reversed: boolean;

    constructor(title: string, field: string, alphabetical?: boolean) {
        this.title = title;
        this.field = field;
        if (alphabetical != null) {
            this.alphabetical = alphabetical;
        }
    }

    static instance(field: string, reversed: boolean): SortField {
        const instance = new SortField(``, field);
        instance.reversed = reversed;
        return instance;
    }

    static createOption(source: SortField, reversed: boolean): SortField {
        const option = new SortField(source.title, source.field, source.alphabetical);
        option.reversed = reversed;
        if (option.reversed && source.reversedTitle != null) {
            option.title = source.reversedTitle;
        }
        return option
    }

    /**
     * Спасибо массовикам-затейникам за интересные варианты названия сортировок для дат
     * "Сначала новые", "Сначала старые"
     * Ну офигеть теперь >.<, а то по иконке бы не разобрались
     *
     * @param field          навазние поля для сортировки
     */
    static date(field: string): SortField {
        const instance = new SortField(`Сначала старые`, field);
        instance.reversedTitle = `Сначала новые`;
        instance.alphabetical = false;
        return instance;
    }
}

export enum ViewTab {
    COMMON = "COMMON",
    USAGES = "USAGES"
}

export class FormObject extends Form<void> {

    key?: CompositeKey<number>;
    name?: string;
    id?: string;

    get object(): void {
        return undefined;
    }
}

export class AjaxErrors {
    exception: string;
    message: string;
    field: string;


    constructor(message: string) {
        this.message = message;
    }
}

export function matchesInterval(date: Date, start: NgbDate, finish: NgbDate): boolean {
    if (!date) {
        return false;
    }
    const dateObject = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    return (dateObject.after(start) || dateObject.equals(start)) && (dateObject.before(finish) || dateObject.equals(finish));
}

export function isEmpty(array: any[]) {
    return array == null || array?.length == 0;
}