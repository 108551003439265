/**
 * Created by semyon on 04.07.16.
 */
import {guid} from "../../../Utils";

import _SockJSClient = require("../../../external/sockjs-client/js/sockjs-fixed");
import {SockJSNamespace} from "../../../external/sockjs-client/js";
let Client: SockJSNamespace.SockJSClass = _SockJSClient as any;

const RECONNECT_TIMEOUT = 3000;

class EventListenerRegistration {
    id: string;
    fn: (event: Event) => void;
}

class Event {
    details: string;
    type: string;
    timestamp: number;
}

export default class WebSocketService {
    
    private lastEventTimestamp: number;

    private tabService: any;

    private accountId: number = null;
    private visitorId: number = null;
    private ws: any = null;

    private readonly listenMasterChanged: (isMaster: boolean) => void;
    private readonly listenWsConnectMessage: (broadcastMessage: any) => void;
    private readonly listenWsDataMessage: (broadcastMessage: any) => void;
    private readonly listeners: { [type: string]: EventListenerRegistration[] };
    private $rootScope: angular.IRootScopeService;

    constructor(TabService, $rootScope: angular.IRootScopeService) {
        this.lastEventTimestamp = new Date().getTime();
        this.tabService = TabService;
        this.$rootScope = $rootScope;

        // данные для коннекта
        this.accountId = null;
        this.visitorId = null;

        this.ws = null;
        this.listeners = {
            ALL: []
        };

        this.listenMasterChanged = (isMaster) => {
            this.websocketLog("Master changed: " + isMaster);
            if (isMaster) {
                // переподключить сокет
                this.websocketLog("Reconnect socket");
                this.closeSocket();
                this.openSocket();

            } else {
                // отключить сокет
                this.websocketLog("Close socket");
                this.closeSocket();
            }
        };

        this.listenWsConnectMessage = broadcastMessage => {
            this.websocketLog("Handle WS Connect message: " + JSON.stringify(broadcastMessage));
            const data = broadcastMessage.data;
            const accId = data.accountId ? data.accountId : this.accountId;
            const visId = data.visitorId ? data.visitorId : this.visitorId;
            this.setIds(accId, visId, false);
        };

        this.listenWsDataMessage = (broadcastMessage) => {
            this.handleMessage(broadcastMessage.data);
        };

        // подключим слушателей
        this.tabService.registerMasterChangeHandler("websocketService", this.listenMasterChanged);
        this.tabService.registerBroadcastHandler("wsconnect", this.listenWsConnectMessage);
        this.tabService.registerBroadcastHandler("wsdata", this.listenWsDataMessage);
    }

    websocketLog(message) {
/*
        const logger: any = console;
        const text = logger.style.wrap(message, "color: white; background: #015cff;");
        logger.style(text)
*/
    }

    setIds(accountId, visitorId, broadcast) {
        this.accountId = accountId;
        this.visitorId = visitorId;
        // переподключим сокет
        this.closeSocket();
        if (this.tabService.isMasterNow()) {
            this.websocketLog("This tab is master, connect socket");
            this.openSocket();
        } else {
            this.websocketLog("This tab is slave, don't connect socket");
            if (broadcast) {
                const data = {accountId: this.accountId, visitorId: this.visitorId};
                this.tabService.broadcast("wsconnect", data);
            }
        }
    }

    openSocket() {
        this.websocketLog("Open socket for account: " + this.accountId + " and visitor: " + this.visitorId);
        // варианты транспорта сообщений
        const trs = ['websocket', 'xdr-streaming', 'xhr-streaming', 'iframe-eventsource',
            'iframe-htmlfile', 'xdr-polling', 'xhr-polling', 'iframe-xhr-polling', 'jsonp-polling'];

        let socketUrl;
        if (this.accountId && this.visitorId) {
            socketUrl = '/eruditews/?type=both&accountId=' + this.accountId + "&visitorId=" + this.visitorId;
        } else if (this.accountId) {
            socketUrl = '/eruditews/?type=account&id=' + this.accountId;
        } else if (this.visitorId) {
            socketUrl = '/eruditews/?type=visitor&id=' + this.visitorId;
        } else {
            console.warn("Can't connect now - accountId and visitorId are absent");
            return;
        }

        if (this.lastEventTimestamp != null) {
            socketUrl += "&timestamp=" + this.lastEventTimestamp;
        }
        const options = {transports: trs, transportMinTimeout: 5000};
        this.ws = new Client(socketUrl, null, options);
        this.ws.onopen = this.onOpen.bind(this);
        this.ws.onmessage = this.onMessage.bind(this);
        this.ws.onclose = this.onClose.bind(this);
    }

    closeSocket() {
        this.websocketLog("Close socket");
        if (this.ws) {
            this.ws.onopen = null;
            this.ws.onmessage = null;
            this.ws.onclose = null;
            this.ws.close();
            this.ws = null;
        }
    }


    handleMessage(event: Event) {
        const eventType: string = event.type;
        this.lastEventTimestamp = event.timestamp;

        let listeners = this.listeners[eventType];
        if (listeners) {
            for (let i = 0; i < listeners.length; i++) {
                let listener = listeners[i];
                listener.fn(event);
            }
        }
        listeners = this.listeners['ALL'];

        for (let i = 0; i < listeners.length; i++) {
            let listener = listeners[i];
            listener.fn(event);
        }
    }

    subscribeOnEvents(handler: {eventType: string, fn: ((event: any) => void)}, id?: string) {
        if (!id) {
            id = guid();
        }
        const obj = handler;
        let eventType = obj.eventType;
        let fn: (event: any) => void = obj.fn;

        let eventTypes = eventType.split(",");
        for (let j = 0; j < eventTypes.length; j++) {
            const type = eventTypes[j];

            let eventListenersList = this.listeners[type];
            if (!eventListenersList) {
                eventListenersList = [];
                this.listeners[type] = eventListenersList;
            }
            eventListenersList.push({
                id: id,
                fn: fn
            });
        }

        return id;
    }

    subscribeOnAllEvents(handlerFn, id: string) {
        if (!id) {
            id = guid();
        }
        this.listeners['ALL'].push({
            id: id,
            fn: handlerFn
        });
        return id;
    }

    removeListener(id: string) {
        Object.keys(this.listeners).forEach((lstKey) => {
            const lst = this.listeners[lstKey];
            for (let i = lst.length - 1; i >= 0; i--) {
                const obj = lst[i];
                if (obj.id == id) {
                    lst.splice(i, 1);
                }
            }
        });
    }

    onOpen() {
    }

    onMessage(event) {
        event = JSON.parse(event.data);
        
        this.$rootScope.$apply(() => {
            this.handleMessage(event);

            // теперь перешлем его по остальным вкладкам
            this.tabService.broadcast("wsdata", event);
        });
    }

    onClose() {
        console.error('WebSocket connection closed.');
        this.ws = null;

        // если эта вкладка Master - делаем reconnect
        if (this.tabService.isMasterNow()) {
            this.websocketLog("I am master, try to reconnect");
            setTimeout(() => {
                this.openSocket();
            }, RECONNECT_TIMEOUT);
        } else {
            this.websocketLog("I am not master, don't try to reconnect");
        }
    }

    tryGetProjectVersionId(event, objectKey){
        let details = JSON.parse(event.details);
        let object = details[objectKey];
        return object && object.key ? object.key.projectVersionId : null
    }
}

WebSocketService.$inject = ["TabService", "$rootScope"];
