import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material";
import {NotificationButton, NotificationComponent, NotificationType} from "./notification.component";


@Injectable()
export class NotificationService {

    constructor(private snackBar: MatSnackBar) {
    }

    private notify(message: string, duration: number, type: NotificationType, buttons: NotificationButton[], data: any = null) {
        this.snackBar.openFromComponent(NotificationComponent, {
            duration: duration,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['notification-snackbar'],
            data: {
                message: message,
                type: type,
                data: data,
                dismiss: () => this.snackBar.dismiss(),
                buttons: buttons
            }
        })
    }

    /**
     * Скрыть уведомление
     */
    dismiss() {
        this.snackBar.dismiss();
    }


    /**
     * Успешное уведомление
     * @param message   сообщение
     * @param duration  продолжительность отображения
     * @param buttons   кнопки
     */
    success(message: string, duration: number = 3000, buttons?: NotificationButton[]) {
        this.notify(message, duration, NotificationType.SUCCESS, buttons);
    }

    /**
     *  уведомление
     * @param message  сообщение
     * @param duration продолжительность отображения
     * @param buttons кнопки
     */
    info(message: string, duration: number = 30000, buttons?: NotificationButton[]) {
        this.notify(message, duration, NotificationType.INFO, buttons);
    }

    /**
     * Уведомление об ошибке
     * @param message  сообщение
     * @param buttons   кнопки
     * @param duration продолжительность отображения
     */
    error(message: string, duration: number = 30000, buttons?: NotificationButton[]) {
        this.notify(message, duration, NotificationType.ERROR, buttons);
    }

    /**
     * Предупреждающее уведомление
     * @param message  сообщение
     * @param buttons   кнопки
     * @param duration продолжительность отображения
     */
    warning(message: string, duration: number = 30000, buttons?: NotificationButton[]) {
        this.notify(message, duration, NotificationType.WARNING, buttons);
    }

}