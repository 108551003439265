import {Injectable} from "@angular/core";
import {VersionedDataService} from "../base/versioned-data.service";
import {HttpClient} from "@angular/common/http";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {TranscriptionDialog} from "./transcription.model";
import {NamedEntitiesModel} from "../model/named-entities.model";


@Injectable()
export class TranscriptionService extends VersionedDataService<TranscriptionDialog, number> {

    baseUrl: string = "/account/expert/transcription";

    readonly entityClass = NamedEntitiesModel.TRANSCRIPTION_DIALOG;

    constructor(httpClient: HttpClient, webSocketService: WebSocketService, stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    /**
     * Транскрибировать диалоги c ошибкой
     */
    async retryTranscription(): Promise<void> {
        return this.httpClient.get<void>(`${this.baseUrl}/retryTranscription`).toPromise();
    }

    /**
     * Транскрибировать диалог
     * @param dialog диалог
     */
    async transcribeDialog(dialog: TranscriptionDialog): Promise<void> {
        return await this.httpClient.get<void>(`${this.baseUrl}/${dialog.key.id}/transcribe`).toPromise();
    }

    /**
     * Загрузить файл
     * @param dialogId
     * @param onAudioFileLoaded callback загрузки
     * @param onError колбек на вывод ошибок
     */
    loadFile(dialogId: string, onAudioFileLoaded: (dataUrl) => void, onError: (error) => void): void {
        this.loadAudio(`/${dialogId}/getFile`, {}, onAudioFileLoaded, onError);
    }

    /**
     * Закончить редактирование
     *
     * @param dialogId id диалога
     */
    async finishEdit(dialogId: string): Promise<void> {
        await this.httpClient.get<void>(`${this.baseUrl}/${dialogId}/finishEdit`).toPromise();
    }



}