import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {isEmpty, SortField} from "../../../../../data/va/Common";


/**
 * Компонента: поисковая строка + сортировка
 */
@Component({
    selector: 'search-bar',
    template: require('./search-bar.component.html'),
    styles: [require('./search-bar.component.less'), require('./search-bar.ng-select.less')],
    encapsulation: ViewEncapsulation.None
})
export class SearchBarComponent {

    /**
     * Поисковый запрос
     */
    @Input()
    searchQuery: string;

    /**
     * Поля сортировки
     */
    sortFields: SortField[];

    /**
     * Выбранная сортировка
     */
    selectedSort: SortField;

    /**
     * Включена ли сортировка
     */
    @Input()
    sortEnabled = false;

    /**
     * Поле для сортировки по-умолчанию
     */
    @Input()
    defaultSortField: SortField = null;

    /**
     * Инициализация списка полей сортировки и выбранной сортировки
     * @param fields
     */
    @Input()
    set fields(fields: SortField[]) {
        if (!this.sortEnabled) {
            return;
        }
        if (isEmpty(fields)) {
            throw new Error(`Sort fields is empty, unable to initialize search-bar.component.ts`)
        }
        this.sortFields = [];
        // создаём опции для сортировки
        const reversedFlags = [false, true];
        for (let field of fields) {
            reversedFlags.forEach(flag => this.sortFields.push(SortField.createOption(field, flag)));
        }
        // если задано поле для дефолтной сортировки ориентируемся на него
        if (this.defaultSortField != null) {
            this.selectedSort = this.sortFields.find(object => object.field == this.defaultSortField.field
                && object.reversed == !!this.defaultSortField.reversed);
        } else {
            // если нет, то берём первую reversed
            this.selectedSort = this.sortFields.find(object => object.reversed);
        }
        this.handleSortSelection();
    }

    /**
     * Событие выбора сортировки
     */
    @Output()
    private sortChanged = new EventEmitter<SortField>();

    @Input()
    private clearSearchStringChange: EventEmitter<any>;

    /**
     * Событие изменения строки поиска
     */
    @Output()
    private searchQueryChange = new EventEmitter<string>();

    constructor() {

    }

    ngOnInit() {
        if (this.clearSearchStringChange != null) {
            this.clearSearchStringChange.subscribe(() => {
                this.searchQuery = "";
            });
        }
    }

    /**
     * Отправка события выбора поля сортировки
     */
    handleSortSelection() {
        this.sortChanged.emit(this.selectedSort);
    }

    /**
     * Отправка события изменения текста в строке поиска
     */
    onSearchQueryChange() {
        this.searchQueryChange.emit(this.searchQuery);
    }

    /**
     * Проверка: выбрано ли в списке сортировки это поле
     * @param item
     */
    isSelected(item: SortField): boolean {
        return this.sortFields.indexOf(this.selectedSort) == this.sortFields.indexOf(item);
    }
}
