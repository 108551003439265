import {Component, OnDestroy, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {BaseMasterComponent} from "../../va/base/base-master.component";
import {Project} from "../project.model";
import {ProjectService} from "../project.service";
import WebSocketService from "../../../../services/WebSocketService";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {SortField} from "../../../../data/va/Common";


@Component({
    selector: 'project',
    template: require('./project-master.component.html'),
    styles: [require('./project-master.component.less')]
})
export class ProjectMasterComponent extends BaseMasterComponent<Project, number> implements OnInit, OnDestroy {
    private subscriptionId: string;

    constructor(protected titleService: Title,
                protected stateService: StateService,
                protected dataService: ProjectService,
                protected httpClient: HttpClient,
                notificationService: NotificationService,
                private webSocketService: WebSocketService) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`d`), new SortField(`Название`, `name`));
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        // Подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_VERSIONING_PROGRESS_FINISH",
            fn: (event) => {
                switch (event.type) {
                    case "VA_VERSIONING_PROGRESS_FINISH":
                        this.loadList();
                        break;
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    getChannels(channels) {
        delete channels.assistant_voice;
        return channels;
    }
}
