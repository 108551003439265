

module.exports = [
    {
        state: "push",
        options: {
            url: "/push",
            parent: "authorized"
        }
    },

    // отправка push уведомлений на msisdn
    {
        state: "push.main",
        options: {
            title: "PUSH-рассылка",
            url: "/push",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/push/push.html",
                    controller: "PushController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                details: "push.main.result"
            }
        }
    },
    {
        state: "push.main.result",
        options: {
            title: "PUSH-рассылка | Результат",
            url: "/:messageID",
            views: {
                "details@push.main": {
                    templateUrl: "/pages/workplace/push/pushStatusDetail.html",
                    controller: "PushStatusDetailController",
                    controllerAs: "ctrl"
                }
            }
        }
    },

    // просмотр статуса доставки push уведомлений
    {
        state: "push.status",
        options: {
            title: "Статус PUSH-рассылки",
            url: "/status",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/push/status.html",
                    controller: "PushStatusController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                details: "push.status.result"
            }
        }
    },
    {
        state: "push.status.result",
        options: {
            title: "Статус PUSH-рассылки | Результат",
            url: "/:messageID",
            views: {
                "details@push.status": {
                    templateUrl: "/pages/workplace/push/pushStatusDetail.html",
                    controller: "PushStatusDetailController",
                    controllerAs: "ctrl"
                }
            }
        }
    }

];
