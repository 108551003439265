import {StepLabelTypeName, VaScriptEdge, VaScriptNode} from "../../../../data/va/Script";
import EruditeLink from "../link-view/EruditeLink";
import {isTechnicalNode} from "../ScriptBuilderUtils";
import {EventEmitter} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import * as urls from '../../../../../../js/workplace/urls';
import ScriptChangesController from "./ScriptChangesController";
import {EruditeElement} from "../element-view/EruditeElement";
import {VaScriptCondition} from "../../../../data/va/VaScriptCondition";

/**
 * Результат валидации
 */
export class ValidationResult {
    success: boolean;
    message?: string;

    constructor(success: boolean, message: string) {
        this.success = success;
        this.message = message;
    }
}

/**
 * Контроллер для валидации действий в построителе сценариев
 */
export default class ValidationController {

    constructor(private http: HttpClient,
                private onDisplaySnackBar: EventEmitter<{ message: string; styleClass: string; duration?: number }>,
                private onDismissSnackBar: EventEmitter<void>,
                private changesController: ScriptChangesController) {
    }

    public static readonly SNACK_DURATION = 3000;

    /**
     * Удалении узла с валидацией
     * @param viewModel узел
     */
    public onNodeDelete(viewModel: EruditeElement) {
        if (viewModel.node.stepLabel.type.name != StepLabelTypeName.EXIT) {
            // удаление без валидации
            this.deleteNode(viewModel);
            return;
        }

        // Если это выход - надо проверить не используется ли он где-то перед удалением
        this.http.post<boolean>(`${urls.va.procedure}isExitUsed`, viewModel.node).toPromise()
            .then((used: boolean) => {
                if (used) {
                    // Отправляем сообщение для показа снэкбара
                    this.onDisplaySnackBar.emit({
                        message: `Нельзя удалить элемент \"Выход ${viewModel.node.stepLabel.name}\": он используется в другом сценарии или процедуре`,
                        styleClass: "warning",
                        duration: ValidationController.SNACK_DURATION
                    });
                } else {
                    this.deleteNode(viewModel);
                }
            })
    }

    /**
     * Удаление элемента
     */
    private deleteNode(viewModel: EruditeElement) {
        this.changesController.initBatchChange();
        viewModel.remove();
        this.changesController.storeBatchChange();
    }

    /**
     * Валидация при добавлении нового ребра
     * @param viewModel ребро
     */
    public onLinkCreated(viewModel: EruditeLink) {
        const sourceNode: VaScriptNode = viewModel.sourceNode;
        const targetNode: VaScriptNode = viewModel.targetNode;
        // Если ребро в технический узел или такое ребро уже добавлено, то удаляем его
        if (isTechnicalNode(targetNode) || this.hasSameEdge(sourceNode, targetNode, viewModel.edgesFromSourceNode)) {
            viewModel.setValidLink(false);
        }
    }

    /**
     * Валидация при обновлении условий в ребре
     * @param viewModel ребро
     * @param closeDialog
     */
    public onConditionUpdate(viewModel: EruditeLink, closeDialog: () => void) {
        this.changesController.initBatchChange();
        try {
            const sourceNode: VaScriptNode = viewModel.sourceElement.node;
            // обернем в класс, чтобы почистить вспомогательные поля
            let conditions = viewModel.scriptEdge.conditions.map(condition => new VaScriptCondition(condition));
            const edgesFromSourceNode: VaScriptEdge[] = viewModel.edgesFromSourceNode;
            const validationResult = ValidationController.validateConditionViolations(viewModel.scriptEdge, sourceNode, edgesFromSourceNode, conditions);
            if (validationResult.success) {
                // перерисуем label
                viewModel.conditions = conditions;
                viewModel.prepareEdgeLabel();
                this.onDismissSnackBar.emit();
                closeDialog();
            } else {
                this.onDisplaySnackBar.emit({
                    message: validationResult.message,
                    styleClass: "warning",
                    duration: ValidationController.SNACK_DURATION
                });
                if (viewModel.edgesFromSourceNode.length > 1 && viewModel.scriptEdge.conditions.length == 0) {
                    viewModel.remove();
                    closeDialog();
                }
            }
        } finally {
            this.changesController.storeBatchChange();
        }
    }

    /**
     * Можно ли от этого элемента провести link
     * @param node          узел
     * @param outgoingEdges исходящие рёбра
     */
    public static isOutgoingEdgeAllowed(node: VaScriptNode, outgoingEdges: VaScriptEdge[]): boolean {
        const stepLabelType: StepLabelTypeName = node.stepLabel.type.name;
        switch (stepLabelType) {
            case StepLabelTypeName.START:
            case StepLabelTypeName.ENTER:
            case StepLabelTypeName.ATTRIBUTE_REQUEST:
            case StepLabelTypeName.ANSWER_RESPONSE:
                // От атрибута/старта/входа/ответа сколько угодно
                return true;
            case StepLabelTypeName.PROCEDURE:
                // От процедуры можно провести рёбра только в том случае, если у неё есть выходы и они использованы не все
                return node.entity.options && node.entity.options.length > outgoingEdges.length;
            case StepLabelTypeName.TO_SUPPORT:
            case StepLabelTypeName.TO_TAG:
            case StepLabelTypeName.EXIT:
                // Нельзя проводить рёбра от перенаправления на оператора, перехода в другую тематику и выхода из процедуры
                return false;
            default:
                throw new Error(`Unrecognized StepLabelTypeName ${stepLabelType}`);
        }
    }

    /**
     * Есть ли уже такое ребро?
     * @param sourceNode    узел "от"
     * @param targetNode    узел "к"
     * @param currentEdges  текущие ребра
     */
    // noinspection JSMethodCanBeStatic
    private hasSameEdge(sourceNode: VaScriptNode, targetNode: VaScriptNode, currentEdges: VaScriptEdge[]) {
        let fromNodeId = sourceNode.key.id;
        let toNodeId = targetNode.key.id;
        return currentEdges && currentEdges.some(edge => edge.fromNodeId == fromNodeId && edge.toNodeId == toNodeId);
    }

    /**
     * Нет ли полного совпадения условий на ребре
     * @param edge            ребро
     * @param node            узел, из которого ребро
     * @param outgoingEdges   исходящие рёбра этого узла
     * @param conditions      условия
     */
    public static validateConditionViolations(edge: VaScriptEdge, node: VaScriptNode, outgoingEdges: VaScriptEdge[], conditions: VaScriptCondition[]) {
        // на ребре может быть только 1 условие на значение выхода из процедуры
        if (conditions.filter(condition => condition.isProcedureCondition()).length > 1) {
            return new ValidationResult(false, `Можно использовать не более 1 значения выхода процедуры на ребре`);
        }
        let result: ValidationResult = new ValidationResult(true, '');
        // При добавлении нового ребра нужно проверить не является ли список его условий дубликатом уже существующего
        // Кроме того, проверим верность условий для одинаковых сущностей с флагом и без флага except
        outgoingEdges.filter(outgoingEdge => outgoingEdge.toNodeId != edge.toNodeId)
            .forEach(storedEdge => {
                // Список сохранённых условий на ребре из сценария
                let storedConditions: VaScriptCondition[] = storedEdge.conditions;
                if (!storedConditions.length && !edge.conditions.length) {
                    result = new ValidationResult(false, `Элемент может иметь только один переход по умолчанию`)
                    return;
                }
                // В этот список будем складывать условия, которые полностью совпадают с теми, что мы добавляем
                let equalConditions: VaScriptCondition[] = [];
                // Бежим по списку условий в добавляемом ребре
                edge.conditions.forEach(condition => {
                    // Ищем совпадения по идентификатору и типу сущности
                    let matchedCondition = storedConditions.find(storedCondition => condition.idAndTypeEquals(storedCondition));
                    // Если такое условие нашлось и оно совпадает либо пересекается с сохранённым, то добавим его в список
                    if (matchedCondition && (condition.equals(matchedCondition) || condition.hasValueViolations(matchedCondition))) {
                        equalConditions.push(matchedCondition);
                    }
                });
                // Если размеры списков добавляемых и одинаковых (либо пересекающихся) условий равны, то пользователь добавляет конфликтные условия
                if (edge.conditions.length > 0 && equalConditions.length == edge.conditions.length) {
                    result = new ValidationResult(false, "Добавляемые условия перехода конфликтуют с уже существующими на других ребрах")
                }
            });
        return result;
    }

}
