import {NgModule} from "@angular/core";
import {EruditeNgbRangeDatepickerComponent} from "./erudite-ngb-range-datepicker.component";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        NgbDatepickerModule,
        CommonModule
    ],
    declarations: [
        EruditeNgbRangeDatepickerComponent
    ],
    exports: [
        EruditeNgbRangeDatepickerComponent
    ],
    entryComponents: [
        EruditeNgbRangeDatepickerComponent
    ]
})

export class EruditeNgbDatepickerModule {

}