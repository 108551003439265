/**
 * Created by semyon on 20.07.17.
 */

interface StatEvent {
    parameters: any,
    section: string,
    subSection: string,
    destLink: string
}

export default class StatisticsService {

    private rootScope: any;
    private state: any;
    private restangular: any;

    static $inject = ["$rootScope", "$state", "Restangular", "$transitions"];
    private transitions: any;

    /**
     * Таймстемп последнего действия юзера на вкладке
     */
    lastUserActionTime: number;

    constructor(rootScope, state, restangular, transitions) {
        this.rootScope = rootScope;
        this.state = state;
        this.restangular = restangular;
        this.transitions = transitions;

        // таймстемп последнего действия юзера на вкладке
        this.updateLastUserActionTime();
        document.onclick = () => this.updateLastUserActionTime();
        document.onmousemove = () => this.updateLastUserActionTime();
        document.onkeypress = () => this.updateLastUserActionTime();
    }

    /**
     * Апдейт таймстемпа последнего действия юзера на вкладке
     */
    updateLastUserActionTime(timestamp?: number) {
        if (timestamp) {
            // пришел таймстемп действия с другой вкладки
            if (timestamp > this.lastUserActionTime) {
                // и он не раньше, чем на текущей, апдейтим
                this.lastUserActionTime = timestamp;
            }
        } else {
            // апдейтим таймстемп на текущее время
            this.lastUserActionTime = new Date().getTime();
        }
    }

    start() {
        this.transitions.onSuccess({}, (transition) => {
            let newState = transition.$to();
            let fromState = transition.$from();
            let toParams = Object.assign({}, transition.params('to'));
            let fromParams = Object.assign({}, transition.params('from'));
            // запишем статистическое событие только при успешном переходе на страницу
            this.registerOpenEvent(newState, toParams);
        });
    }

    registerOpenEvent(toState, toParams, details?: any) {
        const that = this;
        if (toState.hasOwnProperty("data") && toState.data && toState.data.hasOwnProperty("meta")) {
            const metaData = toState.data.meta;
            const parameters = metaData.parameters;
            const stateName = toState.name;

            let statEvent:StatEvent = {
                parameters: new Map<string, string>(),
                section: null,
                subSection: null,
                destLink: null
            };
            
            if (details) {
                // кладем доп-детали
                Object.entries(details).forEach(([key, value]) => statEvent.parameters.set(key, value.toString()));
            }

            let paramArray = [];

            let sectionFn = metaData.sectionFunction;
            let subSectionFn = metaData.subSectionFunction;

            // пройдёмся по списку параметров и достанем
            for (let parameterName of parameters) {
                const parameterValue = toParams[parameterName];
                if (parameterValue) {
                    statEvent.parameters.set(parameterName, parameterValue);
                }
                paramArray.push(parameterValue);
            }

            // получим относительный URL страницы, на которую переходил юзер
            let linkURL = that.state.href(stateName, statEvent.parameters, {absolute: false});
            if (linkURL.startsWith("#")) {
                linkURL = linkURL.substring(1);
            }


            // получим название страницы с помощью функции
            statEvent.section = sectionFn(paramArray);
            statEvent.subSection = subSectionFn(paramArray);
            statEvent.destLink = linkURL;

            that.restangular.one("/account/journal/click").post("", statEvent).then(function (data) {

            }, function (error) {

            });
        }
    }

    registerSearchEvent(eventData) {
        const that = this;
        that.restangular.one("/account/journal/search").post("", eventData).then(function (data) {

        }, function (error) {

        });
    }


}
