import * as moment from 'moment';
import restangular from "restangular";
import * as urls from "../../../../js/workplace/urls.js";
import {dateStringObject} from "../../data/va/Common";
import {DialogFilterParam, FilterForm, SelectionModalMode, SwitchStringStringArrayForm} from "./Analysis";
import {subscribeToPushNotifications} from "../../io/components/common/snackbar/notification/long-operation/push-notification.service";

// noinspection TypeScriptUMDGlobal
export default class VaAnalysisFilterController {

    static $inject = ["Restangular", "$scope", "$state", "$stateParams", "TitleService", "$uibModal"];
    public baseUrl: string = `${urls.va.analysis}`;
    private restAngular: restangular.IService;
    private $scope: ng.IScope;
    private $stateParams: ng.ui.IStateParamsService;
    private $state: ng.ui.IStateService;
    projectVersionId: string;
    jsonFilter: string;
    public object: FilterForm;
    private appliedFilter: DialogFilterParam;
    private $uibModal: any;

    constructor(Restangular, $scope, $state, $stateParams, TitleService, $uibModal) {
        // подписка на пуш-уведомления через снекбар
        subscribeToPushNotifications($state);
        TitleService.setTitle();
        this.restAngular = Restangular;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$uibModal = $uibModal;

        this.projectVersionId = $stateParams['projectVersionId'];
        this.jsonFilter = $state.params["filter"];
        // если есть - то берём объект из statе-а
        const object = this.objFromJson(this.jsonFilter);
        // Если в state есть объект, то берём его. Если нет, то лепим новый
        if (object != null) {
            object.projectVersionId = this.projectVersionId;
            this.object = object;
        } else {
            this.object = {
                date: {
                    range: {
                        // minus week from now
                        startDate: moment().subtract(7, 'days').startOf("day"),
                        // now
                        endDate: moment().endOf("day"),
                    },
                    enabled: true
                },
                identifier: {value: null, enabled: true},
                projectVersionId: this.projectVersionId,
                userRatings: {value: [], enabled: false},
                expertRatings: {value: [], enabled: false},
                superExpertRatings: {value: [], enabled: false},
                expertIds: {value: [], enabled: false},
                checkStates: {value: [], enabled: false},
                autocheck: {value: true, enabled: false},
                correctionTypes: {value: [], enabled: false},
                redirectedToSupport: {value: true, enabled: false},
                prompter: {value: true, enabled: false},
                withoutTag: {value: true, enabled: false},
                dialogTypes: {value: [], enabled: false},
                successStates: {value: [], enabled: false},
                userKeyWords: {value: "", types: [], enabled: false},
                channels: {value: [], enabled: false},
                viewType: "LIST",
                sortParams: {field: 'DATE', order: 'DESC', enabled: true},
                pageConfig: {pageNumber: 0, pageSize: 2000},
                randomizer: {value: 10, mode: "PERCENT", seed: 0, enabled: false},
                tagProcessMode: {value: "PROCESSED_ONLY", enabled: false},
                selectionIds: {value: [], enabled: false},
                recognitionStatuses: {noInput: false, noMatch: false, enabled: false},
                sentiments: {value: [], enabled: false},
            };
        }
        // Если изменяются параметры объекта, то
        this.$scope.$watch('ctrl.object', ((newValue: FilterForm, oldValue: FilterForm) => {
            if (newValue.randomizer.seed == oldValue.randomizer.seed) {
                this.object.randomizer.seed = Math.floor(Math.random() * 1000);
            }
        }), true);
    }

    /**
     * Проверить - валиден ли выбранный временной интервал
     */
    get isValidIntervalSelected(): boolean {
        return this.object.date.range.startDate == null && this.object.date.range.endDate == null
            || this.object.date.range.startDate != null && this.object.date.range.endDate != null
    }


    /**
     * Конвертация объекта фильтра в json
     */
    objFromJson(jsonObject: string): FilterForm {
        try {
            const obj: FilterForm = JSON.parse(jsonObject);
            obj.date.range.startDate = moment(obj.date.range.startDate);
            obj.date.range.endDate = moment(obj.date.range.endDate);
            obj.restored = true;
            return obj;
        } catch (e) {
            return null;
        }
    }

    /**
     * Получение текущего фильтра в JSON-виде, чтобы положить в state
     */
    objAsJson(): string {
        // @ts-ignore
        const filter = angular.copy(this.object);
        if (this.object.date.range.startDate && this.object.date.range.endDate) {
            filter.date.range.startDate = this.object.date.range.startDate.format();
            filter.date.range.endDate = this.object.date.range.endDate.format();
        }
        return JSON.stringify(filter);
    }

    /**
     * Получение фильтра для выполнения запроса на сервер
     */
    getFilter(): DialogFilterParam {
        if (this.appliedFilter == undefined) {
            return VaAnalysisFilterController.convertFilterFormToParam(this.object);
        }
        return this.appliedFilter;
    }

    /**
     * Сконвертировать объект формы фильтра в объект параметров
     */
    private static convertFilterFormToParam(filterObject: FilterForm) {
        const filter = new DialogFilterParam();
        for (let key in filterObject) {
            if (!filterObject.hasOwnProperty(key)) {
                continue;
            }
            const value = VaAnalysisFilterController.getValidValue(filterObject[key]) as any;

            if (key == "viewType" || key == "projectVersionId") {
                // тип выдачи или настройки для постраничной выдачи
                filter[key] = value;
            } else if (key == "pageConfig") {
                // тут объект - копируем его
                // @ts-ignore
                filter[key] = angular.copy(value);
            } else if (key == "date") {
                if (value.enabled && value.range.startDate && value.range.endDate) {
                    // дату в формате unix
                    filter.fromDate = dateStringObject(value.range.startDate);
                    filter.toDate = dateStringObject(value.range.endDate);
                }
            } else if (value.enabled) {
                // если фильтр включён
                if (key == "userKeyWords") {
                    // берем значение, а так же keyword sources
                    filter[key] = VaAnalysisFilterController.getValidValue(value.value);
                    filter["keywordsSources"] = (value as SwitchStringStringArrayForm).types;
                } else if (key == "sortParams") {
                    filter[key] = value;
                } else if (key == "randomizer") {
                    // @ts-ignore
                    let randomizer = angular.copy(filterObject.randomizer);
                    if (value && value.value != null) {
                        randomizer.value = randomizer.mode == 'UNITS' ? value.value : (value.value / 100);
                        filter[key] = randomizer;
                    } else {
                        // тут лежит что-то странное, положим дефолтное значение
                        filterObject.randomizer.value = 10;
                    }
                } else if (key == 'redirectedToSupport' || key == 'withoutTag' || key == 'autocheck') {
                    // если отправка к оператору и Тематика не определена отключены, должны использовать false в фильтре
                    filter[key] = value.value;
                } else if (key == 'recognitionStatuses') {
                    // статусы распознавания
                    filter[key] = { noInput: value.noInput, noMatch: value.noMatch };
                } else {
                    // у остальных параметров берём значение
                    filter[key] = VaAnalysisFilterController.getValidValue(value.value);
                }

            }
        }
        return filter;
    }

    /**
     * используется, например, для того чтобы пустые значения перекинуть в null
     *
     */
    static getValidValue(value: any): any {
        if (!value) {
            return null;
        }
        return value;
    }

    /**
     * Положить текущий объект фильтр в state
     */
    pushFilterToState(tagIdForSubtreeFiltering: number): void {
        this.$state.go('robot.analysis.data', {
            filter: this.objAsJson(),
            tagIdForSubtreeFiltering: tagIdForSubtreeFiltering,
            singleTagIdForFiltering: null
        });
    }

    /**
     * Обработчик кнопки применить фильтр
     */
    applyFilter(): void {
        // сбрасываем текущий номер страницы
        this.object.pageConfig.pageNumber = 1;
        // переходим в состояние
        this.$state.go('robot.analysis.data', {
            filter: this.objAsJson(),
            tagIdForSubtreeFiltering: null,
            singleTagIdForFiltering: null
        }, {notify: true, reload: true});
        // запоминаем примененный фильтр
        this.appliedFilter = VaAnalysisFilterController.convertFilterFormToParam(this.object);
    }


    /**
     * Открыть модальное окно с сохранением/удалением выборок
     */
    openSelectionModal(mode: SelectionModalMode) {
        // для выборки  используем не текущий
        this.$scope.$broadcast("VaAnalysisFilterComponent::openSelectionModal", {mode: mode, filter: this.getFilter()});
    }
}
