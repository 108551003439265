import {CompositeKey} from "./Common";
import {ValueOption} from "./Extractor";
import {FormulationComponent, SourcesKey} from "../../io/components/formulation/formulation.component";
import {VaTag} from "./Tag";
import {VaAttribute} from "./Attribute";
import {VASimpleModuleAnswer} from "./SimpleModuleAnswer";
import {VersionedEntity} from "../../io/components/va/base/versioned-data.service";
import {Macro} from "../../io/components/common/macro/object/Macro";
import {EditableObject} from "../../io/components/common/editable-list/editable-list.model";
import {EruditeFile} from "../../io/models/erudite-file.model";
import {Valued} from "./Valued";

export class WithFormulation implements VersionedEntity<number> {
    key: CompositeKey<number>;
    formulations?: Formulation[];
    expertId?: number;
    d?: Date;
    deleted?: boolean;
    expert?: any;
    taggedInfo: VaTag[];
    existAllAudioRecordFiles: boolean;


    constructor() {
        this.formulations = [];
        this.existAllAudioRecordFiles = true;
    }

    setExistAllAudioRecordFiles(): void {
        if (!this.formulations) {
            this.existAllAudioRecordFiles = true;
            return;
        }
        this.existAllAudioRecordFiles = this.formulations.filter(f => f.existAllAudioRecordFiles === false).length === 0;
    }

    /**
     * Найти в текстах формулировок или в названии объекта совпадения с поисковой строкой
     * @param objectName    имя объекта
     * @param searchQuery   поисковый запрос
     */
    textSearch(objectName: string, searchQuery: string): boolean {
        // из формулировок и текстов по каналам делаем просто lowercase-полотно
        const texts = this?.formulations?.map(formulation => formulation.channelTexts)
            .reduce((previous, current) => previous.concat(current), [])
            .map(channelText => channelText.text)
            .join(" ")
            .toLowerCase();
        // если полотно есть - ищем в нём, если нет - значит объект нам не подходит
        const textMatch = objectName?.toLowerCase().indexOf(searchQuery) >= 0 || texts?.indexOf(searchQuery) >= 0;
        // ищем эксперта
        const expertMatch = this.expert?.fullName?.toLowerCase().indexOf(searchQuery) >= 0;
        // или группу
        const tagMatch = this.taggedInfo?.some(tag => tag.text.toLowerCase().indexOf(searchQuery) >= 0);

        return textMatch || expertMatch || tagMatch;
    }
}

export class Formulation extends EditableObject {
    key?: CompositeKey<number>;
    entityId?: number;
    deleted?: boolean;
    index?: number;
    name?: string;
    routeToOperatorImmediately?: boolean;
    channelTexts: ChannelText[] = [];
    includeIntoRotation?: boolean;
    existAllAudioRecordFiles?: boolean = true;

    /**
     * Выбранный канал
     */
    selectedChannel: ValueOption;

    constructor(object?: any) {
        super();
        if (!object) {
            return;
        }
        this.key = object.key;
        this.entityId = object.entityId;
        this.deleted = object.deleted;
        this.index = object.index;
        this.name = object.name;
        this.routeToOperatorImmediately = object.routeToOperatorImmediately;
        this.channelTexts = object.channelTexts;
        this.includeIntoRotation = object.includeIntoRotation;
        this.setExistAllAudioRecordFiles();
    }

    setExistAllAudioRecordFiles(): void {
        if (!this.channelTexts) {
            this.existAllAudioRecordFiles = true;
            return;
        }
        let existAllAudioRecordFiles = true;
        this.channelTexts
            .filter(channelText => channelText.macros)
            .forEach(channelText => {
                if (existAllAudioRecordFiles) {
                    existAllAudioRecordFiles = channelText.macros
                        .filter(macro => macro.audioRecord && !macro.audioRecord.fileId).length === 0;
                }
            });
        this.existAllAudioRecordFiles = existAllAudioRecordFiles;
    }

    /**
     * Найти тексты для указанного канала
     */
    textsByChannel(channel: ValueOption): ChannelText[] {
        return this.channelTexts ? this.channelTexts.filter(text => text.channelValueOptionId === channel.key.id) : [];
    }

    /**
     * Найти текст для голоса или дефолтного канала
     */
    getByApiKey(apiKey: string): ChannelText[] {
        let texts = this.channelTexts.filter(channelText => channelText.channelValueOption.apiKey === apiKey && !channelText.deleted);
        if (texts.length == 0) {
            return this.getDefaultChannelTexts();
        }
        return texts;
    }

    /**
     * Тексты ответа для дефолтного канала
     */
    getDefaultChannelTexts(): ChannelText[] {
        let texts = this.channelTexts ? this.channelTexts.filter(channelText => channelText.channelValueOption.isDefault) : null;
        if (texts) {
            return texts;
        }
        return this.channelTexts.filter(channelText => !channelText.channelValueOption.deleted);
    }

    /**
     * Тексты по выбранному каналу
     */
    getSelectedChannelTexts(): ChannelText[] {
        return this.textsByChannel(this.selectedChannel);
    }

    objectToTitle(sources: Map<string, any>): String {
        if (this.name) {
            return this.name;
        }
        const channelTexts = this.getDefaultChannelTexts();
        return channelTexts && channelTexts.length > 0 && channelTexts[0].text
            ? channelTexts[0].text.replace(/\${.*?}/g, '') : "";
    }


    getIcon(): string {
        return this.existAllAudioRecordFiles ? "<i class=\"fa fa-info-circle\"></i>" :
            "<i class=\"fa fa-exclamation-circle audio-warning\" aria-hidden=\"true\" " +
            "data-toggle=\"tooltip\" title=\"Нет всех аудиофайлов\" " +
            "style=\"color: #C42E2E;\"></i>";
    }

    beforeEdit(sources: Map<string, any>): void {
        this.beforeItemInteraction(sources, true);
    }

    beforeCreate(sources: Map<string, any>): void {
        this.beforeItemInteraction(sources, false);
    }

    /**
     * Подготовка формулировки перед созданием/редактированием
     * @param sources
     * @param edit
     */
    private beforeItemInteraction(sources: Map<string, any>, edit: boolean) {
        //показываем форму для дефолтного канала
        this.selectedChannel = sources.get(SourcesKey.DEFAULT_CHANNEL);

        const entity = sources.get(SourcesKey.ENTITY) as WithFormulation;
        const entityType = sources.get(SourcesKey.ENTITY_TYPE) as WithFormulationType;

        if (!edit) {
            // используется ли перенаправление в ответе
            this.routeToOperatorImmediately = FormulationComponent.isRerouteAllowed(entity, entityType);
            this.includeIntoRotation = true;
        }
        // создадим нужное количество текстов
        this.prepareChannelTexts(entity, entityType);
    }

    canDoAction(isDelete: boolean, sources: Map<string, any>): boolean {
        if (sources.get(SourcesKey.ENTITY_TYPE) == WithFormulationType.ATTRIBUTE) {
            // атрибуты можно редактировать всегда
            return true;
        }
        //если формулировок больше 1, то можно делать, что угодно
        if (sources.get(SourcesKey.ENTITY).formulations.length > 1) {
            return true;
        }
        //если формулировка одна, то ее нельзя удалить
        return !isDelete;
    }

    afterAction(isDelete: boolean, sources: Map<string, any>): void {
        const entity = sources.get(SourcesKey.ENTITY) as WithFormulation;
        const entityType = sources.get(SourcesKey.ENTITY_TYPE) as WithFormulationType;
        // После работы с формулировкой, надо обработать список текстов
        // Текстовок должно быть столько же, сколько requestAmount (настраивается только у атрибута)
        if (entityType === WithFormulationType.ATTRIBUTE) {
            this.prepareTexts(entity, entityType);
        } else {
            let allowEmptyTexts = this.allowEmptyTexts(entity, entityType);
            this.channelTexts = this.channelTexts.filter(text => {
                // уберем все несимвольные знаки и склеим результат обратно, чтобы посмотреть есть ли текст в поле
                return text.text.trim().split(new RegExp("[\n\t\s\r]")).join("").length > 0 || allowEmptyTexts;
            })
        }
        this.setExistAllAudioRecordFiles();
    }

    /**
     * Разрешено держать channelText с пустым текстом
     */
    allowEmptyTexts(entity: WithFormulation, entityType: WithFormulationType): boolean {
        if (entityType === WithFormulationType.SMA) {
            return $.parseJSON((entity as VASimpleModuleAnswer).vaAct.emptyText);
        }

        return this.channelTexts.some(text => text.channelValueOption.isDefault && ChannelText.hasAttachments(text));
    }

    validateForm(currentIndex: number, sources: Map<string, any>): Map<string, string> {
        let validatorErrors = new Map<string, string>();

        const entity = sources.get(SourcesKey.ENTITY) as WithFormulation;
        const entityType = sources.get(SourcesKey.ENTITY_TYPE) as WithFormulationType;
        const channels = sources.get(SourcesKey.AVAILABLE_CHANNELS) as ValueOption[];
        const defaultChannel = sources.get(SourcesKey.DEFAULT_CHANNEL) as ValueOption;

        this.validateDefaultChannelTexts(entity, entityType, defaultChannel, validatorErrors);
        this.validateDuplicateChannelTexts(entity, currentIndex, validatorErrors);

        let voiceChannelPresent = channels.some(valueOption => valueOption.apiKey == Valued.VOICE_API_KEY || valueOption.apiKey == Valued.ASSISTANT_VOICE_API_KEY);

        // проверяем, что если есть голосовой канал без текста и есть вложение у текстового канала,
        // но при этом нет текста, то выдаем ошибку, что должен быть хоть где-то задан текст
        if (voiceChannelPresent) {
            this.channelTexts.forEach(text => {
                if (text.channelValueOption.isDefault && ChannelText.hasAttachments(text) && !ChannelText.hasText(text)) {
                    let voiceText = this.channelTexts.find(text => text.channelValueOption.apiKey == Valued.VOICE_API_KEY || text.channelValueOption.apiKey == Valued.ASSISTANT_VOICE_API_KEY);
                    if (voiceText?.text?.trim()?.length > 0) {
                        return;
                    }
                    validatorErrors.set("channel", `Необходимо задать текст формулировки для голосового канала 
                    или канала по-умолчанию`);
                }
            });
        }

        if (sources.get(SourcesKey.ENTITY_TYPE) === WithFormulationType.SMA && !this.includeIntoRotation) {
            this.validateIncludeIntoRotation(currentIndex, entity, validatorErrors);
        }
        this.processName(entity, currentIndex, validatorErrors);
        return validatorErrors;
    }

    /**
     * Валидация текстов для дефолтного канала
     */
    validateDefaultChannelTexts(entity: WithFormulation, entityType: WithFormulationType, defaultChannel: ValueOption, errors: Map<string, string>): void {
        // текст для дефолтного канала должен быть
        const defaultChannelTexts = this.textsByChannel(defaultChannel);
        const storedFormulations = entity.formulations.filter(formulation => this.isNotCurrent(formulation));
        if (defaultChannelTexts.length === 0) {
            errors.set("channel", "Не задан ответ для дефолтного канала " + defaultChannel.title);
        } else if (this.isSeveralDefaultChannelTextsEmpty(defaultChannel, storedFormulations)) {
            errors.set("formulation", "Пустая формулировка уже создана");
        } else {
            let allowEmptyTexts = this.allowEmptyTexts(entity, entityType);
            // ищем пустые тексты для дефолтного канала
            let emptyDefaultText = !allowEmptyTexts && this.hasEmptyDefaultChannelTexts();
            if (emptyDefaultText) {
                // текст ответа для дефолтного канала должен быть заполнен
                this.selectedChannel = defaultChannel;
                errors.set("formulation", "Не задан текст ответа для дефолтного канала " + defaultChannel.title);
            }
        }

    }

    /**
     * Фильтрация формулировок: все, кроме текущей
     * @param formulation формулировка
     */
    private isNotCurrent(formulation: Formulation) {
        return this.key ? formulation.key?.id !== this.key.id
            : JSON.stringify(this) !== JSON.stringify(formulation);
    }

    /**
     * Проверка: задано > 1 пустой формулировки?
     * @param defaultChannel        дефолтный канал
     * @param formulations    список сохранённых формулировок (КРОМЕ ТЕКУЩЕЙ)
     */
    private isSeveralDefaultChannelTextsEmpty(defaultChannel: ValueOption, formulations: Formulation[]) {
        const isTextEmpty = this.hasEmptyDefaultChannelTexts();
        const isEmptyTextAlreadyStored = formulations.some(formulation => formulation.hasEmptyDefaultChannelTexts());
        return isTextEmpty && isEmptyTextAlreadyStored;
    }

    /**
     * Проверка: задан ли пустой текст (без вложений) для текста канала по-умолчанию
     */
    public hasEmptyDefaultChannelTexts() {
        // все тексты по дефолтному каналу
        const texts = this.getDefaultChannelTexts();
        // фильтруем повторы (условие на длину текста) и ищем вложения
        return texts.filter(text => text.text.trim().length > 0 || ChannelText.hasAttachments(text)).length === 0;
    }

    /**
     * Проверка, что текущая формулировка не дубликат какой то
     */
    validateDuplicateChannelTexts(entity: WithFormulation, currentIndex: number, errors: Map<string, string>): void {
        entity.formulations
        // выкинем текущую формулировку
            .filter((formulation, index) => currentIndex !== index)
            .forEach(formulation => {
                this.channelTexts.forEach(channelText => {
                    const channel = channelText.channelValueOption;
                    // Если совпадают текст и развернутый текст
                    formulation.textsByChannel(channel).forEach(text => {
                        if (text && text.text == channelText.text && text.text !== "") {
                            this.selectedChannel = channel;
                            errors.set("channel", "Дубликат текстов формулировки для канала");
                        }
                    })
                })
            })
    }

    /**
     * Процессинг названия формулировки. Если задано, проверить, если нет, сгенерировать из текста
     */
    processName(entity: WithFormulation, currentIndex: number, errors: Map<string, string>): void {
        if (!this.name?.length) {
            // имя не задано, генерируем
            this.generateName();
            return;
        }
        if (this.name.length > 256) {
            //имя формулировки больше 256 символов
            errors.set("name", "Имя формулировки должно быть меньше 256 символов");
        } else {
            const duplicateName = entity.formulations
                .filter((f, index) => currentIndex != index)
                .filter(f => f.name === this.name).length > 0;
            //указанное имя формулировки уже используется
            if (duplicateName) {
                errors.set("name", "Формулировка с таким именем уже задана");
            }
        }
    }

    /**
     * Сгенерировать название формулировки
     */
    private generateName() {
        if (!this.channelTexts?.length) {
            // нет текстов
            return;
        }
        // берем текст из дефолтного канала
        const channelText = this.channelTexts.find(text => text.channelValueOption.isDefault);
        this.name = channelText.text;
        if (channelText.macros) {
            // заменяем макросы на текст
            channelText.macros.forEach(macro => this.name = this.name.replace(macro.generateMacroString(), macro.getTextReplacement()))
        }
        // убираем лишние пробелы
        this.name = this.name.trim();
        this.name = this.name.replace(/  +/g, ' ');

        if (this.name.length > 255) {
            // укорачиваем
            this.name = this.name.substring(0, 255);
        }
    }

    /**
     * Валидация includeIntoRotation
     */
    validateIncludeIntoRotation(currentIndex: number, entity: WithFormulation, errors: Map<string, string>): void {
        const inRotation = entity.formulations
            .find((formulation, index) => currentIndex !== index && formulation.includeIntoRotation);
        if (!inRotation && !this.includeIntoRotation) {
            // минимум одна формулировка должна быть в ротации
            errors.set("includeIntoRotation",
                "Для перевода на оператора, когда тематика не обслуживается роботом, должна быть выбрана хотя бы одна формулировка");
        }
    }

    /**
     * Обработка текстов - нужно, чтобы текстов у каждого канала было столько, сколько указано в сущности (у всех, кроме атрибутов сейчас 1)
     */
    prepareTexts(entity: WithFormulation, entityType: WithFormulationType): void {
        // выберем разные каналы
        const channels: ValueOption[] = this.getDistinctChannels();
        // для каждого канала обработаем повторы
        channels.forEach(channel => this.prepareChannelTexts(entity, entityType, channel));
    }

    /**
     * Вытащить каналы, для которых созданы тексты
     */
    getDistinctChannels(): ValueOption[] {
        // выберем разные каналы
        const channels: ValueOption[] = [];
        this.channelTexts.map(text => text.channelValueOption)
            .forEach(channel => {
                if (channels.find(unique => unique.key.id === channel.key.id) == null) {
                    channels.push(channel);
                }
            });
        return channels;
    }

    /**
     * Обработка текстов для одного канала - нужно, чтобы текстов было столько, сколько указано в сущности (у всех, кроме атрибутов сейчас 1)
     */
    prepareChannelTexts(entity: WithFormulation, entityType: WithFormulationType, channel?: ValueOption): ChannelText[] {
        let resultChannel = !channel ? this.selectedChannel : channel;
        const channelTexts = this.textsByChannel(resultChannel);
        const textAmount = this.getTextAmount(resultChannel, entity, entityType);
        if (channelTexts.length === textAmount) {
            return channelTexts;
        }
        // Сколько элементов реально есть
        let existTextAmount = channelTexts.length;
        // Если вопросов меньше 11 и меньше нового количества, то надо дополнить дефолтными
        if (existTextAmount < textAmount && textAmount < 11) {
            for (let i = existTextAmount; i < textAmount; i++) {
                channelTexts.push(new ChannelText(this.key ? this.key.id : null, resultChannel));
            }
        }
        // Если больше 0 и больше нового количества, то удалить с конца
        if (existTextAmount > textAmount && textAmount > 0) {
            channelTexts.splice(textAmount, existTextAmount - textAmount);
        }
        if (!this.channelTexts) {
            this.channelTexts = [];
        } else {
            // удалим старые тексты по каналу
            this.channelTexts = this.channelTexts.filter(text => text.channelValueOptionId !== resultChannel.key.id);
        }
        // проставим в формулировку новые тексты
        this.channelTexts.push(...channelTexts);
        return channelTexts;
    }

    /**
     * Необходимое количество текстовок
     */
    getTextAmount(channel: ValueOption, entity: WithFormulation, entityType: WithFormulationType): number {
        // у атрибутов несколько текстов каналов, у остальных 1
        return entityType === WithFormulationType.ATTRIBUTE ? (entity as VaAttribute).requestAmount : 1;
    }


}


export class ChannelText {
    key?: CompositeKey<number>;
    formulationId?: number;
    channelValueOptionId: number;
    channelValueOption: ValueOption;
    text: string;
    deleted?: boolean;
    requiredAttributes?: number[];
    dkbFieldIds?: number[];
    predefinedValueIds?: number[];
    predefinedValueOptions?: number[];
    predefinedAttributes?: number[];
    macros?: Macro[];
    attachedFilesIds: string[] = [];
    attachedFiles: EruditeFile[] = [];
    fileMacros: Macro[] = [];


    constructor(formulationId: number, channel: ValueOption) {
        this.formulationId = formulationId;
        this.channelValueOptionId = channel.key.id;
        this.channelValueOption = channel;
        this.text = "";
    }

    /**
     * Прикрепить вложения к формулировке
     * @param text
     * @param files
     */
    public static attachFiles(text: ChannelText, files: EruditeFile[]): void {
        text.attachedFiles = text.attachedFiles == null ? [...files] : [...text.attachedFiles, ...files];
        text.attachedFilesIds = text.attachedFilesIds == null ?
            [...files.map(file => file.id)] :
            [...text.attachedFilesIds, ...files.map(file => file.id)];
    }

    public static hasAttachments(text: ChannelText): boolean {
        return text.fileMacros?.length > 0 || text.attachedFilesIds?.length > 0
    }

    public static hasText(channelText: ChannelText): boolean {
        const text = channelText.text.trim();
        if (ChannelText.hasAttachments(channelText) && channelText.fileMacros?.length > 0) {
            channelText.fileMacros.forEach(macro => text.replace(`${macro.key.id}`, ``));
        }
        return text.trim().length != 0
    }

}

export enum WithFormulationType {
    ATTRIBUTE = "ATTRIBUTE",
    ANSWER = "ANSWER",
    SMA = "SMA"
}