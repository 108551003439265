import urls from "../../../urls";
import common from "../../../common";


function VaCommandDirective() {
    return {
        restrict: "E",
        bindToController: true,
        templateUrl: "/pages/workplace/va/common/directives/commands.html",
        scope: {
            tag: "=",
            tbMessageMode: "=",
            title: "@",
            block: "=",
        },
        controller: ["Restangular", "$uibModal", "$stateParams", function (restangular, uibModal, $stateParams) {
            const that = this;

            that.restAngular = restangular;
            that.uibModal = uibModal;

            that.baseUrl = urls.va.commands;
            that.wantCreateNew = false;
            that.wantEditCommand = false;

            that.keywordBackup = null;

            that.projectVersionId = $stateParams['projectVersionId'];

            that.startCreate = function () {
                that.errors = null;
                that.wantCreateNew = !that.wantCreateNew;
            };

            that.refresh = function () {
                that.restAngular.one(that.baseUrl).one("getByTagId", that.tag.key.id).getList()
                    .then(function success(data) {
                        that.commands = data;
                        that.errors = null;
                    }, function error(data) {
                        that.errors = data.errors;
                    })
                    .finally(function () {
                        // в любом случае загрузка закончена
                        common.formFinishLoading(that.form);
                    });
            };

            that.refresh();

            /**
             * Метод для создания/сброса формы
             */
            that.resetForm = function () {
                that.form = {
                    // идёт загрузка
                    isLoading: false,
                };
            };

            that.startEdit = function(command) {
                that.errors = null;
                that.keywordBackup = command.keyword;
                return !command.wantEditCommand;
            };

            that.init = function () {
                that.command = {
                    id: 0,
                    keyword: "",
                    deleted: false,

                    tagId: that.tag.key.id,
                    projectVersionId: that.tag.projectVersionId,
                    expertId: -1,
                    d: new Date().getTime(),
                }
            };

            that.init();
            that.resetForm();

            that.save = function () {
                that.restAngular.one(that.baseUrl).post("", that.command)
                    .then(function success(data) {
                        that.refresh();
                        that.wantCreateNew = false;
                        that.init();
                        that.errors = null;
                    }, function error(data) {
                        that.errors = data.errors;
                    });
            };

            that.restAngular.one(urls.va.tags).get({projectVersionId: that.projectVersionId})
                .then(function success(data) {
                    that.existGroups = data;
                    that.errors = null;
                }, function error(data) {
                    that.errors = data.errors;
                });

            common.formStartLoading(that.form);

            that.startCommandEdit = function (command) {
                that.restAngular.one(that.baseUrl).patch(command)
                    .then(function success(data) {
                        that.refresh();
                        that.wantEditCommand = false;
                        that.errors = null;
                    }, function error(data) {
                        that.errors = data.errors;
                        command.keyword = that.keywordBackup;
                    })
            };

            that.setDeleted = function (command) {
                that.restAngular.one(that.baseUrl + "/" + command.key.id).remove()
                    .then(function success(data) {
                        that.refresh();
                        that.wantEditCommand = false;
                        that.errors = null;
                    }, function error(data) {
                        that.errors = data.errors;
                    })
            }
        }],
        controllerAs: "ctrl",
    };
}

export default VaCommandDirective;