import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {NgbProgressbarModule} from "@ng-bootstrap/ng-bootstrap";
import {NotificationComponent} from "./notification.component";
import {NotificationService} from "./notification.service";
import {EditableListModule} from "../../editable-list/editable-list.module";
import {LongOperationService} from "./long-operation/long-operation.service";
import {PushNotificationComponent} from "./long-operation/push-notification-component";

@NgModule({
    declarations: [
        NotificationComponent,
        PushNotificationComponent
    ],
    imports: [
        BrowserModule,
        NgbProgressbarModule,
        EditableListModule,
    ],
    providers: [
        NotificationService,
        LongOperationService
    ],
    exports: [
        NotificationComponent,
        PushNotificationComponent
    ],
    entryComponents: [
        NotificationComponent
    ]
})
export class NotificationModule {
    static states = [
        {
            name: 'robot.push_notifications',
            url: "/push_notifications?then",
            views: {
                "content@authorized": {
                    component: PushNotificationComponent
                }
            }
        }
    ]
}