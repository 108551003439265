import {VaScriptNode} from "../../../../../data/va/Script";
import RectangleElement from "./RectangleElement";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {STROKE_DASH} from "../../ScriptBuilderUtils";


export class ProcedureElement extends RectangleElement {

    constructor(attributes: {node: VaScriptNode}) {
        super(attributes);

        mergeDeep(this.attributes, {
            attrs: {
                body: {
                    fill: "#F8F8F8",
                    stroke: "#D53300",
                    strokeDasharray: STROKE_DASH
                },
                label: {
                    fill: "#D53300",
                }
            }
        });
    }

    public isNavigable(): boolean {
        return true;
    }


    get isDialogable(): boolean {
        return false;
    }
}