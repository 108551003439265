import {Component, DoCheck, Input, KeyValueDiffer, KeyValueDiffers, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Formulation, WithFormulation, WithFormulationType} from "../../../data/va/Formulation";
import * as urls from "../../../../../js/workplace/urls";
import {HttpClient} from "@angular/common/http";
import {VaTtsSettings} from "../../../data/va/Voice";
import {ValueOption} from "../../../data/va/Extractor";
import {VASimpleModuleAnswer} from "../../../data/va/SimpleModuleAnswer";
import {VAActEnum} from "../../../data/va/VAActEnum";
import {Valued} from "../../../data/va/Valued";
import {EditableForm, EditableItemData} from "../common/editable-list/editable-list.model";


@Component({
    selector: 'formulation',
    template: require('./formulation.component.html'),
    styles: [require('./formulation.component.less')],
    encapsulation: ViewEncapsulation.None
})

export class FormulationComponent implements OnInit, DoCheck {

    /**
     * Сущность с формулировками
     */
    @Input()
    public entity: WithFormulation;

    /**
     * Текущий пользователь
     */
    @Input()
    public account: any;

    /**
     * Уровень доступа
     */
    @Input()
    public access: boolean;

    /**
     * Тип сущности с формулировками
     */
    @Input()
    public entityType: WithFormulationType;

    /**
     * Уровень доступа
     */
    @Input()
    public viewMode: boolean;

    /**
     * Разрешенные типы
     */
    @Input()
    types: string[];

    /**
     * Элементы, которые надо выкинуть из общего списка
     */
    @Input()
    excludeIds: string[];

    /**
     * Список типов каналов
     */
    @Input()
    channels: ValueOption[];

    private customerDiffer: KeyValueDiffer<string, any>;

    editableItemData: EditableItemData;

    /**
     * Форма для формулировок
     */
    form: EditableForm<Formulation> = new EditableForm(new Formulation());

    /**
     * Мапа с исходниками, которые нужны внутри объекта
     */
    sources: Map<string, any> = new Map<string, any>();

    /**
     * Данные по tts и его настройке
     */
    ttsObject: { settings: VaTtsSettings; enabled: boolean };

    constructor(private http: HttpClient, private differs: KeyValueDiffers) {

    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entity) {
            let object = changes.entity.currentValue;
            if (object) {
                // если данные загружены, то добавим их в исходники
                this.sources.set(SourcesKey.ENTITY, this.entity);
                this.sources.set(SourcesKey.ENTITY_TYPE, this.entityType);
                // начнем следить за его полями
                if (this.entityType === WithFormulationType.ATTRIBUTE) {
                    this.customerDiffer = this.differs.find(this.entity).create();
                }
                if (object.formulations) {
                    // нужен объект с формулировками
                    this.entity.formulations = this.entity.formulations.map(formulation => new Formulation(formulation));
                }
            }
        }
        if (changes.access) {
            this.setEditableSettings();
        }
        if (changes.channels) {
            let channels = changes.channels.currentValue;
            if (channels) {
                // если есть канал "голосовой помощник", то проставим название вкладки
                this.channels.filter(channel => channel.apiKey == Valued.ASSISTANT_API_KEY)
                    .forEach(channel => channel.title = 'Отображение в чате');
                this.channels = this.channels.sort((a: ValueOption, b: ValueOption) => {
                    if (a.apiKey === b.apiKey) {
                        return 0;
                    }
                    if (a.isDefault) {
                        return -1;
                    }
                    if (b.isDefault) {
                        return 1;
                    }
                    if (a.apiKey === Valued.WEB_API_KEY) {
                        return -1;
                    }
                    if (a.apiKey === Valued.VOICE_API_KEY) {
                        if (b.apiKey === Valued.WEB_API_KEY) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    if (b.apiKey === Valued.WEB_API_KEY || b.apiKey === Valued.VOICE_API_KEY) {
                        return 1;
                    }
                    return a.apiKey.localeCompare(b.apiKey);
                });
                this.sources.set(SourcesKey.DEFAULT_CHANNEL, this.channels.find(vo => vo.isDefault));
                this.sources.set(SourcesKey.AVAILABLE_CHANNELS, this.channels);
                this.setEditableSettings();
            }
        }
    }

    private setEditableSettings(): void {
        // можно менять список, если есть данные о каналах  и доступ
        const isModify = this.access && !this.viewMode && this.sources.get(SourcesKey.DEFAULT_CHANNEL) != null;
        const isInternalErrorMessage = this.entityType == WithFormulationType.SMA && (this.entity as VASimpleModuleAnswer).vaAct.name == VAActEnum.INTERNAL_ERROR_MESSAGE;
        this.editableItemData = new EditableItemData("формулировку", isModify && !isInternalErrorMessage, isModify, isModify, false, true, true);
    }

    ngDoCheck(): void {
        if (this.customerDiffer) {
            const changes = this.customerDiffer.diff(this.entity);
            if (changes) {
                changes.forEachChangedItem(item => {
                    // при изменении нужного поля - пересоберем превью
                    if (item.key == 'requestAmount') {
                        this.sources.set(SourcesKey.ENTITY, this.entity);
                        if (this.entity.formulations) {
                            this.entity.formulations.forEach(formulation => formulation.prepareTexts(this.entity, this.entityType));

                            // Обновим на открытом formulation component
                            this.form.objectForm.prepareChannelTexts(this.entity, this.entityType, this.form.objectForm.selectedChannel ? this.form.objectForm.selectedChannel : this.channels[0]);
                        }
                    }
                });
            }
        }
    }


    private async loadData() {
        const enabled = await this.http.get<boolean>(`${urls.va.tts}/ttsServerEnabled`).toPromise();
        let textToSpeechSettings: VaTtsSettings;
        // Если это атрибут - грузим его личные настройки tts'a
        if (this.entityType == 'ATTRIBUTE' && this.entity?.key?.id) {
            textToSpeechSettings = await this.http.get<VaTtsSettings>(`${urls.va.tts}/attributeTtsSetting`, {params: {attributeId: `${this.entity?.key?.id}`}}).toPromise()
        } else {
            textToSpeechSettings = await this.http.get<VaTtsSettings>(`${urls.va.tts}/defaultTtsSettings`).toPromise();
        }
        this.ttsObject = {
            enabled: enabled,
            settings: textToSpeechSettings
        }
    }

    showAssistantTitle(): boolean {
        return this.channels.some(channel => channel.apiKey === "assistant");
    }

    /**
     * Действие при выборе канала в селекте
     */
    onChannelSelect(channelId: number): void {
        this.form.objectForm.selectedChannel = this.channels.find(channel => channel.key.id == channelId);
        this.form.objectForm.prepareChannelTexts(this.entity, this.entityType);
    }

    isConfirmAttributeExtraction(): boolean {
        return this.entityType === WithFormulationType.SMA && (this.entity as VASimpleModuleAnswer).vaAct.name == VAActEnum.KM_CONFIRM_ATTRIBUTE_EXTRACTION;
    }

    isTagMacroUsable(): boolean {
        if (this.entityType != WithFormulationType.SMA) {
            return false;
        }
        switch ((this.entity as VASimpleModuleAnswer).vaAct.name) {
            case VAActEnum.KM_CONFIRM_TAG_CHOOSE:
            case VAActEnum.BACK_TO_TOPIC:
            case VAActEnum.TO_TECH_SUPPORT_AS_TAG_ANSWER:
                return true;
            default:
                return false;
        }
    }

    isToTechSupportAsTagAnswer(): boolean {
        return this.entityType === WithFormulationType.SMA && (this.entity as VASimpleModuleAnswer).vaAct.name == VAActEnum.TO_TECH_SUPPORT_AS_TAG_ANSWER;
    }

    isRerouteAllowed(): boolean {
        return FormulationComponent.isRerouteAllowed(this.entity, this.entityType);
    }

    isRerouteDisabled(): boolean {
        if (this.entityType === WithFormulationType.SMA) {
            const inRotation = this.entity.formulations
                .find((formulation, index) => this.form.index !== index && formulation.includeIntoRotation);
            return !inRotation;
        }
        return false;
    }

    public static isRerouteAllowed(entity: WithFormulation, entityType: WithFormulationType): boolean {
        return entityType === WithFormulationType.SMA &&
            [VAActEnum.ACK_APOLOGY, VAActEnum.TO_TECH_SUPPORT, VAActEnum.TO_TECH_SUPPORT_AS_TAG_ANSWER, VAActEnum.INTERNAL_ERROR_MESSAGE, VAActEnum.SORRY_PREFIX]
                .indexOf((entity as VASimpleModuleAnswer).vaAct.name) >= 0;
    }

    /**
     * Выбран голосовой канал
     */
    isVoice(): boolean {
        return this.form.objectForm.selectedChannel?.apiKey === Valued.VOICE_API_KEY || this.form.objectForm.selectedChannel?.apiKey === Valued.ASSISTANT_VOICE_API_KEY;
    }

}

export enum SourcesKey {
    ENTITY = "ENTITY",
    ENTITY_TYPE = "ENTITY_TYPE",
    DEFAULT_CHANNEL = "DEFAULT_CHANNEL",
    AVAILABLE_CHANNELS = "AVAILABLE_CHANNELS"
}

