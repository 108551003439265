import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TagTreeComponent} from "./tag-tree.component";
import {MatTreeModule} from '@angular/material/tree';
import {MatButtonModule, MatIconModule} from "@angular/material";
import {FormsModule} from "@angular/forms";
import {TagNodeComponent} from "../node/tag-node.component";
import {EditableListModule} from "../../../common/editable-list/editable-list.module";
import {BoilerplateModule} from "../../base/boilerplate.module";
import {CollectionModule} from "../../../common/collection/collection.module";

@NgModule({
    imports: [
        MatTreeModule,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        EditableListModule,
        BoilerplateModule,
        CollectionModule
    ],
    declarations: [
        TagTreeComponent,
        TagNodeComponent
    ],
    entryComponents: [
        TagTreeComponent,
        TagNodeComponent
    ],
    exports: [
        TagTreeComponent,
        TagNodeComponent
    ],
    providers: []
})
export class TagTreeModule {

}
