// эти импорты нужны для нового ангуляра
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';
import "zone.js/dist/zone.js";
// обычные импорты
import nprogesss from "./js/general/angular-nprogress";
import locationUI from "./js/general/angular-location-ui";
import routes from "./js/workplace/routes";
import {TemplateCacheBuilder} from "./Utils";
import {MultipleUrlMatcherFactory} from "./js/workplace/services/MultipleUrlMatcherFactoryProvider";
import "./less/skin-chat.less";
import "./less/workplace/workplace";
// прогресс-бар при асинхронной загрузке данных
// UI-improvements

// загрузка путей

// стили
import {downgradeComponent} from '@angular/upgrade/static'
import NewAngularStarter from "./js/general/new-angular-starter";

showJSError.init({
    title: 'Произошла ошибка',
    userAgent: navigator.userAgent,
    copyText: 'Скопировать',
    helpLinks: true
});

var interfaceConfiguration = null;
/**
 * Инициализация Angular
 */
var initAngularApp = function () {
    "use strict";

    /**
     * Функция групповой инициализации данных
     * @param ngMethod конфигурациионный метод ангуляра, 1 параметр - имя модуля, 2 - функция/объект
     * @param components список компонент для инициализации
     */
    function populate(ngMethod, components) {
        components.keys().forEach(function (key) {
            populateItem(ngMethod, key, components(key));
        });
    }

    function populateItem(ngMethod, fileName, resource) {

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function lowercaseFirstLetter(string) {
            return string.charAt(0).toLowerCase() + string.slice(1);
        }

        let controllerName = fileName.replace(/.*\/(.*)\.js/g, "$1");
        controllerName = controllerName.replace(/.*\/(.*)\.ts/g, "$1");
        const controller = resource.default;

        if (!!controller) {
            // var name = controller.name ? controller.name : controllerName;
            var name = controllerName;
            ["Directive", "Component"].forEach(function (burgerStylePostfix) {
                const rx = new RegExp("^(.+)" + burgerStylePostfix + "$");
                if (name.match(rx)) {
                    name = name
                        .replace(rx, "$1") // получаем основное название
                        .replace(/\.?([A-Z]+)/g, function (x, y) { // и преобразуем в dash-style
                            return capitalizeFirstLetter(y.toLowerCase())
                        });
                    name = lowercaseFirstLetter(name);
                }
            });

            ngMethod(name, controller);
        }
    }

    /**
     * Групповая инициализация
     * @param app angular application
     * @param items загружаемые файлы
     */
    function initModule(app, items) {
        // console.info("module");
        items.keys().forEach(function (fileName) {

            // console.info(fileName);

            const resource = items(fileName);

            if (fileName.match(/.*Component\.js$/)) {
                populateItem(app.component, fileName, resource);
            } else if (fileName.match(/.*Controller\.js$/)) {
                populateItem(app.controller, fileName, resource);
            } else if (fileName.match(/.*Directive\.js$/)) {
                populateItem(app.directive, fileName, resource);
            } else if (fileName.match(/.*Service\.js$/)) {
                populateItem(app.service, fileName, resource);
            } else if (fileName.match(/.*Routes\.js$/)) {
                // ignore route - must be included manually
            } else {
                console.debug("File '" + fileName + "' are ignored");
            }
        });
    }

    function angularIOComponents(app, items) {
        items.keys().forEach(function (fileName) {
            const resource = items(fileName);

            let component;

            if (resource.default) {
                component = resource.default;
            } else {
                const componentName = Object.keys(resource).filter(name => name.endsWith('Component'))[0];
                component = resource[componentName];
            }

            if (component) {
                let name = fileName;
                if (name.includes('.component.')) {
                    name = name.replace(/.*\/(.*).component\.ts/g, "$1");
                    name = name.split('-').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join('');
                } else {
                    name = name.replace(/.*\/(.*)Component\.ts/g, "$1");
                    name = name.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/ /g, '');
                }
                app.directive(`ngio${name}`, downgradeComponent({component}));
            }
        });
    }

    const modules = [
        "ngRoute",
        "ngResource",
        "ngAnimate",
        "ngSanitize",
        "input-highlight",
        "ngCookies",
        "ngMessages",
        "ngTagsInput",
        "ui.router",
        "ui.select",
        "ui.router.util",
        "ui.router.upgrade",
        "ui.bootstrap",
        "ui.tree",
        "ntt.TreeDnD",
        "treeControl",
        "datatables",
        "nvd3",
        "angularFileUpload",
        "restangular",
        "daterangepicker",
        "checklist-model",
        "colorpicker.module",
        "loopify.ui.numberPicker",
        "ngFileSaver",
        "ngDragDrop",
        "angularRandomString",
        "luegg.directives",
        "cfp.hotkeys",
        "ngclipboard",
        "gridster",
        "ui.grid",
        'ui.grid.resizeColumns',
        'ui.grid.moveColumns',
        'ui.grid.pagination',
        "ui.grid.autoResize",
        "ui.grid.saveState"
    ];

    if (NODE_ENV === "prod" && interfaceConfiguration.sentryUrl) {
        modules.push("ngRaven");
    }

    const app = angular.module("workplaceApp", modules);

    window.app = app;

    // Инициазизация всех компонент (контролеров, директив etc) – package by layer
    populate(app.component, require.context("./js/workplace/components/", true, /\.js$/));
    populate(app.component, require.context("./ts/workplace/components/", true, /\.ts$/));

    // контроллеры
    populate(app.controller, require.context("./js/workplace/controllers/", true, /\.js$/));
    populate(app.controller, require.context("./ts/workplace/controllers/", true, /\.ts$/));

    //сервисы
    populate(app.service, require.context("./js/workplace/services/", true, /\.js$/));
    populate(app.service, require.context("./ts/workplace/services/", true, /\.ts$/));

    populate(app.directive, require.context("./js/workplace/directives/", true, /\.js$/));
    populate(app.directive, require.context("./ts/workplace/directives/", true, /\.ts$/));

    populate(app.filter, require.context("./js/workplace/filters/", true, /\.js$/));
    populate(app.filter, require.context("./ts/workplace/filters/", true, /\.ts$/));

    //shared компоненты
    populate(app.directive, require.context("./js/shared/directives/", true, /\.js$/));
    populate(app.service, require.context("./js/shared/service/", true, /\.js$/));
    populate(app.filter, require.context("./js/shared/filters/", true, /\.js$/));

    // добавляем компоненты новый Angular
    angularIOComponents(app, require.context("./ts/workplace/io/components/", true, /\.component\.ts$/));
    angularIOComponents(app, require.context("./ts/workplace/io/components/", true, /Component\.ts$/));

    let restangularRequestInterceptor = {
        $state: null,
        intercept: function (element, operation, what, url, headers, params) {
            const $state = this.$state;
            if (!$state) {
                return;
            }
            
            // Если робота нет в пути, то не надо искать домен
            if ($state.current.name.indexOf("robot") === -1) {
                return;
            }

            params.projectVersionId = $state.params.projectVersionId;
            return params;
        }
    };

    const application = app
        .config(nprogesss)
        .config([ '$urlServiceProvider', ($urlService) => $urlService.deferIntercept() ])
        .config(["$stateProvider", "$urlMatcherFactoryProvider", function ($stateProvider, $urlMatcherFactoryProvider) {


            const fac = new MultipleUrlMatcherFactory($urlMatcherFactoryProvider);
            /**
             * Конфигурация путей находитсяResolverPlugin в resources/webapp/routes.js
             * и подставляется на этапе сборки
             */
            for (var i = 0; i < routes.length; i++) {
                var rule = routes[i];
                
                if (Array.isArray(rule.options.url)) {
                    rule.options.url = fac.compile(rule.options.url);
                }

                $stateProvider.state(rule.state, rule.options)
            }
        }])

        .config(["$locationProvider", "$httpProvider", function ($locationProvider, $httpProvider) {
            $httpProvider.defaults.headers.common = {
                "X-Requested-With": "workplaceApp"
            };

            // use the HTML5 History API
            $locationProvider.html5Mode(false);

        }])
        .config(["RestangularProvider", function (RestangularProvider) {
            RestangularProvider.addFullRequestInterceptor(restangularRequestInterceptor.intercept.bind(restangularRequestInterceptor));
            RestangularProvider.addResponseInterceptor(function (data, operation, what, url, fullRequest, deferred) {
                if (fullRequest && fullRequest.config) {
                    if (fullRequest.config.responseType === 'arraybuffer') {
                        return data;
                    }
                }

                if (data.status === "NOT_AUTHORIZED") {
                    console.log("NEED TO REDIRECT TO LOGIN PAGE");
                    location.reload();
                }

                if (data.status !== "OK") {
                    // статус сущности не ок, надо режектить промис как будто это ошибка (хотя код 200)
                    deferred.reject(data);
                    return;
                }
                return data;
            });
            RestangularProvider.setResponseExtractor(function (response, operation, what, url, fullRequest, deferred) {
                if (fullRequest && fullRequest.config) {
                    if (fullRequest.config.responseType === 'arraybuffer') {
                        return response;
                    }
                }

                if (!response) {
                    return;
                }
                return response.result;
            });
            RestangularProvider.addErrorInterceptor(function (response, deferred, responseHandler, errorCallback) {
                if (response.status === 401) {
                    location.reload();
                    return false;
                }
                if (response.status === 403) {
                    const headerDeny = response.headers("X-DENY-REASON");
                    switch (headerDeny) {
                        case "CSRF_EXPIRED":
                        case "CSRF_MISSING":
                            let initInjector = angular.injector(['ng', 'restangular']);
                            let Restangular = initInjector.get('Restangular');
                            let $q = initInjector.get('$q');
                            let $http = initInjector.get('$http');

                            let retries = response.config["x-retries"];
                            if (!retries) {
                                retries = 0;
                            }
                            retries++;
                            response.config["x-retries"] = retries;
                            if (retries > 1) {
                                break;
                            }

                            var data = response.data;
                            if (!data) {
                                data = {};
                            }

                            Restangular.one("/eruditevisitor/welcome/csrf/").get().then(function () {
                                var request = Restangular.restangularizeElement('', data, response.config.url);
                                let method = response.config.method.toLowerCase();
                                $http(response.config).then(function (r) {
                                    responseHandler(r);
                                }, errorCallback);
                                // request[method]().then(function (r) {
                                //     responseHandler(r);
                                // }, errorCallback);
                            }, function () {

                            });

                            return false;
                        default:
                            break;
                    }
                    return;
                }

                if (interfaceConfiguration.showJsErrors) {
                    // все HTTP-ошибки тоже показываем юзеру, если включена соответствующая настройка
                    const requestConfig = response.config;
                    const url = requestConfig.url;
                    const method = requestConfig.method;
                    const status = response.status;
                    let message = "";
                    if (response.data) {
                        message = response.data.message;
                    }
                    showJSError.show({
                        title: 'Ошибка запроса',
                        message: `${status} ${method}: ${url}. ${message}`
                    });
                }
            });
        }])
        .config(["$provide", function ($provide) {
            $provide.decorator("$exceptionHandler", ["$window", "$delegate", function($window, $delegate) {
                // ловим все ошибки из ангуляра и показываем пользователю (если включена соответствующая настройка)
                // затем передаём в следующий обработчик
                return function (exception, cause) {
                    if (interfaceConfiguration.showJsErrors) {
                        showJSError.show(exception);
                    }
                    $delegate(exception, cause);
                };
            }]);
        }])
        .config(["$animateProvider", function ($animateProvider) {
            $animateProvider.classNameFilter(/sliding-item|fading-item|custom-anim-item/);
        }])

        .filter('formatLocation', function () {
            return function (location) {
                return location.displayTitle = location.country
                    + (location.region != null ? (", " + location.region) : "")
                    + (location.city != null ? (", " + location.city) : "");
            };
        })

        .filter('isEmpty', function () {
            var bar;
            return function (obj) {
                if (!obj) {
                    return true;
                }
                if (obj && Array.isArray(obj)) {
                    return obj.length === 0;
                }
                for (bar in obj) {
                    if (obj.hasOwnProperty(bar)) {
                        return false;
                    }
                }
                return true;
            };
        })

        .animation('.sliding-item', function () {
            return {
                enter: function (element, done) {
                    var el = jQuery(element);
                    //сначала hide, чтобы анимация не срабатывала сразу
                    el.hide().slideDown({complete: done});
                    return function (cancelled) {
                        if (cancelled === undefined) {
                            el.stop(true, true);
                            el.hide();
                        }
                    }
                },
                leave: function (element, done) {
                    var el = jQuery(element);
                    el.slideUp({complete: done});
                    return function (cancelled) {
                        if (cancelled === undefined) {
                            el.stop(true, true);
                            el.hide();
                        }
                    }
                }
            }
        })
        .animation('.fading-item', function () {
            return {
                enter: function (element, done) {
                    var el = jQuery(element);
                    //сначала hide, чтобы анимация не срабатывала сразу
                    el.hide().fadeIn({complete: done});
                    return function (cancelled) {
                        if (cancelled === undefined) {
                            el.stop(true, true);
                            el.hide();
                        }
                    }
                },
                leave: function (element, done) {
                    var el = jQuery(element);
                    el.fadeOut({complete: done});
                    return function (cancelled) {
                        if (cancelled === undefined) {
                            el.stop(true, true);
                            el.hide();
                        }
                    }
                }
            }
        })

        .constant("interfaceConfiguration", interfaceConfiguration)
        .run(locationUI)
        .run(TemplateCacheBuilder({
            "/pages/workplace": require.context("./pages/workplace/", true, /\.html$/),
            "/pages/shared": require.context("./pages/shared/", true, /\.html$/)
        }))
        .run(["StatisticsService", function (statisticsService) {
            statisticsService.start();
        }])
        .run(["$state", function ($state) {
            restangularRequestInterceptor.$state = $state;
        }])
        .run(["$rootScope", "$location", function ($rootScope, $location) {
            $rootScope.testHeaders = $location.host() == "127.0.0.1";

            // таймзона сервера в формате '+H' для форматирования дат
            $rootScope.SERVER_TIMEZONE = interfaceConfiguration.serverTimezone;
        }]).run(["$injector", ($injector) => {
            window.$injector = $injector;
        }]);

    if (module.hot && angular.__ngHotReload$didRegisterProviders) {
        module.hot.accept(function (err) {
            if (err) {
                console.error(err);
            }
        });
    }
    return application;
};

$.ajax({
    url: "/account/interface",
    type: "GET",
    dataType: "json",
    contentType: "application/json"
}).done(function (data) {
    if (data.result) {
        interfaceConfiguration = data.result;
        const sentryUrl = interfaceConfiguration.sentryUrl;
        require('./js/general/raven').init(sentryUrl, REVISION);
        // запуск Angular
        require("./js/general/angular-starter").run(initAngularApp, NewAngularStarter, interfaceConfiguration);
    } else {
        alert("Не удается загрузить конфигурацию интерфейса, пожалуйста, повторите запрос");
    }
}).fail(function () {
    alert("Не удается загрузить конфигурацию интерфейса, пожалуйста, повторите запрос");
    console.log("error");
});