/**
 * Конфигурация путей для AngularJS, также зачитывается из Java для генерации статистики
 */

import main from "./routes/MainRoutes";
import settings from "./routes/SettingsRoutes";
import va from "./routes/VaRoutes";
import push from "./routes/PushRoutes";
import accounts from "./routes/AccountRoutes";


var allRoutes = [];
allRoutes = allRoutes.concat(main);
allRoutes = allRoutes.concat(accounts);
allRoutes = allRoutes.concat(settings);
allRoutes = allRoutes.concat(va);
allRoutes = allRoutes.concat(push);

module.exports = allRoutes;
