import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {VAProjectVersion} from "../../data/va/VAProjectVersion";

export interface EruditeFile {
    id: string;
    fileName: string;
    fileOrigin: FileOrigin;
    fileType: FileType;
    creatorId: number;
    tag: string;
    creationDate: Date;
    contentType: string;
    creator: any;
    downloadUrl: string;
    projectVersionId: string;
}

export enum FileOrigin {
    UPLOAD = "UPLOAD",
    GENERATED = "GENERATED",
    REQUESTED = "REQUESTED"
}

export enum FileType {
    REPORT = "REPORT",
    MEDIA = "MEDIA",
    UNDEFINED = "UNDEFINED"
}

export class EruditeFileFilter {
    fromDate: NgbDate;
    toDate: NgbDate;
    projectVersion: VAProjectVersion;

    constructor() {
    }
}