import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogCopyResult} from "../model/dialog.model";

@Component({
    selector: 'dialog-copy-dialog',
    template: require('./dialog-copy-dialog.component.html'),
    styles: [require('./dialog-copy-dialog.component.less')]
})
export class DialogCopyDialogComponent {

    constructor(public dialogRef: MatDialogRef<DialogCopyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {result: DialogCopyResult}) {
    }

    onDeny(): void {
        this.dialogRef.close();
    }
}
