function TrendDetectionSelectorDirective() {
    return {
        restrict: "EA",
        scope: {
            title: "@",
            selectorMultiple: "=",
            selectorCollection: "=",
            selectorModel: "=",
            selectorFieldName: "=",
            placeholder: "@",
        },
        templateUrl: "/pages/workplace/selectors/trendDetectionSelector.html",
        controller: function () {
        },
        bindToController: true,
        controllerAs: "sCtrl"
    };

}
TrendDetectionSelectorDirective.$inject = [];
export default TrendDetectionSelectorDirective;