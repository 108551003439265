import {VaScriptNode} from "../../../../../data/va/Script";
import RectangleElement from "./RectangleElement";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {FormulableElement} from "../interface/FormulableElement";
import {CheckpointableElement} from "../interface/CheckpointableElement";
import {applyMixins} from "../../ScriptBuilderUtils";
import {dia, ui} from "@naumen/rappid/build/rappid";
import {ClickMode} from "../EruditeElement";


export class AnswerElement extends RectangleElement {

    constructor(attributes: {node: VaScriptNode}) {
        super(attributes);

        mergeDeep(this.attributes, {
            attrs: {
                body: {
                    fill: "#00B152",
                    stroke: "#00B152",
                },
                label: {
                    fill: "#FFFFFF",
                }
            }
        });
        this.addCheckpointMarkUp();
        this.addFormulationMarkUp();
    }

    onElementClick(elementView: dia.ElementView, mode: ClickMode): ui.Halo {
        switch (mode) {
            case ClickMode.DEFAULT:
                return this.onFormulableElementClick(elementView, mode);
            case ClickMode.CHECKPOINT:
                return this.onCheckpointableElementClick();
        }
    }

    get isDialogable(): boolean {
        return false;
    }

    public isNavigable(): boolean {
        return true;
    }
}

export interface AnswerElement extends FormulableElement, CheckpointableElement {
}

applyMixins(AnswerElement, [FormulableElement, CheckpointableElement]);