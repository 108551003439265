import {Component} from "@angular/core";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {BaseMasterComponent} from "../../base/base-master.component";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {TranscriptionDialog, TranscriptionStatus, TranscriptionStatusEnum} from "../transcription.model";
import {TranscriptionService} from "../transcription.service";
import {MatDialog} from "@angular/material/dialog";
import {TranscriptionImportDialogComponent} from "../import/dialog/transcription-import-dialog.component";
import {FileSaverService} from "ngx-filesaver";
import {extractFileName} from "../../../../../../util/Utils";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {
    TranscriptionExportBottomSheetComponent,
    TranscriptionExportConfig
} from "../export/transcription-export-bottom-sheet.component";
import * as moment from 'moment';
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {ServerCollectionFilter} from "../../../common/collection/server-collection.component";
import {ServerCollectionBridge} from "../../../common/collection/server-collection.bridge";

@Component({
    selector: 'transcription-master',
    template: require('./transcription-master.component.html'),
    styles: [require('./transcription-master.component.less')]
})
export class TranscriptionMasterComponent extends BaseMasterComponent<TranscriptionDialog, CompositeKey<number>> {

    /**
     * Текст в строке поиска по коллекции
     */
    searchString: string;

    /**
     * Фильтр статусов диалогов
     */
    statusSet: StatusSet = StatusSet.ALL;

    /**
     * Список статусов у транскрибируемых диалогов
     */
    statusSetsList: StatusSet[] = StatusSet.VALUES;

    /**
     * Коннектор серверной коллекции
     */
    serverCollectionBridge: ServerCollectionBridge<TranscriptionDialog, TranscriptionDialogFilter>;

    constructor(titleService: Title,
                stateService: StateService,
                public dataService: TranscriptionService,
                httpClient: HttpClient,
                private bottomSheet: MatBottomSheet,
                private dialog: MatDialog,
                notificationService: NotificationService,
                private fileSaver: FileSaverService) {

        super(titleService, stateService, dataService, httpClient, notificationService);
        // noinspection JSIgnoredPromiseFromCall
        this.getAccess();
        this.sortFields.push(SortField.date(`d`), new SortField(`Имя файла`, 'fileName'), new SortField(`Длительность`, `totalTime`, false));
        this.serverCollectionBridge = new ServerCollectionBridge<TranscriptionDialog, TranscriptionDialogFilter>({
            orderBy: "d",
            orderAsc: false
        }, item => setAudioLength(item))
        this.loadAccount();
    }


    /**
     * Открыть диалоговое окно импорта
     */
    public openImportDialog() {
        this.dialog.open(TranscriptionImportDialogComponent, {
            width: '500px',
        });
    }

    /**
     * Экспортировать реплики
     */
    public exportReplies() {
        // показываем всплывающее меню с вариантами экспорта
        this.bottomSheet.open(TranscriptionExportBottomSheetComponent, {
            data: {
                // показываем пункты для экспорта по поиску, если задана строка поиска
                search: !!this.searchString
            },
        }).afterDismissed().subscribe((exportConfig: TranscriptionExportConfig) => {
                // после закрытия меню получаем выбранный вариант
                if (!exportConfig) {
                    // ничего не выбрали
                    return;
                }
                // параметры экспорта
                const requestBody = {
                    searchString: this.searchString,
                    ...exportConfig
                };
                // качаем эксель с бэкенда с выбранными опциями
                this.httpClient.post(`${this.dataService.baseUrl}/export`, requestBody, {
                    observe: 'response',
                    responseType: 'blob'
                }).subscribe((data) => {
                    const fileName = extractFileName(data.headers.get('Content-Disposition'));
                    this.fileSaver.save(data.body, fileName)
                }, response => this.onError(response));
            }
        );
    }

    /**
     * Ошибка запроса
     */
    onError(response) {
        this.notificationService.error(response.errors.join("\n"));
    }

    isChangeableItem(dialog: TranscriptionDialog): boolean {
        return dialog.status.name === TranscriptionStatusEnum.NORMAL || dialog.status.name === TranscriptionStatusEnum.CHECKED;
    }

    isErrorItem(dialog: TranscriptionDialog): boolean {
        return dialog.status.name === TranscriptionStatusEnum.ERROR;
    }

    /**
     * Транскрибировать диалоги с ошибкой
     */
    async retryTranscription(): Promise<void> {
        await this.dataService.retryTranscription()

    }

    transcribe(dialog: TranscriptionDialog): void {
        this.dataService.transcribeDialog(dialog).then(() => {}, data => this.onError(data))
    }

    onStatusSetChange() {
        if (this.statusSet.suitableStatuses) {
            this.serverCollectionBridge.filter.statuses = this.statusSet.suitableStatuses.map(name => new TranscriptionStatus(name));
        } else {
            this.serverCollectionBridge.filter.statuses = null;
        }
        this.serverCollectionBridge.onFilterChange();
    }

    resetFilter() {
        this.searchString = null;
        this.statusSet = StatusSet.ALL;
        this.serverCollectionBridge.resetFilter();
    }

    /**
     * Удалить диалог
     *
     * @param item диалог
     */
    async delete(item: TranscriptionDialog) {
        await this.dataService.delete(item);
        this.deleteObj(item);
    }
}

/**
 * Запись длительности аудио в отформатированном виде в поле, чтоб не вызывать функцию из шаблона
 */
export function setAudioLength(dialog: TranscriptionDialog): TranscriptionDialog {
    dialog.formattedLength = calculateAudioLength(dialog.totalTime);
    dialog.replies?.forEach(reply => {
        reply.formattedLength = calculateAudioLength(reply.startTime);
    })
    return dialog;
}

/**
 * Вычисление длительности аудиозаписи в формате ММ:СС
 */
export function calculateAudioLength(time: number): string {
    return moment(Math.round(time * 1000)).format('mm:ss');
}

/**
 * Наборы статусов транскрибирования для фильтра по статусам
 */
class StatusSet {
    static readonly ALL = new StatusSet("ALL", "Все статусы");
    static readonly CHECKED = new StatusSet("CHECKED", "Проверенные", [TranscriptionStatusEnum.CHECKED]);
    static readonly UNCHECKED = new StatusSet("UNCHECKED", "Непроверенные", [TranscriptionStatusEnum.NORMAL]);
    static readonly ERROR = new StatusSet("ERROR", "С ошибкой", [TranscriptionStatusEnum.ERROR]);
    static readonly VALUES = [StatusSet.ALL, StatusSet.CHECKED, StatusSet.UNCHECKED, StatusSet.ERROR];

    name: string;
    title: string;
    suitableStatuses: TranscriptionStatusEnum[];

    constructor(name: string, title: string, suitableStatuses?: TranscriptionStatusEnum[]) {
        this.name = name;
        this.title = title;
        this.suitableStatuses = suitableStatuses;
    }

    suits(status: TranscriptionStatusEnum) {
        return this.suitableStatuses.includes(status);
    }

}

/**
 * Фильтр списка диалогов
 */
interface TranscriptionDialogFilter extends ServerCollectionFilter {
    statuses?: TranscriptionStatus[]
}