export class HighlightWord {
    /**
     * Слово
     */
    w: string;
    /**
     * Индекс слова в тексте
     */
    index: number;


    constructor(w: string, index: number) {
        this.w = w;
        this.index = index;
    }

    public static buildWordList(text: string): HighlightWord[] {
        if (!text) {
            return [];
        }
        return this.wordsSplit(text).map((word, index) => new HighlightWord(word, index));
    }

    public static buildSelectedWordList(selectedText: string, words: HighlightWord[]): HighlightWord[] {
        if (!selectedText) {
            return [];
        }
        // массив выбранных слов
        const selectedWords = this.wordsSplit(selectedText);

        const result: HighlightWord[] = [];

        let j = 0;
        selectedWords.forEach(selectedWord => {
            for (j; j < words.length; j++) {
                if (selectedWord.toLowerCase() === words[j].w.toLowerCase()) {
                    // кладём его в результат
                    result.push(new HighlightWord(words[j].w, j));
                    break;
                }
            }
            // искать выбранное слово нужно начиная с индекса предыдущего найденного + 1
            if (result.length !== 0) {
                j = result[result.length - 1].index + 1;
            } else {
                j = 0;
            }
        });
        return result;
    }

    /**
     * Функция для конвертации списка слов в строку
     */
    public static convertWordListToString(wordList: HighlightWord[]): string {
        if (!wordList) {
            return null;
        }
        // отсортируем в порядке упоминаемости в тексте (по индексу), затем возьмем только слова и склеим через пробел
        wordList.sort((a, b) => {
            if (a.index < b.index) {
                return -1;
            } else {
                return 1;
            }
        });
        return wordList.map((e) => e.w).join(" ");
    }

    private static wordsSplit(text: string): string[] {
        if (text == null) {
            return null;
        }
        return text.split(/[ \n.,!?%*;:{}=`~()]/).filter(part => part.length !== 0);
    };
}