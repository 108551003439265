import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ConfirmButtonDialogComponent} from "./dialog/confirm-button-dialog.component";


@Component({
    selector: 'confirm-button',
    template: require('./confirm-button.component.html'),
    styles: [require('./confirm-button.component.less')]
})

export class ConfirmButtonComponent implements OnInit {

    @Input()
    private isDialog: boolean;

    @Input()
    material: boolean;

    @Input()
    private delete: boolean;

    @Input()
    cssClass: string;

    @Input()
    faClass: string;

    @Input()
    buttonText: string;

    @Input()
    questionText: string;

    @Input()
    arbitraryPosition?: boolean;

    @Input()
    private object: any;

    @Input()
    private parent?: any;

    @Output()
    private confirm = new EventEmitter<void>();

    showInlineDialog: boolean = false;

    constructor(private dialog: MatDialog) {
    }

    ngOnInit(): void {
        if (typeof this.isDialog == 'undefined') {
            this.isDialog = true;
        }
        if (typeof this.buttonText == 'undefined') {
            this.buttonText = this.delete ? "Удалить" : "Подтвердить";
        }
        if (!this.questionText) {
            this.questionText = this.delete ? "Вы уверены, что хотите удалить объект?" : "Подтвердите выбранное действие";
        }
    }

    requestForConfirm(): void {
        const valid = this.parent?.validate ? this.parent.validate() : true;
        // Не прошла валидация - ничего не делаем
        if (!valid) {
            return;
        }

        if (this.isDialog) {
            const dialogRef = this.dialog.open(ConfirmButtonDialogComponent, {
                width: '500px',
                data: {questionText: this.questionText}
            });
            // Слушаем закрытие
            dialogRef.afterClosed()
                .subscribe((result: boolean) => {
                    if (result) {
                        this.confirm.emit();
                    }
                });
        } else {
            this.showInlineDialog = true;
        }
    };

    confirmInlineDialog(): void {
        this.confirm.emit();
        this.showInlineDialog = false;
    };

    cancelInlineDialog(): void {
        this.showInlineDialog = false;
    };
}