import {BaseFormController} from "../BaseFormController";
import urls from "../../urls";
import common from "../../common";

export class VABaseFormController extends BaseFormController {


    constructor(Restangular, $scope, $state, $stateParams, objId, formObj, formOptions, afterLoadCallback, baseUrl) {
        super($scope, $state);

        const that = this;

        // инициализируем форму
        this.initFormMode(formOptions);

        // форма редактирования ответа
        that.form.object = formObj;

        if ($stateParams['notifySuccess']) {
            // нужно вывести уведомление об успехе, показываем
            this.form.success = true;
            // убираем флаг "показать" из стейта
            $state.go('.', {notifySuccess: false});
        }

        // контроллер списка
        that.listController = $scope.$parent.ctrl;
        that.restAngular = Restangular;
        that.$stateParams = $stateParams;

        // id объекта
        that.objId = objId && objId !== "" ? objId : null;

        if (this.listController) {
            // selected obj in parent ctrl
            that.listController.selectedId = that.objId;
            $scope.$on("$destroy", function () {
                that.listController.selectedId = null;
            });
            that.baseUrl = that.listController.baseUrl;
        } else {
            that.baseUrl = baseUrl;
        }

        // поля, которые могут быть загружены
        that.attrs = [];
        that.tags = [];
        that.tags = [];
        that.answers = [];
        that.sma = [];
        that.access = true;

        if (objId) {
            that.setModeEdit();
            that.setLoading(true);
            // по ID загружаем объект
            that.restAngular.one(that.baseUrl, that.escapePathVariable(objId))
                .get()
                .then(function success(data) {
                    that.form.object = data;
                    if (afterLoadCallback != null) {
                        afterLoadCallback(that.form.object);
                    }
                }, function error(data) {
                    const isCheckObjectExist = $state.current.data.isCheckObjectExist;
                    // если объекта нет, то сделаем редирект на общий список
                    if (isCheckObjectExist) {
                        common.goToParentState($state);
                    }
                })
                .finally(function () {
                    that.setLoading(false);
                });

        } else {
            // нет id - создание нового объекта
            that.setModeCreate();
            that.access = true;
        }
    }

    /**
     * Функция сохранения объекта
     * @param afterSendCallback callback после успешного создания/обновления - первый параметр isNew - был ли создан новый объект
     * @param errorCallback callback при ошибке
     */
    commonSave(afterSendCallback, errorCallback) {
        const that = this;
        this.form.errors = [];

        // сохранить или обновить
        const saveFunc = that.mode.new ? that.restAngular.all(that.baseUrl).post : that.restAngular.all(that.baseUrl).patch;
        that.setLoading(true);

        saveFunc(this.form.object)
            .then(function success(data) {
                that.form.object = data;
                that.form.success = true;
                if (that.mode.new) {
                    that.setModeEdit();
                    // обновляем id объекта
                    that.objId = data.key ? data.key.id : data.id;
                    if (that.listController) {
                        //если новый объект, добавляем в список объектов в мастере
                        that.listController.addNewObj(that.form.object);
                    }
                    // создание
                    afterSendCallback(true, data);
                } else {
                    if (that.listController) {
                        // заменяем в мастере объект, который редактировали
                        that.listController.replaceObj(that.form.object);
                    }
                    // обновление
                    afterSendCallback(false, data);
                }
            }, function error(data) {
                if (errorCallback) {
                    errorCallback();
                }
                that.handleError(data);
            })
            .finally(function () {
                that.setLoading(false);
            });
    }

    /**
     * Функция удаления объекта
     * @param afterRemoveCallback callback после удаления объекта
     */
    commonRemove(afterRemoveCallback) {
        const that = this;
        that.setLoading(true);
        let id;
        if (that.form.object.key) {
            id = that.form.object.key.id;
        } else if (that.form.object.name) {
            id = that.form.object.name;
        } else {
            id = that.form.object.id;
        }
        that.restAngular.one(that.baseUrl, id).remove()
            .then(function success(data) {
                that.handleSuccess(data);
                if (that.listController) {
                    that.listController.deleteObj(that.form.object);
                }
                afterRemoveCallback();
            }, function error(data) {
                that.handleError(data);
            })
            .finally(function () {
                that.setLoading(false);
            });
    }

    /**
     * Функция получения доступа (по url: .../${id}/access) и сохранение его в поле .access
     */
    getAccess() {
        const that = this;
        that.restAngular.one(that.baseUrl, that.escapePathVariable(that.objId)).one("access").get()
            .then(function success(data) {
                that.access = data.access;
            }, function error(data) {
            });
    }

    /**
     * Загрузка связанных атрибутов (по url: .../${id}/attrs) и сохранение его в поле .attrs
     */
    loadAttributes() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "attrs");
    }

    /**
     * Загрузка dkb полей (по url: .../${id}/fields) и сохранение его в поле .fields
     */
    loadFields() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "fields");
    }

    /**
     * Загрузка связанных сценариев (по url: .../${id}/scripts) и сохранение их в поле .scripts
     */
    loadScripts() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "scripts");
    }

    /**
     * Загрузка dkb значений (по url: .../${id}/dkbValues) и сохранение его в поле .dkbValues
     */
    loadDkbValues() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "dkbValues");
    }


    /**
     * Загрузка dkb entites (по url: .../${id}/entites) и сохранение его в поле .entites
     */
    loadEntities() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "entities");
    }

    /**
     * Загрузка связанных тематик (по url: .../${id}/tags) и сохранение его в поле .tags
     */
    loadTags() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "tags");
    }

    /**
     * Загрузка связанных ответов (по url: .../${id}/answers) и сохранение его в поле .answers
     */
    loadAnswers() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "answers");
    }

    /**
     * Загрузка связанных процедур (по url: .../${id}/procedures) и сохранение его в поле .procedures
     */
    loadProcedures() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "procedures");
    }

    /**
     * Загрузка связанных стандартных ответов (по url: .../${id}/sma) и сохранение его в поле .sma
     */
    loadSMA() {
        return common.loadLinkedValues(this, this.baseUrl, this.objId, "sma");
    }

    /**
     * Получение текущего аккаунта
     */
    loadAccount() {
        const that = this;
        that.restAngular.one(urls.va.account).one("current").get()
            .then(function success(data) {
                that.account = data;
                that.account.isJunior = function () {
                    return that.account.expertType.name == 'JUNIOR';
                };
            }, function error(data) {
            });
    }


    escapePathVariable(pathVariable) {
        if (!pathVariable) {
            return pathVariable;
        }
        return pathVariable.replace(/\//g, '_escslash_', 'g')
            .replace(/\\/g, '_escbackslash_', 'g');
    }
}

VABaseFormController.$inject = ["Restangular", "$scope", "$state", "$stateParams"];
