import * as urls from "../../../../../../js/workplace/urls.js";
import * as common from "../../../../../../js/workplace/common.js";
import {VABaseListController} from "../../../../../../js/workplace/controllers/va/VABaseListController";
import * as restangular from "restangular";
import {DKBEntity, DKBField, DKBValue} from "../../../../data/va/Dkb";

export default class VaDkbValueController extends VABaseListController<DKBValue> {

    static $inject = ["Restangular", "$stateParams", "TitleService", "$state"];

    private entity: DKBEntity;
    private entityId: number;
    private field: DKBField;
    private fieldId: number;

    constructor(Restangular: restangular.IService, $stateParams: ng.ui.IStateParamsService, TitleService: any, $state: ng.ui.IStateService) {
        const entityId = $stateParams['dkbEntityId'];
        const fieldId = $stateParams['dkbFieldId'];

        // загружать значения нужно по id сущности /dkb/field/${id}/values
        super(TitleService, Restangular, $stateParams, urls.va.dkbValue, () => {
            Restangular.one(urls.va.dkbField, fieldId).getList("values")
                .then(data => {
                    this.objects = data;
                }, data => {
                    // если произошла ошибка при загрузке, то перейдем к дкб филдам
                    common.goToParentState($state);
                });
        });

        this.header = [
            {title: "Значение", field: "propertyValue"}
        ];

        this.entityId = entityId;
        this.fieldId = fieldId;

        // загружаем сущность
        Restangular.one(urls.va.dkb, entityId).get()
            .then(data => {
                this.entity = data;
            }, () => {
            });

        // загружаем свойство
        Restangular.one(urls.va.dkbField, fieldId).get()
            .then(data => {
                this.field = data;
            }, () => {
            });

        this.getAccess();
    }

    /**
     * Функция для форматирования условий на атрибуты (для красивого вывода на карточке)
     */
    formatAttributeCondition(ac) {
        return common.formatExtractableValues(ac);
    }
}