/**
 *  Для убирания срабатывания (click), когда должно сработать только (dblclick)
 *
 *   <a (click)="method1()" (dblclick)="method2()"></a>
 *
 * Inside your component:
 *
 *   class YourComponent {
 *      constructor() {
 *          const filter = new ClickFilter(this);
 *          this.method1 = filter.clickHandler(this.method1);
 *          this.method2 = filter.dblClickHandler(this.method2);
 *      }
 *   }
 *
 */
export default class ClickFilter {
    private singleClick = true;

    constructor(private handlerThis, private readonly timeout = 250) {
    }

    /**
     * Для оборачивания метода одинарного клика
     *
     * @param handler метод
     */
    clickHandler(handler: ClickHandler): ClickHandler {
        const filter = this;
        return function() {
            const handlerArguments = arguments;
            filter.singleClick = true;

            setTimeout(() => {
                filter.singleClick && handler.apply(filter.handlerThis, handlerArguments);
            }, filter.timeout);
        }
    }
    
    /**
     * Для оборачивания метода двойного клика
     *
     * @param handler метод
     */
    dblClickHandler(handler: ClickHandler): ClickHandler {
        const filter = this;
        return function() {
            const handlerArguments = arguments;
            filter.singleClick = false;

            setTimeout(() => {
                filter.singleClick = true;
            }, filter.timeout);

            handler.apply(filter.handlerThis, handlerArguments);
        }
    }
}

type ClickHandler = (...any) => void;