import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[appScrollableItem]'
})
export class ScrollableItemDirective {

    @Input('appScrollableItem') public key: string
    constructor(private el: ElementRef<HTMLElement>) {}

    /**
     * Плавно проскроллиться к элементу
     */
    public scrollIntoView() {
        this.el.nativeElement.scrollIntoView({  behavior: 'smooth', block:"nearest"});
    }

    /**
     * Быстро проскроллиться к элементу
     */
    public scrollIntoViewFast() {
        this.el.nativeElement.scrollIntoView({  block:"nearest"});
    }
}
