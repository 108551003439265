import {Component, OnDestroy, OnInit} from "@angular/core";
import {StateService} from "@uirouter/core";
import {DialogService} from "../../dialog/dialog.service";
import {HttpClient} from "@angular/common/http";
import WebSocketService from "../../../../services/WebSocketService";
import {Candidate} from "./prompter.model";
import {DialogChangeData} from "../chat.model";
import {Title} from "@angular/platform-browser";
import {ChatService} from "../chat.service";
import {ChatControlService} from "../chat-control.service";

@Component({
    selector: 'prompter',
    template: require('./prompter.component.html'),
    styles: [require('./prompter.component.less')]
})

export class PrompterComponent implements OnInit, OnDestroy {
    isLoading: boolean = false;
    private dialogId: number;
    private sessionId: string;
    candidates: Candidate[];
    private readonly subscriptionId: string;
    private closeDialogSubscription: any;

    /**
     * Выбранная подсказка
     */
    private candidate: Candidate;

    private subscription: any;

    constructor(private stateService: StateService,
                private dialogService: DialogService,
                public chatControlService: ChatControlService,
                private httpClient: HttpClient,
                private chatService: ChatService,
                private webSocketService: WebSocketService,
                private titleService: Title) {
        this.subscription = this.chatControlService.onDialogChange.subscribe(dialogChangeData => this.onDialogChange(dialogChangeData));
        this.closeDialogSubscription = this.chatControlService.closeDialog.subscribe(() => this.onCloseDialog())
        const title: string = this.stateService.current.data.title;
        this.titleService.setTitle(title);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
        this.closeDialogSubscription.unsubscribe();
        this.subscription.unsubscribe();
    }

    async onDialogChange(dialogChangeData: DialogChangeData) {
        this.dialogId = dialogChangeData.dialogId;
        this.sessionId = dialogChangeData.sessionId;
        this.candidates = await this.chatService.getCandidates(this.sessionId);
        this.candidates.map(params => {
            delete params.attributesExtracted.channel;
            return params;
        });
    }

    onChooseCandidate(candidate: Candidate) {
        if (!this.chatControlService.operator) {
            return;
        }
        this.candidates = null;
        this.candidate = candidate;
        this.chatControlService.candidate.emit(candidate);
    }

    onCloseDialog(){
        this.candidates = null;
    }
}
