import {Component, ContentChild, ElementRef, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {BaseDetailsComponent} from "../base-details.component";

@Component({
    selector: 'detail',
    template: require('./detail-wrapper.component.html'),
    styles: [require('./detail-wrapper.component.less')]
})
export class DetailWrapperComponent {

    @ContentChild('detailsHeader', {static: false})
    detailsHeader: TemplateRef<ElementRef>;

    @ContentChild('detailsBody', {static: false})
    detailsBody: TemplateRef<ElementRef>;

    @ContentChild('detailsFooter', {static: false})
    detailsFooter: TemplateRef<ElementRef>;

    public detailsComponent: BaseDetailsComponent<any, any>;

    @Input()
    showButtons: boolean = true;

    constructor(private _view: ViewContainerRef) {
        this.detailsComponent = this._view['_view'].component;
    }

}