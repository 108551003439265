import {CircleElement} from "./CircleElement";
import {VaScriptNode} from "../../../../../data/va/Script";
import {mergeDeep} from "../../../../../functions/UtilFunctions";

export class StartElement extends CircleElement {

    constructor(attributes: {node: VaScriptNode}) {
        super(attributes);
        mergeDeep(this.attributes, {
            attrs: {
                body: {
                    stroke: '#D53300',
                    fill: "#D53300",
                },
                label: {
                    fill: '#FFFFFF',
                }
            }
        });
    }

}