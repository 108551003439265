import {ChatRobotCommand} from "../dialog/model/reply.model";

export class DialogMessageToRobotArgs  {

    sessionId: string;
    message: string;
    buttonKey: string;
    licensePool: string;
    mrcpCode: string;
    params: {};
    timestamp: number;
    expertId: number;
    tagId: number;
    testing: boolean = true;
    buttonsSupport: boolean = true;
    prompter: boolean;
    attachments: Attachment[];
    mode: string;
    interrupted: number;

    constructor(sessionId: string, mode: string) {
        this.sessionId = sessionId;
        this.mode = mode;
    }
}

export class Attachment {
    filename: string;
    url: string;

    constructor(filename: string, url?: string) {
        this.filename = filename;
        this.url = url;
    }
}

export interface ChatOnReplyArgs {
    sessionId: string;
    error: string;
    commandParams: string;
    actCode: string;
    timestamp: number;
    tagId: number;
    replyId: number;
    dialogId: number;
    answer: any;
    voiceAnswer: any;
    command: ChatRobotCommand;
    attributesExtracted: {};
    attributesReseted: {};
}


export class MRCPCode {
    causeCode: string;
    description: string;
    command: string;
}

export class AutocompleteToRobotArgs {
    sessionId: string;
    text: string;

    constructor(sessionId: string, text: string) {
        this.sessionId = sessionId;
        this.text = text;
    }
}

export class ChatOnAutocompleteArgs {
    suggestions: Suggestion[];
}

export class Suggestion {
    tagId: number;
    suggestId: number;
    text: string;

    constructor(tagId: number, suggestId: number, text: string) {
        this.tagId = tagId;
        this.suggestId = suggestId;
        this.text = text;
    }
}

export class ScoreToRobotArgs {
    sessionId: string;
    score: number;
    type: ScoreType;

    constructor(sessionId: string, score: number, type: ScoreType) {
        this.sessionId = sessionId;
        this.score = score;
        this.type = type;
    }
}

export enum ScoreType {
    ABONENT= "ABONENT",
    OPERATOR = "OPERATOR"
}

export class DialogChangeData {
    dialogId: number;
    sessionId: string;
    userReply: boolean;


    constructor(dialogId: number, sessionId: string, userReply: boolean) {
        this.dialogId = dialogId;
        this.sessionId = sessionId;
        this.userReply = userReply;
    }
}