import {Component} from "../../../../backports";
import restangular from "restangular";
import * as urls from "../../../../../js/workplace/urls";


@Component({
    bindings: {
        // id модели, для которой выводится отчет
        modelId: "<",
        // предвыбраная в фильтре группа
        preselectedTag: "="
    },
    templateUrl: "/pages/workplace/va/common/components/grid/tagReplyGrid.html"
})
export default class VaTagReplyGridComponent {

    static $inject = ["Restangular", "uiGridConstants", "$scope"];

    private restangular: restangular.IService;

    private scope: ng.IScope;

    /**
     * Данные для отчета по репликам
     */
    private replyGrid: ReplyGridItem[];

    private gridConst: any;

    private gridApi: any;

    /**
     * Настройки для отображения отчета
     */
    private gridOptions: any;

    /**
     * ID модели
     */
    private modelId: number;

    /**
     * Идет ли загрузка данных?
     */
    private loading: boolean = true;

    /**
     * Название предвыбранной группы
     */
    private preselectedTag: string;

    constructor(restangular: restangular.IService, gridConst, scope) {
        this.restangular = restangular;
        this.gridConst = gridConst;
        this.scope = scope;

        // создаем watch на предвыбранную группу, чтобы динамически изменять фильтрацию по группе
        this.scope.$watch("$ctrl.preselectedTag", () => {
            this.handleSelectTag();
        });

        // загружаем данные
        this.loadData();
    }

    /**
     * Функция для определения класса у ячейки
     */
    private cellClass(grid, row, col, rI, cI): string {
        // если недостаточно уверенности, то подсвечиваем
        return !row.entity.enoughConfidence ? "bg-warn" : "";
    }


    /**
     * Загрузка данных для отчета
     */
    private loadData() {
        this.restangular.one(urls.va.tag_message_distribution + "/" + this.modelId + "/replyGrid")
            .getList()
            .then((replyGrid: ReplyGridItem[]) => {
                // сохраняем данные
                this.replyGrid = this.restangular.stripRestangular(replyGrid);
                // добавим в данные текстовый вариант bool поля enoughConfidenceText в виде да/нет
                angular.forEach(this.replyGrid, item => {
                    item.enoughConfidenceText = item.enoughConfidence ? "Да" : "Нет";
                });
                // переключаем флаг загрузки
                this.loading = false;
                // инициализируем параметры грида
                this.initGrid();
            });
    }

    /**
     * Функция для обработки смены предвыбранной групыы
     */
    private handleSelectTag() {
        // есди grid api уже есть (грид показан)
        if (this.gridApi != null) {
            // то заменяем term в фильтре у первой колонки на назаание предвыбранной группы
            this.gridApi.grid.columns[0].filters[0] = {
                term: this.preselectedTag
            };
        }
    }

    /**
     * Ициализация грида
     */
    private initGrid() {
        this.gridOptions = {
            // данные, по которым строится отчет
            data: this.replyGrid,
            // доступ к grid api (для обработки предвыбранной группы)
            onRegisterApi: (gridApi) => this.gridApi = gridApi,
            // можно изменять размер колонок
            enableColumnResizing: true,
            // можно фильтровать
            enableFiltering: true,
            // достуно меню грида справа сверху в углу
            enableGridMenu: true,
            // показыаать футер грида (с общим числом элементов)
            showGridFooter: true,
            // быстрый просмотр (т. к. данные ее меняются динамически)
            fastWatch: true,
            // минимальное кол-во отображаемых строк
            minRowsToShow: 25,
            // описание колонок
            columnDefs: [
                {
                    name: 'Тематика',
                    field: 'reply.tagName',
                    cellTemplate: require("raw-loader!./tag.html"),
                    cellClass: this.cellClass,
                },
                {
                    name: 'Реплика',
                    field: 'reply.text',
                    cellClass: this.cellClass,
                    cellTemplate: require("raw-loader!./reply.html")
                },
                {
                    name: 'Ближайшая реплика',
                    field: 'predictedReply.text',
                    cellClass: this.cellClass,
                    cellTemplate: require("raw-loader!./predictedReply.html")
                },
                {
                    name: 'Тематика ближайшей реплики',
                    field: 'predictedReply.tagName',
                    cellClass: this.cellClass,
                    cellTemplate: require("raw-loader!./predictedTag.html")
                },
                {
                    name: 'Достаточно ли уверенности',
                    field: 'enoughConfidenceText',
                    cellClass: this.cellClass
                }

            ],
        };
    }
}

interface Reply {
    text: string;
    handledText: string;
    tagId: number;
    tagName: string;
    showHandled: boolean;
}

interface ReplyGridItem {
    reply: Reply;
    enoughConfidence: boolean;
    enoughConfidenceText: string;
    predictedReply?: Reply;
}