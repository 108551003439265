import {Component, Input} from '@angular/core';


@Component({
    selector: "default-avatar",
    template: require("../../../../pages/workplace/components/defaultAvatar.html")
})
export default class DefaultAvatarComponent {

    @Input() imgClass: string;

    @Input() name: string;

    _avatarUrl: string;

    isLoaded: boolean = false;

    letters: string = "";

    src: string;

    @Input() set avatarUrl(value: string) {
        this._avatarUrl = value;
        this.letters = "";
        if (!this.name) {
            return;
        }
        let name = this.name.split(" ");
        for (let i = 0; i < name.length; i++) {
            let obj = name[i];
            this.letters += obj.charAt(0).toUpperCase();

            // но не больше двух букв
            if (this.letters.length == 2) {
                break
            }
        }

        if (!this._avatarUrl || this._avatarUrl.indexOf("default") != -1) {
            return;
        }
        let img = new Image();
        img.src = this._avatarUrl;
        let that = this;
        $(img).bind('load', function () {
            that.isLoaded = true;
            that.src = that._avatarUrl;
        });
    }

}