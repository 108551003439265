import {Component, OnDestroy} from "@angular/core";
import {BaseDetailsComponent} from "../../base/base-details.component";
import {isEnumExtractor, isVarExtractor, Scope, VaAttribute} from "../../../../../data/va/Attribute";
import {CompositeKey} from "../../../../../data/va/Common";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {AttributeService} from "../attribute.service";
import {Extractor, ValueOption} from "../../../../../data/va/Extractor";
import WebSocketService from "../../../../../services/WebSocketService";
import {ExtractorService} from "../../extractor/extractor.service";
import {AttributeMasterComponent} from "../master/attribute-master.component";
import {ChangePushEvent} from "../../base/base-data.service";
import {ChannelText} from "../../../../../data/va/Formulation";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";


@Component({
    selector: 'attribute-edit',
    template: require('./attribute-edit.component.html'),
    styles: [require('./attribute-edit.component.less')]
})
export class AttributeEditComponent
    extends BaseDetailsComponent<VaAttribute, CompositeKey<number>> implements OnDestroy {

    objectIdKey = 'attrId';

    selectTagsTextareaExcludeIds: string[];

    private subscriptionId: string;

    extractors: Extractor[];

    scopes: Scope[];

    channels: ValueOption[];

    constructor(stateService: StateService,
                httpClient: HttpClient,
                public dataService: AttributeService,
                master: AttributeMasterComponent,
                titleService: Title,
                notificationService: NotificationService,
                private webSocketService: WebSocketService,
                private extractorService: ExtractorService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    get entityTitle(): string {
        return this.form.object.name;
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();

        if (this.objId) {
            //при редактировании нельзя давать возможность ссылки в вопросе на текущий атрибут
            this.selectTagsTextareaExcludeIds = [this.objId];
        } else {
            // начальное количество запросов атрибута
            this.form.object.requestAmount = 2;
        }

        await this.loadChannels();

        await this.getScopes();

        await this.loadExtractors();

        // подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_ENTITY_CHANGE",
            fn: (event) => {
                const changeEvent: ChangePushEvent = JSON.parse(event.details);
                //если изменился состав экстракторов , то их надо перезагрузить
                if (this.stateService.params["projectVersionId"] === changeEvent.projectVersionId && changeEvent.className === "Extractor") {

                    //если изменился состав экстракторов , то их надо перезагрузить
                    this.loadExtractors(items => {
                        // экстрактор пришедший в уведомлении
                        const extractor: Extractor = changeEvent.object;

                        if (extractor) {
                            // ищем его в списке возможных для атрибута
                            const newExtractor = items.find(item => {
                                return item.key.id === extractor.key.id && item.key.projectVersionId === extractor.key.projectVersionId
                            });

                            // нашли, значит его можно проставить
                            if (newExtractor) {
                                // в форме создания атрибута выбираем создаваемый экстрактор
                                this.form.object.extractor = newExtractor;
                            }
                        }
                    });
                }
            }
        });

    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.webSocketService.removeListener(this.subscriptionId);
    }

    /**
     * Сбрасывает количество запросов до числа в пределах 1-10
     */
    onAmountChange() {
        if (this.form.object.requestAmount < 1) {
            this.form.object.requestAmount = 1;
        } else if (this.form.object.requestAmount > 10) {
            this.form.object.requestAmount = 10;
        }
    }

    onObjectLoaded() {
    }

    async loadChannels() {
        this.channels = await this.dataService.getChannelsWithAssistant();
    }

    async loadExtractors(callback?: (items: Extractor[]) => void) {
        let params = {};
        if (!this.isModeNew()) {
            params["forAttribute"] = this.objId;
        }
        this.extractors = await this.extractorService.findAll(params);
        if (callback) {
            callback(this.extractors);
        }
    }

    /**
     * Разрешено показать кнопку "Удалить"
     */
    isDeleteButtonAllow(): boolean {
        // кнопка удаления - только для существующих объектов и если достаточно прав
        return !this.isModeNew() && this.access;
    }

    /**
     * Разрешено показать кнопку "Сохранить"
     */
    isSaveButtonAllow(): boolean {
        if (!this.isModeNew()) {
            //при редактировании проверяем доступ
            return this.access;
        }
        return true;
    }

    async save(): Promise<void> {
        this.form.errors.clear();

        if (this.form.object?.apiKey?.includes(' ')) {
            this.form.errors.set("apiKey", "Ключ должен содержать только буквы латиницы, цифры, тире, подчеркивание");
            return;
        }

        //на форме выбирали целый экстрактор, чтобы по его настройкам считать поля в атрибуте
        this.form.object.extractorId = this.form.object.extractor != null ? this.form.object.extractor.key.id : 0;
        this.preHandleRepeatTexts();
        await super.save(true);

        if (this.form.object.key?.id) {
            if (this.form.errors.size == 0) {
                this.stateService.go('robot.attributes.edit', {
                    attrId: this.form.object.key.id,
                    notifySuccess: true,
                    mode: 'SHOW'
                });
                return;
            }
        }
    }

    /**
     * Генерация ключа атрибута
     */
    async generateAttributeKey() {
        // делаем запрос на генерацию ключа атрибута
        let id = this.objId ? this.objId : '0';
        let name = this.form.object.name ? this.form.object.name : " ";

        this.form.object.apiKey = await this.dataService.generateAttributeKey(id, name);
    }

    /**
     * Экстрактор с перечислением
     */
    isEnumExtractor(): boolean {
        return isEnumExtractor(this.form.object);
    }

    /**
     * Экстрактор без перечисления
     */
    isVarExtractor(): boolean {
        return isVarExtractor(this.form.object);
    }

    generateFormObject(): VaAttribute {
        return new VaAttribute();
    }

    private async getScopes() {
        this.scopes = await this.dataService.getScopes();
    }

    /**
     * Если голосовые вопросы не задавали, то обнуляем поле, иначе валидируем список голосовых вопросов
     */
    private preHandleRepeatTexts(): void {
        if (this.form.object.formulations) {
            this.form.object.formulations
                .forEach(formulation => {
                    // итоговый список
                    const result: ChannelText[] = [];
                    // тексты по apiKey
                    const textsByChannelKey: { [key: string]: ChannelText[] } = {};
                    // раскладываем тексты по каналам
                    formulation.channelTexts.forEach(channelText => {
                        let key = channelText.channelValueOption.apiKey;
                        if (textsByChannelKey.hasOwnProperty(key)) {
                            textsByChannelKey[key].push(channelText);
                        } else {
                            textsByChannelKey[key] = [channelText];
                        }
                    });
                    // Бежим по всем текстам по 1 каналу
                    Object.keys(textsByChannelKey).forEach(channel => {
                        const channelTexts = textsByChannelKey[channel];
                        // смотрим какие из них заполнены, а какие нет
                        const filled = channelTexts.filter(text => !this.isEmptyText(text));
                        const empty = channelTexts.filter(text => this.isEmptyText(text));
                        // если пустых текстов нет, то берем все
                        if (empty.length == 0) {
                            result.push(...channelTexts);
                            return;
                        }
                        // если заполнен 1 текст, а остальные пустые = берем только его
                        if (filled.length == 1) {
                            result.push(filled[0]);
                            // если заполнено больше 1 текста, но есть пустые = пустые заполним из первого
                        } else if (filled.length > 1) {
                            empty.forEach(channelText => channelText.text = filled[0].text.split(new RegExp("[\n\t\s\r]")).join(""));
                            result.push(...filled, ...empty)
                        }
                    });
                    // итоговый результат
                    formulation.channelTexts = result;
                });
        }
    }

    private isEmptyText(channelText: ChannelText) {
        // уберем все несимвольные знаки и склеим результат обратно, чтобы посмотреть есть ли текст в поле
        return channelText.text.trim().split(new RegExp("[\n\t\s\r]")).join("").length === 0 && !ChannelText.hasAttachments(channelText);
    }

    get removeConfirmMessage(): string {
        return `Вы уверены, что хотите удалить атрибут?`;
    }
}