import {NgModule} from "@angular/core";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {LicenseService} from "./license.service";
import {LicenseComponent} from "./license.component";
import {UIRouterModule} from "@uirouter/angular";
import {BoilerplateModule} from "../va/base/boilerplate.module";
import {CommonModule} from "@angular/common";
import {LicensePageModule} from "./license-page/license-page.module";
import {EruditeNgbDatepickerModule} from "../common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";
import {ChartModule} from "primeng/chart";

@NgModule({
    imports: [
        MatTableModule,
        MatSortModule,
        UIRouterModule,
        BoilerplateModule,
        CommonModule,
        LicensePageModule,
        EruditeNgbDatepickerModule,
        ChartModule
    ],
    declarations: [LicenseComponent],
    exports: [LicenseComponent],
    entryComponents: [LicenseComponent],
    providers: [LicenseService]
})

export class LicenseModule {
    static states = [
        {
            name: "settings.license",
            url: "/license",
            views: {
                "content@authorized": {
                    component: LicenseComponent
                }
            },
            data: {
                title: "Лицензии"
            }
        }
    ];
}
