import {NgModule} from "@angular/core";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MacroComponent} from "./macro.component";
import {MacroPreviewComponent} from "./macro-preview.component";
import {ExtractableValuesModule} from "../../va/extractable-values/extractable-values.module";
import {MarkupComponent} from "./dialog/markup/markup.component";
import {FormControlModule} from "../form/form-control.module";
import {MatDialogModule} from "@angular/material/dialog";
import {DatetimePickerModule} from "../datetime-picker/datetime-picker.module";
import {MacroAudioRecordComponent} from "./dialog/audio-record/macro-audio-record.component";
import {MacroDkbComponent} from "./dialog/dkb/macro-dkb.component";
import {EruditeAudioPlayerModule} from "../player/erudite-audio-player.module";
import {EruditeFileUploaderModule} from "../file-uploader/erudite-file-uploader.module";
import {AudioRecordModule} from "../../media-libraries/audio-record/audio-record.module";
import {UIRouterModule} from "@uirouter/angular";
import {MacroService} from "./macro.service";
import {TextToSpeechModule} from "../../media-libraries/text-to-speech/text-to-speech.module";
import {AngularMyDatePickerModule} from "angular-mydatepicker";
import {AttachmentDialogComponent} from "../attachment-dialog/attachment-dialog.component";
import {BoilerplateModule} from "../../va/base/boilerplate.module";

@NgModule({
    declarations: [
        MacroComponent,
        MacroPreviewComponent,
        MacroDkbComponent,
        MarkupComponent,
        MacroAudioRecordComponent
    ],
    imports: [
        NgSelectModule,
        NgbPaginationModule,
        FormsModule,
        CommonModule,
        ExtractableValuesModule,
        FormControlModule,
        MatDialogModule,
        DatetimePickerModule,
        EruditeAudioPlayerModule,
        EruditeFileUploaderModule,
        AudioRecordModule,
        UIRouterModule,
        TextToSpeechModule,
        AngularMyDatePickerModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        BoilerplateModule
    ],
    exports: [
        MacroComponent,
        MacroPreviewComponent,
        MacroDkbComponent,
        MarkupComponent,
        MacroAudioRecordComponent
    ],
    entryComponents: [
        MacroPreviewComponent,
        MacroDkbComponent,
        MarkupComponent,
        MacroAudioRecordComponent,
        AttachmentDialogComponent

    ], providers: [
        MacroService
    ]
})
export class MacroModule {

}