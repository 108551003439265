import * as urls from "../../../../../js/workplace/urls.js";
import restangular from "restangular";
import * as common from "../../../../../js/workplace/common.js";

import * as tagBuildingFunctions from "../../../../../js/workplace/controllers/va/tagbuilding/VaTBFunctions";
import {DatePickerOptions, dateStringObject} from "../../../data/va/Common";
import {VaTag} from "../../../data/va/Tag";
import {AssessorStatistic, TagChangeEventWrapper, VAMessageTagChangeEvent} from "../../../data/va/VaAssessor";
import * as moment from 'moment';


export default class VaAssessorReportController {

    private timeout: ng.ITimeoutService;

    private restAngular: restangular.IService;

    private baseUrl: string;

    private fileSaver: any;

    private state: ng.ui.IStateService;

    private stateParams: ng.ui.IStateParamsService;

    private http: angular.IHttpService;

    private uibModal: any;

    private datepickerOptions: DatePickerOptions;

    private error?: string;

    private date: { endDate: any; startDate: any; enabled: boolean };

    private percent: number;

    private min: number;

    private max: number;

    private isLoading: boolean;

    private noDataFound: boolean;

    private tagChangeEvents: TagChangeEventWrapper[];

    private selectedAssessor: {
        fullName: string;
        id: number
    };

    private tags: VaTag[] = [];

    private assessors: { id: number }[] = [];

    private assessorStatistic: AssessorStatistic;

    constructor(Restangular: restangular.IService, $state, $stateParams: ng.ui.IStateParamsService, $uibModal, $http: angular.IHttpService, FileSaver, $timeout, TitleService) {
        TitleService.setTitle();
        this.restAngular = Restangular;
        this.timeout = $timeout;
        this.state = $state;
        this.stateParams = $stateParams;
        this.uibModal = $uibModal;
        this.http = $http;
        this.fileSaver = FileSaver;
        this.baseUrl = urls.va.assessor_report;
        // Параметры для datepicker-а
        this.datepickerOptions = common.rangeDatepickerOpts;
        this.date = {
            startDate: moment().subtract(7, 'days'),
            endDate: moment().add(1, 'days'),
            enabled: true
        };
        this.percent = 100;
        this.min = 0;
        this.max = 100000;
        this.error = null;
        this.loadSelectOptions()
    }

    /**
     * Загружаем опции для селектов на странице
     */
    loadSelectOptions(): void {
        // асессоры
        this.restAngular.one(this.baseUrl)
            .get()
            .then(data => {
                    this.assessors = this.restAngular.stripRestangular(data);
                }, error => {
                    this.error = error.data.message;
                    this.state.go('^');
                }
            )

        // тематики
        this.restAngular.one(this.baseUrl, "tagList")
            .get()
            .then(data => {
                this.tags = this.restAngular.stripRestangular(data);
            }, error => {
                this.error = error.data.message;
            });
    }

    loadTagChangeEvents(): void {
        this.error = null;

        if (!Number.isFinite(this.percent)
            || !Number.isFinite(this.min)
            || !Number.isFinite(this.max)) {
            this.error = "Укажите все параметры выборки";
            return;
        }

        this.isLoading = true;
        this.noDataFound = false;

        this.restAngular.one(this.baseUrl)
            .post("tagChangeEvents", {
                startDate: dateStringObject(this.date.startDate),
                endDate: dateStringObject(this.date.endDate),
                assessorId: this.selectedAssessor.id,
                percent: this.percent,
                min: this.min,
                max: this.max
            })
            .then(data => {
                this.tagChangeEvents = this.restAngular.stripRestangular(data);
                this.isLoading = false;
                if (!this.tagChangeEvents || this.tagChangeEvents.length === 0) {
                    this.noDataFound = true;
                } else {
                    this.tagChangeEvents.forEach(e => {
                        const sequence = e.right;
                        if (sequence && sequence.length > 1) {
                            const last: VAMessageTagChangeEvent = sequence[0];
                            const prev = sequence[1];
                            if (last == null || prev == null) {
                                return;
                            }
                            if (last.expertId === prev.validatorId && last.oldTagId === prev.newTagId) {
                                e.validatorEvent = last;
                                prev.expertTag = last.newTagId;
                                const tagById = this.getTagById(last.newTagId);
                                prev.expertTagName = tagById.text;
                                sequence.shift();
                            }
                        }
                    })
                }
            }, error => {
                this.isLoading = false;
                this.error = error.data.message;
            });

        this.loadAssessorStatistic();
    }

    loadAssessorStatistic() {
        this.restAngular.one(this.baseUrl)
            .post("assessorStatistic", {
                startDate: dateStringObject(this.date.startDate),
                endDate: dateStringObject(this.date.endDate),
                assessorId: this.selectedAssessor.id,
                percent: this.percent,
                min: this.min,
                max: this.max
            })
            .then(data => {
                this.assessorStatistic = this.restAngular.stripRestangular(data);
            }, error => {
                this.error = error.data.message;
            })
    }

    getTagById(tagId: number): VaTag {
        return this.tags.find(tag => tag.key.id === tagId);
    }

    getTagNameById(tagId): string {
        const find = this.tags.find(tag => tag.key.id === tagId);
        if (!find) {
            return "";
        }
        return find.text;
    }

    getOldTagValue(event: TagChangeEventWrapper) {
        const changeEvent = event.right[0];
        return changeEvent.oldTagId ? this.getTagNameById(changeEvent.oldTagId) : '-';
    }

    getNewTagValue(event: TagChangeEventWrapper) {
        const changeEvent = event.right[0];
        if (changeEvent.deleteTag) {
            return '-';
        }
        return changeEvent.newTagId ? this.getTagNameById(changeEvent.newTagId) : '-';
    }

    exportToExcel(): void {
        this.error = null;
        if (!Number.isFinite(this.percent)
            || !Number.isFinite(this.min)
            || !Number.isFinite(this.max)) {
            this.error = "Укажите все параметры выборки";
            return;
        }

        this.restAngular.one(this.baseUrl)
            .withHttpConfig({responseType: 'arraybuffer'})
            .post("report", {
                startDate: dateStringObject(this.date.startDate),
                endDate: dateStringObject(this.date.endDate),
                assessorId: this.selectedAssessor.id,
                percent: this.percent,
                min: this.min,
                max: this.max
            })
            .then((data) => {
                const file = new Blob([data], {type: "application/vnd.ms-excel"});
                let userName = this.selectedAssessor.fullName;
                let startDate = moment(this.date.startDate).format("DD.MM.YYYY");
                let endDate = moment(this.date.endDate).format("DD.MM.YYYY");
                let reportFileName = "[" + userName + "]_отчёт_по_пользователю_c_" + startDate + "_по_" + endDate + ".xls";
                if (!reportFileName.endsWith(".xls")) {
                    reportFileName = `${reportFileName}.xls`
                }
                this.fileSaver.saveAs(file, reportFileName);
            }, (error) => {
                this.error = "Не удалось сгенерировать отчет";
            });
    }

    openTBDialogModal(dialogId, messageId): void {
        const that = this;
        tagBuildingFunctions.openTBDialogModal(that, urls.va.tagbuilding + "/dialog", dialogId, messageId, 'ASSESSOR_REPORT');
    }

    onSetCorrect(event: TagChangeEventWrapper): void {
        if (event.validatorEvent) {
            this.removeValidatorEvent(event);
        }
    }

    onExpertTagChange(event: TagChangeEventWrapper): void {
        let newEvent: TagChangeEventWrapper = angular.copy(event);
        const value: VAMessageTagChangeEvent = newEvent.right[0];
        if (!value.expertTag) {
            event.error = "Необходимо выбрать тематику";
            return;
        } else {
            event.error = null;
        }

        if (event.validatorEvent) {
            const updatedValidatorEvent = angular.copy(event.validatorEvent);
            updatedValidatorEvent.newTagId = value.expertTag;

            this.restAngular.one(this.baseUrl, "updateEvent")
                .patch(updatedValidatorEvent).then(function (data) {
                event.validatorEvent = data;
            });
        } else {
            value.oldTagId = value.newTagId;
            value.newTagId = value.expertTag;
            this.saveEvent(newEvent, (validatorEvent: VAMessageTagChangeEvent) => {
                event.validatorEvent = validatorEvent;
            });
        }
    }

    onCommentChange(event): void {
        if (event.commentSaveTimeout) {
            this.timeout.cancel(event.commentSaveTimeout);
        }
        event.commentSaveTimeout = this.timeout(() => {
            if (event.right[0].comment) {
                this.saveEvent(event);
            }
        }, 2000);
    }

    saveEvent(event: TagChangeEventWrapper, callback?: (validatorEvent: VAMessageTagChangeEvent) => void) {
        const value = event.right[0];
        if (value.isCorrect) {
            event.error = null;
        } else {
            if (!value.expertTag && (!value.deleteMessage && !value.deleteTag)) {
                event.error = "Необходимо выбрать тематику";
                return;
            }
        }

        this.restAngular.one(this.baseUrl)
            .post("saveEvent", {
                left: event.left.messageId,
                right: event.right[0]
            }).then(function (data) {
            if (callback) {
                callback(data)
            }
        });
    }

    removeValidatorEvent(event: TagChangeEventWrapper): void {
        const messageId = {messageId: event.validatorEvent.messageId, type: event.validatorEvent.type};
        const eventId = event.validatorEvent.key.id;
        this.restAngular.one(this.baseUrl)
            .post("removeValidatorEvent", event.right[0], {
                messageId: event.validatorEvent.messageId,
                type: event.validatorEvent.type,
                eventId: eventId
            }).then(function () {
            event.validatorEvent = null;
        });
    }

    save(): void {
        const ids = this.tagChangeEvents.map(pair => pair.right[0].key.id);

        this.restAngular.one(this.baseUrl)
            .post("freezeEvents", ids)
            .then(() => {
                this.tagChangeEvents.forEach(e => e.right[0].editingForbidden = true)
            });
        this.loadAssessorStatistic();
    }

    change(event: TagChangeEventWrapper): void {
        const lastEvent = event.right[0];

        this.restAngular.one(`${this.baseUrl}`)
            .post("removeDeletedTagOrMessage", event.right[0]).then((data) => {
            lastEvent.deleteTag = null;
            lastEvent.deleteMessage = null;
            if (lastEvent.isCorrect) {
                delete lastEvent.expertTag;
                this.saveEvent(event, (validatorEvent: VAMessageTagChangeEvent) => {
                    this.onSetCorrect(event);
                    event.validatorEvent = validatorEvent;
                });
            } else {
                event.error = "Необходимо выбрать тематику";
            }
        });
    }

    changeMessageDeletion(event: TagChangeEventWrapper): void {
        const lastEvent = event.right[0];

        lastEvent.isCorrect = null;
        lastEvent.deleteTag = false;

        const eventId = event.validatorEvent?.key.id;

        if (lastEvent.deleteMessage) {
            this.restAngular.one(`${this.baseUrl}`)
                .post("setDeleteMessage", event.right[0], {
                    validatorEventId: eventId
                }).then(function (data) {
                event.right[0] = data;
            });
        }
    }

    changeTagDeletion(event: TagChangeEventWrapper): void {
        const lastEvent = event.right[0];

        lastEvent.isCorrect = null;
        lastEvent.deleteMessage = false;

        const eventId = event.validatorEvent?.key.id;

        if (lastEvent.deleteTag) {
            this.restAngular.one(`${this.baseUrl}`)
                .post("setDeleteTag", event.right[0], {
                    validatorEventId: eventId
                }).then(function (data) {
                event.right[0] = data;
            });
        }
    }

    openTaggingItemModalHandler(tagChangeEvent: TagChangeEventWrapper, thisCtrl: any) {
        this.uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: '/pages/workplace/modal/taggingItemModal.html',
            controller: 'VaTaggingItemModalController',
            controllerAs: '$ctrl',
            size: 'lg',
            appendTo: angular.element(document).find("body"),
            resolve: {
                onTagSelected: function () {
                    return function (tag: VaTag) {
                        tagChangeEvent.right[0].expertTag = tag.key.id;
                        tagChangeEvent.right[0].expertTagName = tag.text;
                        thisCtrl.onExpertTagChange(tagChangeEvent);
                    };
                }
            }
        });
    };


    isDisabledTagSelect(tagChangeEvent: VAMessageTagChangeEvent): boolean {
        return tagChangeEvent.editingForbidden || (!tagChangeEvent.editingForbidden && (tagChangeEvent.isCorrect !== false));
    };

}

VaAssessorReportController.$inject = ["Restangular", "$state", "$stateParams", "$uibModal", "$http", "FileSaver", "$timeout", "TitleService"];