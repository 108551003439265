import {DialogContext} from "./dialog-context.model";
import {Reply} from "./reply.model";
import {VaTag} from "../../../../data/va/Tag";
import {MLComponentType} from "../../../../controllers/va/ml/models/ml.model";
import {ExtractedValue} from "./extracted-value.model";
import {CompositeKey} from "../../../../data/va/Common";
import {AudioRecord} from "../../../../data/va/AudioRecord";
import {VAScript} from "../../../../data/va/Script";

export interface Dialog {
    id: number;
    projectVersionId: string;
    userId: number;
    conversationId?: string;
    lastShowcaseId: number;
    expertId: number;
    startTime: Date;
    processTime?: Date;
    finishTime?: Date;
    comment?: string;
    rating?: number;
    finished: boolean;
    testing: boolean;
    copyFrom?: string;
    replies?: Reply[];
    modelVersions?: ModelVersion[];
    updateTime: Date;
    expertRating?: number;
    va: boolean;
    modelDataAvailable: boolean;
    dialogContext?: DialogContext;
    infoFromIndex?: DialogSearchResult;
    tagsInDialog?: VaTag[];
    expert?: any;
    trainState: DialogTrainState;
    badForTask: any[];
    extractedValues: ExtractedValue[];
    finishScript: string;
    audioRecords?: AudioRecord[];
    superExpertRating?: number;
    mode: SessionMode;
    sentiment?: { title: string, name: string };
    sentimentScore?: number;
}

export class DialogDTO {
    dialog: Dialog;
    scripts: VAScript[];
}

export class SessionMode {
    name: SessionModeEnum;
    title: string;
}

export enum SessionModeEnum {
    ROBOT = 'ROBOT',
    PROMPTER = 'PROMPTER',
    ROBOT_AND_PROMPTER = 'ROBOT_AND_PROMPTER',
}

export class ModelVersion {
    id: number;
    projectVersionId: string;
    updated: Date;
    type: MLComponentType;
    name: string;
}

export class DialogSearchResult {

}

export class CheckState {
    name: CheckStateEnum;
    title: string;
    statEventField: any;
}

export enum CheckStateEnum {
    UNCHECKED = "UNCHECKED",
    VALID = "VALID",
    CORRECTIONS_ONLY = "CORRECTIONS_ONLY"

}

export class DialogTrainState {
    key: CompositeKey<number>;
    dialogVersionId: string;
    checkState: CheckState;
    autocheck: boolean;
    deleted: boolean;
    expertId: number;
    d: Date;
    creationDate: Date;
    expert: any;

    constructor() {
    }
}

export interface DialogCopyResult {
    newDialogId?: number;
    absentParts: DialogAbsentPart[];
    error?: string;
    sourceVersionId: string;
    targetVersionId: string;
}

export interface DialogAbsentPart {
    type: DialogAbsentPartType;
    name: string;
    id: any;
}

export interface DialogAbsentPartType {
    name: DialogAbsentPartTypeEnum;
    title: string;
}

export enum DialogAbsentPartTypeEnum {
    TAG = 'TAG',
    ATTRIBUTE = 'ATTRIBUTE',
    VALUE_OPTION = 'VALUE_OPTION',
    SCENARIO = 'SCENARIO',
    NODE = 'NODE'
}
