import {Component, Inject, ViewEncapsulation} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material";

export enum NotificationType {
    INFO = "INFO",
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    ERROR = "ERROR",
}

export interface NotificationButton {
    title: string;
    class: string;
    callback: () => any;
}

@Component({
    selector: 'notification-component',
    template: require('./notification.component.html'),
    styles: [require('./notification.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class NotificationComponent {

    public message: string;
    public type: NotificationType;
    public dismiss: any;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string, type: NotificationType, dismiss: any, buttons?: NotificationButton[]}) {
        this.message = this.data.message;
        this.type = this.data.type;
        this.dismiss = this.data.dismiss
    }

}