
export default class StoreService {

    private store: StoreJsAPI;

    constructor() {
        this.store = require("store-js/js/store.js");
    }

    get(key: string) {
        return this.store.get(key);
    }

    set(key: string, value: any) {
        this.store.set(key, value);
    }

}