import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FileSaverModule} from "ngx-filesaver";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {BoilerplateModule} from "../va/base/boilerplate.module";
import {EruditeFileUploaderModule} from "../common/file-uploader/erudite-file-uploader.module";
import {CollectionComponent} from "../common/collection/collection.component";
import {FormControlModule} from "../common/form/form-control.module";
import {CollectionModule} from "../common/collection/collection.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {ProjectVersionService} from "./project-version.service";
import {ProjectVersionMasterComponent} from "./master/project-version-master.component";
import {ProjectVersionEditComponent} from "./details/project-version-edit.component";
import {ProjectModule} from "../project/project.module";
import {VersioningProgressBarModule} from "../common/versioning-progress-bar/versioning-progress-bar.module";
import {EruditeNgbDatepickerModule} from "../common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        FileSaverModule,
        UIRouterUpgradeModule,
        CollectionModule,
        FormControlModule,
        NgbModule,
        EruditeFileUploaderModule,
        BoilerplateModule,
        NgSelectModule,
        ProjectModule,
        VersioningProgressBarModule,
        EruditeNgbDatepickerModule
    ],
    declarations: [
        ProjectVersionMasterComponent,
        ProjectVersionEditComponent,
    ],
    entryComponents: [
        CollectionComponent
    ],
    exports: [
        ProjectVersionMasterComponent,
        ProjectVersionEditComponent
    ],
    providers: [
        ProjectVersionService
    ]
})
export class ProjectVersionModule {

    static states = [
        {
            name: 'robot.project_versions',
            url: "/project_versions",
            views: {
                "content@authorized": {
                    component: ProjectVersionMasterComponent
                }
            },
            data: {
                title: "Версии проектов",
                requiredProjectVersion: false
            }
        },
        {
            name: 'robot.project_versions.edit',
            url: "/:editProjectVersionId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.project_versions': {
                    component: ProjectVersionEditComponent
                }
            },
            data: {
                title: 'Версии проектов | ',
                newObjectTitle: "Создание версии проекта",
                editObjectTitle: "Редактирование версии проекта",
                successfulSaveMessage: "Версия проекта сохранена",
                meta: {
                    parameters: ["editProjectVersionId"],
                    sectionFunction: ([projectId]) => "Обучение робота",
                    subSectionFunction: ([projectId]) => projectId ? "Редактирование версии проекта" : "Создание версии проекта"
                },
                requiredProjectVersion: false,
                isCheckObjectExist: true
            }
        }
    ];

}