import urls from "../../../urls";
import common from "../../../common";
// контроллер страницы со списком правил блокировки
export default class AntifraudRulesController {

    constructor(Restangular, $state, TitleService) {
        var that = this;
        TitleService.setTitle();
        that.$state = $state;
        that.header = [
            {title: "Ключ", field: "key"}
        ];

        that.restAngular = Restangular;
        that.baseUrl = urls.chat.antifraudrule;

        // список DTO
        common.loadValues(that, that.baseUrl, "rules", null, true);
        common.getAccess(that.restAngular, that.baseUrl,  that);

    }

    // добавить в список
    addRule(rule) {
        this.rules.push(angular.copy(rule));
    }

    // заменить правило
    replaceRule(rule) {
        for (var i = 0; i < this.rules.length; i++) {
            var obj = this.rules[i];
            if (obj.id == rule.id) {
                // копируем объект, чтобы отвязать объект в списке, от объекта в форме редактирования
                this.rules[i] = angular.copy(rule);
                break;
            }
        }
    }
}

AntifraudRulesController.$inject = ["Restangular", "$state", "TitleService"];
