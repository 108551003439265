import {Component, EventEmitter, Inject, Input, LOCALE_ID, Output} from "@angular/core";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";
import {LicenseUsagePage} from "../models/license.model";
import {LicenseConfigCountedUsageModel} from "../models/license-config-counted-usage.model";
import {LicenseConfigPoolUsageModel} from "../models/license-config-pool-usage.model";
import {formatDate} from "@angular/common";


@Component({
    selector: 'license-page',
    template: require('./license-page.component.html'),
    styles: [require('./license-page.component.less')]
})

export class LicensePageComponent {
    @Input()
    set rows(value: LicenseUsagePage) {
        this.rowsIncrementals = this.convertCountedLicense(value.incrementals);
        this.rowsPeriodics = this.convertCountedLicense(value.periodics);
        this.rowsChannels = this.convertPoolIdLicense(value.channels.sort((a, b) => a.poolId.localeCompare(b.poolId)));
        this.rowsPrompters = this.convertPoolIdLicense(value.prompters);
    }

    @Input()
    access: boolean;

    @Input()
    emptyMessage: string;

    @Input()
    set initialSwitch(value: boolean) {
        this.switch = value;
    };

    @Output()
    switchIncremental: EventEmitter<boolean> = new EventEmitter<boolean>();

    headers: string[] = ["Номер лицензии", "Следующее обновление", "Всего", "Используется сейчас", "Сколько использовано"];
    poolHeaders: string[] = ["Номер лицензии", "Когда истекает", "Пул", "Используется сейчас", "Использовано средне"];

    rowsIncrementals: any[][];
    rowsPeriodics: any[][];
    rowsChannels: any[][];
    rowsPrompters: any[][];

    switch: boolean;

    constructor(@Inject(LOCALE_ID) private locale: string,
                protected stateService: StateService,
                protected titleService: Title) {
    }

    switchToggle(): void {
        if (this.access) {
            this.switch = !this.switch;
            this.switchIncremental.emit(this.switch);
        }
    }

    /**
     * Конвертируем данные с бека из LicenseConfigCountedUsageModel в строку для таблицы
     */
    convertCountedLicense(licenses: LicenseConfigCountedUsageModel[]): any[][] {
        return licenses.map(item => {
            let row: any[] = [];
            // номер лицензии
            row.push(item.configId);
            //следующее обновление
            row.push(item.active ? (item.resetDate ? this.dateConverter(item.resetDate) : "Бессрочная") : '-');
            //всего
            row.push(item.limit);
            //используется сейчас
            row.push(item.currentlyUsed);
            //сколько использовано
            row.push({
                type: "PROGRESS_BAR",
                value: {
                    strValue: item.usedOverall + " <span class='gray-400'> / " + item.limit + "</span>",
                    percent: item.usedOverall / item.limit * 100
                }
            });
            return row;
        });
    }

    /**
     * Конвертируем данные с бека из LicenseConfigPoolUsageModel в строку для таблицы
     */
    convertPoolIdLicense(licenses: LicenseConfigPoolUsageModel[]): any[][] {
        let array = licenses.map(item => {
            let row: any[] = [];
            //номер лицензии
            row.push(item.configId);
            //когда истекает
            row.push(this.dateConverter(item.expires));
            //пул
            row.push(item.poolId);
            //используется сейчас
            row.push(item.currentlyUsed + " / " + item.limit);
            //использовано средне
            row.push(item.average.toFixed());
            return row;
        });

        // При одинаковых номерах лицензии надо удалить поля "Номер лицензии" и "Когда истекает"
        for (let i = array.length - 1; i >= 1; i--) {
            if (array[i][0] == array[i - 1][0]) {
                array[i][0] = '';
                array[i][1] = '';
            }
        }

        return array;
    }

    dateConverter(timestamp: number): string {
        let date = new Date(timestamp);
        return formatDate(date, 'dd.MM.yyyy', this.locale);
    }
}
