import {NgModule} from "@angular/core";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {EditableListComponent} from "./editable-list.component";
import {MatCardModule} from "@angular/material/card";
import {SafeHtmlPipe} from "./safe-html.pipe";
import {EruditeIconModule} from "../erudite-icon/erudite-icon.module";

@NgModule({
    declarations: [
        EditableListComponent,
        SafeHtmlPipe
    ],
    imports: [
        NgSelectModule,
        NgbPaginationModule,
        FormsModule,
        CommonModule,
        MatCardModule,
        EruditeIconModule
    ],
    exports: [
        EditableListComponent,
        SafeHtmlPipe
    ]
})
export class EditableListModule {

}