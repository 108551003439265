/**
 * Сервис хранения основной информации о текущем аккаунте
 */
import WebSocketService from "./WebSocketService";

type LogoutListener = () => Promise<any>;

export default class AccountService {

    restAngular: any;
    tabService: any;
    webSocketService: WebSocketService;
    data: any = {};
    statusUrl: string = "/account/status";
    statusTimeoutUrl: string = "/account/status/statusTimeout";
    logoutUrl: string = "/account/logout";
    private logoutListeners: LogoutListener[] = [];
    subscriptionId: any;
    expertAlwaysTestWS: any;

    constructor(private $rootScope,
                private $state,
                private Restangular,
                private TabService,
                private WebSocketService) {
        const that = this;
        this.$state = $state;
        this.restAngular = Restangular;
        this.tabService = TabService;
        this.webSocketService = WebSocketService;

        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "ACCOUNT_BLOCKED",
            // Выкидываем пользователя, если поймали соответствующий ивент на этом аккаунте
            fn: (event) => that.logout()
        });

        $rootScope.$on("$destroy", function handler() {
            that.webSocketService.removeListener(that.subscriptionId);
        });
    }

    getAccountData() {
        return this.data;
    }

    isAdmin() {
        return this.data.user.account.role.name == 'ADMINISTRATOR';
    }

    isSupervisor() {
        return this.data.user.account.role.name == 'SUPERVISOR';
    }

    setUser(user) {
        var that = this;
        that.data.user = user;
        if (that.data.user) {
            // загрузим статус
            that.refreshStatus();
        }
    }

    // save flag 'expertAlwaysTestWS', for hide 'testing' button for (super)expert in this case
    setExpertAlwaysTestWS(expertAlwaysTestWS) {
        this.expertAlwaysTestWS = expertAlwaysTestWS;
    }

    getExpertAlwaysTestWS() {
        return this.expertAlwaysTestWS;
    }

    removeLogoutListener(listenerToDelete) {
        let start = this.logoutListeners.findIndex(listenerToDelete, (listener) => {
            return listener === listenerToDelete
        });
        this.logoutListeners.splice(start, 1);
    }

    addLogoutListener(listener: LogoutListener) {
        this.logoutListeners.push(listener);
    }

    setStatus(statusData) {
        var that = this;
        that.restAngular.all(that.statusUrl).post(statusData)
            .then(function success(data) {
                that.data.statusData = data;
            });
    }

    setSelectedConversationId(conversationId) {
        var that = this;
        that.data.user.selectedConversationId = conversationId;
    }

    refreshStatus() {
        var that = this;
        that.restAngular.one(that.statusUrl).get()
            .then((data) => {
                that.data.statusData = data;
            });
    }

    logout() {
        var that = this;

        function sendLogoutRequest() {
            that.restAngular.one(that.logoutUrl).post()
                .then((data) => {
                    that.tabService.broadcast("logout", {});
                    if (data.redirect) {
                        // oauth2-авторизация - "ручное" перенаправление на внешнюю страницу логаута
                        window.location.replace(data.redirect);
                    } else {
                        location.reload();
                    }
                });
        }

        Promise.all(that.logoutListeners.map(listener => listener()))
            .then(ignoredData => sendLogoutRequest(),
                errorData => sendLogoutRequest());
        that.logoutListeners = [];
    }
}

AccountService.$inject = ["$rootScope", "$state", "Restangular", "TabService", "WebSocketService"];
