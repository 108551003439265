import {CompositeKey} from "./Common";
import {MacroObject} from "../../io/components/common/macro/object/MacroObject";
import {VaTag} from "./Tag";
import {EntityUsage} from "../../io/components/entity-usage/entity-usages.model";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

export class AudioRecord implements MacroObject {

    key: CompositeKey<number>;
    text: string;
    comment?: string;
    fileId?: string;
    rawFileId?: string;
    fileName?: string;
    date?: Date;
    deleted: boolean;
    expertId?: number;
    usages?: EntityUsage[];
    entityTitles?: string;
    selected: boolean;

    /**
     * true - Синтезируем и сохраняем аудиофайл
     * false - загрузить готовый аудиофайл
     */
    synthesized: boolean = false;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.text = object.text;
        this.comment = object.comment;
        this.fileId = object.fileId;
        this.fileName = object.fileName;
        this.date = object.date;
        this.deleted = object.deleted;
        this.expertId = object.expertId;
        this.usages = object.usages;
        this.entityTitles = object.entityTitles;
        this.synthesized = object.synthesized;
        this.selected = object.selected;
    }

    getTitle(): string {
        return this.text;
    }

    getTaggedInfo(): VaTag[] {
        return [];
    }

    getId(): string {
        return this.key.id + "";
    }
}

export class AudioRecordFilter {
    fromDate: NgbDate = null;
    toDate: NgbDate = null;
    noAudio: boolean = false;
    noUsage: boolean = false;


    constructor() {
    }
}