import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {Account} from "../../../data/va/Account";

export class EventType {
    title: string;
    name: string;
    id: number;
}

export class JournalsFilter {
    fromDate: NgbDate;
    toDate: NgbDate;

    // Дата, сконверченная в long, для отправки на бэк
    from: number;
    to: number;
    enabled: boolean = true;
    sections: string[];
    actionTypes: EventType[];
    eventTypes: EventType[];
    accountIds: [];
    page: any;

    constructor() {
    }
}

export class FilterResult {
    page: any;
    list: any[];
}

export class LoginsFilterResult extends FilterResult {
    list: LoginAction[];
}

export class ChangesFilterResult extends FilterResult {
    list: ChangesAction[];
}

export class ChangesAction {
    cells: any;
    showNetworkInfo: boolean = false;
}

export class LoginAction {
    account: Account;
    accountId: string;
    eventTimestamp: number;
    eventType: EventType;
    id: string;
    ip: string;
    userAgent: string;
    showNetworkInfo: boolean;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.account = object.account;
        this.accountId = object.accountId;
        this.eventTimestamp = object.eventTimestamp;
        this.eventType = object.eventType;
        this.id = object.id;
        this.ip = object.ip;
        this.userAgent = object.userAgent;
        this.showNetworkInfo = false;
    }
}
