import {Valued} from "../../../data/va/Valued";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

export class Project {
    id: number;
    name: string;
    type: ProjectType;
    expertId: number;
    d: Date;
    creationDate: Date;
    deleted: boolean;
    voice: boolean;
    text: boolean;
    //Map<String, String>
    channels: {};
    copyChannels: {};
    hasChannelTextByApiKey: {};
    expert: any;

    // view - нужен, так как надо уметь сортировать
    channelList: Channel[];
    language: ProjectLanguage;
    englishProjectId: string;
    accountsIds: any[];
    bargeInDefault: boolean = false;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.id = object.id;
        this.name = object.name;
        this.type = object.type;
        this.expertId = object.expertId;
        this.d = object.d;
        this.creationDate = object.creationDate;
        this.deleted = object.deleted;
        this.voice = object.voice;
        this.text = object.text;
        this.channels = object.channels;
        this.copyChannels = object.copyChannels;
        this.hasChannelTextByApiKey = object.hasChannelTextByApiKey;
        this.prepareViewChannelList();
        this.expert = object.expert;
        this.language = (object.language?.name ? ProjectLanguage[object.language.name] : ProjectLanguage[object.language]) as ProjectLanguage;
        this.englishProjectId = object.englishProjectId;
        this.accountsIds = object.accountsIds;
    }

    private prepareViewChannelList(): void {
        this.channelList = Object.keys(this.channels)
            .map(key => new Channel(key, this.channels[key]));
        this.sortViewChannelList();
    }

    public sortViewChannelList(): void {
        this.channelList = this.channelList
            .sort((a: Channel, b: Channel) => {
                if (a.key === b.key) {
                    return 0;
                }
                if (a.key === "") {
                    return 1;
                }
                if (a.key === Valued.VOICE_API_KEY) {
                    return -1;
                }
                if (a.key === Valued.WEB_API_KEY) {
                    if (b.key === Valued.VOICE_API_KEY) {
                        return 1;
                    } else {
                        return -1;
                    }
                }
                if (b.key === Valued.WEB_API_KEY || b.key === Valued.VOICE_API_KEY) {
                    return 1;
                }
                return a.key.localeCompare(b.key);
            });
    }

    public isRussian(): boolean {
        return ProjectLanguage.RUSSIAN == (this.language as any)?.name || ProjectLanguage.RUSSIAN == this.language;
    }
}

export enum ProjectLanguage {
    RUSSIAN = "RUSSIAN",
    ENGLISH = "ENGLISH"
}


export class ProjectType {
    name: ProjectTypeEnum;
    title: string;
}

export enum ProjectTypeEnum {
    OUTGOING = "OUTGOING",
    INCOMING = "INCOMING"
}

export class Channel {
    key: string;
    value: string;

    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }

    static voiceChannel(): Channel {
        return new Channel(Valued.VOICE_API_KEY, Valued.VOICE_TITLE);
    }

    static webChannel(): Channel {
        return new Channel(Valued.WEB_API_KEY, Valued.WEB_TITLE);
    }

    static assistantChannel(): Channel {
        return new Channel(Valued.ASSISTANT_API_KEY, Valued.ASSISTANT_TITLE);
    }

    static assistantVoiceChannel(): Channel {
        return new Channel(Valued.ASSISTANT_VOICE_API_KEY, Valued.ASSISTANT_VOICE_TITLE);
    }

    static emptyChannel(): Channel {
        return new Channel("", "");
    }

    isEditable(): boolean {
        return this.key !== Valued.VOICE_API_KEY && this.key != Valued.WEB_API_KEY && this.key != Valued.ASSISTANT_API_KEY && this.key != Valued.ASSISTANT_VOICE_API_KEY;
    }
}

export class ProjectExportParams {
    projectVersionId: string;
    withModels: boolean;
    withDialogs: boolean;
    withAudioFiles: boolean;
    withTranscription: boolean;
    dialogDateRange: {fromDate?: NgbDate, toDate?: NgbDate};

    constructor() {
        this.projectVersionId = "";
        this.withModels = false;
        this.withDialogs = false;
        this.withAudioFiles = true;
        this.withTranscription = false;
        this.dialogDateRange = {};
    }
}


