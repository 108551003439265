import {
    Component,
    ContentChild,
    ElementRef,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {DKBField} from "../../../../data/va/Dkb";
import {MacroService} from "./macro.service";
import {BehaviorSubject} from "rxjs";
import {VaTag} from "../../../../data/va/Tag";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import * as cloneDeep from "lodash/cloneDeep";
import {MarkupComponent} from "./dialog/markup/markup.component";
import {MacroEntity, MacroObject} from "./object/MacroObject";
import {MarkupType} from "./object/Markup";
import {ComponentType} from "@angular/cdk/overlay";
import {Macro, MacroType, MacroTypeEnum} from "./object/Macro";
import {VaAttribute} from "../../../../data/va/Attribute";
import {ChannelText} from "../../../../data/va/Formulation";
import {Valued} from "../../../../data/va/Valued";
import {CustomizationScript} from "../../../../data/va/CustomizationScript";
import {MacroDkbComponent} from "./dialog/dkb/macro-dkb.component";
import {AudioRecord} from "../../../../data/va/AudioRecord";
import {MacroAudioRecordComponent} from "./dialog/audio-record/macro-audio-record.component";
import {VaTtsSettings} from "../../../../data/va/Voice";
import {EruditeFile} from "../../../models/erudite-file.model";
import {HttpClient} from "@angular/common/http";
import * as urls from "../../../../../../js/workplace/urls";
import {AttachmentDialogComponent} from "../attachment-dialog/attachment-dialog.component";


@Component({
    selector: 'macro',
    template: require('./macro.component.html'),
    styles: [require('./macro.component.less')]
})

export class MacroComponent implements OnInit {

    static MAX_ATTACHMENTS_COUNT = 5;

    @ContentChild('textAreaLabel', {static: false})
    textAreaLabel: TemplateRef<ElementRef>;

    /**
     * Объект, в который надо записать данные
     */
    @Input()
    channelText: ChannelText;

    /**
     * Разрешенные типы
     */
    @Input()
    types: string[];

    /**
     * Можно ли редактировать
     */
    @Input()
    disabled: boolean;

    /**
     * Элементы, которые надо выкинуть из общего списка
     */
    @Input()
    excludeIds: string[];

    /**
     * Теги, которыми протегирован текущий объект
     */
    @Input()
    taggedInfo: VaTag[];

    /**
     * Данные по tts
     */
    @Input()
    ttsObject: { settings: VaTtsSettings; enabled: boolean };

    @ViewChild("macroTextarea", {static: false})
    macroTextarea: ElementRef<HTMLElement>;

    /**
     * Новый макрос
     */
    macro: Macro = new Macro();

    entities: BehaviorSubject<Map<MacroTypeEnum, MacroEntity>>;

    private customerDiffer: KeyValueDiffer<string, any>;

    private static LOCAL_STORAGE_KEY = 'erudite.channel_text';

    readonly macroOptionTitleMaxLength = 350;

    constructor(public httpClient: HttpClient,
                protected macroService: MacroService,
                public dialog: MatDialog,
                private differs: KeyValueDiffers) {

    }

    ngOnInit(): void {
        this.entities = this.macroService.getEntities(true);
        this.entities.subscribe((result) => {
            result.forEach((entity, type) => {
                this.macroService.prepareMacroEntity(type, entity, this.excludeIds, this.taggedInfo);
            });
        });
        if (this.channelText.macros?.length > 0) {
            this.channelText.fileMacros = this.channelText.macros.map(macro => new Macro(macro))
                .filter(macro => macro.isFile());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.channelText) {
            if (changes.channelText.currentValue) {
                // когда загрузится объект, начнем следить за его полями
                this.customerDiffer = this.differs.find(this.channelText).create();
            }
        }
    }

    ngDoCheck(): void {
        if (this.customerDiffer) {
            const changes = this.customerDiffer.diff(this.channelText);
            if (changes) {
                changes.forEachChangedItem(item => {
                    if (item.key == 'text') {
                        this.removeMacroOnTextChanged();
                    }
                });
            }
        }
    }

    /**
     * Если макрос удален в тексте, то надо удалить из списка
     */
    removeMacroOnTextChanged(): void {
        if (!this.channelText.macros) {
            return;
        }
        this.channelText.macros =
            this.channelText.macros.filter(m => this.channelText.text.indexOf((new Macro(m)).generateMacroString()) >= 0);
        this.channelText.fileMacros = this.channelText.macros.filter(macro => macro.isFile());
    }

    /**
     * Клик на тип макры
     */
    onTypeClick(open: boolean, name: any): void {
        const currentMacroType = this.macro.type;
        if (open) {
            this.clearSelected();
            this.macro.type = MacroType.BY_NAME.get(name);
        } else if (currentMacroType?.name === name) {
            this.clearSelected();
        }
    }

    /**
     * Клик на сущность
     */
    async onEntityClick() {
        switch (this.macro.type.name) {
            // проставляем данне и добавляем макрос и/или открываем диалог с настройкой
            case MacroTypeEnum.ATTRIBUTE:
                this.macro.attributes = [this.macro.selectedEntity as VaAttribute];
                this.macro.entityId = this.macro.attributes[0].key.id + "";
                await this.addMacro();
                break;
            case MacroTypeEnum.DKB_FIELD:
                this.macro.dkbField = this.macro.selectedEntity as DKBField;
                this.macro.entityId = this.macro.dkbField.key.id + "";
                // для дкб надо предложить выбрать значения
                this.openDialog(MacroDkbComponent);
                break;
            case MacroTypeEnum.CSCRIPT:
                this.macro.script = this.macro.selectedEntity as CustomizationScript;
                this.macro.entityId = this.macro.script.name;
                await this.addMacro();
                break;
            case MacroTypeEnum.MARKUP:
                this.macro.markupType = this.macro.selectedEntity as MarkupType;
                if (this.macro.markupType.valueAttributeName) {
                    // если разметка надо донастроить, то переходим в диалог
                    this.openDialog(MarkupComponent);
                } else {
                    // иначе добавляем макрос
                    await this.addMacro();
                }
                break;
            case MacroTypeEnum.AUDIO_RECORD:
                this.macro.audioRecord = this.macro.selectedEntity as AudioRecord;
                this.macro.entityId = this.macro.audioRecord.key.id + "";
                const audioText = this.macro.audioRecord.text;
                const records = this.entities.getValue().get(MacroTypeEnum.AUDIO_RECORD).groupedEntities.get(audioText).map(audioRecord => new AudioRecord(audioRecord));
                if (records.length === 1) {
                    // если запись с текстом одна, то добавляем макрос
                    await this.addMacro();
                } else {
                    // иначе предлагаем окно с select by comment or create
                    this.openDialog(MacroAudioRecordComponent);
                }

                break;
            default:
                throw new Error("Unexpected macro type");
        }
    }

    /**
     * Очистить все выбранные данные
     */
    clearSelected() {
        this.macro = new Macro();
        this.entities.getValue().forEach((entity, type) => {
            entity.showTaggedEntities = entity.taggedEntities.length > 0;
        });
    }

    /**
     * Сгенерировать и добавить макру в место фокуса
     */
    async addMacro() {
        if (this.disabled) {
            return;
        }
        if (this.macro.isFile()) {
            if (this.getAttachmentsCount() >= MacroComponent.MAX_ATTACHMENTS_COUNT) {
                return;
            } else if (this.channelText?.fileMacros) {
                this.channelText.fileMacros.push(this.macro)
            } else {
                this.channelText.fileMacros = [this.macro]
            }
        }
        const macroIds = await this.macroService.generateIds();
        this.macro.setId(macroIds[0]);
        if (!this.channelText.macros) {
            this.channelText.macros = [];
        }
        this.channelText.macros.push(this.macro);
        let macrosText = this.macro.generateMacroString();
        let textarea = this.macroTextarea.nativeElement as any;
        this.insertText(textarea, macrosText);
        this.clearSelected();
    }

    /**
     * Вставить текст в текст-арию по месту курсора/выбранной части текста
     */
    private insertText(textarea: any, text: string) {
        // В зависимости от того, есть ли такой параметр в объекте - определяем какой браузер, если есть, то - не IE
        if ((document as any).selection) {
            textarea.focus();
            // Область вставки
            let selection = (document as any).selection.createRange();
            // Запишем текст
            selection.text = text;
            this.channelText.text = textarea.value;
            textarea.focus();
            // Если такое, то IE
        } else if (textarea.selectionStart || textarea.selectionStart === 0) {
            // Начало вставки
            let startPos = textarea.selectionStart;
            // Конец
            let endPos = textarea.selectionEnd;
            let scrollTop = textarea.scrollTop;
            // Вставим текст
            let newText = textarea.value.substring(0, startPos) + text +
                textarea.value.substring(endPos, textarea.value.length);
            textarea.value = newText;
            this.channelText.text = newText;
            textarea.focus();
            // Переместим курсор
            textarea.selectionStart = startPos + text.length;
            textarea.selectionEnd = startPos + text.length;
            textarea.scrollTop = scrollTop;
        } else {
            let newText = textarea.value + text;
            textarea.value = newText;
            this.channelText.text = newText;
            textarea.focus();
        }
    }

    /**
     * Заголовок макры
     */
    macroTypeTitle(macroTypeEnum): string {
        switch (macroTypeEnum) {
            case MacroTypeEnum.ATTRIBUTE:
                return "Атрибут";
            case MacroTypeEnum.DKB_FIELD:
                return "База знаний";
            case MacroTypeEnum.CSCRIPT:
                return "Скрипт";
            case MacroTypeEnum.MARKUP:
                return "Разметка";
            case MacroTypeEnum.AUDIO_RECORD:
                return "Предзапись";
            default:
                throw new Error("Unexpected macro type");
        }
    }

    searchByTitleFn(term: string, item: MacroObject): boolean {
        return item.getTitle().toLowerCase().indexOf(term.toLowerCase()) > -1;
    }

    /**
     * Открыть диалог
     */
    public openDialog(dialogComponent: ComponentType<any>): void {
        // Открываем окно, передаём ему данные
        const dialogRef = this.dialog.open(dialogComponent, {
            width: '500px',
            disableClose: true,
            data: {macro: cloneDeep(this.macro)}
        });
        // Слушаем закрытие
        dialogRef.afterClosed()
            .subscribe((result: any) => {
                    if (!result) {
                        // если диалог закрыли или неуспешно провели, то очищаем формы
                        this.clearSelected();
                    } else {
                        this.macro = result;
                        // добавляем макрос
                        this.addMacro().then();
                    }
                }
            );
    }

    /**
     * Разрешить добавление нового объекта
     */
    isCreateEntityClick(type: MacroType): boolean {
        return type.name === MacroTypeEnum.AUDIO_RECORD;
    }

    /**
     * Показывать кнопку с выбором объектов для типа
     */
    isShowTypeButton(typeEnum: any): boolean {
        if (this.types.indexOf(typeEnum) < 0) {
            // если тип макры не передали в требуемых, тоне будем показывать кнопку для вставки
            return false;
        }
        if ([MacroTypeEnum.MARKUP, MacroTypeEnum.AUDIO_RECORD].indexOf(typeEnum) >= 0) {
            // если голосовые кнопки, то надо проверить текущий канал
            return this.isVoice();
        }
        return true;
    }

    onShowAllClick(event: any, entity: MacroEntity) {
        event.stopPropagation();
        entity.showTaggedEntities = false;
    }

    /**
     * Фокус на поиск при выпадении дропдауна макроса
     *
     * @param open true - открытие, false - закрытие
     * @param selectId - id нг-селекта x== типу макроса
     */
    onDropDownToggle(open: boolean, selectId: MacroTypeEnum) {
        if (!open) {
            // закрытие
            return;
        }
        // в нг-селекте есть инпут строки поиска, делаем на него фокус
        setTimeout(() => (document.querySelector(`#${selectId} input`) as any).focus(), 50);
    }

    /**
     * По событию копирования копируем в сторадж инфу вместе с макросами
     */
    macrosToStorage($event: ClipboardEvent) {
        localStorage.setItem(MacroComponent.LOCAL_STORAGE_KEY, JSON.stringify(this.channelText.macros));
    }

    /**
     * По событию вставки перегенерируем макры
     */
    async onPaste($event: ClipboardEvent) {
        // вставляемый текст
        let text = $event.clipboardData.getData('text');
        // строки макросов в тексте
        const macroStrings = text.match(/\${[0-9a-z]+}/g);
        if (!macroStrings) {
            // макросов нет, обычная вставка
            return;
        }
        // вставлять будем сами
        $event.preventDefault();
        // достаем данные по макросам из стораджа
        const storageItem = localStorage.getItem(MacroComponent.LOCAL_STORAGE_KEY);
        // десериализуем
        const macros: Macro[] = storageItem ? (JSON.parse(storageItem) as any[]).map(object => new Macro(object)) : [];

        // генерируем новые id для макросов
        const newIds: string[] = await this.macroService.generateIds(macros.length);
        // обновляем макросы, заменяем в тексте на новые id
        macroStrings.forEach((macroString, index) => text = this.renewMacro(macroString, macros, newIds[index], text))
        // вставляем текст
        this.insertText(this.macroTextarea.nativeElement, text);
    }

    /**
     * Перегенерировать и заменить в тексте макру
     */
    private renewMacro(macroString: string, macros: Macro[], newId: string, text: string): string {
        // исходный id макры
        const sourceMacroId = macroString.substring(2, macroString.length - 1);

        // данные о макросе из стораджа
        const macro = macros.find(macro => macro.key.id == sourceMacroId);

        // получаем строку замены макроса в тексте
        const replacement = this.getMacroReplacement(macro, newId);
        // заменяем строку макры в тексте для вставки
        return text.replace(macroString, replacement);
    }

    /**
     * Получить строку замены макроса в тексте
     */
    private getMacroReplacement(macro: Macro, newId: string): string {
        switch (macro.type.name) {
            case MacroTypeEnum.ATTRIBUTE:
            case MacroTypeEnum.CSCRIPT:
            case MacroTypeEnum.DKB_FIELD:
                // можно вставлять макрос, если есть сущность
                return this.isEntityPresent(macro) ? this.bindMacro(macro, newId) : ' ';

            case MacroTypeEnum.MARKUP:
                // разметка - для голоса
                return this.isVoice() ? this.bindMacro(macro, newId) : ' ';

            case MacroTypeEnum.AUDIO_RECORD:
                // можно вставлять, если голос и есть такая предзапись, иначе вставляем текстом
                return this.isVoice() && this.isEntityPresent(macro)
                    ? this.bindMacro(macro, newId) : ' ' + macro.audioRecord.text + ' ';

            default:
                throw new Error('unsupported macro type: ' + macro.type.name);
        }
    }

    /**
     * Есть ли нужная сущность
     */
    private isEntityPresent(macro: Macro): boolean {
        // так как DKB-макросы сейчас отключены, доп. сущности в них пока не проверяем
        return this.entities.getValue()
            .get(macro.type.name)
            .resultEntities.some(entity => entity.getId() == macro.entityId);
    }

    /**
     * Голосовой ли канал
     */
    public isVoice(): boolean {
        return this.channelText.channelValueOption.apiKey === Valued.VOICE_API_KEY || this.channelText.channelValueOption.apiKey === Valued.ASSISTANT_VOICE_API_KEY;
    }

    /**
     * Привязываем макру к редактируемому тексту
     */
    private bindMacro(macro: Macro, newId: string): string {
        // id текста
        macro.channelTextId = this.channelText.key?.id;
        // новый id макроса
        macro.key.id = newId;
        // добавляем макру в список
        if (!this.channelText.macros) {
            this.channelText.macros = [];
        }
        this.channelText.macros.push(macro);
        return macro.generateMacroString();
    }

    /**
     * Удаление вложения из текста
     * @param id идентификатор файла вложения
     */
    removeAttachment(id: string) {
        const index = this.channelText.attachedFiles.findIndex(attachment => attachment.id == id);
        this.channelText.attachedFiles.splice(index, 1);
        this.channelText.attachedFilesIds.splice(this.channelText.attachedFilesIds.indexOf(id), 1);
    }

    /**
     * Метод, который будем вызывать в AttachmentDialogComponent
     * Выполняет загрузку файлов
     */
    async dialogUploadMethod(formData: FormData, dialogRef: MatDialogRef<AttachmentDialogComponent>) {
        try {
            const files: EruditeFile[] = await this.httpClient.post<any>(`${urls.va.file}/upload_multiple`, formData).toPromise();
            dialogRef.close(files)
        } catch (data) {
            return data;
        }
        return null;
    }

    /**
     * Открыть диалоговое окно для вложений
     */
    openAttachmentsDialog() {
        const count = this.getAttachmentsCount();
        const dialogRef = this.dialog.open(AttachmentDialogComponent, {
            width: '500px',
            data: {
                attachmentsCount: count,
                maxAttachments: MacroComponent.MAX_ATTACHMENTS_COUNT,
                checkSize: true,
                uploadMethod: this.dialogUploadMethod,
            }
        });

        dialogRef.afterClosed()
            .subscribe((files: EruditeFile[]) => {
                if (files == null) {
                    return;
                }
                ChannelText.attachFiles(this.channelText, files);
            })
    }

    /**
     *  Количество вложений с учетом файловых макросов
     */
    private getAttachmentsCount(): number {
        const attachments = this.channelText?.attachedFiles?.length || 0;
        const macros = this.channelText.fileMacros?.length || 0;
        return attachments + macros;
    }

    /**
     * Урезать название опции в селекте макросов
     */
    trimMacroOptionTitle(title: string) {
        if (!title || title.length <= this.macroOptionTitleMaxLength) {
            return title
        }
        return title?.substring(0, this.macroOptionTitleMaxLength) + "...";
    }

    /**
     * Загрузка вложения
     * @param downloadUrl url загрузки
     */
    downloadAttachment(downloadUrl: string) {
        if (downloadUrl?.length > 0) {
            this.macroService.downloadAttachment(downloadUrl);
        }
    }
}