import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {FormControlModule} from "../common/form/form-control.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {DialogModule} from "../dialog/dialog.module";
import {ChatComponent} from "./chat.component";
import {ExtractableValuesModule} from "../va/extractable-values/extractable-values.module";
import {ChatService} from "./chat.service";
import {FileUploadModule} from "../common/file-uploader/uploader-vendor/file-upload.module";
import {MatButtonModule, MatProgressSpinnerModule} from "@angular/material";
import {ChatControlPanelComponent} from "./control-panel/chat-control-panel.component";
import {ChatPanelComponent} from "./chat-panel/chat-panel.component";
import {PrompterComponent} from "./prompter/prompter.component";
import {ChatControlService} from "./chat-control.service";
import {ObserversModule} from "@angular/cdk/observers";


@NgModule({
    declarations: [
        ChatComponent,
        ChatControlPanelComponent,
        ChatPanelComponent,
        PrompterComponent
    ],
    imports: [
        CommonModule,
        UIRouterModule,
        FormControlModule,
        NgSelectModule,
        FormsModule,
        DialogModule,
        ExtractableValuesModule,
        FileUploadModule,
        DialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        ObserversModule
    ],
    exports: [
        ChatComponent,
        ChatControlPanelComponent,
        ChatPanelComponent,
        PrompterComponent
    ],
    entryComponents: [
        ChatComponent,
        ChatControlPanelComponent,
        ChatPanelComponent,
        PrompterComponent
    ],
    providers: [
        ChatService,
        ChatControlService
    ]
})

export class ChatModule {

    static states = [
        {
            name: "robot.chat",
            url: "/chat",
            views: {
                'content@authorized': {
                    component: ChatComponent
                }
            },
            data: {
                title: "Чат с роботом",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            },
        },
        {
            name: "robot.prompter",
            url: "/prompter",
            views: {
                'content@authorized': {
                    component: ChatComponent
                }
            },
            data: {
                title: "Суфлёр",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            },
        }
    ];

}
