import {StepLabelTypeName, VAScenario, VAScript, VaScriptEdge, VaScriptNode} from "../../../../data/va/Script";
import {isTechnicalNode} from "../ScriptBuilderUtils";
import {HttpClient} from "@angular/common/http";
import * as urls from '../../../../../../js/workplace/urls';
import {CompositeKey} from "../../../../data/va/Common";
import ValidationController from "./ValidationController";

/**
 * Результат валидации
 */
export class ValidationResult {
    success: boolean;
    message?: string;

    constructor(success: boolean, message: string) {
        this.success = success;
        this.message = message;
    }
}

/**
 * Контроллер для валидации действий в построителе сценариев
 */
export default class ScriptValidationController {

    constructor(private http: HttpClient) {
    }

    /**
     * Валидации при сохранении сценария/процедуры
     * @param script
     * @param isProcedure
     */
    public async onScriptSave(script: VAScript, isProcedure: boolean): Promise<ValidationResult> {
        let scriptName: string = isProcedure ? 'процедуры' : "сценария";
        // Нельзя создать сценарий / процедуру без названия
        if (!script.name) {
            return new ValidationResult(false, `Необходимо задать название ${scriptName}`);
        }
        // Если создаётся сценарий, то у него должна быть тематика
        if (!isProcedure && !(script as VAScenario).tagId) {
            return new ValidationResult(false, `Необходимо задать тематику, для которой задаётся сценарий`);
        }
        // Должен быть добавлен хотя бы 1 элемент
        if (!ScriptValidationController.hasSingleNode(script.nodes)) {
            return new ValidationResult(false, 'Должен быть добавлен хотя бы один элемент');
        }
        // Все узлы должны быть достижимы из узла "Старт" / "Вход в процедуру"
        if (!ScriptValidationController.allNodesReachable(script.nodes, script.edges)) {
            let rootName: string = isProcedure ? '\"Вход в процедуру\"' : '\"Старт\"';
            return new ValidationResult(false, `Все элементы ${scriptName} должны быть связаны с элементом ${rootName}`);
        }
        // Нельзя переходить в тематику для которой создаётся сценарий
        if (!isProcedure && ScriptValidationController.hasSelfTagReference(script, (script as VAScenario).tagId)) {
            return new ValidationResult(false, `Нельзя использовать в сценарии переход в ту тематику, для которой формируется сценарий`);
        }
        // Есть ли среди рёбер сценария несовместимые
        if (ScriptValidationController.hasIncompatibleSteps(script)) {
            return new ValidationResult(false, `Некоторые элементы имеют несовместимые переходы`);
        }
        // Есть ли среди выходных узлов выходы без названия
        if (isProcedure && ScriptValidationController.hasUnnamedExitNodes(script)) {
            return new ValidationResult(false, `Нельзя сохранить процедуру с выходным узлом без названия`);
        }
        // Есть ли среди выходных узлов процедуры
        if (isProcedure && ScriptValidationController.hasDuplicateExitNodesNames(script)) {
            return new ValidationResult(false, `Выход с таким названием уже добавлен`);
        }
        // Создаётся сценарий для тематики, у которой его ещё нет
        if (!isProcedure && await this.isScripted(script.key, (script as VAScenario).tagId)) {
            return new ValidationResult(false, `Для выбранной тематики уже существует сценарий`);
        }
        // Всё ок
        return new ValidationResult(true, ``);
    }

    public async onScriptDelete(script: VAScript, isProcedure: boolean): Promise<ValidationResult> {
        if (isProcedure && await this.isProcedureUsed(script.key.id)) {
            return new ValidationResult(false, `Нельзя удалить процедуру: она используется в другом сценарии или процедуре`);
        }
        return new ValidationResult(true, ``);
    }

    /**
     * Есть ли у этой тематики сценарий?
     * @param key   ключ
     * @param tagId идентификатор процедуры
     */
    public async isScripted(key: CompositeKey<any>, tagId: number): Promise<boolean> {
        let params = {
            tagId: `${tagId}`,
        };
        if (key && key.id) {
            (params as any).scriptId = key.id
        }
        return await this.http.get<boolean>(`${urls.va.tags}isScripted`, {params: params}).toPromise()
    }

    /**
     * Используется ли эта процедура в сценариях?
     * @param procedureId идентификатор процедуры
     */
    public async isProcedureUsed(procedureId: number): Promise<boolean> {
        return await this.http.get<boolean>(`${urls.va.procedure}isProcedureUsed`, {params: {procedureId: `${procedureId}`}}).toPromise()
    }


    /**
     * Есть ли в сценарии/процедуре несовместимые шаги
     * @param script    сценарий / процедура
     */
    private static hasIncompatibleSteps(script: VAScript): boolean {
        const nodes = script.nodes;
        for (let node of nodes) {
            // Находим исходящие рёбра
            let outgoingEdges: VaScriptEdge[] = script.edges.filter(edge => edge.fromNodeId == node.key.id);
            // Если их несколько, то ищем несовместимые по условиям шаги
            let hasIncompatibleSteps = outgoingEdges.length > 1
                && outgoingEdges.some(edge => !ValidationController.validateConditionViolations(edge, node, outgoingEdges, edge.conditions).success)
            if (hasIncompatibleSteps) {
                return true;
            }
        }
        return false;
    }

    /**
     * Есть ли в сценарии хотя бы один узел, кроме "Старт"/"Вход в процедуру"
     * @param nodes
     */
    private static hasSingleNode(nodes: VaScriptNode[]) {
        return nodes.length > 1;
    }

    /**
     * Проверка, что из узла 'СТАРТ' доступны все другие вершины
     * @param nodes узлы
     * @param edges рёбра
     */
    private static allNodesReachable(nodes: VaScriptNode[], edges: VaScriptEdge[]): boolean {
        // we know start node
        let startNode = nodes.find(node => isTechnicalNode(node));

        // all nodes is white (0)
        let nodeMap: { [key: string]: VaScriptNode } = {};
        let nodeColors: { [key: string]: number } = {};
        nodes.forEach((node: VaScriptNode) => {
            nodeMap[node.key.id] = node;
            nodeColors[node.key.id] = 0;
        });

        // run dfs
        ScriptValidationController.deepFirstSearch(startNode, edges, nodeMap, nodeColors);

        // check that all nodes is white
        for (let i = 0; i < Object.keys(nodeColors).length; i++) {
            // if node not white -> return false
            if (nodeColors[Object.keys(nodeColors)[i]] != 2) {
                return false;
            }
        }

        return true;
    }

    /**
     * deep first search
     * @param node current node
     * @param edges   список рёбер
     * @param nodeMap узлы по идентификатороу
     * @param nodeColors цвета
     * @returns {boolean} true - find cycle
     */
    private static deepFirstSearch(node: VaScriptNode, edges: VaScriptEdge[], nodeMap: { [key: string]: VaScriptNode }, nodeColors: { [key: string]: number }) {
        // set node color to gray (1)
        nodeColors[node.key.id] = 1;
        let cycle = false;
        for (let i = 0; i < edges.length; i++) {
            let edge = edges[i];
            // from node go by edges
            if (edge.fromNodeId == node.key.id) {
                // if 'to' node is white -> run dfs
                if (nodeColors[edge.toNodeId] == 0) {
                    let dfsRes = ScriptValidationController.deepFirstSearch(nodeMap[edge.toNodeId], edges, nodeMap, nodeColors);
                    if (dfsRes) {
                        cycle = true;
                    }
                } else if (nodeColors[edge.toNodeId] == 1) {
                    // gray node second in -> find cycle
                    cycle = true;
                }
            }
        }
        // set node color to black (2)
        nodeColors[node.key.id] = 2;
        return cycle;
    }

    /**
     * Нет ли среди узлов ссылки на тематику для которой создаётся сценарий
     * @param script            сценарий
     * @param scenarioTagId     идентификатор тематики
     */
    private static hasSelfTagReference(script: VAScript, scenarioTagId): boolean {
        return script.nodes.filter(node => node.stepLabel.type.name == StepLabelTypeName.TO_TAG)
            .filter(node => node.stepLabel.entityId == scenarioTagId)
            .length > 0
    }

    /**
     *
     * Нет ли среди узлов нескольких выходных узлов с одинаковыми названиями
     * @param script            сценарий
     */
    private static hasDuplicateExitNodesNames(script: VAScript): boolean {
        const exitNodes: VaScriptNode[] = script.nodes.filter(node => node.stepLabel.type.name == StepLabelTypeName.EXIT);
        if (exitNodes.length == 0) {
            return false
        }
        let uniqueExitNames = new Set();
        return exitNodes.some((node) => uniqueExitNames.size === uniqueExitNames.add(node.stepLabel.name).size);
    }

    /**
     * Нет ли среди узлов нескольких выходных узлов с одинаковыми названиями
     * @param script            сценарий
     */
    private static hasUnnamedExitNodes(script: VAScript): boolean {
        const exitNodes: VaScriptNode[] = script.nodes.filter(node => node.stepLabel.type.name == StepLabelTypeName.EXIT);
        if (exitNodes.length == 0) {
            return false
        }
        return exitNodes.some(node => !node.stepLabel.name);
    }

}
