import {CompositeKey} from "./Common";
import {MLComponentType} from "../../controllers/va/ml/models/ml.model";


export interface Model {
    key: CompositeKey<number>;
    name?: string
    componentType: MLComponentType,
    status: ModelStatus,
    creationDate: Date,
    lastStatusUpdate?: Date,
    parentId: Number,
    dependsOnModelIdMap: Map<string, number>,
    modelParameters?: string,
    buildTime?: number;
    init: boolean
}

export enum ModelStatusEnum {
    IN_PROGRESS = "IN_PROGRESS",
    PHASE_BUILDING = "PHASE_BUILDING",
    FINISH = "FINISH",
    ACCEPTED = "ACCEPTED",
    FAILED = "FAILED",
    EMPTY = "EMPTY"
}

export class ModelStatus {
    desc: string;
    name: ModelStatusEnum;
}