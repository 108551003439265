import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {VASimpleModuleAnswer} from "../../../../data/va/SimpleModuleAnswer";
import {VersionedDataService} from "../base/versioned-data.service";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import {StateService} from "@uirouter/core";
import WebSocketService from "../../../../services/WebSocketService";
import {NamedEntitiesModel} from "../model/named-entities.model";


@Injectable()
export class SmaService extends VersionedDataService<VASimpleModuleAnswer, number> {

    baseUrl: string = "/account/expert/sma";

    usageEntityType: AppliedEntityType = AppliedEntityType.SIMPLE_MODULE_ANSWER;

    readonly entityClass = NamedEntitiesModel.VA_SIMPLE_MODULE_ANSWER;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

}