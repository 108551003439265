import {HostListener, Injectable, OnDestroy} from "@angular/core";
import WebSocketService from "../../../../../../services/WebSocketService";
import {NotificationService} from "../notification.service";
import {NotificationButton, NotificationType} from "../notification.component";
import {EruditeFile} from "../../../../../models/erudite-file.model";
import {StateService} from "@uirouter/core";

/**
 * Подписаться на пуш-уведомления через снекбар, если еще не подписаны.
 * Для подписки в старом ангуляре. В новом подписка просто через инжект сервиса пуш-нотификаций.
 *
 * @param $state стейт-сервис
 */
export function subscribeToPushNotifications($state: any) {
    if (PushNotificationService.subscribed) {
        // уже подписаны
        return;
    }
    const back: ThenState = {
        name: $state.current.name,
        params: $state.params
    };
    $state.go('robot.push_notifications', {then: JSON.stringify(back)})
}

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService implements OnDestroy {

    static subscribed: boolean;

    private readonly subscriptionId: string;
    private lastShowTime: number = 0;

    constructor(private notificationService: NotificationService,
                private webSocketService: WebSocketService,
                private stateService: StateService) {
        if (PushNotificationService.subscribed) {
            // уже подписаны
            return;
        }
        // подписываемся на уведомления в снекбаре через пуши
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "PUSH_NOTIFICATION",
            fn: (event) => this.showNotificationWithDelay(JSON.parse(event.details))
        });
        PushNotificationService.subscribed = true;
    }

    /**
     * Отобразить уведомление, но подождать перед этим, если предыдущее было слишком недавно
     */
    private showNotificationWithDelay(notification: PushNotification) {
        // считаем сколько времени прошло с начала показа предыдущего уведомления
        const previousShowTime = this.lastShowTime;
        const now = Date.now();
        const timeAfterPreviousNotification = now - previousShowTime;
        // сколько ждать, исходя из заданного минимального времени показа уведомления
        const timeToWait = Math.max(0, 1000 - timeAfterPreviousNotification);
        // запоминаем, когда будет показано это уведомление
        this.lastShowTime = now + timeToWait;
        // отображаем с рассчитанной паузой
        setTimeout(() => this.showNotification(notification), timeToWait);
    }

    /**
     * Отобразить уведомление в снекбаре
     */
    private showNotification(notification: PushNotification) {
        const formatted = this.formatNotification(notification);
        switch (notification.type) {
            case NotificationType.INFO:
                this.notificationService.info(formatted.message, 5000, formatted.buttons)
                break;
            case NotificationType.SUCCESS:
                this.notificationService.success(formatted.message, 0, formatted.buttons);
                break;
            case NotificationType.WARNING:
                this.notificationService.warning(formatted.message, 0, formatted.buttons);
                break;
            case NotificationType.ERROR:
                this.notificationService.error(formatted.message, 0, formatted.buttons);
                break;
            default:
                throw new Error("unsupported level " + notification.type)
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    /**
     * Отписаться от уведомлений
     */
    private unsubscribe() {
        this.webSocketService.removeListener(this.subscriptionId);
        PushNotificationService.subscribed = false;
    }

    @HostListener('window:beforeunload', ['$event'])
    public beforeunloadHandler() {
        this.unsubscribe();
    }

    /**
     * Форматировать уведомление (ссылки, кнопки,..)
     */
    private formatNotification(notification: PushNotification): {message: string, buttons?: NotificationButton[]} {
        if (!notification.file) {
            return {message: notification.text}
        }
        const listLink = this.stateService.href("robot.file");
        const message = `Файл ${notification.file.fileName} готов, его также можно найти <a href=${listLink}>здесь</a>`
        const button: NotificationButton = {
            class: 'btn btn-download',
            title: 'Скачать',
            callback: () => window.open(`/account/expert/file/${notification.file.id}`, '_blank')
        }
        return {message: message, buttons: [button]};
    }
}

/**
 * Уведомление в пуше
 */
interface PushNotification {
    text: string;
    type: NotificationType;
    file: EruditeFile;
}

/**
 * Ключ продолжительной операции
 */
export interface LongOperationKey {
    id: any;
    type: LongOperationType;
}

/**
 * Тип продолжительной операции
 */
export interface LongOperationType {
    EXPORT_VERSION,
    COLLECT_REPORT,
    EXPORT_DIALOGS
}

/**
 * Стейт, на который нужно перейти
 */
export interface ThenState {
    name: string
    params: any
}

