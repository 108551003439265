import {Component} from "@angular/core";

@Component({
    selector: 'chat',
    template: require('./chat.component.html'),
    styles: [require('./chat.component.less')]
})

export class ChatComponent {

    constructor() {
    }
}



