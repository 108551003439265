import {NgModule} from "@angular/core";
import {MatExpansionModule} from "@angular/material/expansion";
import {CommonModule} from "@angular/common";
import {ExtractorRegularExpressionComponent} from "./extractor-regular-expression.component";
import {FormControlModule} from "../common/form/form-control.module";
import {FormsModule} from "@angular/forms";
import {EditableListModule} from "../common/editable-list/editable-list.module";

@NgModule({
    declarations: [
        ExtractorRegularExpressionComponent
    ],
    imports: [
        MatExpansionModule,
        CommonModule,
        FormControlModule,
        FormsModule,
        EditableListModule,
    ],
    exports: [
        ExtractorRegularExpressionComponent
    ],
    entryComponents: [
        ExtractorRegularExpressionComponent
    ],
    providers: []
})
export class ExtractorRegularExpressionModule {


}