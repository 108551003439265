import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import {Injectable} from "@angular/core";
import {Report} from "./report.model";
import {VAProjectVersion} from "../../../data/va/VAProjectVersion";
import {StatUserAct} from "../../../data/va/SimpleModuleAnswer";
import {Account} from "../../../data/va/Account";

@Injectable()
export class ReportsService {

    baseUrl: string = "/account/expert/report";
    public beingBuiltReportNames = new Set<string>();

    constructor(protected httpClient: HttpClient, protected stateService: StateService) {
    }

    async getList(): Promise<Report[]> {
        return await this.httpClient.get<any>(`${this.baseUrl}`).toPromise();
    }

    async getReport(directoryName: string): Promise<Report> {
        return await this.httpClient.get<any>(`${this.baseUrl}/getByDirectoryName?directoryName=${directoryName}`).toPromise();
    }

    async buildReport(reportData: any): Promise<any> {
        return await this.httpClient.post<any>(this.baseUrl + '/buildReport', reportData, {}).toPromise();
    }

    async getActs(): Promise<StatUserAct[]> {
        return await this.httpClient.get<StatUserAct[]>(`${this.baseUrl}/simpleActs`).toPromise();
    }

    async loadProjectVersion(): Promise<VAProjectVersion> {
        return await this.httpClient.get<any>(`${this.baseUrl}/loadProjectVersion`).toPromise();
    }

    async loadUserAccounts(): Promise<Account[]> {
        return await this.httpClient.get<Account[]>(`${this.baseUrl}/loadUserAccounts`).toPromise();
    }

    async loadOperatorAccounts(): Promise<Account[]> {
        return await this.httpClient.get<any>(`${this.baseUrl}/loadOperatorAccounts`).toPromise();
    }

    async listReportDirectories(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/listReportDirectories`).toPromise();
    }

    async deleteTemplate(templateFileName: string): Promise<any> {
        return await this.httpClient.delete(`${this.baseUrl}/deleteTemplate?templateFileName=${templateFileName}`).toPromise();
    }

    async downloadTemplate(fileName: string): Promise<any> {
        return await this.httpClient.post(this.baseUrl + '/downloadTemplate?templateFileName=' + fileName, {}, {
            responseType: 'arraybuffer'
        }).toPromise();
    }

}