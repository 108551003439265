import {BaseFormController} from "../../BaseFormController";
import common from "../../../common";

// контроллер страницы редактирования правила
export default class AntifraudRuleEditController extends BaseFormController {

    constructor(Restangular, $scope, $stateParams, $state, $filter, TitleService) {
        super($scope, $state);
        var that = this;
        this.initFormMode({"create": {title: "Новое правило блокировки"}});
        that.$filter = $filter;
        that.restAngular = Restangular;

        // контроллер списка правил
        that.blockRuleController = $scope.$parent.ctrl;

        that.form.rule = {};

        // по ID загружаем аккаунт (full data)
        var ruleId = $stateParams['ruleId'];
        if (ruleId) {
            that.setLoading(true);
            that.setModeEdit();
            that.restAngular.one(that.blockRuleController.baseUrl, ruleId).get()
                .then(function success(data) {
                    that.form.rule = data;
                    TitleService.setTitle(that.form.rule.key);
                }, function error(data) {
                }).finally(function () {
                that.setLoading(false);
            });
        } else {
            that.setModeCreate();
            TitleService.setTitle("Новое правило блокировки")
        }

        common.loadValues(that, that.blockRuleController.baseUrl, "timeUnits");

        common.getAccess(that.restAngular, that.blockRuleController.baseUrl, that);
    }

    save() {
        var that = this;
        // сохранить или обновить
        var ruleRest = that.restAngular.all(that.blockRuleController.baseUrl);
        var saveFunc = that.mode.new ? ruleRest.post : ruleRest.patch;

        saveFunc(that.form.rule)
            .then(function success(data) {
                that.handleSuccess(data);
                // обновим данные формы
                that.form.rule = data;

                if (that.mode.new) {
                    that.setModeEdit();
                    //если новый объект, добавляем в список объектов в мастере
                    that.blockRuleController.addRule(that.form.rule);
                    //меняем текущее состояние, добавляя в него id объекта
                    that.$state.go('settings.antifraudrules.edit', {ruleId: data.id, notifySuccess: true})
                } else {
                    // заменяем в мастере объект, который редактировали
                    that.blockRuleController.replaceRule(that.form.rule);
                }
            }, function error(data) {
                that.handleError(data);
            });
    }

    remove() {
        var that = this;
        that.restAngular.one(that.blockRuleController.baseUrl, that.form.rule.id).remove().then(function success(data) {
            that.handleSuccess(data);
            // меняем текущее состояние контроллера
            that.$state.go('settings.antifraudrules', {}, {reload: true});

        }, function error(data) {
            that.handleError(data);
        });
    }

    close() {
        let that = this;
        // меняем текущее состояние контроллера
        that.$state.go('settings.antifraudrules', {}, {reload: true});
    }
}

AntifraudRuleEditController.$inject = ["Restangular", "$scope", "$stateParams", "$state", "$filter", "TitleService"];
