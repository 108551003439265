import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ScriptCall} from "../model/reply.model";
import {escapeHtml} from "../../../../../util/Utils";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {Clipboard} from 'ts-clipboard';

@Component({
    selector: 'script-error-dialog',
    template: require('./script-error-dialog.component.html'),
    styles: [require('./script-error-dialog.component.less')]
})
export class ScriptErrorDialogComponent {

    readonly formattedError: string;

    constructor(private notificationService: NotificationService,
                public dialogRef: MatDialogRef<ScriptErrorDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ScriptCall) {
        this.formattedError = '<div>' + escapeHtml(this.data.error)
                        .replace(/^script: .*\r?\n/g, '')
                        .replace(/ /g, '&nbsp;')
                        .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                        .replace(/\n/g, '</div><div>') +
                    '</div>';
    }

    /**
     * Скопировать текст ошибки в буфер обмена
     */
    copy() {
        Clipboard.copy(this.data.error);
        this.notificationService.success('Данные скопированы в буфер обмена');
    }
}
