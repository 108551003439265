import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Dialog} from "./model/dialog.model";
import {DialogService} from "./dialog.service";
import {ProjectVersionService} from "../project-version/project-version.service";
import {DialogCorrectionDTO, DialogCorrectionTypeEnum} from "./model/correction.model";
import {AppConfigService} from "../../service/app-config.service";
import {
    ChatRobotCommand,
    DialogFile,
    ExtractedValueWithCorrection,
    OrderableExtractedValue,
    Reply,
    ReplyView,
    RerouteReasonEnum,
    VAReplyButton,
    WordOrAudio
} from "./model/reply.model";
import {ExtractedInfoSource, ExtractedValue} from "./model/extracted-value.model";
import {VAActEnum} from "../../../data/va/VAActEnum";
import {Observable, of, zip} from "rxjs";
import WebSocketService from "../../../services/WebSocketService";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {CorrectionMenuBottomSheetComponent} from "./correction-menu/correction-menu-bottom-sheet.component";
import {AudioRecordService} from "../media-libraries/audio-record/audio-record.service";
import {VaScriptCondition} from "../../../data/va/VaScriptCondition";
import {isMissedTagAllowed} from "./dialog-tape-component.util";
import {Formulation, WithFormulation} from "../../../data/va/Formulation";
import {Macro} from "../common/macro/object/Macro";
import {DialogContext} from "./model/dialog-context.model";
import {StateService} from "@uirouter/core";
import {MatDialog} from "@angular/material";
import {DialogCopyDialogComponent} from "./dialogcopy/dialog-copy-dialog.component";
import {PrompterOptionsComponent} from "./prompter-options/prompter-options.component";
import {VaAttribute} from "../../../data/va/Attribute";
import {TagService} from "../va/tag/tag.service";
import {NotificationService} from "../common/snackbar/notification/notification.service";
import {copyData} from "../chat/control-panel/chat-control-panel.component";
import {ScriptErrorDialogComponent} from "./script-error/script-error-dialog.component";

@Component({
    selector: 'dialog-tape',
    template: require('./dialog-tape.component.html'),
    styles: [require('./dialog-tape.component.less'), require('./correction.less')]
})
export class DialogTapeComponent implements OnInit, OnDestroy {

    @Input()
    private conversationId: string;

    /**
     * Диалог из сравнения
     */
    @Input()
    private relevanceDialog: Dialog;

    /**
     * Исходный диалог
     */
    @Input()
    private srcDialog: Dialog;

    @Input()
    access: boolean;

    /**
     * Лента в чате
     */
    @Input()
    private isChat: boolean;

    @Input()
    private dialogMode = 'ALL';

    /**
     * Клик на кнопку в чате
     */
    @Output()
    private onClickDialogButton: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Выбрана "прерванная реплика"
     */
    @Output()
    private onInterruptedSelect: EventEmitter<number> = new EventEmitter<number>();

    private readonly subscriptionId: string;

    dialog: Dialog;
    private corrections: DialogCorrectionDTO[];

    timezone: string;

    replyViewList: ReplyView[] = [];

    /**
     *  Пока идет чат, действия с элементами для дообучения блокируем. Но после завершения делаем доступным прям в окне чата.
     */
    isEditable: boolean;

    /**
     * Типы извлечений ,которые надо выводить до текста реплики
     */
    private beforeReplyTextSource: string[] = [ExtractedInfoSource.EXTERNAL_FUNCTION.name, ExtractedInfoSource.INIT_DIALOG_FORM.name];

    /**
     * Тип предыдущего пуша обновления диалога
     */
    private previousPushEventType: string;

    lastAttributeBeforeReroute: VaAttribute = null;

    /**
     * Аудио из реплики для проигрывания в плеере
     */
    audioUrl: ArrayBuffer;

    /**
     * id реплики, выбранной как "прерванная"
     */
    interruptedToSend;

    constructor(private dialogService: DialogService,
                private projectVersionService: ProjectVersionService,
                private appConfigService: AppConfigService,
                private webSocketService: WebSocketService,
                private stateService: StateService,
                private tagService: TagService,
                private bottomSheet: MatBottomSheet,
                private matDialog: MatDialog,
                private notificationService: NotificationService,
                private audioRecordService: AudioRecordService) {
        this.timezone = appConfigService.interfaceConfig.serverTimezone;
        this.subscriptionId = webSocketService.subscribeOnEvents({
            eventType: "VA_ROBOT_API_DIALOG_CREATE,VA_ROBOT_API_DIALOG_RESOLVE,VA_ROBOT_API_USER_REPLY_SAVED,VA_ROBOT_API_DIALOG_RATE,VA_ROBOT_API_ROBOT_REPLY_SAVED",
            fn: event => this.updateOnPush(event)
        });
    }

    /**
     * Апдейт данных диалога по пушу
     */
    updateOnPush(event) {
        if (event.details != this.conversationId) {
            // другой диалог
            return;
        }
        this.interruptedToSend = null;
        // чекаем флаг "реплика робота сразу за репликой юзера"
        const currentEventType: string = event.type;
        const robotAfterUser = this.previousPushEventType == 'VA_ROBOT_API_USER_REPLY_SAVED' && currentEventType == 'VA_ROBOT_API_ROBOT_REPLY_SAVED';
        this.previousPushEventType = currentEventType;

        if (robotAfterUser && !this.replyViewList[this.replyViewList.length - 1].reply.isUser) {
            // реплику робота уже получили на предыдущем апдейте по уведомлению о реплике пользователя, данные не грузим
            return;
        }
        // грузим данные, конвертируем в нужный вид
        this.loadData();
    }

    ngOnInit(): void {
        if (this.relevanceDialog) {
            // если отображаем сравнение диалога, то коррекшены запрашивать не надо
            this.dialog = this.relevanceDialog;
            this.corrections = [];
            this.buildReplyViews().subscribe(value => {
                this.replyViewList = value;
            });
            this.isEditable = false;
        } else {
            this.dialog = this.srcDialog;
            this.isEditable = this.dialog.finished;
            // запрашиваем коррекшены, строим вью
            this.dialogService.getCorrections(this.dialog.id)
                .then((corrections) => {
                    this.corrections = corrections;
                    this.buildReplyViews().subscribe(value => {
                        this.replyViewList = value;
                        // запомним последний атрибут, который спрашивали
                        this.lastAttributeBeforeReroute = this.replyViewList
                            .map(replyView => replyView.reply)
                            .filter(reply => reply.attribute != null)
                            .pop()?.attribute;
                    });
                });
        }
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    /**
     * Загрузка диалога и коррекшенов, перестроение вью
     */
    loadData(): void {
        const promises: Promise<any>[] = [this.dialogService.getCorrections(this.dialog.id), this.dialogService.getDialog(this.dialog.id + '')];
        Promise.all(promises)
            .then(([corrections, dialog]) => {
                this.corrections = corrections;
                this.dialog = dialog;

                this.buildReplyViews().subscribe(value => {
                    this.replyViewList = value;
                    // запомним последний атрибут, который спрашивали
                    this.lastAttributeBeforeReroute = this.replyViewList
                        .map(replyView => replyView.reply)
                        .filter(reply => reply.attribute != null)
                        .pop()?.attribute;
                });
            });
    }

    /**
     * Посчитать данные для превью
     */
    buildReplyViews(): Observable<ReplyView[]> {
        const observables: Observable<ReplyView>[] = [];
        const correctionAllowed = this.dialog.finished && !this.dialog.trainState?.autocheck;
        const repliesNumber = this.dialog.replies.length;
        this.dialog.replies.map((reply: Reply, index: number) => {
            const replyView = new ReplyView(reply, correctionAllowed);
            if (reply.isUser) {
                // посчитать нужные экстракшены и коррекшены
                this.setInitExtractedValues(replyView, reply);
                this.setResetedValues(replyView);
                this.setActCorrection(replyView);
                this.setWrongTagCorrection(replyView);
                this.setMissedTagCorrection(replyView);
                this.setMissedExtractedValues(replyView);
                this.setExtractedValueWithCorrection(replyView);
            } else {
                // посчитать слова и макросы для реплики робота
                replyView.wordOrAudio = this.getRobotWordOrAudioList(this.dialog, reply);
                // кроме как у последней реплики робота нельзя нажимать кнопки
                replyView.lastRobotReply = index === repliesNumber - 1;
                this.setOutgoingAttachments(replyView);

                if (reply.tagId && !reply.tag && reply.vaAct.name == VAActEnum.KM_CONFIRM_TAG_CHOOSE) {
                    // если у реплики не заполнена тематика, то получим её для отображения в поле "Подтверждаемая тематика"
                    this.tagService.getTag(reply.tagId).then(tag => reply.tag = tag);
                }

                if (reply.prompter) {
                    replyView.prompterConfidence = reply.prompterConfidence ? reply.prompterConfidence : 1;

                    if (reply.vaAct.name == VAActEnum.OPERATORS_ANSWER){
                        replyView.userActTitle = reply.vaAct.title;
                    }
                }
            }
            // заполнить данные по чекпойнтам, если они есть
            if (reply.checkpointEdges) {
                reply.checkpointEdges.filter(edge => edge.conditions)
                    .forEach(edge => edge.conditions = edge.conditions.map(condition => new VaScriptCondition(condition)));
            }
            replyView.checkpointEdges = reply.checkpointEdges;
            replyView.checkpointNodes = reply.checkpointNodes;
            this.setOrderedValues(replyView);
            observables.push(of(replyView));
        });
        return zip(...observables);
    }

    /**
     * Извлечения перед началом диалога
     */
    setInitExtractedValues(replyView: ReplyView, reply: Reply) {
        replyView.initExtractedValues = reply.extractedValues ? reply.extractedValues
            .filter(extractedValue => extractedValue.source && this.beforeReplyTextSource.indexOf(extractedValue.source.name) >= 0) : [];
    }

    /**
     * Пропущенные извлечения
     */
    setMissedExtractedValues(replyView: ReplyView): void {
        replyView.missedExtractedValues = this.getReplyCorrections(replyView.reply, DialogCorrectionTypeEnum.MISSED_EXTRACTION);
    }

    /**
     * Найти извлечения и и корректировки
     */
    setExtractedValueWithCorrection(replyView: ReplyView): void {
        if (!replyView.reply.extractedValues) {
            replyView.extractedValues = [];
            return;
        }
        const extractedValues = replyView.reply.extractedValues
            .filter(extractedValue => !extractedValue.source || this.beforeReplyTextSource.indexOf(extractedValue.source.name) < 0);
        // сброшенные надо отрисовать как полученные сначала
        extractedValues.push(...replyView.reply.resetedValues);
        if (!extractedValues) {
            replyView.extractedValues = [];
            return;
        }
        replyView.extractedValues = extractedValues.map(extractedValue => new ExtractedValueWithCorrection(extractedValue,
            this.getExtractedValueCorrection(replyView.reply, extractedValue, DialogCorrectionTypeEnum.WRONG_EXTRACTION),
            this.getExtractedValueCorrection(replyView.reply, extractedValue, DialogCorrectionTypeEnum.EXCESS_EXTRACTION)));
    }

    /**
     * Найти сброшенные значения, могут принадлежать любой реплике
     */
    setResetedValues(replyView: ReplyView): void {
        const dialogResetedValues: ExtractedValue[] = [].concat.apply([], this.dialog.replies
            .filter(reply => reply.resetedValues)
            .map(reply => reply.resetedValues));
        replyView.resetedValues = dialogResetedValues.filter(resetedValue => resetedValue.resetReplyId === replyView.reply.id);
    }

    /**
     * Отфильтровать корректировки по указанному типу
     */
    getExtractedValueCorrection(reply: Reply, extractedValue: ExtractedValue, type: DialogCorrectionTypeEnum): DialogCorrectionDTO {
        return this.getReplyCorrections(reply, type)
            .find(correction => correction.correction?.extractedValueId === extractedValue.id);
    }

    /**
     * Найти корректировку акта
     */
    setActCorrection(replyView: ReplyView): void {
        replyView.actCorrection = this.getReplyCorrection(replyView.reply, DialogCorrectionTypeEnum.WRONG_ACT);
    }

    /**
     * Найти корректировку, что неправильно определена группа
     */
    setWrongTagCorrection(replyView: ReplyView): void {
        replyView.wrongTagCorrection = this.getReplyCorrection(replyView.reply, DialogCorrectionTypeEnum.WRONG_TAG);
    }

    /**
     * Найти корректировку, что пропущено определение группы
     */
    setMissedTagCorrection(replyView: ReplyView): void {
        replyView.missedTagCorrection = this.getReplyCorrection(replyView.reply, DialogCorrectionTypeEnum.MISSED_TAG);
    }

    /**
     * Список корректировок, отфильтрованных по реплике типу и удаленности
     */
    getReplyCorrections(reply: Reply, type: DialogCorrectionTypeEnum): DialogCorrectionDTO[] {
        if (!reply || !this.corrections) {
            return [];
        }
        return this.corrections
            .filter(correction => correction.correction.replyId === reply.id
                && correction.correction.type.name === type
                && !correction.correction.deleted);
    }

    /**
     * Корректировка, отфильтрованных по реплике типу и удаленности
     */
    getReplyCorrection(reply: Reply, type: DialogCorrectionTypeEnum): DialogCorrectionDTO {
        const filteredCorrections = this.getReplyCorrections(reply, type);
        return filteredCorrections.length > 0 ? filteredCorrections[0] : null;
    }

    /**
     * Вместо nau_audio надо вставит ссылку на audioRecord
     */
    getRobotWordOrAudioList(dialog: Dialog, reply: Reply): WordOrAudio[] {
        if (!reply.text) {
            // нет текста
            return [];
        }
        if (!this.dialog.audioRecords || this.dialog.audioRecords.length == 0) {
            // нет аудио в диалоге
            return [new WordOrAudio(reply.text, null)];
        }
        // разбиваем текст реплики по границам тега nau_audio
        const words = reply.text.split(/(<nau_audio.+?>)/).filter(word => word.length > 0);
        return words.map(word => {
            if (word.indexOf("nau_audio") < 0) {
                return new WordOrAudio(word, null);
            }
            // в урле nau_audio есть rawFileId
            const audioRecord = this.dialog.audioRecords.find(audioRecord => word.indexOf(audioRecord.key.id.toString()) >= 0 || word.indexOf(audioRecord.rawFileId) >= 0);
            return audioRecord ? new WordOrAudio(null, audioRecord) : new WordOrAudio(word, null);
        })
    }

    /**
     * Есть ли ссылка на настройку счетчика
     */
    needSettingsLink(rerouteReason: RerouteReasonEnum): boolean {
        return [RerouteReasonEnum.TAG_UNSUCCESS_MAX_LIMIT_EXCEEDED, RerouteReasonEnum.MAX_TAG_UNKNOWN_LIMIT_EXCEEDED,
            RerouteReasonEnum.MAX_TAG_CONFIRM_LIMIT_EXCEEDED, RerouteReasonEnum.REQUESTS_COUNT_LIMIT_EXCEEDED,
            RerouteReasonEnum.ACT_COUNT_LIMIT_EXCEEDED, RerouteReasonEnum.UNKNOWN_ACT_COUNT_LIMIT_EXCEEDED].indexOf(rerouteReason) >= 0;
    }

    /**
     * Нужно ли показать ссылку на открытие попапа ошибки скрипта
     */
    needScriptError(reply: ReplyView): boolean {
        return reply.reply.rerouteReason.name == RerouteReasonEnum.SCRIPT_EXECUTION_FAILED && reply.reply.scriptCalls?.some(call => call.error);
    }

    /**
     * Показать попап ошибки скрипта
     */
    openScriptErrorDialog(reply: ReplyView) {
        const scriptCall = reply.reply.scriptCalls?.find(call => call.error);
        this.matDialog.open(ScriptErrorDialogComponent, {
            width: '500px',
            data: scriptCall
        })
    }


    /**
     * Есть ли ссылка на атрибут, для которого превышен счетчик
     */
    needAttributeLink(rerouteReason: RerouteReasonEnum): boolean {
        return rerouteReason == RerouteReasonEnum.ATTRIBUTE_REQUESTS_COUNT_LIMIT_EXCEEDED && this.lastAttributeBeforeReroute != null;
    }

    /**
     * Показывать чат или все действия
     */
    showAllAction(): boolean {
        return this.dialogMode === 'ALL';
    }

    /**
     * Удаление корректировки
     */
    async deleteCorrection(correctionId: number, reply: ReplyView): Promise<void> {
        // из списка и с бэкенда
        await this.removeCorrection(correction => correction.correction.key.id === correctionId);
        // удаляем последующие корректировки, например группу после акта на вопрос к МЗ
        await this.cleanupCorrections(reply);
        // проставляем оставшиеся корректировки в реплику
        this.buildCorrections().subscribe(value => this.replyViewList = value);
    }

    /**
     * Удалить корректировки, которые нужно удалить после апдейта данных
     */
    private async cleanupCorrections(reply: ReplyView, changeSourceCorrection?: DialogCorrectionDTO): Promise<void> {
        if (!isMissedTagAllowed(reply)) {
            // пропущенная группа теперь невозможна
            await this.removeCorrection(correction => correction.correction.type.name == DialogCorrectionTypeEnum.MISSED_TAG &&
                // но если это не корректировка пропущенной группы, то ок
                changeSourceCorrection?.correction.key.id == correction.correction.key?.id);
        }
    }

    /**
     * Удалить корректировку из списка и c бэкенда
     */
    async removeCorrection(predicate: (correction: DialogCorrectionDTO) => boolean) {
        const index = this.corrections.findIndex(predicate);
        if (index < 0) {
            // не нашли
            return null;
        }
        // удаляем из списка
        const deleted = this.corrections.splice(index, 1)[0];
        // удаляем с бэкенда
        await this.dialogService.deleteCorrections(deleted.correction.key.id);
        return deleted;
    }

    /**
     * Сохранение корректировки
     */
    async saveCorrection(correctionDTO: DialogCorrectionDTO, reply: ReplyView): Promise<void> {
        if (!this.corrections) {
            this.corrections = [];
        }
        // id сохраняемой корректировки
        const savedId = correctionDTO.correction.key.id;
        // сохраняем корректировку на бэкенд
        correctionDTO.correction.sample = null;
        correctionDTO.correction = await this.dialogService.saveCorrection(this.dialog.id, correctionDTO);

        if (!savedId) {
            // если корректировка новая, добавляем в список
            this.corrections.push(correctionDTO);
        } else {
            // если не новая, заменяем ее в списке
            for (let i = 0; i < this.corrections.length; i++) {
                const savedCorrection = this.corrections[i];
                if (savedCorrection.correction.key.id === savedId) {
                    this.corrections[i] = Object.assign({}, correctionDTO);
                    break;
                }
            }
        }
        // удаляем другие ставшие невозможными корректировки
        await this.cleanupCorrections(reply, correctionDTO);
        // пересчитываем коррекшены
        this.buildCorrections().subscribe(value => this.replyViewList = value);
    }

    /**
     * Пересчитать коррекшены (остальные данные не менялись)
     */
    buildCorrections(): Observable<ReplyView[]> {
        const observables: Observable<ReplyView>[] = [];
        this.replyViewList.map(replyView => {
            if (replyView.reply.isUser) {
                this.setActCorrection(replyView);
                this.setWrongTagCorrection(replyView);
                this.setMissedTagCorrection(replyView);
                this.setMissedExtractedValues(replyView);
                this.setExtractedValueWithCorrection(replyView);
            }
            this.setOrderedValues(replyView);
            observables.push(of(replyView));
        });
        return zip(...observables);
    }

    /**
     * Клик на реплику пользователя, поднимаем меню с выбором корректировки
     */
    openUseReplyCorrectionMenu(reply: ReplyView, $event: MouseEvent): void {
        if ($event.ctrlKey || $event.metaKey) {
            // копируем текст реплики по ctrl+клик
            this.copyData('Текст реплики', reply.reply.text, $event);
            return;
        }
        const correctionTypes = [DialogCorrectionTypeEnum.MISSED_EXTRACTION];
        if (isMissedTagAllowed(reply)) {
            // если возможно, корректировка о пропущенной группе
            correctionTypes.push(DialogCorrectionTypeEnum.MISSED_TAG);
        }
        let onCorrectionSave: EventEmitter<DialogCorrectionDTO> = new EventEmitter<DialogCorrectionDTO>();
        const bottomSheetRef = this.bottomSheet.open(CorrectionMenuBottomSheetComponent, {
            data: {
                reply: reply,
                correctionTypes: correctionTypes,
                onCorrectionSave: onCorrectionSave
            },
        });
        onCorrectionSave.subscribe((result: DialogCorrectionDTO) => {
            if (result) {
                // noinspection JSIgnoredPromiseFromCall
                this.saveCorrection(result, reply);
            }
        });
        bottomSheetRef.afterDismissed().subscribe((result: DialogCorrectionDTO) => {
                if (result) {
                    // noinspection JSIgnoredPromiseFromCall
                    this.saveCorrection(result, reply);
                }
            }
        )
    }

    /**
     * Клик на кнопку в диалоге (отправляем событие в чат)
     */
    onValueButtonClick(button: any, reply: ReplyView): void {
        // дисейблим кнопку
        reply.lastRobotReply = false;
        this.onClickDialogButton.emit((button as VAReplyButton).key);
    }

    /**
     * Можно ли кликать на кнопку в ленте: на последнем сообщении в чате у незаверенного диалога
     */
    isValueButtonClickable(reply: ReplyView): boolean {
        return reply.lastRobotReply && this.isChat && !this.dialog.finished;
    }

    /**
     * Проиграть файл реплики
     */
    playAudioFile(replyId: number, audioFile: DialogFile) {
        this.audioUrl = null;
        this.audioRecordService.loadReplyRecordFile(replyId, audioFile.id,
                dataUrl => this.audioUrl = dataUrl, error => this.notificationService.error(error));
    }

    /**
     * Получить сущность с формулировкой
     */
    static getFormulationEntity(reply: Reply): WithFormulation | undefined {
        if (reply.answer != null) {
            return reply.answer
        } else if (reply.attribute != null) {
            return reply.attribute
        } else if (reply.sma != null) {
            return reply.sma
        }
    }

    /**
     * Проставить список исходящих вложений в DTO
     * @param replyView DTO реплики
     */
    private setOutgoingAttachments(replyView: ReplyView): void {
        const reply = replyView.reply;
        const context = this.dialog.dialogContext;
        const entity = DialogTapeComponent.getFormulationEntity(reply);
        if (!entity) {
            // нет формулировки
            return;
        }
        // ищем использованную в реплике формулировку
        const formulation = entity.formulations.find(formulation => formulation.key.id == reply.formulationId);
        // если нет текстов, то пофиг
        if (formulation == null || formulation?.channelTexts?.length == 0) {
            replyView.outgoingAttachments = [] as any;
            return;
        }
        // ищем использованный текст
        const channelText = this.getReplyChannelText(context, reply, formulation);
        let result = [] as any;
        channelText.attachedFiles.forEach(file => result.push({name: file.fileName, url: file.downloadUrl}));
        // если есть макросы, то надо их обработать
        if (channelText.macros != null) {
            // собираем макросы с файлами
            const fileMacros = channelText.macros.map(macro => new Macro(macro))
                .filter(macro => macro.isFile());
            // складываем в подходящем формате
            fileMacros.forEach(macro => {
                const extractedValue = context.extractedValues.find(value => `${value.entityId}` == macro.entityId);
                if (extractedValue != null) {
                    result.push({name: extractedValue.entity.name, url: extractedValue.value})
                }
            })
        }

        replyView.outgoingAttachments = result;
    }

    /**
     * Получить текст канала, использованный в реплике
     * @param context контекст
     * @param reply реплика
     * @param formulation формулировка
     */
    private getReplyChannelText(context: DialogContext, reply: Reply, formulation: Formulation) {
        if (context.extractedValues.length == 0) {
            return formulation.channelTexts[0];
        }
        const previousReply = this.dialog.replies.find(currentReply => currentReply.numberInDialog == (reply.numberInDialog - 1));
        const isRepeat = previousReply?.vaAct?.name == VAActEnum.ACK_REPEAT;
        // индекс повтора
        const index = isRepeat ? 0 : (reply.repeatAmount || 0);
        // тексты заданного канала со всеми повторами
        const channelTexts = formulation.channelTexts;
        const channelText = channelTexts[index % channelTexts.length];
        if (!channelText) {
            return formulation.channelTexts[0];
        }
        return channelText;
    }

    /**
     * Копирование диалога по указанную реплику и продолжение его в чате с роботом
     * @param replyId
     * @param $event
     */
    async copyForTesting(replyId: number, $event: MouseEvent) {
        if (!$event.ctrlKey && !$event.metaKey) {
            // нужен нажатый ctrl/cmd
            return;
        }
        const target = $event.target;
        try {
            if (!this.isUserReply(replyId) && (!this.isEndingRobotReply(replyId) || this.isReroutingReply(replyId))) {
                // с этой реплики не продолжить
                this.blink(target, 'glow-warning');
                return
            }
            // подсвечиваем область клика
            this.blink(target, 'glow-success');
            // копируем
            const result = await this.dialogService.copyForTesting(this.dialog.id, replyId);
            if (result.newDialogId) {
                // переходим в чат с роботом на скопированный диалог
                const chatLink = this.stateService.href('robot.chat');
                open(chatLink, '_blank');
            } else {
                this.blink(target, result.error ? 'glow-error' : 'glow-warning');
                this.matDialog.open(DialogCopyDialogComponent, {
                    width: '800px',
                    data: {result: result}
                })
            }
        } catch (e) {
            this.handleDialogCopyError(e, target);
        }
    }

    /**
     * Исключение при копировании диалога
     */
    private handleDialogCopyError(e, target: EventTarget) {
        this.blink(target, 'glow-error');
        // достаем описание ошибки
        let error
        if (e.errors?.length && (e.errors[0].exception || e.errors[0].message)) {
            error = e.errors[0].exception || e.errors[0].message;
        } else {
            error = 'Ошибка при копировании диалога';
        }
        // показываем диалог с ошибкой
        this.matDialog.open(DialogCopyDialogComponent, {
            width: '1024px',
            data: {result: {error: error}},
        })
        throw e;
    }

    /**
     * Временно подсветить область клика
     */
    private blink(target: any, classId: string) {
        target?.classList?.remove('glow-success');
        target?.classList?.add(classId);
        setTimeout(() => target?.classList?.remove(classId), 3000);
    }

    /**
     * Является ли реплика репликой пользователя
     */
    private isUserReply(replyId: number): boolean {
        return this.replyViewList.find(view => view.reply.id == replyId).reply.isUser;
    }

    /**
     * Является ли реплика с заданным id последней в серии реплик робота подряд
     */
    private isEndingRobotReply(robotReplyId: number) {
        // id следующей реплики
        const nextReplyId = this.replyViewList.map(view => view.reply.id)
            .filter(id => id > robotReplyId)
            .reduce((previousId, id) => previousId && previousId < id ? previousId : id, null);

        if (!nextReplyId) {
            // следующей нет
            return true;
        }
        // если следующая юзерская, то заданная - последняя в серии
        return this.replyViewList.find(view => view.reply.id == nextReplyId)
            .reply
            .isUser;
    }

    /**
     * Переводит ли реплика на оператора
     */
    private isReroutingReply(replyId: number) {
        const reply = this.replyViewList.find(view => view.reply.id == replyId);
        return reply?.reply.rerouteStatus != ChatRobotCommand.REPLY || reply.reply.rerouteReason;
    }

    /**
     * Сложить извлеченные значения в один список в порядке появления
     */
    private setOrderedValues(view: ReplyView): void {
        const values: OrderableExtractedValue[] = [];
        if (view.extractedValues?.length) {
            values.push(...view.extractedValues.map(value => new OrderableExtractedValue(value)));
        }
        if (view.resetedValues?.length) {
            values.push(...view.resetedValues.map(value => new OrderableExtractedValue(null, value)));
        }
        if (view.missedExtractedValues?.length) {
            values.push(...view.missedExtractedValues.map(value => new OrderableExtractedValue(null, null, value)));
        }
        if (view.reply.enteredProcedures?.length) {
            values.push(...view.reply.enteredProcedures.map(value => new OrderableExtractedValue(null, null, null, value)));
        }
        values.sort((v1, v2) => `${v1.id}`.localeCompare(`${v2.id}`));
        view.orderedValues = values;
    }

    showPrompterChoice(reply: any) {
        const index = this.dialog.replies.findIndex(r => reply.id === r.id);
        const id = this.dialog.replies[index - 1].id;

        this.bottomSheet.open(PrompterOptionsComponent, {
            data: {id: id}
        });
    }

    /**
     * Скопировать данные в буфер обмена по ctrl+клик
     */
    copyData(description: string, data: any, $event: MouseEvent) {
        copyData(this.notificationService, description, data, $event);
    }

    /**
     * Клик на тексте реплики - выбираем ее как "прерванную"
     */
    selectInterrupted(clicked: ReplyView, $event: MouseEvent) {
        if (!$event.shiftKey || this.dialog.finished) {
            // шифт не зажат или диалог завершен, выходим
            return;
        }
        if (this.interruptedToSend == clicked.reply.id) {
            // повторный клик - снимаем предыдущий выбор
            this.interruptedToSend = null;
            this.onInterruptedSelect.emit(null);
            return;
        }
        // последняя реплика юзера
        const userReply = this.dialog.replies.slice().reverse().find(reply => reply.isUser);
        if (userReply.id > clicked.reply.id) {
            // можно выбрать только после последней реплики юзера
            return;
        }
        this.interruptedToSend = clicked.reply.id;
        this.onInterruptedSelect.emit(this.interruptedToSend);
    }

    /**
     * Показывать ли дроп-даун (а не кнопки)
     */
    isButtonsDropdown(reply: ReplyView) {
        // копок больше порога
        return reply.reply.buttons?.length >= 20;
    }
}
