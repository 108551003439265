import {Component, ViewEncapsulation} from "@angular/core";
import {AudioRecord} from "../../../../../data/va/AudioRecord";
import {StateService} from "@uirouter/core";
import {AudioRecordService} from "../audio-record.service";
import {HttpClient} from "@angular/common/http";
import {BaseDetailsComponent} from "../../../va/base/base-details.component";
import {CompositeKey} from "../../../../../data/va/Common";
import {Title} from "@angular/platform-browser";
import {AudioRecordMasterComponent} from "../master/audio-record-master.component";
import {FileItem} from "../../../common/file-uploader/uploader-vendor/file-item.class";
import {TextToSpeechService} from "../../text-to-speech/text-to-speech.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'audio-record-edit',
    template: require('./audio-record-edit.component.html'),
    styles: [require('./audio-record-edit.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class AudioRecordEditComponent extends BaseDetailsComponent<AudioRecord, CompositeKey<number>> {

    objectIdKey = 'audioRecordId';

    private audioFile: File;

    audioFileUrl: any;

    isTtsEnabled: boolean = true;
    /**
     * true - когда у нас выбрано синтезирование и файл готов
     */
    isSynthesized: boolean = false;

    btnSynthesizeText: string;

    constructor(protected stateService: StateService,
                protected master: AudioRecordMasterComponent,
                protected httpClient: HttpClient,
                public dataService: AudioRecordService,
                protected titleService: Title,
                protected notificationService: NotificationService,
                private ttsService: TextToSpeechService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        this.isTtsEnabled = await this.ttsService.isTtsServerEnabled();

        // Если тип файла не был проставлен, значит считаем, что было false
        if (this.form.object && this.form.object?.synthesized == null) {
            this.form.object.synthesized = false;
        }

        // Меняем текст кнопки и статус синтеза, если синтез уже проводили ранее
        if (this.form.object && this.form.object?.fileName && this.form.object.synthesized) {
            this.isSynthesized = true;
            this.btnSynthesizeText = "Сгенерировать заново";
        } else {
            this.btnSynthesizeText = "Сгенерировать";
        }
    }

    /**
     * Создание голосовой версии реплики по тексту из формы
     */
    async startSynthesizing() {
        if (this.form.object.text) {
            this.form.errors.clear();
            this.setLoading(true);
            try {
                // Получим данные синтеза
                let blob = await this.ttsService.synthesize(this.form.object.text, true) as Blob;

                // Соберем в файл, в который будем сохранять
                this.audioFile = new File([blob],
                    'SYNT-' + new Date().getTime() + '.ogg', {type: 'audio/ogg'});

                // Url - для воспроизведения перед сохранением
                this.audioFileUrl = await this.ttsService.readFile(blob);
                this.form.object.fileName = this.audioFile.name;
                this.isSynthesized = true;
                this.btnSynthesizeText = "Сгенерировать заново";
            } catch (error) {
                this.notificationService.error("Внутренняя ошибка: проверьте голосовые настройки");
            }
            this.setLoading(false);
        } else {
            this.notificationService.error("Заполните текст реплики");
        }
    }

    playAudioFile(fileUrl: any) {
        this.master.audioUrl = fileUrl;
    }

    generateFormObject(): AudioRecord {
        const audioRecord: AudioRecord = new AudioRecord();
        audioRecord.text = "";
        this.btnSynthesizeText = "Сгенерировать";
        return audioRecord;
    }

    /**
     * Действие при изменении реплики в аудиофайле
     */
    onReplyChange() {
        if (this.form.object.synthesized) {
            this.removeAudioFile();
        }
    }

    /**
     * Удалить аудиофайл
     */
    removeAudioFile() {
        this.audioFile = null;
        this.audioFileUrl = null;
        this.form.object.fileName = null;
        this.btnSynthesizeText = "Сгенерировать";
        this.isSynthesized = false;
        this.form.object.fileId = null;
    }

    /**
     * При выборе аудиофайла - проставить его имя в объект
     * @param items аудиофайл
     */
    onAudioFileSelect(items: FileItem[]) {
        let audioFile: File = items.pop()._file;
        if (audioFile.size > 0) {
            this.audioFile = audioFile;
            this.form.object.fileName = this.audioFile.name;
        }
    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     * @param $event
     */
    onFileAddFailed($event: string) {
        this.notificationService.error($event)
    }

    async save(): Promise<void> {
        this.form.errors.clear();
        this.setLoading(true);
        let formData = new FormData() as any;
        formData.append('audioRecord', new Blob([JSON.stringify(this.form.object)], {
            type: "application/json"
        }));
        if (this.audioFile) {
            formData.append("audioFile", this.audioFile);
        }
        const promise = this.isModeNew() ? this.dataService.save(formData) : this.dataService.update(formData);
        await this.finishSave(promise, this.isModeNew(), false)
    }

    async finishSave(savePromise, isNew: boolean, preserveState: boolean): Promise<void> {
        await super.finishSave(savePromise, isNew, true);
        if (isNew && this.form.errors.size === 0) {
            this.cancel();
        }
    }

    get entityTitle(): string {
        if (this.isModeNew()) {
            return 'Добавление реплики';
        }
        if (!this.form.object.fileName) {
            return 'Редактирование реплики';
        }
        return this.form.object.fileName;
    }

    get existingFile() {
        if (!this.form.object.fileId) {
            return null;
        }
        if (this.form.object.synthesized) {
            return null;

        }
        return new File([], this.form.object.fileName, {type: 'audio/mp3'});
    }

    showCancel(): boolean {
        return true;
    }

    get removeConfirmMessage(): string {
        return "Вы уверены, что хотите удалить реплику?";
    }

    async remove() {
        let result = await super.remove();
        if (result) {
            this.notificationService.success("Аудиозапись удалена", 3000);
            return true;
        }
        return false;
    }
}