import {ViewChild, ElementRef, Input} from '@angular/core';
import { MatSlider } from '@angular/material/slider';

export class BaseAudioPlayerFunctions {

    @ViewChild('audioPlayer', {static: true})
    player: ElementRef;

    timeLineDuration: MatSlider;

    @Input()
    currentTime = 0;

    @Input()
    stopTime: number;

    loaderDisplay = false;
    isPlaying = false;

    volume = 0.1;
    duration = 0.01;


    currTimePostChanged(event) {
        this.player.nativeElement.currentTime = event.value;
    }

    bindPlayerEvent(): void {
        this.player.nativeElement.addEventListener('playing', () => {
            this.isPlaying = true;
            this.duration = Math.floor(this.player.nativeElement.duration);
        });
        this.player.nativeElement.addEventListener('pause', () => {
            this.isPlaying = false;
        });
        this.player.nativeElement.addEventListener('timeupdate', () => {
            this.currentTime = Math.floor(this.player.nativeElement.currentTime);

            if (this.player.nativeElement.currentTime >= this.stopTime && this.stopTime) {
                // автостоп на заданном времени
                this.pause();
                this.stopTime = null;
                this.autoStop();
            }
        });
        this.player.nativeElement.addEventListener('ended', () => {
            // автостоп в конце
            this.stopTime = null;
            this.autoStop();
        });
        this.player.nativeElement.addEventListener('volume', () => {
            this.volume = Math.floor(this.player.nativeElement.volume);
        });
        this.player.nativeElement.addEventListener('loadstart', () => {
            this.loaderDisplay = true;
        });
        this.player.nativeElement.addEventListener('loadeddata', () => {
            this.loaderDisplay = false;
            this.duration = Math.floor(this.player.nativeElement.duration);
        });
    }

    playBtnHandler(): void {
        if (this.loaderDisplay) {
            return;
        }
        if (this.player.nativeElement.paused) {
            this.player.nativeElement.play(this.currentTime);
        } else {
            this.currentTime = this.player.nativeElement.currentTime;
            this.player.nativeElement.pause();
        }
    }

    play(): void {
        setTimeout(() => {
            this.player.nativeElement.play();
        }, 0);
    }

    pause(): void {
        setTimeout(() => {
            this.player.nativeElement.pause();
        }, 0);
    }

    toggleVolume() {
        if (this.volume === 0) {
            this.setVolume(1.0);
        } else {
            this.setVolume(0);
        }
    }

    setVolume(vol) {
        this.volume = vol;
        this.player.nativeElement.volume = this.volume;
    }

    /**
     * Вызывается при автостопе на заданном времени или в конце
     */
    autoStop(): void {
    }

}