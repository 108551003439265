import {Component} from "../../../../backports";
import restangular from "restangular";

@Component({
    bindings: {
        titled: "<",
        examples: "<",
        perPageCount: "<"
    },
    templateUrl: "/pages/workplace/va/ml/models/examplesTextsList.html"
})
export default class ExampleTextsComponent {

    static $inject = ["$state", "Restangular"];

    private currentPage: number;

    private perPageCount: number;

    private examples: any[];

    private titled: string;

    private state: ng.ui.IStateService;

    private restangular: restangular.IService;

    constructor(state: ng.ui.IStateService, restangular: restangular.IService) {
        this.state = state;
        this.restangular = restangular;
        this.currentPage = 1;
        this.perPageCount = this.perPageCount == null ? 5 : this.perPageCount;
    }

    slice() {
        if (!this.examples) {
            return [];
        }
        return this.examples.slice((this.currentPage - 1) * this.perPageCount, this.currentPage * this.perPageCount)
    }

    private goToEntity(item) {
        let typeId: string = item.id;
        let text: string = item.text;

        // тип сэмпла, идентификатор
        let [typeStr, id, ...additionalIdentificationArray] = typeId.split("_");

        // тип
        const type: number = +typeStr;

        // доп информация для соц.чата
        const additionalIdentification = additionalIdentificationArray.join("_");

        let typeString = "";
        switch (type) {
            case 0:
                typeString = "USER_REPLY";
                break;
            case 1:
                typeString = "VA_MESSAGE";
                break;
            case 2:
                typeString = "EXTRA_SAMPLE";
                break;
            case 3: // VA_TAG
                let url = this.state.href("robot.tags.edit", {tagId: id});
                window.open(url, '_blank');
                return;
            case 4: // OTHER_PROJECT
                let checkReplyOtherProjectUrl = this.state.href("robot.ml_check_reply", {projectVersionId: additionalIdentification, reply: text});
                window.open(checkReplyOtherProjectUrl, '_blank');
                return;
            case 5: // OTHER_SERVICE
                window.open(additionalIdentification, '_blank');
                return;
        }

        this.restangular.one("/account/expert/ml/models/")
            .one("resolveLink", typeString)
            .one("", id)
            .get()
            .then((entityId) => {
                let url = null;
                switch (type) {
                    case 0: // USER_REPLY
                        url = this.state.href("robot.analysis_dialog", {dialogId: entityId});
                        break;
                    case 1: // VA_MESSAGE
                        url = this.state.href("robot.tags.edit", {tagId: entityId, query: text});
                        break;
                    case 2:  // EXTRA_SAMPLE
                        url = this.state.href("robot.acts.examples", {act: entityId, query: text});
                        break;
                }
                if (url) {
                    window.open(url, '_blank');
                }
            });

    }

    // noinspection JSMethodCanBeStatic
    private typeTitle(item): string {
        let typeId = item.id;

        const split = typeId.split("_");
        let type = +split[0];

        switch (type) {
            case 0: // USER_REPLY
                return "Реплика пользователя";
            case 1: // VA_MESSAGE
                return "Сообщение";
            case 2:  // EXTRA_SAMPLE
                return "Дополнительный пример";
            case 3: // VA_TAG
                return "Текст тематики";
        }
        return "";
    }

}