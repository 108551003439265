import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ClusterVaTag, TreeOptions} from "../../../../model/tb-params.model";
import {DndDropEvent} from "ngx-drag-drop";
import {TbTagCreation} from "../../../../model/tb-dialog.model";
import {TagDialogComponent} from "../../cluster-create-node/tag-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TagIdsEnum} from "../../../../../../../data/va/Tag";


@Component({
    selector: 'cluster-tag-node',
    template: require('./cluster-tag-node.component.html'),
    styles: [require('./cluster-tag-node.component.less')]
})
export class ClusterTagNodeComponent {

    dropZone: string[] = [];

    @Input()
    tag: ClusterVaTag;

    @Input()
    treeOptions: TreeOptions;

    @Output()
    dropped: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    isSelected: boolean = false;

    @Input()
    displayIcons: boolean = true;

    SMALLTALK_ID: number = TagIdsEnum.SMALL_TALK_ID_ROOT;

    constructor(protected modalDialog: MatDialog) {
    }

    onDrop(event: DndDropEvent) {
        let data = {cluster: event.data, tag: this.tag};
        this.dropped.emit(data);
    }

    /**
     * Создание новой тематики
     */
    createNewTag() {

        const data: TbTagCreation = {
            viewMode: null,
            treeOptions: this.treeOptions,
            parent: this.tag
        };

        this.modalDialog.open(TagDialogComponent, {
            width: '500px',
            data: data
        });

    }

}
