import {CircleElement} from "./CircleElement";
import {VaScriptNode} from "../../../../../data/va/Script";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {STROKE_DASH} from "../../ScriptBuilderUtils";

export class EnterElement extends CircleElement {

    constructor(attributes: {node: VaScriptNode}) {
        super(attributes);
        mergeDeep(this.attributes, {
            attrs: {
                body: {
                    strokeDasharray: STROKE_DASH,
                    stroke: '#D53300',
                    fill: "#F8F8F8",
                },
                label: {
                    fill: '#D53300',
                }
            }
        });
    }
}