export default function getLineChartOptions(indexCallback: (index: number) => string) {

    return {
        chart: {
            type: 'lineChart',
            height: 225,
            margin: {
                top: 30,
                right: 70,
                bottom: 30,
                left: 70
            },
            legend: {
                align: false,
                margin: {
                    bottom: 10
                }
            },

            useInteractiveGuideline: true,
            duration: 100,
            showControls: false,
            xAxis: {
                tickFormat: (index) => indexCallback(index),
                showMaxMin: false
            },
            yAxis: {
                tickFormat: (d) => `${Math.round(d)}%`,
                showMaxMin: false,
                tickValues: [0, 100]
            },
            yDomain: [0, 100],
            noData: "Нет данных",
            callback: () => {
                d3.selectAll(`.nv-lineChart .nv-x .nv-axis .tick text`)
                    .attr('class', 'xAxis-ticks');
            },
        }
    }
}