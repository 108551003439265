import * as urls from '../../../../../../js/workplace/urls';
import {OnDestroy, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import {BaseMasterComponent} from "./base-master.component";
import {BaseDataService} from "./base-data.service";
import {Title} from "@angular/platform-browser";
import {EntityUsage} from "../../entity-usage/entity-usages.model";
import {NotificationService} from "../../common/snackbar/notification/notification.service";

/**
 * Тексты для компоненты (если не задавать в стейте, то будут дефолтные значения)
 */
interface Texts {
    newObjectTitle: string;
    editObjectTitle: string;
    successfulSaveMessage: string;
}

/**
 * Режим работы с дитейлс
 */
export enum DetailsMode {
    SHOW = "SHOW", EDIT = "EDIT", NEW = "NEW"
}

/**
 * Форма с объектом и ошибками
 */
export class Form<T> {
    success: boolean = false;
    errors = new Map<string, string>();
    object: T;
    usages: EntityUsage[] = [];
}

export abstract class BaseDetailsComponent<T, ID> implements OnInit, OnDestroy {

    /**
     * Режим работы (редактирование, просмотр, создание нового)
     */
    mode: DetailsMode;

    /**
     * Аккаунт пользователя
     */
    public account: any;

    /**
     * Есть ли доступ к редактированию
     */
    public access: boolean = true;

    /**
     * Идентификатор объекта
     */
    public objId: string;

    /**
     * Форма
     */
    public form = new Form<T>();

    /**
     * Происходит ли сейчас загрузка чего-нибудь
     */
    public isLoading: boolean = false;

    /**
     * Тексты для компоненты
     */
    protected metaTexts: Texts;

    protected constructor(protected stateService: StateService,
                          protected master: BaseMasterComponent<T, ID>,
                          protected httpClient: HttpClient,
                          public dataService: BaseDataService<T, ID>,
                          protected titleService: Title,
                          protected notificationService: NotificationService) {
    }

    async ngOnInit(): Promise<void> {
        const stateData = this.stateService.current.data;

        // из названия стейта достаём текст для названия страницы
        const title: string = stateData.title;
        this.titleService.setTitle(title);

        this.metaTexts = {
            newObjectTitle: stateData.newObjectTitle ? stateData.newObjectTitle : 'Создание',
            editObjectTitle: stateData.editObjectTitle ? stateData.editObjectTitle : 'Редактирование',
            successfulSaveMessage: stateData.successfulSaveMessage ? stateData.successfulSaveMessage : 'Успешно сохранено'
        };


        const params = this.stateService.params;
        if (params['notifySuccess']) {
            // нужно вывести уведомление об успехе, показываем
            this.notificationService.success(this.successfulSaveMessage)
            // убираем флаг "показать" из стейта
            this.stateService.go('.', {notifySuccess: false});
        }

        let objId = params[this.objectIdKey];

        // id объекта
        this.objId = objId ? objId : null;

        if (this.master) {
            // selected obj in parent ctrl
            this.master.selectedId = this.objId;
        }

        if (this.objId) {
            this.setModeEdit();
            this.setLoading(true);

            // по ID загружаем объект
            this.dataService.getObject(this.objId).then(value => {
                this.form.object = value;
                const title: string = stateData.title + this.entityTitle;
                this.titleService.setTitle(title);
                this.getAccess();
                this.getObjectUsages();
                this.onObjectLoaded();
            }, () => {
                const isCheckObjectExist = stateData.isCheckObjectExist;
                // если объекта нет, то сделаем редирект на общий список
                if (isCheckObjectExist) {
                    this.stateService.go('^');
                }
                this.onObjectLoadFailed();
            }).finally(() => {
                this.setLoading(false);
            });
        } else {
            // нет id - создание нового объекта
            this.form.object = this.generateFormObject();

            this.setModeCreate();
            this.access = (await this.dataService.getAccess())?.access;
        }
    }

    /**
     * Выбрать режим редактирования существующего объекта
     */
    setModeEdit() {
        this.mode = DetailsMode.EDIT;
    }

    /**
     * Выбрать режим редактирования
     */
    setModeCreate() {
        this.mode = DetailsMode.NEW;
    }

    /**
     * Выбрать режим просмотра
     */
    setModeShow() {
        this.mode = DetailsMode.SHOW;
    }

    /**
     * Режим просмотра?
     */
    isModeShow() {
        return this.mode === DetailsMode.SHOW;
    }

    /**
     * Режим создания?
     */
    isModeNew() {
        return this.mode === DetailsMode.NEW;
    }

    /**
     * Показывать кнопку 'отмена редактирования'
     */
    showCancel(): boolean {
        return false;
    }

    showSave() {
        return true;
    }

    get removeConfirmMessage(): string {
        return null;
    }

    /**
     * Показывать кнопку удаления
     */
    showRemove(): boolean {
        return true;
    }

    /**
     * Текст заголовка формы
     */
    get formTitle(): string {
        switch (this.mode) {
            case DetailsMode.SHOW:
            case DetailsMode.EDIT:
                return "Редактирование";
            case DetailsMode.NEW:
                return this.metaTexts.newObjectTitle;

        }
    }

    /**
     * Текст на кнопке сохранения
     */
    get saveButtonText(): string {
        switch (this.mode) {
            case DetailsMode.SHOW:
            case DetailsMode.EDIT:
                return "Обновить";
            case DetailsMode.NEW:
                return "Сохранить";
        }
    }

    /**
     * Текст успешного сохранения
     */
    get successfulSaveMessage() {
        return this.metaTexts.successfulSaveMessage;
    }

    // успешная обработка
    onSuccess() {
        // покажем, что загрузка закончена
        this.setLoading(false);
        // обнулим ошибки
        this.notificationService.success(this.successfulSaveMessage);
        this.form.errors.clear();
    }

    // отображение состояния загрузки
    setLoading(loading) {
        this.isLoading = loading;
    }

    handleError(data): void {
        this.form.errors.clear();
        // загрузка закончена
        this.setLoading(false);
        this.form.success = false;

        BaseDetailsComponent.showErrors(data, this.notificationService, this.form);
    }

    /**
     * Отобразить ошибки
     * @param data ответ сервера с ошибками
     * @param notificationService сервис уведомлений
     * @param form форма, чьи поля надо подсветить с ошибками
     */
    static showErrors(data: any, notificationService: NotificationService, form?: Form<any>) {
        const errors = data.errors;

        // пришла 503, 502, 501, 500........ ошибка
        if (!errors) {
            if (data.status === 403) {
                notificationService.error("Доступ запрещен");
            } else if (data.status === 'IN_PROCESS' || data.status === 409) {
                notificationService.warning("Уже в процессе");
            } else {
                notificationService.error("Внутренняя ошибка системы, повторите запрос позже");
            }
            return;
        }
        // список ошибок от сервера
        errors.forEach(error => {
            const fieldKey = error.field;
            const msg = error.message;
            if (!fieldKey || !form) {
                // ошибка без привязки к полю
                notificationService.error(msg)
            } else {
                form.errors.set(fieldKey, msg);
            }
        });
    }

    /**
     * Функция сохранения объекта
     */
    async save(preserveState?: boolean) {
        this.form.errors.clear();

        this.setLoading(true);

        let savePromise;
        // сохранить или обновить
        const isNew = this.mode === DetailsMode.NEW;
        if (isNew) {
            savePromise = this.dataService.save(this.form.object);
        } else {
            savePromise = this.dataService.update(this.form.object);
        }

        await this.finishSave(savePromise, isNew, preserveState);
    }

    /**
     * Сохранение с подтверждением
     */
    isSaveWithConfirm(): boolean {
        return false;
    }

    /**
     * Есть ли доступ из бека и если это младший эксперт, то можно ли ему
     */
    isSaveButtonAccess(): boolean {
        return this.access && this.allowJuniorAccess;
    }

    /**
     * Вопрос подтверждения при сохранении
     */
    get saveConfirmMessage(): string {
        return null;
    }

    /**
     * Окончание сохранения
     * @param savePromise   promise сохранения
     * @param isNew         режим сохранения
     * @param preserveState обновлять ли стейт
     */
    async finishSave(savePromise, isNew: boolean, preserveState: boolean) {
        try {
            const saved = await savePromise;
            this.form.object = saved;
            this.form.success = true;
            this.notificationService.success(this.successfulSaveMessage);
            if (isNew) {
                this.setModeEdit();
                // обновляем id объекта
                this.objId = saved.key ? saved.key.id : saved.id;
                if (this.master) {
                    //если новый объект, добавляем в список объектов в мастере
                    this.master.addNewObj(this.form.object);
                    this.master.selectedId = this.objId;
                }
                if (!preserveState) {
                    this.switchState();
                }
            } else {
                if (this.master) {
                    // заменяем в мастере объект, который редактировали
                    this.master.replaceObj(this.form.object);
                }
            }
        } catch (e) {
            this.handleError(e);
        }
        this.setLoading(false);
    }

    protected switchState(notifySuccess: boolean = true) {
        let newParams = Object.assign({}, this.stateService.params);
        newParams[this.objectIdKey] = this.objId;
        newParams['notifySuccess'] = notifySuccess;
        this.stateService.go('.', newParams, {notify: false});
    }

    /**
     * Отмена редактирования
     */
    cancel(): void {
        this.stateService.go('^');
    }

    /**
     * доступ для младшего эксперта
     * для переопределения в наследуемых компонентах
     */
    get allowJuniorAccess() {
        return !this.account || !this.account?.isJunior();
    }

    /**
     * доступ для младшего эксперта
     */
    get allowJuniorUpdate() {
        return true;
    }

    /**
     * Если это редактирование объекта или
     * это создание, где младшему эксперту это разрешено
     */
    get hideIfModeNewForJunior() {
        return this.mode == DetailsMode.NEW && !this.allowJuniorAccess;
    }

    /**
     * Функция удаления объекта
     */
    async remove() {
        this.setLoading(true);

        const object: T = this.form.object;

        let result = true;

        try {
            await this.dataService.delete(object);
            if (this.master) {
                this.master.deleteObj(this.form.object);
            }
            this.stateService.go('^');
        } catch (e) {
            this.handleError(e);
            result = false;
        }
        this.setLoading(false);
        return result;
    }

    /**
     * Функция получения доступа (по url: .../${id}/access) и сохранение его в поле .access
     */
    async getAccess() {
        this.access = await this.dataService.getAccessForObject(this.form.object);
    }

    async getObjectUsages() {
        this.form.usages = await this.dataService.getObjectUsages(this.form.object);
    }

    /**
     * Получение текущего аккаунта
     */
    async loadAccount() {
        this.account = await this.httpClient.get(`${urls.va.account}current`).toPromise();
        this.account.isJunior = () => {
            return this.account.expertType.name == 'JUNIOR';
        }
    }

    ngOnDestroy(): void {
        if (this.master) {
            this.master.selectedId = null;
        }
    }

    /**
     * Коллбэк успешной загрузки объекта
     */
    onObjectLoaded() {

    }

    /**
     * Коллбэк ошибки загрузки объекта
     */
    onObjectLoadFailed() {

    }

    /**
     * Валидация
     */
    validate(): boolean {
        return true;
    };

    isMasterLoading(): boolean {
        return this.master.isLoading;
    }

    /**
     * Метод для получения названия сущности
     */
    abstract get entityTitle(): string;

    /**
     * Сгенерить объект для формы
     */
    abstract generateFormObject(): T;

    /**
     * Ключ идентификатора объекта в параметрах состояния
     */
    abstract objectIdKey: string;
}