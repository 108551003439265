/**
 * Директива сабмита формы по нажатию enter. Требуется если мы хотим использовать textarea как input
 */
function ngEnterDirective() {
    return {
        compile: function () {
            return function link(scope, element, attrs) {
                element.bind("keydown", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.$eval(attrs.ngEnter, {'event': event});
                        });

                        event.preventDefault();
                        return true;
                    }
                });
            };
        }
    };
}
export default ngEnterDirective;