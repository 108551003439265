import {Component, Input} from '@angular/core';

enum FormControlElements {
    TITLE = 'TITLE',
    CONTENT = 'CONTENT',
    ERROR_MESSAGE = 'ERROR_MESSAGE'
}

@Component({
    selector: 'form-control',
    template: require('./form-control.component.html'),
    styles: [require('./form-control.component.less')]
})

export class FormControlComponent {

    @Input()
    form: any = {};

    @Input()
    controlId: string;

    @Input()
    controlName: string;

    @Input()
    displayErrorDescription: boolean = true;

    @Input()
    labelClasses=''

    @Input()
    highlightedElements = [FormControlElements.TITLE, FormControlElements.CONTENT, FormControlElements.ERROR_MESSAGE];

    constructor() {
    }

    get message() {
        return this.form.errors.get(this.controlId);
    }

    get hasError() {
        return this.form?.errors?.get(this.controlId);
    }

    isHighlighted(element: string) {
        return this.highlightedElements.includes(FormControlElements[element])
    }
}