'use strict';

/**
 * Constructor that takes two dotted decimal addresses.
 * @param {string} address An IP address, e.g. "192.168.0.1"
 * @param {string} mask A dotted decimal netmask e.g. "255.255.0.0"
 * @class
 */

export function SubnetUtils(address, mask) {
    const _this = this;
    /*private*/ this.netmask = 0;
    /*private*/ this.address = 0;
    /*private*/ this.network = 0;
    /*private*/ this.broadcast = 0;
    if (((typeof address === 'string') || address === null) && ((typeof mask === 'string') || mask === null)) {
        var __args = Array.prototype.slice.call(arguments);
        this.netmask = 0;
        this.address = 0;
        this.network = 0;
        this.broadcast = 0;
        (function () {
            _this.calculate(_this.toCidrNotation(address, mask));
        })();
    }
    else if (((typeof address === 'string') || address === null) && mask === undefined) {
        var __args = Array.prototype.slice.call(arguments);
        const cidrNotation_1 = __args[0];
        this.netmask = 0;
        this.address = 0;
        this.network = 0;
        this.broadcast = 0;
        (function () {
            _this.calculate(cidrNotation_1);
        })();
    }
    else
        throw new Error('invalid overload');
}
SubnetUtils.SLASH_FORMAT_$LI$ = function () { if (SubnetUtils.SLASH_FORMAT == null)
    SubnetUtils.SLASH_FORMAT = SubnetUtils.IP_ADDRESS + "\\/(\\d{1,3})"; return SubnetUtils.SLASH_FORMAT; };
/**
 * Return a {@link SubnetInfo} instance that contains subnet-specific statistics
 * @return
 * @return {SubnetUtils.SubnetInfo}
 */
SubnetUtils.prototype.getInfo = function () {
    return new SubnetUtils.SubnetInfo(this);
};
SubnetUtils.prototype.calculate = function (mask) {
    const exp = new RegExp(SubnetUtils.SLASH_FORMAT_$LI$());
    if (exp.test(mask)) {
        const test = exp.exec(mask);
        this.address = this.matchAddress(test);
        const s = test;
        const cidrPart = this.rangeCheck(/* parseInt */ parseInt(s[5]), 0, SubnetUtils.NBITS);
        for (let j = 0; j < cidrPart; ++j) {
            this.netmask |= (1 << 31 - j);
        }
        this.network = (this.address & this.netmask);
        this.broadcast = this.network | ~(this.netmask);
    }
    else
        throw Object.defineProperty(new Error("Could not parse [" + mask + "]"), '__classes', { configurable: true, value: ['java.lang.Throwable', 'java.lang.Object', 'java.lang.RuntimeException', 'java.lang.IllegalArgumentException', 'java.lang.Exception'] });
};
SubnetUtils.prototype.toInteger = function (address) {
    const exp = new RegExp(SubnetUtils.IP_ADDRESS);
    if (exp.test(address)) {
        return this.matchAddress(exp.exec(address));
    }
    else
        throw Object.defineProperty(new Error("Could not parse [" + address + "]"), '__classes', { configurable: true, value: ['java.lang.Throwable', 'java.lang.Object', 'java.lang.RuntimeException', 'java.lang.IllegalArgumentException', 'java.lang.Exception'] });
};
SubnetUtils.prototype.matchAddress = function (address) {
    let addr = 0;
    const s = address;
    for (let i = 1; i <= 4; ++i) {
        const n = (this.rangeCheck(/* parseInt */ parseInt(s[i]), 0, 255));
        addr |= ((n & 255) << 8 * (4 - i));
    }
    return addr;
};
SubnetUtils.prototype.toArray = function (val) {
    const ret = [0, 0, 0, 0];
    for (let j = 3; j >= 0; --j)
        ret[j] |= ((val >>> 8 * (3 - j)) & (255));
    return ret;
};
SubnetUtils.prototype.format = function (octets) {
    const str = {
        str: "", toString: function () {
            return this.str;
        }
    };
    const _loop_1 = function (i) {
        /* append */
        (function (sb) {
            return sb.str = sb.str.concat(octets[i]);
        })(str);
        if (i !== octets.length - 1) {
            /* append */
            (function (sb) {
                return sb.str = sb.str.concat(".");
            })(str);
        }
    };
    for (var i = 0; i < octets.length; ++i) {
        _loop_1(i);
    }
    return str.str;
};
SubnetUtils.prototype.rangeCheck = function (value, begin, end) {
    if (value >= begin && value <= end)
        return value;
    throw Object.defineProperty(new Error("Value out of range: [" + value + "]"), '__classes', { configurable: true, value: ['java.lang.Throwable', 'java.lang.Object', 'java.lang.RuntimeException', 'java.lang.IllegalArgumentException', 'java.lang.Exception'] });
};
SubnetUtils.prototype.pop = function (x) {
    x = x - ((x >>> 1) & 1431655765);
    x = (x & 858993459) + ((x >>> 2) & 858993459);
    x = (x + (x >>> 4)) & 252645135;
    x = x + (x >>> 8);
    x = x + (x >>> 16);
    return x & 63;
};
SubnetUtils.prototype.toCidrNotation = function (addr, mask) {
    return addr + "/" + this.pop(this.toInteger(mask));
};

SubnetUtils.IP_ADDRESS = "(\\d{1,3})\\.(\\d{1,3})\\.(\\d{1,3})\\.(\\d{1,3})";
SubnetUtils.NBITS = 32;
SubnetUtils["__class"] = "SubnetUtils";
(function (SubnetUtils) {
    const SubnetInfo = (function () {
        function SubnetInfo(__parent) {
            this.__parent = __parent;
        }

        SubnetInfo.prototype.netmask = function () {
            return this.__parent.netmask;
        };
        SubnetInfo.prototype.network = function () {
            return this.__parent.network;
        };
        SubnetInfo.prototype.address = function () {
            return this.__parent.address;
        };
        SubnetInfo.prototype.broadcast = function () {
            return this.__parent.broadcast;
        };
        SubnetInfo.prototype.low = function () {
            return this.network() + 1;
        };
        SubnetInfo.prototype.high = function () {
            return this.broadcast() - 1;
        };
        SubnetInfo.prototype.isInRange$java_lang_String = function (address) {
            return this.isInRange$int(this.__parent.toInteger(address));
        };
        SubnetInfo.prototype.isInRange = function (address) {
            if (((typeof address === 'string') || address === null)) {
                return this.isInRange$java_lang_String(address);
            }
            else if (((typeof address === 'number') || address === null)) {
                return this.isInRange$int(address);
            }
            else
                throw new Error('invalid overload');
        };
        SubnetInfo.prototype.isInRange$int = function (address) {
            return ((address - this.low()) <= (this.high() - this.low()));
        };
        SubnetInfo.prototype.getBroadcastAddress = function () {
            return this.__parent.format(this.__parent.toArray(this.broadcast()));
        };
        SubnetInfo.prototype.getNetworkAddress = function () {
            return this.__parent.format(this.__parent.toArray(this.network()));
        };
        SubnetInfo.prototype.getNetmask = function () {
            return this.__parent.format(this.__parent.toArray(this.netmask()));
        };
        SubnetInfo.prototype.getAddress = function () {
            return this.__parent.format(this.__parent.toArray(this.address()));
        };
        SubnetInfo.prototype.getLowAddress = function () {
            return this.__parent.format(this.__parent.toArray(this.low()));
        };
        SubnetInfo.prototype.getHighAddress = function () {
            return this.__parent.format(this.__parent.toArray(this.high()));
        };
        SubnetInfo.prototype.getAddressCount = function () {
            return (this.broadcast() - this.low());
        };
        SubnetInfo.prototype.asInteger = function (address) {
            return this.__parent.toInteger(address);
        };
        SubnetInfo.prototype.getCidrSignature = function () {
            return this.__parent.toCidrNotation(this.__parent.format(this.__parent.toArray(this.address())), this.__parent.format(this.__parent.toArray(this.netmask())));
        };
        SubnetInfo.prototype.getAllAddresses = function () {
            const addresses = new Array(this.getAddressCount());
            let add = this.low(), j = 0;
            for (; add <= this.high(); ++add, ++j) {
                addresses[j] = this.__parent.format(this.__parent.toArray(add));
            }
            return addresses;
        };
        SubnetInfo.prototype.getLowAndHigh = function () {
            return {
                low: this.__parent.format(this.__parent.toArray(this.low())),
                high: this.__parent.format(this.__parent.toArray(this.high()))
            }
        };
        return SubnetInfo;
    }());
    SubnetUtils.SubnetInfo = SubnetInfo;
    SubnetInfo["__class"] = "SubnetUtils.SubnetInfo";
})(SubnetUtils);
