import {Component} from "../../../../backports";
import {VaAttribute} from "../../../data/va/Attribute";
import {VaTag} from "../../../data/va/Tag";
import {ConfirmInfoType} from "../../../io/components/dialog/model/extracted-value.model";
import {AttemptDTO} from "../../../io/components/dialog/model/correction.model";

@Component({
    bindings: {
        tags: "<",
        form: "=",
        object: "=",
        attributes: "<",
        withPredefinedText: "<",
        specificModeCallback: "&"
    },
    templateUrl: "/pages/workplace/va/common/components/confirmationAttemptEdit.html",
})
export default class VaConfirmationAttemptEditComponent {

    // noinspection JSUnusedLocalSymbols
    private confirmationTypes: ConfirmInfoType[] = ConfirmInfoType.VALUES;

    // noinspection JSUnusedLocalSymbols
    /**
     * Форма из контроллера
     */
    private form: any;

    /**
     * Модифицируемый объект
     */
    private object: AttemptDTO;

    // noinspection JSUnusedLocalSymbols
    /**
     * Список атрибутов
     */
    private attributes: VaAttribute[];

    // noinspection JSUnusedLocalSymbols
    /**
     * Список тематик
     */
    private tags: VaTag[];

    /**
     * Нужно ли отображать выбор поля для ввода и выделения текста
     * (true-> нет, используется для коррекшеном, false -> да)
     */
    private withPredefinedText: boolean;

    /**
     * Режим выбора подтверждаемой сущности
     */
    private mode: SpecificMode = SpecificMode.GENERAL;

    /**
     * Callback для заполнения текущего object'a данными снаружи. Используется в VaAttemptEditComponent
     */
    private specificModeCallback: () => void;

    private scope: ng.IScope;

    static $inject = ["$scope"];

    constructor($scope: ng.IScope) {
        // Узнаем: это подтверждение для конкретной сущности или для всех
        this.mode = this.getMode();
        this.scope = $scope;
        this.scope.$watch('$ctrl.object', (newObject, oldObject) => {
            // Иницилизируем объект: если не обернули в TS-объект, то обернём
            if (!(newObject instanceof AttemptDTO)) {
                this.object = newObject ? new AttemptDTO(newObject) : new AttemptDTO();
                // Узнаем: это подтверждение для конкретной сущности или для всех
                this.mode = this.getMode();
            }
        }, true);
    }

    /**
     * Это подтверждение для конкретной сущности или для всех
     */
    private getMode() {
        return this.object != null && this.object.confirmInfoType != null && this.object.confirmInfoId != null ? SpecificMode.SPECIFIC : SpecificMode.GENERAL;
    }

// noinspection JSUnusedLocalSymbols
    /**
     * Если стёрли текст, то сбросим выбранное значение
     */
    // noinspection JSUnusedGlobalSymbols
    private clearValue(): void {
        if (this.object.fullText.length === 0) {
            this.object.value = null;
        }
    }

    // noinspection JSUnusedLocalSymbols
    /**
     * Действия при выборе типа подтверждения
     */
    private onConfirmationTypeChange(): void {
        // Сбросим выбранное подтверждение, значение и подтверждаемое значение
        this.object.confirmInfoId = null;
        this.object.entity = null;
        this.onEntityChange();
    }

    // noinspection JSUnusedLocalSymbols
    /**
     * Действия при выборе сущности
     */
    private onEntityChange(): void {
        // При выборе другой сущности сбосим выбранное
        this.object.confirmInfoValue = null;
        this.object.value = null;
    }

    // noinspection JSUnusedLocalSymbols
    /**
     * Действие при изменении режима выбора подтрвеждаемой сущности
     */
    private onSpecificModeChange(): void {
        switch (this.mode) {
            case SpecificMode.GENERAL:
                // Если выбрано "Для всех...", то сбросим сущность и подтверждаемое значение
                this.onConfirmationTypeChange();
                break;
            case SpecificMode.SPECIFIC:
                // Если выбрано "Для определённой ...", то попытаемся её построить по внешним данным, если есть callback
                this.specificModeCallback();
                break;
            default:
                throw new Error("Unsupported SpecificMode type")
        }
    }

    /**
     * Дизейблим селектор типа подтверждения, если нет тематик и атрибутов
     */
    get isTypeSelectorDisabled(): boolean {
        return (this.attributes && this.attributes.length === 0) || (this.tags && this.tags.length === 0);
    }

    /**
     * Дизейблим селектор тематик, если их нет
     */
    get isTagSelectorDisabled(): boolean {
        return this.tags && this.tags.length === 0;
    }

    /**
     * Дизейблим селектор атрибутов, если их нет
     */
    get isAttributeSelectorDisabled(): boolean {
        return this.attributes && this.attributes.length === 0;
    }

    /**
     * Проверка: выбран ли сейчас режим создания подтверждения "Для определённой сущности"
     */
    get isSpecificConfirmation(): boolean {
        return this.mode === SpecificMode.SPECIFIC;
    }
}

/**
 * Режим создания примера подтверждения
 */
export enum SpecificMode {
    // Для всех сущностей указанного типа
    GENERAL = "GENERAL",
    // Для определённой сущности указанного типа
    SPECIFIC = "SPECIFIC"
}