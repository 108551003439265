/**
 * Created by semyon on 19.10.17.
 */

import RegexColorizer from "../RegexColorizer";

function RegexColorizerFilter($sce) {
    return function (value) {
        let text = RegexColorizer.colorizeText(value);
        text = $sce.trustAsHtml(text);
        return text;
    };
}

RegexColorizerFilter.$inject = ["$sce"];

export default RegexColorizerFilter;