import {NgModule} from "@angular/core";
import {MassOperationDialogComponent} from "./dialog/mass-operation-dialog.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        NgSelectModule,
        FormsModule,
        MatDialogModule,
        CommonModule
    ],
    declarations: [
        MassOperationDialogComponent
    ],
    exports: [
    ],
    providers: [
    ],
    entryComponents: [
        MassOperationDialogComponent
    ]
})
export class MassOperationModule {
}