import {Component, Input, OnInit, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {AttemptDTO, VaExtractionContext} from "../../../dialog/model/correction.model";
import {VaAttribute} from "../../../../../data/va/Attribute";
import {ValueOption, ValueTypeEnum} from "../../../../../data/va/Extractor";

@Component({
    selector: 'attribute-extraction-edit',
    template: require('./attribute-extraction-edit.component.html'),
    styles: [require('./attribute-extraction-edit.component.less')],
    encapsulation: ViewEncapsulation.None,
})
export class AttributeExtractionEditComponent implements OnInit {

    /**
     * Пример извлечения
     */
    @Input()
    extractionAttempt: AttemptDTO;

    /**
     * Список атрибутов
     */
    @Input()
    attributes: VaAttribute[];

    /**
     * Список контекстов извлечения
     */
    @Input()
    contexts: VaExtractionContext[];

    constructor() {
    }

    ngOnInit(): void {
        if (this.extractionAttempt.attribute == null) {
            return;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['attributes'] && !changes['attributes'].firstChange) {
            // если обновили тематику в дереве - сбросим проставленный атрибут
            this.extractionAttempt.attribute = null;
            this.extractionAttempt.attributeId = null;
        }
    }

    /**
     * Callback выбора атрибута в селекте
     * @param attribute
     */
    onAttributeSelection(attribute?: VaAttribute) {
        if (attribute == null) {
            this.extractionAttempt.extractor = null;
            this.extractionAttempt.extractorId = null;
            this.extractionAttempt.attributeId = null;
            this.extractionAttempt.strValue = null;
        } else {
            this.extractionAttempt.extractor = attribute.extractor;
            this.extractionAttempt.extractorId = attribute.extractorId;
            this.extractionAttempt.attributeId = attribute.key.id;
            this.extractionAttempt.strValue = null;
            // если выбран ENUM-экстрактор, то надо скинуть выбранное value у extractionAttempt
            if (this.extractionAttempt.extractor.valueType.name == ValueTypeEnum.ENUM) {
                // если выбрано значение и его нет в экстракторе, то надо скинуть
                if (this.extractionAttempt.value && !this.isSelectedOptionValid()) {
                    this.extractionAttempt.value = null;
                }
            }
        }
    }

    /**
     * Callback выбора во в селекте
     */
    onValueOptionSelected(valueOption: ValueOption) {
        if (valueOption) {
            this.extractionAttempt.strValue = valueOption.key.id + "";
        } else {
            this.extractionAttempt.strValue = null;
        }
    }

    /**
     * Есть ли выбранная опция в экстракторе у выбранного атрибута
     */
    private isSelectedOptionValid() {
        return this.extractionAttempt.extractor
            .valueOptions
            .some(valueOption => valueOption.key.id == this.extractionAttempt.value.key.id);
    }
}