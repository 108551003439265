import WebSocketService from "../../services/WebSocketService";
import StatisticsService from "../../services/StatisticsService";
import AccountService from "../../services/AccountService";

export function websocketServiceFactory(i: any) {
    return i.get('WebSocketService');
}

export const websocketServiceProvider = {
    provide: WebSocketService,
    useFactory: websocketServiceFactory,
    deps: ['$injector']
};


/**
 * Провайдер для сервиса записи кликов на фронте в БД
 */
export const statisticsServiceProvider = {
    provide: StatisticsService,
    useFactory: injector => injector.get('StatisticsService'),
    deps: ['$injector']
};

export const accountServiceProvider = {
    provide: AccountService,
    useFactory: injector => injector.get('AccountService'),
    deps: ['$injector']
};