import {EruditeElement} from "../element-view/EruditeElement";
import EruditeLink from "../link-view/EruditeLink";
import {dia} from '@naumen/rappid';

export enum ElementAttributeId {
    SUB_ID = "sub_id",
    VALUE = "value",
    TEXT = "text",
    CHECKPOINT = "checkpoint",
    FOLLOWING_EXTRACTION = "FOLLOWING_EXTRACTION",
    COMMAND_PARAMS = "command_params",
    NAME = "name",
}

export enum LinkAttributeId {
    CONDITIONS = "conditions",
    CHECKPOINT = "checkpoint",
}

/**
 * При проверке, нужно ли выводить конфирм ухода со страницы, проверяем только эти поля в VAScript
 */
export const CHECKABLE_DATA_STRUCTURE = {
    name: true,
    tagId: true,
    nodes: {
        stepLabel: {
            type: {
                name: true
            },
            entityId: true,
            subId: true,
            name: true,
            commandParams: true,
            value: true
        },
        checkpoint: true,
        x: true,
        y: true,
        width: true,
        height: true
    },
    edges: {
        fromNodeId: true,
        toNodeId: true,
        checkpoint: true,
        vertices: true,
        conditions: {
            type: {
                name: true
            },
            entityId: true,
            values: true,
            except: true,
            primitiveRule: {
                type: {
                    name: true
                },
                expressionString: true
            }
        }
    }
};

/**
 * Глубокое сравнение объектов по заданной структуре
 *
 * @param saved первый объект
 * @param current второй объект
 * @param structure описание структуры
 * @param field название поля
 * @return {equals: результат сравения, desc: описание расхождения для логов при equals==false}
 */
export function deepEqual(saved: any, current: any, structure: any, field: string): {equals: boolean, desc?: string} {
    if (saved === current) {
        // или один объект или одинаковые типы и значения
        return {equals: true};
    }
    if (typeof saved !== "object" || typeof current !== "object") {
        // если один из объектов примитивный или undefined - не равны
        return {equals: false, desc: `field: ${field}, saved: ${saved}, current: ${current}`};
    }
    if (Array.isArray(saved) && Array.isArray(current)) {
        // если объекты - массивы
        if (saved.length !== current.length) {
            // длины не равны
            return {equals: false, desc: `field: ${field}, saved length: ${saved.length}, current: ${current.length}`};
        }
        // проверяем все элементы массива
        for (let i = 0; i < current.length; i++) {
            let result = deepEqual(saved[i], current[i], structure, `${field}[${i}]`);
            if (!result.equals) {
                // рекурсивнвя грубокая проверка не прошла - не равны
                return result;
            }
        }
        // все элементы равны
        return {equals: true};
    }
    if (Array.isArray(saved) != Array.isArray(current)) {
        // один объект - массив, другой - нет
        return {equals: false, desc: `field: ${field}, saved is array: ${Array.isArray(saved)}, current is array: ${Array.isArray(current)}`};
    }
    // по всем полям
    for (const property in saved) {
        if (!saved.hasOwnProperty(property)) {
            // не свое проперти, пропускам
            continue;
        }
        if (!structure.hasOwnProperty(property)) {
            // нет в требуемых для проверки полях, пропускам
            continue;
        }

        let subField = `${field}.${property}`;
        if (!current.hasOwnProperty(property)) {
            // у второго объекта нет такой проперти - пропускаем
            return {equals: false, desc: `field: ${field}, current doesn't have property '${subField}'`};
        }
        let result = deepEqual(saved[property], current[property], structure[property], subField);
        if (!result.equals) {
            // рекурсивнвя грубокая проверка полей не прошла - не равны
            return result;
        }
    }
    // объекты равны
    return {equals: true};
}

/**
 * Сохранить значение элемента скрипта в атрибуты рапида для возможности undo
 *
 * @param element элемент
 * @param id идентификатор атрибута
 * @param value значение
 */
export function setElementAttribute(element: EruditeElement, id: ElementAttributeId, value: any) : void {
    setCellAttribute(element, 'erudite/element/' + id, value);
}

/**
 * Сохранить значение ребра скрипта в атрибуты рапида для возможности undo
 *
 * @param link ребро
 * @param id идентификатор атрибута
 * @param value значение
 */
export function setLinkAttribute(link: EruditeLink, id: LinkAttributeId, value: any) : void {
    setCellAttribute(link, 'erudite/link/' + id, value);
}

/**
 * Установить значение атрибута
 * @param cell
 * @param attributeId
 * @param value
 */
function setCellAttribute(cell: dia.Cell, attributeId: string, value: any) {
    if (value && value.length == 0) {
        // пустой массив обычным способом не проставляется
        cell.removeAttr(attributeId);
    } else {
        // остальное как обычно
        cell.attr(attributeId, value);
    }
}

/**
 * Сохранить в backbone-атрибуты поля элемента, которые записать в историю команд
 * @param element элемент
 */
export function saveElementObjectToAttributes(element: EruditeElement) {
    Object.values(ElementAttributeId).forEach(attributeId => {
        setElementAttribute(element, attributeId, getElementObjectValue(element, attributeId));
    });
}

/**
 * Сохранить в backbone-атрибуты поля линка, которые записать в историю команд
 * @param link линк
 */
export function saveLinkObjectToAttributes(link: EruditeLink) {
    Object.values(LinkAttributeId).forEach(attributeId => {
        setLinkAttribute(link, attributeId, getLinkObjectValue(link, attributeId));
    });
}

/**
 * Получить значение из элемента для заданного id атрибута
 * @param element элемент
 * @param attributeId атрибут
 */
export function getElementObjectValue(element: EruditeElement, attributeId: ElementAttributeId) {
    switch (attributeId) {
        case ElementAttributeId.SUB_ID:
            return element.node.stepLabel.subId;
        case ElementAttributeId.VALUE:
            return element.node.stepLabel.value;
        case ElementAttributeId.TEXT:
            return element.node.text;
        case ElementAttributeId.CHECKPOINT:
            return element.node.checkpoint;
        case ElementAttributeId.FOLLOWING_EXTRACTION:
            return element.node.stepLabel.followingExtraction;
        case ElementAttributeId.COMMAND_PARAMS:
            return element.node.stepLabel.commandParams;
        case ElementAttributeId.NAME:
            return element.node.stepLabel.name;
        default:
            throw new Error(`Unsupported erudite attribute id: ${attributeId}`)
    }
}

/**
 * Получить значение из линка для заданного id атрибута
 * @param link линк
 * @param attributeId атрибут
 */
export function getLinkObjectValue(link: EruditeLink, attributeId: LinkAttributeId) {
    switch (attributeId) {
        case LinkAttributeId.CONDITIONS:
            return link.scriptEdge.conditions;
        case LinkAttributeId.CHECKPOINT:
            return link.scriptEdge.checkpoint;
        default:
            throw new Error(`Unsupported erudite attribute id: ${attributeId}`)
    }

}

/**
 * Восстановить значения элемента, которые хранились в атрибутах и пришли в ченджах при undo/redo
 * (который на самом деле присылает не чендж, а все, поэтому сравниваем с текущими значениями
 * для диагностики того, было ли что-то восстановлено)
 *
 * @param element элемент
 * @param actualValues актуальные значения атрибутов
 */
export function restoreElementObject(element: EruditeElement, actualValues: any): boolean {
    // было ли что-то восстановлено
    let restored = false;
    Object.values(ElementAttributeId).forEach(attributeId => {
        // по всем атрибутам, берем актуальное значение для атрибута
        let actualValue = actualValues[attributeId];
        // сравниваем с текущим значением
        const current = getElementObjectValue(element, attributeId);
        if (current == actualValue) {
            // такое же, ок
            return;
        }
        // восстанавливаем значение
        restored = true;
        switch (attributeId) {
            case ElementAttributeId.SUB_ID:
                element.node.stepLabel.subId = actualValue;
                break;
            case ElementAttributeId.VALUE:
                element.node.stepLabel.value = actualValue;
                break;
            case ElementAttributeId.TEXT:
                element.node.text = actualValue;
                break;
            case ElementAttributeId.CHECKPOINT:
                element.node.checkpoint = actualValue;
                break;
            case ElementAttributeId.FOLLOWING_EXTRACTION:
                element.node.stepLabel.followingExtraction = actualValue;
                break;
            case ElementAttributeId.COMMAND_PARAMS:
                element.node.stepLabel.commandParams = actualValue;
                break;
            case ElementAttributeId.NAME:
                element.node.stepLabel.name = actualValue;
                break;
            default:
                throw new Error(`Unsupported erudite attribute id: ${attributeId}`);
        }
    });
    return restored;
}

/**
 * Восстановить значения линка, которые хранились в атрибутах и пришли в ченджах при undo/redo
 *
 * @param link линк
 * @param actualValues актуальные значения атрибутов
 */
export function restoreLinkObject(link: EruditeLink, actualValues: any): boolean {
    // было ли что-то восстановлено
    let restored = false;
    Object.values(LinkAttributeId).forEach(attributeId => {
        // по всем атрибутам, берем актуальное значение для атрибута
        let actualValue = actualValues[attributeId];
        // сравниваем с текущим значением
        const current = getLinkObjectValue(link, attributeId);
        if (current == actualValue) {
            // такое же, ок
            return;
        }
        // восстанавливаем значение
        restored = false;
        switch (attributeId) {
            case LinkAttributeId.CONDITIONS:
                link.scriptEdge.conditions = typeof actualValue == "undefined" ? [] : actualValue;
                break;
            case LinkAttributeId.CHECKPOINT:
                link.scriptEdge.checkpoint = actualValue;
                break;
            default:
                throw new Error(`Unsupported erudite attribute id: ${attributeId}`)
        }
    });
    return restored;
}