import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {MlService} from "../ml.service";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";
import {MLComponentType, ModelStatusInfoDto} from "../../../../../controllers/va/ml/models/ml.model";
import WebSocketService from "../../../../../services/WebSocketService";
import {AppConfigService} from "../../../../service/app-config.service";

@Component({
    selector: 'ml-master',
    template: require('./ml-master.component.html'),
    styles: [require('./ml-master.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class MlMasterComponent implements OnInit {
    mlAccess: boolean;
    mlComponentTypes: ModelStatusInfoDto[];
    selectedType: any;
    private readonly projectVersionId: string;
    access: boolean;
    isLoading: boolean;
    hideSuccess: boolean;
    lastUpdateDate: any;
    timezone: string;
    idealDialogEvaluation: SetCheckProgress;
    private subscriptionId: string;

    readonly iconMap = {
        STEP: "fa-blind green-500",
        EXTRACT_AFTER_Q_ATTR: "fa-cube blue-500",
        EXTRACT_PLAIN_ATTR: "fa-cubes red-500",
        TAG: 'fa-archive yellow-500'
    }

    readonly colorMap = {
        STEP: "bg-green-200",
        EXTRACT_AFTER_Q_ATTR: "bg-blue-200",
        EXTRACT_PLAIN_ATTR: "bg-red-200",
        TAG: 'bg-yellow-200'
    }

    constructor(titleService: Title,
                private stateService: StateService,
                private mlService: MlService,
                private webSocketService: WebSocketService,
                appConfigService: AppConfigService) {
        this.timezone = appConfigService.interfaceConfig.timezone;
        const title: string = this.stateService.current.data.title;
        titleService.setTitle(title);
        this.projectVersionId = this.stateService.params['projectVersionId'];

    }

    async ngOnInit() {
        const dataDto = await this.mlService.getMlComponentTypesAndAccess();
        this.mlAccess = dataDto.mlAccess;
        this.mlComponentTypes = dataDto.modelList;
        this.access = dataDto.access;
        await this.updateLastUpdateDate();

        // подписываемся на событие ожидания ошибки при ребилде
        // подписываемся на события билда
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_REBUILD_MODEL,VA_REBUILD_MODEL_FINISHED,VA_DIALOG_AUTOCHECK",
            fn: event => {
                switch (event.type) {
                    case "VA_REBUILD_MODEL":
                        let componentTypes = JSON.parse(event.details);
                        if (componentTypes.length && componentTypes[0].projectVersionId === this.projectVersionId) {
                            this.mlComponentTypes = componentTypes;
                            this.updateLastUpdateDate();
                        }
                        break;
                    case "VA_REBUILD_MODEL_FINISHED":
                        let finishedComponentTypes = JSON.parse(event.details);
                        if (finishedComponentTypes.length && finishedComponentTypes[0].projectVersionId === this.projectVersionId) {
                            this.mlComponentTypes = finishedComponentTypes;
                            this.updateLastUpdateDate();
                            this.mlService.loadModelsEvent.emit(true);
                        }
                        break;
                    case "VA_DIALOG_AUTOCHECK":
                        this.updateDialogAutocheckState(JSON.parse(event.details));
                        break;
                    default:
                        break;
                }
            }
        });

        await this.getCurrentIdealDialogProgress();
    }

    async getCurrentIdealDialogProgress() {
        await this.mlService.getAutocheckProgress().then(value => {
            if (value) {
                this.updateDialogAutocheckState(value);
            }
        }, () => {
        });
    }

    updateDialogAutocheckState(value: SetCheckProgress) {
        this.idealDialogEvaluation = MlMasterComponent.handleIdealDialogEvaluation(value);
    }

    private static handleIdealDialogEvaluation(value: SetCheckProgress): SetCheckProgress {
        value.successCount = value.processedCount - value.errorsCount - value.differencesCount;
        value.differences = (value.differencesCount / value.count) * 100;
        value.errors = (value.errorsCount / value.count) * 100;
        value.success = (value.successCount / value.count) * 100;
        return value;
    }

    isBuild(type: MLComponentType) {
        if (!this.mlComponentTypes) {
            return false;
        }
        const modelStatusByType = this.mlComponentTypes.find(obj => obj.projectVersionId === this.projectVersionId && obj.type.name === type.name);
        return modelStatusByType && modelStatusByType.building;
    }

    /**
     * Переобучение одной модели с указанным типом
     */
    rebuildOneModel(type): void {
        // сразу считаем, что пошло обучение
        this.updateBuildStatus(true, type);
        this.mlService.getRebuildModel(type.name).then(() => {
        }, () => {
            // если ошибка, то уберем тип из данны по обучени
            this.updateBuildStatus(false, type);
        });
    }

    /**
     * Функция инициализации python-а
     */
    async rebuildModels() {
        this.updateBuildStatus(true);
        // у этой операции не отображаем успех
        this.hideSuccess = true;
        this.isLoading = true;
        await this.mlService.getRebuildModels().then(
            () => this.hideSuccess = false,
            () => this.updateBuildStatus(false)
        );
        this.isLoading = false;
    }

    private updateBuildStatus(building: boolean, type?: MLComponentType) {
        this.mlComponentTypes
            .filter(rebuildInfo => rebuildInfo.projectVersionId === this.projectVersionId && (!type || rebuildInfo.type.name === type.name))
            .forEach(rebuildInfo => rebuildInfo.building = building);
    }

    async initPython() {
        this.isLoading = true;
        await this.mlService.getInitPython();
        this.isLoading = false;
    }

    async initModels() {
        this.isLoading = true;
        await this.mlService.getInitModels();
        this.isLoading = false;
    }

    isBuildingAny() {
        if (!this.mlComponentTypes) {
            return false;
        }
        const buildingModel = this.mlComponentTypes.find(obj => obj.building);
        return buildingModel != null;
    }

    /**
     * Запустить проверку диалогами
     */
    async runIdealDialogEvaluation() {
        this.resetIdealDialogEvaluation();
        await this.mlService.runAutocheck();
    }

    /**
     * В процессе ли проверка диалогами
     */
    isIdealDialogEvaluationRunning() {
        return this.idealDialogEvaluation && this.idealDialogEvaluation.processedCount < this.idealDialogEvaluation.count;
    }

    /**
     * Очистить результат проверки эталонными диалогами
     */
    private resetIdealDialogEvaluation() {
        if (this.idealDialogEvaluation) {
            this.idealDialogEvaluation = {
                count: this.idealDialogEvaluation.count,
                processedCount: 0,
                errorsCount: 0,
                differencesCount: 0,
                successCount: 0,
                success: 0,
                errors: 0,
                differences: 0,
            }
        }
    }

    async updateLastUpdateDate() {
        this.lastUpdateDate = await this.mlService.getLastUpdateDate();
    }
}

interface SetCheckProgress {
    count: number;

    processedCount: number;

    errorsCount: number;

    differencesCount: number;

    successCount?: number;

    success?: number;

    errors?: number;

    differences?: number;

    lastUpdateDate?: number;

}