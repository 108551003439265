import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import {Dialog} from "../dialog/model/dialog.model";
import {
    AutocompleteToRobotArgs,
    ChatOnAutocompleteArgs,
    ChatOnReplyArgs,
    DialogMessageToRobotArgs,
    MRCPCode, ScoreToRobotArgs
} from "./chat.model";
import {Candidate, ChatOnPrompterArgs, PrompterChoiceToRobotArgs} from "./prompter/prompter.model";

@Injectable()
export class ChatService {

    private baseUrl: string = "/account/expert/chat";
    private apiUrl: string = "/api";
    private acceptHeader: string = "application/vnd.erudite.api-3+json";

    constructor(protected httpClient: HttpClient, protected stateService: StateService) {
    }

    async getCommands(): Promise<MRCPCode[]> {
        return await this.httpClient.get<MRCPCode[]>(`${this.baseUrl}/commands`).toPromise();
    }

    async getLastDialog(): Promise<Dialog> {
        return await this.httpClient.post<Dialog>(`${this.baseUrl}/lastDialog`, null).toPromise();
    }

    async sendMessage(projectVersionId: string, args: DialogMessageToRobotArgs): Promise<ChatOnReplyArgs> {
        return await this.httpClient.post<ChatOnReplyArgs>(`${this.apiUrl}/${projectVersionId}/dialog`, args,
            {headers: {Accept: this.acceptHeader}}).toPromise();
    }

    async sendPrompterMessage(projectVersionId: string, args: DialogMessageToRobotArgs): Promise<ChatOnPrompterArgs> {
        return await this.httpClient.post<ChatOnPrompterArgs>(`${this.apiUrl}/${projectVersionId}/prompter`, args,
            {headers: {Accept: this.acceptHeader}}).toPromise();
    }

    async closeDialog(projectVersionId: string, args: {sessionId: string}): Promise<any> {
        return await this.httpClient.post(`${this.apiUrl}/${projectVersionId}/sessionclose`, args,
            {headers: {Accept: this.acceptHeader}}).toPromise();
    }

    async rateDialog(projectVersionId: string, args: ScoreToRobotArgs): Promise<any> {
        return await this.httpClient.post(`${this.apiUrl}/${projectVersionId}/score`, args,
            {headers: {Accept: this.acceptHeader}}).toPromise();
    }

    async getAutocompleteMessages(projectVersionId: string, args: AutocompleteToRobotArgs): Promise<ChatOnAutocompleteArgs> {
        return await this.httpClient.post<ChatOnAutocompleteArgs>(`${this.apiUrl}/${projectVersionId}/autocomplete`, args,
            {headers: {Accept: this.acceptHeader}}).toPromise();
    }

    /**
     * Получение кандидатов суфлера
     *
     * @param sessionId id сессии
     */
    async getCandidates(sessionId: string): Promise<Candidate[]> {
        return await this.httpClient.get<Candidate[]>(`/prompter/candidates`, {params: {sessionId: sessionId}}).toPromise();
    }

    /**
     * Операторский ответ при суфлере
     *
     * @param projectVersionId id версии
     * @param sessionId id диалога
     * @param candidateId id кандидата
     * @param text текст реплики
     */
    async prompterChoice(projectVersionId: string, sessionId: string, candidateId?: string, text?: string): Promise<void> {
        const args: PrompterChoiceToRobotArgs = {
            sessionId: sessionId,
            id: candidateId,
            text: text
        }
        await this.httpClient.post<void>(`/api/${projectVersionId}/prompterchoice`, args, {headers: {Accept: this.acceptHeader}}).toPromise();
    }
}