import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FileMasterComponent} from "./master/file-master.component";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {CollectionModule} from "../../common/collection/collection.module";
import {ExpertModule} from "../../common/expert/expert.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {FileService} from "./file.service";
import {EruditeNgbDatepickerModule} from "../../common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {DenyButtonModule} from "../../common/deny-button/deny-button.module";
import {EditableListModule} from "../../common/editable-list/editable-list.module";
import {FileSizePipe} from "./master/file-size.pipe";
import {FormControlModule} from "../../common/form/form-control.module";


@NgModule({
    declarations: [
        FileMasterComponent,
        FileSizePipe
    ],
    imports: [
        FormsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        UIRouterUpgradeModule,
        CollectionModule,
        ExpertModule,
        BoilerplateModule,
        EruditeNgbDatepickerModule,
        NgSelectModule,
        DenyButtonModule,
        EditableListModule,
        FormControlModule
    ],
    exports: [
        FileMasterComponent,
        FileSizePipe
    ],
    providers: [
        FileService
    ]
})
export class FileModule {

    static states = [
        {
            name: "robot.file",
            url: "/file",
            views: {
                'content@authorized': {
                    component: FileMasterComponent
                }
            },
            data: {
                title: "Файлы",
                requiredProjectVersion: false,
            },
        }
    ];

}