import {NgModule} from "@angular/core";

import {CollectionModule} from "../../common/collection/collection.module";
import {CollectionComponent} from "../../common/collection/collection.component";
import {FormControlModule} from "../../common/form/form-control.module";
import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {LoginsDetailsComponent} from "./details/logins-details.component";
import {LoginsMasterComponent} from "./master/logins-master.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {EruditeNgbDatepickerModule} from "../../common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";
import {FormsModule} from "@angular/forms";
import {BoilerplateModule} from "../../va/base/boilerplate.module";
import {LoginsService} from "./logins.service";
import {ChangesModule} from "../changes/changes.module";
@NgModule({
    imports: [
        CollectionModule,
        CommonModule,
        UIRouterModule,
        FormControlModule,
        NgSelectModule,
        EruditeNgbDatepickerModule,
        FormsModule,
        BoilerplateModule,
        ChangesModule,
    ],
    declarations: [
        LoginsDetailsComponent,
        LoginsMasterComponent
    ],
    entryComponents: [
        CollectionComponent
    ],
    providers: [
        LoginsService
    ]
})
export class LoginsModule {

    static states = [
        {
            name: 'journals.logins',
            url: "/logins",
            views: {
                "content@authorized": {
                    component: LoginsMasterComponent
                }
            },
            data: {
                title: "История логинов"
            }
        },
        {
            name: 'journals.logins.events',
            url: "/:filter",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@journals.logins': {
                    component: LoginsDetailsComponent
                }
            },
            data: {
                title: "История логинов"
            }
        }
    ];

}