/**
 * Created by semyon on 14.03.19.
 */

export default function NumeralFilter() {
    const cases = [2, 0, 1, 1, 1, 2];
    return function (value: number, titles: string[]) {
        return `${value} ${titles[(value % 100 > 4 && value % 100 < 20) ? 2 : cases[(value % 10 < 5) ? value % 10 : 5]]}`;
    };
}
