/**
 * Created by semyon on 22.06.16.
 */
/**
 * Parses "repeat" attribute.
 *
 * Taken from AngularJS ngRepeat source code
 * See https://github.com/angular/angular.js/blob/v1.2.15/src/ng/directive/ngRepeat.js#L211
 *
 * Original discussion about parsing "repeat" attribute instead of fully relying on ng-repeat:
 * https://github.com/angular-ui/ui-select/commit/5dd63ad#commitcomment-5504697
 */
export default class RepeatParser {

    constructor($parse) {
        var self = this;

        /**
         * Example:
         * expression = "address in addresses | filter: {street: $select.search} track by $index"
         * itemName = "address",
         * source = "addresses | filter: {street: $select.search}",
         * trackByExp = "$index",
         */
        self.parse = function (expression) {


            var match;
            //var isObjectCollection = /\(\s*([\$\w][\$\w]*)\s*,\s*([\$\w][\$\w]*)\s*\)/.test(expression);
            // If an array is used as collection

            // if (isObjectCollection){
            // 000000000000000000000000000000111111111000000000000000222222222222220033333333333333333333330000444444444444444444000000000000000055555555555000000000000000000000066666666600000000
            match = expression.match(/^\s*(?:([\s\S]+?)\s+as\s+)?(?:([\$\w][\$\w]*)|(?:\(\s*([\$\w][\$\w]*)\s*,\s*([\$\w][\$\w]*)\s*\)))\s+in\s+(\s*[\s\S]+?)?(?:\s+track\s+by\s+([\s\S]+?))?\s*$/);

            // 1 Alias
            // 2 Item
            // 3 Key on (key,value)
            // 4 Value on (key,value)
            // 5 Source expression (including filters)
            // 6 Track by

            if (!match) {
                throw new Error('iexp', "Expected expression in form of '_item_ in _collection_[ track by _id_]' but got '{0}'.",
                    expression);
            }

            var source = match[5],
                filters = '';

            // When using (key,value) ui-select requires filters to be extracted, since the object
            // is converted to an array for $select.items
            // (in which case the filters need to be reapplied)
            if (match[3]) {
                // Remove any enclosing parenthesis
                source = match[5].replace(/(^\()|(\)$)/g, '');
                // match all after | but not after ||
                var filterMatch = match[5].match(/^\s*(?:[\s\S]+?)(?:[^\|]|\|\|)+([\s\S]*)\s*$/);
                if (filterMatch && filterMatch[1].trim()) {
                    filters = filterMatch[1];
                    source = source.replace(filters, '');
                }
            }

            return {
                itemName: match[4] || match[2], // (lhs) Left-hand side,
                keyName: match[3], //for (key, value) syntax
                source: source,
                filters: filters,
                trackByExp: match[6],
                modelMapper: $parse(match[1] || match[4] || match[2]),
                repeatExpression: function () {
                    var expression = this.itemName + ' in ' + source;
                    if (this.trackByExp) {
                        expression += ' track by ' + this.trackByExp;
                    }
                    return expression;
                }
            };

        };
    }

}

RepeatParser.$inject = ['$parse'];