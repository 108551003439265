import {BaseDataService} from "./base-data.service";
import {CompositeKey} from "../../../../data/va/Common";

export interface VersionedEntity<ID> {

    key: CompositeKey<ID>;

}

export abstract class VersionedDataService<T extends VersionedEntity<ID>, ID> extends BaseDataService<T, CompositeKey<ID>> {

    public FICTIVE_ROOT_TAG_ID = -1;

    getObjectId(object: T): string {
        return `${object.key.id}`;
    }

}