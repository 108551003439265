import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import WebSocketService from "../../../services/WebSocketService";
import {NamedEntitiesModel} from "../va/model/named-entities.model";
import {LicensePeriodSummaryParams} from "./models/license-period-summary-params.model";
import {LicenseWholeChartModel} from "./models/license-whole-chart.model";

@Injectable()
export class LicenseService {

    baseUrl: string = "/account/expert/license";
    readonly entityClass: NamedEntitiesModel = NamedEntitiesModel.LICENSE;

    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
    }

    /**
     * Получить данные по лицензиям
     */
    async currentSummary(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/currentSummary`).toPromise();
    }

    /**
     * Статистика за период по занятым лицензиям
     */
    async periodSummary(params: LicensePeriodSummaryParams): Promise<LicenseWholeChartModel> {
        return await this.httpClient.post<LicenseWholeChartModel>(`${this.baseUrl}/periodSummary`, params).toPromise();
    }

    /**
     * Используются ли на текущий момент пиковые лицензии или нет
     */
    async isIncrementalLicenseEnabled(): Promise<boolean> {
        return await this.httpClient.get<boolean>(`${this.baseUrl}/isIncrementalLicenseEnabled`).toPromise();
    }

    /**
     * Переключить
     */
    async switchIncremental(enable: boolean): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/switchIncremental?enable=${enable}`).toPromise();
    }

    async getAccess(): Promise<boolean> {
        const data = await this.httpClient.get<{ access: boolean }>(`${this.baseUrl}/access`).toPromise();
        return data && data.access;
    }
}
