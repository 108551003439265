import {CompositeKey} from "./Common";
import {VaAttribute} from "./Attribute";
import {VATagAnswer} from "./TagAnswer";
import {UserAct, VASimpleModuleAnswer} from "./SimpleModuleAnswer";
import {VaMessage} from "./Message";

/**
 * Описание: {@link ru.naumen.chat.va.data.entity.questions.VATag}
 */
export class VaTag {
    key: CompositeKey<number>;
    text: string;
    scenarioId: number;
    parentId: number;
    isTopic: boolean;
    skillId?: number;
    deleted: boolean;
    useInTrain: boolean;
    comment?: string;
    expertId?: string;
    d: Date;
    creationDate: Date;
    confirmQuestionId?: number;
    answerIds: number[];
    attributeIds: number[];
    smaIds: number[];
    coarse: boolean;
    useInTbClassification: boolean;
    attributes?: VaAttribute[];
    answers?: VATagAnswer[];
    toSupportAnswers?: VASimpleModuleAnswer[];
    toTags?: VaTag[];
    children?: VaTag[];
    messages?: VaMessage[];
    messageCount?: number;
    expert?: any;
    access?: boolean;
    tagViewStats?: VaTagViewStats;

    //возврат во внешний сценарий
    nestable: boolean;
    repeatOnlyLastReply: boolean = false;
    returnAnswerId?: number;

    // фронтенд поля
    lastChild?: boolean;
    hidden?: boolean;
    highlightedText?: string;
    name?: string;
    processedByRobot?: boolean = null;
    isScriptedTag?: boolean = null;
    title: string;
    userAct: UserAct;
    hide:boolean;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.text = object.text;
        this.parentId = object.parentId;
        this.scenarioId = object.scenarioId;
        this.isTopic = object.isTopic;
        this.skillId = object.skillId;
        this.deleted = object.deleted;
        this.useInTrain = object.useInTrain;
        this.comment = object.comment;
        this.expertId = object.expertId;
        this.d = object.d;
        this.creationDate = object.creationDate;
        this.confirmQuestionId = object.confirmQuestionId;
        this.answerIds = object.answerIds;
        this.attributeIds = object.attributeIds;
        this.smaIds = object.smaIds;
        this.coarse = object.coarse;
        this.useInTbClassification = object.useInTbClassification;
        this.attributes = object.attributes;
        this.answers = object.answers;
        this.toSupportAnswers = object.toSupportAnswers;
        this.toTags = object.toTags;
        this.children = object.children;
        this.messages = object.messages;
        this.messageCount = object.messageCount;
        this.expert = object.expert;
        this.access = object.access;
        this.tagViewStats = object.tagViewStats;
        this.hide = object.hide;
    }
}

export interface VaTagViewStats {
    ratedDialogsCount: number;
    dialogsCount: number;
    avgUserRating: number;

}

export enum TagIdsEnum {
    SMALL_TALK_ID_ROOT = 100000,
    SMALL_TALK_ID_OPERATOR_REQUEST = 100008,
    SMALL_TALK_ID_UPPER_LIMIT= 100100
}
