import {Component, Inject, Input} from "@angular/core";
import * as restangular from "restangular";
import * as urls from "../../../../js/workplace/urls.js";
import * as common from "../../../../js/workplace/common";

@Component({
    selector: "aq-extractable-info",
    template: require("../../../../pages/workplace/va/ml/models/aqext/aqExtractableInfos.html")
})
export default class AqExtractableInfoComponent {

    @Input() private test: {
        object: any
    };

    @Input()
    private modelName: string;

    @Input()
    private modelId: number;

    private infos: any[];
    private value: any;

    private readonly restAngular: restangular.IService;

    constructor(@Inject('$injector') $injector) {
        this.restAngular = $injector.get('Restangular');
    }

    ngOnInit() {
        this.restAngular.one(`${urls.va.ml}/${this.modelName}/${this.modelId}/infos`).get().then((data) => {
            this.infos = this.restAngular.stripRestangular(data)
        })
    }

    /**
     * Функция обработчик выбора
     */
    handleValueSelection() {
        if (this.value == null) {
            return;
        }
        // id (у confirmation пока нет projectVersion поэтому в этом случае просто id)
        this.test.object.id = this.value.key != null ? this.value.key.id : this.value.id;
        // тип (атрибут/подтверждение)
        this.test.object.type = common.typeOfExtractableValue(this.value);
    }
}