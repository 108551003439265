import {VaTBMessageCluster} from "../../../../data/va/Message";
import {VaTag} from "../../../../data/va/Tag";

export class DumpFileType {
    name: string;
    title: string;
    id: number;
}

export class ClusterVaTag extends VaTag {
    clusterMsgCount: any;
    remembered: boolean;
    children: ClusterVaTag[];
}

export class ClusterBuildParams {
    averageClusterSize: number;
    classifierBatchSize: number;
    classifierEpochs: number;
    classifierPatience: number;
    classifierStepsPerEpoch: number;
    classifyThreshold: number;
    clusterMessagesMax: number;
    developMode: boolean;
    fastTextEmbDim: number;
    fastTextEpochs: number;
    fastTextModel: string;
    minClusterSize: number;
    partitionSize: number;
    sufficiencyDialogsPercent: number;
    useLabeledData: boolean;
    vectorizerMaxFeatures: number;
    verbose: number;
    dumpFormatParams: DumpFormatParams;
}

export class DumpFormatParams {
    csvColumnSeparator: string;
    dialogIdColumnName: string;
    fileType: DumpFileType;
    senderColumnName: string;
    senderIncomingValue: string;
    senderOutgoingValue: string;
    textColumnName: string;
    timestampColumnName: string;
    timestampFormat: string;
    numberInSessionColumnName: any;
}

export class Info {
    dialogs: number;
    importedMessages: number;
    messages: number;
    notClusteredDialogs: number;
    replies: number;
    sourceTitle: string;
    stateClusterCounts: any;
    stateMessageCounts: any;
    processedOperation: any;
}

/**
 * Флаги выключения линий в графике размеров датасета
 */
export class DatasetChartDataEnabled {
    tags: boolean;
    messages: boolean;
}

export class ResponseTemp<T> {
    status: any;
    errors: any[];
    result: T;
}

export class DialogDumpLoadResult {
    //Всего диалогов
    dialogsInFile: number;
    //Новых диалогов
    newDialogs: number;
    //Всего строк
    rowsInFile: number;
    //Строк прочитано
    rowsRead: number;
    //Текст сообщения об ошибке
    errorMessage: string;
    //Текст
    successMessage: string;
    forSufficiency: boolean;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.errorMessage = object.errorMessage;
        this.successMessage = object.successMessage;
        this.rowsInFile = object.rowsInFile;
        this.rowsRead = object.rowsRead;
        this.newDialogs = object.newDialogs;
        this.dialogsInFile = object.dialogsInFile;
    }

}

export class ClusterInfoForm {
    isLoading: boolean = false;
    // замена подстроки в репликах с:
    replaceFrom: string = "";
    // замена подстроки в репликах на:
    replaceTo: string = "";
    // true если диалоги из экселя нужно сохранить в базу, false - только распарсить и вывести инфу
    saveDialogs: boolean = null;
    // загружать дамп со строки номер:
    fromRow: number = null;
    // загружать дамп по строку номер:
    toRow: number = null;
    errors: any = new Map<string, string>();
    uploadInfo: DialogDumpLoadResult = null;

    constructor(object?: any) {
        if (!object){
            return;
        }
        this.isLoading = object.isLoading
        this.replaceFrom = object.replaceFrom
        this.replaceTo = object.replaceTo
        this.saveDialogs = object.saveDialogs
        this.fromRow = object.fromRow
        this.toRow = object.toRow
        this.errors = object.errors
        this.uploadInfo = object.uploadInfo
    }
}

export class ClusterEdit {
    clusterEditor: any;
    state: any;
    messagesList: VaTBMessageCluster[];
}

export class Node {
    showUnimportantChildren: boolean;
    important: boolean;
    keyphrase: KeyPhrase;
    children: any[];
    display: boolean;
}

export class KeyPhrase {
    support: any;
    text: string;
}

export class TreeOptions {
    tagMode: string;
    access: boolean;
    droppable: boolean;
    showTrain: boolean;
    hide: boolean;
    highlightedNode: boolean;
    createNewTag: any;
    rememberTag: any;
    focusTag: any;
    dropFn: any;
    dropOptions: any;

    constructor(dropFn: any, rememberTag: any, focusTag: any) {
        this.tagMode = 'ALL';
        this.access = true;
        this.droppable = true;
        this.showTrain = false;
        this.hide = false;
        this.highlightedNode = true;
        this.dropOptions = {classes: {'ui-droppable-hover': 'highlight-drop'}};
        this.rememberTag = rememberTag;
        this.focusTag = focusTag;
        this.dropFn = dropFn;
    }
}
