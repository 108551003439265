import {Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {StateService} from "@uirouter/core";
import {DialogService} from "../dialog/dialog.service";
import {CheckState, CheckStateEnum, Dialog} from "../dialog/model/dialog.model";
import AccountService from "../../../services/AccountService";
import VaAnalysisGridComponent from "../../../components/analysis/VaAnalysisGridComponent";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'dialog-stat',
    template: require('./dialog-stat.component.html'),
    styles: [require('./dialog-stat.component.less')]
})

export class DialogStatComponent implements OnInit, OnDestroy {


    private dialogId: string;
    foundDialogsIds: number[] = [];
    dialog: Dialog;
    access: boolean;
    relevanceDialog: Dialog;
    relevanceLoading: boolean;
    isLoading: boolean = true;
    dialogMode: "ALL" | "CHAT" = "ALL";
    relevanceMode: "SOURCE_DIALOG" | "RELEVANCE" = "SOURCE_DIALOG";
    start: Date;
    checkedDialog: boolean = null;
    private sent: boolean = false;


    /**
     * Ошибка при проверке диалога
     */
    relevanceError: string;

    /**
     * Версия, по которой сделана проверка
     */
    relevanceVersionId: string;

    constructor(private titleService: Title,
                private stateService: StateService,
                private dialogService: DialogService,
                private accountService: AccountService) {
        const params = this.stateService.params;
        this.dialogId = params['dialogId'];
        titleService.setTitle(this.dialogId ? "Диалог | " + this.dialogId : "Диалог");
        this.relevanceVersionId = stateService.params['projectVersionId'];
        this.foundDialogsIds = VaAnalysisGridComponent.getVisibleIds();
    }

    async ngOnInit() {
        await this.loadDialog();
        this.checkRelevance(1);
        this.dialogService.getAccess().then(access => this.access = access.access);
        this.start = new Date();
        this.accountService.addLogoutListener(() => {
            return this.onDestroy();
        });
    }


    /**
     * Обработчик кнопки "Вернуться к статистике"
     */
    goToStat(): void {
        let filterForm = localStorage.getItem("eruditeStatFilterForm");

        if (filterForm && Object.keys(filterForm).length > 0) {
            // переходим в стату по имеющемуся фильтру
            this.stateService.go('robot.analysis.data', {
                filter: filterForm,
                tagIdForSubtreeFiltering: null,
                singleTagIdForFiltering: null
            }, {notify: true});
        } else {
            // переходим на форму статы, если фильтр пустой
            this.stateService.go('robot.analysis', {
                tagIdForSubtreeFiltering: null,
                singleTagIdForFiltering: null
            }, {notify: true});
        }
    }

    /**
     * Запустить проверку диалога
     *
     * @param projectVersionId версия, в которой проверяем
     */
    startCheckRelevance(projectVersionId?: string): void {
        // версию, в которой проверяем диалог, сохраняем для получения результата
        this.relevanceVersionId = projectVersionId;
        this.relevanceLoading = true;
        this.relevanceError = null;
        this.dialogService.startCheckRelevance(this.dialogId, projectVersionId)
            .then(checkResult => {
                this.checkRelevance(1);
            }, statusObject => this.showRelevanceErrorIfPresent(statusObject));
    }

    /**
     * Показать сообщение об ошибке при проверки диалога, если есть
     */
    showRelevanceErrorIfPresent(statusObject) {
        if (statusObject.errors?.length) {
            // показываем ошибку с бэкенда 5 секунд
            this.relevanceError = statusObject.errors[0].message;
            setTimeout(() => this.relevanceError = null, 5000);
        }
        return this.relevanceLoading = false;
    }

    /**
     * Загрузить результат проверки диалога
     */
    checkRelevance(iteration?: number): void {
        this.relevanceLoading = true;
        this.dialogService.checkRelevance(this.dialogId, this.relevanceVersionId)
            .then(checkResult => {
                if (checkResult.status !== 'OK' && !checkResult.finished) {

                    setTimeout(() => {
                        this.checkRelevance(iteration + 1);
                    }, 3000);
                } else {
                    this.onLoadRelevanceResult(checkResult);
                }
            }, checkResult => this.onLoadRelevanceResult(checkResult));
    }

    /**
     * Загружен результат проверки диалога
     */
    onLoadRelevanceResult(checkResult) {
        if (checkResult.dialog) {
            // подменяем реплики в старом диалоге новыми
            checkResult.dialog.replies = checkResult.newDialogReplies;
            this.relevanceDialog = checkResult.dialog;
            this.relevanceMode = 'RELEVANCE';
        } else {
            // или сообщение об ошибке
            this.showRelevanceErrorIfPresent(checkResult);
        }
        this.relevanceLoading = false;
    }

    /**
     * Загрузка диалога
     */
    async loadDialog(): Promise<void> {
        try {
            this.dialog = await this.dialogService.getDialog(this.dialogId);
        } catch (e) {
            // если произошла ошибка при загрузке проекта, то её запишем в консоль
            console.error('error getting dialog ' + this.dialogId);
            console.error(e);
            // и перейдем на страницу статы
            this.stateService.go("robot.analysis", {});
        } finally {
            this.isLoading = false;
        }
    }


    async ngOnDestroy(): Promise<void> {
        return this.sendDialogSessionBeforeClose();
    }

    @HostListener('window:beforeunload')
    private async sendDialogSessionBeforeClose() {
        await this.onDestroy();
    }

    private onDestroy() {
        if (!this.sent) {
            let timeFinish = new Date().getTime();
            let timeStart = this.start.getTime();
            let duration = Math.trunc((timeFinish - timeStart) / 1000);
            this.sent = true;
            return this.dialogService.sendDialogSession(duration, this.dialogId, this.dialog.superExpertRating, this.checkedDialog);
        }
        return Promise.resolve();
    }

    /**
     * Проверяем, поменял ли оператор корректность обработки диалога
     * @param state - состояние диалога
     */
    checkStateChange(state: CheckState) {
        this.checkedDialog = state.name != CheckStateEnum.UNCHECKED;
    }

}

