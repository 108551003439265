import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

export interface ConfirmDialogData {
    title: string;
    confirmActionText?: string;
    denyActionText?: string;
}

@Component({
    selector: 'confirm-dialog',
    template: require('./confirm-dialog.component.html')
})
export class ConfirmDialogComponent {

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    }

    onDeny(): void {
        this.dialogRef.close();
    }
}
