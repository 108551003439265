import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {AccountRole, AdminType, ChatAccountData, ExpertType} from "../../../../data/va/Account";
import {BaseDataService} from "../base/base-data.service";
import {Project} from "../../project/project.model";
import {NamedEntitiesModel} from "../model/named-entities.model";

@Injectable()
export class AccountService extends BaseDataService<ChatAccountData, number> {

    baseUrl: string = "/account/account";

    readonly entityClass = NamedEntitiesModel.CHAT_ACCOUNT_DATA;

    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    /**
     * Список всех ролей.
     */
    async getRoles(): Promise<AccountRole[]> {
        return await this.httpClient.get<AccountRole[]>(`${this.baseUrl}/roles`).toPromise();
    }

    /**
     * Данные по типам эксперта и разрешению на редактирование скриптов
     */
    async getExpertData(): Promise<{ expertTypes: ExpertType[], scriptEditDisabled: boolean }> {
        return await this.httpClient.get<{ expertTypes: ExpertType[], scriptEditDisabled: boolean }>(`${this.baseUrl}/expertData`).toPromise();
    }

    /**
     * Список типов администраторов.
     */
    async getAdminTypes(): Promise<AdminType[]> {
        return await this.httpClient.get<AdminType[]>(`${this.baseUrl}/adminTypes`).toPromise();
    }

    /**
     * Список названий проектов.
     */
    async getRobotProjects(): Promise<string[]> {
        const projects = await this.httpClient.get<Project[]>(`/account/expert/project/all`).toPromise();
        return projects.map(project => project.name);
    }

    /**
     * Список всех пользователей.
     */
    async getFullAccounts(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/full`).toPromise();
    }

}
