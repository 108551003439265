import {MacroObject} from "./MacroObject";
import {VaTag} from "../../../../../data/va/Tag";
import {throwError} from "rxjs";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {CustomDateDelimiter} from "../../erudite-ngb-datepicker/erudite-ngb-datepicker-customizer";

export enum MarkupTypeEnum {
    BREAK = "BREAK",
    NUMBER = "NUMBER",
    DATE = "DATE",
    MONEY = "MONEY",
    BARGEIN_ALLOW = "BARGEIN_ALLOW"
}

export class MarkupType implements MacroObject {
    name: MarkupTypeEnum;
    tag: string;
    valueAttributeName: string;


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.name = object.name;
        this.tag = object.tag;
        this.valueAttributeName = object.valueAttributeName;
    }

    getTaggedInfo(): VaTag[] {
        return [];
    }

    getTitle(): string {
        switch (this.name) {
            case MarkupTypeEnum.NUMBER:
                return "Число";
            case MarkupTypeEnum.DATE:
                return "Дата";
            case MarkupTypeEnum.MONEY:
                return "Сумма";
            case MarkupTypeEnum.BARGEIN_ALLOW:
                return "Разрешить прерывание";
            case MarkupTypeEnum.BREAK:
                return "Пауза";
            default:
                throwError("Unsupported markup type");
        }
    }

    getSettingNames(): string[] {
        switch (this.name) {
            case MarkupTypeEnum.NUMBER:
                return ["value", "attribute", "unit", "inflectionCase", "playingMethod", "numeralType"];
            case MarkupTypeEnum.DATE:
                return ["value", "attribute", "inflectionCase", "playingMethod", "playingPart"];
            case MarkupTypeEnum.MONEY:
                return ["value", "attribute", "currency", "playingMethod"];
            case MarkupTypeEnum.BREAK:
                return ["value"];
            default:
                return [];
        }
    }

    getId(): string {
        return "";
    }
}

export class MarkupSetting {
    /**
     * Значение
     */
    value: any;
    /**
     * Атрибут, из которого надо брать значение
     */
    attribute: MacroObject;
    /**
     * Опциональное дополнительное слово (например, месяц)
     */
    unit: string;
    /**
     * Падеж, в котором будет проиграна фраза
     */
    inflectionCase: string = InflectionCase.NOMINATIVE.type;
    /**
     * Способ проигрывания
     */
    playingMethod: string = PlayingMethod.TTS.type;
    /**
     * Тип числа
     */
    numeralType: string = NumeralType.CARDINAL.type;
    /**
     * Произносимая валюта
     */
    currency: string = Currency.RUB.type;
    /**
     * Часть даты, которую нужно проиграть
     */
    playingPart: string = PlayingPart.DAY_MONTH.type;

    errors: Map<string, string>;

    constructor() {
    }

    toSnakeCase(text: string): string {
        return text.replace(/([A-Z])/g, "_$1").toLowerCase();
    }

    getConditions(markupType: MarkupType, settingNames: string[]): {} {
        const conditions = {};
        settingNames.forEach(setting => {
            if (setting == "attribute" && this.attribute) {
                conditions["attribute"] = this.attribute.getId();
            } else if (setting == "value" && !this.attribute) {
                switch (markupType.name) {
                    case MarkupTypeEnum.BREAK:
                        conditions[markupType.valueAttributeName] = this.value + "s";
                        break;
                    case MarkupTypeEnum.DATE:
                        conditions[markupType.valueAttributeName] = new CustomDateDelimiter("-").format(this.value as NgbDate);
                        conditions["formatter"] = "%d-%m-%Y";
                        break;
                    default:
                        conditions[markupType.valueAttributeName] = this.value;
                        break;
                }
            } else if (this[setting]) {
                conditions[this.toSnakeCase(setting)] = this[setting];
            }
        });
        return conditions;
    }
}

export class InflectionCase {
    type: string;
    title: string;

    constructor(type: string, title: string) {
        this.type = type;
        this.title = title;
    }

    static readonly NOMINATIVE = new InflectionCase('nominative', 'Именительный');
    static readonly GENITIVE = new InflectionCase('genitive', 'Родительный');

    static readonly VALUES = [InflectionCase.GENITIVE, InflectionCase.NOMINATIVE];
}

export class PlayingMethod {
    type: string;
    title: string;

    constructor(type: string, title: string) {
        this.type = type;
        this.title = title;
    }

    static readonly TTS = new PlayingMethod('tts', 'TTS');
    static readonly SOUNDS = new PlayingMethod('sounds', 'Предзапись');

    static readonly VALUES = [PlayingMethod.TTS, PlayingMethod.SOUNDS];
}

export class NumeralType {
    type: string;
    title: string;

    constructor(type: string, title: string) {
        this.type = type;
        this.title = title;
    }

    static readonly CARDINAL = new NumeralType('cardinal', 'Количественное (один, двадцать)');
    static readonly ORDINAL = new NumeralType('ordinal', 'Порядковое - (первый, двадцатый)');

    static readonly VALUES = [NumeralType.CARDINAL, NumeralType.ORDINAL];
}

export class Currency {
    type: string;

    constructor(type: string) {
        this.type = type;
    }

    static readonly RUB = new Currency('RUB');
    static readonly USD = new Currency('USD');
    static readonly EUR = new Currency('EUR');

    static readonly VALUES = [Currency.RUB, Currency.USD, Currency.EUR];
}

export class PlayingPart {
    type: string;
    title: string;


    constructor(type: string, title: string) {
        this.type = type;
        this.title = title;
    }

    static readonly ALL = new PlayingPart('all', 'Вся дата');
    static readonly DAY_MONTH = new PlayingPart('day_month', 'День и месяц');
    static readonly MONTH_YEAR = new PlayingPart('month_year', 'Месяц и год');
    static readonly MONTH = new PlayingPart('month', 'Месяц');


    static readonly VALUES = [PlayingPart.ALL, PlayingPart.DAY_MONTH, PlayingPart.MONTH_YEAR, PlayingPart.MONTH];
}
