function GalleryDirective() {
    return {
        restrict: "A",
        link: function (scope, elem) {
            elem.magnificPopup({
                delegate: 'a',
                type: 'image',
                tLoading: 'Loading image #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0, 1]
                },
                image: {
                    tError: "Can't load image"
                }
            });
        }
    }
}

export default GalleryDirective;