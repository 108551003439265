import {Component} from "@angular/core";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {TextDataService} from "../text-data.service";
import {BaseMasterComponent} from "../../base/base-master.component";
import {TextData} from "../text-data.model";
import {CompositeKey} from "../../../../../data/va/Common";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";


@Component({
    selector: 'text-data-master',
    template: require('./text-data-master.component.html'),
    styles: [require('./text-data-master.component.less')]
})
export class TextDataMasterComponent extends BaseMasterComponent<TextData, CompositeKey<number>> {
    title: string;

    constructor(titleService: Title,
                stateService: StateService,
                dataService: TextDataService,
                notificationService: NotificationService,
                httpClient: HttpClient) {

        super(titleService, stateService, dataService, httpClient, notificationService);
        this.getAccess();
    }

    async loadList(): Promise<void> {
        this.setLoading(true);
        this.objects = await this.httpClient.get<TextData[]>(`${this.dataService.baseUrl}?group=${this.stateService.params["groupName"]}`).toPromise();
        this.setLoading(false);
        // название группы в заголовок
        this.title = this.objects[0].type.group.title;
        this.titleService.setTitle(this.title);
    }
}
