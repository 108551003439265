/**
 * Created by semyon on 29.06.17.
 */

/**
 * директива чтения и записи в контентетитейбл
 *
 */
function ContenteditableModelDirective($sce) {
    return {
        restrict: 'A', // only activate on element attribute
        require: '?ngModel', // get a hold of NgModelController
        link: function (scope, element, attrs, ngModel) {

            if (!ngModel) return; // do nothing if no ng-model

            // Specify how UI should be updated
            ngModel.$render = function () {
                element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
            };

            // Listen for change events to enable binding
            element.on('keyup load click blur', function (event) {
                // Удаляем сгенерированные после удаления стили
                $(element).find('>span').removeAttr('style');
                scope.$evalAsync(read(event));
            });
            read(); // initialize

            function read(event) {


                var text = element.html();
                // When we clear the content editable the browser leaves a <br> behind
                // If strip-br attribute is provided then we strip this out
                if (attrs.stripBr && text == '<br>') {
                    text = '';
                }
                ngModel.$setViewValue(text);
            }
        }
    };
}
export default ContenteditableModelDirective;
ContenteditableModelDirective.$inject = ["$sce"];
