import {NgModule} from "@angular/core";
import {EntityUsageComponent} from "./entity-usage.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {EntityUsageService} from "./entity-usage.service";

@NgModule({
    declarations: [
        EntityUsageComponent
    ],
    imports: [
        MatExpansionModule,
        CommonModule,
        UIRouterModule
    ],
    exports: [
        EntityUsageComponent
    ],
    entryComponents: [
        EntityUsageComponent
    ],
    providers: [
        EntityUsageService
    ]
})
export class EntityUsageModule {

    

}