import {Component} from "@angular/core";
import {VASimpleModuleAnswer} from "../../../../../data/va/SimpleModuleAnswer";
import {CompositeKey} from "../../../../../data/va/Common";
import {StateService} from "@uirouter/core";
import {SmaMasterComponent} from "../master/sma-master.component";
import {HttpClient} from "@angular/common/http";
import {BaseDetailsComponent, DetailsMode} from "../../base/base-details.component";
import {Title} from "@angular/platform-browser";
import {SmaService} from "../sma.service";
import {AttributeService} from "../../attribute/attribute.service";
import {ValueOption} from "../../../../../data/va/Extractor";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";


@Component({
    selector: 'sma-edit',
    template: require('./sma-edit.component.html'),
    styles: [require('./sma-edit.component.less')]
})
export class SmaEditComponent extends BaseDetailsComponent<VASimpleModuleAnswer, CompositeKey<number>> {

    objectIdKey = 'smaId';

    channels: ValueOption[];

    constructor(stateService: StateService, master: SmaMasterComponent, httpClient: HttpClient,
                dataService: SmaService, titleService: Title, notificationService: NotificationService, private attributeService: AttributeService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();

        if (this.objId) {
            await this.loadAccount();
        }

        await this.loadChannels();
    }

    async loadChannels() {
        this.channels = await this.attributeService.getChannelsWithAssistant();
    }

    async save() {
        if (!this.form.object.formulations || this.form.object.formulations.length === 0) {
            this.form.errors.clear();
            this.form.success = false;
            this.form.errors["#main"] = "Необходимо задать формулировку";
            return;
        }
        super.save();
    }

    generateFormObject(): VASimpleModuleAnswer {
        return new VASimpleModuleAnswer();
    }

    get entityTitle(): string {
        return this.form.object.vaAct.title;
    }


    get formTitle(): string {
        if (this.form.object) {
            return this.form.object.vaAct.title;
        }
        switch (this.mode) {
            case DetailsMode.SHOW:
            case DetailsMode.EDIT:
                return "Редактирование";
            case DetailsMode.NEW:
                return this.metaTexts.newObjectTitle;
        }
    }


    showRemove(): boolean {
        return false
    }

    get removeConfirmMessage(): string {
        return `Вы уверены, что хотите удалить стандартный ответ?`;
    }

    get allowJuniorAccess() {
        return true;
    }
}