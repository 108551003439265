export function getPhaseIcon(status) {
    switch (status) {
        case "NOT_STARTED":
            return "fa-gears";
        case "IN_PROGRESS":
            return "fa-circle-o-notch fa-spin";
        case "SUCCEEDED":
            return "fa-thumbs-up";
        case "FAILED":
            return "fa-thumbs-down";
    }
}

export function getModelIcon(status) {
    switch (status) {
        case "IN_PROGRESS":
            return "fa-gears";
        case "PHASE_BUILDING":
            return "fa-circle-o-notch fa-spin";
        case "ACCEPTED":
            return "fa-users";
        case "FINISH":
            return "fa-thumbs-up";
        case "FAILED":
            return "fa-thumbs-down";
        case "EMPTY":
            return "fa-file-o";
    }
}

export function getModelColor(status) {
    switch (status) {
        case "IN_PROGRESS":
            return "bg-purple";
        case "PHASE_BUILDING":
            return "bg-yellow";
        case "ACCEPTED":
            return "bg-aqua";
        case "FINISH":
            return "bg-green";
        case "FAILED":
            return "bg-maroon";
        case "EMPTY":
            return "bg-navy";
    }
}

export function getPhaseColor(status) {
    switch (status) {
        case "NOT_STARTED":
            return "bg-purple";
        case "IN_PROGRESS":
            return "bg-yellow";
        case "SUCCEEDED":
            return "bg-green";
        case "FAILED":
            return "bg-maroon";
    }
}