import {Component, OnDestroy, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import WebSocketService from "../../../../services/WebSocketService";
import * as urls from "../../../../../../js/workplace/urls";
import {VersioningProgressBarData} from "./versioning-progress-bar.model";


@Component({
    selector: 'versioning-progress-bar',
    template: require('./versioning-progress-bar.component.html')
})
export class VersioningProgressBarComponent implements OnInit, OnDestroy {

    private subscriptionId: string;
    versionsBuildProgress: Map<string, VersioningProgressBarData> = new Map<string, VersioningProgressBarData>();

    constructor(protected stateService: StateService,
                protected httpClient: HttpClient,
                private webSocketService: WebSocketService) {
    }

    ngOnInit(): void {
        this.loadProgressStatus();
        // Подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_VERSIONING_PROGRESS_STEP,VA_VERSIONING_PROGRESS_FINISH",
            fn: (event) => {
                let progressData: VersioningProgressBarData = JSON.parse(event.details);
                switch (event.type) {
                    case "VA_VERSIONING_PROGRESS_FINISH":
                        this.loadProgressStatus();
                        break;
                    case "VA_VERSIONING_PROGRESS_STEP":
                        this.refreshProgressInfo(progressData);
                        break;
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    private loadProgressStatus(): void {
        this.httpClient.get(`${urls.va.projectVersion}/status`).toPromise()
            .then((data: VersioningProgressBarData[]) => {
                this.versionsBuildProgress = new Map<string, VersioningProgressBarData>();
                data.forEach(value => {
                    this.versionsBuildProgress.set(value.projectVersionId, value);
                });
            });
    }

    private refreshProgressInfo(progressData: VersioningProgressBarData): void {
        this.versionsBuildProgress.set(progressData.projectVersionId, progressData);
    }

    removeFinishedProgressData(): void {
        this.httpClient.delete(`${urls.va.projectVersion}/status/removeFinished`)
            .toPromise()
            .then((data: any) => {
                this.loadProgressStatus();
            });
    }

    private getTitle(progressData: VersioningProgressBarData): string {
        let title = "";
        switch (progressData.type) {
            case "COPY":
                title = "Создание версии ";
                break;
            case "IMPORT":
                title = "Импорт версии ";
                break;
            case "EXPORT":
                title = "Экспорт версии ";
                break;
            default:
                title = "";
                break;
        }
        return title + progressData.projectVersionId;
    }

    private isInProgress(progressData: VersioningProgressBarData): boolean {
        return progressData.overallCount != 0 && progressData.entityName.length > 0;
    }

}
