import restangular from "restangular";
import * as urls from "../../../../js/workplace/urls.js";
import {DialogFilterParam, SelectionModalMode, VaStatSelection} from "./Analysis";

/**
 * Контроллер модального окна с созданием/удалением выборок
 */
export default class VaStatSelectionModalController {

    static $inject = ["$uibModalInstance", "$state", "Restangular", "mode", "filter", "selections"];
    private state: ng.ui.IStateService;
    private restAngular: restangular.IService;
    private $uibModalInstance: any;
    private form: any;

    /**
     * Режим модального окна: CREATE - создание, EDIT - редактиврование
     */
    private mode: SelectionModalMode;

    /**
     * Фильтр, по которому была сформирована выборка
     */
    private filter: DialogFilterParam;

    /**
     * Название выборки
     */
    private title: string;

    /**
     * Список созданных выборок
     */
    private selections: VaStatSelection[];

    constructor($uibModalInstance, $state: ng.ui.IStateService, Restangular: restangular.IService, mode: SelectionModalMode, filter: DialogFilterParam, selections: VaStatSelection[]) {
        this.$uibModalInstance = $uibModalInstance;
        this.state = $state;
        this.restAngular = Restangular;
        this.mode = mode;
        this.filter = filter;
        this.selections = selections;
        // Форму используем только для отображения ошибок, на деле на бэк объекты не шлём
        this.form = {
            isLoading: false,
            errors: {}
        }
    }

    /**
     * Сохранить выборку
     */
    save() {
        this.form.isLoading = true;
        this.restAngular.one(urls.va.analysis)
            .post(`createSelection?selectionTitle=${this.title != null ? this.title : ''}`, this.filter)
            .then((data) => {
                // Если всё хорошо, то уберём крутилку и закроем модальное окно
                this.form.isLoading = false;
                this.closeModal();
                let selection: VaStatSelection = this.restAngular.stripRestangular(data);
                this.selections.push(selection);
                //
            }, (data) => {
                // Если произошла ошибка, то отобразим её подписью к полю
                this.form.errors = {};
                this.form.isLoading = false;
                this.form.success = false;
                this.form.errors["title"] = data.errors.map(error => error.message).join('\n');
            });
    }

    /**
     * Удалить выборку
     * @param selectionId
     * @param $index
     */
    delete(selectionId: number, $index: number) {
        this.restAngular.one(urls.va.analysis, 'deleteSelection')
            .get({selectionId: selectionId})
            .then((data) => {
                this.selections.splice($index, 1);
            }, (data) => {

            });
    }

    /**
     * Получить заголовок модального окна
     */
    get modalTitle() {
        switch (this.mode) {
            case SelectionModalMode.CREATE:
                return 'Новая выборка';
            case SelectionModalMode.EDIT:
                return 'Выборки';
            default:
                throw new Error(`Unsupported SelectionModalMode: ${this.mode}`);
        }
    }

    cancel() {
        this.closeModal();
    }

    closeModal() {
        this.$uibModalInstance.close();
    }
}