import {Component, Input, OnInit} from "@angular/core";
import {StateService} from "@uirouter/core";
import {Dialog} from "../model/dialog.model";
import {AudioRecordService} from "../../media-libraries/audio-record/audio-record.service";
import * as moment from 'moment';
import {SnackbarComponent} from "../../common/snackbar/snackbar.component";
import {MatSnackBar} from "@angular/material";
import {copyData} from "../../chat/control-panel/chat-control-panel.component";
import {NotificationService} from "../../common/snackbar/notification/notification.service";

@Component({
    selector: 'dialog-info-panel',
    template: require('./dialog-info-panel.component.html'),
    styles: [require('./dialog-info-panel.component.less')]
})
export class DialogInfoPanelComponent implements OnInit {

    /**
     * Внешний идентификатор сессии
     */
    @Input()
    dialog: Dialog;

    /**
     * Запись звонка
     */
    audioUrl: string | ArrayBuffer;

    /**
     * Флаг проигрывания записи
     */
    play: boolean;

    /**
     * Длительность аудиозаписи
     */
    duration: string;

    constructor(private audioRecordService: AudioRecordService,
                private stateService: StateService,
                private snackBar: MatSnackBar,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.audioRecordService.loadCallRecordFile(this.dialog.conversationId, this.initDialogRecordData.bind(this), () => {});
    }

    /**
     * Есть ли в объекте диалога данные о моделях, на которых он был проведён
     */
    hasModelInfo(): boolean {
        return this.dialog.modelDataAvailable && this.dialog?.modelVersions?.length != 0
    }

    /**
     * Возврат к статистике из интерфейса просмотра диалога
     */
    toStatistics() {
        let filterForm = localStorage.getItem("eruditeStatFilterForm");
        if (filterForm && Object.keys(filterForm).length > 0) {
            // переходим в стату по имеющемуся фильтру
            this.stateService.go('robot.analysis.data', {
                filter: filterForm,
                tagIdForSubtreeFiltering: null,
                singleTagIdForFiltering: null
            }, {reload: true})
        } else {
            // переходим на форму статы, если фильтр пустой
            this.stateService.go('robot.analysis', {
                tagIdForSubtreeFiltering: null,
                singleTagIdForFiltering: null
            }, {reload: true});
        }
    }

    /**
     * Сменить диалог
     * @param dialog на какой диалог меняем
     * @param num    индекс диалога
     */
    changeDialog(dialog, num) {
        this.stateService.go("robot.analysis_dialog", {dialogId: dialog.key.id, position: num});
    }

    /**
     * Проиграть запись диалога
     */
    playDialogRecord() {
        this.play = true;
    }

    /**
     * Инициализировать данные для проигрывания записи диалога
     * @param dataUrl аудиозапись
     */
    initDialogRecordData(dataUrl: string) {
        if (dataUrl) {
            let audio = new Audio(dataUrl);
            audio.onloadedmetadata = () => this.duration = moment(Math.round(audio.duration * 1000)).format("mm:ss");
            this.audioUrl = dataUrl;
        }
    }

    openModel() {

        // переходим на форму статы, если фильтр пустой
        this.stateService.go('robot.analysis', {
            tagIdForSubtreeFiltering: null,
            singleTagIdForFiltering: null
        }, {reload: true})
    }

    /**
     * Скачать архив с аудио по диалогу
     */
    downloadAsrArchive($event: MouseEvent) {
        if (!$event.ctrlKey && !$event.metaKey) {
            return;
        }
        if (!this.dialog.conversationId) {
            return;
        }
        this.audioRecordService.downloadAsrArchive(this.dialog.conversationId, error => this.displayError(error));
    }

    /**
     * Отобразить снэк-бар с описанием ошибки
     */
    private displayError(error: any) {
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 10000,
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
            panelClass: 'chat-error-snack-bar',
            data: {text: error}
        });
    }

    sentimentNameToClass = {
        "VERY_NEGATIVE": "sentiment-very-negative",
        "NEGATIVE": "sentiment-negative",
        "NEUTRAL": "sentiment-neutral",
        "POSITIVE": "sentiment-positive",
        "VERY_POSITIVE": "sentiment-very-positive",
    }

    getSentimentTextClass(): any {
        return this.sentimentNameToClass[this.dialog.sentiment ? this.dialog.sentiment.name : 'NEUTRAL'] + "-text";
    }

    getSentimentCircleClass(): any {
        return this.sentimentNameToClass[this.dialog.sentiment ? this.dialog.sentiment.name : 'NEUTRAL'] + "-circle";
    }


    /**
     * Скопировать данные в буфер обмена по ctrl+клик
     */
    copyData(description: string, data: any, $event: MouseEvent) {
        copyData(this.notificationService, description, data, $event);
    }}
