/**
 * Описание: {@link ru.naumen.chat.va.data.entity.projectversion.VAProjectVersion}
 */
import {Project} from "../../io/components/project/project.model";

export class VAProjectVersion {
    id: string;
    projectId: string;
    versionNumber: string;
    active: boolean;
    archive: boolean;
    expertId: number;
    d: Date;
    deleted: boolean;
    copyFromPVId: string;
    state: State;
    channels: {};
    copyChannels: {};
    project: Project;


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.id = object.id;
        this.projectId = object.projectId;
        this.versionNumber = object.versionNumber;
        this.active = object.active;
        this.archive = object.archive;
        this.expertId = object.expertId;
        this.d = object.d;
        this.deleted = object.deleted;
        this.copyFromPVId = object.copyFromPVId;
        this.state = object.state;
        this.channels = object.channels;
        if (object.channels) {
            this.copyChannels = Object.assign({}, object.channels);
        }
        this.project = object.project ? new Project(object.project) : null;
    }
}

export class State {
    name: StateEnum;
    fullName: string;
}

export enum StateEnum {
    TRAINING = "TRAINING",
    FOR_CORRECTION = "FOR_CORRECTION",
    FIXED = "FIXED"
}