/**
 * Контроллер модального окошка с подверждением удаления
 */
export default class ButtonWithConfirmModalController {

    static $inject = ["$uibModalInstance", "confirmCallback", "object", "questionText"];

    /**
     * Инстанс модального окна
     */
    private $uibModalInstance: any;

    private confirmCallback: any;

    private object: any;

    private questionText: string;

    constructor($uibModalInstance: any, confirmCallback: any, object: any, questionText: string) {
        this.$uibModalInstance = $uibModalInstance;
        this.confirmCallback = confirmCallback;
        this.object = object;
        this.questionText = questionText;
    }

    /**
     * Закрыть модальное окно без сохранения изменений
     */
    cancel() {
        this.$uibModalInstance.close();
    }

    /**
     * Сохранение изменений
     */
    save() {
        this.confirmCallback({item: this.object});
        // И закрываем окно
        this.$uibModalInstance.close();
    }
}
