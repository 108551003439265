import {Component, ViewChild, ViewEncapsulation} from "@angular/core";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {BaseDetailsComponent, DetailsMode} from "../../va/base/base-details.component";
import {Channel, Project, ProjectLanguage, ProjectType, ProjectTypeEnum} from "../project.model";
import {ProjectService} from "../project.service";
import {ProjectMasterComponent} from "../master/project-master.component";
import {Valued} from "../../../../data/va/Valued";
import {FileItem} from "../../common/file-uploader/uploader-vendor/file-item.class";
import WebSocketService from "../../../../services/WebSocketService";
import {ErrorResponseData} from "../../../../../../js/workplace/controllers/va/VABaseFormController";
import {ExtractorService} from "../../va/extractor/extractor.service";
import {Extractor, ValueOption} from "../../../../data/va/Extractor";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {AccountService as AccountsService} from "../../va/account/account.service";
import {Account, ChatAccountData} from "../../../../data/va/Account";
import AccountService from "../../../../services/AccountService";


@Component({
    selector: 'project-edit',
    template: require('./project-edit.component.html'),
    styles: [require('./project-edit.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class ProjectEditComponent extends BaseDetailsComponent<Project, number> {

    @ViewChild('userSelect', null) userSelect: any;

    objectIdKey = 'projectId';
    isImport: boolean;
    isText: boolean;
    isVoice: boolean;
    isAssistant: boolean = false;
    private defaultChannel: ValueOption;
    private importFile: File;
    private subscriptionId: string;
    types: ProjectType[] = [];
    languages: ProjectLanguage[];
    englishProjects: Project[];
    accounts: Account[];
    closeUsers: boolean = false;
    displayBargeIn: boolean = false;

    constructor(protected stateService: StateService,
                protected master: ProjectMasterComponent,
                protected httpClient: HttpClient,
                protected projectService: ProjectService,
                protected accountsService: AccountsService,
                protected accountService: AccountService,
                protected titleService: Title,
                protected notificationService: NotificationService,
                private webSocketService: WebSocketService,
                private extractorService: ExtractorService) {
        super(stateService, master, httpClient, projectService, titleService, notificationService);
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        this.isImport = this.stateService.params['mode'] === 'IMPORT';
        await this.loadProjectData();
        if (this.isModeNew() || this.isImport) {
            this.form.object.type = this.form.object.type = this.types.find(type => type.name === ProjectTypeEnum.INCOMING);
        }
        await this.loadProjectData();
        // Подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_VERSIONING_PROGRESS_FINISH",
            fn: (event) => {
                switch (event.type) {
                    case "VA_VERSIONING_PROGRESS_FINISH":
                        if (this.isModeNew() && this.importFile) {
                            let projectName = this.form.object.name;
                            if (!projectName) {
                                // название проекта бралось из фала импорта - надо вытащить его из ид версии проекта
                                projectName = JSON.parse(event.details).projectName;
                            }
                            // если закончился импорт, то надо обновить текущий проект
                            this.httpClient.get(`${this.projectService.baseUrl}/getByName/${projectName}`).toPromise()
                                .then((project: Project) => {
                                    this.form.object = new Project(project);
                                    this.form.success = true;
                                    this.setModeEdit();
                                    this.stateService.params['mode'] = 'EDIT';
                                    // обновляем id объекта
                                    this.objId = project.id + "";
                                    this.master.selectedId = this.objId;
                                    super.switchState(false);
                                }, () => {
                                    this.setLoading(false);
                                });
                        }
                        break;
                }
            }
        });

        this.loadAccounts();
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    async loadProjectData() {
        const projectData = await this.projectService.getProjectData();
        this.types = projectData.types;
        this.languages = projectData.languages;
        this.englishProjects = projectData.englishProjects.filter(project => project.id != this.form.object?.id);
    }

    async loadDefaultChannel() {
        this.extractorService.getProjectChannel(this.form.object.name).then((channel: Extractor) => {
            this.defaultChannel = channel.valueOptions.find(option => option.isDefault);
        });
    }

    onObjectLoaded() {
        this.form.object = new Project(this.form.object);
        this.form.object.copyChannels = Object.assign({}, this.form.object.channels);
        this.isText = this.findChannelByApiKey(Valued.WEB_API_KEY) != null;
        this.isVoice = this.findChannelByApiKey(Valued.VOICE_API_KEY) != null;
        this.isAssistant = this.findChannelByApiKey(Valued.ASSISTANT_API_KEY) != null;
        this.loadDefaultChannel();
    }


    findChannelByApiKey(apiKey: string): Channel {
        return this.form.object.channelList.find(channel => channel.key === apiKey);
    }

    generateFormObject(): Project {
        const project = new Project();
        project.text = true;
        project.channelList = [];
        project.channelList.push(Channel.webChannel());
        project.language = ProjectLanguage.RUSSIAN;
        this.isText = true;
        this.isVoice = false;
        return project;
    }

    onVoiceToggle(): void {
        if (this.isVoice) {
            // если голос включили, то надо добавить его в список
            this.form.object.channelList.push(Channel.voiceChannel());
            if (!this.form.object.type) {
                this.form.object.type = this.types.find(type => type.name === ProjectTypeEnum.INCOMING);
            }
        } else {
            // выкинуть голос
            this.form.object.channelList = this.form.object.channelList.filter(channel => channel.key !== Valued.VOICE_API_KEY);
            this.form.object.type = null;
        }
        this.form.object.sortViewChannelList();
    }

    onTextToggle(): void {
        if (this.isText) {
            // если текст включили, то надо добавить его в список
            this.form.object.channelList.push(Channel.webChannel());

        } else {
            // остается только голос
            this.form.object.channelList = this.form.object.channelList.filter(channel => channel.key !== Valued.WEB_API_KEY);
        }
        this.form.object.sortViewChannelList();
    }

    onAssistantToggle(): void {
        if (this.isAssistant) {
            this.isText = false;
            this.isVoice = false;
            this.form.object.channelList.push(Channel.assistantChannel());
            this.form.object.channelList.push(Channel.assistantVoiceChannel());
            this.form.object.channelList = this.form.object.channelList.filter(channel =>
                (channel.key == Valued.ASSISTANT_API_KEY || channel.key == Valued.ASSISTANT_VOICE_API_KEY));
        } else {
            this.form.object.channelList = this.form.object.channelList.filter(channel =>
                !(channel.key == Valued.ASSISTANT_API_KEY || channel.key == Valued.ASSISTANT_VOICE_API_KEY));
        }
    }

    async save(preserveState?: boolean): Promise<void> {
        // fill channel object
        if (this.form.object.channelList && !this.isImport) {
            this.form.object.channelList = this.form.object.channelList
                .filter(channel => channel.key !== "" || channel.value !== "");
            this.form.object.channels = {};
            this.form.object.channelList
                .forEach(channel => this.form.object.channels[channel.key] = channel.value);
        } else {
            this.form.object.channels = null;
        }
        if (this.isModeNew()) {
            this.form.errors.clear();
            this.setLoading(true);
            let formData = new FormData() as any;
            formData.append('project', new Blob([JSON.stringify(this.form.object)], {
                type: "application/json"
            }));
            let url = `${this.projectService.baseUrl}${(this.isImport ? '/import' : '')}`;
            if (this.importFile) {
                formData.append("file", this.importFile);
                // отправить запрос и поймать ошибки - результат будет через сокет
                this.httpClient.post(`${url}`, formData)
                    .toPromise()
                    .then(() => {
                    }, (error: ErrorResponseData) => {
                        this.handleError(error);
                    });
                this.displayBargeIn = false;
            } else {
                await this.finishSave(this.httpClient.post(`${url}`, formData).toPromise(), true, preserveState);
                this.form.object = new Project(this.form.object);
                this.displayBargeIn = false;
            }
        } else {
            await super.save(preserveState);
            this.form.object = new Project(this.form.object);
            this.displayBargeIn = false;
        }
        this.loadAccounts();
    }


    handleError(data): void {
        super.handleError(data);
        if (!data.errors) {
            return;
        }
        // список ошибок от сервера
        data.errors.forEach(error => {
            const msg = error.message;
            //  "Нельзя удалить вариант канала: есть использующие его сценарии".
            //  Канал при этом пропадать из списка не должен после обновления, только выводится ошибка.
            if (msg && msg === "Нельзя удалить вариант канала: есть использующие его сценарии") {
                this.form.object.channels = this.form.object.copyChannels;
            }
        });
    }

    get entityTitle(): string {
        if (this.form.object.id) {
            return 'Редактирование проекта';
        }
        return this.form.object.name;
    }

    addChannel(): void {
        this.form.object.channelList.push(Channel.emptyChannel());
    }

    isDefaultChannel(channel: string) {
        if (channel === 'VOICE')
            return this.defaultChannel?.apiKey === Valued.VOICE_API_KEY
        if (channel === 'WEB')
            return this.defaultChannel?.apiKey === Valued.WEB_API_KEY;
        if (channel === 'ASS')
            return this.defaultChannel?.apiKey === Valued.ASSISTANT_API_KEY;
    }

    isRemovableChannel(apiKey: string) {
        return apiKey !== Valued.VOICE_API_KEY && apiKey !== Valued.WEB_API_KEY && apiKey !== Valued.ASSISTANT_API_KEY;
    }

    removeChannel(index: number): void {
        this.form.object.channelList.splice(index, 1);
    }


    onImportFileSelected(items: FileItem[]): void {
        let file: File = items.pop()._file;
        if (file.size > 0) {
            this.importFile = file;
        }
    }

    onImportFileRemoved(): void {
        this.importFile = null;
    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     * @param $event
     */
    onImportFileAddFailed($event: string) {
        this.notificationService.error($event);
    }


    isSaveWithConfirm(): boolean {
        if (this.isModeNew()) {
            return false;
        }
        // отфильтрyем, есть ли yдаленные
        const removedChannelsWithChannelText = Object.keys(this.form.object.channels)
            .filter(apiKey => this.form.object.channelList.findIndex(channel => channel.key === apiKey) < 0)
            .filter(apiKey => this.form.object.hasChannelTextByApiKey[apiKey] === true);
        return removedChannelsWithChannelText.length > 0;
    }

    get formTitle(): string {
        if (this.isImport) {
            return `Импорт проекта`;
        }

        switch (this.mode) {
            case DetailsMode.SHOW:
            case DetailsMode.EDIT:
                return "Редактирование";
            case DetailsMode.NEW:
                return this.metaTexts.newObjectTitle;
        }
    }

    get saveConfirmMessage(): string {
        return "После удаления канала сохраненные формулировки удалятся. Продолжить?";
    }

    /**
     * При переключении языка на английский - сбросить привязку к английскому проекту
     */
    onLanguageChange() {
        const project = this.form.object;
        if (project.language == ProjectLanguage.ENGLISH && project.englishProjectId != null) {
            this.form.object.englishProjectId = null;
        }
    }

    
    loadAccounts(): void {
        const user = this.accountService.getAccountData();
        this.accountsService.getFullAccounts().then((accounts: ChatAccountData[]) => {
            // показываем всех пользователей, кроме текущего админа
            this.accounts = accounts.filter(accountData => accountData.id !== user.user.id || accountData.account.role.name !== "ADMINISTRATOR")
                .map(accountData => accountData.account);
            if (this.isModeNew()) {
                this.initNewAccountIds();
            } else {
                this.form.object.accountsIds = this.accounts.filter(account => account.robotProjects.includes(this.form.object.name)).map(account => account.id);
            }
        });
    }

    /**
     * Проинициализировать аккаунты для нового объекта
     */
    private initNewAccountIds() {
        this.projectService.isSelectAll().then(select => {
            // выбираем всех юзеров по умолчанию при упрощенном доступе
            if (select) {
                this.selectAll();
            } else {
                this.form.object.accountsIds = [];
            }
        });
    }

    /**
     * Выбрать всех пользователей
     */
    selectAll() {
        this.form.object.accountsIds = this.accounts.map(account => account.id);
        this.userSelect.close();
    }

    openBargeIn() {
        this.form.object.bargeInDefault = !this.isVoice;
        this.displayBargeIn = !this.isVoice;
    }

    getChannels() {
        return this.form.object.channelList.filter(channel => channel.key !== Valued.ASSISTANT_VOICE_API_KEY);
    }

    showCancel(): boolean {
        return true;
    }
}