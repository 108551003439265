import {Component, Inject, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material";
import {DialogCorrectionDTO, DialogCorrectionTypeEnum} from "../model/correction.model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ReplyView} from "../model/reply.model";
import {DialogService} from "../dialog.service";
import {VaTag} from "../../../../data/va/Tag";

@Component({
    selector: 'reply-tag-correction-dialog',
    template: require('./reply-tag-correction-dialog.component.html'),
    styles: [require('./reply-tag.component.less')]
})
export class ReplyTagCorrectionDialogComponent implements OnInit {

    /**
     * Флажок загрузки
     */
    tagIsLoading: boolean = true;
    correctionIsLoading: boolean = true;
    private correction: DialogCorrectionDTO;
    tagCorrectionIsNotRequired: boolean = false;

    constructor(public dialogRef: MatDialogRef<ReplyTagCorrectionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { reply: ReplyView, correction: DialogCorrectionDTO, type: DialogCorrectionTypeEnum },
                private dialogService: DialogService) {

    }

    ngOnInit(): void {
        if (this.data.correction) {
            this.correction = this.data.correction;
            this.correctionIsLoading = false;
        } else {
            this.dialogService.initCorrection(this.data.reply.reply.dialogId, this.data.reply.reply.id, this.data.type)
                .then((correction => {
                    this.correction = correction;
                }))
                .finally(() => this.correctionIsLoading = false);
        }
    }

    onReady() {
        this.tagIsLoading = false;
    }


    updateTagCorrection() {
        this.correction.message.tag = new VaTag();
        this.correction.message.tag.text = 'Лишнее определение тематики';
        this.correction.message.tagId = null;
        this.dialogRef.close(this.correction);
    }

    close(tag: VaTag): void {
        this.correction.message.tag = tag;
        this.correction.message.tagId = tag.key.id;
        this.dialogRef.close(this.correction);
    }
}