import {Component, Input, KeyValueDiffer, KeyValueDiffers, OnInit, SimpleChange} from "@angular/core";
import {HighlightWord} from "./highlight-text.model";

@Component({
    selector: 'highlight-text',
    template: require('./highlight-text.component.html'),
    styles: [require('./highlight-text.component.less')]
})
export class HighlightTextComponent implements OnInit {

    /**
     * Входящий текст, в котором можно выбирать слова
     */
    @Input()
    private text: string;

    /**
     * Объект, который содержит поле с выделенным текстом
     */
    @Input()
    private selectedTextObject: any;

    /**
     * На3вание поля с выделенным текстом в Объекте
     */
    @Input()
    private selectedTextField: string = "selectedText";

    /**
     * Задизейблить онклик
     */
    @Input()
    private disableClick: boolean;

    /**
     * 3аголовок для текста
     */
    @Input()
    private textLabel: string;

    /**
     * 3аголовок для выделенного текста
     */
    @Input()
    private selectedTextLabel: string;

    /**
     * Показывать исxодники, если ничего не смогли построить
     */
    @Input()
    private showSourceIfEmpty: boolean = false;

    @Input()
    private cssClass: string = "";

    private wordList: HighlightWord[] = [];
    private selectedWordList: HighlightWord[] = [];

    private selectedTextObjectDiffer: KeyValueDiffer<string, any>;


    constructor(private differs: KeyValueDiffers) {
    }

    ngOnInit() {
        this.initWordList();
    }

    initWordList(): void {
        const wordList = HighlightWord.buildWordList(this.text);
        const selectedWordList = HighlightWord.buildSelectedWordList(this.selectedTextObject[this.selectedTextField], wordList);
        // когда все посчитали
        this.wordList = wordList;
        this.selectedWordList = selectedWordList;
    }

    /**
     * Функция выбора/не выбора слова
     */
    toggle(word: HighlightWord): void {
        // если слово ещё не выборано
        if (!this.isSelected(word)) {
            // выбираем - добавляем в массив
            this.selectedWordList.push(word);
        } else {
            // иначе - удаляем из массива
            const index = this.selectedWordList.findIndex((w: HighlightWord) => w.w == word.w && w.index == word.index);
            if (index >= 0) {
                this.selectedWordList.splice(index, 1);
            }
        }
        // поменяем выделенный текст
        this.selectedTextObject[this.selectedTextField] = HighlightWord.convertWordListToString(this.selectedWordList);
    };

    /**
     * Ищем слово в массиве
     */
    isSelected(word: HighlightWord): boolean {
        return this.selectedWordList != null && this.selectedWordList.filter((w: HighlightWord) => word.w == w.w && word.index == w.index).length > 0;

    };

    ngOnChanges(changes: { text: SimpleChange, selectedTextObject: SimpleChange }): void {
        if (changes.text) {
            this.initWordList();
        }
        if (changes.selectedTextObject) {
            this.selectedTextObjectDiffer = this.differs.find(this.selectedTextObject).create();
        }
    }

    ngDoCheck(): void {
        if (this.selectedTextObjectDiffer) {
            const changes = this.selectedTextObjectDiffer.diff(this.selectedTextObject);
            if (changes) {
                changes.forEachChangedItem(item => {
                    if (item.key == this.selectedTextField) {
                        this.initWordList();
                    }
                });
            }
        }
    }

}
