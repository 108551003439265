/**
 * Описание {@link ru.naumen.chat.va.data.entity.sma.VASimpleModuleAnswer}
 */
import {Formulation, WithFormulation} from "./Formulation";
import {VAActEnum} from "./VAActEnum";

export class VASimpleModuleAnswer extends WithFormulation {
    vaAct: VAAct;

    constructor(object?: any) {
        super();
        if (!object) {
            return;
        }
        this.key = object.key;
        this.vaAct = object.vaAct;
        this.expertId = object.expertId;
        this.d = object.d;
        this.deleted = object.deleted;
        this.expert = object.expert;
        this.formulations = object.formulations ? object.formulations.map(f => new Formulation(f)) : null;
        this.taggedInfo = object.taggedInfo;
        this.setExistAllAudioRecordFiles();
    }
}

export class VAAct {
    name: VAActEnum;
    title: string;
    description: string;
    emptyText: string;
    smallTalkTagId: string;
    displayOrder: number;
}

export class UserAct {
    name: UserActEnum;
    title: string;
    description: string;
    emptyText: string;
    smallTalkTagId: string;
}

export enum UserActEnum {
    WELCOME = "WELCOME",
    SOC_CHAT = "SOC_CHAT",
    KM_QUESTION = "KM_QUESTION",
    GOODBYE = "GOODBYE",
    ACK_WAIT = "ACK_WAIT",
    ACK_APOLOGY = "ACK_APOLOGY",
    ACK_END = "ACK_END",
    KM_CLARIFICATION = "KM_CLARIFICATION",
    ACK_REPEAT = "ACK_REPEAT",
    ACK_NO_INPUT = "ACK_NO_INPUT",
    ACK_NO_MATCH = "ACK_NO_MATCH",
    KM_ANSWER_TO_QUESTION = "KM_ANSWER_TO_QUESTION",
    OPERATOR_REQUEST = "OPERATOR_REQUEST",
    ATTACH = "ATTACH",
}

export class StatUserAct {
    name: string;
    title: string;
}



