import {Component} from "@angular/core";
import {PushNotificationService, ThenState} from "./push-notification.service";
import {StateService} from "@uirouter/core";

@Component({
    template: ''
})
export class PushNotificationComponent {

    /**
     * Подписываемся на пуш-уведомления через снек-бар и сразу уходим на заданный стейт (для включения уведомлений на стейтах первого ангуляра)
     */
    constructor(pushNotificationService: PushNotificationService, stateService: StateService) {
        const then: ThenState = JSON.parse(stateService.params['then']);
        stateService.go(then.name, then.params);
    }

}