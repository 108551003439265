import {Component, EventEmitter, Input, Output} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {FileItem} from "../../../common/file-uploader/uploader-vendor/file-item.class";
import {TranscriptionService} from "../transcription.service";
import StatisticsService from "../../../../../services/StatisticsService";
import {chunks} from "../../../../../../util/Utils";

@Component({
    selector: 'transcription-import',
    template: require('./transcription-import.component.html'),
    styles: [require('./transcription-import.component.less')],
})
export class TranscriptionImportComponent {

    @Output()
    private onDeny = new EventEmitter<void>();

    @Output()
    private importComplete = new EventEmitter<void>();

    @Input()
    autoUpload: boolean = false;

    @Input()
    showControlButtons: boolean = false;

    @Input()
    controlName: string = "";

    /**
     * Аудиозаписи
     */
    files: File[];

    /**
     * Данные о загрузке для прогресс-бара
     */
    isLoading: boolean;

    /**
     * Для отображения результата
     */
    form: any = {
        success: false,
        errors: new Map<string, string>()
    };

    /**
     * Прогресс загрузки
     */
    uploadProgress = 0;

    constructor(private http: HttpClient, private service: TranscriptionService, private statisticsService: StatisticsService) {
    }

    /**
     * При изменении контента файл-инпута
     */
    onFileChange(items: FileItem[]) {
        if (!items) {
            this.files = null;
        } else {
            this.files = items.map(item => item._file);
            if (this.autoUpload) {
                this.upload();
            }
        }
    }

    /**
     * Закрыть модалку загрузки
     */
    deny(): void {
        this.onDeny.emit();
    }

    /**
     * Залить аудиофайлы на сервер
     */
    async upload(): Promise<void> {
        // разбиваем на порции по 10 файлов
        let fileChunks: Array<File[]> = chunks(this.files, 10);
        // пока не загрузили - не разлогиниваем
        this.isLoading = true;
        this.keepLoggedIn();
        let loaded = 0;
        // кажду порцию отдельным запросом
        for (let i = 0; i < fileChunks.length; i++) {
            const chunk = fileChunks[i];
            let formData = new FormData();
            chunk.forEach(file => formData.append('files', file, file.name));
            // распознавание запускаем вместе с последним запросом, чтобы лишние треды не плодить
            const isLast = i == (fileChunks.length - 1);
            formData.set('startRecognition', `${isLast}`);
            try {
                await this.http.post(`${this.service.baseUrl}/import`, formData).toPromise();
                // отображаем прогресс
                loaded = loaded == 0 ? chunk.length : loaded += chunk.length;
                this.uploadProgress = (loaded * 100) / this.files.length;
            } catch (exception) {
                exception.errors.forEach(error => this.form.errors.set("#main", error.message));
            }
        }
        this.isLoading = false;
        this.uploadProgress = 0;
        this.form.success = this.form.errors?.size == 0;
        this.deny();
    }


    /**
     * Поддерживаем залогиненным при загрузке
     */
    keepLoggedIn() {
        if (this.isLoading) {
            this.statisticsService.updateLastUserActionTime();
            setTimeout(() => this.keepLoggedIn(), 60000);
        }
    }


    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     * @param $event
     */
    onFileAddFailed($event: string) {
        this.form.errors.set("#main", $event);
        setTimeout(() => this.form.errors.set("#main", null), 5000);
    }
}
