import {CompositeKey} from "../../../../data/va/Common";
import {util} from '@naumen/rappid';

export class TextData {
    key: CompositeKey<number>;
    content: string;
    type: TextDataType;
    expertId: number;
    d: Date;
    creationDate: Date;
    deleted: boolean;
    expert: any;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.content = object.content;
        this.type = object.type;
        this.expertId = object.expertId;
        this.d = object.d;
        this.creationDate = object.creationDate;
        this.deleted = object.deleted;
        this.expert = object.expert;
    }

    getRerouteParams(forCreate: boolean = true): RerouteParam[] {
        if (this.type.name !== TextDataTypeEnum.REROUTE_PARAM) {
            return null;
        }
        const rerouteParams = this.content.length > 0 ? JSON.parse(this.content).map(object => RerouteParam.createFromJson(object)) : [];
        // если дефолтного варианта нет = добавляем его в начало списка
        if (forCreate && rerouteParams.filter(param => param.isDefault).length == 0) {
            rerouteParams.unshift(RerouteParam.createDefaultRerouteParam());
        }
        return rerouteParams;
    }
}

export class TextDataGroup {
    key: CompositeKey<TextDataTypeGroup>;
    textDataList: TextData[];

    textDataWithNumberValue: TextData[];
    rerouteParams: RerouteParam[];
    asrSettings: TextDataAsrSettings;
    answerAsrSettings: TextDataAsrSettings;
    ttsSettings: TextDataTtsSettings;


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.textDataList = object.textDataList.map(data => new TextData(data));
        // распарсим контент в  объект
        switch (this.key.id.name) {
            case TextDataTypeGroupEnum.VOICE:
                this.asrSettings = new TextDataAsrSettings(this.getSettingsObject(TextDataTypeEnum.DEFAULT_ASR_SETTINGS));
                this.answerAsrSettings = (new AnswerAsrSettings(this.getSettingsObject(TextDataTypeEnum.DEFAULT_ANSWER_VOICE_SETTINGS))).confirmAsr;
                this.ttsSettings = new TextDataTtsSettings(this.getSettingsObject(TextDataTypeEnum.DEFAULT_TTS_SETTINGS));
                break;
            case TextDataTypeGroupEnum.DIALOG_HANDLE:
                this.textDataWithNumberValue = this.textDataList
                    .filter(data => [TextDataTypeEnum.TAG_UNSUCCESS_MAX_COUNT, TextDataTypeEnum.MAX_TAG_UNKNOWN_COUNT,
                        TextDataTypeEnum.MAX_TAG_CONFIRM_COUNT,
                        TextDataTypeEnum.REQUESTS_COUNT].indexOf(data.type.name) >= 0)
                    .sort((a: TextData, b: TextData) => a.type.id.toString().localeCompare(b.type.id.toString()));
                const rerouteParamsTextData = this.textDataList
                    .find(data => [TextDataTypeEnum.REROUTE_PARAM].indexOf(data.type.name) >= 0);
                if (rerouteParamsTextData) {
                    this.rerouteParams = rerouteParamsTextData.getRerouteParams();
                }
                break;
            case TextDataTypeGroupEnum.ASSISTANT:
            default:
                break;

        }
    }

    /**
     * Получить объект из Json контента текст даты нежного типа
     */
    private getSettingsObject(type: TextDataTypeEnum): any {
        const textDataByType = this.textDataList.find(data => data.type.name === type);
        return textDataByType ? JSON.parse(textDataByType.content) : null;
    }

    /**
     * Записать объект  в Json контент текст даты нежного типа
     */
    private preparePropertyForSave(type: TextDataTypeEnum, object: any): void {
        const textDataByType = this.textDataList.find(data => data.type.name === type);
        if (textDataByType) {
            textDataByType.content = JSON.stringify(object);
        }
    }

    prepareForSave(): void {
        switch (this.key.id.name) {
            case TextDataTypeGroupEnum.VOICE:
                // запишем то, что  настроили в Json контент  текст даты
                this.preparePropertyForSave(TextDataTypeEnum.DEFAULT_ASR_SETTINGS, this.asrSettings);
                const answerAsrSettings = new AnswerAsrSettings();
                answerAsrSettings.confirmAsr = this.answerAsrSettings;
                this.preparePropertyForSave(TextDataTypeEnum.DEFAULT_ANSWER_VOICE_SETTINGS, answerAsrSettings);
                this.preparePropertyForSave(TextDataTypeEnum.DEFAULT_TTS_SETTINGS, this.ttsSettings);
                break;
            case TextDataTypeGroupEnum.DIALOG_HANDLE:
                // обновим значения в исходном списке для текст даты с числовыми настройками
                this.textDataWithNumberValue.forEach(dataWithNumberValue => {
                    this.textDataList.find(data => data.type.name === dataWithNumberValue.type.name).content = dataWithNumberValue.content;
                });
                // параметры перевода в json
                this.textDataList.find(data => data.type.name === TextDataTypeEnum.REROUTE_PARAM).content =
                    JSON.stringify(this.rerouteParams.filter(param => !param.isEmpty()));
                break;
            default:
                break;
        }
    }
}

export class TextDataType {
    id: number;
    name: TextDataTypeEnum;
    title: string;
    group: TextDataTypeGroup;
    description: string;
    multiline: boolean;
    showValueInList: boolean;
}

export enum TextDataTypeEnum {
    CUSTOM_SPELL = "CUSTOM_SPELL",
    WORD_CHARS = "WORD_CHARS",
    CHAR_REPLACEMENTS = "CHAR_REPLACEMENTS",
    STOP_WORDS = "STOP_WORDS",
    NEGATIONS = "NEGATIONS",
    CLUST_SKIP_WORDS = "CLUST_SKIP_WORDS",
    CLUST_SKIP_DIALOG_PHRASES = "CLUST_SKIP_DIALOG_PHRASES",
    DEFAULT_ASR_SETTINGS = "DEFAULT_ASR_SETTINGS",
    DEFAULT_TTS_SETTINGS = "DEFAULT_TTS_SETTINGS",
    DEFAULT_ANSWER_VOICE_SETTINGS = "DEFAULT_ANSWER_VOICE_SETTINGS",
    TAG_UNSUCCESS_MAX_COUNT = "TAG_UNSUCCESS_MAX_COUNT",
    MAX_TAG_UNKNOWN_COUNT = "MAX_TAG_UNKNOWN_COUNT",
    MAX_TAG_CONFIRM_COUNT = "MAX_TAG_CONFIRM_COUNT",
    REQUESTS_COUNT = "REQUESTS_COUNT",
    REROUTE_PARAM = "REROUTE_PARAM",
    ACK_APOLOGY_SAMPLES = "ACK_APOLOGY_SAMPLES",
    ACK_END_SAMPLES = "ACK_END_SAMPLES",
    ACK_WAIT_SAMPLES = "ACK_WAIT_SAMPLES",
    GOODBYE_SAMPLES = "GOODBYE_SAMPLES",
    KM_CLARIFICATION_SAMPLES = "KM_CLARIFICATION_SAMPLES",
    ACK_REPEAT_SAMPLES = "ACK_REPEAT_SAMPLES",
    OPERATOR_REQUEST_SAMPLES = "OPERATOR_REQUEST_SAMPLES",
    SOC_CHAT_SAMPLES = "SOC_CHAT_SAMPLES",
    WELCOME_SAMPLES = "WELCOME_SAMPLES"

}

export class TextDataTypeGroup {
    name: TextDataTypeGroupEnum;
    title: string;
    section: string;
    editable: boolean;
}

export enum TextDataTypeGroupEnum {
    PIPELINE = "PIPELINE",
    EXTRACTION = "EXTRACTION",
    CLUSTERING = "CLUSTERING",
    VOICE = "VOICE",
    DIALOG_HANDLE = "DIALOG_HANDLE",
    ACT_SAMPLES = "ACT_SAMPLES",
    ASSISTANT = "ASSISTANT"
}

export class AnswerAsrSettings {
    confirmAsr: TextDataAsrSettings;
    confirmMessage: string;


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.confirmAsr = object.confirmAsr ? new TextDataAsrSettings(object.confirmAsr) : null;
        this.confirmMessage = object.confirmMessage;
    }
}

export class RerouteParam {
    id: string;
    value: string;
    description: string;
    isDefault: boolean;

    constructor(id?: string, value?: string, description?: string, isDefault?: boolean) {
        this.id = id;
        this.value = value;
        this.description = description;
        this.isDefault = isDefault;
    }

    public isEmpty(): boolean {
        return this.value?.length == 0;
    }

    static createFromJson(object: { id: string, value: string, description: string, isDefault: boolean }) {
        return new RerouteParam(object.id, object.value, object.description, object.isDefault);
    }

    static createDefaultRerouteParam() {
        return new RerouteParam(util.uuid(), "", "По умолчанию", true);
    }
}

export class TextDataAsrSettings {
    grammar: string;
    noInputTimeout: number;
    recognitionTimeout: number;
    allowBargeIn: boolean;
    speechCompleteTimeout: number;
    speechIncompleteTimeout: number;
    vendor: string;
    bargeInWords: string;


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.grammar = object.grammar;
        this.noInputTimeout = object.noInputTimeout;
        this.recognitionTimeout = object.recognitionTimeout;
        this.allowBargeIn = object.allowBargeIn;
        this.speechCompleteTimeout = object.speechCompleteTimeout;
        this.speechIncompleteTimeout = object.speechIncompleteTimeout;
        this.vendor = object.vendor;
        this.bargeInWords = object.bargeInWords;
    }
}

export class TextDataTtsSettings {
    voiceName: string;
    voiceSpeed: number;
    vendor: string;
    emotion: string;
    audioRecordsUseTts: boolean;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.voiceName = object.voiceName;
        this.voiceSpeed = object.voiceSpeed;
        this.vendor = object.vendor;
        this.emotion = object.emotion ? object.emotion : "neutral";
        this.audioRecordsUseTts = object.audioRecordsUseTts;
    }
}

