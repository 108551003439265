import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {CollectionModule} from "../../common/collection/collection.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {ExpertModule} from "../../common/expert/expert.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {TranscriptionMasterComponent} from "./master/transcription-master.component";
import {TranscriptionEditComponent} from "./details/transcription-edit.component";
import {TranscriptionService} from "./transcription.service";
import {TranscriptionImportComponent} from "./import/transcription-import.component";
import {EruditeFileUploaderModule} from "../../common/file-uploader/erudite-file-uploader.module";
import {TranscriptionImportDialogComponent} from "./import/dialog/transcription-import-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {EruditeAudioPlayerModule} from "../../common/player/erudite-audio-player.module";
import {TranscriptionExportBottomSheetComponent} from "./export/transcription-export-bottom-sheet.component";
import {MatListModule} from "@angular/material/list";
import {MatProgressBarModule} from "@angular/material";
import {EruditeNgbDatepickerModule} from "../../common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {DenyButtonModule} from "../../common/deny-button/deny-button.module";
import {EruditeIconModule} from "../../common/erudite-icon/erudite-icon.module";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
    declarations: [
        TranscriptionMasterComponent,
        TranscriptionEditComponent,
        TranscriptionImportComponent,
        TranscriptionImportDialogComponent,
        TranscriptionExportBottomSheetComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        UIRouterUpgradeModule,
        CollectionModule,
        FormControlModule,
        ExpertModule,
        BoilerplateModule,
        EruditeFileUploaderModule,
        MatDialogModule,
        EruditeAudioPlayerModule,
        MatListModule,
        MatProgressBarModule,
        EruditeNgbDatepickerModule,
        NgSelectModule,
        DenyButtonModule,
        EruditeIconModule,
        MatTooltipModule
    ],
    exports: [
        TranscriptionMasterComponent,
        TranscriptionEditComponent,
        TranscriptionImportComponent,
        TranscriptionImportDialogComponent
    ],
    providers: [
        TranscriptionService
    ],
    entryComponents: [
        TranscriptionImportDialogComponent,
        TranscriptionExportBottomSheetComponent
    ]
})
export class TranscriptionModule {

    static states = [
        {
            name: "robot.transcription",
            url: "/transcription",
            views: {
                'content@authorized': {
                    component: TranscriptionMasterComponent
                }
            },
            data: {
                title: "Транскрибирование",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.transcription.edit',
            url: "/:dialogId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.transcription': {
                    component: TranscriptionEditComponent
                }
            },
            data: {
                title: "Транскрибирование | ",
                newObjectTitle: "",
                editObjectTitle: "Редактирование диалога",
                successfulSaveMessage: "Диалог сохранен",
                meta: {
                    parameters: ["dialogId"],
                    sectionFunction: ([dialogId]) => "Обучение робота",
                    subSectionFunction: ([dialogId]) => dialogId ? "Редактирование диалога" : ""
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];

}