import {VersionedDataService} from "../base/versioned-data.service";
import {HttpClient} from "@angular/common/http";
import {Scope, VaAttribute} from "../../../../data/va/Attribute";
import {Injectable} from "@angular/core";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {ValueOption, ValueTypeEnum} from "../../../../data/va/Extractor";
import {NamedEntitiesModel} from "../model/named-entities.model";
import {Valued} from "../../../../data/va/Valued";

@Injectable()
export class AttributeService extends VersionedDataService<VaAttribute, number> {

    baseUrl: string = "/account/expert/attribute";

    usageEntityType: AppliedEntityType = AppliedEntityType.ATTRIBUTE;

    readonly entityClass = NamedEntitiesModel.VA_ATTRIBUTE;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getScopes(): Promise<Scope[]> {
        return await this.httpClient.get<Scope[]>(`${this.baseUrl}/scopes`).toPromise();
    }

    async getChannels(): Promise<ValueOption[]> {
        return await this.httpClient.get<ValueOption[]>(`${this.baseUrl}/channels`).toPromise();
    }

    /**
     * Есть ли голос в проекте
     */
    async hasVoice(): Promise<boolean> {
        return this.getChannels()
            .then(channels => channels.some(channel => channel.apiKey == Valued.VOICE_API_KEY || channel.apiKey == Valued.ASSISTANT_API_KEY));
    }

    async getChannelsWithAssistant(): Promise<ValueOption[]> {
        return await this.httpClient.get<ValueOption[]>(`${this.baseUrl}/channelsWithAssistant`).toPromise();
    }

    async getDefaultRequestAmount(): Promise<number> {
        return await this.httpClient.get<number>(`${this.baseUrl}/defaultRequestAmount`).toPromise();
    }

    async generateAttributeKey(id: string, name: string): Promise<string> {
        return await this.httpClient.post<string>(`${this.baseUrl}/${id}/generateAttributeKey`, name).toPromise();
    }

    async findAttributesByExtractorId(extractorId: string): Promise<VaAttribute[]> {
        return await this.httpClient.get<VaAttribute[]>(`${this.baseUrl}/findAttributesByExtractorId`, {
            params: {
                extractorId: extractorId
            }
        }).toPromise();
    }

    async findByExtractorValueType(type: ValueTypeEnum): Promise<VaAttribute[]> {
        return await this.httpClient.get<VaAttribute[]>(`${this.baseUrl}/findByExtractorValueType`, {
            params: {
                type: type
            }
        }).toPromise();
    }

    /**
     * Найти все атрибуты города
     */
    async findCityAttributes(): Promise<VaAttribute[]> {
        return await this.httpClient.get<VaAttribute[]>(`${this.baseUrl}/findCityAttributes`, {}).toPromise();
    }

}
