import {NgModule} from "@angular/core";
import {MlService} from "./ml.service";
import {MlEditComponent} from "./details/ml-edit.component";
import {MlMasterComponent} from "./master/ml-master.component";
import {BoilerplateModule} from "../base/boilerplate.module";
import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {CollectionModule} from "../../common/collection/collection.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
    declarations: [
        MlEditComponent,
        MlMasterComponent
    ],
    imports: [
        BoilerplateModule,
        CommonModule,
        UIRouterModule,
        CollectionModule,
        MatProgressBarModule
    ],
    providers: [
        MlService
    ]
})
export class MlModule {
    static states = [
        {
            name: "robot.model_types",
            url: "/model_types",
            views: {
                'content@authorized': {
                    component: MlMasterComponent
                }
            },
            data: {
                title: "Управление обучением",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.model_types.list',
            url: "/:modelType",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.model_types': {
                    component: MlEditComponent
                }
            },
            data: {
                title: "Управление обучением | ",
                meta: {
                    parameters: ["modelType"],
                    sectionFunction: ([]) => "Управление",
                    subSectionFunction: ([]) => "Обучение"
                },
                requiredProjectVersion: true
            }
        }
    ];
}