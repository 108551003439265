import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {UIRouterModule} from "@uirouter/angular";
import {HighlightTextComponent} from "./highlight-text.component";

@NgModule({
    declarations: [
        HighlightTextComponent
    ],
    imports: [
        BrowserModule,
        UIRouterModule
    ],
    exports: [
        HighlightTextComponent
    ],
    entryComponents: [
        HighlightTextComponent
    ],
})
export class HighlightTextModule {

}