import {StepLabel, StepLabelEntity, VaScriptNode} from "../../../../data/va/Script";
import {Formulation} from "../../../../data/va/Formulation";
import {FormulableElement} from "../element-view/interface/FormulableElement";
import {getNodeText} from "../ScriptBuilderUtils";
import ScriptChangesController from "./ScriptChangesController";
import {RerouteElement} from "../element-view/rectangle/RerouteElement";

// направление смены формулировки: следующая/предыдущая
enum SwitchDirection {
    NEXT = 'NEXT',
    PREVIOUS = 'PREVIOUS',
}

/**
 * Контроллер переключения формулировок на блоках "Перенаправление на оператора", "Ответ", "Атрибут"
 */
export default class FormulationSwitchController {

    constructor(private changesController: ScriptChangesController) {
    }

    /**
     * Обработка смены формулировки с текущей на следующую
     * @param viewModel элемент
     */
    onNextFormulation(viewModel: FormulableElement) {
        this.switchFormulation(viewModel, SwitchDirection.NEXT);
    }

    /**
     * Обработка смены формулировки с текущей на предыдущую
     * @param viewModel элемент
     */
    onPreviousFormulation(viewModel: FormulableElement) {
        this.switchFormulation(viewModel, SwitchDirection.PREVIOUS);
    }


    /**
     * Переключить формулировку
     * @param viewModel       элемент
     * @param switchDirection направление смены
     */
    private switchFormulation(viewModel: FormulableElement, switchDirection: SwitchDirection) {
        this.changesController.initBatchChange();
        const entity = viewModel.node.entity;
        const stepLabel = viewModel.node.stepLabel;
        // Текущий индекс
        let currentIndex = entity.formulations.findIndex(formulation => formulation.key.id == stepLabel.subId);
        let nextIndex;
        switch (switchDirection) {
            case SwitchDirection.NEXT:
                // Для следующей формулировки всё просто - всегда следующий индекс
                nextIndex = currentIndex + 1;
                break;
            case SwitchDirection.PREVIOUS:
                // Для предыдущей: если сейчас не выбрана конкретная формулировка, то переключаемся на последнюю
                nextIndex = currentIndex == -1 ? entity.formulations.length - 1 : currentIndex - 1;
                break;
        }
        // Вернём данные, которые сменятся при переключении формулировки
        const {subId, counterText} = FormulationSwitchController.getSwitchChangedData(nextIndex, entity);
        viewModel.subId = subId;
        viewModel.formulationCount = counterText;
        viewModel.labelText = getNodeText(viewModel.node);
        if (viewModel instanceof RerouteElement) {
            // если это блок перевода на оператора, то отобразим параметр перевода
            viewModel.updateRerouteParamTextInElement();
        }
        this.changesController.storeBatchChange();
    }

    /**
     * Получить данные, которые изменятся после смены формулировки
     * @param nextIndex индекс следующей формулировки
     * @param entity    сущность
     */
    private static getSwitchChangedData(nextIndex: number, entity: StepLabelEntity) {
        // Если дошли до конца списка формулировок, то пользователь выбрал "Любую формулировку"
        if (nextIndex == entity.formulations.length || nextIndex == -1) {
            return {
                subId: null,
                counterText: ''
            }
        } else {
            // Выбрали формулировку по индексу
            let formulation: Formulation = entity.formulations[nextIndex];
            // К счётчику +1, т.к. он отражает порядок
            return {
                subId: formulation.key.id,
                counterText: entity.formulations.length == 1 ? '' : `${nextIndex + 1}/${entity.formulations.length}`
            }
        }
    }

    /**
     * Получить данные для отображения узла
     *
     * @param node узел
     */
    public static getViewData(node: VaScriptNode) {
        if (node.stepLabel.value?.length > 0) {
            // задано значение
            return {subId: null, counterText: ''}
        }
        // ищем индекс формулировки
        const index = node.entity.formulations.findIndex(formulation => formulation.key.id === node.stepLabel.subId);

        // счетчик формулировок
        return FormulationSwitchController.getSwitchChangedData(index, node.entity);
    }

    /**
     * Получить текст счётчика формулировок вида "1/3"
     * @param node узел
     */
    public static getCurrentCount(node: VaScriptNode): string {
        const stepLabel: StepLabel = node.stepLabel;
        const entity: StepLabelEntity = node.entity;
        // Если subId не выбран, или формулировка всего одна, или задано значение, то вместо счётчика - пустая строка
        if (!node.stepLabel.subId || entity.formulations.length == 1 || node.stepLabel.value) {
            return '';
        }
        // Ищем индекс subId в формулировках сущности
        let index = entity.formulations.findIndex(formulation => formulation.key.id == stepLabel.subId);
        // Счётчик
        return `${index + 1}/${entity.formulations.length}`;
    }

}