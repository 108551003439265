import {VaTag} from "./Tag";
import {MacroObject} from "../../io/components/common/macro/object/MacroObject";
import {CompositeKey} from "./Common";
import {VaAttribute} from "./Attribute";

export class CustomizationScript implements MacroObject {
    key: CompositeKey<number>;
    name: string;
    language: CustomizationScriptLanguage;
    script: string;
    compilationError: string;
    points: string[];
    viewPoints: CustomizationScriptExecutionPoint[];
    environmentVersion: number;
    module: boolean;
    d: Date;
    creationDate: Date;
    deleted: boolean;
    expertId: number;
    expert: any;
    attribute?: VaAttribute;


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.name = object.name;
        this.language = object.language;
        this.script = object.script;
        this.compilationError = object.compilationError;
        this.points = object.points;
        this.viewPoints = object.points ? object.points.map(pointName => CustomizationScriptExecutionPoint.getByName(pointName)) : null;
        this.environmentVersion = object.environmentVersion;
        this.module = object.module;
        this.d = object.d;
        this.creationDate = object.creationDate;
        this.deleted = object.deleted;
        this.expertId = object.expertId;
        this.expert = object.expert;
        this.attribute = object.attribute;
    }

    getTitle(): string {
        return this.name;
    }

    getId(): string {
        return this.name;
    }

    getTaggedInfo(): VaTag[] {
        return [];
    }

    static readonly BASE_JS_SCRIPT: string = `
/**
 * @param params : {
 *                   invocationPoint - точка вызова,
 *                   text - текст последней реплики,
 *                   entityName - название сущности (атрибут / ответ),
 *                   tagName - название тематики,
 *                   attributeValues - значения атрибутов в контексте,
 *                   sessionId - внешний id сессии,
 *                   dialogId - внутренний id диалога,
 *                   attachments - вложения, формат map(url -> {filename, url, recent}),
 *                   commandParams - текущий параметр перенаправления
 *                 }
 */
function execute(params) {
    return "";
}`;

    static readonly BASE_PYTHON_SCRIPT: string = `
# coding=utf-8
def execute(params):
    """
    :param dict[str, any] params {
                                    invocationPoint - точка вызова,
                                    text - текст последней реплики,
                                    entityName - название сущности (атрибут / ответ),
                                    tagName - название тематики,
                                    attributeValues - значения атрибутов в контексте,
                                    sessionId - внешний id сессии,
                                    dialogId - внутренний id диалога,
                                    log - информация для дебага (log.append("debug info"))
                                    attachments - вложения, формат dict{url -> {filename, url, recent}} 
                                    commandParams - текущий параметр перенаправления
                                  }
    """
    return ""`;

    static readonly MODULE_PYTHON_SCRIPT: string = `
# coding=utf-8

`;


}

export class CustomizationScriptLanguage {

    static readonly JS = new CustomizationScriptLanguage("JS", ".js");
    static readonly PYTHON = new CustomizationScriptLanguage("PYTHON", ".py");
    static readonly VALUES = [CustomizationScriptLanguage.JS, CustomizationScriptLanguage.PYTHON];

    name: string;
    extension: string;

    constructor(name, extension) {
        this.name = name;
        this.extension = extension;
    }
}

export class CustomizationScriptExecutionPoint {

    static readonly AFTER_KM_QUESTION = new CustomizationScriptExecutionPoint("AFTER_KM_QUESTION", "при активации сценария");

    static readonly BEFORE_ATTR_QUESTION = new CustomizationScriptExecutionPoint("BEFORE_ATTR_QUESTION", "перед вопросом / вместо вопроса об атрибуте");

    static readonly AFTER_ATTR_QUESTION = new CustomizationScriptExecutionPoint("AFTER_ATTR_QUESTION", "после ответа на вопрос об атрибуте");

    static readonly ANSWER_TEXT = new CustomizationScriptExecutionPoint("ANSWER_TEXT", "при генерации ответа");

    static readonly AFTER_DIALOG = new CustomizationScriptExecutionPoint("AFTER_DIALOG", "по завершению сессии");

    static readonly VALUES = [CustomizationScriptExecutionPoint.AFTER_KM_QUESTION, CustomizationScriptExecutionPoint.BEFORE_ATTR_QUESTION,
        CustomizationScriptExecutionPoint.AFTER_ATTR_QUESTION, CustomizationScriptExecutionPoint.ANSWER_TEXT, CustomizationScriptExecutionPoint.AFTER_DIALOG];

    text: string;
    name: string;
    title: string;

    constructor(name, text) {
        this.name = name;
        this.text = text;
        this.title = name + " - " + text;
    }

    public static getByName(name: string) {
        return CustomizationScriptExecutionPoint.VALUES.find(executionPoint => executionPoint.name == name);
    }
}

export class CustomizationScriptExecuteParams {
    point: string;
    attributeKeyValueList: [{ key: string, value: string }] = [{key: ``, value: ``}];
    attributeValues: {} = {};
    entityName: string;
    tagName: string;
    text: string = "";
    sessionId: string = "";
    dialogId: string = "";
    attribute: VaAttribute;
}

