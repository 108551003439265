module.exports = [

    {
        state: "robot",
        options: {
            url: "/robot/:projectVersionId?nextUrl",
            parent: "authorized",
            params: {
                projectVersionId: ""
            }
        }
    },
    {
        state: "robot.analysis",
        options: {
            title: "Статистика",
            url: "/analysis",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/analysis/analysis.html",
                    controller: "VaAnalysisFilterController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                details: "robot.analysis.data",
                requiredProjectVersion: true
            }
        }
    },
    {
        state: "robot.analysis.data",
        options: {
            title: "Статистика",
            url: "/:filter/:tagIdForSubtreeFiltering/:singleTagIdForFiltering",
            views: {
                "details@robot.analysis": {
                    templateUrl: "/pages/workplace/va/analysis/analysisData.html",
                    controller: "VaAnalysisDataController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    },
    {
        state: "robot.dkb",
        options: {
            title: "База знаний",
            url: "/dkb",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/dkb/entity/dkb.html",
                    controller: "VaDkbController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                details: "robot.dkb.edit",
                requiredProjectVersion: true
            }
        }
    },
    {
        state: "robot.dkb.edit",
        options: {
            title: "База знаний | ",
            url: "/:dkbEntityId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@robot.dkb": {
                    templateUrl: "/pages/workplace/va/dkb/entity/dkbEdit.html",
                    controller: "VaDkbEditController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                meta: {
                    parameters: ["dkbEntityId"],
                    sectionFunction: function ([dkbEntityId]) {
                        return "Обучение робота";
                    },
                    subSectionFunction: function ([dkbEntityId]) {
                        return dkbEntityId ? "Редактирование сущности в базе знаний" : "Новая сущность в базе знаний";
                    }
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            },
        }

    },
    {
        state: "robot.dkb.edit.dkb_field",
        options: {
            title: "База знаний | Свойства",
            url: "/dkb_field",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/dkb/field/dkbField.html",
                    controller: "VaDkbFieldController",
                    controllerAs: "ctrl"
                }
            }
            ,
            data: {
                details: "robot.dkb.edit",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    },
    {
        state: "robot.dkb.edit.dkb_field.edit",
        options: {
            title: "База знаний | Свойства | ",
            url: "/:dkbFieldId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@robot.dkb.edit.dkb_field": {
                    templateUrl: "/pages/workplace/va/dkb/field/dkbFieldEdit.html",
                    controller: "VaDkbFieldEditController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                meta: {
                    parameters: ["dkbFieldId"],
                    sectionFunction: function ([dkbFieldId]) {
                        return "Обучение робота";
                    },
                    subSectionFunction: function ([dkbFieldId]) {
                        return dkbFieldId ? "Редактирование свойства сущности в базе знаний" : "Новое свойство сущности в базе знаний";
                    }
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    },
    {
        state: "robot.dkb.edit.dkb_field.edit.dkb_value",
        options: {
            title: "База знаний | Свойства | Значения",
            url: "/dkb_value",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/dkb/value/dkbValue.html",
                    controller: "VaDkbValueController",
                    controllerAs: "ctrl"
                }
            }
            ,
            data: {
                details: "robot.dkb.edit",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    },
    {
        state: "robot.dkb.edit.dkb_field.edit.dkb_value.edit",
        options: {
            title: "База знаний | Свойства | Значения | ",
            url: "/:dkbValueId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@robot.dkb.edit.dkb_field.edit.dkb_value": {
                    templateUrl: "/pages/workplace/va/dkb/value/dkbValueEdit.html",
                    controller: "VaDkbValueEditController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                meta: {
                    parameters: ["dkbValueId"],
                    sectionFunction: function ([dkbValueId]) {
                        return "Обучение робота";
                    },
                    subSectionFunction: function ([dkbValueId]) {
                        return dkbValueId ? "Редактирование значения свойства в базе знаний" : "Новое значение свойства в базе знаний";
                    }
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    },
    {
        state: "robot.model_types.ideal",
        options: {
            title: "Управление обучением | ",
            url: "/ideal",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/ml/models/idealDialogResults.html",
                    controller: "VaIdealDialogResultController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                requiredProjectVersion: true
            }
        }
    },
    {
        state: "robot.model",
        options: {
            title: "Модели | ",
            url: "/model/:type/:modelId",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/ml/models/model.html",
                    controller: "VaModelController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    },
    {
        state: "robot.ml_check_reply",
        options: {
            title: "Управление обучением | ",
            url: "/model/check_reply?reply&replyId",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/ml/models/checkReply.html",
                    controller: "VaMlCheckReplyController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                requiredProjectVersion: true
            }
        }
    },
    {
        state: "robot.trend_detection_wrapper",
        options: {
            title: "Выявление трендов",
            url: "/trend_detection",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/trendDetection/trendDetectionWrapper.html",
                    controller: "VaTrendDetectionWrapperController",
                    controllerAs: "wrapperCtrl"
                }
            },
            data: {
                requiredProjectVersion: true
            }
        }
    },
    {
        state: "robot.trend_detection_wrapper.trend_detection",
        options: {
            title: "Выявление трендов",
            url: "/trend_detection",
            views: {
                "content@robot.trend_detection_wrapper": {
                    templateUrl: "/pages/workplace/va/trendDetection/trendDetection.html",
                    controller: "VaTrendDetectionFilterController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                details: "robot.trend_detection_wrapper.trend_detection.data",
                requiredProjectVersion: true
            }
        }
    },
    {
        state: "robot.trend_detection_wrapper.trend_detection.data",
        options: {
            title: "Выявление трендов",
            url: "/:filter",
            views: {
                "details@robot.trend_detection_wrapper.trend_detection": {
                    templateUrl: "/pages/workplace/va/trendDetection/trendDetectionData.html",
                    controller: "VaTrendDetectionDataController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                requiredProjectVersion: true
            }
        }
    }
    ,
    {
        state: "robot.assessor_work_report",
        options: {
            title: "Отчёты по асессорам",
            url: "/assessor_work_report",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/assessor/assessorReport.html",
                    controller: "VaAssessorReportController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                requiredProjectVersion: true
            }
        }
    },
    {
        state: "robot.dashboard",
        options: {
            title: "Мониторинг",
            url: "/dashboard",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/dashboard/dashboard.html",
                    controller: "VaDashboardController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                requiredProjectVersion: true
            }
        }
    },
    {
        state: "robot.tb_sufficiency",
        options: {
            title: "Проверка полноты обучения",
            url: "/tb_sufficiency",
            views: {
                "content@authorized": {
                    templateUrl: "/pages/workplace/va/tagbuilding/sufficiency.html",
                    controller: "SufficiencyController",
                    controllerAs: "ctrl"
                }
            },
            data: {
                requiredProjectVersion: true
            }
        }
    }
];
