import * as urls from "../../../../../../js/workplace/urls.js";
import {VABaseListController} from "../../../../../../js/workplace/controllers/va/VABaseListController";
import {DKBEntity} from "../../../../data/va/Dkb";
import * as restangular from "restangular";

// контроллер страницы со списком дкб полей
export default class VaDkbController extends VABaseListController<DKBEntity> {

    static $inject = ["Restangular", "$stateParams", "$http", "FileSaver", "TitleService"];

    private $http: any;
    private fileSaver: any;
    private projectVersionId: string;
    /**
     * алерт для сообщения об ошибке при выгрузке файла
     */
    private exportUsagesError: boolean;

    constructor(Restangular: restangular.IService, $stateParams: ng.ui.IStateParamsService, $http: any, FileSaver: any, TitleService: any) {
        super(TitleService, Restangular, $stateParams, urls.va.dkb);

        this.header = [
            {title: "Название", field: "name"},
            {title: "Тематика", field: "taggedInfo.text"},
        ];

        this.$http = $http;
        this.fileSaver = FileSaver;
        this.projectVersionId = $stateParams['projectVersionId'];
        this.getAccess();
    }

    exportUsages() {
        this.exportUsagesFile(this.$http, this.fileSaver, this);
    }
}

