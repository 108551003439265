import {Injectable} from "@angular/core";
import {VATagAnswer} from "../../../../data/va/TagAnswer";
import {HttpClient} from "@angular/common/http";
import {VersionedDataService} from "../base/versioned-data.service";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import {NamedEntitiesModel} from "../model/named-entities.model";

@Injectable()
export class AnswerService extends VersionedDataService<VATagAnswer, number> {

    baseUrl: string = "/account/expert/tagAnswer";

    usageEntityType: AppliedEntityType = AppliedEntityType.ANSWER;

    readonly entityClass = NamedEntitiesModel.VA_TAG_ANSWER;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

}