import {NgModule} from "@angular/core";
import {FormulationComponent} from "./formulation.component";
import {FormControlModule} from "../common/form/form-control.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {CommonModule} from "@angular/common";
import {EditableListModule} from "../common/editable-list/editable-list.module";
import {FormsModule} from "@angular/forms";
import {MacroModule} from "../common/macro/macro.module";
import {BoilerplateModule} from "../va/base/boilerplate.module";

@NgModule({
    declarations: [
        FormulationComponent
    ],
    imports: [
        FormControlModule,
        NgSelectModule,
        CommonModule,
        EditableListModule,
        FormsModule,
        MacroModule,
        BoilerplateModule
    ],
    exports: [
        FormulationComponent
    ],
    entryComponents: [
        FormulationComponent
    ]
})
export class FormulationModule {

}