import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {VersionedDataService} from "../base/versioned-data.service";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import {CustomizationScript} from "../../../../data/va/CustomizationScript";
import {NamedEntitiesModel} from "../model/named-entities.model";

@Injectable()
export class CustomizationScriptService extends VersionedDataService<CustomizationScript, number> {

    baseUrl: string = "/account/expert/customizationscript";

    usageEntityType: AppliedEntityType = AppliedEntityType.CUSTOMIZATION_SCRIPT;

    readonly entityClass = NamedEntitiesModel.CUSTOMISATION_SCRIPT;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }
}