export function getTextFunc(modelName) {
    switch (modelName) {
        case "aqext":
            return function (item) {
                return item.description;
            };
        case "tag":
            return function (item) {
                return item.text;
            };
        case "ranker":
            return function (item) {
                return item.q.text;
            };
        case "act":
            return function (item) {
                return item.text;
            };
        case "plainext":
            return function (item) {
                return item.description;
            };
        case "step":
            return function (item) {
                return item.q.text;
            };
    }
}
