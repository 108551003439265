import * as urls from '../../../../../../js/workplace/urls';
import {StateService} from "@uirouter/core";
import {BaseDataService} from "./base-data.service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {SortField} from "../../../../data/va/Common";
import {saveAs} from 'file-saver';
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {OnInit} from "@angular/core";
import {ServerCollectionBridge} from "../../common/collection/server-collection.bridge";
import {objectsEqual, replaceItem} from "../../../../../util/Utils";
import {ServerCollectionFilter} from "../../common/collection/server-collection.component";


export class BaseMasterComponent<T, ID> implements OnInit {

    /**
     * Объекты
     */
    public objects: T[] = [];

    /**
     * Аккаунт пользователя
     */
    account: any;

    /**
     * Поля, по которым можно сортировать элементы коллекции
     */
    public sortFields: SortField[] = [];

    /**
     * Выбранный в детейлс элемент
     */
    public selectedId: string;

    /**
     * Есть ли доступ
     */
    public access: boolean = false;

    /**
     * Происходит ли сейчас загрузка чего-нибудь
     */
    public isLoading: boolean = false;

    /**
     * Хелпер серверной коллекции. Если инициализирован, то данные загружаются и обрабатываются в коллекции
     */
    serverCollectionBridge: ServerCollectionBridge<any, ServerCollectionFilter>;

    public searchString: string;

    constructor(protected titleService: Title,
                protected stateService: StateService,
                protected dataService: BaseDataService<T, ID>,
                protected httpClient: HttpClient,
                protected notificationService: NotificationService) {
        const title: string = this.stateService.current.data.title;
        this.titleService.setTitle(title);

    }

    async ngOnInit(): Promise<void> {
        await Promise.all([this.loadAccount(), this.loadList(), this.getAccess()]);
    }

    // отображение состояния загрузки
    setLoading(loading: boolean): void {
        this.isLoading = loading;
    }

    async loadList() {
        if (this.serverCollectionBridge) {
            // если используем серверную коллекцию, список сущностей хранится там
            return;
        }
        this.setLoading(true);
        this.objects = await this.dataService.findAll();
        this.setLoading(false);
    }

    // добавить ответ в список
    addNewObj(o: T) {
        if (this.serverCollectionBridge) {
            // если используем серверную коллекцию, список сущностей хранится там
            return;
        }
        const copyObject = Object.assign({}, o);
        this.objects.push(copyObject);
    }

    // заменить сущность по ID либо name (например после обновление в форме редактирования)
    replaceObj(o, assignCallback: (object: any) => any = null) {
        if (!this.serverCollectionBridge) {
            // заменяем сущность в списке
            replaceItem(this.objects, o, assignCallback);
        }
    }

    // удалить ответ из списка
    deleteObj(o) {
        if (this.serverCollectionBridge) {
            // если используем серверную коллекцию, список сущностей хранится там
            return;
        }
        for (let i = 0; i < this.objects.length; i++) {
            const obj = this.objects[i];
            let eq = objectsEqual(obj, o);
            if (eq) {
                // удалить объект из массива
                this.objects.splice(i, 1);
                break;
            }
        }
    }

    // получение текущего аккаунта
    async loadAccount() {
        this.account = await this.httpClient.get(`${urls.va.account}current`).toPromise();
        this.account.isJunior = () => {
            return this.account.expertType.name == 'JUNIOR';
        };
    }

    async getAccess() {
        const data = await this.dataService.getAccess();
        this.access = data && data.access;
    }

    /**
     * Экспорт использований
     */
    exportUsages() {
        this.dataService.exportUsagesFile().then((value: HttpResponse<Blob>) => {
            const file: Blob = value.body;
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

            const disposition = value.headers.get('Content-Disposition');
            const matches = filenameRegex.exec(disposition);
            let fileName: string;
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            } else {
                fileName = "usages.xlsx";
            }
            saveAs(file, fileName);
        }, () => {
            // покажем сообщение об ошибке при загрузке файла
            this.showError('Не удалось получить файл');
        });
    }

    /**
     * Показать ошибку
     */
    showError(error: string) {
        this.notificationService.error(error);
    }
}