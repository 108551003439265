import {CompositeKey} from "../../../../data/va/Common";
import {MLTaskType} from "../../../../controllers/analysis/Analysis";
import {UserAct} from "../../../../data/va/SimpleModuleAnswer";
import {VaTag} from "../../../../data/va/Tag";
import {Extractor, ValueOption} from "../../../../data/va/Extractor";
import {VaAttribute} from "../../../../data/va/Attribute";
import {ConfirmInfoType, ExtractedValue, ExtractedValueType} from "./extracted-value.model";

export class DialogCorrectionDTO {
    correction: DialogCorrection;
    actExtraSample: DCActExtraSampleDTO;
    message: DCMessageDTO;
    extractionAttempt: AttemptDTO;

    constructor() {
    }
}

export class DCActExtraSampleDTO {
    userAct: UserAct;
    text: string;
}

export class DCMessageDTO {
    tagId: number;
    text: string;
    tag: VaTag;
}

export class DialogCorrectionType {
    name: DialogCorrectionTypeEnum;
    title: string;
    sampleType: any;
    mlTaskType: MLTaskType;
    statEventField: any;
}

export enum DialogCorrectionTypeEnum {
    WRONG_ACT = "WRONG_ACT",
    WRONG_TAG = "WRONG_TAG",
    MISSED_TAG = "MISSED_TAG",
    MISSED_EXTRACTION = "MISSED_EXTRACTION",
    WRONG_EXTRACTION = "WRONG_EXTRACTION",
    EXCESS_EXTRACTION = "EXCESS_EXTRACTION"
}

export class DialogCorrectionReason {
    name: DialogCorrectionReasonEnum;
    title: string;
}

export enum DialogCorrectionReasonEnum {
    UNKNOWN_REASON = "UNKNOWN_REASON",
    COMPLEX_FORMULATION = "COMPLEX_FORMULATION",
    NO_SUCH_ACT = "NO_SUCH_ACT",
    NO_SUCH_TAG = "NO_SUCH_TAG",
    MESSAGES_MOVE = "MESSAGES_MOVE"
}

export class DialogCorrection {
    key: CompositeKey<number>;
    dialogId: number;
    replyId: number;
    extractedValueId: number;
    type: DialogCorrectionType;
    reason: DialogCorrectionReason;
    expertId: number;
    d: Date;
    creationDate: Date;
    deleted: boolean;
    expert: any;
    sample: any;
}

export class AttemptDTO {
    id: number;
    dialogId: number;
    replyId: number;
    fullText: string;
    selectedText: string;
    d: Date;
    extractionContext: VaExtractionContext;
    expert: any;
    attributeId: number;
    attribute: VaAttribute;
    extractorId: number;
    confirmInfoId: number;
    extractedValueId: number;
    value: ValueOption;
    strValue: string;
    projectVersionId: string;
    confirmInfoValue: string;
    type: ExtractedValueType;
    confirmInfoType: ConfirmInfoType;
    extractor: Extractor;
    tag: VaTag;
    //view
    preselectedTag?: VaTag;
    entity?: any;
    errors: Map<string, string>;
    excluded?: boolean;
    selected: boolean


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.id = object.id;
        this.dialogId = object.dialogId;
        this.fullText = object.fullText;
        this.selectedText = object.selectedText;
        this.d = object.d;
        this.extractionContext = object.extractionContext;
        this.expert = object.expert;
        this.extractorId = object.extractorId;
        this.confirmInfoId = object.confirmInfoId;
        this.confirmInfoValue = object.confirmInfoValue;
        this.value = object.value;
        this.strValue = object.strValue;
        this.type = object.type;
        this.projectVersionId = object.projectVersionId;
        this.confirmInfoType = object.confirmInfoType;
        this.attribute = object.attribute;
        this.attributeId = object.attributeId;
        this.extractor = object.extractor;
        this.tag = object.tag;
        this.entity = object.entity || object.attribute || object.tag;
        this.extractedValueId = object.extractedValueId;
        this.selected = object.selected;
    }

    public static initFromExtractedValue(extractedValue: ExtractedValue, fullText: string) {
        const attemptDTO = new AttemptDTO();

        attemptDTO.dialogId = extractedValue.dialogId;
        attemptDTO.replyId = extractedValue.replyId;

        attemptDTO.type = extractedValue.type;
        attemptDTO.fullText = fullText;
        attemptDTO.selectedText = extractedValue.highlightedText;
        attemptDTO.d = extractedValue.extractionDate;
        attemptDTO.extractionContext = extractedValue.extractionContext;
        attemptDTO.attributeId = extractedValue.entityId;
        attemptDTO.extractorId = extractedValue.extractorId;
        attemptDTO.confirmInfoId = extractedValue.entityId;
        attemptDTO.value = extractedValue.valueOption;
        attemptDTO.strValue = extractedValue.value;
        attemptDTO.type = extractedValue.type;
        attemptDTO.projectVersionId = extractedValue.projectVersionId;
        attemptDTO.confirmInfoType = extractedValue.confirmInfoType;
        return attemptDTO;
    }
}

export interface VaExtractionContext {
    name: "AQ" | "PLAIN" | "BOTH";
    title: string;
}


export class AttemptFilter {
    search: string;
    selectedValueOption: ValueOption;
    selectedExtractionContext: VaExtractionContext;
    fromDialog: string;

    constructor() {
        this.search = "";
        this.selectedValueOption = null;
        this.selectedExtractionContext = null;
    }
}