import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AppliedEntityType} from "../../../../entity-usage/entity-usages.model";
import {NamedEntitiesModel} from "../../../model/named-entities.model";
import WebSocketService from "../../../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {TagService} from "../../../tag/tag.service";


@Injectable()
export class ClusterTagTreeService extends TagService {

    tbUrl: string = "/account/expert/tagbuilding";

    trendUrl: string = "/account/expert/trenddetection";

    tagUrl: string = "/account/expert/tag/";

    usageEntityType: AppliedEntityType = AppliedEntityType.TAG;

    readonly entityClass: NamedEntitiesModel.VA_MESSAGE;


    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getLengthCountRelations(): Promise<any> {
        return await this.httpClient.get<any>(`${this.trendUrl}/lengthCountRelations`).toPromise();
    }

    async getEstimations(): Promise<any> {
        return await this.httpClient.get<any>(`${this.trendUrl}/estimations`).toPromise();
    }

    async getOrderTypes(): Promise<any> {
        return await this.httpClient.get<any>(`${this.trendUrl}/orderTypes`).toPromise();
    }

    async getBuildTypes(): Promise<any> {
        return await this.httpClient.get<any>(`${this.trendUrl}/buildTypes`).toPromise();
    }

    async getDefaultValues(): Promise<any> {
        return await this.httpClient.get<any>(`${this.trendUrl}/defaultValues`).toPromise();
    }

    async getTree(sourceTagIdRequestParam: string): Promise<any> {
        return await this.httpClient.get<any>(`${this.tbUrl}/clusterKeyPhrases/${sourceTagIdRequestParam}`).toPromise();
    }

    async updateCluster(cluster: any): Promise<any> {
        return await this.httpClient.patch<any>(`${this.tbUrl}`, cluster).toPromise();
    }

    async sendToTag(msgId, tagId, sourceTagId): Promise<any> {
        return await this.httpClient.post<any>(`${this.tbUrl}/sendToTag/?msgId=${msgId}&tagId=${tagId}&sourceTagId=${sourceTagId}`, {}).toPromise();
    }

    async sendReplyToTag(replyId, tagId, sourceTagId, clusterId): Promise<any> {
        return await this.httpClient.post<any>(`${this.tbUrl}/sendReplyToTag/?replyId=${replyId}&tagId=${tagId}&sourceTagId=${sourceTagId}&clusterId=${clusterId}`, {}).toPromise();

    }

    async markAsImportant(phrase: string, important: boolean) {
        return await this.httpClient.post<any>(`${this.tbUrl}/markAsImportant`, {phrase: phrase, isImportant: important}).toPromise();
    }
}
