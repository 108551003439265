import {CollectionComponent, MassOperationButton} from "./collection.component";
import {Component, EventEmitter, Input, KeyValueDiffers} from "@angular/core";
import {StateService} from "@uirouter/core";
import {BaseDataService, ChangeEventType, ChangePushEvent} from "../../va/base/base-data.service";
import {ServerCollectionBridge} from "./server-collection.bridge";
import {getItemId, replaceItem} from "../../../../../util/Utils";
import {NamedEntitiesModel} from "../../va/model/named-entities.model";
import {SortField} from "../../../../data/va/Common";

/**
 * Серверная коллекция
 * Если добавляем новый тип, надо не забыть добавить класс
 * в ru.naumen.chat.va.web.push.ChangePushServiceImpl#DEFAULT_ENTITY_TYPES чтобы коллекция менялась
 */
@Component({
    selector: 'server-collection',
    template: require('./collection.component.html'),
    styles: [require('./collection.component.less')],
})
export class ServerCollectionComponent extends CollectionComponent {

    /**
     * Сервис получения данных с бэка
     */
    @Input()
    private dataService: ServerCollectionDataService<any>;


    /**
     * Загружать информацию в поле usages о том, где используется сущность
     */
    @Input()
    private loadUsages: boolean;

    /**
     * Фильтр коллекции с сортировкой и пейджингом
     */
    private filter: ServerCollectionFilter;

    /**
     * Набор объектов для коммуникации с parent component
     */
    @Input()
    bridge: ServerCollectionBridge<any, ServerCollectionFilter>;

    /**
     * Размер отфильтрованной коллекции
     */
    private filteredItemsCount;

    constructor(differs: KeyValueDiffers, private stateService: StateService) {
        super(differs)
    }

    async ngOnInit() {
        super.ngOnInit();
        // заполняем дефолтные поля фильтра, если не заполнены
        this.fillFilterDefaults();
        // поля отображения сортировки
        this.sortField = SortField.instance(this.bridge.filter.orderBy, !this.bridge.filter.orderAsc)
        this.sortReverse = !this.bridge.filter.orderAsc;
        // подписываемся на обновления данных
        // фильтра через инпуты
        this.bridge.filterUpdate.subscribe(changed => this.receiveFilterChange(changed))
        // апдейтов сущностей через инпуты (selected) снаружи, при котором можно заменить объект
        this.bridge.itemUpdate.subscribe(item => this.onItemUpdate(item))
        // выбора всех элементов/снятия выделения
        this.bridge.selectAllNone.subscribe((allNone) => this.onSelectAllNone(allNone))
        // апдейтов сущностей через пуши с бэка
        this.dataService.itemChange.subscribe(changeEvent => this.onChangePushEvent(changeEvent));
        // загружаем список
        await this.loadItems(true, false, true);
    }

    /**
     * Получено изменение фильтра
     */
    private async receiveFilterChange(changed: ServerCollectionFilter) {
        // новое значение в поле
        this.filter = changed;
        // дефолтные значения фильтра
        this.fillFilterDefaults();
        // грузим данные
        await this.onFiltersChange();
    }

    /**
     * Проставить в фильтре дефолтные значения в поля, у которых эти значения есть
     */
    private fillFilterDefaults() {
        if (!this.filter) {
            // инициализируем фильтр
            this.filter = this.bridge.initialFilter;
        }
        // в начало списка
        this.filter.pageNumber = 1;
        if (!this.filter.pageSize) {
            // дефолтный размер страницы
            this.filter.pageSize = 10;
        }
        // версия проекта
        const projectVersionId = this.stateService.params["projectVersionId"];
        if (!this.filter.projectVersionId) {
            this.filter.projectVersionId = projectVersionId;
        }
    }

    /**
     * Загрузить страницу элементов и, если надо, счетчик
     * @param loadCount загружать ли счетчик
     * @param append true - добавить ли загруженные элементы к имеющимся или false - заменить
     * @param showLoader показывать лоадер
     */
    private async loadItems(loadCount = false, append = false, showLoader = false): Promise<void> {
        try {
            if (showLoader) {
                // показываем лоадер, если надо
                this.setLoading(true);
            }
            // запросы пошлем параллельно - страницу данных
            const promises: Promise<any>[] = [this.dataService.filter(this.filter)];
            if (loadCount) {
                // и размеры всего списка с учетом фильтра
                promises.push(this.dataService.count(this.filter));

                let initFilter = this.bridge.initialFilter;
                // проставим версию проекта в предзаданном фильтре, если её нет
                const projectVersionId = this.stateService.params["projectVersionId"];
                if (!initFilter.projectVersionId) {
                    initFilter.projectVersionId = projectVersionId;
                }

                // и без учета фильтра
                promises.push(this.dataService.count(initFilter));
            }
            // качаем, проставляем результаты
            const results = await Promise.all(promises);
            const loaded = results[0];

            if (this.loadUsages && this.dataService instanceof BaseDataService) {
                loaded.forEach(item => this.loadItemUsages(item, this.dataService as BaseDataService<any, number>));
            }

            if (append) {
                // добавляем данные в конец списка, когда скроллинг
                this.items.push(...loaded);
            } else {
                // или заменяем текущий список
                this.items = loaded;
            }
            if (loadCount) {
                // счетчики
                this.filteredItemsCount = results[1];
                this.bridge.itemsCount = results[2];
            }
            // пост-обработка загруженного
            this.onItemsLoaded();
            // загруженные данные в уведомлении наружу
            this.onFiltered.emit(this.items);
        } finally {
            if (showLoader) {
                // выключаем лоадер
                this.setLoading(false);
            }
        }
    }

    /**
     * Заполнить usages у сущности
     * @param item сущность
     * @param baseDataService сервис, наследуемый от BaseDataService
     */
    async loadItemUsages(item, baseDataService: BaseDataService<any, number>) {
        if (item && !item.usages) {
            item.usages = await baseDataService.getObjectUsages(item);
        }
        return await item;
    }

    async changeCurrentPage() {
        super.changeCurrentPage();
        this.filter.pageNumber = this.curPage;
        await this.loadItems();
    };

    /**
     * Массовый выбор/снятие выбора
     */
    async massSelectionChange(select: boolean) {
        await this.onSelectAllNone(select);
    }

    /**
     * Нажатие на кнопку выполнения массовой операции
     */
    onMassOperationButtonPress(button: MassOperationButton) {
        button.operation(Array.from(this.bridge.selectedIds));
    }

    /**
     * Выбран ли хоть один элемент
     */
    get isAnySelected() {
        return this.bridge.selectedIds.size > 0;
    }

    /**
     * Выбраны ли все элементы
     */
    get areAllSelected() {
        return this.bridge.selectedIds.size == this.filteredItemsCount;
    }

    async goToPage() {
        super.goToPage();
        this.filter.pageNumber = this.curPage;
        await this.loadItems();
    };

    async onSearchStringChangeDebounced() {
        this.searchStringChange.emit(this.searchString);
        this.filter.text = this.searchString;
        await this.onFiltersChange();
    }

    ngOnChanges() {
        // все изменения получаем через эмиттеры
    }

    ngDoCheck() {
        // все изменения получаем через эмиттеры
    }

    getEmptyItemsMessage(): string {
        return this.collectionSize
            ? this.filteredCollectionSize ? "" : this.emptyFilteredItemsMessage
            : this.emptyItemsMessage;
    }

    async onItemsChange() {
        if (this.infiniteScroll) {
            // при изменении списка скролл возвращаем в начало
            await this.upFast();
            // на первую страницу
            this.filter.pageNumber = 1;
        }
        // грузим данные
        await this.loadItems(true, false);
    }

    async onFiltersChange(): Promise<void> {
        // очищаем список id выбранных элементов
        this.bridge.selectedIds.clear();
        // скроллим вверх
        await this.upFast();
        // и на первую страницу
        this.filter.pageNumber = 1;
        // грузим данные
        await this.loadItems(true);
    }

    async selectSortField(event: SortField): Promise<void> {
        this.sortField = event;
        this.filter.orderBy = event.field;
        this.filter.orderAsc = !event.reversed;
        // возвращаемся в начало списка
        await this.upFast();
        // и на первую страницу
        this.filter.pageNumber = 1;
        // грузим данные
        await this.loadItems();
    }

    get itemsOnPage() {
        return this.items;
    }

    get filteredCollectionSize(): number {
        return this.filteredItemsCount;
    }

    get collectionSize(): number {
        return this.bridge.itemsCount;
    }

    async onChangeItemsPerPage() {
        this.filter.pageSize = this.itemPerPage;
        await this.onFiltersChange();
    }

    async onScroll() {
        this.filter.pageNumber += 1;
        await this.loadItems(false, true);
    }

    /**
     * Пришло изменение элемента
     */
    private onItemUpdate(item) {
        // заменяем элемент в списке, если он среди загруженных
        replaceItem(this.items, item, this.bridge.itemConstructor);
        // получаем id
        const itemId = getItemId(item);
        if (item.selected) {
            // элемент выбран
            this.bridge.selectedIds.add(itemId);
        } else {
            // выделение элемента снято
            this.bridge.selectedIds.delete(itemId);
        }
    }

    /**
     * Пост-обработка загруженных с бэка сущностей
     */
    private onItemsLoaded(): void {
        if (this.bridge.itemConstructor) {
            // если задан конструктор сущности, маппим сущности через него
            this.items = this.items.map(item => this.bridge.itemConstructor(item));
        }
        // проставляем флаги "выбрано"
        this.setSelectedFlags();
    }

    /**
     * Получено событие обновления элемента
     */
    private async onChangePushEvent(event: ChangePushEvent) {
        if (!event.eventType) {
            // массовое изменение - подгружаем страницу с бэка
            await this.onItemsChange();
            return;
        }
        switch (event.eventType) {
            case ChangeEventType.POST_CREATE:
            case ChangeEventType.POST_DELETE:
                // создание/удаление - подгружаем страницу с бэка
                await this.onItemsChange();
                break;
            case ChangeEventType.POST_UPDATE:
                // апдейт - заменяем объект на странице, если он там есть, может не подходить под фильтр, но и ладно
                this.onItemUpdate(event.object);
                break;
            default:
                throw new Error(event.eventType);
        }
    }

    /**
     * Пришел сигнал массового изменения выделения
     *
     * @param allNone true - выбрано все, false - выделение убрано со всего
     * @private
     */
    private async onSelectAllNone(allNone: boolean) {
        if (allNone) {
            // загружаем список всех отфильтрованных сущностей с бэка
            const itemIds = await this.dataService.filterIds(this.filter);
            // очищаем текущий список выделения
            this.bridge.selectedIds.clear();
            // запоминаем новый полученный список
            itemIds.forEach(itemId => this.bridge.selectedIds.add(itemId))
        } else {
            // очищаем сет id
            this.bridge.selectedIds.clear();
        }
        // проставляем флаг "выделено" в элементы
        this.setSelectedFlags();
    }

    /**
     * Проставить флаг "выделено" в элементы
     */
    private setSelectedFlags() {
        // если id есть в сете выделенных, то элемент выбран
        this.items.forEach(item => item.selected = this.bridge.selectedIds.has(getItemId(item)));
    }

    /**
     * Быстро проскроллить в начало списка
     */
    private async upFast() {
        if (!this.infiniteScroll) {
            // не скролл
            return;
        }
        // в такой позиции при изменении коллекции директива скроллинга перестает генерировать события скролла и застревает
        if (this.filter.pageNumber == 2) {
            // поэтому подгружаем еще страницу и скроллим вниз
            this.scrollableItems.last.scrollIntoViewFast();
            this.filter.pageNumber = 3;
            await this.loadItems(false, true);
            this.scrollableItems.last.scrollIntoViewFast();
        }
        this.scrollableItems.first?.scrollIntoViewFast();
    }
}

/**
 * Базовый фильтр серверной коллекции
 */
export interface ServerCollectionFilter {
    /**
     * Версия проекта
     */
    projectVersionId?: string;

    /**
     * Строка поиска по тексту
     */
    text?: string;

    /**
     * Дата от
     */
    fromDate?: Date | any;

    /**
     * Дата до
     */
    toDate?: Date | any;

    /**
     * Название поля сортировки
     */
    orderBy?: string;

    /**
     * Направление сортировки
     */
    orderAsc?: boolean;

    /**
     * Номер страницы, с 1
     */
    pageNumber?: number;

    /**
     * Размер страницы
     */
    pageSize?: number;
}

/**
 * Сервис доступа к данным на бэке для серверной коллекции
 */
export interface ServerCollectionDataService<ITEM> {

    /**
     * Эмиттер обновления объекта из пуша с бэка
     */
    itemChange: EventEmitter<ChangePushEvent>;

    /**
     * Получить количество элементов по фильтру
     *
     * @param filter фильтр коллекции
     */
    count(filter: ServerCollectionFilter): Promise<number>;

    /**
     * Получить список элементов по фильтру
     *
     * @param filter фильтр коллекции
     */
    filter(filter: ServerCollectionFilter): Promise<ITEM[]>

    /**
     * Получить список id элементов по фильтру
     *
     * @param filter фильтр коллекции
     */
    filterIds(filter: ServerCollectionFilter): Promise<number[]>

    /**
     * Название класс сущности на бэке
     */
    entityClass: NamedEntitiesModel
}