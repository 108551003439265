import {IPromise} from 'angular';

export default function InlineFilterTreeDirective() {
    return {
        restrict: 'E',
        controllerAs: "$ctrl",
        transclude: true,
        templateUrl: "/pages/workplace/components/filterTree.html",
        scope: {
            tree: '<',
            treeOptions: '<',
            selectedTagId: '<'
        },
        bindToController: true,
        controller: ["$scope", "$timeout", InlineFilterTreeDirectiveControler]
    };
}


class InlineFilterTreeDirectiveControler {
    private tagFilter: string;
    private timeOut: ng.ITimeoutService;
    private $scope: ng.IScope;
    private tagFilterTask: IPromise<void>;
    private treeOptions: any;
    private sort: boolean;
    private tree: any;

    constructor($scope: ng.IScope, $timeout: ng.ITimeoutService) {
        this.timeOut = $timeout;
        this.$scope = $scope;
        this.tagFilterTask = null;
        this.tagFilter = "";
        this.sort = false;

        $scope.$watch("$ctrl.tagFilter", () => {
            this.tagFilterChange();
        });

        $scope.$watch("$ctrl.tree", () => {
            this.tagFilterChange();
        });

        $scope.$watch("$ctrl.treeOptions.tagMode", () => {
            this.tagFilter = "";
        });
    }

    traverseAndMarkTreeNode(tag, filter, parentText) {
        let text = tag.text.toLowerCase();
        let index = text.indexOf(filter);
        let nameMatches = index !== -1;
        if (nameMatches) {
            tag.highlitedText = text.replace(filter, "<b>" + filter + "</b>");
            let firstLetter = index === 0 ? tag.highlitedText.substring(4, 3) : tag.highlitedText.substring(1, 0);
            let parentPrefix = parentText != null && this.treeOptions.sortableFilterResult ?
                ("<span class=\"parent\">" + parentText + " | </span>") : "";
            tag.highlitedText = parentPrefix +
                tag.highlitedText.replace(firstLetter, firstLetter.toUpperCase());
        }
        if (this.treeOptions.sortableFilterResult) {
            tag.hide = !nameMatches;
        }

        let children = tag.children;
        if (!children) {
            return !nameMatches;
        }
        let hasNotHiddenChild = false;
        for (let child of children) {
            let childParentText = (parentText != null ? (parentText + " | ") : "") + tag.text;
            let hide = this.traverseAndMarkTreeNode(child, filter, childParentText);
            if (!this.treeOptions.sortableFilterResult) {
                child.hide = hide;
            }
            if (!hide) {
                hasNotHiddenChild = true;
            }
        }
        if (this.treeOptions.sortableFilterResult && !nameMatches) {
            tag.hide = true;
        }
        if (hasNotHiddenChild) {
            this.$scope.$broadcast("tag-open", tag);
        }
        return !hasNotHiddenChild && !nameMatches;
    }

    tagFilterChange() {
        if (this.tagFilterTask) {
            this.timeOut.cancel(this.tagFilterTask);
            this.tagFilterTask = null;
        }

        let handler = () => {
            let filter = this.tagFilter;
            let focusedTags = this.tree;
            if (!filter) {
                this.sort = false;
                const unhide = (nodes) => {
                    for (let i = 0; i < nodes.length; i++) {
                        let node = nodes[i];
                        node.hide = false;
                        node.highlitedText = null;
                        if (node.children && node.children.length > 0) {
                            unhide(node.children);
                        }
                    }
                };
                unhide(this.tree);
                this.$scope.$broadcast("tag-sorted", this.sort);
            } else {
                this.sort = this.treeOptions.sortableFilterResult;
                filter = filter.toLowerCase();

                for (let tag of focusedTags) {
                    let hide = this.traverseAndMarkTreeNode(tag, filter, null);
                    if (!this.treeOptions.sortableFilterResult) {
                        tag.hide = hide;
                    } else {
                        tag.hide = true;
                    }
                }

                this.$scope.$broadcast("tag-sorted", this.sort);
            }
        };
        
        this.tagFilterTask = this.timeOut(handler, 300);
    }

}