import {NgModule} from "@angular/core";
import {AttributeRuleEditorComponent} from "./attribute-rule-editor.component";
import {ExpressionPartComponent} from "./part/expression-part.component";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "ng-pick-datetime";
import {MatIconModule} from "@angular/material/icon";
import {DatetimePickerModule} from "../../common/datetime-picker/datetime-picker.module";


@NgModule({
    declarations: [
        AttributeRuleEditorComponent,
        ExpressionPartComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatIconModule,
        DatetimePickerModule
    ],
    exports: [
        AttributeRuleEditorComponent
    ]
})
export class AttributeRuleEditorModule {

}