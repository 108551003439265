import {Inject, Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {flatMap, map} from "rxjs/operators";
import {of, throwError} from "rxjs";

@Injectable()
export class ProjectVersionInterceptor implements HttpInterceptor {

    private readonly key: string = 'projectVersionId';

    private readonly stateParams: ng.ui.IStateParamsService;

    /**
     * @param $injector - позволяет получить доступ к зависимостям из AngularJS
     */
    constructor(@Inject('$injector') $injector) {
        this.stateParams = $injector.get('$stateParams');
    }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler) {
        // Если projectVersionId не добавлен, то добавляем
        if (!httpRequest.params.has(this.key)) {
            // HttpRequest immutable, поэтому clone
            const interceptedRequest = httpRequest.clone({
                params: httpRequest.params.append(this.key, this.stateParams[this.key])
            });
            return next.handle(interceptedRequest)
                .pipe(map((event: HttpEvent<any>) => this.extractResponse(event)));
        }
        return next.handle(httpRequest)
            .pipe(flatMap((event: HttpEvent<any>) => {
                let responseType = httpRequest.responseType;

                if (event instanceof HttpResponse && responseType === 'blob') {
                    if (event.body && event.body.type === 'application/json') {
                        // это все-таки ошибка
                        return throwError(event.body);
                    }
                    // если это уже ответ и это блоб -- возвращаем
                    return of(event);
                }

                if (event instanceof HttpResponse && event.body && event.body.status && event.body.status !== "OK") {
                    // статус сущности не ок, надо режектить промис как будто это ошибка (хотя код 200)
                    return throwError(event.body);
                }

                return of(this.extractResponse(event));

            }));
    }

    public extractResponse(event: HttpEvent<any>): HttpEvent<any> {
        if (event instanceof HttpResponse && event.body && event.body.status && event.body.status === "OK") {
            return event.clone({body: event.body.result});
        }
        return event;
    }

    private isArrayBufferOrBlob(event: HttpResponse<any>) {
        return event.body instanceof ArrayBuffer || event.body instanceof Blob
    }
}