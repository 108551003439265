import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ExtractableValuesSerDTO} from "../../../../data/va/Dkb";
import {VaAttribute} from "../../../../data/va/Attribute";
import {ValueOption, ValueTypeEnum} from "../../../../data/va/Extractor";
import {EditableForm, EditableItemData} from "../../common/editable-list/editable-list.model";
import {EditableListComponent} from "../../common/editable-list/editable-list.component";
import {ChatControlService} from "../../chat/chat-control.service";


@Component({
    selector: 'extractable-values',
    template: require('./extractable-values.component.html')
})

export class ExtractableValuesComponent implements OnInit, OnDestroy {

    /**
     * Модель для сохранения выбранного значения(-ий)
     */
    @Input()
    values: ExtractableValuesSerDTO[];

    /**
     * Список атрибутов, из которых можно выбирать
     */
    @Input()
    attributes: VaAttribute[];

    /**
     * Разрешено ли выбирать один вариант значений или несколько?
     */
    @Input()
    multiple: boolean;

    /**
     * Нельзя изменять содержимое компоненты
     */
    @Input()
    private disabled: boolean;

    @Input()
    private showActionTitle: boolean = null;

    @Input()
    onlyEnumValue: boolean = false;

    /**
     * Значение можно не задавать
     */
    @Input()
    valueIsOptional: boolean = false;

    isDynamic: boolean = false;

    @ViewChild('editableListComponent', {static: false})
    private editableListComponent: EditableListComponent;

    editableItemData: EditableItemData = new EditableItemData("атрибут",
        true, true, true, false, true, false);

    /**
     * Форма для условий
     */
    form: EditableForm<ExtractableValuesSerDTO> = new EditableForm(new ExtractableValuesSerDTO());

    /**
     * Отфильтрованные атрибуты
     */
    selectAttributes = [];

    sources: Map<string, any> = new Map<string, any>();

    private subscription: any;

    constructor(private chatControlService: ChatControlService) {
    }

    ngOnInit(): void {
        this.subscription = this.chatControlService.finishEdit.subscribe(() => this.finishEdit())

        if (this.showActionTitle !== null) {
            this.editableItemData.showActionTitle = this.showActionTitle;
        }
        this.sources.set("onlyEnumValue", this.onlyEnumValue);
        this.sources.set("valueIsOptional", this.valueIsOptional);

        this.filterSelectAttributes();
    }

    /**
     * Если атрибут уже выбран, то убрать его из списка доступных
     */
    filterSelectAttributes() {
        this.selectAttributes = this.attributes
            .filter(info => {
                if (this.values != null) {
                    //  Среди атрибутов с уже заданным значением ищем текуший info
                    let selectedValue = this.values.find(extractedValue => {
                        return extractedValue.info.key.id === info.key.id
                    });
                    // если атрибут ещё не был выбран, то его можно выбрать
                    return !selectedValue;
                }
                return true;
            });
    }

    /**
     * Функция для сброса выбранных на данные момент значений у атрибутов
     * Используется, чтобы сбросить выбранные значения при смене атрибута в select-е
     */
    resetValueOptions() {
        this.form.objectForm.values = [];
        this.isDynamic = false;
        if (this.form.objectForm.info?.extractor.valueType.name == ValueTypeEnum.DATE && !this.onlyEnumValue) {
            // дату поставим текущую в поле ввода
            this.form.objectForm.value = new Date();
        }

        this.addDynamicValue();
    };

    addDynamicValue() {
        if (this.form.objectForm.info) {
            // если у экстрактора есть не пустое dynamicOptionsAttributeId значит - аттрибут динамический
            const attribute = this.form.objectForm.info;
            this.isDynamic = !!attribute.extractor?.dynamicOptionsAttributeId;
        }

        if (this.form.objectForm.info.extractor.valueType.name !== ValueTypeEnum.ENUM) {
            return;
        }

        //hardcode-ик
        const ifExist = this.form.objectForm.info.extractor.valueOptions.some(item => item.title === 'Динамические значения')
        if (this.isDynamic && this.multiple && !ifExist) {
            this.form.objectForm.info.extractor.valueOptions.push(new ValueOption({
                title: 'Динамические значения',
                key: {id: "333", projectVersionId: "1"},
                apiKey: 'DYNAMIC_OPTION'
            }));
        }
    }

    isSelectValue(): boolean {
        this.addDynamicValue();
        const attribute = this.form.objectForm.info;
        return attribute.extractor && attribute.extractor.valueType.name == ValueTypeEnum.ENUM;
    }

    isDateValue(): boolean {
        const attribute = this.form.objectForm.info;
        const value = this.form.objectForm.value;
        // показать поле ввода даты, когда все данные обновятся
        return attribute.extractor && attribute.extractor.valueType.name == ValueTypeEnum.DATE
            // доп проверка, чтобы опцию или текст не парсить в число
            && value && (value._isAMomentObject || value instanceof Date);
    }

    isInputValue(): boolean {
        const attribute = this.form.objectForm.info;
        return attribute.extractor && [ValueTypeEnum.ANY, ValueTypeEnum.FILE, ValueTypeEnum.FLOAT, ValueTypeEnum.INT]
            .indexOf(attribute.extractor.valueType.name) >= 0;
    }

    /**
     * Сохранить редактируемый элемент
     * @return true - ок, false - ошибка в значении
     */
    finishEdit(): boolean {
        return this.editableListComponent.save();
    }

    /**
     * Ожидаем сообщение о сохранении dkb и принудительно вызываем finishEdit на случай, если пользователь не нажимал кнопку сохранить атрибут
     */
    @HostListener('window:message', ['$event'])
    callFinishEditFromController() {
        this.finishEdit();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
