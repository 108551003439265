import common from "../../../common";

export default class SecurityController {

    constructor(Restangular, TitleService) {
        var that = this;
        that.restAngular = Restangular;
        that.baseUrl = "/account/settings/security/";
        TitleService.setTitle();
        that.header = [
            {title: "Роль", field: "role"}
        ];

        // список DTO
        that.rules = [];
        that.ip = "";

        common.getAccess(that.restAngular, that.baseUrl,  that);
        that.restAngular.one("/account/settings/security")
            .get()
            .then(function successCallback(data) {
                that.rules = data.rules;
                that.ip = data.ipAddress;
            }, function errorCallback(data) {

            });
    }

    // добавить в список
    addNewRule(rule) {
        this.rules.push(angular.copy(rule));
    }

    // удалить из списка
    deleteRule(rule) {
        for (var i = 0; i < this.rules.length; i++) {
            var obj = this.rules[i];
            if (obj.role.name == rule.role.name) {
                // удалить объект из массива
                this.rules.splice(i, 1);
                break;
            }
        }
    }

    // заменить правило по роли
    replaceRule(rule) {
        for (var i = 0; i < this.rules.length; i++) {
            var obj = this.rules[i];
            if (obj.role.name == rule.role.name) {
                // копируем объект, чтобы отвязать объект в списке, от объекта в форме редактирования
                // привязки к людям и витринам не меняются
                this.rules[i] = angular.copy(rule);
                break;
            }
        }
    }
}

SecurityController.$inject = ["Restangular", "TitleService"];