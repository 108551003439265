import {CompositeKey} from "./Common";
import {VaAsrSettings} from "./Voice";
import {VaTag} from "./Tag";
import {EditableObject} from "../../io/components/common/editable-list/editable-list.model";
import {VaAttribute} from "./Attribute";

export enum ValueTypeEnum {
    ANY = "ANY",
    ENUM = "ENUM",
    INT = "INT",
    DATE = "DATE",
    FLOAT = "FLOAT",
    FILE = "FILE",
    ADDRESS = "ADDRESS"
}

export class ValueType {
    name: ValueTypeEnum;
    title: string;
}

export enum ExtractorSuppliedTypeEnum {
    CHANNEL = "CHANNEL",
    BINARY = "BINARY",
    SUCCESS = "SUCCESS",
    CONFIRM = "CONFIRM",
    CITY = "CITY",
    EMAIL = "EMAIL",
    PHONE_NUMBER = "PHONE_NUMBER",
    ADDRESS = "ADDRESS"
}

export class ExtractorSuppliedType {
    name: ExtractorSuppliedTypeEnum;
    title: string;
    required: boolean;
}

export enum ValidatorTypeEnum {
    PHONE = "PHONE",
    E_MAIL = "E_MAIL"
}

export class ValidatorType {
    name: ValidatorTypeEnum;
    title: string;
    required: boolean;
}

export interface MultipleDatesRule {
    name: MultipleDatesRuleEnum
    title: string;
}

export enum MultipleDatesRuleEnum {
    CLOSEST_TO_CURRENT = "CLOSEST_TO_CURRENT",
    FARTHEST_FROM_CURRENT = "FARTHEST_FROM_CURRENT"
}

export interface ImplicitIntervalRule {
    name: ImplicitIntervalRuleEnum
    title: string;
}

export enum ImplicitIntervalRuleEnum {
    PAST = "PAST",
    FUTURE = "FUTURE"
}


export class ValueOption extends EditableObject {
    key: CompositeKey<number>;
    extractorId: number;
    title: string;
    apiKey?: string;
    orderNum: number;
    dtmfNumber: number;
    deleted: boolean;
    infoType: any;
    showButtonInDialog: boolean;
    voiceFileName?: string;
    isDefault: boolean;
    // Фейковое поле для отображения на фронте
    name?: string;
    infoId?: number;
    audioFileId?: number;

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }

        this.key = obj.key;
        this.extractorId = obj.extractorId;
        this.title = obj.title;
        this.apiKey = obj.apiKey;
        this.orderNum = obj.orderNum;
        this.dtmfNumber = obj.dtmfNumber;
        this.deleted = obj.deleted;
        this.infoType = obj.infoType;
        this.showButtonInDialog = obj.showButtonInDialog;
        this.voiceFileName = obj.voiceFileName;
        this.isDefault = obj.isDefault;
        this.name = obj.name;
        this.infoId = obj.infoId;
        this.audioFileId = obj.audioFileId;
    }

    objectToTitle(sources: Map<string, any>): String {
        // todo убрать эту жесть
        return `<span style="width: 40%; display: inline-block;">${this.title}</span>
                <span style="width: 40%; display: inline-block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">${this.apiKey ? this.apiKey : ''}</span>`;
    }


    beforeCreate(sources: Map<string, any>): void {
        this.showButtonInDialog = true;
    }


    validateForm(currentIndex: number, sources: Map<string, any>): Map<string, string> {
        let errors = new Map();
        // необходимо задать значение
        if (!this.title) {
            errors.set("value", "Значение не задано");
        }
        // нельзя задать значение, которое уже есть в списке
        (sources.get("extractor") as Extractor).valueOptions
            .filter((vo, index) => index !== currentIndex)
            .forEach(vo => {
                if (vo.title === this.title) {
                    errors.set("value", "Данное значение уже задано");
                }
                if (this.apiKey && vo.apiKey === this.apiKey) {
                    errors.set("apiKey", "Данный ключ уже задан");
                }
            });
        return errors;
    }


    afterAction(isDelete: boolean, sources: Map<string, any>): void {
        (sources.get("extractor") as Extractor).valueOptions.forEach((vo, index) => {
            vo.dtmfNumber = index + 1;
            vo.voiceFileName = vo.title;
        })
    }


    canDoExternalAction(sources: Map<string, any>): boolean {
        return this.infoId != null && this.key != null;
    }
}

export class Extractor {
    key: CompositeKey<number>;
    name: string;
    valueType: ValueType;
    baseId: number;
    allBaseIds: string;
    suppliedType: ExtractorSuppliedType;
    extractorScriptName: string;
    expertId: number;
    d: Date;
    deleted: boolean;
    valueOptions: ValueOption[];
    copyValueOptions: ValueOption[];
    base: Extractor;
    asrSettings?: VaAsrSettings;
    taggedInfo?: VaTag[];
    suppliedBase: ExtractorSuppliedType;
    isAsr?: boolean;
    baseName: string;
    dynamicOptionsAttributeName: string;
    validatorType: ValueType;
    regExp?: string;
    intervalRule?: ImplicitIntervalRule;
    defaultCity?: string;
    defaultCityAttributeId?: number;
    defaultCityAttributeName?: string;
    houseNecessary: boolean;
    apartmentNecessary: boolean;
    multipleDatesRule?: MultipleDatesRule;
    scriptId?: number;
    dynamicOptionsAttributeId: number;
    classifyTag: boolean;
    extractFromKmq: boolean = true;
    extendedAddressData: boolean = false;

    constructor(obj?: any) {
        if (!obj) {
            return;
        }
        Object.assign(this, obj);
        this.valueOptions = this.valueOptions ? this.valueOptions.map(data => new ValueOption(data)) : [];
        this.copyValueOptions = this.valueOptions.map(data => new ValueOption(data));
        this.classifyTag = this.valueType.name != ValueTypeEnum.ENUM ? false : this.classifyTag;
    }
}

export interface DateExtractorRulesDTO {
    intervalRules: ImplicitIntervalRule[];
    defaultIntervalRule: ImplicitIntervalRule;
    multipleDatesRules: MultipleDatesRule[];
    defaultMultiDatesRule: MultipleDatesRule;
}


