import * as common from "../../../../../js/workplace/common.js";
import restangular from "restangular";
import VaTrendDetectionFilterController from "./VaTrendDetectionFilterController";
import * as moment from 'moment';

export default class VATrendDetectionDataController {

    private restAngular: restangular.IService;
    private filterCtrl: VaTrendDetectionFilterController;
    private scope: ng.IScope;
    private form: { isLoading: boolean; hideSuccess: boolean };
    private pageResult: any[];
    private dialogsPageResult: any[];
    private chosenKeyphrase: string;
    private dialogsWithChosenKeyphrase: any[];
    private chartDataCounts: any[];
    private chartKeyphrase: string;
    private chartDataArr: any[];
    private chartOptions: any;
    private chartConfig: any;
    private chartData: any[];
    private filter: any;
    private access: boolean;
    private result: any;

    constructor(Restangular: restangular.IService, $scope: ng.IScope, $state, $stateParams, $timeout, TitleService) {
        TitleService.setTitle();
        // контроллер формы с фильтрами
        this.filterCtrl = ($scope.$parent as any).ctrl;
        this.restAngular = Restangular;

        this.form = {
            isLoading: false,
            // не нужно выводить успех операции
            hideSuccess: true
        };

        this.scope = $scope;
        // Страница выдачи Трендов
        this.pageResult = [];
        // Страница выдачи списка диалогов по ключевой фразе
        this.dialogsPageResult = [];
        // Выбранная ключевая фраза для просмотра списка диалогов с ней
        this.chosenKeyphrase = "";
        // IDшники диалогов, в которых встретилась выбранная ключевая фраза
        this.dialogsWithChosenKeyphrase = [];
        // Счётчики возникновения ключевой фразы
        this.chartDataCounts = [];
        // Ключевая фраза для построения графика
        this.chartKeyphrase = "";
        // Данные для построения графика (по конкретной ключевой фразе)
        this.chartDataArr = [];
        // Конфиги графика
        this.chartOptions = {
            chart: {
                type: 'lineChart',
                // Не обрезаем по углам точки
                clipEdge: false,
                // По Y отсчёт ведём от нуля
                forceY: [0],
                // Высота в пикселях
                height: 300,
                // Отступы
                margin: {
                    top: 20,
                    right: 40,
                    bottom: 40,
                    left: 55
                },
                x: d => d.x,
                y: d => d.y,
                // Использовать вертикальную штангу с точными значениями
                useInteractiveGuideline: false,
                // Плавное перестроение графиков
                duration: 500,
                // Настройки осей
                xAxis: {
                    tickFormat: d => moment(d).format("DD.MM.YYYY")
                },
                // Не отображать легенду
                showLegend: false
            }
        };
        this.chartConfig = {
            visible: true,
            extended: false,
            disabled: false,
            refreshDataOnly: false,
            deepWatchOptions: true,
            deepWatchData: true,
            deepWatchDataDepth: 2,
            debounce: 10
        };

        this.restAngular.one(this.filterCtrl.trendUrl + "/access").get()
            .then((data) => {
                this.access = data.access;
            }, data => {
            });

        // Angular-nvd3 данные для построения графика, вместе с цветом области и прочими украшательствами
        this.chartData = [];
        // делаем запрос
        this.filterRequest();

        // Следим за изменением массива с ID диалогов, и при его изменении - сбросим страницу на первую
        $scope.$watchCollection('ctrl.dialogsWithChosenKeyphrase', (oldDialogIds, newDialogIds) => {
            this.filter = this.filterCtrl.getFilter();
            this.filter.dialogPage.pageNumber = 1;
            this.dialogsPageChange();
            this.filterCtrl.pushFilterToState();
            if (this.chartKeyphrase.length > 0 && this.dialogsWithChosenKeyphrase.length > 0) {
                this.chartKeyphrase = "";
            }
        });

        // Перерисовываем график после того, как изменилась выбранная ключевая фраза
        $scope.$watch('ctrl.chartKeyphrase', (newChartKeyphrase: string, oldChartKeyphrase) => {
            if (this.chartDataCounts.length > 0 && newChartKeyphrase.length > 0) {
                let keyphrase = this.chartDataCounts.filter(item => item.text === newChartKeyphrase)[0];
                this.chartDataArr = keyphrase.counts;
                this.chartData = [
                    {
                        values: this.chartDataArr,
                        key: this.chartKeyphrase,
                        color: '#2ca02c',
                        area: true
                    }
                ];
                if (this.dialogsWithChosenKeyphrase.length > 0) {
                    this.dialogsWithChosenKeyphrase = [];
                }
            }
        });

        $scope.$on('change-importance-downwards', (event, node) => {
            this.result.forEach(res => this.findNodeByText(res, node.phrase, node.isImportant))
        });
    }

    /**
     * Выполнение запроса на получение диалогов по текущему фильтру
     */
    filterRequest() {
        // получаем текущий фильтр
        this.filter = this.filterCtrl.getFilter();

        // Приведём нумерацию к машинному виду
        let machineTrendPageNumber = this.filter.trendPage.pageNumber - 1;
        // Узнаем где у нас начало и конец
        let trendPageStart = machineTrendPageNumber * this.filter.trendPage.pageSize;
        let trendPageEnd = trendPageStart + this.filter.trendPage.pageSize;

        if (!this.result) {
            common.formOperationWithLoading(
                this.restAngular.one("/account/expert/trenddetection").post("filter", this.filter),
                this.form,
                (data) => {
                    this.result = this.restAngular.stripRestangular(data);
                    // После загрузки произведём подсчёт по дням каждой из ключевых фраз, чтобы потом отображать на графиках
                    data.forEach(item => this.prepareChartData(item, item.date));
                    // Потом соберём все даты и унифицируем результат, чтобы если где-то был 0, то не было бы пропусков
                    let dates = data.map(item => item.date);
                    this.unifyCounts(dates);
                    this.processRequest(trendPageStart, trendPageEnd);
                }, () => {
                }
            );
        } else {
            this.processRequest(trendPageStart, trendPageEnd);
        }
    }

    /**
     * Обработчик выбора другой страницы
     */
    pageChange() {
        // кладем новый измененный фильтр в state
        this.filterCtrl.pushFilterToState();
        // выполняем запрос
        this.filterRequest();
    }

    /**
     *
     */
    dialogsPageChange() {
        // получаем текущий фильтр
        this.filter = this.filterCtrl.getFilter();

        let machineDialogPageNumber = this.filter.dialogPage.pageNumber - 1;
        let dialogPageStart = machineDialogPageNumber * this.filter.dialogPage.pageSize;
        let dialogPageEnd = dialogPageStart + this.filter.dialogPage.pageSize;
        let dialogIds = this.dialogsWithChosenKeyphrase.slice(dialogPageStart, dialogPageEnd);

        if (dialogIds.length > 0) {
            common.formOperationWithLoading(
                this.restAngular.one("/account/expert/dialog").post("loadList", dialogIds),
                this.form,
                (data) => {
                    this.dialogsPageResult = this.restAngular.stripRestangular(data);
                }, () => {
                }
            );
        }

        this.filterCtrl.pushFilterToState();
    }

    /**
     * Обработчик отображаемых данных для страницы
     */
    processRequest(pageStart, pageEnd) {
        this.pageResult = this.result.slice(pageStart, pageEnd);
        this.scope['wrapperCtrl'].setFilter(this.filter, this.result.length);
    }

    formatTime(timestamp) {
        return moment(timestamp).format("DD.MM.YYYY");
    };

    anyUnImportantChildren(node) {
        return node.children.filter(child => !child.important).length > 0;
    };

    prepareChartData(item, date) {
        // Если нет ключевой фразы, то это корень
        if (!item.keyphrase) {
            // Проверяем наличие детей и если они есть - итерируемся
            if (item.children) {
                item.children.forEach(child => this.prepareChartData(child, date));
            }
            return;
        }
        // Если ключевая фраза есть, то всё ок - проверим записывали ли мы такую информацию в chartDataCounts
        let phraseStored = this.chartDataCounts.filter(storedData => storedData.text === item.keyphrase.text).length > 0;
        // Если нет, то добавим целый новый объект
        if (!phraseStored) {
            this.chartDataCounts.push({
                text: item.keyphrase.text,
                counts: [{x: date, y: item.keyphrase.occurredDialogIds.length}]
            });
            // Если да, то добавим только счётчики по дате
        } else {
            this.chartDataCounts
                .filter(storedData => storedData.text === item.keyphrase.text)[0].counts
                .push({x: date, y: item.keyphrase.occurredDialogIds.length});
        }
        // Если у этого айтема есть дети, пробежимся и по ним
        if (item.children) {
            item.children.forEach(child => this.prepareChartData(child, date));
        }
    }

    unifyCounts(dates) {
        // Пробежимся по собранным счётчикам
        this.chartDataCounts.forEach((item) => {
            // И заменим counts так, что если нет count'a соответсвующего определённой дате, то добавим его со значением 0
            item.counts = dates.map((date) => {
                let storedCount = item.counts.filter(count => date === count.x)[0];
                if (!storedCount) {
                    return {x: date, y: 0}
                } else {
                    return storedCount;
                }
            });
        })
    }

    findNodeByText(node, text, important) {
        if (!node.keyphrase && node.children) {
            node.children.forEach(child => this.findNodeByText(child, text, important));
            return;
        }

        if (node.keyphrase.text.trim() === text) {
            node.important = important;
        }

        if (node.children) {
            node.children.forEach(child => this.findNodeByText(child, text, important));
        }
    }
}

VATrendDetectionDataController.$inject = ["Restangular", "$scope", "$state", "$stateParams", "$timeout", "TitleService"];
