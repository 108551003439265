/**
 * Компонент кнопки удаления объектов с подтверждением
 */
import {Component} from "../../../backports";

@Component({
    bindings: {
        confirmCallback: "&onConfirm",
        buttonText: "@",
        questionText: "@",
        // открыть диалог (модалку)
        isDialog: "<",
        // css класс для кнопок
        cssClass: "@",
        // fa класс для кнопок
        faClass: "@",
        // использовать иконки material вместо текста
        material: "<",
        // объект, который передаётся в callback (как item)
        object: "=",
        // подтверждение удаления
        delete: "<"
    },
    templateUrl: "/pages/workplace/elements/buttonwithconfirm/buttonWithConfirm.html",
    controllerAs: "dCtrl"
})
export default class ButtonWithConfirmComponent {

    static $inject = ["$uibModal"];

    private $uibModal: any;
    private isDialog: boolean;
    private material: boolean;
    private delete: boolean;
    private cssClass: string;
    private faClass: string;
    private buttonText: string;
    private questionText: string;
    private object: any;
    private confirmCallback: ({item: any}) => void;
    private showInlineDialog: boolean = false;

    constructor($uibModal: any) {
        this.$uibModal = $uibModal;
        if (typeof this.isDialog == 'undefined') {
            this.isDialog = true;
        }
        if (!this.buttonText) {
            this.buttonText = this.delete ? "Удалить" : "Подтвердить";
        }
        if (!this.questionText) {
            this.questionText = this.delete ? "Вы уверены, что хотите удалить объект?" : "Подтвердите выбранное действие";
        }
    }

    requestForConfirm(): void {
        if (this.isDialog) {
            this.$uibModal.open({
                ariaDescribedBy: 'modal-body',
                templateUrl: '/pages/workplace/elements/buttonwithconfirm/buttonWithConfirmDialog.html',
                controller: 'ButtonWithConfirmModalController',
                controllerAs: '$ctrl',
                size: 'sm',
                appendTo: angular.element(document).find("body"),
                windowTopClass: "button-with-confirm-modal",
                // Проброс изменяемого объекта
                resolve: {
                    confirmCallback: () => {
                        return this.confirmCallback;
                    },
                    object: () => {
                        return this.object;
                    },
                    questionText: () => {
                        return this.questionText;
                    }
                }
            });
        } else {
            this.showInlineDialog = true;
        }
    };

    confirmInlineDialog(): void {
        this.confirmCallback({item: this.object});
        this.showInlineDialog = false;
    };

    cancelInlineDialog(): void {
        this.showInlineDialog = false;
    };
}

