
function InputPatternDirective() {
    return {
        require: 'ngModel',
        scope: {
            patternValue: "="
        },
        link: function (scope, element, attr, ngModelCtrl) {
            let $el = jQuery(element);
            let $parent = $el.parent();


            let pattern = scope.inPat.patternValue;
            if (!pattern.startsWith("^")) {
                pattern = "^" + pattern;
            }
            if (!pattern.endsWith("$")) {
                pattern += "$";
            }
            const regex = new RegExp(pattern);

            function fromUser(text) {
                if(regex.test(text)) {
                    // паттерн сработал
                    $parent.addClass("has-success");
                    $parent.removeClass("has-error");
                } else {
                    $parent.addClass("has-error");
                    $parent.removeClass("has-success");
                }
                return text;
            }
            ngModelCtrl.$parsers.push(fromUser);
        },
        controllerAs: "inPat",
        bindToController: true,
        controller: function() {
        }
    };
}

export default InputPatternDirective;