import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

import {ClusterBuildParams, DialogDumpLoadResult, DumpFileType, Info} from "../../../model/tb-params.model";
import {VersionedDataService} from "../../../base/versioned-data.service";
import {VaTBMessageCluster} from "../../../../../../data/va/Message";
import {AppliedEntityType} from "../../../../entity-usage/entity-usages.model";
import WebSocketService from "../../../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {NamedEntitiesModel} from "../../../model/named-entities.model";


@Injectable()
export class ClusterInfoService extends VersionedDataService<VaTBMessageCluster, number> {

    baseUrl: string = "/account/expert/tagbuilding";

    usageEntityType: AppliedEntityType = AppliedEntityType.TAG;

    readonly entityClass: NamedEntitiesModel.VA_TB_MESSAGE_CLUSTER;

    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }


    async clusterizeGet(sourceTagId: number, script: string): Promise<VaTBMessageCluster[]> {
        return await this.httpClient.get<VaTBMessageCluster[]>(`${this.baseUrl}/${script}${sourceTagId ? '?sourceTagId=' + sourceTagId : ''}`).toPromise();
    }

    async getClusterBuildParams(sourceTagId: number): Promise<ClusterBuildParams> {
        return await this.httpClient.get<ClusterBuildParams>(`${this.baseUrl}/clusterBuildParams${sourceTagId ? '?sourceTagId=' + sourceTagId : ''}`).toPromise();

    }

    async getFileTypes(): Promise<DumpFileType[]> {
        return await this.httpClient.get<DumpFileType[]>(`${this.baseUrl}/dumpFileTypes`).toPromise();
    }

    async getInfo(sourceTagId: number): Promise<Info> {
        return await this.httpClient.get<Info>(`${this.baseUrl}/info${sourceTagId ? '?sourceTagId=' + sourceTagId : ''}`).toPromise();
    }

    async rebuildClusterBuildParams(params: ClusterBuildParams, sourceTagId: number): Promise<ClusterBuildParams> {
        return await this.httpClient.post<ClusterBuildParams>
        (`${this.baseUrl}/rebuildClusterBuildParams${sourceTagId ? '?sourceTagId=' + sourceTagId : ''}`, params).toPromise();
    }

    async uploadMessagesFile(saveDialogs: boolean, file: FormData, fromRow: number, toRow: number, projectVersion: string): Promise<DialogDumpLoadResult> {

        let url = `${this.baseUrl}/loadFile/${projectVersion}?save=${saveDialogs}`;
        // загружать со строки
        if (fromRow) {
            url += '&fromRow=' + fromRow;
        }
        // загружать по строку
        if (toRow) {
            url += '&toRow=' + toRow;
        }
        return await this.httpClient.post<DialogDumpLoadResult>(`${url}`, file).toPromise()
    }

}
