const ImageGalleryComponent = {
    bindings: {
        title: "@",
        imagenames: "<",
        imageurl: "@"
    },
    templateUrl: "/pages/workplace/va/ml/models/image-gallery.html"
};

export default ImageGalleryComponent;

