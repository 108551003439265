/**
 * Created by semyon on 24.06.16.
 */

function DefaultAvatarDirective() {
    return {
        scope: {
            imgClass: "@",
            name: "<",
            avatarUrl: "<"
        },
        bindToController: true,
        controller: ["$scope", function ($scope) {
            this.isLoaded = false;
            this.letters = "";

            $scope.$watch("defaultAvatar.avatarUrl", function () {
                this.letters = "";
                if (!this.name) {
                    return;
                }
                let name = this.name.split(" ");
                for (let i = 0; i < name.length; i++) {
                    let obj = name[i];
                    this.letters += obj.charAt(0).toUpperCase();

                    // но не больше двух букв
                    if (this.letters.length == 2) {
                        break
                    }
                }

                if (!this.avatarUrl || this.avatarUrl.indexOf("default") != -1) {
                    return;
                }

                this.src = this.avatarUrl;
                let img = new Image();
                img.src = this.avatarUrl;
                angular.element(img).bind('load', function () {
                    $scope.$apply(function () {
                        this.isLoaded = true;
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        }],
        templateUrl: "/pages/shared/defaultAvatar.html",
        controllerAs: "defaultAvatar"
    }

}

DefaultAvatarDirective.$inject = [];

export default DefaultAvatarDirective;