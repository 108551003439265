import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VaAttribute} from "../../../../../../data/va/Attribute";
import {ValueTypeEnum} from "../../../../../../data/va/Extractor";
import {MacroObject} from "../../object/MacroObject";
import {
    Currency,
    InflectionCase,
    MarkupSetting,
    MarkupTypeEnum,
    NumeralType,
    PlayingMethod,
    PlayingPart
} from "../../object/Markup";
import {Macro, MacroTypeEnum} from "../../object/Macro";
import {MacroService} from "../../macro.service";
import {
    CustomDashDMYDateParserFormatter,
    CustomDatepickerI18n,
    I18n
} from "../../../erudite-ngb-datepicker/erudite-ngb-datepicker-customizer";
import {NgbDate, NgbDateParserFormatter, NgbDatepickerI18n} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'markup',
    template: require('./markup.component.html'),
    styles: [require('../dialog.component.less')],
    providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: NgbDateParserFormatter, useClass: CustomDashDMYDateParserFormatter}]
})

export class MarkupComponent implements OnInit {

    /**
     * Атрибуты нужного типа
     */
    attributes: MacroObject[] = [];

    inflectionCase: InflectionCase[] = InflectionCase.VALUES;
    playingMethod: PlayingMethod[] = PlayingMethod.VALUES;
    playingPart: PlayingPart[] = PlayingPart.VALUES;
    numeralType: NumeralType[] = NumeralType.VALUES;
    currency: Currency[] = Currency.VALUES;

    settings: string[] = [];

    constructor(protected dialogRef: MatDialogRef<MarkupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { macro: Macro }, protected macroService: MacroService) {
        this.settings = data.macro.markupType.getSettingNames();
        if (!data.macro.markupSettings) {
            data.macro.markupSettings = new MarkupSetting();
        }
        switch (data.macro.markupType.name) {
            case MarkupTypeEnum.NUMBER:
            case MarkupTypeEnum.MONEY:
                this.prepareNumberAttributes();
                break;
            case MarkupTypeEnum.DATE:
                this.data.macro.markupSettings.value = MarkupComponent.getDate();
                this.prepareDateAttributes();
                break;
            default:
                break;
        }
    }

    private static getDate(): NgbDate {
        let d: Date = new Date();
        return new NgbDate(d.getFullYear(), d.getMonth() + 1, d.getDate());
    }

    prepareNumberAttributes(): void {
        this.macroService.getEntities().subscribe(result => {
            this.attributes = result.get(MacroTypeEnum.ATTRIBUTE)
                .resultEntities
                .filter(attribute => [ValueTypeEnum.FLOAT, ValueTypeEnum.INT].indexOf((attribute as VaAttribute).extractor.valueType.name) >= 0);
        });
    }

    prepareDateAttributes(): void {
        this.macroService.getEntities().subscribe(result => {
            this.attributes = result.get(MacroTypeEnum.ATTRIBUTE)
                .resultEntities
                .filter(attribute => [ValueTypeEnum.DATE].indexOf((attribute as VaAttribute).extractor.valueType.name) >= 0);
        });
    }

    ngOnInit(): void {
    }

    onDeny(): void {
        this.dialogRef.close();
    }

    onSave(): void {
        let validatorErrors = new Map<string, string>();
        const markupType = this.data.macro.markupType;
        const settings = this.data.macro.markupSettings;
        switch (markupType.name) {
            case MarkupTypeEnum.BREAK:
                settings.value = this.validateNumber(settings.value as string, validatorErrors);
                break;
            case MarkupTypeEnum.NUMBER:
            case MarkupTypeEnum.MONEY:
                if (!settings.attribute) {
                    settings.value = this.validateNumber(settings.value as string, validatorErrors);
                }
                break;
            default:
                break;
        }
        this.data.macro.markupSettings.errors = validatorErrors;
        if (validatorErrors.size == 0) {
            this.data.macro.conditions = this.data.macro.markupSettings.getConditions(this.data.macro.markupType, this.settings);
            if (this.data.macro.markupSettings.attribute) {
                this.data.macro.attributes = [this.data.macro.markupSettings.attribute as VaAttribute];
            }
            this.dialogRef.close(this.data.macro);
        }
    }

    validateNumber(value: string, validatorErrors: Map<string, string>): string {
        if (!value) {
            validatorErrors.set("value", "Поле обязательно для заполнения");
            return value;
        } else {
            const decimalValue = value.replace(",", ".");
            const matchNumber = decimalValue.match(new RegExp("^[0-9.]*$"));
            const number = Number(decimalValue);
            if (!matchNumber || !number) {
                validatorErrors.set("value", "Введите корректное значение");
                return value;
            }
            return number + "";
        }
    }

    onAttributeSelect(): void {
        this.data.macro.markupSettings.value = null;
        if (this.data.macro.markupType.name === MarkupTypeEnum.DATE && this.data.macro.markupSettings.attribute == null) {
            let d: Date = new Date();
            this.data.macro.markupSettings.value = MarkupComponent.getDate();
        }
    }

    getValueTitle(): string {
        switch (this.data.macro.markupType.name) {
            case MarkupTypeEnum.BREAK:
                return "Продолжительность паузы, сек.";
            case MarkupTypeEnum.NUMBER:
                return "Заданное число";
            case MarkupTypeEnum.DATE:
                return "Дата";
            case MarkupTypeEnum.MONEY:
                return "Заданное значение суммы";
            default:
                throw new Error("Unexpected markup type");
        }
    }
}


