import {Component, Input, OnInit} from "@angular/core";
import {ReplyView} from "../model/reply.model";
import {VaScriptEdge, VaScriptNode} from "../../../../data/va/Script";
import {ExtractedValue} from "../model/extracted-value.model";
import {VaScriptCondition} from "../../../../data/va/VaScriptCondition";

@Component({
    selector: 'reply-checkpoint',
    template: require('./reply-checkpoint.component.html'),
    styles: [require('./reply-checkpoint.component.less')]
})

export class ReplyCheckpointComponent implements OnInit {

    @Input()
    private reply: ReplyView;

    constructor() {
    }

    ngOnInit(): void {
    }

    getFromNode(edge: VaScriptEdge): VaScriptNode {
        if (!this.reply.reply.script) {
            return null;
        }
        return this.reply.reply.script.nodes.find(node => node.key.id === edge.fromNodeId);
    }

    isAddEntityName(edge: VaScriptEdge): boolean {
        // если на ребре больше одного условия, или не по сущности, из которой вышли, добавляем название сущности
        return edge.conditions.length > 1 || edge.conditions[0].entityId !== this.getFromNode(edge)?.stepLabel.entityId + '';
    }

    getExtractedValue(condition: VaScriptCondition): ExtractedValue {
        return this.reply.reply.extractedValues ? this.reply.reply.extractedValues.find(extractedValue => extractedValue.entityId + '' === condition.entityId) : null;
    }

    isShowConditionEntityTitle(edge: VaScriptEdge, condition: VaScriptCondition): boolean {
        return this.isAddEntityName(edge) || !this.getExtractedValue(condition);
    }
}

