import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import {LoginsFilterResult} from "../journals.model";
import {JournalsService} from "../journals.service";


@Injectable()
export class LoginsService extends JournalsService<LoginsFilterResult>{

    baseUrl: string = "/account/loginjournal";

    constructor(protected httpClient: HttpClient, protected stateService: StateService) {
        super(httpClient, stateService);
    }
}