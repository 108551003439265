import {NgModule} from "@angular/core";
import {TaggingItemComponent} from "./tagging-item.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";

@NgModule({
    declarations: [
        TaggingItemComponent
    ],
    imports: [
        NgSelectModule,
        FormsModule
    ],
    exports: [
        TaggingItemComponent
    ]
})
export class TaggingItemModule {

}