import urls from "../../../urls";
import common from "../../../common";
// контроллер страницы со списком правил блокировки
export default class AntifraudBlockUsersController {

    constructor(Restangular, $state, TitleService) {
        var that = this;
        TitleService.setTitle();
        that.$state = $state;
        that.header = [
            {title: "Ключ", field: "key"}
        ];

        that.restAngular = Restangular;
        that.baseUrl = urls.chat.antifraudblockuser;

        // список DTO
        common.loadValues(that, that.baseUrl, "users", null, true);
        common.getAccess(that.restAngular, that.baseUrl,  that);

    }
}

AntifraudBlockUsersController.$inject = ["Restangular", "$state", "TitleService"];
