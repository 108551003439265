export class ChatAccountData {
    id: number;
    account: Account;
    status: Status;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.id = object.id;
        this.account = object.account;
        this.status = object.status;
    }
}

export class Account {
    id: number;
    fullName: string;
    login: string;
    password: string;
    changedPassword: string;
    deleted: boolean;
    blocked: boolean;
    role: AccountRole;
    expertType: ExpertType;
    adminType: AdminType;
    robotProjects: string[];
    creationTime: any;
    useLdapLogin: boolean;
    visible: boolean;
    isDefaultAdmin: boolean;

    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.id = object.id;
        this.fullName = object.fullName;
        this.login = object.login;
        this.password = object.password;
        this.changedPassword = object.changedPassword;
        this.deleted = object.deleted;
        this.blocked = object.blocked;
        this.role = object.role;
        this.expertType = object.expertType;
        this.adminType = object.adminType;
        this.robotProjects = object.robotProjects;
        this.creationTime = object.creationTime;
        this.useLdapLogin = object.useLdapLogin;
        this.visible = true;
        this.isDefaultAdmin = object.isDefaultAdmin;
    }
}

export interface AccountRole {
    id: number;
    description: string;
    action: string;
    name: string;
}

export interface ExpertType {
    id: number;
    title: string;
    name: string;
}

export interface AdminType {
    id: number;
    title: string;
    name: string;
}

export interface Status {
    title: string;
}

export class AccountFilter {
    role: AccountRole = null;
    project: string[] = null;
    isBlocked: boolean = false;

    constructor() {
    }
}