import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    template: require('./export-scenario.component.html'),
    styles: [require('./export-scenario.component.less')]
})
export class ExportScenario {

    constructor(public dialogRef: MatDialogRef<ExportScenario>, @Inject(MAT_DIALOG_DATA) public data: {image: string, scenarioName: string}) {
        
    }

    download() {

        // функция для сохранения картинки
        const downloadURI = (uri, name) => {
            let link = document.createElement("a");
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        // скачиваем картинку
        downloadURI(this.data.image, this.data.scenarioName);
        
        // закрываем диалог
        this.dialogRef.close();
    }

}