import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DenyButtonComponent} from "./deny-button.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {EruditeIconModule} from "../erudite-icon/erudite-icon.module";

@NgModule({
    declarations: [
        DenyButtonComponent
    ],
    imports: [
        CommonModule,
        AngularSvgIconModule,
        EruditeIconModule
    ],
    exports: [
        DenyButtonComponent
    ]
})
export class DenyButtonModule {

}