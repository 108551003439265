import {Injectable} from "@angular/core";
import WebSocketService from "../../../../../../services/WebSocketService";
import {PushNotificationService} from "./push-notification.service";


@Injectable()
export class LongOperationService {

    // noinspection JSUnusedLocalSymbols
    constructor(private webSocketService: WebSocketService, private pushNotificationService: PushNotificationService) {
    }

    /**
     * Подписаться на завершение операции
     *
     * @param subscribedKey ключ операции
     * @param listener действие по завершению
     */
    public subscribeToFinish(subscribedKey: LongOperationKey, listener: () => void): string {
        return subscribeToLongOperationFinish(this.webSocketService, subscribedKey, listener);
    }

    /**
     * Отписаться
     *
     * @param subscriptionId id подписки
     */
    public removeListener(subscriptionId: string): void {
        this.webSocketService.removeListener(subscriptionId);
    }
}

/**
 * Подписаться на окончание продолжительного действия.
 * Для частей на первом ангуляре, для нового лучше через сервис метода, заодно на пуш-уведомления подписка будет при инжекте.
 *
 * @param webSocketService вебсокет-сервис
 * @param subscribedKey ключ действия
 * @param callback колбэк
 */
export function subscribeToLongOperationFinish(webSocketService: WebSocketService, subscribedKey: LongOperationKey, callback: () => void) {
    return webSocketService.subscribeOnEvents({
        eventType: 'LONG_OPERATION_FINISHED',
        fn: event => {
            const receivedKey: LongOperationKey = JSON.parse(event.details);
            if (receivedKey.id === subscribedKey.id && receivedKey.type == receivedKey.type) {
                callback();
            }
        }
    });
}

/**
 * Ключ продолжительной операции
 */
export interface LongOperationKey {
    id: any;
    type: LongOperationType;
}

/**
 * Тип продолжительной операции
 */
export enum LongOperationType {
    EXPORT_VERSION = 'EXPORT_VERSION',
    COLLECT_REPORT = 'COLLECT_REPORT',
    EXPORT_DIALOGS = 'EXPORT_DIALOGS'
}

