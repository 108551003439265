import * as urls from "../../../../../../js/workplace/urls.js";
import * as common from "../../../../../../js/workplace/common.js";
import {VABaseListController} from "../../../../../../js/workplace/controllers/va/VABaseListController";
import {DKBEntity, DKBField} from "../../../../data/va/Dkb";
import * as restangular from "restangular";

export default class VaDkbFieldController extends VABaseListController<DKBField> {

    static $inject = ["Restangular", "$stateParams", "TitleService", "$state"];

    private entity: DKBEntity;
    private entityId: number;

    constructor(Restangular: restangular.IService, $stateParams: ng.ui.IStateParamsService, TitleService: any, $state: ng.ui.IStateService) {
        const entityId = $stateParams['dkbEntityId'];

        // загружать свойства нужно по id сущности /dkb/${id}/fields
        super(TitleService, Restangular, $stateParams, urls.va.dkbField, () => {
            Restangular.one(urls.va.dkb, entityId).getList("fields")
                .then(data => {
                    this.objects = data;
                }, data => {
                    // если произошла ошибка при загрузке, то перейдем к дкб сущностям
                    common.goToParentState($state);
                });
        });

        this.header = [
            {title: "Название", field: "name"}
        ];

        // сохраняем id сущности
        this.entityId = entityId;

        // загружаем сущность
        Restangular.one(urls.va.dkb, entityId).get()
            .then(data => {
                this.entity = data;
            }, data => {
            });
        this.getAccess();
    }
}