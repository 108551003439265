import RectangleElement from "./RectangleElement";
import {VaScriptNode} from "../../../../../data/va/Script";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {ui} from "@naumen/rappid/build/rappid";
import HandlePosition = ui.Halo.HandlePosition;

export class TagElement extends RectangleElement {

    /**
     * Callback на открытие сценария
     */
    public onScenarioOpen: any;

    constructor(attributes: {node: VaScriptNode}) {
        super(attributes);

        mergeDeep(this.attributes, {
            attrs: {
                body: {
                    fill: "#FF851B",
                    stroke: "#FF851B",
                },
                label: {
                    fill: "#FFFFFF",
                }
            }
        });

        if (this.node.entity?.tagScenarioId) {
            this.handles.push({
                name: 'open-scenario-icon',
                position: HandlePosition.SE,
                events: {pointerdown: () => this.onScenarioOpen(this.node.entity?.tagScenarioId)},
            });
        }
    }

    get isDialogable(): boolean {
        return false;
    }

    public isNavigable(): boolean {
        return true;
    }
}