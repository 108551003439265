export default class PushStatusController {

    constructor($rootScope, $scope, Restangular, TitleService) {
        var that = this;
        TitleService.setTitle();
        that.restAngular = Restangular;
        that.messages = [];

        that.init = function() {

            that.restAngular.one("/account/push/getMessages/").get()
                .then(function success(data) {
                        that.data = data;
                    }, function error(data) {
                    }
                )
        };

        that.init();
    }

}

PushStatusController.$inject = ["$rootScope", "$scope", "Restangular", "TitleService"];

