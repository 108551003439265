import restangular from "restangular";
import * as common from "../../../../js/workplace/common.js";
import VaAnalysisFilterController from "./VaAnalysisFilterController";
import {DialogFilterParam, FilterForm, SelectionModalMode} from "./Analysis";
import WebSocketService from "../../services/WebSocketService";
import {LongOperationType, subscribeToLongOperationFinish} from "../../io/components/common/snackbar/notification/long-operation/long-operation.service";
import VaAnalysisGridComponent from "../../components/analysis/VaAnalysisGridComponent";

export default class VaAnalysisDataController {

    static $inject = ["Restangular", "$scope", "$state", "$stateParams", "TitleService", "WebSocketService", "FileSaver", "FileUploader", "$http", "$cookies"];

    private restAngular: restangular.IService;
    private $scope: ng.IScope;
    private $stateParams: ng.ui.IStateParamsService;
    private $state: ng.ui.IStateService;
    private readonly tagIdForSubtreeFiltering: number;
    private readonly singleTagIdForFiltering: number;
    private filterCtrl: VaAnalysisFilterController;
    private $uibModal;
    private form: {
        isLoading: boolean,
        // не нужно выводить успех операции
        hideSuccess: boolean
        success?: boolean;
        errors: any
    } = {
        isLoading: false,
        hideSuccess: true,
        errors: {}
    };
    private filter: DialogFilterParam;
    private filterForm: FilterForm;
    public result: any[];
    public totalResult: number;
    public showUpload: boolean;
    private uploadForm: any;
    private fileSaver: any;
    private readonly projectVersionId: string;
    private httpService: any;
    private cookieService: any;
    private exportDialogsBlocked: boolean;
    private webSocketService: WebSocketService;

    constructor(Restangular, $scope, $state, $stateParams, TitleService, WebSocketService: WebSocketService, FileSaver: any, FileUploader: any, $http: angular.IHttpService, $cookies, $uibModal) {
        TitleService.setTitle();
        this.restAngular = Restangular;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.fileSaver = FileSaver;
        this.httpService = $http;
        this.cookieService = $cookies;
        this.webSocketService = WebSocketService;

        this.projectVersionId = $stateParams['projectVersionId'];

        // контроллер формы с фильтрами
        this.filterCtrl = $scope.$parent.ctrl;

        this.tagIdForSubtreeFiltering = $stateParams['tagIdForSubtreeFiltering'];
        this.singleTagIdForFiltering = $stateParams['singleTagIdForFiltering'];

        // делаем запрос
        this.filterRequest();

        this.uploadForm = {
            message: "",
            isLoading: false,
            upload: new FileUploader({
                url: `${this.filterCtrl.baseUrl}importDialogs?projectVersionId=${this.projectVersionId}`,
                removeAfterUpload: true,
                queueLimit: 1,
                headers: {
                    "X-XSRF-TOKEN": this.cookieService.get("XSRF-TOKEN"),
                },
            }),
        };

        // кнопка экспорта диалогов заблокирована, если экспорт в процессе
        // noinspection JSIgnoredPromiseFromCall
        this.initExportBlockButton();
        // при пуше о финише экспорта разблокируем кнопку
        const subscriptionId = subscribeToLongOperationFinish(this.webSocketService, {type: LongOperationType.EXPORT_DIALOGS, id: this.projectVersionId}, () => this.exportDialogsBlocked = false);
        // В деструкторе отписываемся
        this.$scope.$on("$destroy", () => this.webSocketService.removeListener(subscriptionId));
    }

    /**
     * Проинициализировать доступность кнопки экспорта
     */
    private async initExportBlockButton() {
        this.exportDialogsBlocked = await this.restAngular.one(`${this.filterCtrl.baseUrl}isExportBlocked`).get()
            .then(data => this.restAngular.stripRestangular(data).blocked);
    }

    /**
     * Выполнение запроса на получение диалогов по текущему фильтру
     */
    filterRequest(): void {
        // получаем текущий фильтр
        this.filter = this.filterCtrl.getFilter();
        this.filterForm = this.filterCtrl.object;
        if (this.tagIdForSubtreeFiltering) {
            this.filter.viewType = 'LIST';
            this.filter.tagIdForSubtreeFiltering = this.tagIdForSubtreeFiltering;
        }
        if (this.singleTagIdForFiltering) {
            this.filter.viewType = 'LIST';
            this.filter.singleTagIdForFiltering = this.singleTagIdForFiltering;
        }

        // Проставим в форму фильтрации значение для "вид для просмотра"
        this.filterCtrl.object.viewType = this.filter.viewType;

        common.formOperationWithLoading(
            this.restAngular.one(this.filterCtrl.baseUrl).post("filter", this.filter),
            this.form,
            (data) => {
                if (this.filter.viewType == 'TAGS') {
                    if (data != null) {
                        this.result = data;
                    }
                } else {
                    this.result = data.rows;
                    this.totalResult = data.totalDocs;
                }
                const previousFilter = localStorage.getItem("eruditeStatFilter");
                const currentFilter = JSON.stringify(this.filter);
                if (currentFilter != previousFilter) {
                    // если фильтр поменялся, очищаем сохраненное состояние таблицы с диалогами
                    VaAnalysisGridComponent.clearSavedGridState();
                }
                localStorage.setItem("eruditeStatFilter", currentFilter);
                localStorage.setItem("eruditeStatFilterForm", JSON.stringify(this.filterForm));
            }, () => {
                this.result = null;
                this.totalResult = 0;
            }
        )
    }

    /**
     * Открыть модальное окно для создания новой выборки по текущему фильтру
     */
    createSelection(): void {
        this.filterCtrl.openSelectionModal(SelectionModalMode.CREATE);
    }

    /**
     * Пусты ли результаты с бэка
     */
    get emptyResults(): boolean {
        return (this.result == null || this.result.length == 0) && !this.form.isLoading && this.form.success
    }

    toggleImportDialogs() {
        this.showUpload = !this.showUpload;
    }

    /**
     * Вывести сообщение об ошбке
     * @param error
     */
    private setError(error: string) {
        this.form.errors["#main"] = error;
    }

    /**
     * Импортировать диалоги
     */
    importDialogs() {
        this.uploadForm.upload.onCompleteItem = (item, response, status) => {
            this.form.isLoading = false;

            this.showUpload = false;
            // перезапрашиваем стату
            this.filterRequest();

            if (response.result) {
                // успешный импорт
                return;
            }
            if (status != 200) {
                this.setError(`Не удалось загрузить файл. Код ответа: ${status}`);
            } else if (response.errors && response.errors[0] && response.errors[0].message) {
                this.setError(response.errors[0].message);
            } else {
                this.setError('Произошла ошибка при загрузке файла');
            }
        };

        const items = this.uploadForm.upload.getNotUploadedItems().filter(item => !item.isUploading);
        if (items.length) {
            this.result = null;
            this.form.isLoading = true;
            this.setError(null);
            this.uploadForm.upload.uploadAll();
        }
    }

    /**
     * Экспортировать диалоги
     */
    exportDialogs() {
        if (this.exportDialogsBlocked) {
            return
        }
        this.setError(null);
        this.filter = this.filterCtrl.getFilter();
        this.form.isLoading = true;
        this.exportDialogsBlocked = true;
        this.httpService.post(`${this.filterCtrl.baseUrl}exportDialogs?projectVersionId=${this.projectVersionId}`, this.filter).error((data, status) => {
            this.setError("Не удалось скачать файл. Код ответа: " + status);
        }).finally(() => {
            this.form.isLoading = false;
        });
    }


}
