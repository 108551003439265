import {VaTag} from "../../../../../data/va/Tag";

export interface MacroObject {

    getTitle(): string;

    getId(): string;

    getTaggedInfo(): VaTag[];
}

export class MacroEntity {
    entities: MacroObject[] = [];
    resultEntities: MacroObject[] = [];
    taggedEntities: MacroObject[] = [];
    groupedEntities: Map<string, MacroObject[]>;
    showTaggedEntities: boolean;

    constructor() {
    }
}



