import {StepLabel, StepLabelEntity, StepLabelTypeName, VaScriptNode} from "../../../data/va/Script";
import {dia, util} from "@naumen/rappid/build/rappid";
import {ValueOption, ValueTypeEnum} from "../../../data/va/Extractor";
import {MacroService} from "../common/macro/macro.service";
import {ChannelText} from "../../../data/va/Formulation";
import {EruditeFile} from "../../models/erudite-file.model";
import Size = dia.Size;

/**
 * Ячейка решетки
 */
export const GRID_SIZE = 10;
/**
 * Максимальная ширина блока
 */
const defaultMaxWidth = 300;
/**
 * Минимальная ширина блока
 */
const defaultMinWidth = 160;
/**
 * Отступы по ширине
 */
const defaultWidthPadding = 20;
/**
 * Отступы по высоте
 */
const defaultHeightPadding = 47;
/**
 * Ширина символа для шрифта размером 14
 */
const defaultLetterWidth = 8;
/**
 * Высота символа
 */
const defaultFontSize = 16;
/**
 * Настройки шрифта по-умолчанию
 */
const defaultFontConfig = {'font-size': defaultFontSize, 'font-family': 'RobotoLight'};

/**
 * Максимальная длина текста значения атрибута
 */
const maxAttrValueDescLen = 100;

/**
 * Максимальная ширина строки по-умолчанию
 */
const maxLineLength = 30;

const regex = new RegExp(`([a-zA-Z0-9\\s_\\\\.\\-\\(\\):])+(.+)$`)

/**
 * Разбить текст переводами строки ('\n') для отображения в SVG
 * @param text          текст
 * @param maxWidth      ширина текста
 * @param containerSize размеры контейнера в пикселях, куда должен быть вписан текст
 * @param fontConfig    настройки шрифта, которыми этот текст будет отрисован
 */
export function wrapText(text: string, maxWidth: number, containerSize?: any, fontConfig?: any) {
    let size = containerSize ? containerSize : {width: maxWidth - defaultWidthPadding};
    let attrs = fontConfig ? fontConfig : defaultFontConfig;
    return util.breakText(text, size, attrs)
}

/**
 * Получить цвет фона для превью drag'n'drop'a
 */
export function getDragPreviewStyle(entity: StepLabelEntity): any {
    const type: StepLabelTypeName = entity.type.name;
    const {width, height} = getNodeSize(entity.dragPreviewContent, false);
    // курсор - сверху по центру перетаскиваемого блока
    const offset = `${-width / 2}px`;
    switch (type) {
        case StepLabelTypeName.ATTRIBUTE_REQUEST:
            return {'background-color': '#02B2CC', 'width': `${width}px`, 'height': `${height}px`, 'margin-left': offset};
        case StepLabelTypeName.ANSWER_RESPONSE:
            return {'background-color': '#02b254', 'width': `${width}px`, 'height': `${height}px`, 'margin-left': offset};
        case StepLabelTypeName.ENTER:
            return {'background-color': '#D23204', 'width': `${width}px`, 'height': `${height}px`, 'margin-left': offset};
        case StepLabelTypeName.PROCEDURE:
        case StepLabelTypeName.EXIT:
            return {
                'background-color': '#FFFFFF',
                'color': '#D23204',
                'border': '2px dashed #D23204',
                'width': `${width}px`,
                'height': `${height}px`,
                'margin-left': offset
            };
        case StepLabelTypeName.TO_SUPPORT:
            return {'background-color': '#F6027A', 'width': `${width}px`, 'height': `${height}px`, 'margin-left': offset};
        case StepLabelTypeName.START:
            return {'background-color': '#D23204', 'width': `${width}px`, 'height': `${height}px`, 'margin-left': offset};
        case StepLabelTypeName.TO_TAG:
            return {'background-color': '#FF851B', 'width': `${width}px`, 'height': `${height}px`, 'margin-left': offset};
    }
    return '';
}

/**
 * Получить текст для отображения при drag'n'drop'e
 * @param entity сущность
 */
export function getDragPreviewContent(entity: StepLabelEntity): string {
    const text = getEntityNodeText(entity);
    return wrapText(text, defaultMaxWidth);
}

/**
 * Когда блок начали "тянуть" проставим ему стиль и текст
 * @param entity сущность
 */
export function setBlockView(entity: StepLabelEntity) {
    entity.dragPreviewContent = getDragPreviewContent(entity);
    entity.dragPreviewStyle = getDragPreviewStyle(entity);
}

/**
 * Получить размеры элемента': если размер не задан, то рассчитать по тексту
 * @param text текст
 * @param maxWidth ширина текста
 * @param square элемент равносторонний
 */
export function getItemSize(text: string, maxWidth: number, square: boolean): Size {
    // Если высоты нет, то считаем размер узла по тексту: разбиваем текст пробелами
    const lines: string[] = wrapText(text, maxWidth).split("\n");
    // Находим самую длинную строчку
    const maxLineLength: number = Math.max(...lines.map(line => line.length));
    // Считаем длину самой длинной строчки в пикселях
    const calculatedWidth = defaultWidthPadding + (maxLineLength * defaultLetterWidth);
    // Если длина больше максимально разрешённой, то скидываем до макс. разрешённой
    const resultWidth = calculatedWidth > defaultMaxWidth ? defaultMaxWidth : calculatedWidth;
    const resultHeight = defaultHeightPadding + (lines.length * defaultFontSize);
    return {
        width: ceil(square ? resultHeight : resultWidth < defaultMinWidth ? defaultMinWidth : resultWidth, GRID_SIZE * 2),
        height: ceil(resultHeight, GRID_SIZE * 2)
    };
}

/**
 * @param value число для округления
 * @param precision точность
 */
export function ceil(value: number, precision: number): number {
    return Math.ceil(value / precision) * precision;
}

/**
 * Получить размеры узла: если размер не задан, то рассчитать по тексту
 * @param text текст
 * @param square элемент равносторонний
 */
export function getNodeSize(text: string, square: boolean): Size {
    return getItemSize(text, defaultMaxWidth, square);
}

/**
 * Получить размеры текста условий на стрелке: если размер не задан, то рассчитать по тексту
 * @param text текст
 */
export function getEdgeLabelSize(text: string): Size {
    return getItemSize(text, defaultMinWidth, false);
}

/**
 * Цвет подсветки
 */
export const HIGHLIGHT_COLOR = '#F80077';

/**
 * Стиль пунктира обводки узлов
 */
export const STROKE_DASH = '12,8';

/**
 * Получить цвет для ребра по stepLabelType'у
 * @param stepLabelType тип перехода
 * @param passed
 * @param stuck
 */
export function getLinkColor(stepLabelType: StepLabelTypeName, passed: boolean, stuck: boolean) {
    if (passed) {
        return '#0000ff';
    }
    if (stuck) {
        return '#FF0000';
    }
    switch (stepLabelType) {
        case StepLabelTypeName.START:
        case StepLabelTypeName.PROCEDURE:
        case StepLabelTypeName.ENTER:
        case StepLabelTypeName.EXIT:
            return '#D53300';
        case StepLabelTypeName.ANSWER_RESPONSE:
            return '#00B152';
        default:
            return '#00B3CB';
    }
}

/**
 * Получить цвет для ребра по stepLabelType'у
 * @param stepLabelType тип перехода
 */
export function getStrokeDash(stepLabelType: StepLabelTypeName) {
    switch (stepLabelType) {
        case StepLabelTypeName.PROCEDURE:
        case StepLabelTypeName.ENTER:
        case StepLabelTypeName.EXIT:
            return STROKE_DASH;
        default:
            return '1,0';
    }
}

export function isTechnicalNode(node: VaScriptNode) {
    return [StepLabelTypeName.START, StepLabelTypeName.ENTER].includes(node.stepLabel.type.name);
}

export function applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            Object.defineProperty(derivedCtor.prototype, name, Object.getOwnPropertyDescriptor(baseCtor.prototype, name));
        });
    });
}

/**
 * Читаемое отображение значения
 *
 * @param value значение атрибута
 * @param attribute атрибут
 */
export function formatAttributeValue(value: string, attribute: StepLabelEntity) {
    let title: string;
    switch (attribute.valueType.name) {
        case ValueTypeEnum.ENUM:
            // найдем опцию
            const option: ValueOption = attribute.options.find(option => {
                return option.key.id.toString() == value;
            });
            if (option) {
                // нашли, берем заголовок
                title = option.title;
            } else {
                // не нашли, отображаем id
                title = value;
            }
            break;
        case ValueTypeEnum.ANY:
        case ValueTypeEnum.INT:
        case ValueTypeEnum.DATE:
        case ValueTypeEnum.FLOAT:
        case ValueTypeEnum.ADDRESS:
        case ValueTypeEnum.FILE:
            // все остальное можно показывать как есть
            title = value == null ? 'null' : value.toString();
            break;
        default:
            throw 'unsupported attribute value type' + attribute.valueType.name;

    }
    if (title.length > maxAttrValueDescLen) {
        // значение слишком длинное, урежем
        title = title.substr(0, maxAttrValueDescLen) + '...';
    }
    return title;
}

/**
 * Рассчитать смещение относительно центра на указанный множитель (больше 1 или меньше)
 */
export function calcOffset(source: number, center: number, factor: number): number {
    // исходная дистанция
    const before = source - center;
    // дельта
    return before * factor - before;
}

/**
 * Типы логгируемых действий
 */
export type ClickAction =
    'undo' |
    'redo' |
    'layout_vertical_incline' |
    'layout_vertical_orthogonal' |
    'layout_horizontal_incline' |
    'layout_horizontal_orthogonal' |
    'incline_enable' |
    'incline_disable' |
    'spacing_increase' |
    'spacing_decrease' |
    'spacing_increase_vertical' |
    'spacing_decrease_vertical' |
    'spacing_increase_horizontal' |
    'spacing_decrease_horizontal' |
    'copy' |
    'cut' |
    'paste' |
    'select_all' |
    'picture_download';

/**
 * Для параметров изменения пространства между узлами
 */
export class ChangeSpacingParams {
    public readonly horizontalFactor: number;
    public readonly verticalFactor: number;
    public readonly action: ClickAction;
}

/**
 * Найти все номера вхождений значения в массиве
 */
export function getAllINums(arr, val) {
    let indexes = [], i;
    for (i = 0; i < arr.length; i++)
        if (arr[i] === val)
            indexes.push(i + 1);
    return indexes;
}

/**
 * Является ли узел стартовым/входным
 */
export function isRootNode(node: any): boolean {
    return node?.stepLabel?.type.name == StepLabelTypeName.START || node?.stepLabel?.type.name == StepLabelTypeName.ENTER;
}

/**
 * Получить текст для отображения в узле
 *
 * @param node узел
 */
export function getNodeText(node: VaScriptNode): string {
    return getEntityNodeText(node.entity, node.stepLabel);
}

/**
 * Получить текст для отображения в узле по сущности узла
 */
function getEntityNodeText(entity: StepLabelEntity, label?: StepLabel): string {
    switch (entity.type.name) {
        case StepLabelTypeName.ENTER:
        case StepLabelTypeName.START:
        case StepLabelTypeName.TO_TAG:
            // тип сущности без формулировок
            return entity.title;
        case StepLabelTypeName.PROCEDURE:
            return `Процедура ${entity.title}`;
        case StepLabelTypeName.EXIT:
            return `Выход ${label?.name ? label?.name : ''}`;

        case StepLabelTypeName.ANSWER_RESPONSE:
        case StepLabelTypeName.ATTRIBUTE_REQUEST:
        case StepLabelTypeName.TO_SUPPORT:
            return getFormulationNodeText(entity, label);
    }
}

/**
 * Получить текст для отображения в узле c формулировкой
 */
function getFormulationNodeText(entity: StepLabelEntity, label?: StepLabel): string {
    if (label?.value && entity.type.name == StepLabelTypeName.ATTRIBUTE_REQUEST) {
        // задано значение атрибута
        return entity.title + ' = ' + formatAttributeValue(label?.value, entity);
    }
    // тип сущности с формулировкой, инициализация первой в списке
    const formulationId = label ? label.subId : entity.formulations.length > 0 ? entity.formulations[0].key.id : null;
    if (!formulationId || !entity.formulations.length) {
        // формулировка не задана
        return `${entity.title}:\n${(entity.formulations.length ? 'любая формулировка' : 'пустая формулировка')}`;
    }
    // задана формулировка, ищем ее
    const formulation = entity.formulations.find(formulation => formulation.key.id == formulationId);
    if (!formulation) {
        // не нашли
        return `${entity.title}:\nудаленная формулировка`;
    }
    // ищем текст дефолтного канала
    let channelText = formulation.channelTexts?.find(text => text.channelValueOption.isDefault);
    if (!channelText) {
        // не нашли канал, берем первый
        channelText = formulation.channelTexts?.find(text => !text.channelValueOption.deleted);
    }
    if (!channelText) {
        // все удалено
        return entity.title;
    }

    let fullTextView = MacroService.getFullTextView(channelText.text, channelText.macros);
    // отображение названий вложений
    fullTextView += getOutgoingAttachmentsText(entity.title, channelText);

    return fullTextView;
}

/**
 * Получить текстовое представление вложений для блока
 * @param title
 * @param channelText
 */
function getOutgoingAttachmentsText(title: string, channelText: ChannelText): string {
    if (channelText.attachedFiles?.length == 0) {
        return ``;
    }
    let text = channelText.text?.length > 0 ? `` : `${title}`;
    text += `\n\n Вложения:\n ${channelText.attachedFiles.map(file => formatFilename(file)).join(`\n`)}`;
    return text;
}

/**
 * Форматировать название файла для отображения в блоке
 * @param file  файл
 */
function formatFilename(file: EruditeFile) {
    if (file.fileName.length <= maxLineLength) {
        return file.fileName;
    } else {
        return `${file.fileName.substr(0, maxLineLength)}...`;
    }
}