export class Track {
    index?: number;
    title: string;
    file: any;


    constructor(index: number, title: string, file: any) {
        this.index = index;
        this.title = title;
        this.file = file;
    }
}