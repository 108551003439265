import {dia, util} from "@naumen/rappid/build/rappid";

export class NavigatorElementView extends dia.ElementView {

    body = null;

    markup = [{
        tagName: 'rect',
        selector: 'body',
        attributes: {
            'fill': '#31d0c6'
        }
    }];

    constructor(opt: dia.CellView.Options<dia.Element>) {
        super(opt);
    }

    render() {
        var doc = util.parseDOMJSON(this.markup);
        this.body = doc.selectors.body;
        this.el.appendChild(doc.fragment);
        this.updateNodesAttributes();
        // @ts-ignore
        this.updateTransformation();
        return undefined;
    }

    updateNodesAttributes () {
        var size = this.model.get('size');
        this.body.setAttribute('width', size.width);
        this.body.setAttribute('height', size.height);
    }
}

export class NavigatorLinkView extends dia.LinkView {


    constructor(opt: dia.CellView.Options<dia.Link>) {
        super(opt);
    }

    initialize() {

    }

    render() {
         return undefined;
    }

    update(link: dia.Link, attributes: any, opt?: { [p: string]: any }): this {
        return undefined;
    }
}