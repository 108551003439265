import {Component} from "@angular/core";
import {VASimpleModuleAnswer} from "../../../../../data/va/SimpleModuleAnswer";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {BaseMasterComponent} from "../../base/base-master.component";
import {SmaService} from "../sma.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'sma-master',
    template: require('./sma-master.component.html'),
    styles: [require('./sma-master.component.less')]
})
export class SmaMasterComponent extends BaseMasterComponent<VASimpleModuleAnswer, CompositeKey<number>> {

    constructor(titleService: Title,
                stateService: StateService,
                dataService: SmaService,
                httpClient: HttpClient,
                notificationService: NotificationService) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`d`));
    }

    async loadList(): Promise<void> {
        this.setLoading(true);
        this.dataService.findAll().then((smaList: VASimpleModuleAnswer[]) => {
            this.objects = smaList.map(sma => new VASimpleModuleAnswer(sma))
                .sort((first, second) => first.vaAct.displayOrder > second.vaAct.displayOrder ? 1 : -1);
            this.setLoading(false);
        });
    }


    addNewObj(o: VASimpleModuleAnswer) {
        this.objects.push(new VASimpleModuleAnswer(o))
    }

    replaceObj(o, assignCallback: (object: any) => any = null) {
        super.replaceObj(o, object => new VASimpleModuleAnswer(object));
    }

    /**
     * Функция для фильтрации
     * @param searchString поисковая строка
     * @param item         атрибут
     * @param filter       текущий фильтр
     */
    public testSimpleModuleAnswer(searchString: string, item: VASimpleModuleAnswer, filter: any): boolean {
        return item.textSearch(item.vaAct.title, searchString);
    }
}
