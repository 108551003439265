/**
 * Created by semyon on 20.09.16.
 */

var FoldingComponent = {
    templateUrl: "/pages/workplace/util/folding.html",
    transclude: true,
    bindings: {
        title: "@"
    },
    controller: function () {
        this.state = {
            title: "",
            collapsed: false
        };

        this.setTitle = function (title) {
            this.state.title = title;
        };

    }
};

export default FoldingComponent;
