import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    template: require('./confirm-button-dialog.component.html'),
    styles: [require('./confirm-button-dialog.component.less')]
})
export class ConfirmButtonDialogComponent {

    constructor(public dialogRef: MatDialogRef<ConfirmButtonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {questionText: string}) {
    }

    ok() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }

}