import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {
    CHAT_MODE_STORAGE_KEY,
    CHAT_PROMPTER_STATE,
    CHAT_ROBOT_STATE,
    ChatForm
} from "../chat-panel/chat-panel.component";
import {AttributeSuppliedTypeEnum, VaAttribute} from "../../../../data/va/Attribute";
import {AttributeService} from "../../va/attribute/attribute.service";
import {VaTag} from "../../../../data/va/Tag";
import {TagService} from "../../va/tag/tag.service";
import {StateService} from "@uirouter/core";
import {ChatControlService} from "../chat-control.service";
import {SessionMode, SessionModeEnum} from "../../dialog/model/dialog.model";
import {DialogService} from "../../dialog/dialog.service";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {Clipboard} from 'ts-clipboard';

@Component({
    selector: 'chat-control-panel',
    template: require('./chat-control-panel.component.html'),
    styles: [require('./chat-control-panel.component.less')],
    encapsulation: ViewEncapsulation.None
})

export class ChatControlPanelComponent implements OnInit, OnDestroy {
    selectedAttributes: any;
    form: ChatForm;
    attributes: VaAttribute[];
    tags: VaTag[];
    sessionModes: SessionMode[];
    sessionMode: SessionMode;
    subscription: any;

    constructor(private cdr: ChangeDetectorRef,
                private attributeService: AttributeService,
                private tagService: TagService,
                private dialogService: DialogService,
                private stateService: StateService,
                private notificationService: NotificationService,
                public chatControlService: ChatControlService) {
    }

    ngOnInit(): void {
        // загружаем режимы проведения диалога
        this.dialogService.getSessionModes().then(modes => {
            this.sessionModes = modes;
            const storedSessionMode = sessionStorage.getItem(CHAT_MODE_STORAGE_KEY);
            if (storedSessionMode) {
                // режим проведения диалога сохранен
                this.sessionMode = this.sessionModes.find(mode => mode.name == storedSessionMode);
            } else {
                // берем первый
                this.sessionMode = this.sessionModes[0];
            }
        });

        this.subscription = this.chatControlService.form.subscribe(form => this.form = form);

        this.attributeService.findAll().then((attributes: VaAttribute[]) =>
            this.attributes = attributes
                .sort((a1, a2) =>
                    // сначала идет тип канала,
                    a1.suppliedType?.name == AttributeSuppliedTypeEnum.CHANNEL
                        ? -1
                        : a2.suppliedType?.name == AttributeSuppliedTypeEnum.CHANNEL
                            ? 1
                            // потом сортировка по названию,
                            : a1.name < a2.name
                                ? -1
                                : a1.name > a2.name
                                    ? 1
                                    // потом по id
                                    : a1.key.id - a2.key.id));


        this.tagService.getTagList().then((tags: VaTag[]) => {
            this.tags = tags;
        });
    }


    closeDialog() {
        this.chatControlService.closeDialog.emit(this.chatControlService.dialog.conversationId);
    }

    sendMessage() {
        const editAttributeOk = this.selectedAttributes ? this.chatControlService.finishEdit.emit() : true;
        if (editAttributeOk) {
            this.chatControlService.onControlSend.emit();
        }
    }

    /**
     * Переключить режим суфлер/робот
     */
    switchMode() {
        sessionStorage.setItem(CHAT_MODE_STORAGE_KEY, this.sessionMode.name);
        this.stateService.go(this.sessionMode.name != SessionModeEnum.PROMPTER ? CHAT_ROBOT_STATE : CHAT_PROMPTER_STATE);
    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


    /**
     * Скопировать данные в буфер обмена по ctrl+клик
     */
    copyData(description: string, data: any, $event: MouseEvent) {
        copyData(this.notificationService, description, data, $event);
    }
}


/**
 * Скопировать данные в буфер обмена по ctrl+клик
 */
export function copyData(notificationService: NotificationService, description: string, data: any, $event: MouseEvent) {
    if (!$event.ctrlKey && !$event.metaKey) {
        return;
    }
    Clipboard.copy(data);
    notificationService.success(`${description} скопирован в буфер обмена`);
}



