import {NgModule} from "@angular/core";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {FormMessageComponent} from "./form-message/form-message.component";
import {FormControlComponent} from "./form-control/form-control.component";
import {ConfirmButtonComponent} from "./confirm-button/confirm-button.component";
import {ConfirmButtonDialogComponent} from "./confirm-button/dialog/confirm-button-dialog.component";

@NgModule({
    declarations: [
        FormControlComponent,
        FormMessageComponent,
        ConfirmButtonComponent,
        ConfirmButtonDialogComponent
    ],
    imports: [
        NgSelectModule,
        NgbPaginationModule,
        FormsModule,
        CommonModule,
        MatCardModule
    ],
    exports: [
        FormControlComponent,
        FormMessageComponent,
        ConfirmButtonComponent,
        ConfirmButtonDialogComponent
    ],
    entryComponents: [
        ConfirmButtonDialogComponent
    ]
})
export class FormControlModule {

}