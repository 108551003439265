import {VaExtractionContext} from "../../io/components/dialog/model/correction.model";


export interface MassOperation {
    ids: number[],
    type: MassOperationType,
    context?: VaExtractionContext,
    tagId?: number
}

export enum MassOperationType {
    CHANGE_CONTEXT = 'CHANGE_CONTEXT',
    CONFIRM_MOVE = 'CONFIRM_MOVE',
    DELETE = 'DELETE'
}