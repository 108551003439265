import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {StepLabelEntity} from "../../../../data/va/Script";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'tagging-item',
    template: require('./tagging-item.component.html'),
    styles: [require('./tagging-item.component.less')]
})
export class TaggingItemComponent implements OnInit {

    tags: StepLabelEntity[];

    @Input()
    taggedInfo: StepLabelEntity[];

    @Input()
    multiple: boolean;

    @Output()
    taggedInfoChange = new EventEmitter<StepLabelEntity[]>();
    
    constructor(private httpClient: HttpClient) {
    }

    ngOnInit(): void {
        this.loadTags();
    }

    async loadTags() {
        this.tags = await this.httpClient.get<StepLabelEntity[]>(`/account/expert/tag/tags`).toPromise();
    }


    onChange() {
        this.taggedInfoChange.emit(this.taggedInfo);
    }

}