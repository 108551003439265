import {Injectable} from '@angular/core';

import {HttpClient} from "@angular/common/http";
import {BaseDataService} from "../va/base/base-data.service";
import {Project, ProjectLanguage, ProjectType} from "./project.model";
import * as urls from "../../../../../js/workplace/urls";
import WebSocketService from "../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {NamedEntitiesModel} from "../va/model/named-entities.model";


@Injectable()
export class ProjectService extends BaseDataService<Project, number> {

    baseUrl: string = `${urls.va.project}`;

    readonly entityClass = NamedEntitiesModel.PROJECT;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    /**
     * Загрузить данные для создания/импорта проекта
     */
    async getProjectData(): Promise<{ types: ProjectType[], languages: ProjectLanguage[], englishProjects: Project[] }> {
        return await this.httpClient.get<{ types: ProjectType[], languages: ProjectLanguage[], englishProjects: Project[] }>(`${this.baseUrl}/projectData`).toPromise();
    }

    /**
     * Выбрать ли всех юзеров по дефолту
     */
    async isSelectAll(): Promise<boolean> {
        return await this.httpClient.get<boolean>(`${this.baseUrl}/isSelectAll`).toPromise();
    }

}