import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {Title} from '@angular/platform-browser';
import {MlService} from "../ml.service";
import {StateService} from "@uirouter/core";
import {Model} from "../../../../../data/va/Model";
import {AppConfigService} from "../../../../service/app-config.service";
import {MlMasterComponent} from "../master/ml-master.component";

@Component({
    selector: 'ml-edit',
    template: require('./ml-edit.component.html'),
    styles: [require('./ml-edit.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class MlEditComponent implements OnInit, OnDestroy {
    isLoading: boolean = true;
    modelType: string;
    models: Model[] = [];
    access: boolean;
    timezone: string;

    constructor(private titleService: Title,
                private mlService: MlService,
                private stateService: StateService,
                private master: MlMasterComponent,
                appConfigService: AppConfigService) {
        this.timezone = appConfigService.interfaceConfig.timezone;
        let title: string = this.stateService.current.data.title;
        this.modelType = this.stateService.params['modelType'];
        this.master.selectedType = this.modelType;

        switch (this.modelType) {
            case 'TAG':
                title += 'ТЕМАТИКИ';
                break;
            case 'STEP':
                title += 'СЦЕНАРИЙ';
                break;
            case 'EXTRACT_PLAIN_ATTR':
                title += 'АТРИБУТЫ: ИЗ РЕПЛИКИ';
                break;
            case 'EXTRACT_AFTER_Q_ATTR':
                title += 'АТРИБУТЫ: ПОСЛЕ ОТВЕТА';
                break;
        }

        titleService.setTitle(title);
    }

    async ngOnInit() {
        this.access = await this.mlService.getAccess();
        await this.loadModels();
        this.mlService.loadModelsEvent.subscribe(async event => {
            if (event) {
                await this.loadModels();
            }
        })
    }

    ngOnDestroy() {
        this.master.selectedType = null;
    }

    async loadModels() {
        this.isLoading = true;
        if (this.modelType) {
            this.models = await this.mlService.getModelsByType(this.modelType);
        }
        this.isLoading = false;
    }

    async createNewModel() {
        // создаём новую модель и открываем её
        this.mlService.createModelByType(this.modelType).then(data => {
            // кладём созданную модель в список
            this.models.push(data);

            if (this.modelType === 'TAG') {
                // переходим к просмотру модели
                this.stateService.go('robot.intent_model', {
                    modelId: data.key.id,
                    type: data.componentType.shortName.toLowerCase()
                });
            } else {
                // переходим к просмотру модели
                this.stateService.go('robot.model', {
                    modelId: data.key.id,
                    type: data.componentType.shortName.toLowerCase()
                });
            }
        });
    }
}