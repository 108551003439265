import {dia, ui} from "@naumen/rappid/build/rappid";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import FormulationSwitchController from "../../controller/FormulationSwitchController";
import RectangleElement from "../rectangle/RectangleElement";
import {ClickMode} from "../EruditeElement";
import ElementView = dia.ElementView;
import Halo = ui.Halo;

/**
 * Элемент, у которого можно переключить формулировку
 */
export class FormulableElement extends RectangleElement {

    private static HANDLE_NAME_SUFFIX: string = "-formulation";

    /**
     * Callback при переключении формулировки "вперёд"
     */
    public onNextFormulation: (viewModel: FormulableElement) => void;

    /**
     * Callback при переключении формулировки "назад"
     */
    public onPreviousFormulation: (viewModel: FormulableElement) => void;

    /**
     * Название хэндла переключения на предыдущую формулирвку
     */
    private previousHandleName;

    /**
     * Название хэндла переключения на следующую формулирвку
     */
    private nextHandleName;

    /**
     * Вёрстка
     */
    public addFormulationMarkUp() {
        mergeDeep(this.attributes, {
            attrs: {
                formulationCount: {
                    fill: '#333333',
                    stroke: '#FFFFFF',
                    refX: -10,
                    refY: 0,
                    fontSize: 14,
                    text: FormulationSwitchController.getCurrentCount(this.node),
                    ref: 'formulationCountWrapper',
                },
                formulationCountWrapper: {
                    refX: '100%',
                    refY: 5,
                    x: -30,
                }
            }
        });

        this.markup.push({
            tagName: 'rect',
            selector: 'formulationCountWrapper'
        }, {
            tagName: 'text',
            selector: 'formulationCount',
        });

        this.previousHandleName = `previous-${this.node.stepLabel.type.name}-formulation`;
        this.nextHandleName = `next-${this.node.stepLabel.type.name}-formulation`;
    }

    public onFormulableElementClick(elementView: ElementView, mode: ClickMode): ui.Halo {
        super.onElementClick(elementView, mode);
        return this.addEarsIfNecessary();
    }

    /**
     * Добавить переключатели формулировок, если надо
     */
    public addEarsIfNecessary() {
        const hasValue = this.node.stepLabel.value?.length > 0;
        const subId = this.node.stepLabel.subId;

        // subId это id удалённой формулировки?
        const isDeletedSubId = subId ? !this.node.entity.formulations.some(formulation => formulation.key.id == subId) : false;
        // если в subId у нас удалённая формулировка, а пришла 1 формулировка, тогда надо отобразить переключатели, иначе должно быть больше 1 формулировки
        const isFormulationChangeAllowed = isDeletedSubId ? this.node.entity.formulations.length > 0 :
            this.node.entity.formulations.length > 1;

        if (this.node.entity.formulations && isFormulationChangeAllowed && !hasValue) {
            this.halo.addHandles(this.formulationHandles);
        }
        return this.halo.render();
    }

    set formulationCount(count: string) {
        this.attr('formulationCount', {
            text: count
        });
    };

    get formulationHandles() {
        return [{
            name: this.previousHandleName,
            position: Halo.HandlePosition.W,
            events: {
                pointerup: (event) => this.onPreviousFormulation(this)
            }
        },
            {
                name: this.nextHandleName,
                position: Halo.HandlePosition.E,
                events: {
                    pointerup: (event) => this.onNextFormulation(this)
                }
            }
        ]

    }

    /**
     * Удалить переключатели формулировок, если есть
     */
    public removeEarsIfPresent(): void {
        if (this.halo.hasHandle(this.previousHandleName)) {
            this.halo.removeHandle(this.previousHandleName);
        }
        if (this.halo.hasHandle(this.nextHandleName)) {
            this.halo.removeHandle(this.nextHandleName);
        }
        this.halo.render();
    }

    /**
     * Есть ли формулировки
     */
    get isFormulable(): boolean {
        return true;
    }
}
