import moment from "moment/js/moment";
import "moment/js/locale/ru";
import urls from "../urls";

let webSocketService;
let statisticsService;

export default class RootController {

    constructor($scope, $rootScope, $controller, $location, $state, $stateParams, $timeout, Restangular, AccountService, WebSocketService, StatisticsService, TabService, snackbarService, uibModal, $transitions, interfaceConfiguration) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.accountService = AccountService;
        this.tabService = TabService;
        this.$transitions = $transitions;

        this.snackbarService = snackbarService;
        this.uibModal = uibModal;

        webSocketService = WebSocketService;
        statisticsService = StatisticsService;

        this.user = null;
        this.$timeout = $timeout;
        this.restAngular = Restangular;

        //показываем прогрессбар
        this.isLoading = true;

        this.projectVersions = null;

        this.interfaceConfiguration = interfaceConfiguration;

        // подписываемся на событие ожидания изменений в версиях проекта
        this.subscriptionId = webSocketService.subscribeOnEvents({
            eventType: "VA_PROJECT_VERSIONS_CHANGED",
            fn: (event) => {
                this.projectVersions = JSON.parse(event.details);
            }
        });

        // в деструкторе стопаем метод, не забываем отписаться
        $scope.$on("$destroy", () => {
            webSocketService.removeListener(this.subscriptionId);
        });


        const customHover = (event, add) => {
            const element = angular.element(event.currentTarget).parent()[0];
            if (element == null) {
                return;
            }
            if (add) {
                $(element).addClass('active');
            } else {
                $(element).removeClass('active');
            }
        };

        this.$scope.mouseOver = (event) => customHover(event, true);
        this.$scope.mouseLeave = (event) => customHover(event, false);

        // слушаем события перехода на стейт
        //.on() возвращает функцию, которую можно вызвать чтобы удалить созданные listener
        const removeListenerFunction = this.$transitions.onStart({}, (transition) => {
            let newState = transition.$to();
            let fromState = transition.$from();
            let newStateParams = Object.assign({}, transition.params('to'));
            let fromParams = Object.assign({}, transition.params('from'));

            // первый переход на стейт вообще в приложении
            // проверим авторизацию и перейдём либо на логин
            // либо на старый стейт
            if (newStateParams.from_auth_interceptor) {
                // мы уже проверили авторизацию, её нет, снова проверять не надо
                this.restAngular.one("/account/login/oauth").get()
                    .then((data) => {
                        if (data) {
                            // если включен oauth, страницу логина никогда не показываем
                            window.location.replace("/");
                        } else {
                            this.isLoading = false;
                        }
                    });
                return true;
            }


            this.isAuthorized(isAuthorized => {
                //скрываем прогрессбар
                this.isLoading = false;
                if (isAuthorized) {
                    // слушатель должен работать только пока не авторизовались, удаляем его
                    removeListenerFunction();

                    let initialState = newStateParams["nextUrl"];
                    let initialStateParamsString = newStateParams["params"];
                    let initialStateParams = {};
                    if (initialStateParamsString) {
                        initialStateParams = JSON.parse(initialStateParamsString);
                    }

                    if (newState.name.startsWith("login")) {
                        // по умолчанию переходим к первому пункту меню
                        if (!initialState || initialState.startsWith("login")) {
                            initialState = this.menu[0].submenu[0] ?
                                this.menu[0].url + "." + this.menu[0].submenu[0].url : this.menu[0].url;
                        }
                        this.$state.go(initialState, initialStateParams);
                    } else {
                        // переходим на изначальный state
                        let match = this.menu.some(function (elem) {
                            return newState.name.includes(elem.url);
                        });

                        if (match) {
                            // если стейт разрешен и ролью то переходим
                            $state.transitionTo(newState, newStateParams, {reload: true, inherit: false, notify: true});
                        } else {
                            // если стейт не разрешен ролью то переходим на первый в меню
                            initialState = this.menu[0].submenu[0] ?
                                this.menu[0].url + "." + this.menu[0].submenu[0].url : this.menu[0].url;
                            this.$state.go(initialState, initialStateParams);
                        }
                    }
                } else {
                    if (newState.name.startsWith("login")) {
                        // чтобы не заходить в interceptor снова
                        newStateParams.from_auth_interceptor = true;
                        $state.go("login", newStateParams);
                    } else {
                        const params = {
                            nextUrl: newState.name,
                            params: JSON.stringify(newStateParams),
                            // чтобы не заходить в interceptor снова
                            from_auth_interceptor: true
                        };
                        $state.go("login", params);
                    }
                }
            });
            // Отменяем ивент (если стейт не авторизация), т.к. проверка действия асинхронная
            return false;
        });

        /**
         * Проверить, есть ли версия проекта в параметрах и если нет, то редиректнуть на выбор
         * Проверить, что выбрана корректная версия проекта, которая существует, не удалена и тп, иначе редиректнуть на выбор
         * Какой state проверять - настраивается в routes (нужно добавить флаг requiredProjectVersion)
         */
        this.$transitions.onStart({}, (transition) => {
            let toState = transition.$to();
            let fromState = transition.$from();
            let toParams = Object.assign({}, transition.params('to'));
            let fromParams = Object.assign({}, transition.params('from'));
            //если версии проектов не загружены, то ничего проверять не будем
            if (!this.projectVersions) {
                return;
            }
            const state = $state;
            if (toState.data) {
                if (!toState.data.requiredProjectVersion) {
                    // Если проверять на версию проекта не надо или уже все сделали, то пропускаем запрос
                    // toState.data.validProjectVersion = false;
                    // toState.data.educatedProjectVersion = null;
                } else {
                    const educatedProjectVersionId = toParams['projectVersionId'];
                    // Найдем данные о выбранной версии проекта
                    const projectVersion = this.getProjectVersion(educatedProjectVersionId);
                    if (!projectVersion) {
                        // Если нет версии проекта, значит надо отправить на выбор
                        return transition.router.stateService.target(...this.onWrongProjectVersion(toState, toParams, state));
                    } else {
                        // Если с версией все хорошо, то завершаем переход
                        // Прокидываем через стейт версию, чтобы в директиве для показа еще раз не запрашивать
                        // toState.data.validProjectVersion = true;
                        toState.data.educatedProjectVersion = projectVersion;
                    }
                }
            }
        });

        this.getProjectVersion = function (educatedProjectVersionId) {
            if (!educatedProjectVersionId || educatedProjectVersionId.length === 0) {
                return null;
            }
            return this.projectVersions.find(pv => pv.id === educatedProjectVersionId);
        };

        /**
         * Если в запросе некорректная версия проекта, то надо попробовать запросить весь список проектов
         * И если есть всего одна версия, то перенаправить на нее
         */
        this.onWrongProjectVersion = function (toState, toParams, state) {
            if (this.projectVersions && this.projectVersions.length === 1) {
                // Версия проекта всего одна, выберем ее и перезагрузим текущий стейт
                toParams['projectVersionId'] = this.projectVersions[0].id;
                toState.data.validProjectVersion = true;
                toState.data.educatedProjectVersion = this.projectVersions[0];
                this.restAngular.one(urls.va.projectVersion, "onStartEdu").get().then(data => {
                }, data => {
                });
                return [toState, toParams, {reload: true}];
            }
            return ["robot.project_versions", {nextUrl: toState.name}, {reload: true}];
        };

        // установка русской локали для moment
        moment.locale("ru");

        moment.calendarFormat = function (myMoment, now) {
            const diff = myMoment.diff(now, 'days', true);
            const weekDiff = myMoment.week() - now.week();
            let retVal = '';

            if (diff < -6) {
                retVal = 'sameElse';
            } else if (diff < -1) {
                if (weekDiff < 0) {
                    retVal = 'sameElse';
                } else {
                    retVal = 'lastWeek';
                }
            } else if (diff < 0) {
                retVal = 'lastDay';
            } else if (diff < 1) {
                retVal = 'sameDay';
            } else {
                retVal = 'sameElse';
            }
            return retVal;
        };

        // получить данные о пользовательских действиях от другой вкладки
        this.listenUserAction = (event) => {
            statisticsService.updateLastUserActionTime(event.data.timestamp);
        };
        // отправить данные о пользовательских действиях в другие вкладки
        this.sendUserAction = function () {
            this.tabService.broadcast("uamonitor", {timestamp: statisticsService.lastUserActionTime});
        };

        // будем слушать события от других вкладок
        this.tabService.registerBroadcastHandler("uamonitor", this.listenUserAction);

        // отслеживаем логины/логауты в других вкладках
        this.listenLoginLogout = (event) => {
            let params = Object.assign({}, $stateParams);
            params.from_auth_interceptor = false;
            this.$state.transitionTo(this.$state.current, params, {
                reload: true, inherit: false, notify: true
            });
        };
        this.tabService.registerBroadcastHandler("login", this.listenLoginLogout);
        this.tabService.registerBroadcastHandler("logout", this.listenLoginLogout);

        // обновить статус, разлогинить, если таймаут бездействия
        this.refreshUserStatus = () => {
            // обновляем статус
            this.accountService.refreshStatus();
            const timeToOut = this.interfaceConfiguration.inactivityTimeout - (new Date().getTime() - statisticsService.lastUserActionTime);
            if (timeToOut <= 0) {
                // последнее действие давно - выходим
                this.accountService.logout();
            }
            // последнее действие недавно - рассылаем время последнего действия по всем вкладкам
            this.sendUserAction();

            // планируем повтор
            let repeatTime = Math.min(this.interfaceConfiguration.inactivityTimeout / 4, 60000);
            this.$timeout(this.refreshUserStatus, repeatTime);
        };
    }

    /**
     * Проверяем авторизацию
     * @param callback
     */
    isAuthorized(callback) {
        // обращаемся на сервер, чтобы проверить, есть ли в сессии авторизационные данные
        this.restAngular.one("/account/login").get().then((data) => {
            if (data.redirect) {
                // "ручной" редирект при oauth2
                window.location.replace(data.redirect);
                return;
            }
            // отправить главному контроллеру сообщение об авторизации
            this.onAuthorization(data, () => {
                callback(true);
            });
        }, (data) => {
            callback(false);
        });
    }

    onAuthorization(data, callback) {
        let roleName = data.user.account.role.name;
        if (!(roleName === 'EXPERT' || roleName === 'SUPER_EXPERT')) {
            // если это не эксперт, то список проектов ему не нужен в меню
            this.addUserData(data, callback);
            return;
        }
        //прошла  авторизация, надо загрузить версии проектов до того, как все загрузится
        this.restAngular.all(urls.va.projectVersion).getList()
            .then(projectVersions => {
                    this.projectVersions = projectVersions;
                    this.addUserData(data, callback);
                },
                () => {
                    this.addUserData(data, callback);
                });
    }

    addUserData(data, callback) {
        this.menu = data.menu;
        this.accountService.setUser(data.user);
        this.accountService.setExpertAlwaysTestWS(data.expertAlwaysTestWS);
        this.refreshUserStatus();
        //webSocketService.connect(data.user);
        webSocketService.setIds(data.user.id, null, true);
        if (callback) {
            callback();
        }
    }

    hoverIn(el) {
        console.log(el)
    }

    hoverOut(el) {
        console.log(el)
    }
}

RootController.$inject = ["$scope", "$rootScope", "$controller", "$location",
    "$state", "$stateParams", "$timeout", "Restangular", "AccountService", "WebSocketService", "StatisticsService", "TabService", "SnackbarService", "$uibModal", "$transitions", "interfaceConfiguration"];
