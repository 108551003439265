import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from "@angular/core";
import {
    StepLabelEntity,
    StepLabelType,
    StepLabelTypeName, VAProcedure,
    VAScenario,
    VAScript,
    VaScriptNode
} from "../../../data/va/Script";
import {HttpClient} from "@angular/common/http";
import * as urls from '../../../../../js/workplace/urls';
import {MatDialog, MatSnackBar} from "@angular/material";
import {ScriptViewerComponent} from "./script-viewer.component";
import {SnackbarComponent} from "../common/snackbar/snackbar.component";
import {StateService, TransitionService} from "@uirouter/core";
import {util} from "@naumen/rappid/build/rappid";
import {Title} from "@angular/platform-browser";
import {catchError} from "rxjs/operators";
import {ScenarioService} from "../va/scenario/scenario.service";
import {ProcedureService} from "../va/procedure/procedure.service";
import ScriptValidationController from "./controller/ScriptValidationController";
import {TagIdsEnum, VaTag} from "../../../data/va/Tag";
import {TagService} from "../va/tag/tag.service";

@Component({
    selector: 'script-edit',
    template: require('./script-edit.component.html')
})
export class ScriptEditComponent implements OnInit {

    /**
     * Идентификатор сценария / процедуры
     */
    private readonly scriptId: number;

    /**
     * Редактируемый / создаваемый объект - процедура?
     */
    readonly isProcedure: boolean;

    /**
     * Ссылка на элемент навигатора
     */
    @ViewChild(`navigator`, {static: false})
    navigatorRef: ElementRef;

    /**
     * Ссылка на элемент навигатора
     */
    @ViewChild(ScriptViewerComponent, {static: false})
    private viewer: ScriptViewerComponent;

    /**
     * URL по которому делаются запросы, зависит от сущности
     */
    private baseUrl: string;

    /**
     * Список сущностей для каждого StepLabel'a
     */
    entities: { type: StepLabelType, entities: StepLabelEntity[] }[];

    /**
     * Данные сценария/процедуры или undefined, если это новый сценарий/процедура
     */
    script: VAScript;

    /**
     * Список тематик
     */
    tags: VaTag[];

    access: boolean = false;

    account: any;

    /**
     * Валидатор
     */
    private scriptValidationController: ScriptValidationController;

    /**
     * id сценария по которому выводим инфу генерации кейсов/диагностики доступности узлов
     */
    private readonly caseScenarioId?: number;

    private readonly tagId: number;

    onDisplaySnackBar: EventEmitter<{ message: string, styleClass: string, duration?: number }> = new EventEmitter();

    onDismissSnackBar: EventEmitter<void> = new EventEmitter();

    constructor(private http: HttpClient,
                public dialog: MatDialog,
                private snackBar: MatSnackBar,
                private stateService: StateService,
                private titleService: Title,
                private transitionService: TransitionService,
                private scenarioService: ScenarioService,
                private procedureService: ProcedureService,
                private tagService: TagService) {
        this.isProcedure = this.stateService.params['type'] == 'procedure';
        this.scriptId = this.isProcedure ? this.stateService.params['procedureId'] : this.stateService.params['scriptId'];
        this.caseScenarioId = this.stateService.params['caseScenarioId'];
        this.baseUrl = this.isProcedure ? `${urls.va.procedure}` : `${urls.va.script}`;
        this.scriptValidationController = new ScriptValidationController(this.http);
        this.onDisplaySnackBar.subscribe(event => this.displaySnackbar(event.message, event.styleClass, event.duration));
        this.onDismissSnackBar.subscribe(() => this.snackBar.dismiss());
        const title: string = this.stateService.current.data.title;
        this.titleService.setTitle(title);
        this.transitionService.onBefore({}, () => this.snackBar.dismiss());
        this.tagId = this.stateService.params['tagId'];
    }

    async ngOnInit() {
        await this.init();

        if (this.scriptId) {
            this.access = this.isProcedure ?
                await this.procedureService.getAccessForObject(this.script as VAProcedure) :
                await this.scenarioService.getAccessForObject(this.script as VAScenario);
        } else {
            await this.loadAccount();
            if (this.account.isJunior()) {
                // младшему эксперту нельзя создавать -> выходим
                this.stateService.go('^');
            }
            this.access = this.isProcedure ?
                (await this.procedureService.getAccess())?.access :
                (await this.scenarioService.getAccess())?.access;
        }
    }

    /**
     * Получение текущего аккаунта
     */
    async loadAccount() {
        this.account = await this.http.get(`${urls.va.account}current`).toPromise();
        this.account.isJunior = () => {
            return this.account.expertType.name == 'JUNIOR';
        }
    }

    save() {
        // Получаем текущий сценарий
        const currentScript: VAScript = this.viewer.getCurrentScript();
        // Валидируем
        this.scriptValidationController.onScriptSave(currentScript, this.isProcedure).then(validationResult => {
            // Если нашли ошибки, то не даём сохранять, показываем ошибку
            if (!validationResult.success) {
                this.displaySnackbar(validationResult.message, 'warning');
                return;
            }
            // Сообщение и метод в зависимости от того что сохраняем
            const method = this.scriptId ? 'patch' : 'post';

            this.http.request<VAScript>(method, this.baseUrl, {body: currentScript})
                .subscribe((script) => {
                    if (currentScript.key != null) {
                        this.displaySnackbar(this.stateService.current.data.successfulSaveMessage, 'success');
                    } else {
                        const params = this.isProcedure ? {procedureId: script.key.id} : {scriptId: script.key.id};
                        let transitionOptions = this.stateService.current;
                        transitionOptions.data.displaySnackbar = true;
                        this.stateService.go(this.stateService.current.name, params, {reload: transitionOptions});
                    }
                    // помечаем текущее состояние как начальное
                    this.viewer.markUnchanged();
                }, error => {
                    let message;
                    if (error.errors?.length && error.errors[0].message) {
                        message = error.errors.filter(error => error.message).map(error => error.message).join('\n');
                    } else {
                        message = 'Ошибка при сохранении';
                    }
                    this.displaySnackbar(message, 'warning');
                });
        });
    }

    private async init() {
        // Запрос узлов/рёбер для сценария/процедуры
        this.script = this.scriptId ? await this.loadScript() : this.createScript();

        // Запрос доступных сущностей
        this.entities = await this.http.get<{ type: StepLabelType, entities: StepLabelEntity[] }[]>(`${this.baseUrl}entities`).toPromise();

        // Тематики
        this.tagService.getTagsWithoutScenario().then(data => {
            let tags = data.filter(tag => tag.key.id != TagIdsEnum.SMALL_TALK_ID_ROOT);
            const requestedTagId = (this.script as any).tagId ? (this.script as any).tagId : this.tagId;
            if (requestedTagId && requestedTagId != "0" && !data.some(tag => tag.key.id == requestedTagId)) {
                // если не подгрузился тег для существующего скрипта, то подгрузим его по id
                this.tagService.getTag(requestedTagId).then(tagForCurrentScript => {
                    tags.push(tagForCurrentScript);
                    this.tags = tags;
                });
            } else {
                // тематика и так уже есть, можем проставлять
                this.tags = tags;
            }
        });

        if (this.script.name) {
            this.titleService.setTitle(`${this.titleService.getTitle()} | ${this.script.name}`);
        } else if (this.scriptId) {
            this.titleService.setTitle(`${this.titleService.getTitle()} | ${this.scriptId}`);
        } else {
            this.titleService.setTitle(`${this.titleService.getTitle()} | ${this.stateService.current.data.newObjectTitle}`);
        }

        if (this.stateService.current.data.displaySnackbar) {
            let message = this.stateService.current.data.successfulSaveMessage;
            this.displaySnackbar(message, 'success');
            delete this.stateService.current.data.displaySnackbar;
        }
    }

    /**
     *  Обработчик ошибок - перейти на родительский стейт в случае ошибки
     */
    private errorHandler() {
        const parentState = this.stateService.$current.parent.name;
        this.stateService.go(parentState);
        return [];
    }

    /**
     *  Загрузить сценарий / процедуру
     */
    private loadScript() {
        let params = this.caseScenarioId ? {caseScenarioId: this.caseScenarioId.toString()} : {};
        return this.http.get<VAScript>(`${this.baseUrl}${this.scriptId}`, {params: params}).pipe(
            catchError(() => this.errorHandler()))
            .toPromise();
    }

    /**
     * Создать сценарий
     */
    private createScript() {
        let script = {
            nodes: [this.createRootNode()],
            autoLayoutApplied: true
        } as any;
        if (!this.isProcedure) {
            script.tagId = this.tagId;
        }
        return script;
    }

    async onDeleteConfirm() {
        // Получаем текущий сценарий
        const currentScript: VAScript = this.viewer.getCurrentScript();
        const isUsed = await this.scriptValidationController.isProcedureUsed(currentScript.key.id);

        if (isUsed) {
            this.displaySnackbar('Нельзя удалить процедуру: она используется в другом сценарии или процедуре', 'warning');
            return;
        }

        this.scriptValidationController.onScriptDelete(currentScript, this.isProcedure).then(validationResult => {
            if (!validationResult.success) {
                this.displaySnackbar(validationResult.message, 'warning');
                return;
            }
            this.viewer.markUnchanged();
            const deletePromise = this.http.delete(`${this.baseUrl}${this.scriptId}`).toPromise();
            const parentState = this.isProcedure ? 'robot.script_procedure' : 'robot.script';
            deletePromise.then(() => this.stateService.go(parentState, {}, {reload: true}));
        });
    }

    /**
     * Отобразить сообщение в snack-bar'e
     * @param message     сообщение
     * @param styleClass  стиль
     * @param duration    продолжительность отобржения (мс.)
     */
    private displaySnackbar(message: string, styleClass: string, duration?: number): void {
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: duration ? duration : 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['script-builder-snack-bar', styleClass],
            data: {text: message},
        });
    }

    /**
     * Создать корневой узел, если scriptId нет и это создание нового сценария / процедуры
     */
    private createRootNode(): VaScriptNode {
        let title = this.isProcedure ? 'Вход' : 'Старт';
        let stepLabel = {
            type: {
                name: this.isProcedure ? StepLabelTypeName.ENTER : StepLabelTypeName.START,
                title: title
            }
        };
        return {
            key: {id: util.uuid(), projectVersionId: this.stateService.params['projectVersionId']},
            text: title,
            stepLabel: stepLabel,
            x: -50,
            y: -10,
            entity: {type: stepLabel.type, title: title} as any
        }
    }
}