import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as moment from 'moment';

@Component({
    selector: "datetime-picker",
    template: require('./datetime-picker.component.html'),
    styles: [require('./datetime-picker.component.less')],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatetimePickerComponent),
        multi: true
    }]
})
export class DatetimePickerComponent implements ControlValueAccessor {

    /**
     * Holds the current value of the slider
     */
    value: any = 0;

    @Input()
    disabled: boolean;

    @Input()
    placeholder: string;

    @Input()
    returnDateString?: boolean = false;

    @Input()
    addMilliseconds?: boolean = false;

    @Input()
    format?: string;

    constructor() {
    }

    /**
     * Invoked when the model has been changed
     */
    onChange: (_: any) => void = (_: any) => {
    };

    /**
     * Invoked when the model has been touched
     */
    onTouched: () => void = () => {
    };

    /**
     * Method that is invoked on an update of a model.
     */
    updateChanges() {
        if (this.value == null) {
            this.onChange(this.value);
        } else if (this.returnDateString && this.format) {
            const ms = this.addMilliseconds ? `.000` : ``;
            const value = `${moment(this.value).format(this.format)}${ms}`;
            this.onChange(value);
        } else if (this.returnDateString) {
            throw new Error(`Date string format is not specified`);
        } else {
            this.onChange(this.value);
        }
    }

    ///////////////
    // OVERRIDES //
    ///////////////

    /**
     * Writes a new item to the element.
     * @param value the value
     */
    writeValue(value: number): void {
        this.value = value;
        this.updateChanges();
    }

    /**
     * Registers a callback function that should be called when the control's value changes in the UI.
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when the control receives a blur event.
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

}