import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {VersionedDataService} from "../../base/versioned-data.service";
import {VaTag} from "../../../../../data/va/Tag";
import {AppliedEntityType} from "../../../entity-usage/entity-usages.model";
import WebSocketService from "../../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {NamedEntitiesModel} from "../../model/named-entities.model";

@Injectable()
export class SourceSelectorService extends VersionedDataService<VaTag, number> {

    baseUrl: string = "/account/expert/tagbuilding";

    usageEntityType: AppliedEntityType = AppliedEntityType.TAG;

    readonly entityClass: NamedEntitiesModel.VA_TAG;


    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getSources(): Promise<VaTag[]> {
        return await this.httpClient.get<VaTag[]>(`${this.baseUrl}/sources`).toPromise();
    }


}
