/**
 * Created by semyon on 20.09.16.
 */

var AsyncLoaderComponent = {
    templateUrl: "/pages/workplace/util/asyncLoader.html",
    transclude: true,
    bindings: {
        asyncLoad: "<",
        modelAttribute: "<",
        loadMessage: "@",
        defaultLoadMessage: "@"
    },
    controller: function () {
    }
};

export default AsyncLoaderComponent;
