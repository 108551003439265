export class EditableItemData {

    title: string;
    canCreate: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canMove: boolean;
    showActionTitle: boolean;
    overContent: boolean;
    externalLinkIcon: string;

    constructor(title: string, canCreate: boolean, canEdit: boolean, canDelete: boolean, canMove: boolean, showActionTitle: boolean, overContent: boolean, externalLinkIcon?: string) {
        this.title = title;
        this.canCreate = canCreate;
        this.canEdit = canEdit;
        this.canDelete = canDelete;
        this.canMove = canMove;
        this.showActionTitle = showActionTitle;
        this.overContent = overContent;
        this.externalLinkIcon = externalLinkIcon;
    }
}

export class EditableForm<T extends EditableObject> {
    new: boolean;
    show: boolean;
    index: number;
    objectForm?: T;
    errors = new Map<string, string>();

    constructor(objectForm: T) {
        this.objectForm = objectForm;
    }
}

export class EditableObject {

    id: number;

    constructor() {
    }

    objectToTitle(sources: Map<string, any>): String {
        return this.id + "";
    }

    validateForm(currentIndex: number, sources: Map<string, any>): Map<string, string> {
        return new Map();
    }

    // build
    formToObject(sources: Map<string, any>): EditableObject {
        return this;
    }

    beforeCreate(sources: Map<string, any>): void {

    }

    // parse
    beforeEdit(sources: Map<string, any>): void {

    }

    canDoAction(isDelete: boolean, sources: Map<string, any>): boolean {
        return true;
    }

    canDoExternalAction(sources: Map<string, any>): boolean {
        return false;
    }

    afterAction(isDelete: boolean, sources: Map<string, any>): void {

    }

}