/**
 * Директива (коллекции)
 */

function CollectionDirective() {
    return {
        restrict: "EA",
        replace: true,
        transclude: {
            'body': 'collectionBody',
            'header': '?collectionHeader',
            'filter': '?collectionFilter'
        },
        scope: {
            header: "=",
            sortable: "=", // нужно ли показывать хедер с сортировкой
            defaultValue: "@",
            paginationId: "@",
            loading: "=",
            searchable: "=",
            search: "=?",
            title: "=",
            oneCard: "=",
            withoutCard: "=",
            collapsible: "=",
            showLoading: "=",
            defaultSortReverse: "=",
            collectionFiltered: "&?"
        },
        bindToController: true,
        templateUrl: "/pages/workplace/collection.html",
        controller: ["$scope", function ($scope) {
            var defaultValue = this.defaultValue;
            this.sortType = defaultValue != null ? defaultValue : null;
            var defaultReverse = this.defaultSortReverse;
            this.sortReverse = defaultReverse != null ? defaultReverse : null;

            // варианты кол-ва элементов на странице
            this.itemPerPageOptions = [10, 30, 50];
            // кол-во элементов на странице по умолчанию
            this.itemPerPage = 10;

            // нужно создать отдельное поле в объекте контроллера,, иначе поле будет перезаписано дефолтным объектом из parent scope
            var isSortable = (this.sortable != null && this.sortable !== "") ? this.sortable : true;
            if (!this.header) {
                isSortable = false;
            }

            // sortable по-дефолту
            this.isSortable = isSortable;

            // если можно коллапсить, то по умолчанию скрываем
            this.isVisible = this.collapsible ? false : true;

            this.selectSortType = function (type) {
                this.sortType = type;
                this.toggleSortReverse();
            };

            this.toggleSortReverse = function () {
                this.sortReverse = !this.sortReverse;
            };

            this.toggleVisibility = function () {
                if (!this.collapsible) {
                    return;
                }
                this.isVisible = !this.isVisible;
            };

            this.curPage = 1;

            this.paginationInput = 1;

            const that = this;

            $scope.$watch("collection.curPage", function() {
               that.paginationInput = that.curPage;
            });

            if (this.collectionFiltered) {
                $scope.$watch("collection.collectionRepeat.$filtered.length", () => {
                    if (this.collectionRepeat && this.collectionRepeat.$filtered) {
                        this.collectionFiltered({$filtered: this.collectionRepeat.$filtered});
                    }
                });
            }

            this.goToPage = function () {
                let page = parseInt(that.paginationInput);
                if (!isNaN(page)) {
                    this.curPage = page;
                }
            };

        }],
        controllerAs: "collection",
        compile: function (tElement, tAttr) {
            // Q: почему не ng-class?
            // A: потому что ng-class ещё не успевает добавить sliding-item когда ng-if выполняет enter()-функцию
            var collapsible = tAttr["collapsible"];
            if (!collapsible) {
                $(tElement).find(".sliding-item").removeClass("sliding-item");
            }
            return function (scope, element, attrs,ctrl, $transclude) {
                $transclude(scope, function (clone) {
                });
            };
        }
    };
}

export default CollectionDirective;