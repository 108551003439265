import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {StateService} from "@uirouter/core";
import {DialogService} from "../../dialog/dialog.service";
import {AttributeService} from "../../va/attribute/attribute.service";
import {TagService} from "../../va/tag/tag.service";
import {VaAttribute} from "../../../../data/va/Attribute";
import {VaTag} from "../../../../data/va/Tag";
import {ValueTypeEnum} from "../../../../data/va/Extractor";
import {ExtractableValuesSerDTO} from "../../../../data/va/Dkb";
import {ChatService} from "../chat.service";
import * as moment from 'moment';
import {FileUploader} from "../../common/file-uploader/uploader-vendor/file-uploader.class";
import {FileItem} from "../../common/file-uploader/uploader-vendor/file-item.class";
import {SessionModeEnum} from "../../dialog/model/dialog.model";
import {
    Attachment,
    AutocompleteToRobotArgs,
    ChatOnAutocompleteArgs,
    ChatOnReplyArgs,
    DialogChangeData,
    DialogMessageToRobotArgs,
    MRCPCode,
    ScoreToRobotArgs,
    ScoreType,
    Suggestion
} from "../chat.model";
import {util} from "@naumen/rappid/build/rappid";
import * as urls from "../../../../../../js/workplace/urls";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import WebSocketService from "../../../../services/WebSocketService";
import {Candidate, ChatOnPrompterArgs} from "../prompter/prompter.model";
import {Title} from "@angular/platform-browser";
import {SnackbarComponent} from "../../common/snackbar/snackbar.component";
import {MatSnackBar} from "@angular/material";
import {ChatControlService} from "../chat-control.service";
import {ChatRobotCommand} from "../../dialog/model/reply.model";


@Component({
    selector: 'chat-panel',
    template: require('./chat-panel.component.html'),
    styles: [require('./chat-panel.component.less')]
})

export class ChatPanelComponent implements OnInit, OnDestroy {

    @ViewChild("userMessageTextArea", {static: false})
    userMessageTextArea: ElementRef<HTMLElement>;

    @ViewChild('fileInput', {static: false})
    fileInputElement: ElementRef<HTMLInputElement>;

    /**
     * Элементы для отрисовки превью загруженной картинки
     */
    @ViewChild('canvas', {static: false})
    public canvas: ElementRef;

    /**
     * Скроллящийся блок с чатом
     */
    @ViewChild('scrollChatBlock', {static: false})
    scrollChatBlock: any;
    scrollChatBlockHeight: number = 0;

    /**
     * Если диалог завершился, то надо проскроллить до последнего сообщения 1 раз
     */
    needLastScroll: boolean = true;

    prompter: boolean = false;
    isLoading: boolean = false;
    ctx: CanvasRenderingContext2D;
    attributes: VaAttribute[];
    tags: VaTag[];
    commands: MRCPCode[];
    form: ChatForm = new ChatForm();
    starForm: StarForm = new StarForm();
    private readonly subscriptionId: string;
    uploader: FileUploader;

    /**
     * Выбранный в суфлере кандидат
     */
    candidate: Candidate;

    /**
     * Пользователь
     */
    private expert: any;

    /**
     * максимальное количество автокомплит предложений в кеше
     */
    private maxSuggestionsLength: number = 10;

    /**
     * задача на пересчет автокомплита
     */
    private autocompleteTask: any;

    /**
     * кеш для автокомплита
     */
    private autocompleteSuggestionsCache: Map<string, ChatOnAutocompleteArgs> = new Map<string, ChatOnAutocompleteArgs>();

    /**
     * id версии
     */
    private readonly projectVersionId: string;

    /**
     * Истина, если оператор поменял ответ суфлера или прикрепленный файл
     */
    private operatorChangePrompterAnswer: boolean;

    private closeDialogSubscription: any;
    private onControlSendSubscription: any;
    private candidateSubscription: any;

    constructor(private stateService: StateService,
                private dialogService: DialogService,
                public chatControlService: ChatControlService,
                private httpClient: HttpClient,
                private attributeService: AttributeService,
                private tagService: TagService,
                private chatService: ChatService,
                private webSocketService: WebSocketService,
                private snackBar: MatSnackBar,
                protected titleService: Title) {
        this.closeDialogSubscription = this.chatControlService.closeDialog.subscribe(conversationId => this.closeDialog(conversationId))
        this.onControlSendSubscription = this.chatControlService.onControlSend.subscribe(() => this.onControlSend())
        this.candidateSubscription = this.chatControlService.candidate.subscribe(candidate => this.candidateChose(candidate));

        const title: string = this.stateService.current.data.title;
        this.titleService.setTitle(title);
        this.subscriptionId = webSocketService.subscribeOnEvents({
            eventType: "VA_ROBOT_API_DIALOG_RESOLVE",
            fn: (event) => {
                if (event.details == this.chatControlService.dialog?.conversationId) {
                    // закрываем чат и чистим форму
                    this.chatControlService.dialog.finished = true;
                    this.form.clear();
                    this.chatControlService.form.emit(this.form);
                }
            }
        });
        this.projectVersionId = this.stateService.params["projectVersionId"];
    }

    async ngOnInit(): Promise<void> {
        this.chatControlService.form.emit(this.form);

        this.httpClient.get(`${urls.va.account}current`).toPromise().then(account => this.expert = account);
        this.tagService.getTagList().then((tags: VaTag[]) => {
            this.tags = tags;
        });
        this.chatService.getCommands().then((commands: MRCPCode[]) => this.commands = commands);

        this.uploader = new FileUploader({
            autoUpload: true,
            method: 'post',
            url: `${urls.va.file}/upload`,
            httpClient: this.httpClient
        });

        this.uploader.onCompleteItem = (item, response) => {
            this.form.fileUrl = (response as any).downloadUrl;
            this.chatControlService.form.emit(this.form);
            this.enableInput();
        };

        // задача на отрисовку превью картинки
        this.uploader.onAfterAddingAll = (files: FileItem[]) => {
            if (files?.length == 1) {
                this.chatControlService.inputDisabled = true;
                this.form.file = files[0]._file;
                this.chatControlService.form.emit(this.form);
                let reader = new FileReader();
                reader.onload = (ev: ProgressEvent<FileReader>) => {
                    let image = new Image();
                    image.src = ev.target.result as string;
                    image.onload = () => {
                        const imgWidth = image.width;
                        const imgHeight = image.height;
                        let width;
                        let height;
                        if (imgHeight > 50) {
                            // пропорционально уменьшим все для отрисовки
                            const percent = 50 / imgHeight;
                            width = imgWidth * percent;
                            height = 50;
                        } else {
                            width = imgWidth;
                            height = imgHeight;
                        }
                        this.canvas.nativeElement.height = height;
                        let ctx = this.canvas.nativeElement.getContext('2d');
                        ctx.drawImage(image, 0, 0, width, height);
                    };
                };
                reader.readAsDataURL(this.form.file);
            }
        };

        this.isLoading = true;
        // запрашиваем последний диалог эксперта в версии проекта
        const dialog = await this.chatService.getLastDialog();
        if (!dialog) {
            if (sessionStorage.getItem(CHAT_MODE_STORAGE_KEY) == SessionModeEnum.PROMPTER) {
                // режим - суфлер
                this.prompter = true;
                if (this.justifyChatModeState()) {
                    return;
                }
            }
            this.isLoading = false;
            return;
        }
        this.prompter = dialog.dialogContext.prompter;
        if (this.justifyChatModeState()) {
            return;
        }
        sessionStorage.setItem(CHAT_MODE_STORAGE_KEY, dialog.mode.name);
        await this.loadDialog(dialog.id);
        this.isLoading = false;
    }

    /**
     * Разрешить пользовательский ввод
     */
    enableInput() {
        this.chatControlService.inputDisabled = false;
        setTimeout(() => this.userMessageTextArea?.nativeElement.focus(), 50);
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
        this.closeDialogSubscription.unsubscribe();
        this.onControlSendSubscription.unsubscribe();
        this.candidateSubscription.unsubscribe();
    }

    /**
     * Удалить файл
     */
    removeFile() {
        this.uploader.clearQueue();
        this.form.file = null;
        this.form.fileUrl = null;
        this.chatControlService.form.emit(this.form);
        this.operatorChangePrompterAnswer = true;
    }

    /**
     * Открыть форму выбора файла
     */
    onFileSelectClick() {
        this.fileInputElement.nativeElement.click();
    }

    /**
     * Выбран mrcp код - отправляем сообщение
     */
    async onCommandClick(command: MRCPCode): Promise<void> {
        this.form.selectedCommand = command;
        this.chatControlService.form.emit(this.form);
        await this.sendUserMessage();
    };

    /**
     * Нажата кнопка
     * enter - отправить сообщение
     * иначе посчитать автокомплит или команды
     */
    async onKeyType(event: KeyboardEvent): Promise<void> {
        // noinspection JSDeprecatedSymbols
        if (event.key === 'Enter' && !event.shiftKey) {
            // enter - отправить сообщение
            event.preventDefault();
            await this.sendText();
            return;
        }

        this.operatorChangePrompterAnswer = true;

        if (this.autocompleteTask) {
            // отменим старый запрос к автокомплиту
            clearTimeout(this.autocompleteTask);
        }

        this.autocompleteTask = setTimeout(() => {
            const text = this.form.text ? this.form.text.trim() : null;
            if (!text) {
                return;
            }
            // если текст начинается со слеша, значит покажем mrcp команды
            if (text === "/") {
                this.form.commands = this.commands;
                this.chatControlService.form.emit(this.form);
            } else {
                this.form.commands = [];
                this.chatControlService.form.emit(this.form);
            }
            this.chatControlService.form.emit(this.form);
            if (this.form.text.length <= 2 || text === this.form.lastAutocompleteSuggestionsRequest) {
                // автокомплит начнём с 3 символов
                // если запрос дублирует предыдущий -- ничего не делаем
                this.form.autocompleteSuggestions = [];
                this.chatControlService.form.emit(this.form);
                return;
            }
            // запомним текущий запрос
            this.form.lastAutocompleteSuggestionsRequest = text;
            this.chatControlService.form.emit(this.form);
            const cachedAutocompleteResponse = this.autocompleteSuggestionsCache.get(text);
            if (cachedAutocompleteResponse) {
                this.form.autocompleteSuggestions = cachedAutocompleteResponse.suggestions;
                this.chatControlService.form.emit(this.form);
                return;
            }

            this.chatService.getAutocompleteMessages(this.projectVersionId,
                new AutocompleteToRobotArgs(this.chatControlService.dialog ? this.chatControlService.dialog.conversationId : null, text))
                .then((response: ChatOnAutocompleteArgs) => {
                    this.form.autocompleteSuggestions = response.suggestions;
                    this.chatControlService.form.emit(this.form);
                    let suggestionsKeys = Object.keys(this.autocompleteSuggestionsCache);
                    if (suggestionsKeys.length > this.maxSuggestionsLength) {
                        // кеш больше максимального размера, удаляем самую старую запись
                        // порядок ключей вроде как гарантирован (во всяком случае для хрома)
                        delete this.autocompleteSuggestionsCache[suggestionsKeys[0]];
                    }
                    // кешируем результат
                    this.autocompleteSuggestionsCache[text] = response;
                }, () => {
                });
        }, 300);
    };

    /**
     * Клик на кнопку в ленте диалога - отправляем сообщение
     */
    async onDialogButtonClick(key: string): Promise<void> {
        this.form.buttonKey = key;
        this.chatControlService.form.emit(this.form);
        await this.sendUserMessage();
    }


    /**
     * Выбрана "прерванная" реплика
     */
    onInterruptedSelect(interrupted: number) {
        this.form.interrupted = interrupted;
    }

    /**
     * Посчитать апи атрибуты для запроса
     */
    getAttributes(): any {
        let values = {};
        this.form.selectedAttributes.forEach(valueDto => {
            switch (valueDto.info.extractor.valueType.name) {
                case ValueTypeEnum.ENUM:
                    values[valueDto.info.apiKey] = valueDto.value.apiKey;
                    break;
                case ValueTypeEnum.DATE:
                    values[valueDto.info.apiKey] = moment(valueDto.value).format("YYYY-MM-DD,HH:mm:ss.SSS");
                    break;
                default:
                    values[valueDto.info.apiKey] = valueDto.value;
                    break;
            }
        });
        this.chatControlService.form.emit(this.form);
        return values;
    }

    /**
     * Закрыть диалог
     */
    closeDialog(conversationId: any): void {
        this.chatService.closeDialog(this.projectVersionId, {sessionId: conversationId})
            .then(() => {
                if (this.chatControlService.dialog) {
                    this.chatControlService.dialog.finished = true;
                }
            }).catch((response: HttpErrorResponse) => {
            this.displayResponseError(response);
        });
    }

    /**
     * Нажатие отправки в контрольной панели
     */
    async onControlSend(): Promise<void> {
        if (this.chatControlService.dialog?.finished) {
            this.chatControlService.dialog = null;
            // если диалог завершен, очищаем форму для начала нового диалога
            this.starForm = new StarForm();
            // первое сообщение - от пользователя
            this.chatControlService.operator = false;
            if (this.isPrompterState() && sessionStorage.getItem(CHAT_MODE_STORAGE_KEY) != SessionModeEnum.PROMPTER) {
                // возвращаемся на чат с роботом
                this.stateService.go(CHAT_ROBOT_STATE);
            }
        } else {
            await this.sendText();
        }
    }

    /**
     * Находимся ли на стейте суфлера
     */
    private isPrompterState() {
        const name = this.stateService.current.name;
        switch (name) {
            case CHAT_PROMPTER_STATE:
                return true;
            case CHAT_ROBOT_STATE:
                return false;
            default:
                throw new Error("unexpected state " + name);
        }
    }

    /**
     * Отправка текста из поля ввода
     */
    async sendText(): Promise<void> {
        if (this.prompter && this.chatControlService.operator) {
            // оператор шлет в режиме суфлера
            await this.sendOperatorMessage();

        } else {
            // шлет пользователь
            await this.sendUserMessage();
        }
    }

    /**
     * Отправка операторского сообщения в режиме суфлера
     */
    async sendOperatorMessage() {
        this.chatControlService.inputDisabled = true;
        if (!this.operatorChangePrompterAnswer && this.candidate) {
            await this.chatService.prompterChoice(this.stateService.params['projectVersionId'], this.chatControlService.dialog.conversationId, this.candidate.id);
        } else {
            await this.chatService.prompterChoice(this.stateService.params['projectVersionId'], this.chatControlService.dialog.conversationId, null, this.form.text);
        }
        this.chatControlService.operator = false;
        this.form.text = '';
        this.chatControlService.form.emit(this.form);
        this.enableInput();
        await this.afterMessageSend(this.chatControlService.dialog.id);
        this.chatControlService.onDialogChange.emit(new DialogChangeData(this.chatControlService.dialog.id, this.chatControlService.dialog.conversationId, false));
        this.operatorChangePrompterAnswer = false;
    }

    /**
     * Отправка пользовательского сообщения/кнопки/тематики
     */
    async sendUserMessage(): Promise<void> {
        try {
            this.chatControlService.inputDisabled = true;
            const args = this.prepareMessageArgs();
            if (this.prompter) {
                // если это промптер, то надо просто отправить запрос на регистрацию реплики юзера через апи.
                // Кандидаты запрашиваются через контроллер, так как их надо брать из кеша
                const response: ChatOnPrompterArgs = await this.chatService.sendPrompterMessage(this.projectVersionId, args);
                await this.afterMessageSend(response.dialogId);
                this.chatControlService.onDialogChange.emit(new DialogChangeData(response.dialogId, args.sessionId, true));
                this.chatControlService.operator = true;
            } else {
                // обычная отправка сообщения через диалог апи
                const response: ChatOnReplyArgs = await this.chatService.sendMessage(this.projectVersionId, args)
                await this.afterMessageSend(response.dialogId);
                if (response.command == ChatRobotCommand.REROUTE_STRICT) {
                    await this.loadDialog(response.dialogId);
                }
            }
        } catch (e) {
            this.displayResponseError(e);
        } finally {
            this.enableInput();
            this.operatorChangePrompterAnswer = false;
        }
    }

    /**
     * Подготовить запрос к апи с сообщением от пользователя
     */
    private prepareMessageArgs() {
        const storedMode = sessionStorage.getItem(CHAT_MODE_STORAGE_KEY);
        const mode = storedMode || SessionModeEnum.ROBOT;
        const args = new DialogMessageToRobotArgs(this.chatControlService.dialog ? this.chatControlService.dialog.conversationId : util.uuid(), mode);
        args.message = this.form.text;
        args.expertId = this.expert.id;
        if (this.form.selectedCommand) {
            args.mrcpCode = this.form.selectedCommand.causeCode;
            args.message = null;
        }
        args.buttonKey = this.form.buttonKey;
        args.params = this.getAttributes();
        args.tagId = this.form.selectedTag?.key.id;
        if (this.form.file) {
            args.attachments = [new Attachment(this.form.file.name, this.form.fileUrl)];
        }
        args.interrupted = this.form.interrupted;
        return args;
    }

    /**
     * Отобразить ошибку из ответа апи
     */
    private displayResponseError(error: Error) {
        if (!(error instanceof HttpErrorResponse)) {
            throw error;
        }
        if (error.error.message == 'data required: message or buttonKey or tagId or attachments') {
            this.form.error = 'Необходимо ввести текст сообщения и/или выбрать тематику, вложение, кнопку';
        } else {
            this.form.error = error.error.status + ": " + error.error.message;
        }
        this.chatControlService.form.emit(this.form);
        this.displayError();
    }

    /**
     * Перезагрузить диалог после отправки сообщения
     */
    private async afterMessageSend(dialogId: number): Promise<void> {
        this.form.clear();
        this.chatControlService.form.emit(this.form);
        if (!this.chatControlService.dialog) {
            // если это новое обращение, то надо загрузить диалог
            await this.loadDialog(dialogId);
        }
    }

    /**
     * Загрузка диалога
     */
    private async loadDialog(dialogId: number): Promise<void> {
        this.chatControlService.dialog = await this.dialogService.getDialog(dialogId);

        this.prompter = this.chatControlService.dialog.dialogContext.prompter;
        this.justifyChatModeState();
        if (this.prompter) {
            // посылаем сигнал об изменении диалога в суфлер
            const lastIsUser = this.chatControlService.dialog.replies[this.chatControlService.dialog.replies.length - 1].isUser;
            this.chatControlService.onDialogChange.emit(new DialogChangeData(this.chatControlService.dialog.id, this.chatControlService.dialog.conversationId, lastIsUser));
            this.chatControlService.operator = lastIsUser;
        }
        this.isLoading = false;
    }

    /**
     * Поставить оценку диалогу через апи
     */
    rate(starAmount: number): void {
        this.starForm.selected = starAmount;
        this.chatService.rateDialog(this.projectVersionId,
            new ScoreToRobotArgs(this.chatControlService.dialog.conversationId, starAmount, ScoreType.ABONENT))
            .then(() => {
            }).catch((response: HttpErrorResponse) => {
            this.displayResponseError(response);
        });
    }

    /**
     * Наведение курсора на звездочки
     */
    hoverRate(starAmount: number): void {
        this.starForm.hoverSelected = starAmount;
    }

    /**
     * Убрать курсор со звездочек
     */
    leaveRate(): void {
        this.starForm.hoverSelected = null;
    }

    /**
     * Отобразить снэк-бар с описанием ошибки
     */
    private displayError() {
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 10000,
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
            panelClass: 'chat-error-snack-bar',
            data: {text: this.form.error}
        });
    }

    scrollChatBlockChange() {
        if (!this.chatControlService.dialog.finished) {
            this.needLastScroll = !this.chatControlService.dialog.finished;
            this.scrollChatBlockHeight = this.scrollChatBlock.nativeElement.scrollHeight;
        } else {
            if (this.needLastScroll) {
                this.needLastScroll = false;
                this.scrollChatBlockHeight = this.scrollChatBlock.nativeElement.scrollHeight;
            }
        }
    }

    /**
     * Поменять отправителя
     */
    switchSender(op: boolean) {
        if (!this.chatControlService.dialog) {
            // первое сообщение только от пользователя
            this.chatControlService.operator = false;
            return;
        }
        this.chatControlService.operator = op;
    }

    /**
     * Изменения в данных
     */
    async candidateChose(candidate: Candidate) {
        this.form.text = candidate.text;

        // перенос каретки
        (document.querySelector('#masha') as any).focus();

        //todo надо исправить в рамках задачи с добавлением нескольких файлов
        if (candidate.attachments && candidate.attachments.length != 0) {
            this.form.file = new File(["foo"], candidate.attachments[0].filename, {type: candidate.attachments[0].mimetype});
            this.form.fileUrl = candidate.attachments[0].url;
        }

        this.enableInput()
        this.candidate = candidate;
        this.operatorChangePrompterAnswer = false;
        this.form.bottomOffset = 0;
    }

    /**
     * Сравнить стейт с текущим режимом диалога, если отличается, перейти на нужный стейт
     */
    private justifyChatModeState() {
        if (this.prompter) {
            if (!this.isPrompterState()) {
                // переходим на стейт чата с суфлером
                this.stateService.go(CHAT_PROMPTER_STATE);
                return true;
            }
        } else if (this.isPrompterState()) {
            // переходим на стейт чата с роботом
            this.stateService.go(CHAT_ROBOT_STATE);
            return true;
        }
    }
}

export class StarForm {
    hoverSelected: number;
    selected: number;
}

export class ChatForm {
    text: string;
    commands: MRCPCode[];
    lastAutocompleteSuggestionsRequest: string;
    autocompleteSuggestions: Suggestion[];
    selectedAttributes: ExtractableValuesSerDTO[] = [];
    selectedTag: VaTag;
    selectedCommand: MRCPCode;
    file: File;
    fileUrl: string;
    buttonKey: string;
    interrupted: number;
    error: string;
    bottomOffset: number = 0;


    clear(): void {
        this.text = null;
        this.commands = [];
        this.autocompleteSuggestions = [];
        this.selectedAttributes = [];
        this.selectedTag = null;
        this.selectedCommand = null;
        this.file = null;
        this.fileUrl = null;
        this.buttonKey = null;
        this.error = null;
        this.bottomOffset = 0;
        this.interrupted = null;
    }
}

/**
 * Ключ стораджа для хранения режима проведения диалога
 */
export const CHAT_MODE_STORAGE_KEY = 'chat_mode';

/**
 * Стейт чата с роботом
 */
export const CHAT_ROBOT_STATE = 'robot.chat';

/**
 * Стейт чата с суфлером
 */
export const CHAT_PROMPTER_STATE = 'robot.prompter';


