import {BaseFormController} from "../BaseFormController";

export default class PushController extends BaseFormController {

    constructor($rootScope, $scope, $state, $cookies, Restangular, FileUploader, TitleService) {
        super($scope, $state);
        TitleService.setTitle();
        var that = this;
        that.restAngular = Restangular;
        // форма для отправки
        that.form.hideSuccess = true;
        that.form.data = {
            msisdnStr: "",
            message: ""
        };
        // сюда будем сохранять результат
        that.result = {};

        that.upload = new FileUploader({
            url: "/account/push/sendWithFile",
            alias: 'file',
            removeAfterUpload: true,
            queueLimit: 1,
            headers: {
                "X-XSRF-TOKEN": $cookies.get("XSRF-TOKEN")
            }
        });
        // перед отправкой файла - положим ему туда сообщение
        that.upload.onBeforeUploadItem = function (item) {
            item.formData.push({"message": that.form.data.message});
        };

        that.upload.onCompleteItem = function (item, response, status, headers) {
            that.setLoading(true);
            console.log("Upload result: " + JSON.stringify(response));
            // очистить очередь загрузки
            that.upload.clearQueue();
            $("#chat-file-upload").val(null);
            console.log("RESPONSE: " + JSON.stringify(response));

            if (response.result) {
                console.log("RESULT: " + JSON.stringify(response.result));
                // картинка загружена успешно, нужно заменить ею текущую
                that.handleSuccess(response);
                that.result = response.result;
            } else {
                // если загрузка не удалась - покажем ошибку
                that.handleError(response);
            }
        };

        // отправить либо файл, либо набор номеров из текстового поля
        that.onSubmit = function () {
            if (that.upload.queue.length) {
                that.onSubmitFile();
            } else {
                that.onSubmitText();
            }
        };

        that.onSubmitFile = function () {
            console.log("File submitted");
            // установим какие данные отправлять вместе с файлом
            that.setLoading(true);
            that.upload.uploadAll();
        };

        that.onSubmitText = function () {
            console.log("Text submitted");
            that.setLoading(true);
            that.restAngular.all("/account/push/sendWithoutFile/").post(that.form.data)
                .then(function success(data) {
                        that.handleSuccess(data);
                        that.result = data;
                    }, function error(data) {
                        that.handleError(data);
                    }
                );
        };
    }
}

PushController.$inject = ["$rootScope", "$scope", "$state", "$cookies", "Restangular", "FileUploader", "TitleService"];

