import {NgModule} from "@angular/core";
import {TextToSpeechService} from "./text-to-speech.service";

@NgModule({
    imports: [],
    declarations: [],
    entryComponents: [],
    providers: [
        TextToSpeechService
    ]
})
export class TextToSpeechModule {

}