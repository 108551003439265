function AccountIdSelectorDirective() {
    return {
            restrict: "EA",
            scope: {
                selectorCollection: "=",
                selectorModel: "=",
                selectorMultiple: "=",
                selectorFieldName: "=",
                disabled: "="
            },
            templateUrl: "/pages/workplace/selectors/accountIdSelector.html",
            controller: function () {
            },
            bindToController: true,
            controllerAs: "dCtrl"
        };

}

AccountIdSelectorDirective.$inject = [];
export default AccountIdSelectorDirective;