import restangular from "restangular";
import * as urls from "../../../../../js/workplace/urls.js";

export default class VaTagEditModalController {

    static $inject = ["$uibModalInstance", "parent", "Restangular", "$stateParams"];

    private parent: any;

    private Restangular: restangular.IService;

    private form: any;

    private $uibModalInstance: any;

    constructor($uibModalInstance, parent, Restangular: restangular.IService, $stateParams) {
        this.parent = parent;
        this.Restangular = Restangular;
        this.$uibModalInstance = $uibModalInstance;
        
        this.form = {
            isLoading: false,
            object: {
                text: "",
                useInTrain: true,
                parentId: parent.key.id
            }
        };
    }

    cancel() {
        this.$uibModalInstance.close();
    }

    save() {
        this.form.isLoading = true;

        this.Restangular.all(urls.va.tags).post(this.form.object).then((data) => {
            this.$uibModalInstance.close();
        }, (data) => {
            this.form.errors = {};
            // загрузка закончена
            this.form.isLoading = false;
            this.form.success = false;
            const errors = data.errors;

            // пришла 503, 502, 501, 500........ ошибка
            if (!errors) {
                if(data.status == 403){
                    this.form.errors["#main"] = "Доступ запрещен";
                } else {
                    this.form.errors["#main"] = "Внутренняя ошибка системы, повторите запрос позже";
                }
                return;
            }
            // список ошибок от сервера
            for (let i = 0; i < errors.length; i++) {
                const error = errors[i];
                let fieldKey = error.field;
                const msg = error.message;
                if (!fieldKey) {
                    // ошибка без привязки к полю
                    this.form.errors["#main"] = msg;
                } else {
                    this.form.errors[fieldKey] = msg;
                }
            }
        });
    }

}