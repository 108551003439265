/**
 * директива построения реадктируемого списка
 * Created by vev
 */
function ListWithInputDirective() {
    return {
        restrict: "EA",
        replace: true,
        scope: {
            field: "@",
            listIcon: "@",
            placeholder: "@",
            disabled: "=",
            items: "="
        },
        templateUrl: "/pages/workplace/elements/listWithInput.html",
        controller: function () {

            var that = this;
            that.errorMessage = "";
            that.item = {
                [that.field]: ""
            };
            that.editable = false;

            that.add = function () {
                if (that.item[that.field] == "") {
                    that.errorMessage = "Необходимо ввести текст";
                } else {
                    that.errorMessage = "";
                        if (!that.editable) {
                            that.items.push(that.item);
                        }
                    that.item = {};
                    that.item[that.field] = "";
                    that.editable = false;
                }
            };

            that.remove = function (item) {
                if ((that.item.id && item.id == that.item.id) || item[that.field] == that[that.field]) {
                    that.item = {};
                    that.item[that.field] = "";
                }
                for (var i = 0; i < that.items.length; i++) {
                    var elem = that.items[i];
                    if (item.id && elem.id && item.id == elem.id) {
                        that.items.splice(i, 1);
                    } else if (item[that.field] && item[that.field] == elem[that.field]) {
                        that.items.splice(i, 1);
                    }
                }
            };

            that.edit = function (item) {
                that.item = item;
                that.editable = true;
            }
        },
        bindToController: true,
        controllerAs: "liCtrl"
    };
}

export default ListWithInputDirective;