import {NgModule} from "@angular/core";
import {CollectionModule} from "../../common/collection/collection.module";
import {UIRouterModule} from "@uirouter/angular";
import {CommonModule} from "@angular/common";
import {ExpertModule} from "../../common/expert/expert.module";
import {MacroModule} from "../../common/macro/macro.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {FormsModule} from "@angular/forms";
import {FormulationModule} from "../../formulation/formulation.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {TaggingItemModule} from "../../common/tagging-item/tagging-item.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {CustomizationScriptEditComponent} from "./details/customization-script-edit.component";
import {CustomizationScriptMasterComponent} from "./master/customization-script-master.component";
import {CustomizationScriptService} from "./customization-script.service";
import {AceEditorModule} from "ng2-ace-editor";

@NgModule({
    declarations: [
        CustomizationScriptEditComponent,
        CustomizationScriptMasterComponent
    ],
    imports: [
        CollectionModule,
        UIRouterModule,
        CommonModule,
        ExpertModule,
        MacroModule,
        EntityUsageModule,
        FormControlModule,
        FormsModule,
        FormulationModule,
        NgSelectModule,
        TaggingItemModule,
        BoilerplateModule,
        AceEditorModule,
    ],
    exports: [
        CustomizationScriptEditComponent,
        CustomizationScriptMasterComponent
    ],
    providers: [
        CustomizationScriptService
    ]
})
export class CustomizationScriptModule {

    static states = [
        {
            name: "robot.customization_script",
            url: "/customization_script",
            views: {
                'content@authorized': {
                    component: CustomizationScriptMasterComponent
                }
            },
            data: {
                title: "Скрипты",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.customization_script.edit',
            url: "/edit/:scriptId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.customization_script': {
                    component: CustomizationScriptEditComponent
                }
            },
            data: {
                title: "Скрипты | ",
                newObjectTitle: "Новый скрипт",
                editObjectTitle: "Редактирование скрипта",
                successfulSaveMessage: "Скрипт сохранён",
                meta: {
                    parameters: ["scriptId"],
                    sectionFunction: ([scriptId]) => "Обучение робота",
                    subSectionFunction: ([scriptId]) => scriptId ? "Редактирование скрипта" : "Новый скрипт"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];

}