import {NgModule} from "@angular/core";
import {CollectionModule} from "../../common/collection/collection.module";
import {UIRouterModule} from "@uirouter/angular";
import {CommonModule} from "@angular/common";
import {ExpertModule} from "../../common/expert/expert.module";
import {MacroModule} from "../../common/macro/macro.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {FormsModule} from "@angular/forms";
import {FormulationModule} from "../../formulation/formulation.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {TaggingItemModule} from "../../common/tagging-item/tagging-item.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {AccountEditComponent} from "./details/account-edit.component";
import {AccountMasterComponent} from "./master/account-master.component";
import {AccountService} from "./account.service";
import {AceEditorModule} from "ng2-ace-editor";
import {DenyButtonModule} from "../../common/deny-button/deny-button.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [
        AccountEditComponent,
        AccountMasterComponent
    ],
    imports: [
        CollectionModule,
        UIRouterModule,
        CommonModule,
        ExpertModule,
        MacroModule,
        EntityUsageModule,
        FormControlModule,
        FormsModule,
        FormulationModule,
        NgSelectModule,
        TaggingItemModule,
        BoilerplateModule,
        AceEditorModule,
        DenyButtonModule,
        MatTooltipModule,
    ],
    exports: [
        AccountEditComponent,
        AccountMasterComponent
    ],
    providers: [
        AccountService
    ]
})
export class AccountModule {

    static states = [
        {
            name: "organizations.accounts",
            url: "/accounts",
            views: {
                "content@authorized": {
                    component: AccountMasterComponent
                }
            },
            data: {
                title: "Пользователи"
            }

        },
        {
            name: "organizations.accounts.edit",
            url: "/:accountId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@organizations.accounts": {
                    component: AccountEditComponent
                }
            },
            data: {
                title: "Пользователи | ",
                newObjectTitle: "Новый аккаунт",
                editObjectTitle: "Редактирование",
                successfulSaveMessage: "Пользователь сохранён",
                meta: {
                    parameters: ["accountId"],
                    sectionFunction: ([accountId]) => "Обучение робота",
                    subSectionFunction: ([accountId]) => accountId ? "Редактирование аккаунта пользователя" : "Создание нового аккаунта пользователя"
                },
                isCheckObjectExist: true
            }
        }
    ];
}
