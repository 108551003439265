export default function ProgressBarDirective() {
    return {
        restrict: "EA",
        scope: {
            currentCount: '<',
            overallCount: '<',
            name: '<',
            barClass: '@barClass',
        },
        transclude: true,
        link: function (scope, elem, attrs) {
            scope.label = attrs.label;
            scope.showPercent = (attrs.showPercent) || false;
            scope.completedClass = (scope.completedClass) || 'progress-bar-success';
            scope.$watch('complete', () => {
                // Когда доходит до 100%, то делаем progress bar делаем зелёным
                let progress = scope.currentCount / scope.overallCount;
                if (progress >= 1) {
                    $(elem).find('.progress-bar').addClass(scope.completedClass);
                }
                else if (progress < 1) {
                    $(elem).find('.progress-bar').removeClass(scope.completedClass);
                }
            });
        },
        template:
        "<div class='progress-bar-title'>{{entityName}}</div>" +
        "<div class='progress'>" +
        "<div class='progress-bar {{barClass}}' title='{{currentCount/overallCount * 100 | number: 0 }}%' style='width:{{currentCount/overallCount * 100}}%;'>" +
        "{{(currentCount/overallCount * 100).toFixed(0)}}%</div>" +
        "</div>"
    };

}