/**
 * Created by semyon on 17.06.16.
 */

module.exports = [
    {
        state: "login",
        options: {
            title: "Авторизация",
            url: "/login?nextUrl&params&{from_auth_interceptor:bool}",
            views: {
                "main@": {
                    templateUrl: "/pages/workplace/auth/loginForm.html",
                    controller: "LoginController",
                    controllerAs: "lCtrl"
                }
            }
        }
    },
    {
        state: "login2",
        options: {
            title: "Авторизация",
            url: "/?nextUrl&params&{from_auth_interceptor:bool}",
            views: {
                "main@": {
                    templateUrl: "/pages/workplace/auth/loginForm.html",
                    controller: "LoginController",
                    controllerAs: "lCtrl"
                }
            }
        }
    },
    {
        state: "login3",
        options: {
            title: "Авторизация",
            url: "?nextUrl&params&{from_auth_interceptor:bool}",
            views: {
                "main@": {
                    templateUrl: "/pages/workplace/auth/loginForm.html",
                    controller: "LoginController",
                    controllerAs: "lCtrl"
                }
            }
        }
    },
    {
        state: "authorized",
        options: {
            url: "/workplace",
            views: {
                "main@": {
                    templateUrl: "/pages/workplace/authorized.html"
                }
            }
        }
    }
];