import {Component} from "@angular/core";
import {CompositeKey} from "../../../../../data/va/Common";
import {StateService, TransitionService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {BaseDetailsComponent, DetailsMode} from "../../base/base-details.component";
import {Title} from "@angular/platform-browser";
import {setAudioLength, TranscriptionMasterComponent} from "../master/transcription-master.component";
import {TranscriptionDialog, TranscriptionReply, TranscriptionStatusEnum} from "../transcription.model";
import {TranscriptionService} from "../transcription.service";
import {AppConfigService} from "../../../../service/app-config.service";
import ClickFilter from "../../../../../../util/click.filter";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";


/**
 * Транскрибирование диалога
 */
@Component({
    selector: 'transcription-edit',
    template: require('./transcription-edit.component.html'),
    styles: [require('./transcription-edit.component.less')]
})
export class TranscriptionEditComponent extends BaseDetailsComponent<TranscriptionDialog, CompositeKey<number>> {

    objectIdKey = 'dialogId';

    /**
     * Для "Отметить все реплики как корректные"
     */
    checkAll: boolean = false;

    /**
     * Для плеера
     */
    file: any;

    /**
     * Таймзона сервера
     */
    timezone: string;

    /**
     * Время начала проигрывания
     */
    audioTime: number = 0;

    /**
     * Время окончания проигрывания
     */
    stopTime?: number;

    /**
     * Показать плеер
     */
    showPlayer: boolean;

    /**
     * Состояние сохранения
     */
    saveState: SaveState;

    /**
     * Листенер кликов
     */
    private clickListener = (event: MouseEvent) => this.onFinishEditReply(event);

    constructor(protected stateService: StateService,
                protected master: TranscriptionMasterComponent,
                protected httpClient: HttpClient,
                public dataService: TranscriptionService,
                protected titleService: Title,
                protected notificationService: NotificationService,
                private transitionService: TransitionService,
                private appConfigService: AppConfigService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);

        this.timezone = appConfigService.interfaceConfig.serverTimezone;

        // Валидируем текст реплики, выходим из ее редактирования по клику вне текст-арии
        document.addEventListener('click', this.clickListener);
        // при уходе удаляем листенер
        this.registerLeaveAction();

        // навешиваем утильный класс для убирания срабатывания двух сингл-кликов при дабл-клике
        const filter = new ClickFilter(this);
        this.playReply = filter.clickHandler(this.playReply);
        this.editReply = filter.dblClickHandler(this.editReply);
    }

    async ngOnInit(): Promise<void> {
        this.loadFile();
        await super.ngOnInit();
    }

    /**
     * Прописывает длительность записи в удобном формате и прописывает время старта каждой реплики
     */
    onObjectLoaded() {
        setAudioLength(this.form.object);
        this.saveState = this.form.object.status.name == TranscriptionStatusEnum.CHECKED ? SaveState.SAVED : null;
        this.loadAccount().then(() => {
            if (this.form.object.editingExpertId != this.account.id) {
                this.notificationService.error('Действия недоступны, с диалогом работает другой пользователь', 3000);
                this.cancel();
            }
        })
    }

    generateFormObject(): TranscriptionDialog {
        return undefined;
    }

    get entityTitle(): string {
        return this.form.object?.fileName;
    }

    get formTitle(): string {
        switch (this.mode) {
            case DetailsMode.EDIT:
                return this.form.object?.fileName;
            default:
                throw new Error("Unexpected mode");
        }
    }


    get removeConfirmMessage(): string {
        return "Вы уверены, что хотите удалить аудиофайл?";
    }


    showCancel(): boolean {
        return true;
    }


    get saveButtonText(): string {
        return "Сохранить";
    }


    async finishSave(savePromise, isNew: boolean, preserveState: boolean): Promise<void> {
        try {
            this.form.object = await savePromise;
            //  после сохранения слой закрывается
            this.cancel();
        } catch (e) {
            this.setLoading(false);
            this.handleError(e);
        }

    }

    /**
     * “Сохранить”. Доступна только после установки хотя бы одного чекбокса и если нет редактирования
     */
    isSaveButtonAccess(): boolean {
        return super.isSaveButtonAccess() && !this.isEditReplyInProgress();
    }

    /**
     * Отметить все реплики как корректные
     */
    async onCheckAll(): Promise<void> {
        this.form.object.replies.forEach(reply => reply.checkedReply = this.checkAll);
        await this.saveOnChanges();
    }

    /**
     * Загрузить файл
     */
    loadFile(): void {
        const dialogId = this.stateService.params[this.objectIdKey];
        if (!dialogId) {
            return;
        }
        this.dataService.loadFile(dialogId, (dataUrl) => this.file = dataUrl, () => {});
    }

    /**
     * Проиграть диалог с заданной реплики
     */
    playReply($event: MouseEvent, replyToPlay: TranscriptionReply): void {
        if ($event.ctrlKey || $event.metaKey) {
            replyToPlay.channel = replyToPlay.channel == 1 ? 2 : 1;
            // noinspection JSIgnoredPromiseFromCall
            this.saveOnChanges();
            return;
        }
        if (this.form.object?.replies.some(reply => reply.edit && reply.key.id == replyToPlay.key.id)) {
            // проверяем, что это не "клик" после "дабл-клик" -  не редактируем ли мы эту реплику уже
            return;
        }
        // находим время начала следующей реплики
        const nextReplyStartTime = Math.min(...this.form.object?.replies.map(reply => reply.startTime)
            .filter(otherReplyStartTime => otherReplyStartTime > replyToPlay.startTime));

        // запускаем проигрывание от начала текущей реплики до начала следующей
        this.playFromTime(replyToPlay.startTime, nextReplyStartTime);
    }

    /**
     * Проиграть с заданного времени
     */
    playFromTime(startTime: number, stopTime?: number) {
        this.showPlayer = true;
        if (this.audioTime == startTime) {
            // если время уже на заданном, сдвигаем его, чтобы получился эвент
            this.audioTime = 0.01;
        }
        // проигрываем, задавая время старта
        setTimeout(() => {
            this.stopTime = stopTime;
            this.audioTime = startTime;
        }, 1);
    }

    /**
     * Начать редактирование текста реплики
     */
    editReply(editReply: TranscriptionReply): void {
        if (this.isEditReplyInProgress()) {
            // нашли другую редактирующуюся реплику - не прошла ее валидация, не редактируем новую
            return;
        }
        // флаг редактирования
        this.form.object.replies.forEach(reply => reply.edit = (reply.key.id === editReply.key.id));
        // чтобы мочь откатить изменения
        editReply.textCopy = editReply.text;
    }

    /**
     * Есть ли реплика в процессе редактирования
     */
    private isEditReplyInProgress() {
        return this.form.object?.replies.some(reply => reply.edit);
    }

    /**
     * Клик по области вне текстового поля сохраняет изменения, режим редактирования выключается.
     * Если текст реплики пустой, выводим ошибку
     */
    private async onFinishEditReply(event: MouseEvent) {
        let target = (event.target as any);
        if (!(target.localName !== 'textarea' && target.className !== "row" && this.form.object)) {
            return;
        }
        let edited;
        this.form.object.replies
            .filter(reply => reply.edit === true)
            .forEach(reply => {
                // реплика обязательна для заполнения, сообщение об ошибке "Обязательно для заполнения".
                if (reply.text === null || reply.text.trim() === '') {
                    reply.error = "Обязательно для заполнения";
                    return;
                }
                edited = true;
                reply.edit = false;
                reply.textCopy = null;
                reply.error = null;
                // Чекбокс, что реплика проверена
                reply.checkedReply = true;
            });
        if (edited) {
            // сохранение при изменении
            await this.saveOnChanges();
        }
    }

    /**
     * По клику на иконку "закрыть" все изменения сбрасываются, режим редактирования выключается.
     */
    denyEdit(editReply: TranscriptionReply): void {
        editReply.text = editReply.textCopy;
        editReply.textCopy = null;
        editReply.error = null;
        editReply.edit = false;
    }

    /**
     * Сохранение при изменениях
     */
    async saveOnChanges() {
        const startTimeMs = Date.now();
        try {
            this.saveState = SaveState.SAVING;
            this.form.object = setAudioLength(await this.dataService.update(this.form.object));
            this.changeSaveState(startTimeMs, SaveState.SAVED);
        } catch (e) {
            this.changeSaveState(startTimeMs, SaveState.ERROR);
        }
    }

    /**
     * Поменять статус сохранения с задержкой
     */
    private changeSaveState(startTimeMs: number, newState: SaveState) {
        setTimeout(() => this.saveState = newState, 1000 - Date.now() + startTimeMs);
    }


    cancel() {
        this.dataService.finishEdit(this.objId);
        super.cancel();
    }

    /**
     * Регистрируем листенер для уведомления об окончании редактирования
     */
    registerLeaveAction() {
        const unregisterLeaveAction = this.transitionService.onStart({}, () => {
            document.removeEventListener('click', this.clickListener);
            // noinspection JSIgnoredPromiseFromCall
            unregisterLeaveAction();
        });
    }
}

enum SaveState {
    SAVING = 'SAVING',
    SAVED = 'SAVED',
    ERROR = 'ERROR',
}