import {Component} from "@angular/core";
import {CompositeKey} from "../../../../../data/va/Common";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {BaseDetailsComponent, DetailsMode} from "../../base/base-details.component";
import {Title} from "@angular/platform-browser";
import {TextData} from "../text-data.model";
import {TextDataMasterComponent} from "../master/text-data-master.component";
import {TextDataService} from "../text-data.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";


@Component({
    selector: 'text-data-edit',
    template: require('./text-data-edit.component.html'),
    styles: [require('./text-data-edit.component.less')]
})
export class TextDataEditComponent extends BaseDetailsComponent<TextData, CompositeKey<number>> {
    objectIdKey = 'dataId';

    constructor(stateService: StateService, master: TextDataMasterComponent, httpClient: HttpClient,
                dataService: TextDataService, titleService: Title, notificationService: NotificationService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    generateFormObject(): TextData {
        return undefined;
    }

    get entityTitle(): string {
        return this.form.object.type.title;
    }


    get formTitle(): string {
        if (this.form.object) {
            return "Редактирование: " + this.form.object.type.title;
        }
        switch (this.mode) {
            case DetailsMode.SHOW:
            case DetailsMode.EDIT:
                return "Редактирование";
            case DetailsMode.NEW:
                return this.metaTexts.newObjectTitle;
        }
    }
}