/**
 * Получить filename из 'Content-Disposition'a
 * @param disposition 'Content-Disposition'
 * @param defaultFilename    имя, на случай если ничего не извлекли
 */
export function extractFileName(disposition: string, defaultFilename?: string): string {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    let fileName;
    if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
    } else {
        fileName = defaultFilename ? defaultFilename : "";
    }
    return fileName;
}

/**
 * Получить описание ошибки из ответа сервера
 *
 * @param data ответ
 * @param callback колбэк по завершению получения ошибки
 */
export function readErrorDescription(data: any, callback: Function) {
    if (data instanceof Blob) {
        const blob: Blob = data;
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(getErrorDescription(JSON.parse(reader.result as string)));
        };
        reader.readAsText(blob)
    } else {
        callback(getErrorDescription(data));
    }
}

/**
 * Получить описание ошибки из данных ответа сервера
 *
 * @param data ответ
 */
export function getErrorDescription(data: any) {
    const defaultError = 'Извините, произошла ошибка';
    if (!data) {
        return defaultError;
    }
    if (data.errors && data.errors.length && data.errors[0].message) {
        return data.errors.map(error => error.message).join('\n');
    }
    if (data.statusText) {
        return `${data.status} ${data.statusText}`;
    }
    if (data.status) {
        return `Код ответа: ${data.status}`;
    }
    return defaultError;

}

export function escapeHtml(text: string): string {
    const entityMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': '&quot;',
        "'": '&#39;',
        "/": '&#x2F;'
    };
    return text.replace(/[&<>"'\/]/g, (s) => {
        return entityMap[s];
    });
}

/**
 * Разбить список на списки меньшего размера
 * @param list список
 * @param size размер
 */
export function chunks<T>(list: T[], size: number): Array<T[]> {

    const chunks: Array<T[]> = [];
    let index = 0;
    let length = list.length;

    while (index < length) {
        chunks.push(list.slice(index, index += size));
    }

    return chunks;
}

/**
 * Сравнить два объекта на равенство по key.id либо name, либо id
 */
export function objectsEqual(obj1, obj2) {
    if (obj1.key) {
        return obj1.key.id === obj2.key.id;
    } else if (obj1.id) {
        return obj1.id === obj2.id;
    } else if (obj1.name) {
        return obj1.name === obj2.name;
    }
}

/**
 * Получить id элемента
 */
export function getItemId(item) {
    return item?.key?.id || item.id;
}

/**
 * Заменить элемент в списке при равенстве по objectsEqual
 *
 * @param items список
 * @param replacement элемент
 * @param assignCallback колб
 */
export function replaceItem(items: any[], replacement, assignCallback: (object: any) => any) {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (objectsEqual(item, replacement)) {
            // копируем объект, чтобы отвязать объект в списке, от объекта в форме редактирования
            const copy = Object.assign({}, replacement);
            items[i] = assignCallback ? assignCallback(copy) : copy;
        }
    }
}

/**
 * Сравнить два объекта по результату заданной функции (геттеру)
 */
export function nullsFirst(entity1: any, entity2: any, ...getters): number {
    if (!entity1) {
        // первая сущность не задана
        return entity2 ? -1 : 0;
    }
    if (!entity2) {
        // вторая сущность не задана
        return 1;
    }
    return getters.map(getter => {
        // по очереди получаем поля и сравниваем их до первого расхождения
        const field1 = getter.call(null, entity1);
        const field2 = getter.call(null, entity2);
        if (!field1) {
            return field2 ? -1 : 0;
        }
        if (!field2) {
            return 1;
        }
        return field1 < field2 ? -1 : field1 > field2 ? 1 : 0;
    }).find(value => value);

}

/**
 * Получить строковое представление значения поля
 *
 * @param object объект
 * @param fieldId название поля или полей через точку
 */
export function getFieldAsString(object: any, fieldId: string): string {
    let values = [object];
    // найти значение поля key.id в объекте object -> (object[key])[id]
    fieldId.split(".").forEach(subField => {
        // сюда складываем значения поля (это может быть массив)
        const newValues = [];
        values.forEach(value => {
            let subValue = value[subField];
            if (Array.isArray(subValue)) {
                // массив - добавляем все значения, потом заджойним
                newValues.push(...subValue);
            } else if (subValue) {
                // значение есть
                newValues.push(subValue);
            } else {
                // значения нет
                newValues.push("");
            }
        });
        // к следующему под-уровню
        values = newValues;
    });
    // джойним все полученные значения
    return values.join(" ").toLowerCase();
}