import {Macro} from "./Macro";

export class MacroPreview {
    text: string;
    index: number;
    macro: Macro;
    file: MacroPreviewFile;

    constructor(text: string, index: number) {
        this.text = text;
        this.index = index;
    }
}

/**
 * file по тексту, чтоы использовать и для ттс
 */
export class MacroPreviewFile {

    text: string;
    file: any;

    constructor(text: string) {
        this.text = text;
    }
}

