import {Component, ViewChild, ViewEncapsulation} from "@angular/core";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {BaseDetailsComponent} from "../../base/base-details.component";
import {Title} from "@angular/platform-browser";
import {AccountMasterComponent} from "../master/account-master.component";
import {AccountService} from "../account.service";
import {Account, AccountRole, AdminType, ExpertType} from "../../../../../data/va/Account";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'account-edit',
    template: require('./account-edit.component.html'),
    styles: [require('./account-edit.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class AccountEditComponent extends BaseDetailsComponent<any, number> {
    @ViewChild('projectSelect', null) projectSelect: any;

    objectIdKey = 'accountId';

    role: AccountRole[];
    expertTypes: ExpertType[];
    adminTypes: AdminType[];
    robotProjects: string[];
    scriptEditDisabled: boolean;

    constructor(stateService: StateService,
                master: AccountMasterComponent,
                httpClient: HttpClient,
                public dataService: AccountService,
                titleService: Title,
                notificationService: NotificationService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    async ngOnInit() {
        await super.ngOnInit();
        this.role = await this.dataService.getRoles();
        let {expertTypes, scriptEditDisabled} = await this.dataService.getExpertData();
        this.expertTypes = expertTypes;
        this.scriptEditDisabled = scriptEditDisabled;
        this.adminTypes = await this.dataService.getAdminTypes();
        this.robotProjects = await this.dataService.getRobotProjects();
    }

    get entityTitle(): string {
        if (this.form.object.fullName) {
            return this.form.object.fullName;
        }

        return "Новый пользователь";
    }

    generateFormObject(): Account {
        return new Account();
    }

    showCancel(): boolean {
        return true;
    }

    showRemove(): boolean {
        return !this.form.object.isDefaultAdmin;
    }

    get removeConfirmMessage() {
        return `Вы уверены, что хотите удалить пользователя?`;
    }

    onObjectLoaded() {
        this.form.object = new Account(this.form.object.account);
    }

    getPasswordTitle() {
        return this.isModeNew() ? "Пароль" : "Задать новый пароль";
    }

    async save(preserveState?: boolean): Promise<void> {
        let update = true;

        if (this.form.object.role?.name != 'EXPERT' && this.form.object.role?.name != 'SUPER_EXPERT') {
            this.form.object.expertType = null;
        }

        if (this.form.object.role?.name != 'ADMINISTRATOR') {
            this.form.object.adminType = null;
        }

        const promise = this.isModeNew() ?
            await this.dataService.save(this.form.object)
                .catch(e => {
                    this.handleError(e);
                    update = false;
                }) :
            await this.dataService.update(this.form.object).catch(e => {
                this.handleError(e);
                update = false;
            });

        if (update) {
            await this.finishSave(promise, this.isModeNew(), false);
            this.form.object = new Account(this.form.object.account);
        }
    }

    selectAll() {
        this.form.object.robotProjects = this.robotProjects;
        this.projectSelect.close();
    }
}
