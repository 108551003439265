import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'transcription-import-dialog',
    template: require('./transcription-import-dialog.component.html')
})
export class TranscriptionImportDialogComponent {

    constructor(public dialogRef: MatDialogRef<TranscriptionImportDialogComponent>) {
        dialogRef.disableClose = true;
    }

    onDeny(): void {
        this.dialogRef.close();
    }
}
