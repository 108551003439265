import {MatDialog} from "@angular/material";
import {ProcedureExitNameDialogComponent} from "../dialog/procedure/procedure-exit-name-dialog.component";
import {RerouteParamsDialogComponent} from "../dialog/reroute/reroute-params-dialog.component";
import {EditEruditeElementDialogComponent} from "../dialog/edit-erudite-element/edit-erudite-element-dialog.component";
import {StepLabelEntity, StepLabelType, StepLabelTypeName, VaScriptNode} from "../../../../data/va/Script";
import {ComponentType} from "@angular/cdk/portal";
import RectangleElement from "../element-view/rectangle/RectangleElement";
import {ExitElement} from "../element-view/rectangle/ExitElement";
import {RerouteElement} from "../element-view/rectangle/RerouteElement";
import {AttributeElement} from "../element-view/rectangle/AttributeElement";
import FormulationSwitchController from "./FormulationSwitchController";
import {EdgeConditionComponent} from "../edge-condition/edge-condition.component";
import EruditeLink from "../link-view/EruditeLink";
import {EventEmitter} from "@angular/core";
import ScriptChangesController from "./ScriptChangesController";
import {getNodeText} from "../ScriptBuilderUtils";


/**
 * Контроллер открытия диалоговых окон
 */
export default class DialogController {

    constructor(public dialog: MatDialog, private changesController: ScriptChangesController) {
    }

    /**
     * Открыть диалоговое окошко (pop-up)
     * @param viewModel узел
     */
    public openDialog(viewModel: RectangleElement) {
        if (viewModel.isDialogable) {
            const dialogComponent = DialogController.selectDialogComponent(viewModel.node);
            // Открываем окно, передаём ему узел
            const dialogRef = this.dialog.open(dialogComponent, {
                width: '600px',
                data: {node: viewModel.node}
            });
            // Слушаем закрытие
            dialogRef.afterClosed()
                .subscribe((result: any) => this.afterClose(result, viewModel));
        }
    }

    /**
     * Выбрать тип компоненты из которой открывать диалоговое окно
     * @param node  узел
     */
    private static selectDialogComponent(node: VaScriptNode): ComponentType<any> {
        switch (node.stepLabel.type.name) {
            case StepLabelTypeName.TO_SUPPORT:
                return RerouteParamsDialogComponent;
            case StepLabelTypeName.EXIT:
                return ProcedureExitNameDialogComponent;
            case StepLabelTypeName.ATTRIBUTE_REQUEST:
                return EditEruditeElementDialogComponent;
            default:
                throw new Error(`Unsupported stepLabel type for dialog component: ${node.stepLabel.type.name}`)
        }
    }

    /**
     * После закрытия диалога
     */
    private afterClose(result: any, viewModel: RectangleElement): void {
        if (result == undefined) {
            if (viewModel.node.stepLabel.type.name == StepLabelTypeName.TO_SUPPORT) {
                DialogController.closeReroute(result, viewModel as RerouteElement);
            }
            return;
        }
        if (result.deny) {
            return;
        }
        this.changesController.initBatchChange();

        switch (viewModel.node.stepLabel.type.name) {
            case StepLabelTypeName.TO_SUPPORT:
                DialogController.closeReroute(result, viewModel as RerouteElement);
                break;
            case StepLabelTypeName.EXIT:
                DialogController.closeProcedureExit(result, viewModel as ExitElement);
                break;
            case StepLabelTypeName.ATTRIBUTE_REQUEST:
                DialogController.closeAttribute(result, viewModel as AttributeElement);
                break;
            default:
                throw new Error(`Unsupported stepLabel type for after close function: ${viewModel.node.stepLabel.type.name}`);
        }
        this.changesController.storeBatchChange();
    }

    /**
     * Изменено значение атрибута
     */
    private static closeAttribute(result: any, viewModel: AttributeElement) {
        if (result?.length > 0) {
            // есть значение
            viewModel.stepLabelValue = result;
            viewModel.removeEarsIfPresent();
        } else if (result === '') {
            // значение удалено
            viewModel.stepLabelValue = null;
            viewModel.addEarsIfNecessary();
        }
        const data = FormulationSwitchController.getViewData(viewModel.node);
        viewModel.formulationCount = data.counterText;
        viewModel.labelText = getNodeText(viewModel.node);
    }

    /**
     * Изменен выход процедуры
     */
    private static closeProcedureExit(result: any, viewModel: ExitElement) {
        let name = result.name;
        viewModel.stepLabelName = name.length > 0 ? name.trim() : null;
        viewModel.labelText = getNodeText(viewModel.node);
    }

    /**
     * Изменены параметры перевода
     */
    static closeReroute(result: any, viewModel: RerouteElement) {
        viewModel.stepCommandParams = result?.id == null ? null : result.id;
        viewModel.stepCommandParamsText = result?.id == null ? "" : result.value;
    }
    
    public openEdgeConditionsDialog(viewModel: EruditeLink,
                                    entities: { type: StepLabelType, entities: StepLabelEntity[] }[]) {
        const closeEvent = new EventEmitter();
        // Открываем окно, передаём ему узел
        this.dialog.open(EdgeConditionComponent, {
            width: '700px',
            disableClose: true,
            data: {
                edge: viewModel.scriptEdge,
                sourceElement: viewModel.sourceElement,
                entities: entities,
                closeEvent: closeEvent
            }
        });
        // Отправим на валидацию по клику на затемнённую область и по закрытию на крестик
        closeEvent.subscribe(() => viewModel.onConditionModifyFinish(viewModel));
    }


}