import {EruditeElement} from "../EruditeElement";
import {VaScriptNode} from "../../../../../data/va/Script";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {getNodeSize, wrapText} from "../../ScriptBuilderUtils";
import {dia, ui} from "@naumen/rappid/build/rappid";
import {ElementAttributeId, setElementAttribute} from "../../controller/ScriptAttributeUtils";
import Size = dia.Size;
import HandlePosition = ui.Halo.HandlePosition;

export default class RectangleElement extends EruditeElement {

    /**
     * Callback на открытие окна
     */
    public onDialogOpen: (viewModel: RectangleElement) => void;

    /**
     * Callback на начало пакетных изменений
     */
    public onInitBatchChange: () => void;

    /**
     * Callback на окончание пакетных изменений
     */
    public onStoreBatchChange: () => void;

    /**
     * Callback на открытие контекстного меню
     */
    public onEntityOpen: any;

    constructor(attributes: { node: VaScriptNode }) {
        super(attributes);

        mergeDeep(this.attributes, {
            type: "standard.Rectangle",
            attrs: {
                body: {
                    refWidth: "100%",
                    refHeight: "100%",
                    strokeWidth: 2,
                    rx: 10,
                    ry: 10
                },
                label: {
                    textVerticalAnchor: "middle",
                    textAnchor: "middle",
                    refX: "50%",
                    refY: "50%",
                    fontSize: 14,
                    textWrap: {
                        text: this.node.text,
                        width: -20,
                    },
                    fontWeight: 'bold'
                },
                wrapper: {
                    refX: '100%',
                    refY: '100%',
                    x: -25,
                    y: -25
                },
            }
        });

        this.markup = [{
            tagName: 'rect',
            selector: 'body'
        }, {
            tagName: 'rect',
            selector: 'wrapper'
        }, {
            tagName: 'text',
            selector: 'label'
        }];

        if (this.hasExternalLink) {
            this.handles.push({
                name: 'open-in-tab',
                content: '<div><i class="fa fa-external-link"></i></div>',
                position: HandlePosition.NE,
                events: {pointerdown: event => this.onEntityOpen(this)},
            });
        }

        if (this.isDialogable) {
            // редактирование
            this.handles.push({
                name: 'edit-erudite-element',
                position: HandlePosition.SW,
                events: {pointerdown: event => this.onDialogOpen(this)},
            });
        }
    }

    /**
     * Редактируемый ли элемент
     */
    get isDialogable(): boolean {
        return true;
    }

    /**
     * Есть ли кнопка "открыть сущность в отдельном окне"
     */
    get hasExternalLink(): boolean {
        return true;
    }

    set labelText(text: string) {
        this.nodeText = text;
        this.attr('label', {
            textWrap: {
                text: text
            }
        });
        // Меняем размер shape'a, чтобы он подходил под текст
        const size: Size = getNodeSize(text, this.isRound());
        this.resize(size.width, size.height);
        this.prop("minSize", {
            width: size.width, height: size.height
        });
        if (this.freeTransform) {
            const options: ui.FreeTransform.Options = this.freeTransform.options;
            options.minWidth = size.width;
            options.minHeight = size.height;
        }
    }

    /**
     * Зададим размер блока на основе значения rerouteParams
     * Используем в RerouteElement и в FormulableElement
     * @param rerouteParamText текст значения параметров перевода
     */
    public updateRerouteParamTextInElement(rerouteParamText ?: string) {
        let displayText: string = 'Параметр перенаправления:\n';
        let currentText = !rerouteParamText ? this.node.stepLabel.commandParamsText : rerouteParamText;
        if (this.node.stepLabel.commandParams && currentText != "") {
            displayText += currentText.length > 23 ? currentText.slice(0, 23) + '...' : currentText;
        } else {
            displayText += 'Не задан';
        }
        const textSize: any = getNodeSize(this.node.text, false);

        let newSize = {
            width: Math.max(220, textSize.width),
            height: this.attributes.size.height
        };

        // При изменении параметра перенаправления размер не увеличиваем, иначе +30
        if (!this.attributes.attrs.rerouteParamText.textWrap || this.attributes.attrs.rerouteParamText.textWrap?.text == displayText) {
            newSize.height += 30;
        }

        mergeDeep(this.attributes, {
            attrs: {
                rerouteParamText: {
                    textWrap: {
                        text: displayText,
                    }
                },
                rerouteParamWrapper: {
                    width: 220,
                    x: -110
                }
            }
        });

        // Изменим размер блока
        this.resize(newSize.width, newSize.height);
        this.attributes.minSize = newSize;
        this.prop("minSize", {
            width: newSize.width, height: newSize.height
        });

        if (this.freeTransform) {
            const options: ui.FreeTransform.Options = this.freeTransform.options;
            options.minWidth = newSize.width;
            options.minHeight = newSize.height;
        }
    }

    set stepLabelName(name: string) {
        this.node.stepLabel.name = name;
        setElementAttribute(this, ElementAttributeId.NAME, name);
    }

    set stepLabelValue(value: string) {
        this.node.stepLabel.value = value;
        setElementAttribute(this, ElementAttributeId.VALUE, value);
    }

    set nodeText(value: string) {
        this.node.text = value;
        setElementAttribute(this, ElementAttributeId.TEXT, value);
    }

    set subId(value: number) {
        this.node.stepLabel.subId = value;
        setElementAttribute(this, ElementAttributeId.SUB_ID, value);
    }
}