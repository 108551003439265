import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {AttemptDTO, VaExtractionContext} from "../../dialog/model/correction.model";
import {BaseDataService} from "../base/base-data.service";
import {Extractor} from "../../../../data/va/Extractor";
import {NamedEntitiesModel} from "../model/named-entities.model";
import {MassOperation} from "../../../../data/va/MassOperation";


@Injectable()
export class AttemptService extends BaseDataService<AttemptDTO, number> {

    baseUrl: string = "/account/expert/ml/attempts";

    readonly entityClass = NamedEntitiesModel.VA_EXTRACTION_ATTEMPT;

    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getInfoExtractor(extractorId) {
        return await this.httpClient.get<Extractor>(this.baseUrl + `/extractor?id=${extractorId}`).toPromise();
    }

    async getAttemptsList(): Promise<AttemptDTO[]> {
        return await this.httpClient.get<AttemptDTO[]>(`${this.baseUrl}/list/${this.stateService.params['attemptId']}`).toPromise();
    }

    async loadExtractionContexts(): Promise<VaExtractionContext[]> {
        return await this.httpClient.get<VaExtractionContext[]>(`${this.baseUrl}/extractionContexts`).toPromise();
    }

    async getExport(extractorId) {
        return await this.httpClient.post(`${this.baseUrl}/export?id=${extractorId}`, {}, {
            responseType: 'arraybuffer',
            observe: 'response'
        }).toPromise();
    }

    async import(formData: FormData, extractorId) {
        return await this.httpClient.post(`${this.baseUrl}/import?id=${extractorId}`, formData).toPromise()
    }

    async getAttemptFilterData(infoId) {
        return await this.httpClient.get(`${this.baseUrl}/attemptFilterData`,
            {params: new HttpParams().set('infoId', infoId)}).toPromise()
    }

    massOperation(operation: MassOperation): Promise<any> {
        return this.httpClient.post(`${this.baseUrl}/massOperation`, operation).toPromise();
    }

}