import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {FileUploader} from "./uploader-vendor/file-uploader.class";
import {FileItem} from "./uploader-vendor/file-item.class";
import {HttpClient} from "@angular/common/http";
import {FileLikeObject} from "./uploader-vendor/file-like-object.class";


@Component({
    selector: 'erudite-file-uploader',
    template: require('./file-uploader.component.html'),
    styles: [require('./file-uploader.component.less')]
})

export class EruditeFileUploader implements OnInit, OnChanges {

    /**
     * Можно ли загружать несколько файлов
     */
    @Input()
    multiple: boolean = false;

    /**
     * Флаг автоматической загрузки файлов после добавления в input'e
     */
    @Input()
    autoUpload: boolean = false;

    /**
     * URL, по которому загружаются файлы при autoUpload = true
     */
    @Input()
    url?: string;

    /**
     * Mime-типы разрешенных к загрузке файлов
     */
    @Input()
    allowedMimeTypes?: string[];

    /**
     * Нyжно подтверждения удаления файла(ов)
     */
    @Input()
    deleteWithConfirmation?: boolean;

    /**
     * Текст подтверждения удаления файла(ов)
     */
    @Input()
    deleteConfirmationQuestion?: string;

    /**
     * Внешний файл, которым можем проинициализировать компоненту (например, для удаления)
     */
    @Input()
    existingFile?: File;

    @Input()
    dropdownFileMessage: string = "Перенесите файл сюда";

    @Input()
    selectFileMessage: string = "выберите файл";

    dropZoneOver: boolean;

    uploader: FileUploader;

    @ViewChild('fileInput', {static: false})
    fileInputElement: ElementRef<HTMLInputElement>;

    @Output()
    complete = new EventEmitter<UploadResult>();

    @Output()
    filesAdded = new EventEmitter<FileItem[]>();

    @Output()
    filesRemoved = new EventEmitter<void>();

    @Output()
    validationFailed = new EventEmitter<string>();

    /**
     * Процесс загрузки файла (0-100), передаваемый из родительской компоненты
     */
    @Input()
    progressNumber: number;

    // количество выбранных файлов
    fileCount: number = 0;

    // завершилась ли загрузка
    uploadComplete: boolean = false;

    // происходит ли загрузка
    uploadInProgress: boolean = false;

    // закончилась ли загрузка ошибкой
    uploadFailed: boolean = false;

    // началась ли загрузка
    loadStarted: boolean = false;

    // текстовая подпись к загрузке
    uploadTitle: string = "";

    // добавлен ли файл
    fileAdded: boolean;

    constructor(private httpClient: HttpClient) {
    }

    public fileOverDropZone(e: any): void {
        this.dropZoneOver = e;
    }

    onFileSelectClick() {
        this.fileInputElement.nativeElement.click();
    }

    ngOnInit(): void {
        this.uploader = new FileUploader({
            autoUpload: this.autoUpload,
            method: 'post',
            url: this.url,
            allowedMimeType: this.allowedMimeTypes,
            httpClient: this.httpClient,
        });

        if (this.autoUpload) {

            this.uploader.onAfterAddingAll = (files: any[]) => {
                // когда все файлы выбраны, запомним количество и запишем тайтл
                this.fileCount = files.length;

                this.uploadInProgress = true;
                this.loadStarted = true;

                this.uploadTitle = `Загрузка ${this.fileCount} ${EruditeFileUploader.getUploadTitleText(this.fileCount)}`;
            };

            let readyItems: UploadItem[] = [];
            this.uploader.onCompleteItem = (item, response, status) => {
                readyItems.push({file: item, responseData: response});
            };

            this.uploader.onCompleteAll = () => {
                let result = new UploadResult(readyItems, this.multiple);

                this.uploadInProgress = false;
                this.uploadComplete = true;

                this.complete.emit(result);

                readyItems = [];
            };


        } else {

            this.uploader.onAfterAddingAll = (files: FileItem[]) => {
                this.fileAdded = true;
                if (files.length && files.length == 1) {
                    this.uploadTitle = `${files[0]._file.name}`;
                } else if (files.length && files.length > 1) {
                    this.uploadTitle = `Выбрано ${files.length} ${EruditeFileUploader.getUploadTitleText(files.length)}`
                }

                this.filesAdded.emit(files)
            };

            if (this.existingFile) {
                this.uploader.addToQueue([this.existingFile])
            }

            this.deleteConfirmationQuestion = this.deleteConfirmationQuestion ? this.deleteConfirmationQuestion : 'Удалить файл?';
        }

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            this.validationFailed.emit(`Файл ${item.name} имеет недопустимый формат`);
        }
    }

    ngOnChanges() {
        if (this.progressNumber) {
            this.uploadTitle = `Файл ${this.uploadTitle} загружается`;
        }
    }

    getProgress() {
        if (this.progressNumber) {
            return this.progressNumber;
        }
        // какой процент от прогресса занимает один файл
        let oneFile = 100.0 / this.fileCount;
        return this.uploader.queue.map(value => {
            return (value.progress / 100.0) * oneFile;
        }).reduce((a, b) => a + b, 0);
    }

    private static getUploadTitleText(number: number): string {
        let titles = ["файл", "файла", "файлов"];
        const stringNumber = number + "";
        // нyжны 2 последние цифры, склонение одинаковое для более высоких порядков
        const comparableNumber = stringNumber.length > 2 ?
            parseInt(stringNumber.substring(stringNumber.length - 2, stringNumber.length)) :
            number;
        if (comparableNumber > 10 && comparableNumber < 20) {
            // 11 - 19 всегда файлов
            return titles[2];
        }
        const c = comparableNumber % 10;
        if (c === 0 || c >= 5) {
            return titles[2];
        }
        if (c === 1) {
            return titles[0];
        }
        if (c > 1 && c < 5) {
            return titles[1];
        }
        throw new Error("getUploadTitleText: unexpected number");
    }

    public removeFiles() {
        this.uploader.clearQueue();
        this.fileAdded = false;
        this.uploadTitle = ``;
        this.filesRemoved.emit()
    }

}

interface UploadItem {
    file: FileItem;
    responseData: any;
}

export class UploadResult {

    files: UploadItem[];

    multiple: boolean;


    constructor(files: UploadItem[], multiple: boolean) {
        this.files = files;
        this.multiple = multiple;
    }

    getSingleResult(): UploadItem {
        return this.files[0];
    }

    getResults(): UploadItem[] {
        return this.files;
    }

}