import {Component, Input, OnInit} from "@angular/core";
import {StateService} from "@uirouter/core";
import {Dialog} from "../model/dialog.model";
import VaAnalysisGridComponent from "../../../../components/analysis/VaAnalysisGridComponent";

@Component({
    selector: 'dialog-back-to-stat',
    template: require('./dialog-back-to-stat.component.html'),
    styles: [require('./dialog-back-to-stat.component.less')]
})
export class DialogBackToStatComponent implements OnInit {

    /**
     * Внешний идентификатор сессии
     */
    @Input()
    dialog: Dialog;

    /**
     * Список диалогов, которые нашлись по фильтру из статистики
     */
    @Input()
    dialogIds: number[];

    /**
     * Номер текущего просматриваемого диалога
     */
    private currentPosition: number;

    constructor(private stateService: StateService) {

    }

    ngOnInit(): void {
        this.currentPosition = +this.stateService.params["position"];
    }

    /**
     * Возврат к статистике из интерфейса просмотра диалога
     */
    toStatistics() {
        let filterForm = localStorage.getItem("eruditeStatFilterForm");
        if (filterForm && Object.keys(filterForm).length > 0) {
            // переходим в стату по имеющемуся фильтру
            this.stateService.go('robot.analysis.data', {
                filter: filterForm,
                tagIdForSubtreeFiltering: null,
                singleTagIdForFiltering: null
            }, {reload: true})
        } else {
            // переходим на форму статы, если фильтр пустой
            this.stateService.go('robot.analysis', {
                tagIdForSubtreeFiltering: null,
                singleTagIdForFiltering: null
            }, {reload: true});
        }
    }

    /**
     * Предыдущий диалог
     */
    previousDialog() {
        let pos = this.currentPosition - 1;
        if (this.dialogIds?.length > 0 && pos >= 0) {
            this.changeDialog(this.dialogIds[pos], pos);
        }
    }

    /**
     * Следующий диалог
     */
    nextDialog() {
        let pos = this.currentPosition + 1;
        if (this.dialogIds?.length > 0 && pos < this.dialogIds.length) {
            this.changeDialog(this.dialogIds[pos], pos);
        }
    }

    /**
     * Блокировать кнопку перехода к предыдущему диалогу?
     */
    disablePrevious() {
        return this.currentPosition - 1 < 0;
    }


    /**
     * Блокировать кнопку перехода к следующему диалогу?
     */
    disableNext() {
        return this.currentPosition + 1 >= this.dialogIds.length;
    }

    /**
     * Сменить диалог
     * @param dialogId на какой диалог меняем
     * @param num    индекс диалога
     */
    changeDialog(dialogId: number, num) {
        VaAnalysisGridComponent.onPositionChange(num);
        this.stateService.go("robot.analysis_dialog", {dialogId: dialogId, position: num});
    }

}