import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as urls from "../../../../../../js/workplace/urls";
import {AttributeSuppliedTypeEnum, VaAttribute} from "../../../../data/va/Attribute";
import {BehaviorSubject} from "rxjs";
import {VaTag} from "../../../../data/va/Tag";
import {MacroEntity, MacroObject} from "./object/MacroObject";
import {Macro, MacroTypeEnum} from "./object/Macro";
import {AudioRecord} from "../../../../data/va/AudioRecord";
import {StateService} from "@uirouter/core";
import {AttributeService} from "../../va/attribute/attribute.service";
import {AudioRecordService} from "../../media-libraries/audio-record/audio-record.service";
import {MarkupType} from "./object/Markup";
import {CustomizationScriptService} from "../../va/customization-script/customization-script.service";
import {CustomizationScript} from "../../../../data/va/CustomizationScript";
import {DkbFieldService} from "../../va/dkb/dkb-field.service";
import {DKBField} from "../../../../data/va/Dkb";
import {extractFileName} from "../../../../../util/Utils";
import {FileSaverService} from "ngx-filesaver";


@Injectable({
    providedIn: 'root',
})

export class MacroService {

    private macroDataSubject: BehaviorSubject<Map<MacroTypeEnum, MacroEntity>> = new BehaviorSubject<Map<MacroTypeEnum, MacroEntity>>(new Map<MacroTypeEnum, MacroEntity>());

    constructor(protected http: HttpClient,
                private stateService: StateService,
                private attributeService: AttributeService,
                private audioService: AudioRecordService,
                private dDkbFieldService: DkbFieldService,
                private customizationScriptService: CustomizationScriptService,
                private fileSaver: FileSaverService) {
    }

    private loadEntities(observableObjects: BehaviorSubject<Map<MacroTypeEnum, MacroEntity>>) {

        this.attributeService.subFindAll(entities => {
            const data = observableObjects.getValue()
                .set(MacroTypeEnum.ATTRIBUTE, MacroService.getMacroEntity(entities.map(entity => new VaAttribute(entity))
                    .filter(attribute => attribute.suppliedType?.name != AttributeSuppliedTypeEnum.CHANNEL)));
            observableObjects.next(data);
        });

        this.audioService.subFindAll(entities => {
            const data = observableObjects.getValue()
                .set(MacroTypeEnum.AUDIO_RECORD, MacroService.getMacroEntity(entities.map(entity => new AudioRecord(entity))));
            observableObjects.next(data);
        });

        this.dDkbFieldService.subFindAll(entities => {
            const data = observableObjects.getValue()
                .set(MacroTypeEnum.DKB_FIELD, MacroService.getMacroEntity(entities.map(entity => new DKBField(entity))));
            observableObjects.next(data);
        });

        this.customizationScriptService.subFindAll(entities => {
            const scripts = entities.map(entity => new CustomizationScript(entity)).filter(script => !script.module);
            const data = observableObjects.getValue()
                .set(MacroTypeEnum.CSCRIPT, MacroService.getMacroEntity(scripts));
            observableObjects.next(data);
        });

        this.http.get<MarkupType[]>(`${urls.va.macro}/markups`).subscribe(entities => {
            const data = observableObjects.getValue()
                .set(MacroTypeEnum.MARKUP, MacroService.getMacroEntity(entities.map(entity => new MarkupType(entity))));
            observableObjects.next(data);
        });
    }

    async generateIds(num = 1): Promise<string[]> {
        if (!num) {
            return [];
        }
        return await this.http.get<string[]>(`${urls.va.macro}/generate_ids`, {params: {num: num.toString()}}).toPromise();
    }

    getEntities(reload?: boolean): BehaviorSubject<Map<MacroTypeEnum, MacroEntity>> {
        if (!reload) {
            return this.macroDataSubject;
        } else {
            // пока не загружали ни разу - добавим все ожидаемые типы
            const data = new Map<MacroTypeEnum, MacroEntity>();
            data.set(MacroTypeEnum.AUDIO_RECORD, new MacroEntity());
            data.set(MacroTypeEnum.MARKUP, new MacroEntity());
            data.set(MacroTypeEnum.ATTRIBUTE, new MacroEntity());
            data.set(MacroTypeEnum.DKB_FIELD, new MacroEntity());
            data.set(MacroTypeEnum.CSCRIPT, new MacroEntity());
            this.macroDataSubject = new BehaviorSubject<Map<MacroTypeEnum, MacroEntity>>(data);
            this.loadEntities(this.macroDataSubject);
            return this.macroDataSubject;
        }
    }

    /**
     * Собрать объекты для макросов по типу
     */
    public prepareMacroEntity(typeEnum: MacroTypeEnum, entity: MacroEntity, excludeIds: string[], taggedInfo: VaTag[]): MacroEntity {
        // выкинем ненужное
        if (typeEnum === MacroTypeEnum.AUDIO_RECORD) {
            // группируем по тексту
            entity.groupedEntities = this.groupAudioRecordsByText(entity.entities);
            entity.resultEntities = [];
            entity.groupedEntities.forEach((value, key) => {
                // показываем 1 из группы
                entity.resultEntities.push(value[0]);
            });
        } else {
            entity.resultEntities = this.filterExcludedIds(entity.entities, excludeIds);
        }
        entity.taggedEntities = this.getTaggedEntities(entity.resultEntities, taggedInfo);
        entity.showTaggedEntities = entity.taggedEntities.length > 0;
        return entity;
    }

    /**
     * Получить полное текстовое представление (без макросов) для текста по каналу
     * @param text  текст
     * @param macros макросы
     */
    public static getFullTextView(text: string, macros: Macro[]): string {
        if (!macros?.length) {
            return text;
        }
        // куски текста, разбитые макросами
        const textPieces: string[] = text
            .split(new RegExp("[${|}]"))
            .filter(text => text !== "");
        // ищем макросы вида ${absdbas41} и заменяем на названия сущностей
        textPieces.forEach(marcoId => {
            const macro = macros.find(macro => macro.key.id == marcoId);
            if (macro != null) {
                let name;
                switch (macro.type.name) {
                    case MacroTypeEnum.ATTRIBUTE:
                        name = macro.attributes[0].name;
                        break;
                    case MacroTypeEnum.DKB_FIELD:
                        name = macro.dkbField.name;
                        break;
                    case MacroTypeEnum.CSCRIPT:
                        name = macro.script ? macro.script.name : "Некорректный/устаревший макрос";
                        break;
                    case MacroTypeEnum.MARKUP:
                        name = new MarkupType(macro.markupType).getTitle();
                        break;
                    case MacroTypeEnum.AUDIO_RECORD:
                        name = macro.audioRecord.text;
                        break;
                    default:
                        throw new Error(`Unsupported macro type: ${macro.type.name}`);

                }
                text = text.replace(macro.key.id, `${name}`);
            }
        });

        return text;
    }

    /**
     * Собрать объекты для макросов по типу
     */
    private static getMacroEntity(entities: MacroObject[]): MacroEntity {
        let entity = new MacroEntity();
        // отсортируем
        entity.entities = MacroService.sort(entities);
        return entity;
    }

    /**
     * Выкинуть объекты, которые нельзя показывать
     */
    private filterExcludedIds(entities: MacroObject[], excludeIds: string[]): MacroObject[] {
        if (!excludeIds) {
            return entities;
        }
        return entities.filter(entity => excludeIds.indexOf(entity.getId()) < 0);
    }

    /**
     * Надо показать уникальные тексты
     */
    private groupAudioRecordsByText(entities: MacroObject[]): Map<string, MacroObject[]> {
        const groupedByName = new Map<string, MacroObject[]>();
        entities.forEach(audioRecord => {
            if (groupedByName.get(audioRecord.getTitle()) == null) {
                groupedByName.set(audioRecord.getTitle(), [audioRecord]);
            } else {
                groupedByName.get(audioRecord.getTitle()).push(audioRecord);
            }
        });
        return groupedByName;
    }

    /**
     * Отсортировать по алфавиту
     */
    private static sort(entities: MacroObject[]): MacroObject[] {
        return entities.sort((i1, i2) => {
            return i1.getTitle().localeCompare(i2.getTitle());
        });
    }

    /**
     * Выбрать тегированные объекты
     */
    private getTaggedEntities(entities: MacroObject[], taggedInfo: VaTag[]): MacroObject[] {
        if (!taggedInfo) {
            return [];
        }
        //если предлагаемый объект протегирован
        return entities
            .filter(item => item.getTaggedInfo().length > 0)
            .filter(item => item.getTaggedInfo()
                .find(itemTaggedInfo => this.isSameTaggedInfo(taggedInfo, itemTaggedInfo)) != null);
    }

    private isSameTaggedInfo(currentTaggedInfoList: VaTag[], itemTaggedInfo: VaTag) {
        return currentTaggedInfoList.find(currentTaggedInfo => currentTaggedInfo.key.id === itemTaggedInfo.key.id) != null
    }

    /**
     * Загрузка вложения
     * @param url url загрузки
     */
    public downloadAttachment(url: string) {
        this.http.get(`${url}`, {
            observe: 'response',
            responseType: 'blob'
        }).subscribe((data) => {
            const fileName = extractFileName(data.headers.get('Content-Disposition'));
            this.fileSaver.save(data.body, fileName)
        });
    }
}



