import {NgModule} from "@angular/core";
import {ReportsMasterComponent} from "./master/reports-master.component";
import {ReportsEditComponent} from "./details/reports-edit.component";
import {CollectionModule} from "../common/collection/collection.module";
import {CollectionComponent} from "../common/collection/collection.component";
import {ReportsService} from "./reports.service";
import {BoilerplateModule} from "../va/base/boilerplate.module";
import {CommonModule} from "@angular/common";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormControlModule} from "../common/form/form-control.module";
import {FormsModule} from "@angular/forms";
import {EruditeNgbDatepickerModule} from "../common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";
import {UIRouterModule} from "@uirouter/angular";
import {MatRadioModule} from "@angular/material/radio";
import {EditableListModule} from "../common/editable-list/editable-list.module";
import {ExtractableValuesModule} from "../va/extractable-values/extractable-values.module";

@NgModule({
    imports: [
        CollectionModule,
        BoilerplateModule,
        CommonModule,
        NgSelectModule,
        FormControlModule,
        FormsModule,
        EruditeNgbDatepickerModule,
        UIRouterModule,
        MatRadioModule,
        EditableListModule,
        ExtractableValuesModule,
    ],
    declarations: [
        ReportsMasterComponent,
        ReportsEditComponent
    ],
    entryComponents: [
        CollectionComponent
    ],
    providers: [
        ReportsService
    ]
})
export class ReportsModule {

    static states = [
        {
            name: 'robot.reports',
            url: "/reports",
            views: {
                "content@authorized": {
                    component: ReportsMasterComponent
                }
            },
            data: {
                title: "Отчёты"
            }
        },
        {
            name: 'robot.reports.request',
            url: "/:directoryName",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.reports': {
                    component: ReportsEditComponent
                }
            },
            data: {
                title: "Отчёты"
            }
        }
    ];

}