import {
    Component,
    ContentChild,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChange,
    TemplateRef,
    ViewChildren
} from '@angular/core';
import {ScrollableItemDirective} from './scrollable-item.directive';
import {SortField} from "../../../../data/va/Common";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {getFieldAsString} from "../../../../../util/Utils";

@Component({
    selector: 'collection',
    template: require('./collection.component.html'),
    styles: [require('./collection.component.less')],
})

export class CollectionComponent implements OnInit, OnChanges, DoCheck {

    @ContentChild('collectionBody', {static: false})
    collectionBody: TemplateRef<ElementRef>;

    @ContentChild('collectionTools', {static: false})
    collectionTools: TemplateRef<ElementRef>;

    @ContentChild('collectionFilter', {static: false})
    collectionFilter: TemplateRef<ElementRef>;

    @ContentChild('collectionDescription', {static: false})
    collectionDescription: TemplateRef<ElementRef>;

    /**
     * Список объектов
     */
    @Input()
    items: any[];

    /**
     * включить сортировку
     */
    @Input()
    sortable: boolean = true;

    /**
     * включить поиск
     */
    @Input()
    searchable: boolean = true;

    /**
     * идет загрузка
     */
    @Input()
    loading: boolean;
    @Output()
    loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Включить пагинацию
     */
    @Input()
    pageable: boolean;

    /**
     * Показывать блок хедера
     */
    @Input()
    showHeader: boolean = true;

    /**
     * Показывать блок описания
     */
    @Input()
    showDescription: boolean = false;

    /**
     * Поля для сортировки
     */
    @Input()
    sortFields: SortField[];

    /**
     * дефолтное поле, по которому сортировать
     */
    @Input()
    sortField: SortField;

    /**
     * строка поиска
     */
    @Input()
    searchString: string;

    /**
     * Эмиттер строки поиска
     */
    @Output()
    searchStringChange = new EventEmitter<string>();

    @Output()
    onFiltered = new EventEmitter<any[]>();

    /**
     * Сообщение о том, что после фильтрации нет подходящих элементов
     */
    @Input()
    protected emptyFilteredItemsMessage: string = "Нет элементов для отображения";

    /**
     * Сообщение о том, что список объектов пуст
     */
    @Input()
    protected emptyItemsMessage: string = "Нет элементов для отображения";

    /**
     * Заголовок коллекции
     */
    @Input()
    title: string;

    @Input()
    searchPlaceholder: string;

    @Input()
    private externalFilter: any;

    @Input()
    private filterFunction: (searchString: string, item: any, filter: any) => boolean;

    /**
     * Используется ли фильтр
     */
    @Input()
    private isUsingFilter: (filter: any) => boolean;

    @Input()
    private clearSearchStringChange: EventEmitter<any>;

    // кол-во элементов на странице по умолчанию
    @Input()
    itemPerPage: number = 10;

    // варианты кол-ва элементов на странице
    itemPerPageOptions: number[] = [10, 30, 50];

    // кол-во элементов подгружаемых скроллом
    private countOfLoadItem: number = 10;

    // сортируемый фильтруемый список
    filteredItems: any[] = [];

    /**
     * номер текущей страницы
     */
    public curPage: number = 1;

    public paginationInput: string = "1";

    /**
     * Сейчас используется фильтр
     */
    private usingFilter: boolean = false;

    /**
     * перевернутый порядок сортировки
     */
    @Input()
    sortReverse: boolean;

    /**
     * Включить бесконечный скролл
     */
    @Input()
    infiniteScroll: boolean = true;

    /**
     * Дистанция для срабатывания функции при прокрутке вниз
     */
    @Input()
    infiniteScrollDistance: number = 2;

    private itemsCustomerDiffer: KeyValueDiffer<string, any>;
    private filterCustomerDiffer: KeyValueDiffer<string, any>;

    // по этому контейнеру будет рассчитываться бесконечный скролинг
    selector: string = '.scroll';

    // кнопочка скролла в начало коллекции
    showBtnUp = false;

    infiniteScrollThrottle = 10;

    private delta = 0;

    /**
     * Кнопки массовых операций
     */
    @Input()
    massOperationButtons: MassOperationButton[];

    /**
     * Выбрать всё чекбокс 20px или 25px
     */
    @Input()
    smallCheckbox: boolean = true;

    // директива прокрутки коллекции
    @ViewChildren(ScrollableItemDirective)
    scrollableItems: QueryList<ScrollableItemDirective>

    /**
     * Сабжект изменения строки поиска
     */
    private searchStringChangeSubject: Subject<void> = new Subject<void>();

    @Input()
    access: boolean = true;

    @Input()
    searchBarClass: string = 'py-20';

    @Input()
    filterClass: string = 'box box-primary sticky px-12 br-8';

    constructor(private differs: KeyValueDiffers) {
        // Обрабатываем изменения строки поиска через debounce чтобы не спамить изменениями
        this.searchStringChangeSubject
            .pipe(debounceTime(250))
            .subscribe(() => this.onSearchStringChangeDebounced());
    }

    ngOnInit(): void {
        if (!this.sortFields) {
            this.sortable = false;
        }
        if (this.clearSearchStringChange != null) {
            this.clearSearchStringChange.subscribe(() => {
                this.searchString = "";
                this.onSearchStringChange("");
            });
        }

        // Если pageable задали, то отменим бесконечный скролл
        if (this.pageable == true) {
            this.infiniteScroll = false;
        }
    }

    /**
     * прокручивает с анимацией коллекцию к первому элементу
     */
    public up() {
        const item = this.scrollableItems.first;
        item.scrollIntoView();
    }

    /**
     * срабатывает при прокрутке вниз
     */
    onScroll() {
        this.itemPerPage += this.countOfLoadItem;
    }

    /**
     * ловит событие прокрутки и в зависимости от направления движения скрывает кнопку или открывает
     */
    onElementScroll(event) {
        this.showBtnUp = this.delta - event.target.scrollTop >= 0;
        this.delta = event.target.scrollTop;
        this.showBtnUp = this.delta != 0;
    }

    onItemsChange() {
        this.filteredItems = this.items;
        this.usingFilter = false;
        this.sort();
    }

    /**
     * Выбор поля сортировки
     */
    selectSortField(event: SortField): void {
        this.sortField = event;
        this.sortReverse = event.reversed;
        this.sort();
    };

    /**
     * Текущая страница изменилась
     */
    changeCurrentPage() {
        this.paginationInput = this.curPage + "";
    };

    /**
     * Перейти к заданной странице
     */
    goToPage() {
        let page = parseInt(this.paginationInput);
        if (!isNaN(page)) {
            this.curPage = page;
        }
    };

    /**
     * Сортировка
     */
    private sort(): void {
        // если выключена сортировка или поле, по которому надо сортировать, пустое -> то выходим
        if (!this.sortable || this.sortField == null) {
            return;
        }
        this.filteredItems = this.filteredItems.sort((a: any, b: any) => {
            // берем значения в нужных полях
            const aFieldValue = getFieldAsString(a, this.sortField.field);
            let aFieldParsed = Number.parseFloat(aFieldValue);
            const bFieldValue = getFieldAsString(b, this.sortField.field);
            let bFieldParsed = Number.parseFloat(bFieldValue);
            // сравниваем
            // Для начала проверим не числа ли это (иначе они сравниваются как строки и двузначные неправильно сортируются)
            if (!isNaN(aFieldParsed) && !isNaN(bFieldParsed)) {
                let compareResult = aFieldParsed - bFieldParsed;
                if (this.sortReverse) {
                    compareResult = compareResult * -1;
                }
                return compareResult
            }
            // сравниваем не числа, а строки
            if (!this.sortReverse) {
                return aFieldValue.localeCompare(bFieldValue);
            } else {
                return -1 * aFieldValue.localeCompare(bFieldValue)
            }
        });
    }

    /**
     * Изменение строки поиска
     */
    onSearchStringChange(event: string) {
        this.searchString = event;
        this.searchStringChangeSubject.next();
    }

    /**
     * Изменение строки поиска после debounce-фильтра
     */
    onSearchStringChangeDebounced() {
        this.searchStringChange.emit(this.searchString);
        this.onFiltersChange();
    }

    /**
     * Фильтрация
     */
    onFiltersChange(): void {
        this.usingFilter = false;

        if (this.selectable) {
            // сбрасываем выбор элементов
            this.filteredItems.forEach(item => item.selected = false);
        }
        // если выключен поиск или строка поиска пустая, то выходим
        let searchDisabled = !this.searchable || !this.searchString || !this.searchString.length;
        if (!this.externalFilter && searchDisabled) {
            this.filteredItems = this.items;
            this.sort();
            this.onFiltered.emit(this.filteredItems);
            return;
        }
        let usingExternalFilter = false;
        if (this.isUsingFilter) {
            // есть что то заполненное во внешнем фильтре
            usingExternalFilter = this.isUsingFilter(this.externalFilter);
        }
        // Фильтрация либо кастомным фильтром, либо дефолтным (с поиском по хэдерам сортировки)
        if (this.externalFilter && this.filterFunction && (this.searchString?.length > 0 || usingExternalFilter)) {
            this.usingFilter = true;
            this.filteredItems = this.items.filter(item => this.filterFunction(this.searchString?.toLowerCase(), item, this.externalFilter));
        } else {
            this.usingFilter = !searchDisabled;
            this.filteredItems = this.items.filter(item => !searchDisabled ? this.isMatches(item) : true);
        }

        this.sort();
        this.onFiltered.emit(this.filteredItems);
    }

    get selectable() {
        return this.massOperationButtons?.length > 0;
    }

    private isMatches(item: any) {
        // фильтруем по строке из поиска и по полям их хедера
        return this.sortFields.some(field => {
            let fieldValue = getFieldAsString(item, field.field);
            return fieldValue.indexOf(this.searchString.toLowerCase()) >= 0
        });
    }

    /**
     * Проставить флаг "загружается"
     */
    setLoading(loading: boolean): void {
        this.loading = loading;
        this.loadingChange.emit(loading);
    }

    ngOnChanges(changes: { items: SimpleChange, externalFilter: SimpleChange }): void {
        if (changes.items && !this.itemsCustomerDiffer) {
            this.itemsCustomerDiffer = this.differs.find(this.items).create();
        }
        if (changes.externalFilter && !this.filterCustomerDiffer) {
            this.filterCustomerDiffer = this.differs.find(this.externalFilter).create();
        }
    }

    ngDoCheck(): void {
        if (this.itemsCustomerDiffer?.diff(this.items)) {
            this.onItemsChange();
            this.onFiltersChange();
        }
        if (this.filterCustomerDiffer?.diff(this.externalFilter)) {
            this.onFiltersChange();
        }
    }

    getEmptyItemsMessage(): string {
        return this.usingFilter ? this.emptyFilteredItemsMessage : this.emptyItemsMessage
    }

    /**
     * Нажатие на кнопку выполнения массовой операции
     */
    onMassOperationButtonPress(button: MassOperationButton) {
        button.operation(this.items.filter(item => item.selected));
    }

    /**
     * Массовый выбор/снятие выбора
     */
    massSelectionChange(select: boolean) {
        this.filteredItems.forEach(item => item.selected = select)
    }

    /**
     * Выбран ли хоть один элемент
     */
    get isAnySelected() {
        return this.filteredItems.some(item => item.selected);
    }

    /**
     * Выбраны ли все элементы
     */
    get areAllSelected() {
        return this.filteredItems.length > 0 && !this.filteredItems.some(item => !item.selected);
    }

    /**
     * Список элементов на странице
     */
    get itemsOnPage() {
        return this.filteredItems.slice((this.curPage - 1) * this.itemPerPage, this.curPage * this.itemPerPage)
    }

    /**
     * Количество элементов в коллекции
     */
    get collectionSize(): number {
        return this.items.length;
    }

    /**
     * Количество элементов в профильтрованной коллекции
     */
    get filteredCollectionSize(): number {
        return this.filteredItems.length;
    }

    onChangeItemsPerPage() {
    }
}

/**
 * Кнопка массовой операции
 */
export interface MassOperationButton {
    /**
     * Название
     */
    title: string;

    /**
     * Операция
     */
    operation: (selectedItems: any[]) => void

    /**
     * CSS-класс
     */
    class: string
}