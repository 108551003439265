import {Injectable} from "@angular/core";
import {VersionedDataService} from "../base/versioned-data.service";
import {DateExtractorRulesDTO, Extractor, ImplicitIntervalRule, ValidatorType, ValueOption, ValueType} from "../../../../data/va/Extractor";
import {HttpClient} from "@angular/common/http";
import {VaAsrSettings} from "../../../../data/va/Voice";
import {CompositeKey} from "../../../../data/va/Common";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import * as urls from "../../../../../../js/workplace/urls";
import {NamedEntitiesModel} from "../model/named-entities.model";


@Injectable()
export class ExtractorService extends VersionedDataService<Extractor, number> {

    baseUrl = "/account/expert/extractor";
    attributeUrl: string = "/account/expert/attribute";

    usageEntityType: AppliedEntityType = AppliedEntityType.EXTRACTOR;

    readonly entityClass = NamedEntitiesModel.VA_EXTRACTOR;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getDefaultAsrSettings(): Promise<VaAsrSettings> {
        return await this.httpClient.get<VaAsrSettings>(`${this.baseUrl}/defaultAsrSettings`).toPromise();
    }

    async getChannel(): Promise<Extractor> {
        return await this.httpClient.get<Extractor>(`${this.baseUrl}/getChannel`).toPromise();
    }

    async getProjectChannel(projectId: string): Promise<Extractor> {
        return await this.httpClient.get<Extractor>(`${this.baseUrl}/getProjectChannel?projectId=${projectId}`).toPromise();
    }

    /**
     * @return Список ответов, сущностей, сценариев, которые используют атрибут (по во)
     */
    async getAllByValueOption(extractorId: string): Promise<Map<string, Map<string, { key: CompositeKey<number> }[]>>> {
        return await this.httpClient
            .get<Map<string, Map<string, { key: CompositeKey<number> }[]>>>(`${this.baseUrl}/${extractorId}/allByValueOption`)
            .toPromise();
    }

    async getValueTypes(): Promise<ValueType[]> {
        return await this.httpClient.get<ValueType[]>(`${this.baseUrl}/valueTypes`).toPromise();
    }

    async getValidatorTypes(): Promise<ValidatorType[]> {
        return await this.httpClient.get<ValidatorType[]>(`${this.baseUrl}/validatorTypes`).toPromise();
    }

    async getIntervalRules(): Promise<ImplicitIntervalRule[]> {
        return await this.httpClient.get<ImplicitIntervalRule[]>(`${this.baseUrl}/intervalRules`).toPromise();
    }

    async getDateExtractorData(): Promise<DateExtractorRulesDTO> {
        return await this.httpClient.get<DateExtractorRulesDTO>(`${this.baseUrl}/dateExtractorData`).toPromise();
    }

    filterAttempts(stateService, valueOption: ValueOption): void {
        let url = stateService.href('robot.attempts', {
            type: 'ATTRIBUTE',
            id: valueOption.infoId,
            selectedValueOption: valueOption.apiKey
        }, {reload: true})
        window.open(url, '_blank');
    }

    /**
     * Протестировать регулярное выражение
     * @param text              текст
     * @param regularExpression регулярное выражение
     * @param replacement       замена для отображения
     */
    async testRegExp(text: string, regularExpression: string, replacement?: string): Promise<{ [key: string]: string }> {
        return await this.httpClient.get<{ [key: string]: string }>(`${urls.va.extractor}/testRegExp`, {
            params: {
                text: encodeURIComponent(text),
                regularExpression: encodeURIComponent(regularExpression),
                replacement: replacement
            }
        }).toPromise();
    }

    /**
     * Загрузить список городов по умолчанию для экстрактора адресов
     */
    async loadDefaultCities(): Promise<string[]> {
        return this.httpClient.get<string[]>(`${this.baseUrl}/defaultCities`).toPromise();
    }

    /**
     * импорт value option-сов
     */
    async import(formData: FormData): Promise<any> {
        return this.httpClient.post<any>(`${this.baseUrl}/importOptions`, formData).toPromise();
    }

    /**
     * Загрузить грамматики
     *
     * @param vendor вендор
     */
    async getGrammars(vendor: string): Promise<string[]> {
        return await this.httpClient.get<string[]>(`${this.baseUrl}/grammars/${vendor}`).toPromise();
    }
}