import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ModelQueryTestComponent} from "./model-query-test.component";
import {FormControlModule} from "../common/form/form-control.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {EditableListModule} from "../common/editable-list/editable-list.module";
import {FormsModule} from "@angular/forms";
import {MacroModule} from "../common/macro/macro.module";
import {MatTableModule} from "@angular/material";

@NgModule({
    declarations: [
        ModelQueryTestComponent
    ],
    imports: [
        FormControlModule,
        NgSelectModule,
        CommonModule,
        EditableListModule,
        FormsModule,
        MacroModule,
        MatTableModule
    ],
    exports: [
        ModelQueryTestComponent
    ],
    entryComponents: [
        ModelQueryTestComponent
    ]
})
export class ModelQueryTestModule {

}