import {CompositeKey} from "../../data/va/Common";
import {CheckState} from "../../io/components/dialog/model/dialog.model";
import {DialogCorrectionType} from "../../io/components/dialog/model/correction.model";

export type MLTaskType = "ACTS" | "TAGS" | "EXTRACTING" | "STEPS";
export type ViewType = "TAGS" | "LIST" | "ONE_BY_ONE";
export type SortOrder = "ASC" | "DESC";
export type SortField = "DATE" | "USER_RATING";
export type AnalysisSortingParams = {
    order: SortOrder;
    field: SortField;
    enabled: boolean;
};
export type PageConfig = {
    pageNumber: number;
    pageSize: number;
};
export type RecognitionStatuses = {
    noInput: boolean;
    noMatch: boolean;
    enabled?: boolean;
};
export type AnalysisTextSearchType = "USER_REPLY" | "VA_REPLY" | "EXPERT" | "TAG"

export class DialogFilterParam {
    fromDate?: Date | any;
    toDate?: Date | any;
    userRatings?: number[];
    prompter?: boolean;
    expertRatings?: number[];
    expertIds?: number[];
    redirectedToSupport?: boolean;
    withoutTag?: boolean;
    hadSuccessfulSolutions?: boolean;
    userKeyWords?: string;
    channels?: string[];
    sentiments?: string[];
    checkStates?: CheckState[];
    autocheck?: boolean;
    correctionTypes?: DialogCorrectionType[];
    viewType?: ViewType;
    sortParams?: AnalysisSortingParams;
    pageConfig?: PageConfig;
    keywordsSources?: AnalysisTextSearchType[];
    sessionId?: string;
    dialogId?: number;
    projectVersionId?: string;
    tagIdForSubtreeFiltering?: number;
    singleTagIdForFiltering?: number;
    selectionIds?: number[];
    recognitionStatuses?: RecognitionStatuses;

    constructor() {
    }

}

export interface FilterForm {
    date?: {
        range: {
            startDate: any;
            endDate: any;
        }
        enabled: boolean;
    };
    // оценки пользователей
    userRatings?: SwitchNumberArrayForm;
    // участвовал ли суфлер?
    prompter?: SwitchBooleanForm;
    // оценки эксперта
    expertRatings?: SwitchNumberArrayForm;
    superExpertRatings?: SwitchNumberArrayForm;
    // эксперты
    expertIds?: SwitchNumberArrayForm;
    redirectedToSupport?: SwitchBooleanForm;
    withoutTag?: SwitchBooleanForm;
    dialogTypes?: SwitchStringArrayForm;
    successStates?: SwitchStringArrayForm;
    userKeyWords?: SwitchStringStringArrayForm;
    channels?: SwitchStringArrayForm;
    sentiments?: SwitchStringArrayForm,
    // состояние (проверки) диалога
    checkStates?: SwitchStringArrayForm;
    autocheck?: SwitchBooleanForm;
    correctionTypes?: SwitchStringArrayForm;
    viewType?: ViewType;
    sortParams?: AnalysisSortingParams;
    pageConfig?: PageConfig;
    identifier?: SwitchStringForm;
    projectVersionId?: string;
    randomizer?: {
        value: number;
        mode: string;
        seed: number;
        enabled?: boolean;
    };
    tagProcessMode?: SwitchStringForm;
    selectionIds?: SwitchNumberArrayForm;
    // восстановлено из JSON'a
    restored?: boolean;
    recognitionStatuses?: RecognitionStatuses;
}

export interface SwitchStringForm {
    value: string;
    enabled: boolean;
}

export interface SwitchNumberForm {
    value: number;
    enabled: boolean;
}

export interface SwitchBooleanForm {
    value: boolean;
    enabled: boolean;
}

export interface SwitchNumberArrayForm {
    value: number[];
    enabled: boolean;
}

export interface SwitchStringArrayForm {
    value: string[];
    enabled: boolean;
}

export interface SwitchStringStringArrayForm {
    value: string;
    types: AnalysisTextSearchType[]; // TODO: тут, на самом деле, обёрнутый enum, а не только name
    enabled: boolean;
}

export interface DialogRandomizer {
    mode: RandomizerMode;
    value: number;
    seed: number
}

export interface VaStatSelection {
    id: number;
    date: Date;
    title: string;
    deleted: boolean;
    expertId: number;
    selectionSize: number;
    dialogKeys?: CompositeKey<number>[];
}

export enum SelectionModalMode {
    CREATE = "CREATE",
    EDIT = "EDIT"
}

export type RandomizerMode = "PERCENT" | "UNITS"
export type TagProcessMode = "PROCESSED_ONLY" | "NOT_PROCESSED_ONLY"
