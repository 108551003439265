function ScopedIncludeDirective() {
    return {
        templateUrl: function (elem, attrs) {
            return attrs.scopedInclude
        },
        restrict: 'A',
        scope: {
            'variables': '&'
        },
        link: function (scope, elem, attrs) {
            let vars = scope.variables();
            Object.keys(vars).forEach(function (key) {
                scope[key] = vars[key];
                console.log(key,);
            });
        }
    };
}

export default ScopedIncludeDirective;