import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {VersionedDataService} from "../../base/versioned-data.service";
import {VaTag} from "../../../../../data/va/Tag";
import {AppliedEntityType} from "../../../entity-usage/entity-usages.model";
import WebSocketService from "../../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {VaTBMessageCluster} from "../../../../../data/va/Message";
import {NamedEntitiesModel} from "../../model/named-entities.model";


@Injectable()
export class MessageClustersService extends VersionedDataService<VaTBMessageCluster, number> {

    baseUrl: string = "/account/expert/tagbuilding";

    usageEntityType: AppliedEntityType = AppliedEntityType.TAG;

    readonly entityClass: NamedEntitiesModel.VA_MESSAGE;


    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getTags(): Promise<VaTag[]> {
        return await this.httpClient.get<VaTag[]>(`${this.baseUrl}/tags`).toPromise();
    }

    async getTBList(sourceTagId: number): Promise<VaTBMessageCluster[]> {
        return await this.httpClient.get<VaTBMessageCluster[]>(`${this.baseUrl}/${sourceTagId ? '?sourceTagId=' + sourceTagId : ''}`).toPromise();
    }

    async clusterizeGet(sourceTagId: number, script: string): Promise<VaTBMessageCluster[]> {
        return await this.httpClient.get<VaTBMessageCluster[]>(`${this.baseUrl}/${script}${sourceTagId ? '?sourceTagId=' + sourceTagId : ''}`).toPromise();
    }
}
