import {Component, OnDestroy} from "@angular/core";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {BaseDetailsComponent} from "../../base/base-details.component";
import {Title} from "@angular/platform-browser";
import {CompositeKey} from "../../../../../data/va/Common";
import {RerouteParam, TextDataGroup, TextDataTypeGroup, TextDataTypeGroupEnum} from "../../text-data/text-data.model";
import {SettingsService} from "../settings.service";
import {SettingsMasterComponent} from "../master/settings-master.component";
import {TextToSpeechService} from "../../../media-libraries/text-to-speech/text-to-speech.service";
import {LevitanVendor} from "../../../../../data/va/Voice";
import {util} from '@naumen/rappid';
import {NotificationService} from "../../../common/snackbar/notification/notification.service";


@Component({
    selector: 'settings-edit',
    template: require('./settings-edit.component.html'),
    styles: [require('./settings-edit.component.less')]
})
export class SettingsEditComponent extends BaseDetailsComponent<TextDataGroup, CompositeKey<TextDataTypeGroup>> implements OnDestroy {

    ttsVendors: LevitanVendor[];
    asrVendors: LevitanVendor[];
    voices: string[] = [];
    grammars: string[] = [];
    emotions: string[] = [];

    objectIdKey = 'groupName';

    get formTitle(): string {
        return null;
    }

    get entityTitle(): string {
        return "";
    }

    generateFormObject(): TextDataGroup {
        return undefined;
    }

    showRemove(): boolean {
        return false;
    }

    constructor(protected stateService: StateService,
                public masterComponent: SettingsMasterComponent,
                protected httpClient: HttpClient,
                public dataService: SettingsService,
                protected titleService: Title,
                protected notificationService: NotificationService,
                private ttsService: TextToSpeechService) {
        super(stateService, null, httpClient, dataService, titleService, notificationService);
    }

    async ngOnDestroy() {
        // если находясь в настройках, нажали перейти в настройки, то вместо перехода вызовем переход из мастера к конкретной настройке
        if (this.stateService.current.name == "robot.settings") {
            await this.masterComponent.ngOnInit();
        }
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();

        if (this.masterComponent.isVoice === null) {
            await this.masterComponent.loadChannel();
        }
        if (this.stateService.params[this.objectIdKey] === TextDataTypeGroupEnum.VOICE) {
            this.isLoading = true;
            // получаем списки поставщиков TTS и ASR
            this.ttsVendors = await this.ttsService.getVendors(true);
            this.asrVendors = await this.ttsService.getVendors(false);
        }
    }

    onObjectLoaded() {
        this.form.object = new TextDataGroup(this.form.object);
        this.loadVoices(false);
        this.loadEmotions();
    }

    loadVoices(reload: boolean): void {
        this.voices = [];
        if (reload) {
            this.form.object.ttsSettings.voiceName = null;
        }
        if (!this.form.object.ttsSettings.vendor) {
            return;
        }
        this.ttsService.getVoices(this.form.object.ttsSettings.vendor).then(
            (voices: string[]) => {
                if (voices && voices instanceof Array) {
                    this.voices = voices;
                    if (!this.form.object.ttsSettings.voiceName) {
                        this.form.object.ttsSettings.voiceName = this.voices[0];
                    }
                }
            });
    }

    loadEmotions(): void {
        if (!this.form.object.ttsSettings.vendor) {
            return;
        }
        this.ttsService.getEmotions(this.form.object.ttsSettings.vendor).then(
            (emotions: string[]) => {
                if (emotions && emotions instanceof Array) {
                    this.emotions = emotions;
                }
            });
    }

    onChangeGroup(groupName: string): void {
        if (groupName !== this.objId) {
            this.stateService.go('robot.settings.edit', {
                groupName: groupName
            });
        }
    }

    async save(preserveState?: boolean): Promise<void> {
        this.form.errors.clear();
        switch (this.form.object.key.id.name) {
            case TextDataTypeGroupEnum.VOICE:
                this.validateNotEmpty(this.form.object.asrSettings, "asrSettings_");
                this.validateNotEmpty(this.form.object.answerAsrSettings, "answerAsrSettings_");
                this.validateNotEmpty(this.form.object.ttsSettings, "");
                break;
            case TextDataTypeGroupEnum.DIALOG_HANDLE:
                this.form.object.textDataWithNumberValue.filter(dataWithNumberValue => dataWithNumberValue.content === null)
                    .forEach(dataWithNumberValue => this.form.errors.set(dataWithNumberValue.type.name, "Поле обязательно для заполнения"));

                break;
            default:
                break;
        }
        if (this.form.errors.size === 0) {
            this.form.object.prepareForSave();
            await super.save(preserveState);
            this.form.object = new TextDataGroup(this.form.object);
        }
    }

    validateNotEmpty(object: any, errorPrefix: string): void {
        Object.keys(object).forEach(fieldName => {
                if (object[fieldName] === null || object[fieldName] === "") {
                    if (fieldName !== 'bargeInWords') {
                        this.form.errors.set(errorPrefix + fieldName, "Поле обязательно для заполнения");
                    }
                }
            }
        );
    }

    removeRerouteParam(index: number): void {
        this.form.object.rerouteParams.splice(index, 1);
    }

    addRerouteParam(): void {
        this.form.object.rerouteParams.push(new RerouteParam(util.uuid(), ""));
    }

    isMasterLoading(): boolean {
        return false;
    }

    checkSpeed() {
        if (this.form.object.ttsSettings.voiceSpeed) {
            this.form.object.ttsSettings.voiceSpeed = Number.parseFloat(this.form.object.ttsSettings.voiceSpeed.toFixed(1));
            if (this.form.object.ttsSettings.voiceSpeed > 3) {
                this.form.object.ttsSettings.voiceSpeed = 3;
            } else if (this.form.object.ttsSettings.voiceSpeed < 0.1) {
                this.form.object.ttsSettings.voiceSpeed = 0.1;
            }
        } else {
            this.form.object.ttsSettings.voiceSpeed = 1.2;
        }

    }
}