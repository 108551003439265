import {Component} from "@angular/core";
import {CompositeKey} from "../../../../../data/va/Common";
import {VATagAnswer} from "../../../../../data/va/TagAnswer";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {AnswerMasterComponent} from "../master/answer-master.component";
import {AnswerService} from "../answer.service";
import {StepLabelEntity} from "../../../../../data/va/Script";
import {BaseDetailsComponent} from "../../base/base-details.component";
import {Title} from "@angular/platform-browser";
import {ValueOption} from "../../../../../data/va/Extractor";
import {AttributeService} from "../../attribute/attribute.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'answer-edit',
    template: require('./answer-edit.component.html'),
    styles: [require('./answer-edit.component.less')]
})
export class AnswerEditComponent extends BaseDetailsComponent<VATagAnswer, CompositeKey<number>> {

    objectIdKey = 'answerId';

    tags: StepLabelEntity[];
    channels: ValueOption[];

    constructor(stateService: StateService, master: AnswerMasterComponent,
                httpClient: HttpClient, dataService: AnswerService, titleService: Title, notificationService: NotificationService, private attributeService: AttributeService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        const promises: Promise<any>[] = [this.loadTags(), this.loadChannels()];
        if (this.objId) {
            promises.push(this.loadAccount());
        }
        await Promise.all(promises);
    }

    async loadChannels() {
        this.channels = await this.attributeService.getChannelsWithAssistant();
    }

    async loadTags() {
        this.tags = await this.httpClient.get<StepLabelEntity[]>(`/account/expert/tag/tags`).toPromise();
    }

    generateFormObject(): VATagAnswer {
        return new VATagAnswer();
    }

    get entityTitle(): string {
        return this.form.object.name;
    }

    get removeConfirmMessage(): string {
        return `Вы уверены, что хотите удалить ответ?`;
    }

}