import restangular from "restangular";
import * as urls from "../../../../../js/workplace/urls.js";

export default class VaTaggingItemModalController {

    static $inject = ["$uibModalInstance", "onTagSelected", "Restangular", "$stateParams"];

    private Restangular: restangular.IService;

    private $uibModalInstance: any;

    private treeOptions: any;

    private tagsTree: any;

    private onTagSelected: (tag: any) => void;

    constructor($uibModalInstance, onTagSelected: (tag: any) => void, Restangular: restangular.IService, $stateParams) {
        const that = this;
        this.Restangular = Restangular;
        this.$uibModalInstance = $uibModalInstance;
        this.onTagSelected = onTagSelected;

        const onClick = (tag) => {
            this.onTagSelected(tag);
            this.$uibModalInstance.close();
        };

        this.treeOptions = {
            tagMode: 'ALL',
            access: true,
            droppable: false,
            showTrain: false,
            hide: true,
            sortableFilterResult: true,
            highlightedNode: false,
            clickFn: function (tag) {
                onClick(tag);
            },
        };

        this.Restangular.one(urls.va.tags).get()
            .then(function success(data) {
                    that.tagsTree = [data];
                }, function error(data) {
                }
            );

    }

    cancel() {
        this.$uibModalInstance.close();
    }

}