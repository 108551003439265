export class Report {
    reportName: string;
    fileName: string;
    directoryName: string;
    reportType?: string;
    requiredParameters: VAReportParameter[];
    reportDescription?: string;
}

export class VAReportParameter {
    name: ParameterName;
    description: string;
    valueClassName: ValueClassName;
    value: any;
    channelValue?: number;
}

export enum ValueClassName {
    BOOLEAN = "java.lang.Boolean",
    DOUBLE = "java.lang.Double",
    FLOAT = "java.lang.Float",
    INTEGER = "java.lang.Integer",
    LONG = "java.lang.Long",
    SHORT = "java.lang.Short",
    STRING = "java.lang.String",
    BIG_DECIMAL = "java.math.BigDecimal",
    SQL_DATE = "java.sql.Date",
    TIME = "java.sql.Time",
    TIMESTAMP = "java.sql.Timestamp",
    JAVA_DATE = "java.util.Date",
    SET_OF_LONGS = "java.util.Set",
    LIST_OF_STRINGS = "java.util.ArrayList",
}

export class Interval {
    name: string;
    value: string;


    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }
}

export enum ParameterName {
    PROJECT_OR_VERSION_ID = "PROJECT_OR_VERSION_ID",
    START_DATE = "START_DATE",
    END_DATE = "END_DATE",
    INTERVAL = "INTERVAL",
    SQL_START_DATE = "SQL_START_DATE",
    SQL_END_DATE = "SQL_END_DATE",
    ACCOUNT_IDS = "ACCOUNT_IDS",
    OPERATOR_IDS = "OPERATOR_IDS",
    ATTRIBUTE_IDS = "ATTRIBUTE_IDS",
    CHANNEL_ID = "CHANNEL_ID",
    OPTION_IDS = "OPTION_IDS",
    SCENARIO_ID = "SCENARIO_ID",
    REPORT_TYPE = "REPORT_TYPE",
    SMALL_TALK_IDS = "SMALL_TALK_IDS"
}
