import * as moment from 'moment';

export default function getBarChartOptions() {
    return {
        chart: {
            type: 'multiBarChart',
            height: 225,
            margin: {
                top: 30,
                right: 70,
                bottom: 30,
                left: 70
            },
            stacked: false,
            showLegend: false,
            showControls: false,
            showValues: false,
            groupSpacing: 0.5,
            color: ["#6196C7"],
            duration: 500,
            xAxis: {
                tickFormat: (timestamp) => moment(timestamp).format("DD.MM HH:mm"),
            },
            yAxis: {
                tickFormat: (d) => `${d.toFixed(0)}%`,
                showMaxMin: false,
                tickValues: [0, 100],
            },
            yDomain: [0, 100],
            noData: "Нет данных",
            callback: () => {
                d3.selectAll(`.nv-multiBarWithLegend .nv-x .nv-axis .tick text`)
                    .attr(`class`, `xAxis-ticks`);
            },
        }
    };
}