import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {StateService} from "@uirouter/core";

@Injectable()
export class AccessInterceptor implements HttpInterceptor {

    constructor(private stateService: StateService) {
    }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler) {
        return next.handle(httpRequest)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 403) {
                        this.stateService.go('^');
                        return [];
                    }
                    return throwError(error);
                })
            );
    }
}