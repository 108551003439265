import {Component, Input} from "@angular/core";
import {AppConfigService} from "../../../../service/app-config.service";

@Component({
    selector: 'expert-and-date',
    template: require('./expert-and-date.component.html'),
    styles: [require('./expert-and-date.component.less')]
})
export class ExpertAndDateComponent {

    @Input()
    obj: any;

    @Input()
    dateFieldName: string = "d";

    @Input()
    showExpert: boolean = true;

    timezone: string;


    constructor(appConfigService: AppConfigService) {
        this.timezone = appConfigService.interfaceConfig.serverTimezone;
    }

}