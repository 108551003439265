import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";
import {ReportsService} from "../reports.service";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {ReportsMasterComponent} from "../master/reports-master.component";
import {Interval, ParameterName, Report, ValueClassName, VAReportParameter} from "../report.model";
import {VAScript} from "../../../../data/va/Script";
import {VaAttribute} from "../../../../data/va/Attribute";
import {StatUserAct} from "../../../../data/va/SimpleModuleAnswer";
import {Form} from "../../va/base/base-details.component";
import {ExtractorSuppliedTypeEnum, ValueOption} from "../../../../data/va/Extractor";
import {
    LongOperationType,
    subscribeToLongOperationFinish
} from "../../common/snackbar/notification/long-operation/long-operation.service";
import WebSocketService from "../../../../services/WebSocketService";
import {AttributeService} from "../../va/attribute/attribute.service";
import {ScenarioService} from "../../va/scenario/scenario.service";
import {
    subscribeToPushNotifications
} from "../../common/snackbar/notification/long-operation/push-notification.service";
import {NgbCalendar} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";
import {Account} from "../../../../data/va/Account";
import {CompositeKey} from "../../../../data/va/Common";

@Component({
    selector: 'reports-edit',
    template: require('./reports-edit.component.html'),
    styles: [require('./reports-edit.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class ReportsEditComponent implements OnInit {
    public form = new Form<Report>();

    public directoryName: string;
    public report: Report;
    public currentProjectVersionId: string;
    public currentProjectId: string;

    public intervals: Interval[] = [
        new Interval('Час', 'hour'),
        new Interval('День (сутки)', 'day'),
        new Interval('Неделя', 'week'),
        new Interval('Месяц', 'month'),
    ];

    public userAccounts: Account[];
    public operatorAccounts: Account[];
    public scenarios: VAScript[];
    public filteredAttributes: VaAttribute[];
    public allAttributes: VaAttribute[];
    public acts: StatUserAct[];
    public isLoading: boolean;
    public selectedScenario: VAScript;
    public attributeValues: any[] = [];
    // Id событий построения отчёта
    private readonly subscriptionIds = new Set<string>();
    public dateRange;
    public projectVersionParameters: VAReportParameter[];
    public dateParameters: VAReportParameter[];
    public extraParameters: VAReportParameter[];
    public channels: ValueOption[] = new Array<ValueOption>();
    private ALL_CHANNELS_OPTION_ID = -1;

    constructor(protected stateService: StateService,
                private master: ReportsMasterComponent,
                private dataService: ReportsService,
                titleService: Title,
                private notificationService: NotificationService,
                private webSocketService: WebSocketService,
                private attributeService: AttributeService,
                private scenarioService: ScenarioService,
                calendar: NgbCalendar,
                private fileSaver: FileSaverService) {
        this.directoryName = stateService.params['directoryName'];

        const today = calendar.getToday();
        this.dateRange = {fromDate: today, toDate: today};

        // подписка на пуш-уведомления через снекбар
        subscribeToPushNotifications(stateService);
    }

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        // поищем отчёт в мастере, чтобы не делать запрос
        this.report = this.master.standardReports?.find(report => report.directoryName == this.directoryName);
        if (!this.report) {
            // не нашли в стандартных, поищем в кастомных
            this.report = this.master.customReports?.find(report => report.directoryName == this.directoryName);
        }
        if (!this.report) {
            // не нашли нигде, сделаем запрос
            this.report = await this.dataService.getReport(this.directoryName);
        }

        if (this.report.reportType == 'CUSTOM') {
            // Переключим вкладку
            this.master.isCustomReportSelected = true;
        }

        // Проставим проект и его версию
        const loadedVersions = await this.dataService.loadProjectVersion();
        this.currentProjectVersionId = loadedVersions.id;
        this.currentProjectId = loadedVersions.projectId;

        // Вытащим параметры по разным секциям
        this.projectVersionParameters = this.report.requiredParameters.filter(parameter =>
            parameter.name == ParameterName.PROJECT_OR_VERSION_ID);

        this.dateParameters = this.report.requiredParameters.filter(parameter =>
            parameter.name == ParameterName.START_DATE || parameter.name == ParameterName.END_DATE);

        this.extraParameters = this.report.requiredParameters.filter(parameter =>
            parameter.name != ParameterName.PROJECT_OR_VERSION_ID &&
            parameter.name != ParameterName.START_DATE &&
            parameter.name != ParameterName.END_DATE);

        // Проставим id проекта в параметры
        this.projectVersionParameters.forEach(parameter => {
            if (parameter.name == ParameterName.PROJECT_OR_VERSION_ID) {
                parameter.value = this.currentProjectVersionId;
            }
        });

        // не грузим, то что не надо
        if (this.report.requiredParameters.some(parameter => parameter.name == ParameterName.ACCOUNT_IDS)) {
            this.userAccounts = await this.dataService.loadUserAccounts();
        }
        if (this.report.requiredParameters.some(parameter => parameter.name == ParameterName.OPERATOR_IDS)) {
            this.operatorAccounts = await this.dataService.loadOperatorAccounts();
        }
        if (this.report.requiredParameters.some(parameter => parameter.name == ParameterName.ATTRIBUTE_IDS)) {
            this.allAttributes = await this.attributeService.findAll({'projectVersionId': this.currentProjectVersionId});
            this.filteredAttributes = this.allAttributes;
        }
        if (this.report.requiredParameters.some(parameter => parameter.name == ParameterName.SCENARIO_ID)) {
            this.scenarios = await this.scenarioService.findAll({'projectVersionId': this.currentProjectVersionId});
        }
        if (this.report.requiredParameters.some(parameter => parameter.name == ParameterName.SMALL_TALK_IDS)) {
            this.acts = await this.dataService.getActs();
        }

        // добавим опцию все каналы
        const allChannelOption = new ValueOption();
        allChannelOption.key = new CompositeKey<number>();
        allChannelOption.key.id = this.ALL_CHANNELS_OPTION_ID;
        allChannelOption.title = "Все каналы";
        if (this.report.requiredParameters.some(parameter => parameter.name == ParameterName.CHANNEL_ID)) {
            this.channels.push(allChannelOption);
            this.channels.push(...await this.attributeService.getChannels());
        }

        // Интервал по-умолчанию - "Час"
        // Канал по-умолчанию - "Все каналы"
        this.extraParameters.forEach(parameter => {
            if (parameter.name == ParameterName.INTERVAL) {
                parameter.value = this.intervals[0].value;
            }
            if (parameter.name == ParameterName.CHANNEL_ID) {
                parameter.channelValue = this.ALL_CHANNELS_OPTION_ID;
            }
        });

        this.isLoading = false;
    }


    async buildReport() {
        this.isLoading = true;
        const object = this.report;

        // Подготовка request-body (нужно очистить даты, перевести их в строку, чтобы парсить на бэке в server time)
        this.dateParameters.forEach((parameter: VAReportParameter) => {
            if (parameter.name == ParameterName.START_DATE) {
                let date = this.dateRange.fromDate;
                // dd.MM.yyyy, HH:mm:ss
                parameter.value = `${date.day}.${date.month}.${date.year}, 0:0:0"`;

            } else if (parameter.name == ParameterName.END_DATE) {
                let date = this.dateRange.toDate;
                // dd.MM.yyyy, HH:mm:ss
                parameter.value = `${date.day}.${date.month}.${date.year}, 0:0:0"`;
            }
        });

        this.extraParameters.forEach(parameter => {
            switch (parameter.name) {
                case ParameterName.ATTRIBUTE_IDS:
                    if (this.attributeValues) {
                        // показывали форму с выбором атрибут - значение
                        parameter.value = this.attributeValues.map(data => data.info.key.id).join(", ");
                    }
                    break;
                case ParameterName.OPTION_IDS:
                    if (this.attributeValues) {
                        // показывали форму с выбором атрибут - значение
                        parameter.value = this.attributeValues
                            // на бэкенде распарсим в списки опций по каждому атрибуту
                            .map(data => data.values.map(value => (value as ValueOption).key.id).join("__"))
                            // пустые значения с краю списка split на бэке выкидывает, заменяем на пустые списки
                            .map(value => value !== "" ? value : "__")
                            .join(",");
                    }
                    break;
                case ParameterName.CHANNEL_ID:
                    if (parameter.channelValue == this.ALL_CHANNELS_OPTION_ID) {
                        // была выбрана опция "Все каналы" - оставим поле пустым
                        parameter.value = null;
                    } else {
                        parameter.value = parameter.channelValue;
                    }
                    break;
            }
        });

        // Вернем параметры из секций в общий список
        object.requiredParameters = [];
        object.requiredParameters.push(...this.extraParameters);
        object.requiredParameters.push(...this.projectVersionParameters);
        object.requiredParameters.push(...this.dateParameters);

        try {
            // шлем запрос на построение отчета
            await this.dataService.buildReport(object);
            // действия по блокировке/разблокировке кнопки построения отчета
            this.blockButton(object);
        } catch (e) {
            if (e.errors) {
                e.errors.forEach(error => this.notificationService.error(error.message));
            }
        }
        this.isLoading = false;
    }

    /**
     * Действия по блокировке/разблокировке кнопки построения отчета
     */
    private blockButton(object: any) {
        // запоминаем, что построение этого отчета пока недоступно
        this.dataService.beingBuiltReportNames.add(object.reportName);
        const subscriptionId = subscribeToLongOperationFinish(this.webSocketService, {
            type: LongOperationType.COLLECT_REPORT,
            id: object.reportName
        }, () => {
            // при пуше о фише построения разблокируем кнопку
            this.dataService.beingBuiltReportNames.delete(object.reportName);
            // и отписываемся от событий по этому отчету
            this.subscriptionIds.delete(subscriptionId);
            this.webSocketService.removeListener(subscriptionId);
        });
    }

    /**
     * Строится ли текущий отчет
     */
    public isBeingBuilt(): boolean {
        return this.dataService.beingBuiltReportNames.has(this.report.reportName);
    }

    /**
     * Нужно ли дизейблить кнопку построения отчета
     */
    public isBuildReportButtonDisabled(): boolean {
        if (this.isBeingBuilt()) {
            // построение уже в процессе
            return true;
        }
        let disabled = false;
        if (this.report) {
            // Бежим по всем доступным ключам
            for (let parameter of this.report.requiredParameters) {
                if ([ParameterName.ACCOUNT_IDS, ParameterName.OPERATOR_IDS, ParameterName.SCENARIO_ID, ParameterName.OPTION_IDS,
                    ParameterName.ATTRIBUTE_IDS, ParameterName.SMALL_TALK_IDS, ParameterName.CHANNEL_ID].indexOf(parameter.name) >= 0) {
                    disabled = false;
                } else {
                    if (parameter.value?.length === 0) {
                        disabled = true;
                    }
                }
                if (disabled) {
                    break;
                }
            }
        }
        return disabled;
    }

    /**
     * Отправить запрос на удаление пользовательского отчёта
     * Если ок, то обновить список отчётов
     */
    async deleteReport() {
        const response = await this.dataService.deleteTemplate(this.report.fileName);

        if (response == 'OK') {
            await this.master.loadReports();
            this.master.closeDetails();
        }
    }

    /**
     * Событие выбора сценария
     * @param i индекс сценарного параметра в массиве extraParameters
     */
    onScenarioSelect(i: number): void {
        this.filteredAttributes = null;
        this.attributeValues = [];
        const parameter = this.extraParameters[i];
        if (this.selectedScenario) {
            parameter.value = this.selectedScenario.key.id;
            this.scenarioService.getUsedAttributes(parameter.value).then((data: VaAttribute[]) => {
                // В Атрибутах не выводится значения для типа канала.
                this.filteredAttributes = data.filter(attribute => attribute.extractor.suppliedType?.name !== ExtractorSuppliedTypeEnum.CHANNEL);
            });
        } else {
            parameter.value = null;
            this.filteredAttributes = this.allAttributes;
        }
    }

    /**
     * Создать input-блок для переданного параметра
     */
    public checkTypeAndInitParam(parameter: VAReportParameter, callback: any): string {
        // В зависимости от выбираем чем инициализировать
        switch (<any>ValueClassName[parameter.valueClassName]) {
            // Для boolean - checkbox
            case ValueClassName.BOOLEAN:
                // Проинициализируем false, чтобы не было недоразумений
                if (callback) {
                    callback(false);
                }
                return 'BOOLEAN';
            case ValueClassName.DOUBLE:
            case ValueClassName.FLOAT:
            case ValueClassName.INTEGER:
            case ValueClassName.LONG:
            case ValueClassName.SHORT:
            case ValueClassName.BIG_DECIMAL:
                if (callback) {
                    callback(0);
                }
                return 'NUMBER';
            case ValueClassName.STRING:
                if (callback) {
                    if (parameter.name === ParameterName.PROJECT_OR_VERSION_ID) {
                        callback(this.stateService.params['projectVersionId'])
                    } else {
                        callback('');
                    }
                }
                return 'STRING';
            case ValueClassName.SQL_DATE:
            case ValueClassName.TIME:
            case ValueClassName.TIMESTAMP:
            case ValueClassName.JAVA_DATE:
                if (callback) {
                    if (parameter.name === ParameterName.START_DATE) {
                        let startOfDay = new Date();
                        startOfDay.setHours(0, 0, 0, 0);
                        callback(startOfDay);
                    } else {
                        callback(new Date());
                    }
                }
                return 'DATE';
            case ValueClassName.SET_OF_LONGS:
                return callback ? callback([]) : 'SET_OF_LONGS';
            case ValueClassName.LIST_OF_STRINGS:
                return callback ? callback([]) : 'LIST_OF_STRINGS';
            default:
                return 'NONE';
        }
    }

    async downloadTemplate() {
        this.isLoading = true;
        await this.dataService.downloadTemplate(this.report.fileName).then((data) => {
            const file = new Blob([data], {type: "text/xml"});
            const fileName = this.report.fileName;
            this.fileSaver.save(file, fileName);
        }, (data) => {
            // Парсим в ответ, чтобы посмотреть что там пришло
            let result: any = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(data)));
            // Показываем сообщение об ошибке
            this.notificationService.error(result.message);
        });
        this.isLoading = false;
    }
}
