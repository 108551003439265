import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import * as urls from "../../../../../../js/workplace/urls";
import {HttpClient} from "@angular/common/http";
import {FileItem} from "../../common/file-uploader/uploader-vendor/file-item.class";

@Component({
    selector: 'media-files-import-dialog',
    template: require('./audio-record-import-dialog.component.html'),
    styles: [require('./audio-record-import-dialog.component.less')]
})
export class AudioRecordImportDialogComponent {

    /**
     * XLS-файл с репликами
     */
    replies: File;

    /**
     * Аудиозаписи
     */
    private audioFiles: File[];

    /**
     * Данные о загрузке для прогресс-бара
     */
    isLoading: boolean;

    form: any = {
        success: false,
        errors: new Map<string, string>()
    };

    constructor(private http: HttpClient, public dialogRef: MatDialogRef<AudioRecordImportDialogComponent>) {
    }

    onDeny(): void {
        this.dialogRef.close();
    }

    onRepliesFileChange(items: FileItem[]) {
        if (!items) {
            this.replies = null;
        } else {
            this.replies = items.pop()._file;
        }
    }

    onAudioFileChange(items: FileItem[]) {
        if (!items) {
            this.audioFiles = null;
        } else {
            this.audioFiles = items.map(item => item._file);
        }
    }

    upload() {
        let formData = new FormData();
        formData.append('replies', this.replies);
        if (this.audioFiles) {
            this.audioFiles.forEach(file => formData.append('audioFiles', file, file.name));
        }

        this.isLoading = true;
        this.http.post(`${urls.va.audio_records}/import`, formData).toPromise()
            .then((data) => {
                this.onDeny();
            }, (data) => {
                data.errors.forEach(error => this.form.errors.set("#main", error.message));
            }).finally(() => this.isLoading = false);

    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     * @param $event
     */
    onFileAddFailed($event: string) {
        this.form.errors.set("#main", $event);
        setTimeout(() => this.form.errors.set("#main", null), 5000);
    }
}
