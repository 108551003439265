import {Component, DoCheck, KeyValueDiffer, KeyValueDiffers} from "@angular/core";
import {BaseDetailsComponent, DetailsMode} from "../../base/base-details.component";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {StateService} from "@uirouter/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {
    DateExtractorRulesDTO,
    Extractor,
    ValidatorType,
    ValueOption,
    ValueType,
    ValueTypeEnum
} from "../../../../../data/va/Extractor";
import {ExtractorService} from "../extractor.service";
import {ExtractorMasterComponent} from "../master/extractor-master.component";
import {CustomizationScript} from "../../../../../data/va/CustomizationScript";
import {VaAsrSettings} from "../../../../../data/va/Voice";
import * as urls from "../../../../../../../js/workplace/urls";
import {EditableForm, EditableItemData} from "../../../common/editable-list/editable-list.model";
import {VaAttribute} from "../../../../../data/va/Attribute";
import {AttributeService} from "../../attribute/attribute.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {Valued} from "../../../../../data/va/Valued";
import {AudioRecord} from "../../../../../data/va/AudioRecord";
import {AudioRecordService} from "../../../media-libraries/audio-record/audio-record.service";
import {getProgressDataPercent, ProgressData} from "../../../../../data/va/ProgressData";
import {FileItem} from "../../../common/file-uploader/uploader-vendor/file-item.class";
import WebSocketService from "../../../../../services/WebSocketService";
import * as cloneDeep from 'lodash/cloneDeep';


@Component({
    selector: 'extractor-edit',
    template: require('./extractor-edit.component.html'),
    styles: [require('./extractor-edit.component.less')]
})
export class ExtractorEditComponent extends BaseDetailsComponent<Extractor, CompositeKey<number>> implements DoCheck {

    objectIdKey = 'extractorId';

    defaultAsrSettings: VaAsrSettings;
    extractorScripts: CustomizationScript[] = [];

    // Список ответов, сущностей, сценариев, которые используют атрибут (по во)
    private allByValueOption: Map<string, Map<string, { key: CompositeKey<number> }[]>>;
    validatorTypes: ValidatorType[] = [];
    valueTypes: ValueType[] = [];
    private extractors: Extractor[];
    baseExtractors: Extractor[];
    dateExtractorData: DateExtractorRulesDTO;
    /**
     * Список атрибутов с экстрактором типа строка
     */
    attrsByAnyExtractors: VaAttribute[];

    private customerDiffer: KeyValueDiffer<string, any>;

    /**
     * Мапа с исходниками, которые нужны внутри объекта
     */
    sources: Map<string, any> = new Map<string, any>();

    audioRecords: AudioRecord[];

    selectedAudioFile: AudioRecord;

    selectedAudioFileURL: string;

    audioFileIsLoading: boolean = false;

    extractorHasVoiceChannel: boolean = false;

    showAudioPlayer: boolean = false;

    baseExtractorEditableItemData = new EditableItemData("вариант значения базового экстрактора", false, false, false,
        false, false, false);
    editableItemData = new EditableItemData("вариант значения", true, true, true, true, true, false, "fa-cubes");

    optionForm: EditableForm<ValueOption> = new EditableForm(new ValueOption());

    defaultCities: string[];

    defaultCityAttributes: VaAttribute[];


    /**
     * Id подписки на пуши импорта опций
     */
    subscriptionId: string;

    /**
     * Данные о прогрессе импорта формы
     */
    progressData: ProgressData;

    importForm = {
        success: false,
        errors: new Map<string, string>()
    };

    addExample: any = [{title: 'Из файла', format: 'file'},
        {title: 'Вручную', format: 'custom'}];

    /**
     * Показывать ли форму добавления опции вручную
     */
    showCustomAdd: boolean = false;

    /**
     * Показывать ли форму импорта опций
     */
    showImportForm: boolean = false;

    filter: ValueOptionsFilter = new ValueOptionsFilter();

    searchString: string;

    sortFields = [new SortField("Текст значения", "title")];

    file: any;

    closeButton: boolean = false;

    constructor(stateService: StateService,
                httpClient: HttpClient,
                public dataService: ExtractorService,
                titleService: Title,
                protected master: ExtractorMasterComponent,
                private differs: KeyValueDiffers,
                private attributeService: AttributeService,
                notificationService: NotificationService,
                private audioRecordService: AudioRecordService,
                private webSocketService: WebSocketService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);

    }

    get entityTitle(): string {
        return this.form.object.name;
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        await this.init();
    }

    async init() {
        this.extractorHasVoiceChannel = await this.attributeService.hasVoice();
        const promises: Promise<any>[] = [
            this.attributeService.findByExtractorValueType(ValueTypeEnum.ANY)
                .then(attributes => attributes.map(attribute => new VaAttribute(attribute))),
            this.attributeService.findCityAttributes()
                .then(attributes => attributes.map(attribute => new VaAttribute(attribute))),
            // если есть голосовой канал, то подгрузим аудиозаписи
            this.extractorHasVoiceChannel ? this.audioRecordService.findAllWithoutFill() : Promise.resolve(),
            this.dataService.getValidatorTypes(),
            this.dataService.getValueTypes(),
            this.dataService.getDateExtractorData(),
            this.dataService.findAll()
                .then(extractors => extractors.map(extractor => new Extractor(extractor))),
            this.loadExtractorScripts(),
            this.dataService.loadDefaultCities()
        ];
        [
            this.attrsByAnyExtractors,
            this.defaultCityAttributes,
            this.audioRecords,
            this.validatorTypes,
            this.valueTypes,
            this.dateExtractorData,
            this.extractors,
            this.extractorScripts,
            this.defaultCities
        ] = await Promise.all(promises);

        this.reloadSuitableBaseExtractors();
        // атрибут города для экстрактора адресов - все типа строка + атрибут-город
        this.defaultCityAttributes.push(...this.attrsByAnyExtractors);
        // подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VALUE_OPTIONS_PROGRESS",
            fn: (event) => this.receiveProgressPush(event)
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.webSocketService.removeListener(this.subscriptionId);
    }

    ngDoCheck(): void {
        if (this.customerDiffer) {
            const changes = this.customerDiffer.diff(this.form.object);
            if (changes) {
                this.sources.set("extractor", this.form.object);
                const canEdit = (this.form.object.baseId === undefined || this.form.object.baseId === null) && this.access;
                this.editableItemData.canCreate = canEdit;
                this.editableItemData.canDelete = canEdit;
                this.editableItemData.canEdit = canEdit;
            }
        }
    }

    async onObjectLoaded() {
        this.form.object = new Extractor(this.form.object) as any;
        await this.prepareOptions();
        await this.prepareAsrSettings(this.form.object);
        this.customerDiffer = this.differs.find(this.form.object).create();
    }

    /**
     * Загрузить скрипты для извлечения
     */
    async loadExtractorScripts(): Promise<CustomizationScript[]> {
        const params = new HttpParams();
        params.set("forUse", "true");
        return this.httpClient.get<CustomizationScript[]>(`${urls.va.attribute_scripts}`, {params: params})
            .toPromise();
    }


    generateFormObject(): Extractor {
        const extractor = new Extractor();
        // название объекта из state
        const nameFromParams = this.stateService.params['name'];
        if (nameFromParams) {
            extractor.name = nameFromParams;
        }
        extractor.valueOptions = [];
        this.prepareAsrSettings(extractor).then(() => {});
        this.form.object = extractor as any;
        this.customerDiffer = this.differs.find(this.form.object).create();
        return extractor;
    }

    /**
     * Заполнить asr настройки
     */
    async prepareAsrSettings(extractor: Extractor) {
        extractor.isAsr = extractor.asrSettings != null;
        if (!this.defaultAsrSettings) {
            this.defaultAsrSettings = await this.dataService.getDefaultAsrSettings();
            if (!extractor.asrSettings) {
                extractor.asrSettings = JSON.parse(JSON.stringify(this.defaultAsrSettings));
            }
        } else if (!extractor.asrSettings) {
            extractor.asrSettings = JSON.parse(JSON.stringify(this.defaultAsrSettings));
        }
    }

    /**
     * Заполнить недостащие данные об опциях
     */
    private async prepareOptions() {
        if (this.objId && this.form.object.valueOptions) {
            this.allByValueOption = await this.dataService.getAllByValueOption(this.objId);
        }
    }

    /**
     * При изменении типа атрибута
     */
    reloadSuitableBaseExtractors(): void {
        if (!this.extractors) {
            this.baseExtractors = [];
            return;
        }
        if (!this.form.object.valueType) {
            this.baseExtractors = [];
            return;
        }
        this.baseExtractors = this.extractors.filter(extractor =>
            extractor.valueType.name === this.form.object.valueType.name &&
            (!this.form.object.key || this.form.object.key.id !== extractor.key.id));
    }

    /**
     * При изменении типа экстрактора
     */
    onChangeExtractorType(): void {
        this.form.object.baseId = null;
        if (this.form.object.valueType.name != ValueTypeEnum.ENUM) {
            this.form.object.valueOptions = [];
        }
        if (this.form.object.valueType.name != ValueTypeEnum.ANY && this.form.object.valueType.name != ValueTypeEnum.INT && this.form.object.valueType.name != ValueTypeEnum.FLOAT) {
            // регулярку оставляем только у тех типов, в которых она используется
            this.form.object.regExp = null;
        }


        if (this.form.object.valueType.name == ValueTypeEnum.DATE) {
            this.form.object.intervalRule = this.dateExtractorData.defaultIntervalRule;
            this.form.object.multipleDatesRule = this.dateExtractorData.defaultMultiDatesRule;
        } else {
            this.form.object.intervalRule = null;
            this.form.object.multipleDatesRule = null;
        }

        if (this.form.object.valueType.name == ValueTypeEnum.ADDRESS) {
            this.form.object.defaultCity = this.defaultCities.find(city => city == 'Москва');
        } else {
            this.form.object.defaultCity = null;
        }
        this.reloadSuitableBaseExtractors();
    }

    /**
     * Нужно ли показывать блок со значениями базового экстрактора?
     */
    get showBaseExtractorValues(): boolean {
        return this.form.object.baseId &&
            this.form.object.valueType.name === 'ENUM';
    }

    /**
     * Получить значения базового экстрактора
     */
    public getBaseExtractorValueOptions(): ValueOption[] {
        if (!this.isModeNew()) {
            return this.form.object.valueOptions;
        } else if (this.form.object.baseId && this.baseExtractors) {
            let baseExtractor: Extractor = this.baseExtractors.find(extractor => extractor.key.id == this.form.object.baseId);
            return baseExtractor ? baseExtractor.valueOptions : [];
        }
        return [];
    }


    filterAttempts(valueOption: ValueOption): void {
        this.dataService.filterAttempts(this.stateService, valueOption);
    }

    /**
     * Генерация ключа опции
     */
    async generateValueOptionKey(valueOption: ValueOption) {
        // dto запроса
        const keyGenerationDto = {
            // значение опции
            value: valueOption.title,
            // ключи других опций (чтобы исключить дублирование)
            otherOptionsKeys: []
        };

        // собираем ключи других опций в dto
        this.form.object.valueOptions.forEach((otherOption) => {
            if (otherOption.title !== valueOption.title && otherOption.apiKey) {
                keyGenerationDto.otherOptionsKeys.push(otherOption.apiKey);
            }
        });

        // делаем запрос на генерацию ключа опции
        valueOption.apiKey = await this.httpClient.post<string>(`${this.dataService.baseUrl}/${this.objId ? this.objId : 0}/generateValueOptionsKey`, keyGenerationDto)
            .toPromise();
    }

    /**
     * Обработка во перед сохранением
     */
    isDefaultAsrSettings(): boolean {
        return this.form.object.asrSettings.allowBargeIn == this.defaultAsrSettings.allowBargeIn
            && this.form.object.asrSettings.grammar === this.defaultAsrSettings.grammar
            && this.form.object.asrSettings.noInputTimeout === this.defaultAsrSettings.noInputTimeout
            && this.form.object.asrSettings.recognitionTimeout === this.defaultAsrSettings.recognitionTimeout
            && this.form.object.asrSettings.speechCompleteTimeout === this.defaultAsrSettings.speechCompleteTimeout
            && this.form.object.asrSettings.speechIncompleteTimeout === this.defaultAsrSettings.speechIncompleteTimeout
            && this.form.object.asrSettings.bargeInWords === this.defaultAsrSettings.bargeInWords
            && this.form.object.asrSettings.vendor.name === this.defaultAsrSettings.vendor.name;
    }

    /**
     * Чистим список во: если есть старый вариант, то оставляем его, но актуализируем
     * @param extractor редактируемый объект
     */
    preHandleValueOptions(extractor: Extractor): void {
        if (!extractor.valueOptions) {
            return;
        }
        if (extractor.copyValueOptions != null) {
            // for each value options try find with same text on copied value options
            const handledVO = [] as ValueOption[];
            extractor.valueOptions.forEach((newVO, index) => {
                let find = false;
                extractor.copyValueOptions.forEach(oldVO => {
                    if (oldVO.title === newVO.title) {
                        // не забудем взять актуальные (из нового VO) параметры
                        oldVO.apiKey = newVO.apiKey;
                        oldVO.voiceFileName = newVO.voiceFileName;
                        oldVO.dtmfNumber = newVO.dtmfNumber;
                        oldVO.orderNum = index;
                        oldVO.showButtonInDialog = newVO.showButtonInDialog;
                        oldVO.audioFileId = newVO.audioFileId;
                        handledVO.push(oldVO);
                        find = true;
                    }
                });
                // not find - it's new
                if (!find) {
                    handledVO.push(newVO);
                }
            });
            // set vo
            extractor.valueOptions = handledVO;
        }
    }


    async save(preserveState?: boolean): Promise<void> {
        this.form.errors.clear();
        if (this.form.object.baseId && this.form.object.dynamicOptionsAttributeId) {
            this.form.errors.set("#main", "Заданы базовый экстрактор и атрибут со значениями из внешней системы");
            return;
        }
        const isAsr = this.form.object.isAsr;
        if (!isAsr || this.isDefaultAsrSettings()) {
            this.form.object.asrSettings = null;
        }
        // check changes in value options and set order idx
        this.preHandleValueOptions(this.form.object);

        if (this.form.object.regExp == "") {
            // очистим поле, если оно пустое
            this.form.object.regExp = null;
        }

        if (this.form.object.regExp) {
            // уберем пробелы по краям
            this.form.object.regExp = this.form.object.regExp.trim();
        }

        await super.save(preserveState);
    }


    async finishSave(savePromise, isNew: boolean, preserveState: boolean): Promise<void> {
        await super.finishSave(savePromise, isNew, preserveState);
        this.form.object = new Extractor(this.form.object);
        await this.prepareOptions();
        await this.prepareAsrSettings(this.form.object);
    }

    /**
     * Обработка ошибки: если что-то пошло не так, то нужно вновь заполнить форму настройками ASR'a
     * @param data ошикба
     */
    handleError(data): void {
        super.handleError(data);
        const isAsr = this.form.object.isAsr;
        if (isAsr && this.form.object.asrSettings == null) {
            this.form.object.asrSettings = JSON.parse(JSON.stringify(this.defaultAsrSettings));
        }
    }


    get removeConfirmMessage(): string {
        return `Вы уверены, что хотите удалить экстрактор?`;
    }


    showRemove(): boolean {
        return !this.form.object.suppliedType;
    }

    /**
     * Является ли экстрактор ребёнком
     * @param extractor
     */
    isBinaryChild(extractor: Extractor) {
        // нет экстрактора
        if (extractor == null) {
            return false;
        }
        // есть в родителях бинарный?
        const isBinaryChild = extractor.allBaseIds != null ? extractor.allBaseIds.includes('10001') : false;
        // есть в наследниках бинарный, либо прямой потомок бинарного
        return isBinaryChild || `${extractor.baseId}` == Valued.BINARY_EXTRACTOR;
    }

    /**
     * Действие при выборе в селекте базового экстрактора
     */
    onBaseExtractorSelect() {
        // скидываем изменения
        this.form.object.classifyTag = false;
        // ищем родителя
        const parent = this.extractors.find(extractor => this.form.object.baseId == extractor.key.id);
        // не нашли -- ничего не делаем
        if (parent == null) {
            // если родителя нет - то чистим всю цепочку наследования
            this.form.object.allBaseIds = null;
            return;
        }
        // проверяем родителя на бинарность
        const isBinary = `${parent.key.id}` == Valued.BINARY_EXTRACTOR || this.isBinaryChild(parent);
        // обновляем объектус
        this.form.object.classifyTag = this.mode == DetailsMode.NEW && isBinary;
        this.form.object.allBaseIds = [parent.key.id, ...(parent.allBaseIds as any)] as any;
    }

    /**
     * Получить url аудиофайла из optionForm.objectForm и задать имя файла для отображения
     * Если audioFileId не задан, то сбросить selectedAudioFile и url
     */
    updateAudioFile() {
        this.selectedAudioFile = null;
        this.selectedAudioFileURL = null;
        this.showAudioPlayer = false;
        const currentAudioFileId = this.optionForm.objectForm.audioFileId;
        if (currentAudioFileId) {
            this.audioFileIsLoading = true;
            this.selectedAudioFile = this.audioRecords?.find(audioRecord => audioRecord.key.id === currentAudioFileId);
            if (this.selectedAudioFile?.fileId) {
                this.audioRecordService.loadAudioFile(this.selectedAudioFile.fileId,
                    (audioUrl) => {
                        this.selectedAudioFileURL = audioUrl;
                        this.audioFileIsLoading = false;
                    }, () => {});
            } else {
                this.audioFileIsLoading = false;
            }
        }
    }

    /**
     * Отобразить аудиоплеер
     */
    playAudioFile() {
        if (this.selectedAudioFileURL) {
            this.showAudioPlayer = true;
        }
    }

    /**
     * При изменении "дом обязателен" для адреса
     */
    onToggleHouseNecessary(value: any) {
        if (!value) {
            this.form.object.apartmentNecessary = false;
        }
    }

    /**
     * Есть ли операция в процессе
     */
    isProgressDataAvailable(): boolean {
        return this.progressData?.operation && !this.progressData?.finished
    }

    /**
     * Добавили файл для импорта
     */
    onImportFileChange(items: FileItem[]) {
        if (!items) {
            this.file = null;
        } else {
            this.file = items.pop()._file;
        }
    }

    /**
     * Неверный формат файла импорта
     */
    onImportFileAddFailed(message: string) {
        this.notificationService.error(message, 5000);
    }

    /**
     * @return процент сделанного
     */
    getProgressPercent() {
        return getProgressDataPercent(this.progressData);
    }

    /**
     * Получить пуш с данными о прогрессе обработки
     */
    private async receiveProgressPush(event) {
        let data = JSON.parse(event.details);
        if (data.subscriptionId != this.subscriptionId) {
            // другая сущность
            return;
        }
        if (!data.finished) {
            // в процессе
            this.progressData = data;
            return;
        }

        this.progressData = null;

        if (!this.closeButton) {
            // отображаем уведомление
            if (data.success) {
                if (data.additionalData) {
                    // заменяем существующие опции на новые
                    this.form.errors = new Map<string, string>();
                    this.form.object.valueOptions = (data.additionalData as []).map(obj => new ValueOption(obj));
                    this.form.object.valueOptions.map(val => {
                        if (!val.apiKey) {
                            this.generateValueOptionKey(val).finally(() => {
                                this.showImportForm = false;
                            });
                        } else {
                            this.showImportForm = false;
                        }
                    });
                    this.notificationService.success(data.success, 30000);
                }

            }
            if (data.info) {
                this.notificationService.info(data.info);
            } else if (data.warning) {
                this.notificationService.warning(data.warning);
            } else if (data.error) {
                this.notificationService.error(data.error);
            }
        }
        this.closeButton = false;
    }

    pushValueOptions() {
        // шлем файл на сервер
        const formData = new FormData();
        formData.append('subscriptionId', this.subscriptionId);
        formData.append('existingOptions', JSON.stringify(this.form.object.valueOptions ? this.form.object.valueOptions : []));
        formData.append('file', this.file);
        this.dataService.import(formData).catch((reason => {
            let message = reason.errors?.[0].message ? reason.errors.map(error => error.message).join('\n') : JSON.stringify(reason);
            this.notificationService.error(message);
        }));
    }

    /**
     * Показать окно добавление примера
     * @param option - на какую опцию жмакнули (импорт или одиночный ввод)
     */
    showAddPanel(option?: any) {
        if (option) {
            this.showCustomAdd = option.format == "custom";
            if (this.showCustomAdd) {
                this.selectedAudioFileURL = null;
            }
            this.showImportForm = option.format == "file";
            this.optionForm.new = true;
        } else {
            this.showCustomAdd = false;
            this.showImportForm = false;
            this.optionForm = new EditableForm(new ValueOption());
        }
    }

    /**
     * Фильтр для коллекции опций
     */
    filterChange(searchString: string, item: any, filter: ValueOptionsFilter): boolean {
        return item.apiKey && (searchString ? item.apiKey.toLowerCase().indexOf(searchString) >= 0 : true)
            || item.title && (searchString ? item.title.toLowerCase().indexOf(searchString) >= 0 : true);
    }

    /**
     * Добавление элемента в коллекцию вручную
     */
    addValueOption(): boolean {
        this.optionForm.errors = new Map<string, string>();
        let formObject = this.optionForm.objectForm;
        // валидируем форму, если есть функция валидации
        const validatorResult = formObject.validateForm(this.optionForm.index, this.sources);
        if (validatorResult.size === 0) {
            let object = cloneDeep(formObject.formToObject(this.sources));
            if (this.optionForm.new) {
                // если это создание нового элемента - просто положим в конец массива
                if (this.form.object.valueOptions == null) {
                    this.form.object.valueOptions = [];
                }
                this.form.object.valueOptions.push(object);
            } else {
                // если это было редактирование - заменим соответствующий элемент массива
                this.form.object.valueOptions[this.optionForm.index] = object;
            }
            // теперь закроем форму
            this.notificationService.success("Успешно");
            this.optionForm = new EditableForm(new ValueOption());
            this.optionForm.new = true;
            this.selectedAudioFile = null;
            this.selectedAudioFileURL = null;
            object.afterAction(false, this.sources);
            this.showCustomAdd = false;
            return true;
        } else {
            this.optionForm.errors = validatorResult;
            return false;
        }
    };


    close() {
        this.closeButton = true;
        this.showImportForm = false;
    }

    /**
     * Удалить опцию в экстракторе перечисление
     */
    deleteValueOption(element: ValueOption): void {
        let index = this.form.object.valueOptions.findIndex(item => item.apiKey == element.apiKey);

        const object = this.form.object.valueOptions[index];
        this.form.object.valueOptions.splice(index, 1);
        object.afterAction(true, this.sources);
    };

    moveUp(element: ValueOption): void {
        let index = this.form.object.valueOptions.findIndex(item => item.apiKey == element.apiKey);

        if (index > 0) {
            const previousObject = this.form.object.valueOptions[index - 1];
            this.form.object.valueOptions[index - 1] = this.form.object.valueOptions[index];
            this.form.object.valueOptions[index] = previousObject;
        }
    };

    moveDown(element: ValueOption): void {
        let index = this.form.object.valueOptions.findIndex(item => item.apiKey == element.apiKey);

        if (index < this.form.object.valueOptions.length - 1) {
            const nextObject = this.form.object.valueOptions[index + 1];
            this.form.object.valueOptions[index + 1] = this.form.object.valueOptions[index];
            this.form.object.valueOptions[index] = nextObject;
        }
    };

    editValueOption(obj: any, index: number) {
        this.optionForm.objectForm = obj;
        this.optionForm.new = false;
        this.optionForm.show = true;
        this.optionForm.index = index;

        let object = cloneDeep(this.form.object.valueOptions[index]);
        object.beforeEdit(this.sources);

        this.updateAudioFile();
        this.showCustomAdd = true;
    }
}

export class ValueOptionsFilter {
    name: string;

    constructor() {
    }
}
