import {NgModule} from "@angular/core";
import {HighlightTextModule} from "../../common/highlight-text/highlight-text.module";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {FormControlModule} from "../../common/form/form-control.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatRadioModule} from "@angular/material";
import {ConfirmationExtractionEditComponent} from "./confirmation/confirmation-extraction-edit.component";
import {AttributeExtractionEditComponent} from "./attribute/attribute-extraction-edit.component";
import {UIRouterModule} from "@uirouter/angular";
import {AttemptEditComponent} from "./edit/attempt-edit.component";
import {AttemptMasterComponent} from "./master/attempt-master.component";
import {AttemptDetailsComponent} from "./details/attempt-details.component";
import {AttemptService} from "./attempt.service";
import {CollectionModule} from "../../common/collection/collection.module";
import {ExpertModule} from "../../common/expert/expert.module";
import {MacroModule} from "../../common/macro/macro.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {FormulationModule} from "../../formulation/formulation.module";
import {TaggingItemModule} from "../../common/tagging-item/tagging-item.module";
import {EruditeFileUploaderModule} from "../../common/file-uploader/erudite-file-uploader.module";
import {MatDialogModule} from "@angular/material/dialog";
import {BoilerplateModule} from "../base/boilerplate.module";
import {AceEditorModule} from "ng2-ace-editor";
import {MassOperationDialogComponent} from "../mass-operation/dialog/mass-operation-dialog.component";
import {TagTreeModule} from "../tag/tree/tag-tree.module";

@NgModule({
    imports: [
        HighlightTextModule,
        CommonModule,
        FormsModule,
        FormControlModule,
        NgSelectModule,
        MatRadioModule,
        UIRouterModule,
        CollectionModule,
        ExpertModule,
        MacroModule,
        EntityUsageModule,
        FormulationModule,
        TaggingItemModule,
        EruditeFileUploaderModule,
        MatDialogModule,
        BoilerplateModule,
        AceEditorModule,
        TagTreeModule
    ],
    declarations: [
        AttemptEditComponent,
        AttributeExtractionEditComponent,
        ConfirmationExtractionEditComponent,
        AttemptMasterComponent,
        AttemptDetailsComponent
    ],
    exports: [
        AttemptEditComponent,
        AttributeExtractionEditComponent,
        ConfirmationExtractionEditComponent,
        AttemptMasterComponent,
        AttemptDetailsComponent
    ],
    providers: [
        AttemptEditComponent,
        AttributeExtractionEditComponent,
        ConfirmationExtractionEditComponent,
        AttemptService
    ],
    entryComponents: [
        MassOperationDialogComponent
    ]
})
export class AttemptModule {
    static states = [
        {
            name: "robot.attempts",
            url: "/attempts/:id/:selectedValueOption",
            views: {
                "content@authorized": {
                    component: AttemptMasterComponent
                }
            },
            data: {
                title: "Примеры извлечения",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }

        },
        {
            name: "robot.attempts.edit",
            url: "/:attemptId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@robot.attempts": {
                    component: AttemptDetailsComponent
                }
            },
            data: {
                title: "Примеры извлечения | ",
                newObjectTitle: "Новый пример извлечения",
                editObjectTitle: "Редактирование",
                successfulSaveMessage: "Пример сохранён",
                meta: {
                    parameters: ["attemptId"],
                    sectionFunction: ([attemptId]) => "Обучение робота",
                    subSectionFunction: ([attemptId]) => attemptId ? "Редактирование попытки извлечения" : "Создание попытка извлечения"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];
}