import {Component} from "@angular/core";
import {VaTBMessageCluster} from "../../../../../data/va/Message";
import {TbDialogModel, VaDialog} from "../../model/tb-dialog.model";
import {BaseDetailsComponent, DetailsMode} from "../../base/base-details.component";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {ClusterEditService} from "./cluster-edit.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {MessageClustersComponent} from "../message-clusters-list/message-clusters.component";
import {ClusterVaTag} from "../../model/tb-params.model";
import {ShowDialogComponent} from "./show-dialog/show-dialog.component";


@Component({
    selector: 'cluster-edit',
    template: require('./cluster-edit.component.html'),
    styles: [require('./cluster-edit.component.less')]
})
export class ClusterEditComponent extends BaseDetailsComponent<VaTBMessageCluster, CompositeKey<number>> {

    entityTitle: string;
    helperColumn: string;
    objectIdKey: string = "clusterId";
    messageTags: any[] = [];
    messageTagsIds: any[] = [];
    tagsTree: ClusterVaTag[] = [];
    tbList: VaTBMessageCluster[] = [];
    tagsIds: string[] = [];
    sentToTagMessageCount: number = 0;
    messages: VaTBMessageCluster[] = [];
    workMode: string;
    justSaved: boolean;
    justSaveError: boolean;
    clusterState: any;
    sourceTagId: number;
    dialog: VaDialog;
    searchQuery: string;
    states: any;
    sortFields: SortField[] = [new SortField("Текст", "text"), new SortField("Оператор", "clusterEditor")];

    constructor(protected titleService: Title,
                protected stateService: StateService,
                public dataService: ClusterEditService,
                protected httpClient: HttpClient,
                private modalDialog: MatDialog,
                protected notificationService: NotificationService,
                protected parent: MessageClustersComponent) {
        super(stateService, parent, httpClient, dataService, titleService, notificationService);


        this.sourceTagId = stateService.params['sourceTagId'];
        this.objId = stateService.params['clusterId'];
        const searchMessageId = stateService.params['smi'];
        this.searchQuery = searchMessageId ? searchMessageId : this.parent.searchQuery;
        const workMode = stateService.params['workMode'];
        this.workMode = workMode.toUpperCase();
        this.setModeHandy(this.workMode);
        this.sentToTagMessageCount = 0;

        // загружаем список возможных статусов кластера
        this.loadStates();
        this.loadMessages();
        this.getAccess()
    }

    async ngOnInit() {
        await super.ngOnInit();
        let temp = await this.dataService.getTBList(null);
        this.tbList = temp["list"];
        this.titleService.setTitle("Кластер #" + this.objId);
    }


    async getAccess() {
        this.access = await this.dataService.getAccessForEdit();
    }

    async loadStates() {
        this.states = await this.dataService.getStates();
    }

    messagesChange(message, deletion) {
        if (deletion) {
            this.sentToTagMessageCount = this.messages.filter(m => m.tagId).length;
            return;
        }
        this.messages.find(msg => msg.key.id === message.key.id).tagId = message.tagId;
        this.sentToTagMessageCount = this.messages.filter(m => m.tagId).length;
    }

    /**
     * Ручная установка обработки кластера
     * @param workMode
     */
    setModeHandy(workMode: string) {
        if (workMode.toUpperCase() == DetailsMode.EDIT) {
            this.mode = DetailsMode.EDIT;
        } else {
            this.mode = DetailsMode.SHOW;
        }
    }

    /**
     * Загрузка сообщений кластера
     */
    async loadMessages() {
        this.messageTags = [];
        // загружаем список сообщений в этом кластере
        this.messages = await this.dataService.getClusterMessages(this.objId);
        this.sentToTagMessageCount = this.messages.filter(m => m.tagId).length;
        // заполним поле selected в сообщении согласно его state
        let tagIds = [];
        this.messages.forEach(msg => {
            if (msg.tagId) {
                tagIds.push(msg.tagId);
            }
            // Здесь грузим диалог, чтобы сортировать по времени диалога список сообщений.
            // И передаём его дальше в директиву (не грузим диалог повторно)
            // Но всё это делаем, если есть dialogId
            if (msg.dialogId) {
                this.dataService.getDialog(msg.dialogId)
                    .then((data) => {
                        msg.dialog = this.dialog = data;
                    })
            }
        });
        this.selectMessageTag(tagIds);
    }

    selectMessageTag(msgTagIdList, removeList = [], addToList = true, tagTree: ClusterVaTag[] = null) {

        if (addToList) {
            this.messageTagsIds.push(...msgTagIdList);
        }

        removeList.forEach(id => {
            const index = this.messageTagsIds.indexOf(id);
            if (index >= 0) {
                this.messageTagsIds.splice(index, 1);
            }
        });

        let tagToCount = {};
        this.messageTagsIds.forEach(id => {
            tagToCount[id] = (tagToCount[id] || 0) + 1;
        });

        // копия массива
        if (tagTree) {
            this.tagsTree = tagTree;
            let tags = this.tagsTree;
            for (let tag of tags) {
                Array.prototype.push.apply(tags, tag.children);
            }
            this.messageTags = [];

            if (tags && tags.length > 0) {
                tags.forEach(tag => {
                    if (this.dataService.FICTIVE_ROOT_TAG_ID != tag.key.id) {
                        let nodeId = "node-" + tag.key.id;
                        this.tagsIds.push(nodeId);
                        tag.clusterMsgCount = tagToCount[tag.key.id];
                        this.messageTags.push(tag);
                    }
                });
            }
        }

    }

    /**
     * Сохранение изменений в кластере
     */
    saveMes(state) {
        // проставим messageList в кластер, чтобы передавать его на сервер
        this.form.object.messageList = this.messages;
        this.form.object.state = state;
        this.save(true).then(() => {
            this.modifyInProcess(false);
            this.workMode = 'VIEW';
            this.mode = DetailsMode.SHOW;
        }, () => {
            this.form.object.state = this.clusterState;
        })
    }

    /**
     * Переход в режим обработки
     */
    goToEditMode() {
        this.workMode = 'EDIT';
        this.mode = DetailsMode.EDIT;
        this.goToProcessState('EDIT');
    }

    /**
     * Переход в режим просмотра, вернуть кластер из обработки
     * сохранить изменения в кластере, потом перейти на новый стейт и убрать эксперта из обработки
     */
    goToViewMode() {
        // проставим messageList в кластер, чтобы передавать его на сервер
        this.form.object.messageList = this.messages;
        this.workMode = 'VIEW';
        this.mode = DetailsMode.SHOW;
        this.save(false).then(() => {
            this.goToProcessState('VIEW');
        });
    }

    /**
     * перейти в режим обработки кластера
     * @param state
     */
    goToProcessState(state) {
        // Если мы в режиме просмотра, то перейдём в режим редактирования
        state = state.toUpperCase();
        switch (state) {
            case 'VIEW':
                this.modifyInProcess(false);
                break;
            case 'EDIT':
                this.modifyInProcess(true);
                break;
        }
        if (this.workMode !== state) {
            this.stateService.go('robot.tb_message_clusters.edit', {
                clusterId: this.objId,
                workMode: state,
                modifyProcessState: true
            });
        }
    }

    /**
     * Показывать кнопку "Переход в режим просмотра"
     */
    isViewModeEnabled() {
        return this.access && this.workMode !== 'VIEW' && this.checkCurrentEditor();
    }

    /**
     * Показывать кнопку "Взять в обработку"
     */
    isEditModeEnabled() {
        return this.workMode == 'VIEW' || this.checkOtherEditor();
    }

    /**
     * Маркируем кластер как обрабатывающийся
     */
    async modifyInProcess(setInProgress: boolean) {

        this.form.object.clusterEditor = await this.dataService.isInProgress(this.objId, setInProgress);

        this.parent.replaceObj(this.form.object);
        this.form.errors.clear();
        if (this.checkOtherEditor()) {
            this.form.errors["#main"] = "Кластер редактируется другим пользователем";
        }

    }

    getCurrentWorkModeText() {
        switch (this.mode) {
            case DetailsMode.EDIT:
                return "Редактирование";
            case DetailsMode.SHOW:
                return "Просмотр";
            default:
                return "Редактирование";
        }
    }

    /**
     * Дизейблить ли кнопку "к другому кластеру"
     *
     * @param forward следующий/предыдущий
     * @returns {boolean} дизейблить
     */
    isNextButtonDisabled(forward) {

        // индекс в списке на странице
        const currentIndex = this.tbList.findIndex(cluster => {
            return cluster.key.id == Number.parseInt(this.objId);
        });

        // первый ли элемент на странице
        const first = currentIndex == 0;
        // последний ли элемент на странице
        const last = currentIndex == this.tbList.length - 1;

        return forward ? last : first;
    }

    /**
     * Переход между кластерами без возврата к списку
     *
     * @param forward вперед/назад
     * @param currentIndex индекс текущего кластера, задается, если это рекурсивный вызов при переходе на другую страницу
     */
    nextCluster(forward, currentIndex) {

        if (typeof currentIndex == 'undefined') {
            // если индекс текущего кластера не задан, ищем
            currentIndex = this.tbList.findIndex(cluster => {
                return cluster.key.id == Number.parseInt(this.objId);
            });
        }

        // ищем следующий кластер
        let next;
        if (forward) {
            // если двигаемся вперед по списку, берем следующий по индексу
            let nextIndex = currentIndex + 1;
            if (nextIndex < this.tbList.length) {
                // не вышли за конец страницы - ок
                next = this.tbList[nextIndex];
            }
        } else {
            // если двигаемся назад, берем предыдущий по индексу
            let nextIndex = currentIndex - 1;
            if (currentIndex > 0) {
                // не вышли за начало страницы - ок
                next = this.tbList[nextIndex];
            }
        }

        // нашли следующий кластер, переходим на него
        if (next) {
            this.stateService.go('robot.tb_message_clusters_edit', {
                sourceTagId: this.sourceTagId,
                clusterId: next.key.id,
                workMode: this.workMode.toLowerCase(),
                modifyProcessState: this.workMode.toUpperCase() === 'EDIT'
            });
        }

    }

    /**
     * Копирование ссылки на редактирование кластера сообщения
     * @param msgId
     */
    copyText(msgId: number) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.getMessageHref(msgId);
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.onSuccessCopy();
    }

    /**
     * создание ссылки на конкретный кластер
     * @param msgId
     */
    getMessageHref(msgId) {
        return `${window.location.origin}/workplace.html#/workplace/robot/${this.parent.projectVersionId}/${this.sourceTagId}/tb_message_clusters/${this.workMode.toLowerCase()}/${this.objId}?smi=${msgId}`;
    }

    onSuccessCopy() {
        this.notificationService.success("Ссылка скопирована", 3000);
    }

    /**
     * получение названия тематики для отображения в кластере
     * @param tagId
     */
    getTagName(tagId): string {
        if (!tagId) {
            return "";
        }
        if (!this.parent.tags) {
            return "";
        }
        return this.parent.tagIdToText(tagId)
    }

    /**
     * Получение css-класса для кнопки по изменению статуса
     */
    getClusterStateBtnClass(stateName: string) {

        // если текущий статус выбран
        if (this.form.object.state.name == stateName) {
            switch (stateName) {
                case 'NEW':
                    return 'btn-info';
                case 'DONE':
                    return 'btn-success';
                case 'BAD':
                    return 'btn-danger';
            }
        } else {
            // если этот статус не активен - то дефолтный класс
            return "btn-plain";
        }
    }

    /**
     * Удаление сообщения (в том числе из кластера)
     * @param msg
     */
    deleteMsg(msg) {
        this.dataService.deleteMessage(msg.key.id).then(() => {
            // если сообщение успешно удалено, удаляем из кластера
            this.excludeMessage(msg);
        });
    }

    /**
     * Стирание тематики
     * @param msg
     */
    async clearTag(msg) {
        await this.dataService.clearTag(msg.key.id);
        // стираем тематику
        msg.tagId = null;
        this.markAsSentToTag(msg);
    }

    /**
     * Исключение сообщение из кластера
     */
    excludeMessage(msg) {
        this.messages = this.messages.filter(message => message.key.id !== msg.key.id);
        this.messagesChange(msg, true);
    }

    /**
     * Отметим, что сообщение отправлено в тематику
     * @param msg (месседж или реплай)
     */
    markAsSentToTag(msg) {
        msg.justSentToTag = false;
        msg.justSentToTag = true;

        this.messagesChange(msg, false);
    }

    /**
     * Update tag list
     * Load tags from server again
     */
    updateTags() {
        this.parent.loadTags();
    }

    /**
     * Открытие модального окна с TBDialog-ом по id
     * @param dialogId id tb-dialog-а
     */
    openTBDialogModal(dialogId) {
        const data: TbDialogModel = {
            dialogId: dialogId,
            viewMode: null
        };

        this.modalDialog.open(ShowDialogComponent, {
            width: '500px',
            data: data
        });
    }


    showCancel(): boolean {
        return false;
    }

    showRemove(): boolean {
        return false;
    }

    showSave(): boolean {
        return false;
    }

    generateFormObject() {
        return new VaTBMessageCluster();
    }

    onObjectLoaded() {
        const modifyProcessState = this.stateService.params["modifyProcessState"];
        if (modifyProcessState != null && modifyProcessState) {
            this.modifyInProcess(modifyProcessState);
        }
        // сохраненное значение состояния кластера
        this.clusterState = this.form.object.state;
    }

    checkCurrentEditor() {
        return this.form.object && this.form.object.clusterEditor && this.form.object.clusterEditor.name === 'CURRENT';
    }

    checkOtherEditor() {
        return this.form.object && this.form.object.clusterEditor && this.form.object.clusterEditor.name === 'OTHER';
    }

    checkNoEditor() {
        return this.form.object && this.form.object.clusterEditor && this.form.object.clusterEditor.name === 'NOBODY';
    }

    checkNotBadState() {
        return this.form.object.state && this.form.object.state.name != 'BAD';
    }
}