import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {FileSaverService} from "ngx-filesaver";
import {Component, OnInit} from "@angular/core";
import {TagService} from "../tag.service";
import {TagsComponent} from "../list/tags.component";
import {TagIdsEnum, VaTag} from "../../../../../data/va/Tag";
import {BaseDetailsComponent, DetailsMode} from "../../base/base-details.component";
import {CompositeKey} from "../../../../../data/va/Common";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {VASimpleModuleAnswer} from "../../../../../data/va/SimpleModuleAnswer";
import {ValueOption} from "../../../../../data/va/Extractor";
import {AttributeService} from "../../attribute/attribute.service";
import {VAScript} from "../../../../../data/va/Script";
import {AnswerService} from "../../answer/answer.service";
import {VATagAnswer} from "../../../../../data/va/TagAnswer";
import {ChangePushEvent} from "../../base/base-data.service";
import WebSocketService from "../../../../../services/WebSocketService";

@Component({
    selector: 'small-talks',
    template: require('./small-talks.component.html'),
    styles: [require('./small-talks.component.less')]
})
export class SmallTalksComponent extends BaseDetailsComponent<VaTag, CompositeKey<number>> implements OnInit {
    projectVersionId: string;
    objectIdKey: string = 'tagId';
    objId: any;

    sma: VASimpleModuleAnswer;
    channels: ValueOption[];
    isLoading: boolean = true;

    casesMessage: string;
    mode: DetailsMode;
    script: VAScript;
    scriptEditLink: string = 'robot.script_edit';

    operatorRequestTagId = TagIdsEnum.SMALL_TALK_ID_OPERATOR_REQUEST;
    showEditSave: boolean = true;
    answers: VATagAnswer[];
    private subscriptionId: string;
    defaultQuery: string;


    constructor(protected stateService: StateService,
                public attributeService: AttributeService,
                protected actService: TagService,
                protected tagService: TagService,
                protected answerService: AnswerService,
                protected httpClient: HttpClient,
                protected fileService: FileSaverService,
                protected webSocketService: WebSocketService,
                protected master: TagsComponent,
                protected titleService: Title,
                protected notificationService: NotificationService) {
        super(stateService, master, httpClient, actService, titleService, notificationService);

        this.defaultQuery = stateService.params['query'];
        this.objId = stateService.params['tagId'];
    }

    async ngOnInit() {
        await super.ngOnInit();
        await this.loadAnswers();
        await this.loadTagAnswers();
        await this.loadScripts();

        // подписка на изменения ответа
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_ENTITY_CHANGE",
            fn: (event) => {
                const changeEvent: ChangePushEvent = JSON.parse(event.details);
                if (this.form.object.key.projectVersionId === changeEvent.projectVersionId && changeEvent.className === "VATagAnswer") {
                    this.loadTagAnswers(items => {
                        const answer: VATagAnswer = changeEvent.object;
                        if (answer) {
                            // если доступен ответ, который только что создан и мы нажимали на кнопку создать - сразу вставим его в поле
                            const newAnswer = items.find(item => {
                                return item.key.id === answer.key.id && item.key.projectVersionId === answer.key.projectVersionId
                            });
                            if (newAnswer) {
                                this.form.object.returnAnswerId = newAnswer.key.id;
                            }
                        }
                    });
                }
            }
        });

        this.isLoading = false;
    }

    get entityTitle(): string {
        return "";
    }

    generateFormObject(): VaTag {
        return undefined;
    }

    showRemove(): boolean {
        return false;
    }

    showSave(): boolean {
        return this.showEditSave;
    }

    async loadAnswers() {
        this.sma = await this.actService.getRobotAnswers(this.objId.toString());
        this.channels = await this.attributeService.getChannelsWithAssistant();
    }


    async loadTagAnswers(callback?: (items: any) => void) {
        let params = {};
        if (!this.isModeNew()) {
            params["forVATagAnswer"] = this.objId;
        }
        this.answers = await this.answerService.findAll(params);
        if (callback) {
            callback(this.answers);
        }
    }

    /**
     * Загрузка скрипта по тематике
     */
    async loadScripts() {
        this.script = await this.tagService.loadScript(this.objId);
    }

    /**
     * Сгенерировать кейсы прохождения сценария
     */
    generateCases(event) {
        const loadingMessage = 'Генерация кейсов...';
        if (!event.ctrlKey && !event.metaKey || !this.script || loadingMessage === this.casesMessage) {
            // нужно кликать с зажатым контролом и чтобы у группы был сценарий и загрузка не уже в процессе
            return;
        }
        // без информации о прохождении сценария и расцветки, если не зажат шифт
        const boringFormat = !event.shiftKey;

        this.casesMessage = loadingMessage;
        this.tagService.sendCases(this.script.key.id.toString(), this.projectVersionId, boringFormat).then((data) => {
            const file = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const tagName = this.form.object.text.replace(/[^A-Za-zА-Яа-яЁё0-9]/, "_").replace(/_{2,}/, "_");
            const fileName = `Кейсы_${this.projectVersionId}_${tagName}_${this.objId}.xlsx`;
            this.fileService.save(file, fileName);
            this.casesMessage = '';
        });
    }


    /**
     * надпись на форме в зависимости от режима
     */
    get formTitle(): string {
        switch (this.mode) {
            case DetailsMode.SHOW:
            case DetailsMode.EDIT:
                return "Редактирование";
            case DetailsMode.NEW:
                return "Создание тематики";

        }
    }

    onTabSelect(event: string) {
        this.showEditSave = event === 'SETTINGS';
    }

    checkRepeatOnlyLastReply(nestable: boolean): void {
        if (!nestable) {
            this.form.object.repeatOnlyLastReply = false;
            this.form.object.returnAnswerId = null;
        }
    }
}
