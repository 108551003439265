import {APP_INITIALIZER, NgModule, NgZone} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {UpgradeModule} from "@angular/upgrade/static";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import DefaultAvatarComponent from "./workplace/io/components/DefaultAvatarComponent";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UIRouter, UrlService} from '@uirouter/core';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {UIRouterModule} from '@uirouter/angular';
import {ScriptViewerComponent} from "./workplace/io/components/script-builder/script-viewer.component";

import AqExtractableInfoComponent from "./workplace/io/components/AqExtractableInfoComponent";
import states from "./workplace/io/states/main.states";
import {MatExpansionModule} from "@angular/material";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from 'ngx-mat-datetime-picker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {StepLabelSectionComponent} from "./workplace/io/components/script-builder/step-label-section/step-label-section.component";
import {ProjectVersionInterceptor} from "./workplace/io/components/ProjectVersionInterceptor";

import {
    accountServiceProvider,
    statisticsServiceProvider,
    websocketServiceProvider
} from "./workplace/io/service/ajs-upgraded-providers";
import {ScriptEditComponent} from "./workplace/io/components/script-builder/script-edit.component";
import {ConfirmDialogComponent} from "./workplace/io/components/common/confirm-dialog/confirm-dialog.component";
import {SnackbarComponent} from "./workplace/io/components/common/snackbar/snackbar.component";
import {EntityAccordionComponent} from "./workplace/io/components/script-builder/accordion/entity-accordion.component";
import {ScriptBuilderResponseExtractor} from "./workplace/io/components/ScriptBuilderResponseExtractor";
import {ProcedureExitNameDialogComponent} from "./workplace/io/components/script-builder/dialog/procedure/procedure-exit-name-dialog.component";
import {RerouteParamsDialogComponent} from "./workplace/io/components/script-builder/dialog/reroute/reroute-params-dialog.component";
import {EditEruditeElementDialogComponent} from "./workplace/io/components/script-builder/dialog/edit-erudite-element/edit-erudite-element-dialog.component";
import {ExportScenario} from "./workplace/io/components/script-builder/dialog/export-scenario/export-scenario.component";
import {EdgeConditionComponent} from "./workplace/io/components/script-builder/edge-condition/edge-condition.component";
import {AttributeRuleEditorModule} from "./workplace/io/components/attribute/rule/attribute-rule-editor.module";
import {DatetimePickerModule} from "./workplace/io/components/common/datetime-picker/datetime-picker.module";
import {SmaModule} from "./workplace/io/components/va/sma/sma.module";
import {CollectionModule} from "./workplace/io/components/common/collection/collection.module";
import {MacroModule} from "./workplace/io/components/common/macro/macro.module";
import {ExtractableValuesModule} from "./workplace/io/components/va/extractable-values/extractable-values.module";
import {EditableListModule} from "./workplace/io/components/common/editable-list/editable-list.module";
import {FormControlModule} from "./workplace/io/components/common/form/form-control.module";
import {ExpertModule} from "./workplace/io/components/common/expert/expert.module";
import {AppConfigService} from "./workplace/io/service/app-config.service";
import {EntityUsageModule} from "./workplace/io/components/entity-usage/entity-usage.module";
import {FormulationModule} from "./workplace/io/components/formulation/formulation.module";
import {AnswerModule} from "./workplace/io/components/va/answer/answer.module";
import {AudioRecordModule} from "./workplace/io/components/media-libraries/audio-record/audio-record.module";
import {FileUploadService} from "./workplace/io/service/FileUploadService";
import {AttributeModule} from "./workplace/io/components/va/attribute/attribute.module";
import {ExtractorPropertiesModule} from "./workplace/io/components/common/extractor-properties/extractor-properties.module";
import {VaAsrModule} from './workplace/io/components/common/va-asr/va-asr.module';
import {TaggingItemModule} from "./workplace/io/components/common/tagging-item/tagging-item.module";
import {ExtractorModule} from "./workplace/io/components/va/extractor/extractor.module";
import {ScenariosModule} from "./workplace/io/components/va/scenario/scenarios.module";
import {TextToSpeechModule} from "./workplace/io/components/media-libraries/text-to-speech/text-to-speech.module";
import {ModelModule} from "./workplace/io/components/va/model/model.module";
import {ModelQueryTestModule} from "./workplace/io/components/query-test/model-query-test.module";
import {BoilerplateModule} from "./workplace/io/components/va/base/boilerplate.module";
import {ScriptImportDialogComponent} from "./workplace/io/components/va/script-import/script-import-dialog.component";
import {EruditeFileUploaderModule} from "./workplace/io/components/common/file-uploader/erudite-file-uploader.module";
import {FileSaverModule} from "ngx-filesaver";
import {EruditeNgbDatepickerModule} from "./workplace/io/components/common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";
import {ProcedureModule} from "./workplace/io/components/va/procedure/procedure.module";
import {TextDataModule} from "./workplace/io/components/va/text-data/text-data.module";
import {SettingsModule} from "./workplace/io/components/va/settings/settings.module";
import {ExtractorRegularExpressionModule} from "./workplace/io/components/extractor-regular-expression/extractor-regular-expression.module";
import {DkbModule} from "./workplace/io/components/va/dkb/dkb.module";
import {CustomizationScriptModule} from "./workplace/io/components/va/customization-script/customization-script.module";
import {ProjectModule} from "./workplace/io/components/project/project.module";
import {ProjectVersionModule} from "./workplace/io/components/project-version/project-version.module";
import {VersioningProgressBarModule} from "./workplace/io/components/common/versioning-progress-bar/versioning-progress-bar.module";
import {HighlightTextModule} from "./workplace/io/components/common/highlight-text/highlight-text.module";
import {TagTreeModule} from "./workplace/io/components/va/tag/tree/tag-tree.module";
import {TranscriptionModule} from "./workplace/io/components/va/transcription/transcription.module";
import {DialogModule} from "./workplace/io/components/dialog/dialog.module";
import {DialogStatModule} from "./workplace/io/components/dialog-stat/dialog-stat.module";
import {AttemptModule} from "./workplace/io/components/va/attempt/attempt.module";
import {TagModule} from "./workplace/io/components/va/tag/tag.module";
import {ChatModule} from "./workplace/io/components/chat/chat.module";
import {AnalysisModule} from "./workplace/io/components/analysis/analysis.module";
import {AccountModule} from "./workplace/io/components/va/account/account.module";
import {DenyButtonModule} from "./workplace/io/components/common/deny-button/deny-button.module";
import {NotificationModule} from "./workplace/io/components/common/snackbar/notification/notification.module";
import {SvgIconRegistryService} from "angular-svg-icon";
import {FileModule} from "./workplace/io/components/va/file/file.module";
import {TagBuildingModule} from "./workplace/io/components/va/tagbuilding/tagbuilding.module";
import {ChangesModule} from "./workplace/io/components/journals/changes/changes.module";
import {registerLocaleData} from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import {LoginsModule} from "./workplace/io/components/journals/logins/logins.module";
import {JournalsModule} from "./workplace/io/components/journals/journals.module";
import {ReportsModule} from "./workplace/io/components/reports/reports.module";
import {AttachmentDialogModule} from "./workplace/io/components/common/attachment-dialog/attachment.module";
import {MassOperationModule} from "./workplace/io/components/va/mass-operation/mass-operation.module";
import {MlModule} from "./workplace/io/components/va/ml/ml.module";
import {LicenseModule} from "./workplace/io/components/license/license.module";
import {AccessInterceptor} from "./workplace/io/components/AccessInterceptor";

registerLocaleData(localeRu);

@NgModule({
    imports: [
        AccountModule,
        AnalysisModule,
        AnswerModule,
        AttachmentDialogModule,
        AttemptModule,
        AttributeModule,
        AttributeRuleEditorModule,
        AudioRecordModule,
        BoilerplateModule,
        BrowserAnimationsModule,
        BrowserModule,
        ChatModule,
        ChangesModule,
        CollectionModule,
        CustomizationScriptModule,
        DatetimePickerModule,
        DenyButtonModule,
        DialogModule,
        DialogStatModule,
        DkbModule,
        DragDropModule,
        EditableListModule,
        EntityUsageModule,
        EruditeFileUploaderModule,
        EruditeNgbDatepickerModule,
        ExpertModule,
        ExtractableValuesModule,
        ExtractorModule,
        ExtractorPropertiesModule,
        ExtractorRegularExpressionModule,
        FileModule,
        FileSaverModule,
        FormControlModule,
        FormsModule,
        FormulationModule,
        HighlightTextModule,
        HttpClientModule,
        JournalsModule,
        LoginsModule,
        LicenseModule,
        MacroModule,
        MassOperationModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        MlModule,
        ModelModule,
        ModelQueryTestModule,
        NgOptionHighlightModule,
        NgSelectModule,
        NgbModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NotificationModule,
        ProcedureModule,
        ProjectModule,
        ProjectVersionModule,
        ReactiveFormsModule,
        ReportsModule,
        ScenariosModule,
        SettingsModule,
        SmaModule,
        TagModule,
        TagBuildingModule,
        TagTreeModule,
        TaggingItemModule,
        TextDataModule,
        TextToSpeechModule,
        TranscriptionModule,
        UIRouterModule.forChild({states: states}),
        UIRouterUpgradeModule,
        UpgradeModule,
        VaAsrModule,
        VersioningProgressBarModule
    ],
    declarations: [
        AqExtractableInfoComponent,
        ConfirmDialogComponent,
        DefaultAvatarComponent,
        EdgeConditionComponent,
        EditEruditeElementDialogComponent,
        EntityAccordionComponent,
        ExportScenario,
        ProcedureExitNameDialogComponent,
        RerouteParamsDialogComponent,
        ScriptEditComponent,
        ScriptImportDialogComponent,
        ScriptViewerComponent,
        SnackbarComponent,
        StepLabelSectionComponent
    ],
    entryComponents: [
        AqExtractableInfoComponent,
        ConfirmDialogComponent,
        DefaultAvatarComponent,
        EdgeConditionComponent,
        EditEruditeElementDialogComponent,
        EntityAccordionComponent,
        ExportScenario,
        ProcedureExitNameDialogComponent,
        RerouteParamsDialogComponent,
        ScriptEditComponent,
        ScriptImportDialogComponent,
        ScriptViewerComponent,
        SnackbarComponent,
        StepLabelSectionComponent
    ],
    exports: [
        ScriptImportDialogComponent
    ],
    providers: [
        websocketServiceProvider,
        statisticsServiceProvider,
        accountServiceProvider,
        {provide: HTTP_INTERCEPTORS, useClass: ProjectVersionInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ScriptBuilderResponseExtractor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AccessInterceptor, multi: true},
        {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfigService, registry: SvgIconRegistryService) => () => {
                config.interfaceConfig = AppModule.interfaceConfiguration;
                Object.keys(ICONS).forEach(key => registry.addSvg(key, ICONS[key]))
            },
            deps: [AppConfigService, SvgIconRegistryService],
            multi: true
        },
        FileUploadService
    ]
})
export class AppModule {

    private static appName: string;
    private static doc: Element;
    private static interfaceConfiguration: any;

    constructor(private upgrade: UpgradeModule) {
    }

    public static start(appName: string, doc: Element, interfaceConfiguration: any) {
        AppModule.appName = appName;
        AppModule.doc = doc;
        AppModule.interfaceConfiguration = interfaceConfiguration;

        platformBrowserDynamic().bootstrapModule(AppModule)
            .then(platformRef => {
                // Initialize the Angular Module
                // get() the UIRouter instance from DI to initialize the router
                const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

                // Instruct UIRouter to listen to URL changes
                function startUIRouter() {
                    urlService.listen();
                    urlService.sync();
                }

                platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
            });
    }


    ngDoBootstrap() {
        this.upgrade.bootstrap(AppModule.doc, [AppModule.appName], {strictDi: true});
    }

}

export default AppModule;
