import {ServerCollectionFilter} from "./server-collection.component";
import {EventEmitter} from "@angular/core";

/**
 * Хелпер серверной коллекции для ее использования в parent-компоненте.
 * 
 * ITEM - тип сущности.
 * FILTER - тип фильтра коллекции.
 */
export class ServerCollectionBridge<ITEM, FILTER extends ServerCollectionFilter> {

    /**
     * Фильтр серверной коллекции
     */
    private _filter: FILTER;

    /**
     * Эмиттер, передающий изменения фильтра серверной коллекции в коллекцию
     */
    readonly filterUpdate: EventEmitter<FILTER> = new EventEmitter<FILTER>();

    /**
     * Эмиттер, передающий измененный объект в коллекцию при апдейте сущностей через инпут (selected) снаружи
     */
    readonly itemUpdate: EventEmitter<ITEM> = new EventEmitter<ITEM>();

    /**
     * Количество элементов в коллекции
     */
    itemsCount: number;

    /**
     * Идентификаторы выбранных элементов (полем selected)
     */
    readonly selectedIds: Set<any> = new Set<any>();

    /**
     * Эмиттер передачи в коллекцию сигналов "выбрать все" (true) / "сбросить выделение" (false)
     */
    readonly selectAllNone: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * @param _initialFilter начальный фильтр
     * @param itemConstructor конструктор элемента из объекта, пришедшего с бэка или null, если исходный объект сойдет
     */
    constructor(private readonly _initialFilter: FILTER, readonly itemConstructor: (any) => ITEM = null) {
        this._filter = this.initialFilter;
    }

    /**
     * Начальный фильтр с копированием, чтобы не изменить исходный объект через тот, в который он был бы присвоен
     */
    get initialFilter(): FILTER {
        return Object.assign({}, this._initialFilter);
    }

    get filter(): FILTER {
        return this._filter;
    }

    onFilterChange(): void {
        this.filterUpdate.emit(this._filter);
    }

    resetFilter() {
        this._filter = this.initialFilter;
        this.onFilterChange();
    }
}