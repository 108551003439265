import {NgModule} from "@angular/core";
import {AnalysisTableComponent} from "./analysis-table.component";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        MatTableModule,
        MatSortModule,
        CommonModule
    ],
    declarations: [
        AnalysisTableComponent
    ],
    exports: [
        AnalysisTableComponent
    ],
    entryComponents: [
        AnalysisTableComponent
    ]
})
export class AnalysisModule {
}