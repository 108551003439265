import {Component, EventEmitter} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {BaseMasterComponent} from "../../base/base-master.component";
import {AccountService} from "../account.service";
import {AccountFilter, AccountRole, ChatAccountData} from "../../../../../data/va/Account";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'account-master',
    template: require('./account-master.component.html'),
    styles: [require('./account-master.component.less')]
})
export class AccountMasterComponent extends BaseMasterComponent<ChatAccountData, number> {
    clearSearchStringChange: EventEmitter<any> = new EventEmitter<any>();
    roles: AccountRole[];
    robotProjects: string[];
    filter: AccountFilter = new AccountFilter();

    constructor(titleService: Title,
                stateService: StateService,
                protected dataService: AccountService,
                notificationService: NotificationService,
                httpClient: HttpClient) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.getAccess();
    }

    async ngOnInit() {
        await super.ngOnInit();
        this.robotProjects = await this.dataService.getRobotProjects();
        this.roles = await this.dataService.getRoles();
        this.setLoading(false);
    }

    clearFilters() {
        this.clearSearchStringChange.next();
        this.filter = new AccountFilter();
    }

    filterChange(searchString: string, item: ChatAccountData, filter: AccountFilter): boolean {
        // фильтр по флагу blocked
        item.account.visible = filter.isBlocked ? item.account.blocked : true;
        // фильтр по роли
        item.account.visible = filter.role ?
            item.account.visible && item.account.role.id == filter.role.id : item.account.visible;
        // фильтр по проектам
        if (filter.project) {
            for (let entry of filter.project) {
                if (!item.account.robotProjects.includes(entry)) {
                    return item.account.visible = false;
                }
            }
        }

        item.account.visible = item.account.visible && (searchString ? item.account.fullName.toLowerCase().indexOf(searchString) >= 0 : true);

        return item.account.visible;
    }

    public isUsingFilter(filter: AccountFilter): boolean {
        return filter.isBlocked === true || filter.project != null || filter.role != null;
    }

    updateFilter(value: any, key: string) {
        this.filter[key] = value;
        this.filter = Object.assign({}, this.filter);
    }
}
