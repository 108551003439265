export enum VAActEnum {
    WELCOME = "WELCOME",
    SOC_CHAT = "SOC_CHAT",
    KM_ANSWER = "KM_ANSWER",
    KM_SOLUTION = "KM_SOLUTION",
    KM_ATTRIBUTE_QUESTION = "KM_ATTRIBUTE_QUESTION",
    KM_CONFIRM_TAG_CHOOSE = "KM_CONFIRM_TAG_CHOOSE",
    KM_CONFIRM_ATTRIBUTE_EXTRACTION = "KM_CONFIRM_ATTRIBUTE_EXTRACTION",
    KM_REPEAT_QUESTION = "KM_REPEAT_QUESTION",
    TO_TECH_SUPPORT = "TO_TECH_SUPPORT",
    TO_TECH_SUPPORT_AS_TAG_ANSWER = "TO_TECH_SUPPORT_AS_TAG_ANSWER",
    GOODBYE = "GOODBYE",
    ACK_WAIT = "ACK_WAIT",
    ACK_APOLOGY = "ACK_APOLOGY",
    ACK_END = "ACK_END",
    KM_CLARIFICATION = "KM_CLARIFICATION",
    ACK_REPEAT = "ACK_REPEAT",
    ACK_NO_INPUT = "ACK_NO_INPUT",
    ACK_NO_MATCH = "ACK_NO_MATCH",
    KM_CONFIRM_SOLUTION_SUCCESS = "KM_CONFIRM_SOLUTION_SUCCESS",
    BACK_TO_TOPIC = "BACK_TO_TOPIC",
    HELLO_PREFIX = "HELLO_PREFIX",
    INTERNAL_ERROR_MESSAGE = "INTERNAL_ERROR_MESSAGE",
    SORRY_PREFIX = "SORRY_PREFIX",
    BUTTON_SELECT_ADVISE = "BUTTON_SELECT_ADVISE",
    GREETING = "GREETING",
    OPERATORS_ANSWER = "OPERATORS_ANSWER"
}