import {Injectable} from '@angular/core';
import {VAProcedure} from "../../../../data/va/Script";
import {VersionedDataService} from "../base/versioned-data.service";
import {HttpClient} from "@angular/common/http";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {NamedEntitiesModel} from "../model/named-entities.model";


@Injectable({
    providedIn: 'root',
})
export class ProcedureService extends VersionedDataService<VAProcedure, number> {

    baseUrl = '/account/expert/procedure';

    usageEntityType: AppliedEntityType = AppliedEntityType.PROCEDURE;

    readonly entityClass = NamedEntitiesModel.VA_PROCEDURE;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async updateName(object: VAProcedure): Promise<VAProcedure> {
        return this.httpClient.post<VAProcedure>(`${this.baseUrl}/${this.getObjectId(object)}/updateName`, object.name).toPromise();
    }

}
