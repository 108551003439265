export let MODEL_CONFIG = {
    aqext: {
        DATASET: {
            idx: "1",
            name: "Построение train/dev/test datasets",
            params: [
                {},
                {
                    fromComponent: "common",
                    forPhase: "dataset",
                    paramsPrefix: "sets"
                }
            ]
        },
        FC: {
            idx: "2",
            name: "Вычисление feature vectors",
            params: [
                {},
                {
                    fromComponent: "common",
                    forPhase: "dataset",
                    paramsPrefix: "ir",
                    linkVariable: "lexicalFeaturesParams"
                }
            ],
            result: {
                empty: true
            }
        },
        TRAIN: {
            idx: "3",
            name: "Обучение классификатора",
            params: [
                {},
                {
                    fromComponent: "common",
                    forPhase: "dataset",
                    paramsPrefix: "tokenmatch",
                    linkVariable: "tokenMatchParams",
                    f_display: function (p) {
                        return p.extractionMethod === 'MATCHER';
                    }
                },
                {
                    fromComponent: "common",
                    forPhase: "train",
                    paramsPrefix: "neural",
                    linkVariable: "neuralParams",
                    f_display: function (p) {
                        return p.extractionMethod === 'NEURAL_NETS';
                    }
                }
            ],
            result: {
                empty: true
            }
        },
        EVALUATE: {
            idx: "4",
            name: "Оценка результатов классификации на test set-е"
        },
        THRESHOLDS: {
            idx: "5",
            name: "выбор пороговых значений",
            params: [{}],
            test: {
                customQuery: true
            }
        },
    },
    act: {
        DATASET: {
            idx: "1",
            name: "Построение train/dev/test datasets",
            params: [{
                fromComponent: "common",
                forPhase: "dataset",
                paramsPrefix: "sets"
            }, {}]
        },
        FC: {
            idx: "2",
            name: "Вычисление feature vectors",
            params: [{},
                {
                    fromComponent: "common",
                    forPhase: "dataset",
                    paramsPrefix: "ir",
                    linkVariable: "lexicalFeaturesParams",
                    f_display: function (p) {
                        return p.learningMethod == 'FAST_TEXT';
                    }
                },
                {
                    fromComponent: "common",
                    forPhase: "dataset",
                    paramsPrefix: "lexical",
                    linkVariable: "lexicalFeaturesParams",
                    f_display: function (p) {
                        return p.learningMethod == 'THEANO_LOG_REGRESSION' || p.learningMethod == 'NEURAL_NETS';
                    }
                },
                {
                    fromComponent: "common",
                    forPhase: "fc",
                    paramsPrefix: "embeddings_fc",
                    linkVariable: "embeddingsParams",
                    f_display: function (p) {
                        return p.learningMethod == 'NEURAL_NETS';
                    }
                }, {
                    fromComponent: "common",
                    forPhase: "fc",
                    paramsPrefix: "ensemble_fc",
                    linkVariable: "ensembleParams",
                    f_display: function (p) {
                        return p.learningMethod == 'NEURAL_NETS';
                    }
                }],
            result: {
                empty: true
            }
        },
        TRAIN: {
            idx: "3",
            name: "Обучение классификатора",
            params: [{
                fromComponent: "common",
                forPhase: "train",
                paramsPrefix: "theano_lr",
                linkVariable: "theanoLogRegressionParams",
                f_display: function (p) {
                    return p.lm == 'THEANO_LOG_REGRESSION';
                }
            }, {
                fromComponent: "common",
                forPhase: "train",
                paramsPrefix: "fasttext",
                linkVariable: "fastTextParams",
                f_display: function (p) {
                    return p.lm == 'FAST_TEXT';
                }
            }, {
                fromComponent: "common",
                forPhase: "train",
                paramsPrefix: "nn",
                linkVariable: "neuralNetsParams",
                f_display: function (p) {
                    return p.lm == 'NEURAL_NETS';
                }
            }],
            result: {
                empty: true
            }
        },
        EVALUATE: {
            idx: "4",
            name: "Оценка результатов классификации на dev и test set-ах",
            test: {}
        },
        THRESHOLDS: {
            idx: "5",
            name: "Выбор порогов",
            params: [{
                fromComponent: "common",
                forPhase: "thresholds",
                paramsPrefix: "thresholds"
            }],
        }
    },
    tag: {
        DATASET: {
            idx: "1",
            name: "Построение train и test datasets",
            params: [{}, {
                fromComponent: "common",
                forPhase: "dataset",
                paramsPrefix: "sets"
            }]
        },
        FC: {
            idx: "2",
            name: "Вычисление feature vectors",
            params: [{}, {
                fromComponent: "common",
                forPhase: "dataset",
                paramsPrefix: "ir",
                linkVariable: "irModelParams",
                f_display: function (p) {
                    return p.learningMethod === 'FAST_TEXT' || p.learningMethod === 'NEURAL_NETS';
                }
            },
                {
                    fromComponent: "common",
                    forPhase: "fc",
                    paramsPrefix: "embeddings_fc",
                    linkVariable: "embeddingsParams",
                    f_display: function (p) {
                        return p.learningMethod === 'NEURAL_NETS';
                    }
                }],
            result: {
                empty: true
            }
        },
        INDEX: {
            idx: "3",
            name: "Построение индекса сообщений для автодополнения",
            result: {
                empty: true
            }
        },
        TRAIN: {
            idx: "4",
            name: "Обучение классификатора",
            params: [{
                fromComponent: "common",
                forPhase: "train",
                paramsPrefix: "fasttext",
                linkVariable: "fastTextParams",
                f_display: function (p) {
                    return p.lm === 'FAST_TEXT';
                }
            }, {
                fromComponent: "common",
                forPhase: "train",
                paramsPrefix: "embeddings",
                linkVariable: "embeddingsParams",
                f_display: function (p) {
                    return p.lm === 'NEURAL_NETS';
                }
            }],
            result: {
                empty: true
            }
        },
        EVALUATE: {
            idx: "5",
            name: "Настройка и оценка результатов классификации на test set-е",
            test: {}
        },
        THRESHOLDS: {
            idx: "7",
            name: "Выбор пороговых значений",
            params: [{}]
        }
    },
    step: {
        DATASET: {
            idx: "1",
            name: "Построение train и test datasets",
            params: [{}]
        }
    },
    plainext: {
        DATASET: {
            idx: "1",
            name: "Построение train и test datasets и вычисление feature vectors",
            params: [
                {
                    fromComponent: "common",
                    forPhase: "dataset",
                    paramsPrefix: "sets"
                },
                {
                    fromComponent: "common",
                    forPhase: "dataset",
                    paramsPrefix: "ir",
                    linkVariable: "lexicalFeaturesParams"
                },
                {
                    fromComponent: "common",
                    forPhase: "dataset",
                    paramsPrefix: "tokenmatch",
                    linkVariable: "tokenMatchParams"
                },
            ]
        },
        EVALUATE: {
            idx: "2",
            name: "Оценка результатов классификации на test set-е",
            test: {}
        }
    }
};