import "../../backports"

export default function TaggingItemDirective() {
    return {
        restrict: 'E',
        controllerAs: "taggingctrl",
        transclude: true,
        templateUrl: "/pages/workplace/components/taggingItem.html",
        scope: {
            selectedTags: "="
        },
        bindToController: true,
        controller: ["$scope", "$uibModal", TaggingItemDirectiveController]
    };
}

class TaggingItemDirectiveController {
    private $uibModal: any;
    private $scope: angular.IScope;
    private selectedTags: any[];

    constructor($scope: angular.IScope, $uibModal: any) {
        this.$scope = $scope;
        this.$uibModal = $uibModal;
        if (!this.selectedTags) {
            this.selectedTags = [];
        }
    }

    openTaggingItemModalHandler(selectedTags) {
        this.$uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: '/pages/workplace/modal/taggingItemModal.html',
            controller: 'VaTaggingItemModalController',
            controllerAs: '$ctrl',
            size: 'lg',
            appendTo: angular.element(document).find("body"),
            resolve: {
                onTagSelected: function () {
                    return function (tag) {
                        selectedTags.push(tag);
                        return tag.text;
                    };
                }
            }
        });
    };

}