import {Component, Inject} from "@angular/core";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
    selector: 'transcription-export-bottom-sheet',
    template: require('./transcription-export-bottom-sheet.component.html')
})
export class TranscriptionExportBottomSheetComponent {

    /**
     * Пункты меню
     */
    readonly MENU: TranscriptionExportConfig[];

    constructor(private bottomSheetRef: MatBottomSheetRef<TranscriptionExportBottomSheetComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: { search: boolean },
                private bottomSheet: MatBottomSheet) {
        // отфильтровываем доступные пункты меню в зависимости от наличия текста в строке поиска
        this.MENU = [
            {checked: true},
            {},
            {search: true, checked: true},
            {search: true, checked: true, following: true},
            {search: true},
            {search: true, following: true},
        ].filter(item => data.search == !!item.search);
    }

    /**
     * Кликнули на пункт меню
     *
     * @param exportConfig выбранный пункт
     */
    onSelectOption(exportConfig: TranscriptionExportConfig): void {
        // передаем выбранное наружу
        this.bottomSheetRef.dismiss(exportConfig);
    }
}

/**
 * Пункт меню с определенными параметрами экспорта
 */
export interface TranscriptionExportConfig {

    /**
     * Пункт доступен при заданном поисковом запросе / когда поисковая строка пустая
     */
    search?: boolean,

    /**
     * Выгружать реплики идущее ЗА найденной репликой в диалоге / найденные
     */
    following?: boolean;

    /**
     * Выгружать только проверенные реплики / все
     */
    checked?: boolean,
}