import {Component, Input, OnInit} from "@angular/core";
import {Form} from "../va/base/base-details.component";
import {Extractor} from "../../../data/va/Extractor";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ExtractorService} from "../va/extractor/extractor.service";

@Component({
    selector: 'extractor-regular-expression',
    template: require('./extractor-regular-expression.component.html'),
    styles: [require('./extractor-regular-expression.component.less')]
})
export class ExtractorRegularExpressionComponent implements OnInit {

    private static REPLACEMENT: string = `<span class="extractor-regexp-match">*</span>`;

    @Input()
    form: Form<Extractor>;

    @Input()
    access: boolean;

    private dataChange = new Subject<string>();

    testQuery: string = "";

    innerHtml: string = "";

    constructor(private httpClient: HttpClient, private extractorService: ExtractorService) {

    }

    ngOnInit(): void {
        this.dataChange.pipe(debounceTime(200), distinctUntilChanged())
            .subscribe(() => this.testRegularExpression());
    }

    onTestQueryChange() {
        this.dataChange.next(this.testQuery);
    }

    onRegExpChange() {
        this.dataChange.next(this.form.object.regExp);
    }

    private async testRegularExpression() {
        // если тестового запроса нет, то пустая строка
        if (this.testQuery?.length == 0 || this.form?.object?.regExp?.length == 0) {
            // если тестовый запрос есть, но нет регулярки - просто отобразим текст
            this.innerHtml = this.testQuery?.length > 0 ? this.testQuery : "";
            return;
        }
        const result = await this.extractorService.testRegExp(this.testQuery, this.form.object.regExp, ExtractorRegularExpressionComponent.REPLACEMENT);
        this.innerHtml = result.left
    }

    onTestQueryCollapse() {
        this.testQuery = "";
        this.innerHtml = "";
    }
}
