import {Component, Inject, ViewEncapsulation} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material";

@Component({
    selector: 'snack-bar',
    template: require('./snackbar.component.html'),
    encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

    }

}