import {NgModule} from "@angular/core";
import {BoilerplateModule} from "../base/boilerplate.module";
import {CollectionModule} from "../../common/collection/collection.module";
import {UIRouterModule} from "@uirouter/angular";
import {CommonModule} from "@angular/common";
import {ExpertModule} from "../../common/expert/expert.module";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormControlModule} from "../../common/form/form-control.module";
import {EruditeFileUploaderModule} from "../../common/file-uploader/erudite-file-uploader.module";
import {FileSaverModule} from "ngx-filesaver";
import {ScriptEditComponent} from "../../script-builder/script-edit.component";
import {ScenarioMasterComponent} from "./scenario-master.component";

@NgModule({
    declarations: [
        ScenarioMasterComponent
    ],
    imports: [
        BoilerplateModule,
        CollectionModule,
        UIRouterModule,
        CommonModule,
        ExpertModule,
        MatDialogModule,
        MatProgressBarModule,
        FormControlModule,
        EruditeFileUploaderModule,
        FileSaverModule,

    ],
    exports: [
        ScenarioMasterComponent
    ]
})
export class ScenariosModule {
    static states = [
        {
            name: "robot.script",
            url: "/script",
            views: {
                'content@authorized': {
                    component: ScenarioMasterComponent
                }
            },
            data: {
                title: "Сценарии",
                requiredProjectVersion: true,
                meta: {
                    parameters: [],
                    sectionFunction: ([]) => "Обучение робота",
                    subSectionFunction: ([]) => "Сценарии"
                },
            },
        },
        {
            name: 'robot.script_edit',
            url: "/robot.script_edit/:type/:scriptId?tagId&nodeId&caseScenarioId&caseId",
            views: {
                'content@authorized': {
                    component: ScriptEditComponent
                }
            },
            data: {
                title: "Сценарий",
                newObjectTitle: "Новый сценарий",
                successfulSaveMessage: "Сценарий сохранен",
                meta: {
                    parameters: ["scriptId"],
                    sectionFunction: ([scriptId]) => "Обучение робота",
                    subSectionFunction: ([scriptId]) => scriptId ? "Редактирование сценария" : "Новый сценарий"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }

    ]
}