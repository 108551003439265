import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

export class LicenseGraphFilterModel {
    fromDate: NgbDate;
    toDate: NgbDate;

    constructor() {
    }
}

export class CheckState {
    title: string;
    checked: boolean;
}