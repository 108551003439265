import {CompositeKey} from "./Common";
import {VaTag} from "./Tag";
import {Scope} from "./Attribute";
import {Extractor, ValueType} from "./Extractor";
import {Formulation} from "./Formulation";
import {VersionedEntity} from "../../io/components/va/base/versioned-data.service";
import {VaScriptCondition} from "./VaScriptCondition";

export class VAScript implements VersionedEntity<number> {
    key: CompositeKey<number>;
    name: string;
    deleted: boolean;
    expertId: number;
    d: Date;
    expert: any;
    nodes: VaScriptNode[];
    edges: VaScriptEdge[];
    autoLayoutApplied?: boolean;
    hasToTag?: boolean;
    incline: boolean
    caseView?: CaseView;
    isProcedure?: boolean
}

export class VAScenario extends VAScript {
    tagId: number;
    tag: VaTag;
}

export class VAProcedure extends VAScript {
    scope?: Scope;
    taggedInfo?: VaTag[];
    exitNodes?: VaScriptNode[];
    enterId?: any;
}

export class VaScriptNode {
    key?: CompositeKey<string>;
    stepLabel: StepLabel;
    checkpoint?: boolean;
    scriptId?: number;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    // Фейковые поля, которые существуют только у объектов Node из vis.Network
    id?: any;
    label?: string;
    color?: {
        color: string;
        background: string;
        highlight: string;
    };
    borderWidth?: number;
    text?: string;
    entity?: StepLabelEntity;
}

export class VaScriptEdge {
    key: CompositeKey<string>;
    scriptId: number;
    conditions: VaScriptCondition[];
    fromNodeId: string;
    toNodeId: string;
    checkpoint?: boolean;
    vertices?: string;
}

export class StepLabel {
    type?: StepLabelType;
    entityId?: number;
    subId?: number;
    name?: string;
    commandParams?: string;
    commandParamsText?: string;
    value?: string;
    followingExtraction?: boolean;
}

export class StepLabelType {
    name: StepLabelTypeName;
    title?: string;
}

/**
 * Все возможные name типов лейблов
 */
export enum StepLabelTypeName {
    ATTRIBUTE_REQUEST = 'ATTRIBUTE_REQUEST',
    ANSWER_RESPONSE = 'ANSWER_RESPONSE',
    TO_SUPPORT = 'TO_SUPPORT',
    TO_TAG = 'TO_TAG',
    START = 'START',
    ENTER = 'ENTER',
    EXIT = 'EXIT',
    PROCEDURE = 'PROCEDURE'
}


export class StepLabelEntity {
    // --- Поля с бэка ---
    key: CompositeKey<string>;
    type: StepLabelType;
    title: string;
    options: any[];
    formulations: Formulation[];
    valueType: ValueType;
    taggedInIds: number[];
    extractor?: Extractor;
    // --- Поля, которые считаются на фронте ---
    dragPreviewContent?: string;
    dragPreviewStyle?: string;
    tagScenarioId?: number;
    tagId?: number;

    constructor() {
    }
}

/**
 * Данные по генерации тест-кейсов для проверки покрытия и просмотра конкретных путей
 */
export class CaseView {
    paths: any;
    stuckPaths: any;
    visitedEdges: string[];
    stuckEdges: string[];
    currentNodeId?: string;
    info?: any;
    scenarioId: number;
}
