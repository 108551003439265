import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {FormControlModule} from "../common/form/form-control.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {DialogStatComponent} from "./dialog-stat.component";
import {DialogRelevanceComponent} from "./dialog-relevance/dialog-relevance.component";
import {DialogModule} from "../dialog/dialog.module";


@NgModule({
    declarations: [
        DialogStatComponent,
        DialogRelevanceComponent
    ],
    imports: [
        CommonModule,
        UIRouterModule,
        FormControlModule,
        NgSelectModule,
        FormsModule,
        DialogModule
    ],
    exports: [
        DialogStatComponent,
        DialogRelevanceComponent
    ],
    entryComponents: [
        DialogStatComponent,
        DialogRelevanceComponent
    ],
    providers: []
})

export class DialogStatModule {

    static states = [
        {
            name: "robot.dialog",
            url: "/dialog/:dialogId?position",
            views: {
                'content@authorized': {
                    component: DialogStatComponent
                }
            },
            data: {
                title: "Диалог",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            },
        }, {
            name: "robot.analysis_dialog",
            url: "/dialog/:dialogId?position",
            views: {
                'content@authorized': {
                    component: DialogStatComponent
                }
            },
            data: {
                title: "Диалог",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            },
        }
    ];

}
