import {StepLabelEntity, StepLabelType, StepLabelTypeName, VAScript, VaScriptEdge, VaScriptNode} from "../../../data/va/Script";
import {dia, shapes, util} from '@naumen/rappid';
import {getLinkColor, getNodeSize, getNodeText, isRootNode} from "./ScriptBuilderUtils";
import EruditeLink from "./link-view/EruditeLink";
import CheckpointController from "./controller/CheckpointController";
import FormulationSwitchController from "./controller/FormulationSwitchController";
import ValidationController from "./controller/ValidationController";
import EntityNavigationController from "./controller/EntityNavigationController";
import {StateService, TransitionService} from "@uirouter/core";
import {EventEmitter} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material";
import DialogController from "./controller/DialogController";
import {StartElement} from "./element-view/circle/StartElement";
import {EnterElement} from "./element-view/circle/EnterElement";
import {AnswerElement} from "./element-view/rectangle/AnswerElement";
import {AttributeElement} from "./element-view/rectangle/AttributeElement";
import {RerouteElement} from "./element-view/rectangle/RerouteElement";
import {TagElement} from "./element-view/rectangle/TagElement";
import {ExitElement} from "./element-view/rectangle/ExitElement";
import {ProcedureElement} from "./element-view/rectangle/ProcedureElement";
import {EruditeElement} from "./element-view/EruditeElement";
import RectangleElement from "./element-view/rectangle/RectangleElement";
import {EdgeConditionComponent} from "./edge-condition/edge-condition.component";
import ScriptChangesController from "./controller/ScriptChangesController";
import {ScriptViewerComponent} from "./script-viewer.component";
import {saveElementObjectToAttributes, saveLinkObjectToAttributes} from "./controller/ScriptAttributeUtils";
import CopyPasteController from "./controller/CopyPasteController";
import {CheckpointableElement} from "./element-view/interface/CheckpointableElement";
import {FormulableElement} from "./element-view/interface/FormulableElement";
import {VaScriptCondition} from "../../../data/va/VaScriptCondition";
import Point = dia.Point;
import Link = dia.Link;
import Size = dia.Size;
import CellView = dia.CellView;


export default class JointAdapter {

    linkHandler: (link: dia.Link) => void;

    nodeHandler: (node: dia.Element) => void;

    private graph = new dia.Graph();

    private root: EruditeElement;

    private validationController: ValidationController;

    public entityNavigationController: EntityNavigationController;

    public dialogController: DialogController;

    public checkpointController: CheckpointController;

    private formulationController: FormulationSwitchController;

    public changesController: ScriptChangesController;

    public copyPasteController: CopyPasteController;

    isProcedure: boolean;

    constructor(public script: VAScript, private stateService: StateService, private onDisplaySnackBar: EventEmitter<{ message: string, styleClass: string, duration?: number }>, private http: HttpClient, private dialog: MatDialog, private entities: { type: StepLabelType, entities: StepLabelEntity[] }[], private onDismissSnackBar: EventEmitter<void>, transitionService: TransitionService, public component: ScriptViewerComponent) {
        this.component.caseId = this.stateService.params['caseId'];
        this.entityNavigationController = new EntityNavigationController(stateService, this);
        this.changesController = new ScriptChangesController(this, transitionService, component);
        this.dialogController = new DialogController(this.dialog, this.changesController);
        this.validationController = new ValidationController(http, this.onDisplaySnackBar, this.onDismissSnackBar, this.changesController);
        this.checkpointController = new CheckpointController(this);
        this.formulationController = new FormulationSwitchController(this.changesController);
        this.isProcedure = this.stateService.params['type'] == 'procedure';
    }

    public initTools() {
        this.copyPasteController = new CopyPasteController(this);
    }

    getGraph(): dia.Graph {
        return this.graph;
    }

    get rootElement() {
        return this.root;
    }

    createNode(entity: StepLabelEntity, position: Point, size: Size): EruditeElement {
        // Создаём узел сценария
        const node: VaScriptNode = {
            key: {id: util.uuid(), projectVersionId: entity.key.projectVersionId},
            stepLabel: {
                type: entity.type,
                entityId: entity.key.id as any,
                subId: entity.formulations && entity.formulations.length > 0 ? entity.formulations[0].key.id : null
            },
            x: position.x - size.width / 2,
            y: position.y,
            width: size.width,
            height: size.height,
            checkpoint: false,
            text: entity.dragPreviewContent?.trim(),
            entity: entity,
        };
        // Превращаем его в joint-элемент
        const jointNode = this.createEruditeElement(node);
        // Цепляем к shape'у обработчик
        this.nodeHandler(jointNode);
        // Добавляем shape к графу
        jointNode.addTo(this.graph);
        return jointNode;
    }

    adapt() {
        const nodes = this.script.nodes;
        const edges = this.script.edges;

        let nodeMap: NodeMap = {};

        nodes.forEach(node => this.adaptNode(node, nodeMap));

        if (edges) {
            edges.forEach(edge => this.adaptEdge(edge, nodeMap));
        }
    }

    private adaptNode(node, nodeMap: NodeMap) {
        if (node.entity?.formulations && node.entity?.formulations.length == 1 && !node.stepLabel.subId) {
            // если у сущности появилась формулировка после последнего редактирования сценария, явно выбираем ее, по аналогии с созданием узла
            node.stepLabel.subId = node.entity.formulations[0].key.id;
        }

        // подставляем в формулировки текст вместо макросов
        node.text = getNodeText(node);
        // Если у узла не задана ширина / высота, то надо рассчитать по тексту внутри
        if (!node.width || !node.height) {
            const size: Size = getNodeSize(node.text, isRootNode(node));
            node.width = size.width;
            node.height = size.height;
        }
        // ScriptNode в Joint Shape
        const jointNode = this.createEruditeElement(node);
        // Добавить к графу и повесить обработчик
        jointNode.addTo(this.graph);
        this.nodeHandler(jointNode);

        nodeMap[node.key.id] = {
            joint: jointNode,
            node: node
        };
        if (isRootNode(node)) {
            this.root = jointNode;
        }
    }

    private adaptEdge(edge, nodeMap: NodeMap) {
        // ScriptEdge в JointShape
        // Достаём узлы на концах link'a
        let {joint: source}: { node: VaScriptNode; joint: EruditeElement } = nodeMap[edge.fromNodeId];
        let {joint: target} = nodeMap[edge.toNodeId];
        // Прикрепляем узел "от"
        const link: shapes.standard.Link = this.createEruditeLink(edge, source, target);
        // Добавляем к графу, вешаем обработчик
        link.addTo(this.graph);
        this.linkHandler(link);
    }

    /**
     * Новый элемент сброшен на линк, "вставляем" его в линк
     */
    public dropOnLink(newElement: EruditeElement, link: EruditeLink) {
        this.changesController.initBatchChange();
        link.deleteVertices();
        this.createNewLink(newElement, link.targetElement);
        link.targetElement = newElement;
        this.changesController.storeBatchChange();
    }

    public createEruditeLink(edge: VaScriptEdge, source: EruditeElement, target: EruditeElement): EruditeLink {
        const link = new EruditeLink({
            edge: edge,
            sourceNode: source.node,
            incline: this.script.incline,
            caseView: this.script.caseView,
            caseId: this.component.caseId
        });
        link.onCreate = viewModel => this.validationController.onLinkCreated(viewModel);
        link.onCheckpoint = viewModel => this.checkpointController.onEdgeCheckpointClicked(viewModel);
        link.onConditionModifyFinish = viewModel => this.validationController.onConditionUpdate(viewModel, () => this.dialog.closeAll());
        link.onConditionModify = viewModel => this.dialogController.openEdgeConditionsDialog(viewModel, this.entities);
        link.on('change:target', (eruditeLink: EruditeLink, target: any) => {
            if (!target.id || !eruditeLink.targetElement) {
                return;
            }
            // у прошлого таргета выключаем подсветку
            eruditeLink.targetElement.switchHighlight(false);
            eruditeLink.targetElement = eruditeLink.collection
                .filter(element => !(element instanceof EruditeLink))
                .find((element: EruditeElement) => target.id === element.node.key.id);
        });

        saveLinkObjectToAttributes(link);

        link.sourceElement = source;
        link.targetElement = target;

        return link;
    }

    /**
     * Получить текущий сценарий из графа
     */
    public getCurrentScript(): VAScript {
        // Достаём shape'ы и link'и
        const elements: EruditeElement[] = this.graph.getElements() as EruditeElement[];
        const links: EruditeLink[] = this.graph.getLinks() as EruditeLink[];
        // Превращаем их в сущности для Erudite
        const nodes: VaScriptNode[] = elements.map(element => JointAdapter.toScriptNode(element));
        const edges: VaScriptEdge[] = links.map(link => JointAdapter.toScriptEdge(link));
        // Проставляем в сценарий и вовращаем
        this.script.nodes = nodes;
        this.script.edges = edges;
        return this.script;
    }

    /**
     * Преобразовать shape в VaScriptNode
     * @param element объект графа
     */
    static toScriptNode(element: EruditeElement): VaScriptNode {
        // Достаём атрибуты и ноду
        const attributes = element.attributes;
        const node: VaScriptNode = element.node;
        // Проставляем значения, которыми управляет joint
        node.x = attributes.position.x;
        node.y = attributes.position.y;
        node.width = attributes.size.width;
        node.height = attributes.size.height;
        return node;
    }

    /**
     * Преобразовать link в VaScriptEdge
     * @param link стрелка на графе
     */
    private static toScriptEdge(link: EruditeLink): VaScriptEdge {
        // Edge из линка
        const edge: VaScriptEdge = link.scriptEdge;
        // Изгибы линка
        edge.vertices = link.serializedVertices;
        return edge;
    }

    public createEruditeElement(node: VaScriptNode): EruditeElement {
        let eruditeElement: EruditeElement;

        const attributes = {node: node};
        switch (node.stepLabel.type.name) {
            case StepLabelTypeName.ATTRIBUTE_REQUEST:
                eruditeElement = new AttributeElement(attributes);
                break;
            case StepLabelTypeName.ANSWER_RESPONSE:
                eruditeElement = new AnswerElement(attributes);
                break;
            case StepLabelTypeName.TO_SUPPORT:
                eruditeElement = new RerouteElement(attributes);
                break;
            case StepLabelTypeName.TO_TAG:
                eruditeElement = new TagElement(attributes);
                break;
            case StepLabelTypeName.START:
                eruditeElement = new StartElement(attributes);
                break;
            case StepLabelTypeName.ENTER:
                eruditeElement = new EnterElement(attributes);
                break;
            case StepLabelTypeName.EXIT:
                eruditeElement = new ExitElement(attributes);
                break;
            case StepLabelTypeName.PROCEDURE:
                eruditeElement = new ProcedureElement(attributes);
                break;
        }
        this.setElementCallbacks(eruditeElement);
        saveElementObjectToAttributes(eruditeElement);
        return eruditeElement;
    }

    /**
     * Проставить callback'и в элементы
     * @param eruditeElement элемент
     */
    public setElementCallbacks(eruditeElement: EruditeElement) {
        // Если элементу можно проставить чекпоинт - получаем callback из контроллера
        if (eruditeElement.isCheckpointable) {
            (eruditeElement as CheckpointableElement).onCheckpoint = viewModel => this.checkpointController.onNodeCheckpointClicked(viewModel);
        }
        // Если у элемента можно открыть диалог
        if (eruditeElement.isDialogable) {
            (eruditeElement as RectangleElement).onDialogOpen = viewModel => this.dialogController.openDialog(viewModel);
        }
        // Если у элемента можно переключить формулировку
        if (eruditeElement.isFormulable) {
            (eruditeElement as FormulableElement).onNextFormulation = viewModel => this.formulationController.onNextFormulation(viewModel);
            (eruditeElement as FormulableElement).onPreviousFormulation = viewModel => this.formulationController.onPreviousFormulation(viewModel);
        }
        // Контекстное меню
        if (eruditeElement instanceof RectangleElement) {
            eruditeElement.onEntityOpen = viewModel => this.entityNavigationController.navigateTo(viewModel);
        }
        // Открытие сценария
        if (eruditeElement instanceof TagElement) {
            eruditeElement.onScenarioOpen = scenarioId => this.entityNavigationController.navigateToScenario(scenarioId);
        }
        // Удаление элемента
        if (eruditeElement.isDeletable()) {
            eruditeElement.onElementRemove = () => this.validationController.onNodeDelete(eruditeElement);
        }
        // Пакетные изменения
        if (eruditeElement instanceof RectangleElement) {
            eruditeElement.onInitBatchChange = () => this.changesController.initBatchChange();
            eruditeElement.onStoreBatchChange = () => this.changesController.storeBatchChange();
        }

        // Обработчик создания Link'a
        eruditeElement.onLinkCreate = (link) => this.onLinkCreate(link);
    }

    public onLinkCreate(temporaryLink): void {
        const source: EruditeElement = temporaryLink.getSourceElement();
        const target: EruditeElement = temporaryLink.getTargetElement();
        if (!source || !target) {
            return;
        }
        let newLink = this.createNewLink(source, target, temporaryLink);
        if (source.outgoingEdges.length > 1) {
            this.dialogController.openEdgeConditionsDialog(newLink, this.entities);
        }
        temporaryLink.remove();
    }

    /**
     * Создать новое ребро без условий между заданными узлами по временному линку или без него
     */
    private createNewLink(source: EruditeElement, target: EruditeElement, temporaryLink?: dia.LinkView) {
        let edge: VaScriptEdge = {
            key: {id: util.uuid(), projectVersionId: source.node.key.projectVersionId},
            fromNodeId: source.node.key.id,
            toNodeId: target.node.key.id,
            conditions: [],
            scriptId: source.node.scriptId,
            vertices: temporaryLink ? temporaryLink.vertices() : [],
        };
        if (source.node.stepLabel.type.name == StepLabelTypeName.PROCEDURE && source.node.entity.options.length == 1) {
            let condition = VaScriptCondition.newInstance(source);
            EdgeConditionComponent.filterExitNodes(condition, edge, source);
            if (condition.availableValueEntities.length == 1) {
                // если всего один выход и он ещё не проставлен - добавим его автоматом
                let node = source.node.entity.options[0];
                condition.values = [node.key.id];
                condition.exitNode = node;
                edge.conditions.push(condition);
            }
        }
        let newLink = this.createEruditeLink(edge, source, target);
        if (temporaryLink) {
            newLink.onLinkCreated(temporaryLink);
        }
        if (newLink.isValidLink) {
            newLink.addTo(this.graph);
        }
        return newLink;
    }

    public defaultLink(sourceElementView: CellView): Link {
        const link = new shapes.standard.Link();
        const element: EruditeElement = sourceElementView.model as EruditeElement;
        const type = element.node.stepLabel.type.name;
        link.attr({
            line: {
                stroke: getLinkColor(type, false, false),
                strokeWidth: 4,
                strokeDasharray: '5 5',
                strokeDashoffset: 7.5
            }
        });
        link.prop('sourceElement', element);
        return link;
    }

    /**
     * Найти элемент, отображающий узел
     *
     * @param nodeId id узла
     */
    public findNodeElement(nodeId: string): dia.Element {
        return this.graph.getElements()
            .find(element => (element as EruditeElement).node != undefined && (element as EruditeElement).node.key.id == nodeId);

    }

    /**
     * Перерисовать граф
     */
    redrawGraph() {
        this.graph.resetCells(this.graph.getCells());
    }
}

/**
 * Идентификатор узла -> {узел erudite, узел joint}
 */
interface NodeMap {
    [key: string]: { node: VaScriptNode, joint: EruditeElement };
}
