import {Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {AudioRecordImportDialogComponent} from "../../import/audio-record-import-dialog.component";
import {HttpClient} from "@angular/common/http";
import {FileSaverService} from "ngx-filesaver";
import {extractFileName} from "../../../../../../util/Utils";
import {AudioRecord, AudioRecordFilter} from "../../../../../data/va/AudioRecord";
import {AudioRecordService} from "../audio-record.service";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {BaseMasterComponent} from "../../../va/base/base-master.component";
import {EntityUsageService} from "../../../entity-usage/entity-usage.service";
import WebSocketService from "../../../../../services/WebSocketService";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {getProgressDataPercent, ProgressData, ProgressDataOperation} from "../../../../../data/va/ProgressData";
import {MassOperationButton} from "../../../common/collection/collection.component";
import {MassOperationDialogComponent} from "../../../va/mass-operation/dialog/mass-operation-dialog.component";
import {getUnitCase, UnitCase} from "../../../../../../utils";
import {MassOperation, MassOperationType} from "../../../../../data/va/MassOperation";


@Component({
    selector: 'audio-record',
    template: require('./audio-record-master.component.html'),
    styles: [require('./audio-record-master.component.less')]
})
export class AudioRecordMasterComponent extends BaseMasterComponent<AudioRecord, CompositeKey<number>> implements OnInit, OnDestroy {
    clearSearchStringChange: EventEmitter<any> = new EventEmitter<any>();
    audioUrl: any;
    filter: AudioRecordFilter = new AudioRecordFilter();
    private subscriptionId: string;

    /**
     * Данные для отображения прогресса по обработке предзаписей
     */
    progressData: ProgressData;

    readonly massOperationButtons: MassOperationButton[] = [
        {
            title: "Удалить выбранное",
            class: 'btn btn-plain',
            operation: selectedItems => this.openMassOperationDialog(selectedItems, MassOperationType.DELETE)
        }
    ];

    constructor(titleService: Title,
                stateService: StateService,
                protected dataService: AudioRecordService,
                httpClient: HttpClient,
                notificationService: NotificationService,
                private dialog: MatDialog,
                private fileSaver: FileSaverService,
                public entityUsagesService: EntityUsageService,
                private webSocketService: WebSocketService) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`date`), new SortField(`Текст реплики`, `text`), new SortField(`Название файла`, `fileName`), new SortField(`Где используется`, `entityTitles`));
        // noinspection JSIgnoredPromiseFromCall
        this.getAccess();
        // noinspection JSIgnoredPromiseFromCall
        this.loadProgressData();
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        // подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "AUDIO_RECORD_PROGRESS",
            fn: (event) => this.receiveProgressPush(event)
        });
    }

    /**
     * Получить пуш с данными о прогрессе обработки
     */
    private async receiveProgressPush(event) {
        const importData: ProgressData = JSON.parse(event.details);
        if (importData.projectVersionId !== this.stateService.params["projectVersionId"]) {
            // другая версия
            return;
        }
        await this.showProgressData(importData);
    }

    /**
     * Загрузить данные о прогрессе обработки
     */
    private async loadProgressData() {
        const data = await this.httpClient.get<ProgressData>(`${this.dataService.baseUrl}/loadProgressData`, {}).toPromise();
        await this.showProgressData(data);
    }

    /**
     * Очистить данные о прогрессе обработки
     */
    async clearProgressData() {
        await this.httpClient.get(`${this.dataService.baseUrl}/clearProgressData`, {}).toPromise();
        await this.showProgressData(null);
        this.notificationService.dismiss();
    }

    /**
     * Очистить данные о прогрессе обработки, если зажат ctrl/command
     */
    async clearProgressDataByCtrl(event: MouseEvent) {
        if (event.ctrlKey || event.metaKey) {
            await this.clearProgressData();
        }
    }

    /**
     * Отобразить данные о прогрессе
     */
    private async showProgressData(data: ProgressData) {
        if (!data) {
            this.progressData = data;
            return;
        }
        if (data.warning) {
            // уведомление, если варнинг
            this.notificationService.warning(data.warning, 5000);
        }
        if (this.progressData && !this.progressData.error && data.error) {
            // отображаем ошибку, если она новая
            this.notificationService.error(data.error);
        }
        if (this.progressData && !this.progressData.finished && data.finished) {
            // если обработка завершена
            if (!data.error && !data.warning) {
                // сообщаем об успехе
                this.notificationService.success(this.getResultDescription());
            }
            if (!data.warning) {
                // подгружаем список
                await this.loadList();
            }
        }
        // запоминаем в поле
        this.progressData = data;
    }

    async loadList(): Promise<void> {
        this.setLoading(true);
        this.dataService.findAll().then((records: AudioRecord[]) => {
            records.forEach(record => {
                if (record.usages) {
                    record.entityTitles = record.usages.map(usages => usages.usages.map(usage => usage.title).join(" ")).join(" ");
                }
            });
            this.objects = records;
            this.setLoading(false);
        });
    }

    /**
     * Открыть диалоговое окно импорта реплик и аудиозаписей
     */
    public openImportDialog() {
        this.dialog.open(AudioRecordImportDialogComponent, {
            width: '500px',
        });
    }

    /**
     * Экспортировать реплики в XLS
     */
    public exportReplies() {
        const params = {};
        this.httpClient.post(`${this.dataService.baseUrl}/export`, {}, {
            params: params,
            observe: 'response',
            responseType: 'blob'
        }).subscribe((data) => {
            const fileName = extractFileName(data.headers.get('Content-Disposition'));
            this.fileSaver.save(data.body, fileName)
        })
    }

    /**
     * Сгенерировать аудио
     */
    async generateAll() {
        try {
            await this.httpClient.get(`${this.dataService.baseUrl}/generateAll`, {}).toPromise();
        } finally {
            this.dataService.load()
        }
    }

    /**
     * Функция для фильтрации
     * @param searchString поисковая строка
     * @param item         аудиозапись
     * @param filter       текущий фильтр
     */
    public filterAudioRecord(searchString: string, item: AudioRecord, filter: AudioRecordFilter): boolean {
        item.selected = false;
        const isIntervalMatched = () => {
            if (!item.date) {
                return false;
            }
            const date = (new Date(item.date));
            let itemDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            return (itemDate.after(filter.fromDate) || itemDate.equals(filter.fromDate))
                && (itemDate.before(filter.toDate) || itemDate.equals(filter.toDate));
        };

        const searchMatch = !searchString || item.text.toLowerCase().indexOf(searchString) >= 0 || item?.fileName?.toLowerCase().indexOf(searchString) >= 0 ||
            item?.usages?.some(usage => usage?.usages?.some(u => u?.title?.toLowerCase().indexOf(searchString) >= 0));

        return searchMatch && (filter.fromDate && filter.toDate ? isIntervalMatched() : true)
            && (filter.noAudio ? !item.fileName : true)
            && (filter.noUsage ? item.usages.filter(usages => item.usages && usages.usages.length > 0).length === 0 : true);
    }

    /**
     * Функция для проверки используется ли фильтр
     */
    public isUsingFilter(filter: AudioRecordFilter): boolean {
        return filter.fromDate !== null || filter.toDate !== null || filter.noAudio === true || filter.noUsage === true;
    }

    /**
     * Сбросить текущие фильтры в дефолтное положение
     */
    clearFilters() {
        this.clearSearchStringChange.next();
        this.filter = new AudioRecordFilter();
    }

    updateFilter(value: any, key: string) {
        this.filter[key] = value;
        this.filter = Object.assign({}, this.filter);
    }

    playAudioFile(item: AudioRecord) {
        this.audioUrl = null;
        (this.dataService as AudioRecordService).loadAudioFile(item.fileId, (dataUrl) => this.audioUrl = dataUrl, (error) => this.notificationService.error(error));
    }

    /**
     * Описание процесса
     */
    getProgressDescription() {
        if (!this.progressData?.operation) {
            return '';
        }
        switch (this.progressData.operation) {
            case ProgressDataOperation.AUDIO_IMPORT:
                return 'Аудиозаписи импортируются...';
            case ProgressDataOperation.AUDIO_GENERATION:
                return 'Аудиофайлы генерируются...';
            default:
                throw new Error('unsupported ' + this.progressData.operation);
        }
    }

    /**
     * Описание результата операции
     */
    getResultDescription() {
        if (!this.progressData?.operation) {
            return '';
        }
        switch (this.progressData.operation) {
            case ProgressDataOperation.AUDIO_IMPORT:
                return 'Импорт аудиозаписей завершен';
            case ProgressDataOperation.AUDIO_GENERATION:
                return 'Генерация аудиофайлов завершена';
            default:
                throw new Error('unsupported ' + this.progressData.operation);
        }
    }

    /**
     * Название текущей операции
     */
    getResultDetails() {
        if (!this.progressData?.operation) {
            return '';
        }
        switch (this.progressData.operation) {
            case ProgressDataOperation.AUDIO_IMPORT:
                return 'Импортировано аудиозаписей ';
            case ProgressDataOperation.AUDIO_GENERATION:
                return 'Сгенерировано аудиофайлов ';
            default:
                throw new Error('unsupported ' + this.progressData.operation);
        }
    }

    /**
     * В процессе ли операция
     */
    isInProgress() {
        return this.progressData?.operation && !this.progressData.finished
    }

    /**
     * Есть ли данные для отображения прогресса
     */
    isProgressDataAvailable() {
        return this.progressData?.operation && !this.progressData.warning
    }

    /**
     * @return процент сделанного
     */
    getProgressPercent() {
        return getProgressDataPercent(this.progressData);
    }

    /**
     * Массовое удаление
     */
    async openMassOperationDialog(items: AudioRecord[], type: MassOperationType) {
        // открываем диалог
        const operation = await this.dialog.open(MassOperationDialogComponent, {
            width: '500px',
            data: {
                operation: {ids: items.map(item => item.key.id), type: type}
            }
        }).afterClosed().toPromise<MassOperation>();

        if (!operation) {
            // отмена
            return;
        }
        if (this.stateService.current.name.endsWith('edit')) {
            // выходим из редактирования элемента, если было открыто
            this.stateService.go('^');
        }
        // массовая операция на бэке
        await this.massOperationRequest(operation);

        // перегружаем список элементов
        await this.loadList();
    }

    /**
     * Сделать запрос массовой операции, получить описание результата
     */
    private async massOperationRequest(operation: MassOperation): Promise<void> {
        try {
            await this.dataService.massOperation(operation);

            this.createNotificationMessage(operation.ids.length);
        } catch (e) {
            if (e.errors) {
                if (e.errors?.length != operation.ids.length) {
                    // если были ошибки не во всех, то отобразим сколько смогли удалить
                    let macroError = e.errors.some(error => error.message == 'Нельзя удалить аудиофайл, он используется в макросах');
                    if (macroError) {
                        // если ошибка использования в макросах, то выведем её
                        this.createNotificationMessage(operation.ids.length - e.errors.length, operation.ids.length, 'используется в макросах');
                    } else {
                        this.createNotificationMessage(operation.ids.length - e.errors.length, operation.ids.length);
                    }
                } else {
                    // если ничего не получилось удалить, то отобразим все уникальные ошибки
                    e.errors = e.errors.filter((v, i, a) => a.findIndex(t => (t.exception == v.exception)) === i);
                    e.errors.forEach(error => this.notificationService.error(`Произошла ошибка: ${error.message}`));
                }
            }
        }
    }

    /**
     * Выдать сообщение в notificationService о том, как удалили
     * @param successfullyDeleted количество успешно удалённых
     * @param operationItemsCount сколько всего пытались удалить
     * @param errorMessage текст ошибки при удалении
     * @private
     */
    private createNotificationMessage(successfullyDeleted: number, operationItemsCount?: number, errorMessage?: string) {
        let message;
        if (operationItemsCount) {
            // если пришло общее количество, значит удалили не всё
            message = `<b>Успешно удалено</b><br>${this.getAudioMessageForCount(successfullyDeleted)}<br><b>Не удалось удалить</b><br>` + this.getAudioMessageForCount(operationItemsCount - successfullyDeleted);

            if (errorMessage) {
                // добавим ошибку, если надо
                message += ` (${errorMessage})`;
            }
        } else {
            message = this.getAudioMessageForCount(successfullyDeleted) + ' удалено';
        }
        if (message) {
            if (operationItemsCount && successfullyDeleted != operationItemsCount) {
                // предупреждение, что удалили не всё
                this.notificationService.warning(message);
            } else {
                this.notificationService.success(message);
            }
        }
    }

    getAudioMessageForCount(count: number) {
        switch (getUnitCase(count)) {
            case UnitCase.ONE:
                return `${count} аудиозапись`;
            case UnitCase.TWO_TO_FOUR:
                return `${count} аудиозаписи`;
            case UnitCase.FIVE_AND_MORE:
                return `${count} аудиозаписей`;
            default:
                return null;
        }
    }
}
