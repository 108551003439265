import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {AttemptDTO} from "../../../dialog/model/correction.model";
import {VaAttribute} from "../../../../../data/va/Attribute";
import {VaTag} from "../../../../../data/va/Tag";
import {SpecificMode} from "../../../../../components/va/confirmation/VaConfirmationAttemptEditComponent";
import {ConfirmInfoType} from "../../../dialog/model/extracted-value.model";

@Component({
    selector: 'confirmation-extraction-edit',
    template: require('./confirmation-extraction-edit.component.html'),
    styles: [require('./confirmation-extraction-edit.component.less')],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmationExtractionEditComponent implements OnInit {

    /**
     * Пример извлечения
     */
    @Input()
    private extractionAttempt: AttemptDTO;

    /**
     * Типы подтверждаемых сущностей
     */
    @Input()
    private confirmInfoTypes: ConfirmInfoType[];

    /**
     * Атрибуты
     */
    @Input()
    private attributes: VaAttribute[];

    /**
     * Группы
     */
    @Input()
    private tags: VaTag[];

    /**
     * Нужно ли отображать выбор поля для ввода и выделения текста
     * (true-> нет, используется для корректировок, false -> да)
     */
    @Input()
    private withPredefinedText: boolean;

    /**
     * Режим выбора подтверждаемой сущности
     */
    private mode: SpecificMode = SpecificMode.GENERAL;


    constructor() {
    }

    async ngOnInit(): Promise<void> {

    }

    /**
     * Проверка: выбран ли сейчас режим создания подтверждения "Для определённой сущности"
     */
    get isSpecificConfirmation(): boolean {
        return this.mode === SpecificMode.SPECIFIC;
    }

    /**
     * При изменении режим подветрждения SPECIFIC/GENERAL -> сброс сущностей и т.п
     * @param mode
     */
    private onModeChange(mode: string) {
        this.mode = SpecificMode[mode];
        this.onConfirmationTypeChange();
    }

    /**
     * Очистить значение при смене текста
     */
    private clearValue(): void {
        if (this.extractionAttempt.fullText.length === 0) {
            this.extractionAttempt.value = null;
            this.extractionAttempt.strValue = null;
        }
    }

    /**
     * Действия при выборе типа подтверждения
     */
    private onConfirmationTypeChange(): void {
        // Сбросим выбранное подтверждение, значение и подтверждаемое значение
        this.extractionAttempt.confirmInfoId = null;
        this.extractionAttempt.tag = null;
        this.extractionAttempt.attribute = null;
        this.onEntityChange();
    }

    /**
     * Действия при выборе сущности
     */
    private onEntityChange(): void {
        // При выборе другой сущности сбосим выбранное
        this.extractionAttempt.confirmInfoValue = null;
        this.extractionAttempt.value = null;
        this.extractionAttempt.strValue = null;
    }
}