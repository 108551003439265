
export const NumberVariableOperands: VariableOperand[][] = [
    [{displayName: 'Значение', name: 'X'}]
];


export const DateVars = {
    YEAR: 'year' as 'year',
    MONTH: 'month' as 'month',
    DAY_OF_YEAR: 'day_of_year' as 'day_of_year',
    DAY: 'day' as 'day',
    HOUR: 'hour' as 'hour',
    MINUTE: 'minute' as 'minute',
    SECOND: 'second' as 'second'
};

export type DateVar = (typeof DateVars)[keyof typeof DateVars];


/**
 * Виды аргумента функции дат
 */
export const DateArgumentOperands: VariableOperand[] = [
    {displayName: 'Сейчас', name: "now"},
    {displayName: 'Значение', name: 'X'}
];

/**
 * Виды функции дат
 */
export const DateFunctionOperands: VariableOperand[] = [
    {displayName: 'Год', name: DateVars.YEAR},
    {displayName: 'Месяц', name: DateVars.MONTH},
    {displayName: 'День года', name: DateVars.DAY_OF_YEAR},
    {displayName: 'День месяца', name: DateVars.DAY},
    {displayName: 'Час', name: DateVars.HOUR},
    {displayName: 'Минута', name: DateVars.MINUTE},
    {displayName: 'Секунда', name: DateVars.SECOND}
];

/**
 * Части выражения для дат
 */
export const DateVariableOperands: VariableOperand[][] = [
    DateArgumentOperands,
    DateFunctionOperands
];

export interface VariableOperand {
    name: string;
    displayName: string;
    regex?: RegExp;
}


export const DatetimeVariablesDescription = {
    "X": "значение",
    "X_year": "год",
    "X_month": "месяц",
    "X_day_of_year": "день года",
    "X_day": "день месяца",
    "X_hour": "час",
    "X_minute": "минута",
    "X_second": "секунда"
};

export const NowVariablesDescription = {
    "now": "сейчас",
    "now_year": "год",
    "now_month": "месяц",
    "now_day_of_year": "день года",
    "now_day": "день месяца",
    "now_hour": "час",
    "now_minute": "минута",
    "now_second": "секунда"
};


export const LogicalOperators: Operator[] = [
    {displayName: "или", name: "||", regex: /\|\|/g},
    {displayName: "и", name: "&&", regex: /&&/g}
];

export interface Operator {
    displayName: string;
    name: string;
    regex?: RegExp;
}

export const ComparisonOperators = [
    {displayName: ">", name: '>'},
    {displayName: "<", name: '<'},
    {displayName: "≥", name: '>='},
    {displayName: "≤", name: '<='},
    {displayName: "=", name: "=="},
    {displayName: "≠", name: "!="}
];