import {NgModule} from "@angular/core";
import {AttachmentDialogComponent} from "./attachment-dialog.component";
import {FormControlModule} from "../form/form-control.module";
import {EruditeFileUploaderModule} from "../file-uploader/erudite-file-uploader.module";
import {CommonModule} from "@angular/common";
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
    declarations: [
        AttachmentDialogComponent
    ],
    imports: [
        FormControlModule,
        EruditeFileUploaderModule,
        CommonModule,
        MatDialogModule

    ],
    exports: [
        AttachmentDialogComponent
    ],
    entryComponents: [

    ], providers: [
    ]
})
export class AttachmentDialogModule {

}