import {NgModule} from "@angular/core";
import {ExtractorPropertiesComponent} from "./extractor-properties.component";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {UIRouterModule} from "@uirouter/angular";
import {EditableListModule} from "../editable-list/editable-list.module";

@NgModule({
    declarations: [
        ExtractorPropertiesComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        UIRouterModule,
        EditableListModule
    ],
    exports: [
        ExtractorPropertiesComponent
    ]
})
export class ExtractorPropertiesModule {

}