export default function InlineTreeDirective() {
    return {
        restrict: 'E',
        controllerAs: "$ctrl",
        transclude: true,
        templateUrl: "/pages/workplace/components/tree.html",
        scope: {
            tree: '<',
            treeOptions: '<',
            selectedTagId: '<'
        },
        bindToController: true,
        controller: ["$scope", InlineTreeDirectiveController]
    };
}

class InlineTreeDirectiveController {
    private $scope: ng.IScope;
    private flatTree: Node[];
    private sort: boolean;
    private treeOptions: any;
    private selectedTagId: string;
    private tree: any[];

    constructor($scope: ng.IScope) {
        this.flatTree = [];
        this.$scope = $scope;
        this.sort = false;

        $scope.$watch("$ctrl.tree", () => {
            this.flatTree = this.makeTreeFlat(this.tree, this.treeOptions.hide);
            if (this.selectedTagId) {
                this.expandNode(this.selectedTagId);
            }
        });

        $scope.$watch("$ctrl.treeOptions", () => {
            for (let [key, value] of Object.entries(this.treeOptions)) {
                this[key] = value;
            }
        }, true);

        $scope.$on("tag-tree-tag-change", (event, hide, selectedTagId) => {
            //при удалении тега, не надо закрывать дерево
            this.flatTree = this.makeTreeFlat(this.tree, hide);
            if (selectedTagId) {
                this.selectedTagId = selectedTagId;
            }
            if (this.selectedTagId) {
                this.expandNode(this.selectedTagId);
            }
        });

        $scope.$on("tag-open", (event, tag) => {
            for (let node of this.flatTree) {
                if (node.node === tag) {
                    node.isCollapsed = false;
                }
            }
        });

        $scope.$on("tag-sorted", (event, sort) => {
            this.sort = sort;
        });

    }


    expandNode(selectedNodeId) {
        for (let node of this.flatTree) {
            if (node.node.key.id === selectedNodeId) {
                node.isCollapsed = false;
                if (node.parent) {
                    this.expandNode(node.parent.node.key.id);
                }
            }
        }
    }

    makeTreeFlat(tree: any[], hide: boolean) {
        let flatten: Node[] = tree
            .slice()
            .map(node => new Node(0, node));

        for (let i = 0; i < flatten.length; i++) {
            let item = flatten[i];
            if (hide && item.depth == 1) {
                //можно закрыть дерево
                item.collapse();
            }
            let node = item.node;
            let appendIdx = 1;
            if (!node.children) {
                continue;
            }
            for (let child of node.children) {
                flatten.splice(i + appendIdx, 0,
                    new Node(item.depth + 1, child, item));
                appendIdx++;
            }
        }

        return flatten;
    }

    getterArrayFn() {
        if (!this.sort) {
            return;
        }
        return [
            (tag) => {
                let searchInputValue = (this.$scope.$parent as any).$ctrl.tagFilter;
                return tag.node.text.toLowerCase().indexOf(searchInputValue.toLowerCase());
            },
            (tag) => {
                return tag.node.text;
            }
        ];
    };

}

class Node {

    depth: number;

    node: any;

    isCollapsed = false;

    readonly parent: Node = null;

    constructor(depth: number, node: any);

    constructor(depth: number, node: any, parent: Node);

    constructor(depth: number, node: any, parent?: Node) {
        this.depth = depth;
        this.node = node;
        this.parent = parent;
    }

    collapse() {
        this.isCollapsed = true;
    }

    open() {
        this.isCollapsed = false;
    }

    toggle() {
        this.isCollapsed = !this.isCollapsed;
    }

    isVisible(): boolean {
        let parent = this.parent;
        while (parent != null) {
            if (parent.isCollapsed) {
                return false;
            }
            parent = parent.parent;
        }
        return true;
    }

}