import {NgModule} from "@angular/core";
import {CollectionModule} from "../../common/collection/collection.module";

import {UIRouterModule} from "@uirouter/angular";
import {CommonModule} from "@angular/common";
import {ExpertModule} from "../../common/expert/expert.module";
import {MacroModule} from "../../common/macro/macro.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {FormsModule} from "@angular/forms";
import {VaAsrModule} from "../../common/va-asr/va-asr.module";
import {TaggingItemModule} from "../../common/tagging-item/tagging-item.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {ExtractorMasterComponent} from "./master/extractor-master.component";
import {ExtractorEditComponent} from "./edit/extractor-edit.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {ExtractorPropertiesModule} from "../../common/extractor-properties/extractor-properties.module";
import {ExtractorService} from "./extractor.service";
import {EditableListModule} from "../../common/editable-list/editable-list.module";
import {ExtractorRegularExpressionModule} from "../../extractor-regular-expression/extractor-regular-expression.module";
import {EruditeAudioPlayerModule} from "../../common/player/erudite-audio-player.module";
import {DenyButtonModule} from "../../common/deny-button/deny-button.module";
import {EruditeFileUploaderModule} from "../../common/file-uploader/erudite-file-uploader.module";
import {NgbProgressbarModule} from "@ng-bootstrap/ng-bootstrap";
import {EruditeIconModule} from "../../common/erudite-icon/erudite-icon.module";


@NgModule({
    declarations: [
        ExtractorMasterComponent,
        ExtractorEditComponent
    ],
    imports: [
        CollectionModule,
        UIRouterModule,
        CommonModule,
        ExpertModule,
        MacroModule,
        EntityUsageModule,
        FormControlModule,
        FormsModule,
        NgSelectModule,
        ExtractorPropertiesModule,
        VaAsrModule,
        TaggingItemModule,
        BoilerplateModule,
        EditableListModule,
        ExtractorRegularExpressionModule,
        EruditeAudioPlayerModule,
        DenyButtonModule,
        EruditeFileUploaderModule,
        NgbProgressbarModule,
        EruditeIconModule
    ],
    exports: [
        ExtractorMasterComponent,
        ExtractorEditComponent
    ],
    providers: [
        ExtractorService
    ]
})
export class ExtractorModule {

    static states = [
        {
            name: "robot.extractors",
            url: "/extractors",
            views: {
                'content@authorized': {
                    component: ExtractorMasterComponent
                }
            },
            data: {
                title: "Экстракторы",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.extractors.edit',
            url: "/EDIT/:extractorId?name",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.extractors': {
                    component: ExtractorEditComponent
                }
            },
            data: {
                title: "Экстракторы | ",
                newObjectTitle: "Новый экстрактор",
                editObjectTitle: "Редактирование экстрактора",
                successfulSaveMessage: "Экстрактор сохранён",
                meta: {
                    parameters: ["extractorId"],
                    sectionFunction: ([extractorId]) => "Обучение робота",
                    subSectionFunction: ([extractorId]) => extractorId ? "Редактирование экстрактора" : "Новый экстрактор"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        },
    ];
}
