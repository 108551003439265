import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import WebSocketService from "../../../../services/WebSocketService";
import {EruditeFile} from "../../../models/erudite-file.model";
import {BaseDataService} from "../base/base-data.service";
import {NamedEntitiesModel} from "../model/named-entities.model";


@Injectable()
export class FileService extends BaseDataService<EruditeFile, string> {

    baseUrl: string = "/account/expert/file";

    readonly entityClass = NamedEntitiesModel.ERUDITE_FILE;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

}