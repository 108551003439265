export interface EntityUsage {
    sectionTitle: string;
    type: AppliedEntityType,
    usages: AppliedEntity[];
}

export interface AppliedEntity {
    id: number,
    title: String;
    type: AppliedEntityType;
}

export enum AppliedEntityType {

    ANSWER = "ANSWER",

    ATTRIBUTE = "ATTRIBUTE",

    EXTRACTOR = "EXTRACTOR",

    SCENARIO = "SCENARIO",

    PROCEDURE = "PROCEDURE",

    SIMPLE_MODULE_ANSWER = "SIMPLE_MODULE_ANSWER",

    TAG = "TAG",

    DKB_ENTITY = "DKB_ENTITY",

    DKB_FIELD = "DKB_FIELD",

    DKB_VALUE = "DKB_VALUE",

    AUDIO_RECORD = "AUDIO_RECORD",

    CUSTOMIZATION_SCRIPT = "CUSTOMIZATION_SCRIPT",
}
