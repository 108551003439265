export enum NamedEntitiesModel {
    VA_TAG_ANSWER = "VATagAnswer",
    VA_ATTRIBUTE = "VAAttribute",
    AUDIO_RECORD = "AudioRecord",
    VA_COMMAND = "VACommand",
    CUSTOMISATION_SCRIPT = "CustomisationScript",
    VA_DKB_FIELD = "VADKBField",
    VA_DKB_ENTITY = "VADKBEntity",
    VA_DKB_VALUE = "VADKBValue",
    VA_EXTRACTOR = "Extractor",
    VA_PROCEDURE = "VAProcedure",
    VA_SCENARIO = "VAScenario",
    VA_SIMPLE_MODULE_ANSWER = "VASimpleModuleAnswer",
    VA_TAG = "VATag",
    VA_TEXT_DATA = "VATextData",
    TRANSCRIPTION_DIALOG = "TranscriptionDialog",
    ACCOUNT = "Account",
    CHAT_ACCOUNT_DATA = "ChatAccountData",
    VA_EXTRACTION_ATTEMPT = "VAExtractionAttempt",
    ERUDITE_FILE = "EruditeFile",
    PROJECT = "Project",
    VA_PROJECT_VERSION = "VAProjectVersion",
    VA_MESSAGE = "VAMessage",
    VA_TB_MESSAGE_CLUSTER = "VaTBMessageCluster",
    LICENSE = "LICENSE"
}
