export class ProgressData {
    operation: ProgressDataOperation
    projectVersionId: string;
    subscriptionId?: string;
    additionalData?: any;
    total: number;
    processed: number;
    finished: boolean;
    success: string;
    info: string;
    error: string;
    warning: string;
}

/**
 * @return процент сделанного
 */
export function getProgressDataPercent(data: ProgressData) {
    if (!data) {
        return null;
    }
    if (!data.processed || !data.total) {
        return 0;
    }
    return Math.floor(100 * data.processed / data.total);
}

export enum ProgressDataOperation {
    AUDIO_IMPORT = 'AUDIO_IMPORT',
    AUDIO_GENERATION = 'AUDIO_GENERATION',
    FAQ_TAGS_IMPORT = 'FAQ_TAGS_IMPORT',
    FAQ_MESSAGES_IMPORT = 'FAQ_MESSAGES_IMPORT',
    VALUE_OPTIONS_IMPORT = 'VALUE_OPTIONS_IMPORT'
}