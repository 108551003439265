import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FileSaverModule} from "ngx-filesaver";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {ProjectMasterComponent} from "./master/project-master.component";
import {ProjectEditComponent} from "./details/project-edit.component";
import {ProjectService} from "./project.service";
import {BoilerplateModule} from "../va/base/boilerplate.module";
import {EruditeFileUploaderModule} from "../common/file-uploader/erudite-file-uploader.module";
import {CollectionComponent} from "../common/collection/collection.component";
import {FormControlModule} from "../common/form/form-control.module";
import {CollectionModule} from "../common/collection/collection.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {VersioningProgressBarModule} from "../common/versioning-progress-bar/versioning-progress-bar.module";
import {MatRadioModule} from "@angular/material";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        FileSaverModule,
        UIRouterUpgradeModule,
        CollectionModule,
        FormControlModule,
        NgbModule,
        EruditeFileUploaderModule,
        BoilerplateModule,
        NgSelectModule,
        VersioningProgressBarModule,
        MatRadioModule
    ],
    declarations: [
        ProjectMasterComponent,
        ProjectEditComponent
    ],
    entryComponents: [
        CollectionComponent
    ],
    providers: [
        ProjectService
    ]
})
export class ProjectModule {

    static states = [
        {
            name: 'settings.projects',
            url: "/projects",
            views: {
                "content@authorized": {
                    component: ProjectMasterComponent
                }
            },
            data: {
                title: "Проекты"
            }
        },
        {
            name: 'settings.projects.edit',
            url: "/:mode/:projectId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@settings.projects': {
                    component: ProjectEditComponent
                }
            },
            data: {
                title: 'Проекты | ',
                newObjectTitle: "Создание проекта",
                editObjectTitle: "Редактирование проекта",
                successfulSaveMessage: "Проект сохранен",
                meta: {
                    parameters: ["projectId"],
                    sectionFunction: ([projectId]) => "Настройки",
                    subSectionFunction: ([projectId]) => projectId ? "Редактирование проекта" : "Создание проекта"
                },
                isCheckObjectExist: true
            }
        }
    ];

}