import {Component} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {VATagAnswer} from "../../../../../data/va/TagAnswer";
import {AnswerService} from "../answer.service";
import {BaseMasterComponent} from "../../base/base-master.component";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'answer-master',
    template: require('./answer-master.component.html'),
    styles: [require('./answer-master.component.less')]
})
export class AnswerMasterComponent extends BaseMasterComponent<VATagAnswer, CompositeKey<number>> {

    constructor(titleService: Title,
                stateService: StateService,
                dataService: AnswerService,
                notificationService: NotificationService,
                httpClient: HttpClient) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`d`), new SortField(`Название`, `name`), new SortField(`Тематика`, `taggedInfo.text`), new SortField(`Эксперт`, `expert.fullName`));
        this.getAccess();
    }

    async loadList(): Promise<void> {
        this.setLoading(true);
        this.dataService.findAll().then((answers: VATagAnswer[]) => {
            this.objects = answers.map(answer => new VATagAnswer(answer));
            this.setLoading(false);
        });
    }


    addNewObj(o: VATagAnswer) {
        this.objects.push(new VATagAnswer(o))
    }

    replaceObj(o, assignCallback: (object: any) => any = null) {
        super.replaceObj(o, object => new VATagAnswer(object));
    }

    /**
     * Функция для фильтрации
     * @param searchString поисковая строка
     * @param item         атрибут
     * @param filter       текущий фильтр
     */
    public testAnswer(searchString: string, item: VATagAnswer, filter: any): boolean {
        return item.textSearch(item.name, searchString);
    }
}
