import {Injectable} from '@angular/core';
import * as urls from "../../../../../../js/workplace/urls";
import {AudioRecord} from "../../../../data/va/AudioRecord";
import {VersionedDataService} from "../../va/base/versioned-data.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import {extractFileName} from "../../../../../util/Utils";
import {FileSaverService} from "ngx-filesaver";
import {NamedEntitiesModel} from "../../va/model/named-entities.model";
import {MassOperation} from "../../../../data/va/MassOperation";


@Injectable()
export class AudioRecordService extends VersionedDataService<AudioRecord, number> {

    baseUrl: string = `${urls.va.audio_records}`;

    usageEntityType: AppliedEntityType = AppliedEntityType.AUDIO_RECORD;

    readonly entityClass = NamedEntitiesModel.AUDIO_RECORD;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService, private fileSaver: FileSaverService) {
        super(httpClient, webSocketService, stateService);
    }

    /**
     * Скачать аудиофайл
     *
     * @param audioFileId id файла
     * @param onAudioFileLoaded колбэк на загрузку
     * @param onError колбек на вывод ошибок
     */
    loadAudioFile(audioFileId: string, onAudioFileLoaded: (dataUrl) => void, onError: (error) => void): void {
        this.loadAudio(`/getAudioRecordFile`, {audioFileId: audioFileId}, onAudioFileLoaded, onError)
    }

    async findAllWithoutFill(): Promise<AudioRecord[]> {
        return await this.httpClient.get<AudioRecord[]>(`${this.baseUrl}/`, {params: new HttpParams().set('skipFillingUsages', 'true')}).toPromise();
    }

    /**
     * Скачать аудиозапись сессии
     *
     * @param sessionId id сессии
     * @param onCallRecordLoaded колбэк на загрузку
     * @param onError колбек на вывод ошибок
     */
    loadCallRecordFile(sessionId: string, onCallRecordLoaded: (dataUrl) => void, onError: (error) => void) {
        this.loadAudio(`/getCallRecordFile`, {sessionId: sessionId}, onCallRecordLoaded, onError)
    }

    /**
     * Скачать аудиозапись реплики
     *
     * @param replyId id реплики
     * @param fileId id файла
     * @param onRecordLoaded колбэк на загрузку
     * @param onError колбек на вывод ошибок
     */
    loadReplyRecordFile(replyId: number, fileId: string, onRecordLoaded: (dataUrl) => void, onError: (error) => void) {
        let params = fileId ? {fileId: fileId} : {replyId: replyId.toString()};
        this.loadAudio(`/getReplyRecordFile`, params, onRecordLoaded, onError)
    }

    /**
     * Скачать архив с аудио
     * @param sessionId id сессии
     * @param onError хендлер ошибки
     */
    downloadAsrArchive(sessionId: string, onError: (error) => void) {
        this.httpClient.post(`${this.baseUrl}/asrArchive`, {}, {
            responseType: 'blob',
            observe: "response",
            params: {sessionId: sessionId}
        }).subscribe((data) => {
            const fileName = extractFileName(data.headers.get('Content-Disposition'));
            this.fileSaver.save(data.body, fileName)
        }, error => {
            if (error instanceof Blob) {
                (error as any).text().then(text => onError(text.substring(0, 300)));
            } else if (error.status == 410) {
                onError('Данные не найдены');
            } else if (error.message) {
                onError(error.message);
            } else {
                onError(JSON.stringify(error));
            }
        });
    }

    massOperation(operation: MassOperation): Promise<any> {
        return this.httpClient.post(`${this.baseUrl}/massOperation`, operation).toPromise();
    }
}