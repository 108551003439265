import {Component, Input} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";

@Component({
    selector: 'model-query-test',
    template: require('./model-query-test.component.html'),
    styles: [require('./model-query-test.component.less')]
})
export class ModelQueryTestComponent {

    @Input()
    private baseUrl: string;

    @Input()
    private modelId: string;

    /**
     * Запрос
     */
    public query: string;

    /**
     * Результаты тестирования запроса
     */
    public queryTestResult: any;

    constructor(private httpClient: HttpClient, private stateService: StateService) {

    }

    public test() {
        this.httpClient.get(`${this.baseUrl}/testQuery`, {params: {modelId: this.modelId, query: this.query}})
            .subscribe((result: any[]) => {
                this.queryTestResult = {
                    equalitySearcher: result.filter(item => item.equalitySearchExamples && item.equalitySearchExamples.length > 0),
                    classifier: result.filter(item => !item.equalitySearchExamples || item.equalitySearchExamples.length == 0)
                };
            })
    }

    public goToEntity(item) {
        // тип сэмпла, идентификатор
        let [typeStr, id, ...additionalIdentificationArray] = item.split("_");

        // тип
        const type: number = +typeStr;

        // доп информация для соц.чата
        const additionalIdentification = additionalIdentificationArray.join("_");

        let typeString = "";
        switch (type) {
            case 0:
                typeString = "USER_REPLY";
                break;
            case 1:
                typeString = "VA_MESSAGE";
                break;
            case 2:
                typeString = "EXTRA_SAMPLE";
                break;
            case 3: // VA_TAG
                let url = this.stateService.href("robot.tags.edit", {tagId: id});
                window.open(url, '_blank');
                return;
            case 4:
                typeStr = "OTHER_PROJECT";
                break;
            case 5: // OTHER_SERVICE
                window.open(additionalIdentification, '_blank');
                return;
        }

        this.httpClient.get(`${this.baseUrl}/getEqualitySearchLinkData`, {params: {type: typeString, entityId: id}})
            .toPromise()
            .then((entity: any) => {
                let entityId = entity.key;
                let text = entity.value;
                let url = null;
                switch (type) {
                    case 0: // USER_REPLY
                        url = this.stateService.href("robot.analysis_dialog", {dialogId: entityId});
                        break;
                    case 1: // VA_MESSAGE
                        url = this.stateService.href("robot.tags.edit", {tagId: entityId, query: text});
                        break;
                    case 2:  // EXTRA_SAMPLE
                        url = this.stateService.href("robot.acts.examples", {act: entityId, query: text});
                        break;
                    case 4: // OTHER_PROJECT
                        let checkReplyOtherProjectUrl = this.stateService.href("robot.ml_check_reply", {
                            projectVersionId: additionalIdentification,
                            reply: text
                        });
                        window.open(checkReplyOtherProjectUrl, '_blank');
                        return;
                }
                if (url) {
                    window.open(url, '_blank');
                }
            });

    }

}