import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ReplyView} from "../model/reply.model";
import {DialogCorrectionDTO, DialogCorrectionTypeEnum} from "../model/correction.model";
import {MatDialog} from "@angular/material/dialog";
import {ReplyTagCorrectionDialogComponent} from "./reply-tag-correction-dialog.component";
import * as cloneDeep from "lodash/cloneDeep";
import {isTagClassified} from "../dialog-tape-component.util";
import {StateService} from "@uirouter/core";

@Component({
    selector: 'reply-tag',
    template: require('./reply-tag.component.html'),
    styles: [require('./reply-tag.component.less'), require('./../correction.less')]
})

export class ReplyTagComponent implements OnInit {

    @Input()
    reply: ReplyView;

    @Input()
    isEditable: boolean;

    @Input()
    access: boolean;

    @Output()
    private onCorrectionDelete: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    private onCorrectionSave: EventEmitter<DialogCorrectionDTO> = new EventEmitter<DialogCorrectionDTO>();

    constructor(private dialog: MatDialog, private stateService: StateService) {
    }

    ngOnInit(): void {
    }

    /**
     * Клик на крестик у корректировки
     */
    onCorrectionDeleteClick() {
        if (this.reply.wrongTagCorrection) {
            this.onCorrectionDelete.emit(this.reply.wrongTagCorrection.correction.key.id);
            return;
        }
        if (this.reply.missedTagCorrection) {
            this.onCorrectionDelete.emit(this.reply.missedTagCorrection.correction.key.id);
            return;
        }

    }

    isCorrectionAllowed(): boolean {
        return this.reply.correctionAllowed && this.isEditable && this.access;
    }

    openCorrectionDialog(correction: DialogCorrectionDTO, $event: MouseEvent) {
        if ($event.ctrlKey || $event.metaKey) {
            // с ctrl/cmd переходим на тематику, потому будет задача на "нормально"
            const tagId = correction?.message?.tagId || this.reply.reply.classifiedTagId;
            window.open(this.stateService.href("robot.tags.edit", {tagId: tagId}), '_blank');
            return;
        }

        const dialogRef = this.dialog.open(ReplyTagCorrectionDialogComponent, {
            data: {
                reply: cloneDeep(this.reply),
                correction: correction ? cloneDeep(correction) : null,
                type: DialogCorrectionTypeEnum.WRONG_TAG
            },
            width: '800px',
        });
        dialogRef.afterClosed()
            .subscribe((correction: DialogCorrectionDTO) => {
                if (correction) {
                    this.onCorrectionSave.emit(correction);
                }

            });
    }

    /**
     * Нужно ли отображать информацию о тематике
     *
     */
    isTagDisplayable() {
        // акт с учетом корректировки - вопрос к мз и есть группа
        return isTagClassified(this.reply.reply);
    }
}

