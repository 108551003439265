import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {
    OWL_DATE_TIME_FORMATS,
    OWL_DATE_TIME_LOCALE, OwlDateTimeIntl,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
} from "ng-pick-datetime";
import {MatIconModule} from "@angular/material/icon";
import {DatetimePickerComponent} from "./datetime-picker.component";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {OwlMomentDateTimeModule} from "ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time.module";

export class DefaultIntl extends OwlDateTimeIntl {
    /** A label for the up second button (used by screen readers).  */
    upSecondLabel= 'Добавить секунду';

    /** A label for the down second button (used by screen readers).  */
    downSecondLabel= 'Отнять секунду';

    /** A label for the up minute button (used by screen readers).  */
    upMinuteLabel= 'Добавить минуту';

    /** A label for the down minute button (used by screen readers).  */
    downMinuteLabel= 'Отнять минуту';

    /** A label for the up hour button (used by screen readers).  */
    upHourLabel= 'Добавить час';

    /** A label for the down hour button (used by screen readers).  */
    downHourLabel= 'Отнять час';

    /** A label for the previous month button (used by screen readers). */
    prevMonthLabel= 'Предыдущий месяц';

    /** A label for the next month button (used by screen readers). */
    nextMonthLabel= 'Следующий месяц';

    /** A label for the previous year button (used by screen readers). */
    prevYearLabel= 'Предыдущий год';

    /** A label for the next year button (used by screen readers). */
    nextYearLabel= 'Следующий год';

    /** A label for the previous multi-year button (used by screen readers). */
    prevMultiYearLabel= 'Предыдущий 21 год';

    /** A label for the next multi-year button (used by screen readers). */
    nextMultiYearLabel= 'Следующий 21 год';

    /** A label for the 'switch to month view' button (used by screen readers). */
    switchToMonthViewLabel= 'Перейти к месяцу';

    /** A label for the 'switch to year view' button (used by screen readers). */
    switchToMultiYearViewLabel= 'Выберите месяц и год';

    /** A label for the cancel button */
    cancelBtnLabel= 'Отмена';

    /** A label for the set button */
    setBtnLabel= 'ОК';

    /** A label for the range 'from' in picker info */
    rangeFromLabel= 'От';

    /** A label for the range 'to' in picker info */
    rangeToLabel= 'До';

    /** A label for the hour12 button (AM) */
    hour12AMLabel= 'AM';

    /** A label for the hour12 button (PM) */
    hour12PMLabel= 'PM';
}

export const MOMENT_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm:ss',
    fullPickerInput: 'DD/MM/YYYY HH:mm:ss',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
    declarations: [
        DatetimePickerComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        OwlMomentDateTimeModule,
        MatIconModule
    ],
    exports: [
        DatetimePickerComponent
    ],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'ru-RU'
        },
        {
            provide: OWL_DATE_TIME_LOCALE,
            useValue: 'ru-RU'
        },
        {
            provide: OWL_DATE_TIME_FORMATS,
            useValue: MOMENT_FORMATS
        },
        {
            provide: OwlDateTimeIntl,
            useClass: DefaultIntl
        },
    ]
})
export class DatetimePickerModule {

}