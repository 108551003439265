import {LicenseConfigCountedUsageModel} from "./license-config-counted-usage.model";
import {LicenseConfigPoolUsageModel} from "./license-config-pool-usage.model";

export class LicenseUsages {
    active: LicenseUsagePage;
    inactive: LicenseUsagePage;

    constructor(object?: any) {
        if (!object) {
            return;
        }

        this.active = object.active;
        this.inactive = object.inactive;
    }
}

export class LicenseUsagePage {
    incrementals: LicenseConfigCountedUsageModel[];
    periodics: LicenseConfigCountedUsageModel[];
    channels: LicenseConfigPoolUsageModel[];
    prompters: LicenseConfigPoolUsageModel[];

    constructor(object?: any) {
        if (!object) {
            return;
        }

        this.channels = object.channels;
        this.prompters = object.prompters;
        this.periodics = object.periodics;
        this.incrementals = object.incrementals;
    }
}

export enum LicenseDisplayedType {
    INCREMENTAL = 'Пиковые',
    PERIODIC = 'Диалоговые',
    CHANNEL= 'Канальные',
    PROMPTER = 'Суфлер'
}
