import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import {Dialog, DialogCopyResult, DialogDTO, SessionMode} from "./model/dialog.model";
import {DialogCorrection, DialogCorrectionDTO, DialogCorrectionTypeEnum} from "./model/correction.model";
import {DialogRelevanceCheckResult} from "./model/dialog-relevance.model";
import {VAProcedure, VAScript} from "../../../data/va/Script";
import {Reply} from "./model/reply.model";
import {ExtractedValueType} from "./model/extracted-value.model";

@Injectable()
export class DialogService {

    private baseUrl: string = "/account/expert/dialog";

    constructor(protected httpClient: HttpClient, protected stateService: StateService) {
    }

    async getPrompterOptions(id: number): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/${id}/prompter`).toPromise();
    }

    /**
     * Режимы проведения диалога
     */
    async getSessionModes(): Promise<SessionMode[]> {
        return await this.httpClient.get<SessionMode[]>(`${this.baseUrl}/sessionModes`).toPromise();
    }

    /**
     * Загрузить диалог с бэкенда со всеми дополнительными нужными для отображения данными
     * @param id
     */
    async getDialog(id): Promise<Dialog> {
        // закачиваем с бэкенда диалог в паре со списком его сценариев/процедур
        const dialogDTO = await this.httpClient.get<DialogDTO>(`${this.baseUrl}/${id}`).toPromise();
        // проставляем сценарий/процедуры с наполнением (не только id) в реплики диалога
        dialogDTO.dialog.replies.forEach(reply => this.fillScripts(reply, dialogDTO.scripts))

        // если перебивали робота, то отобразим это в соответствующей реплике
        dialogDTO.dialog.replies.filter(reply => reply.isUser && reply.interrupted)
            .map(reply => dialogDTO.dialog.replies.find(otherReply => !otherReply.isUser && otherReply.id == reply.interrupted))
            .forEach(robotReply => robotReply.isInterrupted = true);

        return dialogDTO.dialog;
    }

    /**
     * Проставить сценарий и процедуры в реплику
     *
     * @param reply реплика робота
     * @param scripts перечень сценариев и процедур в диалоге
     */
    private fillScripts(reply: Reply, scripts: VAScript[]): void {
        if (reply.script) {
            reply.script = DialogService.findScript(scripts, reply.script);
        }
        if (reply.enteredProcedures) {
            reply.enteredProcedures = reply.enteredProcedures.map(procedure => DialogService.findScript(scripts, procedure))
        }
        if (reply.exitedProcedures) {
            reply.exitedProcedures = reply.exitedProcedures.map(procedure => DialogService.findScript(scripts, procedure))
        }
        if (reply.extractedValues) {
            reply.extractedValues.filter(value => value.type == ExtractedValueType.PROCEDURE).forEach(value => value.entity = DialogService.findScript(scripts, value.entity as VAProcedure))
        }
    }

    /**
     * Найти сценарий/процедуру в списке по заданному скрипту с таким же id
     */
    private static findScript(scripts: VAScript[], idInstance: VAScript) {
        return scripts.find(script => script.key.id == idInstance.key.id);
    }

    async getCorrections(dialogId: number): Promise<DialogCorrectionDTO[]> {
        return await this.httpClient.get<DialogCorrectionDTO[]>(`${this.baseUrl}/${dialogId}/corrections`).toPromise();
    }

    /**
     * Создать копию диалога по выбранную реплику реплики для продолжения в чате с роботом
     */
    async copyForTesting(dialogId: number, replyId: number): Promise<DialogCopyResult> {
        return await this.httpClient.get<DialogCopyResult>(`${this.baseUrl}/${dialogId}/${replyId}/copyForTesting`).toPromise();
    }

    async deleteCorrections(dialogId: number): Promise<any> {
        return await this.httpClient.delete(`${this.baseUrl}/correction/${dialogId}/deleteCorrection`).toPromise();
    }

    /**
     * Создать новое исправление
     */
    async initCorrection(dialogId: number, replyId: number, type: DialogCorrectionTypeEnum, extractedValueId?: number): Promise<DialogCorrectionDTO> {
        return await this.httpClient
            .get<DialogCorrectionDTO>(`${this.baseUrl}/${dialogId}/initCorrection/${replyId}/${type}${extractedValueId ? '/' + extractedValueId : ''}`)
            .toPromise();
    }

    async saveCorrection(dialogId: number, correction: DialogCorrectionDTO): Promise<DialogCorrection> {
        const action = correction.correction.key.id ? 'updateCorrection' : 'createCorrection';
        return await this.httpClient
            .post<DialogCorrection>(`${this.baseUrl}/${dialogId}/${action}`, correction)
            .toPromise();
    }

    async startCheckRelevance(dialogId: string, projectVersionId: string): Promise<DialogRelevanceCheckResult> {
        return await this.httpClient.post<DialogRelevanceCheckResult>(`${this.baseUrl}/${dialogId}/relevance/${projectVersionId}`, null).toPromise();
    }

    async checkRelevance(dialogId: string, relevanceVersionId: string): Promise<DialogRelevanceCheckResult> {
        return await this.httpClient.post<DialogRelevanceCheckResult>(`${this.baseUrl}/${dialogId}/relevance`, null, {params: {projectVersionId: relevanceVersionId}}).toPromise();
    }

    async getAccess(): Promise<{ access: boolean }> {
        return await this.httpClient.get<{ access: boolean }>(`${this.baseUrl}/access`).toPromise();
    }

    async sendDialogSession(duration: number, dialogId: string, superExpertRating: number, checkedDialog: boolean) {
        let dialogStat = {duration, superExpertRating, checkedDialog};
        return await this.httpClient.post(`${this.baseUrl}/${dialogId}/logDialogSession`, dialogStat).toPromise();
    }
}



