import {Component} from "@angular/core";
import {BaseMasterComponent} from "../../base/base-master.component";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {VaTBMessageCluster} from "../../../../../data/va/Message";
import {VaTag} from "../../../../../data/va/Tag";
import WebSocketService from "../../../../../services/WebSocketService";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {MessageClustersService} from "./message-clusters.service";
import {HttpClient} from "@angular/common/http";
import {SourceSelectorService} from "../source-selector/source-selector.service";

@Component({
    selector: 'messages-clusters-list',
    template: require('./message-clusters.component.html'),
    styles: [require('./message-clusters.component.less')]
})
export class MessageClustersComponent extends BaseMasterComponent<VaTBMessageCluster, CompositeKey<number>> {


    projectVersionId: string;
    sourceTagId: number;
    sourceTagIdRequestParam: number;
    tags: VaTag[] = [];
    clusterSource: VaTag;
    subscriptionId: any;
    searchQuery: string;
    childSearchQuery: string;

    constructor(protected webSocketService: WebSocketService,
                protected titleService: Title,
                private sourceSelectorService: SourceSelectorService,
                protected stateService: StateService,
                protected notificationService: NotificationService,
                protected dataService: MessageClustersService,
                protected httpClient: HttpClient) {

        super(titleService, stateService, dataService, httpClient, notificationService);

        this.projectVersionId = stateService.params['projectVersionId'];
        this.sourceTagId = stateService.params['sourceTagId'];
        this.sourceTagIdRequestParam = stateService.params['sourceTagId'];

        this.getAccess();
        this.sortFields.push(SortField.date(`d`), new SortField("Сообщения", "messageCount", false),
            new SortField("Статус", "state.title"));
        // Если это кластеризация по тематикам, то проверим есть ли кластеры с таким sourceTag и если нет, то инициализируем их
        if (this.sourceTagId) {
            this.getClusterSource();
        }


        this.loadTags();

        // подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_CLUSTER_CHANGED,VA_TAG_CREATED,VA_TAG_CHANGED,VA_TAG_DELETED",
            fn: (event) => {
                switch (event.type) {
                    case "VA_CLUSTER_CHANGED":
                        this.refreshData(event);
                        break;
                    case "VA_TAG_CREATED":
                    case "VA_TAG_CHANGED":
                    case "VA_TAG_DELETED":
                        let projectVersionId = this.webSocketService.tryGetProjectVersionId(event, 'object');
                        if (projectVersionId !== this.projectVersionId) {
                            break;
                        }
                        this.handleTagChanges(event, event.type);
                        break;
                }
            }
        });
    }

    handleTagChanges(event, type) {
        let details = JSON.parse(event.details);
        let oldObject = details.oldObject;
        let object = details.object;

        let messages = [];

        switch (type) {
            case "VA_TAG_CREATED":
                messages.push(`Создана тематика ${object.text}`);
                break;
            case "VA_TAG_CHANGED":
                if (oldObject.text !== object.text) {
                    messages.push(`Тематика "${oldObject.text}" переименована в "${object.text}"`);
                }
                if (oldObject.parentId !== object.parentId) {
                    let oldTag = this.tagIdToText(oldObject.parentId);
                    let newTag = this.tagIdToText(object.parentId);
                    messages.push(`Тематика "${object.text}" перенесена из "${oldTag}" в "${newTag}"`);
                }
                break;
            case "VA_TAG_DELETED":
                messages.push(`Удалена тематика ${object.text}`);
                break;
        }

        const message = messages.reduce((p, c) => p + "\n" + c, '')
        this.notificationService.info(message);

        this.loadTags();
    }

    async loadList() {
        this.setLoading(true);
        this.loadPage();
        this.setLoading(false);
    }

    refreshData(event) {
        let newCluster = JSON.parse(event.details);
        let oldClusters = this.objects.filter(c => c.key.id == newCluster.key.id);
        if (oldClusters && oldClusters.length > 0) {
            let oldCluster = oldClusters[0];
            oldCluster.clusterEditor = newCluster.clusterEditor;
            oldCluster.state = newCluster.state;
            oldCluster.messageCount = newCluster.messageCount;
            oldCluster.expertId = newCluster.expertId;
            oldCluster.expert = newCluster.expert;
        }
    }

    async loadPage() {
        let temp = await this.dataService.getTBList(this.sourceTagIdRequestParam);
        this.objects = temp['list'];
    }

    getClusterSource() {
        this.sourceSelectorService.getSources()
            .then((data) => {
                this.clusterSource = data.find(obj => obj.key.id == this.sourceTagId);
                if (!this.clusterSource) {
                    this.clusterizeGet("initTagClusters");
                }
            });
    }

    /**
     * Load tag list
     */
    async loadTags() {
        // подгружаем список тематик
        this.tags = await this.dataService.getTags();
    }

    /**
     * Получение названия тематики по id
     */
    tagIdToText(tagId) {
        for (let i = 0; i < this.tags.length; i++) {
            if (tagId == this.tags[i].key.id) {
                return this.tags[i].text;
            }
        }
    }

    /**
     * Цвет для отображения статуса кластера
     */
    getColor(state) {
        switch (state) {
            case 'NEW':
                return 'bg-aqua';
            case 'DONE':
                return 'bg-green';
            case 'BAD':
                return 'bg-red';
        }
    }

    /**
     * Функция отправки запроса
     */
    async clusterizeGet(script: string) {
        let temp = await this.dataService.clusterizeGet(this.sourceTagIdRequestParam, script);
        if (temp instanceof VaTBMessageCluster) {
            this.objects = temp;
        } else {
            this.objects = [];
        }
    }


}