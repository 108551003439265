import {Component, Input} from "@angular/core";


@Component({
    selector: 'cell-progress',
    template: require('./cell-progress.component.html'),
    styles: [require('./table.component.less')]
})

export class CellProgressComponent {
    @Input() values: { strValue: string, percent: number };

    constructor() {
    }
}
