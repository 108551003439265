import {Component, Input, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EntityUsage} from "./entity-usages.model";
import {EntityUsageService} from "./entity-usage.service";

/**
 * Компонента для отображения списка используемых сущностей
 */
@Component({
    selector: 'entity-usage',
    template: require('./entity-usage.component.html'),
    styles: [require('./entity-usage.component.less')]
})
export class EntityUsageComponent implements OnInit {

    /**
     * Показывать сообщение о том, что сущность нигде не используется
     */
    @Input()
    private showUsageAbsenceMessage: boolean = false;

    /**
     * Перечень использований сущности в других сущностях
     */
    @Input()
    usages: EntityUsage[];

    constructor(private http: HttpClient, public entityUsagesService: EntityUsageService) {
    }

    ngOnInit(): void {

    }

    /**
     * Используется ли эта сущность где-то
     */
    get isEntityUsed() {
        return this.usages && this.usages.some(data => data.usages.length > 0);
    }

    /**
     * Нужно ли отображать сообщение о том, что сущность нигде не используется
     */
    get showMessage() {
        return this.showUsageAbsenceMessage && !this.isEntityUsed;
    }
}

