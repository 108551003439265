import {Component, Inject} from "@angular/core";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatDialog} from "@angular/material/dialog";

import {DialogService} from "../dialog.service";

@Component({
    selector: 'prompter-options',
    template: require('./prompter-options.component.html'),
    styles: [require('./prompter-options.component.less')]
})

export class PrompterOptionsComponent {

    prompterOptions: any;

    constructor(private bottomSheetRef: MatBottomSheetRef<PrompterOptionsComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
                    id: number
                },
                private dialog: MatDialog,
                private bottomSheet: MatBottomSheet,
                private dialogService: DialogService) {
        this.loadData();
    }

    loadData(){
        this.dialogService.getPrompterOptions(this.data.id)
            .then((data) => {
                this.prompterOptions = data;
            });
    }
}



