import {dia, shapes} from "@naumen/rappid/build/rappid";
import {CaseView, StepLabelTypeName, VaScriptEdge, VaScriptNode} from "../../../../data/va/Script";
import {calcOffset, getAllINums, getEdgeLabelSize, getLinkColor, HIGHLIGHT_COLOR} from "../ScriptBuilderUtils";
import {EruditeElement} from "../element-view/EruditeElement";
import {LinkAttributeId, setLinkAttribute} from "../controller/ScriptAttributeUtils";
import {VaScriptCondition} from "../../../../data/va/VaScriptCondition";
import LinkView = dia.LinkView;
import Size = dia.Size;

type LinkCallback = (viewModel: EruditeLink) => void;

export default class EruditeLink extends shapes.standard.Link {

    // колбэк переключение чекпоинта
    public onCheckpoint: LinkCallback;

    /**
     * Callback создания нового link'a
     */
    public onCreate: LinkCallback;

    /**
     * Callback после обновления условий перехода
     */
    public onConditionModifyFinish: LinkCallback;

    // колбэк добавления и редактирования условий перехода
    public onConditionModify: LinkCallback;

    /**
     * Поле изменяемое валидатором в том случае, если такое ребро нельзя создать
     */
    public isValidLink = true;

    public readonly erudite = true;

    private showPlusButton: boolean = false;

    private showLabelText: boolean = false;

    /**
     * Пройденные номера ребер для диагностики доступности
     */
    private passedNums: number[] = [];

    /**
     * Флаг недоступности из старта
     */
    private stuck: boolean;

    public readonly sourceNode: VaScriptNode;

    constructor(attributes: { edge: VaScriptEdge, sourceNode: VaScriptNode, incline: boolean, caseView?: CaseView, caseId?: any }) {
        super(attributes);
        this.sourceNode = attributes.sourceNode;
        this.attributes.id = this.edge.key.id;
        this.attributes.vertices = EruditeLink.deserializeVertices(this.edge);
        this.router({name: attributes.incline ? 'normal' : 'manhattan'});
        this.connector({name: 'jumpover'});

        this.initCaseDate(attributes.caseView, attributes.caseId);
        let color = getLinkColor(this.sourceNode.stepLabel.type.name, this.passedNums.length > 0, this.stuck);

        this.attr({
            line: {
                stroke: color,
                strokeWidth: 4
            },
        });

        this.appendLabel({
            markup: [
                {
                    tagName: 'rect',
                    selector: 'body'
                },
                {
                    tagName: 'g',
                    selector: 'wrapper',
                    children: [{
                        tagName: 'text',
                        selector: 'label'
                    }, {
                        tagName: "path",
                        selector: "chevron",
                        attributes: {
                            'd': 'M13 0H0L6.88235 7L13 0Z'
                        }
                    }, {
                        tagName: 'g',
                        selector: 'plusButton',
                        children: [
                            {
                                tagName: 'circle',
                                selector: 'plusButtonCircle'
                            }, {
                                tagName: 'path',
                                selector: 'plusButtonIcon',
                                attributes: {
                                    'd': 'M 0 -6 0 6 M -6 0 6 0',
                                    'fill': 'none',
                                    'stroke-width': 2,
                                    'pointer-events': 'none'
                                }
                            },
                        ]
                    }, {
                        tagName: 'path',
                        selector: 'checkpoint',
                        attributes: {
                            'd': 'M20.8437 4.00893C20.8437 3.67411 20.7097 3.33928 20.4687 3.09821L18.6472 1.27678C18.4062 1.03571 18.0713 0.901785 17.7365 0.901785C17.4017 0.901785 17.0669 1.03571 16.8258 1.27678L8.0401 10.0759L4.1026 6.125C3.86152 5.88393 3.5267 5.75 3.19188 5.75C2.85706 5.75 2.52224 5.88393 2.28117 6.125L0.459739 7.94643C0.218667 8.1875 0.0847387 8.52232 0.0847387 8.85714C0.0847387 9.19196 0.218667 9.52679 0.459739 9.76786L5.30795 14.6161L7.12938 16.4375C7.37045 16.6786 7.70527 16.8125 8.0401 16.8125C8.37492 16.8125 8.70974 16.6786 8.95081 16.4375L10.7722 14.6161L20.4687 4.91964C20.7097 4.67857 20.8437 4.34375 20.8437 4.00893Z'
                        }
                    }
                    ]
                }
            ],
            attrs: {
                wrapper: {
                    refWidth: '100%',
                    refHeight: '100%'
                },
                label: {
                    textWrap: {
                        ellipsis: true,
                    },
                    fontSize: 14,
                    textAnchor: 'middle',
                    yAlignment: 'middle',
                    pointerEvents: 'none',
                    fill: color
                },
                body: {
                    ref: 'wrapper',
                    event: 'link:condition-button:pointerdown',
                    refHeight: "100%",
                    fill: '#f8f8f8',
                    refX: -10,
                    refY: 0,
                    cursor: 'pointer',
                    rx: 10,
                    ry: 10
                },
                chevron: {
                    pointerEvents: 'none',
                    ref: 'text',
                    refX: '40%',
                    refX2: '120%',
                    refY: '40%',
                    fill: color
                },
                checkpoint: {
                    pointerEvents: 'none',
                    ref: 'chevron',
                    refX: '200%',
                    refY: '-80%',
                    fill: color
                },
                plusButtonIcon: {
                    stroke: color,
                },
                plusButtonCircle: {
                    event: 'link:condition-button:pointerdown',
                    stroke: color,
                    r: 17,
                    fill: '#fff',
                    'stroke-width': 2,
                    cursor: 'pointer'
                }
            }
        });

        this.hasCheckpoint = this.edge.checkpoint;
        this.prepareEdgeLabel();
    }

    get edge(): VaScriptEdge {
        return this.attributes.edge
    }

    get targetNode() {
        return this.targetElement.node;
    }

    get scriptEdge() {
        return this.edge;
    }

    /**
     * Показать/Скрыть шевроны/кнопки плюс и т.д.
     * @param paper
     * @param show
     */
    toggleControlsVisibility(paper: dia.Paper, show: boolean) {
        this.label(0, {
            attrs: {
                plusButton: {
                    visibility: this.showPlusButton && show ? 'visible' : 'hidden'
                },
                chevron: {
                    visibility: this.showLabelText && show ? 'visible' : 'hidden'
                }
            }
        });
        this.findView(paper).render();
    }

    set hasCheckpoint(has: boolean) {
        this.label(0, {
            attrs: {
                body: {
                    refWidth: has ? 90 : 45
                },
                checkpoint: {
                    visibility: has ? 'visible' : 'hidden'
                }
            }
        });
    }


    prepareEdgeLabel() {
        const conditionsForLabel = this.edge.conditions.filter(condition => !condition.isUnconditionalStep());
        this.showLabelText = conditionsForLabel.length > 0 || this.passedNums.length > 0;
        if (this.showLabelText) {
            let edgeLabel = conditionsForLabel[0].objectToTitleWithCondition();
            if (conditionsForLabel.length > 1) {
                edgeLabel += " и\n";
                edgeLabel += conditionsForLabel.slice(1).map(condition => condition.getConditionText(true, conditionsForLabel.length > 1)).join(" и\n");
            }
            if (this.passedNums.length) {
                // если есть диагностика доступности, выводим ее
                edgeLabel += '\n' + this.passedNums.join(", ");
            }
            const size: Size = getEdgeLabelSize(edgeLabel);
            this.label(0, {
                attrs: {
                    label: {
                        textWrap: {
                            text: edgeLabel,
                            width: size.width,
                            height: size.height
                        },
                    }
                }
            });
            this.showPlusButton = false;
        } else {
            this.showPlusButton = this.isConditionable;
        }

        this.label(0, {
            attrs: {
                plusButton: {
                    visibility: this.showPlusButton ? 'visible' : 'hidden'
                },
                chevron: {
                    visibility: this.showLabelText ? 'visible' : 'hidden'
                },
                body: {
                    visibility: this.showLabelText ? 'visible' : 'hidden'
                },
                label: {
                    visibility: !this.showPlusButton ? 'visible' : 'hidden'
                }
            }
        });
    }

    get isConditionable() {
        const notConditionableTypes = [
            StepLabelTypeName.EXIT,
            StepLabelTypeName.TO_TAG,
            StepLabelTypeName.TO_SUPPORT,
        ];
        return !notConditionableTypes.includes(this.sourceNode.stepLabel.type.name);
    }

    get serializedVertices() {
        let vertices = this.prop('vertices');
        return vertices ?
            vertices.map((point: dia.Point) => `${point.x};${point.y}`).join("##") : null;
    }

    set sourceElement(sourceElement: EruditeElement) {
        this.edge.fromNodeId = sourceElement.node.key.id;
        this.source(sourceElement, {
            anchor: {
                name: 'perpendicular'
            }
        });
        this.prop('sourceElement', sourceElement);
    }

    get sourceElement(): EruditeElement {
        return this.prop('sourceElement');
    }

    set targetElement(targetElement: EruditeElement) {
        this.edge.toNodeId = targetElement.node.key.id;
        this.target(targetElement, {
            anchor: {
                name: 'perpendicular'
            },
            connectionPoint: {
                name: 'boundary'
            }
        });
        this.prop('targetElement', targetElement);
    }

    get targetElement(): EruditeElement {
        return this.prop('targetElement');
    }

    get edgesFromSourceNode(): VaScriptEdge[] {
        return this.sourceElement.outgoingEdges;
    }

    public setValidLink(value: boolean) {
        this.isValidLink = value;
        if (!value) {
            this.remove({disconnectLinks: true});
        }
    }

    onCheckpointToggled(linkView: LinkView) {
        this.onCheckpoint(this);
    }

    onConditionButton() {
        if (this.isConditionable) {
            this.onConditionModify(this);
        }
    }

    onLinkCreated(linkView: LinkView) {
        this.onCreate(this)
    }

    private static deserializeVertices(edge: VaScriptEdge) {
        if (Array.isArray(edge.vertices)) {
            return edge.vertices;
        }
        if (!edge.vertices || !edge.vertices.split) {
            return [];
        }
        return edge.vertices
            .split("##")
            .map((value: string) => {
                const strings: number[] = value.split(";").map(value => parseFloat(value));
                return {x: strings[0], y: strings[1]};
            });
    }

    deleteVertices() {
        this.vertices([]);
    }

    /**
     * Двигаем узлы, линк перепрокладывается
     */
    redraw() {
        this.sourceElement.translate(1, 1);
        this.sourceElement.translate(-1, -1);
        this.targetElement.translate(1, 1);
        this.targetElement.translate(-1, -1);
    }

    set checkpoint(value: boolean) {
        this.edge.checkpoint = value;
        setLinkAttribute(this, LinkAttributeId.CHECKPOINT, value);
    }

    set conditions(conditions: VaScriptCondition[]) {
        this.edge.conditions = conditions;
        setLinkAttribute(this, LinkAttributeId.CONDITIONS, conditions);
    }

    /**
     * Линки для подсветки при наведении на этот линк
     */
    get linksToHighlight(): EruditeLink[] {
        return [this];
    }

    /**
     * Узлы для подсветки при наведении на этот линк
     */
    get elementsToHighlight(): EruditeElement[] {
        return [this.sourceElement, this.targetElement];
    }

    /**
     * Переключить подсветку ребра
     *
     * @param enable true для включить, false - в нормальный вид
     */
    switchHighlight(enable: boolean) {
        // новый цвет
        const newColor: string = enable ? HIGHLIGHT_COLOR : getLinkColor(this.sourceElement.node.stepLabel.type.name, this.passedNums.length > 0, this.stuck);

        // толщина обводки лейбла
        const labelStrokeWidth: number = enable ? 2 : 0;

        // линия
        this.prop('attrs/line/stroke', newColor);

        // лейбл
        this.label(0, {
            attrs: {
                body: {
                    stroke: newColor,
                    strokeWidth: labelStrokeWidth
                },
                plusButtonCircle: {
                    stroke: newColor
                }
            }
        });
        if (enable) {
            // выносим на передний план
            this.toFront();
        }
    }

    /**
     * Пропорционально сместить все вершины линка относительно заданной точки
     */
    offsetCoordinates(rootCenter: dia.Point, horizontalFactor: number, verticalFactor: number): void {
        let vertices = this.prop('vertices');
        if (!vertices) {
            // нет точек на ребре
            return
        }
        // пропорционально изменяем расстояние до начального узла
        const newVertices = vertices.map((v: dia.Point) => {
            const dx = calcOffset(v.x, rootCenter.x, horizontalFactor);
            const dy = calcOffset(v.y, rootCenter.y, verticalFactor);
            return {x: v.x + dx, y: v.y + dy};
        });
        this.prop('vertices', newVertices);
    }


    /**
     * Переключить режим наклонных ребер
     */
    toggleIncline(enable: boolean) {
        this.attributes.incline = enable;
        this.router(enable ? 'normal' : 'manhattan');
    }

    /**
     * Проинициализировать данные по генерации кейсов / диагностике доступности узлов
     */
    private initCaseDate(caseView: CaseView, caseId: any) {
        if (!caseView || !caseId) {
            // нет данных
            return;
        }
        let visited;
        let stuck;
        if (caseId) {
            // задан конкретный кейс
            visited = caseView.paths[caseId] as string[]
            stuck = caseView.stuckPaths[caseId] as string[]
        } else {
            // все кейсы
            visited = caseView.visitedEdges;
            stuck = caseView.stuckEdges;
        }
        if (visited) {
            // пройденные ребра
            this.passedNums = getAllINums(visited, this.edge.key.id);
        }
        if (stuck) {
            // флаг "застряли"
            this.stuck = !!stuck.find(id => id == this.edge.key.id);
        }
    }

    /**
     * Проставить версию
     */
    setProjectVersionId(projectVersionId: string) {
        this.attributes.edge.key.projectVersionId = projectVersionId;
        this.edge.key.projectVersionId = projectVersionId;
        this.attributes.sourceNode.key.projectVersionId = projectVersionId;
        this.sourceNode.key.projectVersionId = projectVersionId;
    }

    /**
     * Все использованные в условиях линка id, мапа (id сущности -> (id субсущности))
     */
    get allIds(): Map<any, Set<any>> {
        const ids = new Map<any, Set<any>>();
        if (this.edge.conditions) {
            this.edge.conditions.forEach(condition => {
                if (condition.entityId && !condition.primitiveRule) {
                    const subIds = new Set<any>();
                    ids.set(condition.entityId, subIds);
                    if (condition.values) {
                        condition.values.forEach(value => subIds.add(value));
                    }
                }
            })
        }
        return ids;
    }
}
