import {Scope, VaAttribute} from "../../../../data/va/Attribute";
import {VaTag} from "../../../../data/va/Tag";
import {VAProcedure} from "../../../../data/va/Script";
import {ValueOption, ValueTypeEnum} from "../../../../data/va/Extractor";
import {InfoType} from "./dialog-context.model";
import {VaExtractionContext} from "./correction.model";

export class ExtractedValue {
    id: number;
    entityId: number;
    replyId: number;
    resetReplyId: number;
    resetId: number;
    dialogId: number;
    projectVersionId: string;
    value: string;
    extractionDate: Date;
    source: ExtractedInfoSource;
    confidence: Confidence;
    extractorId: number;
    highlightedText: string;
    extractionContext: VaExtractionContext;
    scope: Scope;
    type: ExtractedValueType;
    resetState: ResetState;
    confirmInfoType: InfoType;
    confirmInfoValue: string;
    entity: VaAttribute | VaTag | VAProcedure;
    valueOption: ValueOption;
    procedureValueName: string;
    callId: number;

    /**
     * Извлечённое значение = дата?
     * @param value извлечённое значение
     */
    static isDateValue(value: ExtractedValue) {
        return (value?.entity as VaAttribute)?.extractor?.valueType?.name == ValueTypeEnum.DATE
    }

    /**
     * Извлечённое значение = адрес?
     * @param value извлечённое значение
     */
    static isAddressValue(value: ExtractedValue) {
        return (value?.entity as VaAttribute)?.extractor?.valueType?.name == ValueTypeEnum.ADDRESS
    }
}

export enum ResetState {
    RESET = "RESET",
    OUTDATED = "OUTDATED"
}

export enum ExtractedValueType {
    ATTRIBUTE = "ATTRIBUTE",
    CONFIRMATION = "CONFIRMATION",
    PROCEDURE = "PROCEDURE"
}

export class ConfirmInfoType {
    static readonly TAG = new ConfirmInfoType("TAG", "Подтверждение выбора тематики");
    static readonly ATTRIBUTE = new ConfirmInfoType("ATTRIBUTE", "Подтверждение значения атрибута");
    static readonly VALUES = [ConfirmInfoType.TAG, ConfirmInfoType.ATTRIBUTE];

    name: string;
    title: string;

    constructor(name, title) {
        this.name = name;
        this.title = title;
    }
}

export class Confidence {
    static readonly ABSOLUTELY_SURE = new Confidence("ABSOLUTELY_SURE", "Уверен");
    static readonly UNSURE = new Confidence("UNSURE", "Не уверен");
    static readonly UNSUCCESSFUL = new Confidence("UNSUCCESSFUL", "Не успешно");
    static readonly VALUES = [Confidence.ABSOLUTELY_SURE, Confidence.UNSURE, Confidence.UNSUCCESSFUL];

    name: string;
    title: string;

    constructor(name: string, title: string) {
        this.name = name;
        this.title = title;
    }
}

export class ExtractedInfoSource {

    static readonly USER_FREE_FORM = new ExtractedInfoSource("USER_FREE_FORM", "Ввод текста пользователем");
    static readonly USER_SPEC_FORM = new ExtractedInfoSource("USER_SPEC_FORM", "Выбор варианта пользвателем");
    static readonly EXTERNAL_FUNCTION = new ExtractedInfoSource("EXTERNAL_FUNCTION", "Внешняя функция");
    static readonly HANDLER_FUNCTION = new ExtractedInfoSource("HANDLER_FUNCTION", "Функция-обработчик");
    static readonly EXPERT_HIGHLIGHTING = new ExtractedInfoSource("EXPERT_HIGHLIGHTING", "Выделенное экспертом");
    static readonly EXPERT_RESULT = new ExtractedInfoSource("EXPERT_RESULT", "Проставленное руками эксперта");
    static readonly DROPPED_BY_EXPERT = new ExtractedInfoSource("DROPPED_BY_EXPERT", "Удалено руками эксперта");
    static readonly FINAL_RESULT = new ExtractedInfoSource("FINAL_RESULT", "Итоговое значение");
    static readonly PRESET = new ExtractedInfoSource("PRESET", "Был известен до попыток извлечения");
    static readonly UNKNOWN = new ExtractedInfoSource("UNKNOWN", "???");
    static readonly INIT_DIALOG_FORM = new ExtractedInfoSource("INIT_DIALOG_FORM", "Форма выбора значения атрибутов перед началом диалога");
    static readonly USER_THEN_FUNCTION = new ExtractedInfoSource("USER_THEN_FUNCTION", "Функция-обработчик на основе пользовательского ввода");
    static readonly SET_BY_SCENARIO = new ExtractedInfoSource("SET_BY_SCENARIO", "Задано в сценарии");
    static readonly VALUES = [ExtractedInfoSource.USER_FREE_FORM, ExtractedInfoSource.USER_SPEC_FORM, ExtractedInfoSource.EXTERNAL_FUNCTION, ExtractedInfoSource.HANDLER_FUNCTION, ExtractedInfoSource.EXPERT_HIGHLIGHTING, ExtractedInfoSource.EXPERT_RESULT, ExtractedInfoSource.DROPPED_BY_EXPERT, ExtractedInfoSource.FINAL_RESULT, ExtractedInfoSource.PRESET, ExtractedInfoSource.UNKNOWN, ExtractedInfoSource.INIT_DIALOG_FORM, ExtractedInfoSource.USER_THEN_FUNCTION, ExtractedInfoSource.SET_BY_SCENARIO];

    name: string;
    title: string;

    constructor(name: string, title: string) {
        this.name = name;
        this.title = title;
    }
}
