import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * Кнопка "сбросить"
 */
@Component({
    selector: 'deny-button',
    template: require('./deny-button.component.html'),
    styles: [require('./deny-button.component.less')],
})
export class DenyButtonComponent {

    /**
     * Текст на кнопке
     */
    @Input()
    text: string = 'Сбросить';

    /**
     * Обработчик нажатия
     */
    @Output()
    onDeny = new EventEmitter<void>();

    constructor() {

    }

    denyClicked() {
        this.onDeny.emit();
    }
}
