import {NgModule} from "@angular/core";

import {ChangesMasterComponent} from "./master/changes-master.component";
import {ChangesDetailsComponent} from "./details/changes-details.component";
import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {BrowserModule} from "@angular/platform-browser";
import {BoilerplateModule} from "../../va/base/boilerplate.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {CollectionModule} from "../../common/collection/collection.module";
import {EruditeNgbDatepickerModule} from "../../common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";
import {FormsModule} from "@angular/forms";
import {ChangesService} from "./changes.service";
import {JournalsMasterComponent} from "../journals-master/journals-master.component";
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        UIRouterModule,
        BoilerplateModule,
        FormControlModule,
        NgSelectModule,
        CollectionModule,
        EruditeNgbDatepickerModule,
        FormsModule,
        MatExpansionModule,
    ],
    declarations: [
        ChangesMasterComponent,
        ChangesDetailsComponent,
        JournalsMasterComponent
    ],
    exports: [
        JournalsMasterComponent
    ],
    providers: [
        ChangesService
    ]
})
export class ChangesModule {

    static states = [
        {
            name: 'journals.actions',
            url: "/actions",
            views: {
                "content@authorized": {
                    component: ChangesMasterComponent
                }
            },
            data: {
                title: "История действий"
            }
        },
        {
            name: 'journals.actions.events',
            url: "/:filter",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@journals.actions': {
                    component: ChangesDetailsComponent
                }
            },
            data: {
                title: "История действий"
            }
        }
    ];

}