import * as moment from 'moment';
/**
 * Множитель для долнительного пространтсва сверху
 */
const overlapMultiplier = 1.2;
/**
 * Множитель для вычисления позиции, куда нужно вставить подпись
 */
const widthMultiplier = 4;

/**
 * Создать конфигурацию multiChart'a
 * @param firstSeriesMaxValue   максимальное значение первой серии
 * @param secondSeriesMaxValue  максимальное значение второй серии
 */
export default function getMultiChartOptions(firstSeriesMaxValue: number, secondSeriesMaxValue: number) {
    // Небольшой запас по высоте, чтобы значения на графиках не оказались съедены
    let yAxis1MaxValue = firstSeriesMaxValue * overlapMultiplier;
    let yAxis2MaxValue = secondSeriesMaxValue * overlapMultiplier;
    return {
        chart: {
            type: 'multiChart',
            height: 225,
            margin: {
                top: 30,
                right: 70,
                bottom: 30,
                left: 70
            },
            color: ['#F7C64A', '#6196C7'],
            duration: 500,
            xAxis: {
                tickFormat: () => ''
            },
            yAxis1: {
                tickFormat: (d) => d3.format(',.0f')(d),
                axisLabel: `Количество тематик`,
                axisLabelDistance: -10,
                showMaxMin: false,
                tickValues: [0, yAxis1MaxValue]
            },
            yAxis2: {
                tickFormat: (d) => d3.format(',.0f')(d),
                axisLabel: `Количество сообщений в тематиках`,
                showMaxMin: false,
                tickValues: [0, yAxis2MaxValue]
            },
            yDomain1: [0, yAxis1MaxValue],
            yDomain2: [0, yAxis2MaxValue],
            bars1: {
                stacked: false,
                groupSpacing: 0.5,
            },
            bars2: {
                dispatch: {
                    renderEnd: (chart) => {
                        unstackBars(chart);
                        setTimeout(() => handleRenderEnd(), 200);
                    }
                },
                stacked: false,
                groupSpacing: 0.5,
            },
            legend: {
                key: (d) => d.key,
                maxKeyLength: 50
            },
            legendRightAxisHint: '',
            noData: "Нет данных"
        }
    }
}

/**
 * Сделать так, чтобы bar'ы на диаграмме не слипались в один
 * @param chart график
 */
function unstackBars(chart) {
    // Выбираем полосу на диаграмме и делим её ширину пополам
    let width = d3.select(".bars2Wrap .nv-bar").attr("width") / 2;
    if (!chart) {
        // При первой загрузке char undefined - каждой полосе по половине ширины
        d3.selectAll(".bars1Wrap .nv-bar").style("width", width);
        d3.selectAll(".bars2Wrap .nv-bar").style("width", width);
        // Перемещаем полосы, чтобы не налезали друг на друга
        d3.selectAll(".bars2Wrap .nv-bar")[0].forEach((d) => transform(d, width))
    } else if (chart.yAxis == 2 && chart.disabled) {
        // Отключен второй бар - другой делаем на всю ширину
        d3.selectAll(".bars1Wrap .nv-bar").style("width", width * 2);
    } else if (chart.yAxis == 1 && chart.disabled) {
        // Отключен первый бар - другой на всю ширину
        d3.selectAll(".bars2Wrap .nv-bar").style("width", width * 2);
    } else {
        d3.selectAll(".bars1Wrap .nv-bar").style("width", width);
        d3.selectAll(".bars2Wrap .nv-bar").style("width", width);
        d3.selectAll(".bars2Wrap .nv-bar")[0].forEach((d) => transform(d, width))
    }
}

/**
 * Добавить значения на столбики и подписи по оси X
 */
function handleRenderEnd() {
    // Выставляем значение для баров
    let multiBarSelection = d3.selectAll(`.multiChart .nv-multibar .nv-group`);
    setLabelsToMultiChart(multiBarSelection, `bar-values`, null, widthMultiplier,
        (bar) => bar.y,
        (bar) => parseFloat(bar.attr(`y`)) - 5,
        false);
    // Добавляем руками подписи по оси
    let xAxis = d3.select(`.multiChart .nv-x .nv-axis`);
    xAxis.selectAll(`.bar-titles`).remove();
    let multiplier = getMultiplier(multiBarSelection);
    setLabelsToMultiChart(multiBarSelection, `bar-titles`, xAxis, multiplier,
        (bar) => moment(bar.x).format("DD.MM HH:mm"),
        () => 20.0,
        true);
    // Крутим подпись по x
    let yAxis2Label = d3.select('.multiChart .nv-y2 .nv-axis .nv-axislabel');
    yAxis2Label.attr("transform", "rotate(-90)")
        .attr('y', 50)
        .attr('x', 10 - yAxis2Label.node().getBBox().width / 2);

    // Убираем лишние полосы
    d3.selectAll(`.multiChart .nv-axis .tick line`).remove();
}

/**
 *
 * @param selection      d3 выборка из html-element'ов
 * @param cssClass       класс
 * @param appendElement  элемент к которому будем прикреплять текст
 * @param multiplier     множитель (для определения сдвига)
 * @param valueGetter    функция для получения отображаемого значения
 * @param yAxisGetter    функция для получения значения координаты элемента по y
 * @param single         не добавляем значения для серии, где уже были
 */
function setLabelsToMultiChart(selection, cssClass, appendElement, multiplier, valueGetter, yAxisGetter, single) {
    let visitedBars = [];
    // Идём по выборке
    selection.each((group, index) => {
        // Получаем конкретный элемент
        let gElement = d3.select(selection[0][index]);
        // Возможно нам его нужно удалить
        if (!appendElement) {
            gElement.selectAll('text').remove();
        }
        // Берём выборку полос
        let barSelection = gElement.selectAll('.nv-bar');
        barSelection.each((barElement, barIndex) => {
            // Проверяем записывали ли дня неё значение уже
            if (single && visitedBars.indexOf(barElement.x) != -1) {
                return;
            }
            let bar = d3.select(barSelection[0][barIndex]);
            let barWidth = bar.attr('width');
            // Определяем к какому элементу будем крепиться
            let parent = appendElement ? appendElement : gElement;
            let text = parent.append('text')
                .text(valueGetter(barElement));
            text
                .attr('transform', bar.attr('transform'))
                .attr('y', yAxisGetter(bar))
                .attr('x', parseFloat(bar.attr('x')) + (parseFloat(barWidth) / multiplier) - (text.node().getBBox().width / 2))
                .attr('fill', 'rgba(0,0,0,1)')
                .attr('stroke', 'rgba(0,0,0,0)')
                .attr('style', 'font-weight: bold; font: normal 12px Arial')
                .attr('class', cssClass);
            // Запоминаем, что уже были
            visitedBars.push(barElement.x);
        });
    });
}

/**
 * Получить множитель для сдвига
 * @param multiBarSelection  выборка
 */
function getMultiplier(multiBarSelection) {
    return multiBarSelection[0]
        .map((group, index) => {
            let gElement = d3.select(multiBarSelection[0][index]);
            let barSelection = gElement.selectAll('.nv-bar');
            return barSelection.pop().length == 0 ? 3 : 1
        })
        .reduce((prev, next) => prev + next, 0);
}

/**
 * Задать элементу transfrom
 * @param element  элемент
 * @param width    ширина
 */
function transform(element, width) {
    let translate = d3.transform(d3.select(element).attr("transform")),
        x = translate.translate[0] + width,
        y = translate.translate[1];
    d3.select(element).attr("transform", "translate(" + x + "," + y + ")");
}