import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {VersionedDataService} from "../../base/versioned-data.service";
import {VaTBMessageCluster} from "../../../../../data/va/Message";
import {AppliedEntityType} from "../../../entity-usage/entity-usages.model";
import WebSocketService from "../../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {NamedEntitiesModel} from "../../model/named-entities.model";


@Injectable()
export class ClusterEditService extends VersionedDataService<VaTBMessageCluster, number> {

    baseUrl: string = "/account/expert/tagbuilding";

    usageEntityType: AppliedEntityType = AppliedEntityType.TAG;

    readonly entityClass: NamedEntitiesModel.VA_MESSAGE;


    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    /**
     * Edit requests
     */
    async getStates(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/states`).toPromise();
    }

    async getAccessForEdit(): Promise<boolean> {
        return await this.httpClient.get<boolean>(`${this.baseUrl}/access`).toPromise();
    }

    async getClusterMessages(objId: string): Promise<VaTBMessageCluster[]> {
        let id = Number.parseInt(objId);
        return await this.httpClient.get<VaTBMessageCluster[]>(`${this.baseUrl}/${id}/messages`).toPromise();
    }

    async getDialog(dialogId: number): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/dialog/${dialogId}`).toPromise();
    }

    async isInProgress(objId: string, inProcess: boolean) {
        return await this.httpClient.get(`${this.baseUrl}/inProcess/${objId}/${inProcess}`).toPromise();
    }

    async deleteMessage(id: any) {
        return await this.httpClient.get(`${this.baseUrl}/delete/${id}`).toPromise()
    }

    async clearTag(id: any) {
        return await this.httpClient.get(`${this.baseUrl}/clearTag/${id}`).toPromise()

    }

    async getTBList(sourceTagId: number): Promise<VaTBMessageCluster[]> {
        return await this.httpClient.post<VaTBMessageCluster[]>(`${this.baseUrl}`, {sourceTagId}).toPromise();
    }

    /**
     * Show dialog requests
     */


    async getDialogMessages(id: number): Promise<VaTBMessageCluster[]> {
        return await this.httpClient.get<VaTBMessageCluster[]>(`${this.baseUrl}/dialog/${id}/messages`).toPromise()
    }

    async removeReplyTag(id: any): Promise<any> {
        return await this.httpClient.post<any[]>(`${this.baseUrl}/removeReplyTag/${id}`, {}).toPromise()
    }


}
