import RectangleElement from "./RectangleElement";
import {VaScriptNode} from "../../../../../data/va/Script";
import {applyMixins} from "../../ScriptBuilderUtils";
import {FormulableElement} from "../interface/FormulableElement";
import {CheckpointableElement} from "../interface/CheckpointableElement";
import {dia, ui} from "@naumen/rappid/build/rappid";
import {ClickMode} from "../EruditeElement";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {ElementAttributeId, setElementAttribute} from "../../controller/ScriptAttributeUtils";

export class RerouteElement extends RectangleElement {

    constructor(attributes: { node: VaScriptNode }) {
        super(attributes);

        mergeDeep(this.attributes, {
            attrs: {
                body: {
                    fill: "#F80077",
                    stroke: "#F80077"
                },
                rerouteParamText: {
                    fill: 'rgba(255, 255, 255, 0.6)',
                    textAnchor: "middle",
                    fontSize: 14,
                    fontWeight: 'bold',
                    refX: '50%',
                    refY: 0,
                    ref: 'rerouteParamWrapper'
                },
                rerouteParamWrapper: {
                    fill: 'rgba(0, 0, 0, 0)',
                    refY: '110%',
                    refX: '50%',
                    ref: 'label',
                    height: 35,
                },
                label: {
                    fill: "#FFFFFF"
                }
            }
        });

        this.markup.push({
            tagName: 'rect',
            selector: 'rerouteParamWrapper',
        },
            {
            tagName: 'text',
            selector: 'rerouteParamText',
        });

        this.updateRerouteParamTextInElement(this.node.stepLabel.commandParamsText);
        this.addCheckpointMarkUp();
        this.addFormulationMarkUp();
    }

    set stepCommandParams(commandParams: string) {
        this.node.stepLabel.commandParams = commandParams;
        setElementAttribute(this, ElementAttributeId.COMMAND_PARAMS, commandParams);
    }

    set stepCommandParamsText(commandParamsText: string) {
        this.node.stepLabel.commandParamsText = commandParamsText;
        this.updateRerouteParamTextInElement(commandParamsText);
    }

    onElementClick(elementView: dia.ElementView, mode: ClickMode): ui.Halo {
        switch (mode) {
            case ClickMode.DEFAULT:
                return this.onFormulableElementClick(elementView, mode);
            case ClickMode.CHECKPOINT:
                return this.onCheckpointableElementClick();
        }
    }

    public isNavigable(): boolean {
        return true;
    }
}

export interface RerouteElement extends FormulableElement, CheckpointableElement {
}

applyMixins(RerouteElement, [FormulableElement, CheckpointableElement]);