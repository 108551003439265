import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EruditeIconComponent} from "./erudite-icon.component";
import {AngularSvgIconModule} from "angular-svg-icon";

@NgModule({
    declarations: [
        EruditeIconComponent
    ],
    imports: [
        CommonModule,
        AngularSvgIconModule
    ],
    exports: [
        EruditeIconComponent
    ]
})
export class EruditeIconModule {

}