import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {VaAsrComponent} from "./va-asr.component";
import {FormControlModule} from "../form/form-control.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {ProjectService} from "../../project/project.service";
import {MatInputModule} from "@angular/material/input";

@NgModule({
    declarations: [
        VaAsrComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        FormControlModule,
        NgSelectModule,
        MatInputModule
    ],
    exports: [
        VaAsrComponent
    ],
    providers: [
        ProjectService
    ]
})
export class VaAsrModule {

}