import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import * as urls from "../../../../../../../js/workplace/urls";
import {HttpClient, HttpParams} from "@angular/common/http";
import {VaTag} from "../../../../../data/va/Tag";
import {DialogCorrectionDTO, VaExtractionContext} from "../../../dialog/model/correction.model";
import {VaAttribute} from "../../../../../data/va/Attribute";
import {ConfirmInfoType} from "../../../dialog/model/extracted-value.model";

@Component({
    selector: 'attempt-edit',
    template: require('./attempt-edit.component.html'),
    styles: [require('./attempt-edit.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class AttemptEditComponent implements OnInit {

    /**
     * Корректировка
     */
    @Input()
    correction: DialogCorrectionDTO;

    /**
     * Id тематики у текущей реплики
     */
    @Input()
    tagId;

    /**
     * Тематики
     */
    tags: VaTag[];

    /**
     * Атрибуты
     */
    attributes: VaAttribute[];

    /**
     * Контексты извлечений
     */
    contexts: VaExtractionContext[];

    /**
     * Типы подтверждаемых сущнсотей
     */
    confirmInfoTypes: ConfirmInfoType[];

    /**
     * Флажок загрузки
     */
    tagIsLoading: boolean = true;

    constructor(private http: HttpClient, private changeDetector: ChangeDetectorRef) {
    }

    async ngOnInit(): Promise<void> {
        let currentTagId;
        if (this.correction.extractionAttempt?.tag) {
            currentTagId = this.correction.extractionAttempt.tag.key.id;
            this.tagId = currentTagId;
        } else {
            currentTagId = this.tagId;
        }
        this.http.get<any>(`${urls.va.correction}/extractedValueCorrectionData`, {
            params: currentTagId ? new HttpParams().set('tagId', currentTagId) : null
        }).toPromise().then(data => {
            this.attributes = data.attributes;
            this.contexts = data.contexts;
            this.confirmInfoTypes = data.confirmInfoTypes;
            this.changeDetector.markForCheck();
        });
    }

    get loaded() {
        return !this.tagIsLoading && this.contexts != null && this.confirmInfoTypes != null;
    }

    onReady() {
        this.tagIsLoading = false;
    }

    async onTagSelect(tag: VaTag) {
        this.attributes = null;
        this.tagId = tag.key.id;
        this.correction.extractionAttempt.tag = tag;
        let data: { attributes: VaAttribute[] } = await this.http.get<any>(`${urls.va.correction}/extractedValueCorrectionData`, {
            params: new HttpParams().set('tagId', tag.key.id.toString())
        }).toPromise();
        this.attributes = data.attributes;
    }
}