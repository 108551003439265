import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseDataService} from "../va/base/base-data.service";
import * as urls from "../../../../../js/workplace/urls";
import {VAProjectVersion} from "../../../data/va/VAProjectVersion";
import WebSocketService from "../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {NamedEntitiesModel} from "../va/model/named-entities.model";
import {ProjectExportParams} from "../project/project.model";


@Injectable()
export class ProjectVersionService extends BaseDataService<VAProjectVersion, string> {

    baseUrl: string = `${urls.va.projectVersion}`;

    readonly entityClass = NamedEntitiesModel.VA_PROJECT_VERSION;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getVersionsOfProject(): Promise<VAProjectVersion[]> {
        return await this.httpClient.get<VAProjectVersion[]>(`${this.baseUrl}/versions_of_project`).toPromise();
    }


    async isExportInProgress(projectVersionId: string): Promise<boolean> {
        if (!projectVersionId) {
            return false;
        }
        return await this.httpClient.get<boolean>(`${this.baseUrl}/isExportInProgress`, {params: {projectVersionId: projectVersionId}}).toPromise();
    }

    async export(exportParams: ProjectExportParams): Promise<any> {
        return await this.httpClient.get(`${this.baseUrl}/export`, {
            params: new HttpParams()
                .set('projectVersionId', exportParams.projectVersionId)
                .set('withModels', exportParams.withModels + "")
                .set('withDialogs', exportParams.withDialogs + "")
                .set('withAudioFiles', exportParams.withAudioFiles + "")
                .set('withTranscription', exportParams.withTranscription + "")
                .set('dialogDateRange', JSON.stringify(exportParams.dialogDateRange))
        }).toPromise();
    }
}