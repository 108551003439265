import {Component, EventEmitter, HostListener, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {StepLabelEntity, StepLabelType, StepLabelTypeName, VaScriptEdge, VaScriptNode} from "../../../../data/va/Script";
import {ValueOption} from "../../../../data/va/Extractor";
import {EruditeElement} from "../element-view/EruditeElement";
import {EditableForm, EditableItemData} from "../../common/editable-list/editable-list.model";
import {ExtractedValueType} from "../../dialog/model/extracted-value.model";
import {VaScriptCondition} from "../../../../data/va/VaScriptCondition";


@Component({
    selector: 'edge-condition',
    template: require('./edge-condition.component.html'),
    styles: [require('./edge-condition.component.less')]
})

export class EdgeConditionComponent {

    /**
     * Форма для условий
     */
    form: EditableForm<VaScriptCondition>;

    /**
     * Мапа с исходниками, которые нужны внутри объекта
     */
    sources: Map<string, any> = new Map<string, any>();

    hasCondition: boolean;                                              
    editableItemData: EditableItemData = new EditableItemData("", true, true, true, false, false, false);

    private showAllClicked = false;

    constructor(public dialogRef: MatDialogRef<EdgeConditionComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    edge: VaScriptEdge, sourceElement: EruditeElement,
                    entities: { type: StepLabelType, entities: StepLabelEntity[] }[], closeEvent: EventEmitter<void>
                }) {
        this.sources.set(SourcesKey.SOURCE_ELEMENT, this.data.sourceElement);
        this.sources.set(SourcesKey.CURRENT_EDGE, this.data.edge);
        this.form = new EditableForm(VaScriptCondition.newInstance(this.data.sourceElement));
        this.hasCondition = this.data.edge.conditions.filter(condition => !condition.isUnconditionalStep()).length > 0;
        this.prepareAttributes();
        this.dialogRef.backdropClick().subscribe(() => this.data.closeEvent.emit());
    }

    get edge(): VaScriptEdge {
        return this.sources.get(SourcesKey.CURRENT_EDGE);
    }

    onDeny(): void {
        this.data.closeEvent.emit();
    }

    /**
     * Действия при выборе атрибута - надо обновить список vo
     */
    onAttributeSelect(): void {
        this.showAllClicked = false;
        this.form.objectForm.entityId = this.form.objectForm.entity.key.id;
        this.form.objectForm.values = [];
        this.form.objectForm.options = [];
        this.form.objectForm.setExpressionData();
        EdgeConditionComponent.filterAvailableAttributes(this.form.objectForm, this.sources);
        EdgeConditionComponent.filterAvailableValueOptions(this.form.objectForm, this.sources);
    }

    /**
     * Действия при выборе атрибута - надо обновить список vo
     */
    onValuesChange(): void {
        this.form.objectForm.buildValuesByValuesEntities();
        if (this.form.objectForm.type !== ExtractedValueType.PROCEDURE) {
            EdgeConditionComponent.filterAvailableValueOptions(this.form.objectForm, this.sources, this.showAllClicked);
        }
    }

    /**
     * Действия при выборе атрибута - надо обновить список vo
     */
    onShowAllOptionClick(): void {
        this.showAllClicked = true;
        EdgeConditionComponent.filterAvailableValueOptions(this.form.objectForm, this.sources, this.showAllClicked);
    }

    /**
     * Переключить режим "Все значения, кроме"
     */
    changeExceptMode(): void {
        this.form.objectForm.except = !this.form.objectForm.except;
        EdgeConditionComponent.filterAvailableValueOptions(this.form.objectForm, this.sources, this.showAllClicked);
        this.form.objectForm.options = [];
    }

    /**
     * Собрать список атрибутов, по которым можно сделать развилку на данный момент
     */
    prepareAttributes(): void {
        // Находим все атрибуты с внешним API ключом
        const attributes = this.data.entities
            .filter(entity => entity.type.name == StepLabelTypeName.ATTRIBUTE_REQUEST)
            .pop()
            .entities;
        this.sources.set(SourcesKey.ATTRIBUTES, attributes);
    }

    /**
     * Отфильтровать из списка всех атрибутов те, которые уже используются в условиях
     */
    public static filterAvailableAttributes(condition: VaScriptCondition, sources: Map<string, any>): void {
        const edgeConditions = sources.get(SourcesKey.CURRENT_EDGE).conditions;
        if (!edgeConditions) {
            condition.attributes = sources.get(SourcesKey.ATTRIBUTES);
        } else {
            condition.attributes = sources.get(SourcesKey.ATTRIBUTES)
                .filter(attribute => !edgeConditions.find(edgeCondition =>
                    edgeCondition.entityId != condition.entityId && edgeCondition.entityId + "" == attribute.key.id));
        }
        // Функция для фильтрации выдачи списка атрибутов:
        // сначала идёт тот атрибут, ОТ узла с которым идёт добавляемое ребро
        const sourceEntityStepLabel = sources.get(SourcesKey.SOURCE_ELEMENT).node.stepLabel;
        condition.attributes = condition.attributes.sort((a: StepLabelEntity, b: StepLabelEntity) => {
            if (sourceEntityStepLabel.type.name !== StepLabelTypeName.START) {
                if (a.key.id === sourceEntityStepLabel.entityId + "") {
                    return -1;
                }
                if (b.key.id === sourceEntityStepLabel.entityId + "") {
                    return 1;
                }
            }
            return a.title.localeCompare(b.title);

        });
    }

    /**
     * Отфильтровать список выходных нод, показываем только неиспользованные
     */
    public static filterAvailableExitNodes(condition: VaScriptCondition, sources: Map<string, any>): void {
        this.filterExitNodes(condition, sources.get(SourcesKey.CURRENT_EDGE), sources.get(SourcesKey.SOURCE_ELEMENT));
    }

    /**
     * Отфильтровать список выходных нод, показываем только неиспользованные
     */
    public static filterExitNodes(condition: VaScriptCondition, currentEdge: VaScriptEdge, sourceElement: EruditeElement): void {
        // @ts-ignore
        let exitNodes: VaScriptNode[] = angular.copy(condition.entity.options);
        const filteredConditions = EdgeConditionComponent.getEntityConditions(condition, currentEdge, sourceElement);
        // Если нет пересечений по условиям, то вернём весь список exit nodes
        if (filteredConditions.length == 0) {
            condition.availableValueEntities = exitNodes;
            return;
        }
        condition.availableValueEntities = exitNodes
            .filter(node => !filteredConditions.find(condition => condition.values[0] == node.key.id));
    }

    /**
     * Отфильтровать список valueOption'ов, показываем изначально только неиспользованные
     */
    public static filterAvailableValueOptions(condition: VaScriptCondition, sources: Map<string, any>, showAll?: boolean): void {
        // @ts-ignore
        let options: ValueOption[] = angular.copy(condition.entity.options);
        if (showAll) {
            // выбрана опция показать все
            condition.availableValueEntities = options;
            condition.showAllOption = false;
            return;
        }
        const filteredConditions = EdgeConditionComponent.getEntityConditions(condition,
            sources.get(SourcesKey.CURRENT_EDGE),
            sources.get(SourcesKey.SOURCE_ELEMENT));
        // Если нет пересечений по условиям, то вернём весь список valueOption'ов
        if (filteredConditions.length == 0) {
            condition.availableValueEntities = options;
            return;
        }
        condition.availableValueEntities = options.filter(option => {
            // Обработаем каждое условие: закроем из списка опций использованные
            let useOptionCondition = filteredConditions.find(filteredCondition =>
                // Ищем индексы valueOption'ов, у которых такой же id или name как у values из filteredCondition'a
                filteredCondition.values.filter(value => option.title === value || option.key && option.key.id + "" === value).length > 0
            );
            if (!useOptionCondition) {
                // Если режим except = кроме указанных, то нужно закрыть все, кроме использованных
                return !condition.except;
            } else {
                // Если режимы except совпадают, то нужно закрыть уже использованные valueOption'ы
                return condition.except !== useOptionCondition.except;
            }
        });
        // если есть, что надо закрыть
        condition.showAllOption = condition.availableValueEntities.length !== options.length;
    }

    /**
     * Найти все кондишены для выбранной сущности из этого узла
     */
    public static getEntityConditions(currentCondition: VaScriptCondition,
                                      currentEdge: VaScriptEdge,
                                      sourceElement: EruditeElement): VaScriptCondition[] {
        // Список рёбер из этого узла
        const fromNodeEdges: VaScriptEdge[] = sourceElement.outgoingEdges
            .filter(edge => currentEdge.fromNodeId === edge.fromNodeId && currentEdge.toNodeId !== edge.toNodeId);

        // Все условия на рёбрах из этого узла
        const allConditions: VaScriptCondition[] = fromNodeEdges
            .map(edge => edge.conditions)
            .reduce((previous, next) => previous.concat(next), []);
        // Условия только по выбранной сущности
        return allConditions
            .filter(condition => condition.entityId == currentCondition.entityId);
    }

    searchByTitleFn(term: string, item: StepLabelEntity): boolean {
        return item.title.toLowerCase().indexOf(term.toLowerCase()) > -1;
    }

    trackByIdFn(item: StepLabelEntity): string {
        return item.key.id;
    }

    @HostListener('document:keydown', ['$event'])
    onKeyDownHandler(event: KeyboardEvent) {
        if (event.key === "Escape") {
            this.onDeny();
        }
    }

}

export enum SourcesKey {
    ATTRIBUTES = "ATTRIBUTES",
    SOURCE_ELEMENT = "SOURCE_ELEMENT",
    CURRENT_EDGE = "CURRENT_EDGE",
}
