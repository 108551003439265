import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FileItem} from "../file-uploader/uploader-vendor/file-item.class";
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "../snackbar/notification/notification.service";

@Component({
    selector: 'attachment-dialog',
    template: require('./attachment-dialog.component.html'),
    styles: [require('./attachment-dialog.component.less')]
})

export class AttachmentDialogComponent {

    maxFileSize = Math.pow(10, 7);

    denominator = Math.pow(10, 6);

    checkSize: boolean = false;

    attachmentsCount: number = 1;

    /**
     * Вложения
     */
    attachments: File[] = [];

    /**
     * Данные о загрузке для прогресс-бара
     */
    public isLoading: boolean;

    multiple: boolean = true;

    maxAttachments: number;

    title: string = 'Загрузка вложений';
    allowedMimeTypes: string[];

    /**
     * Родительский async метод загрузки файла
     * Возвращает пойманные ошибки
     */
    uploadMethod: (formData: FormData, dialogRef: MatDialogRef<AttachmentDialogComponent>) => any;

    public form: any = {
        success: false,
        errors: new Map<string, string>()
    };

    constructor(private httpClient: HttpClient,
                private notificationService: NotificationService,
                public dialogRef: MatDialogRef<AttachmentDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    attachmentsCount: number,
                    uploadMethod: (formData: FormData, dialogRef: MatDialogRef<AttachmentDialogComponent>) => any,
                    title: string,
                    maxAttachments: number,
                    multiple: boolean,
                    checkSize: boolean,
                    denominator: number,
                    maxFileSize: number,
                    allowedMimeTypes: string[]
                }) {
        this.isLoading = true;
        this.attachmentsCount = this.data.attachmentsCount;
        this.setIfNotNull('uploadMethod', this.data.uploadMethod);
        this.setIfNotNull('title', this.data.title);
        this.setIfNotNull('maxAttachments', this.data.maxAttachments);
        this.setIfNotNull('multiple', this.data.multiple);
        this.setIfNotNull('checkSize', this.data.checkSize);
        this.setIfNotNull('denominator', this.data.denominator);
        this.setIfNotNull('maxFileSize', this.data.maxFileSize);
        this.setIfNotNull('allowedMimeTypes', this.data.allowedMimeTypes);
        this.isLoading = false;
    }

    setIfNotNull(fieldName, parameter) {
        if (parameter != null) {
            this[fieldName] = parameter;
        }
    }

    /**
     * Загрузка вложений
     */
    async upload(): Promise<void> {
        const slots = this.maxAttachments - this.attachmentsCount - this.attachments.length;
        // не даём загружать слишком много
        if (slots < 0) {
            this.onFileAddFailed(`Ограничение на количество вложений ${this.maxAttachments} штук`);
            return;
            // не пытаемся загрузить при отсутствии файлов
        } else if (this.attachments.length == 0) {
            this.onFileAddFailed('Не выбраны вложения для загрузки!');
            return;
            // не грузим, если файл больше 10 мб
        } else if (this.checkSize && this.attachments.some((file: File) => file.size >= this.maxFileSize)) {
            const names = this.attachments.map(file => file.name);
            const message = names.length > 1 ? `ы ${names.join(", ")}` : ` ${names.join()}`;
            const size = this.maxFileSize / this.denominator;
            this.onFileAddFailed(`Файл${message} превысили допустимый размер ${size} МБ`);
            return;
        }
        // проставляем данные, грузим
        const formData = new FormData();
        this.attachments.forEach(file => formData.append('files', file, file.name));
        this.isLoading = true;

        const exception = await this.uploadMethod(formData, this.dialogRef)
        if (exception?.error) {
            this.notificationService.error(exception.error.message);
        } else if (exception?.errors) {
            exception.errors.forEach(error => this.notificationService.error(error?.message));
        }
        this.isLoading = false;
    }

    onDeny(): void {
        this.dialogRef.close();
    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     * @param $event
     */
    onFileAddFailed($event: string) {
        this.notificationService.error($event);
    }

    /**
     * Есть ли слоты для загрузки вложений?
     */
    hasSlots(): boolean {
        return this.maxAttachments != this.attachmentsCount;
    }

    /**
     * Заголовок для загрузчика вложений
     */
    loaderTitle(): string {
        if (this.multiple == false) {
            return null;
        }
        const slots = this.maxAttachments - this.attachmentsCount;
        return slots > 0 ? `Доступно для загрузки: ${slots}` : `Загружено максимальное количество вложений!`
    }

    /**
     * Обработчик добавления или удаления файлов
     * @param items файлы
     */
    filesAdded(items: FileItem[]) {
        this.attachments = items == null ? [] : items.map(item => item._file);
    }
}