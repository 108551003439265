import {NgModule} from "@angular/core";
import {BoilerplateModule} from "../base/boilerplate.module";
import {CollectionModule} from "../../common/collection/collection.module";
import {UIRouterModule} from "@uirouter/angular";
import {CommonModule} from "@angular/common";
import {ExpertModule} from "../../common/expert/expert.module";
import {ScriptEditComponent} from "../../script-builder/script-edit.component";
import {FormControlModule} from "../../common/form/form-control.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {FormsModule} from "@angular/forms";
import {ProcedureMasterComponent} from "./procedure-master.component";

@NgModule({
    declarations: [
        ProcedureMasterComponent
    ],
    imports: [
        BoilerplateModule,
        CollectionModule,
        UIRouterModule,
        CommonModule,
        ExpertModule,
        FormControlModule,
        EntityUsageModule,
        FormsModule
    ],
    exports: [
        ProcedureMasterComponent
    ]
})
export class ProcedureModule {
    static states = [
        {
            name: 'robot.script_procedure',
            url: "/script_procedure",
            views: {
                "content@authorized": {
                    component: ProcedureMasterComponent
                }
            },
            data: {
                title: "Процедуры",
                requiredProjectVersion: true,
                details: "robot.script_procedure.view",
                meta: {
                    parameters: [],
                    sectionFunction: ([]) => "Обучение робота",
                    subSectionFunction: ([]) => "Процедуры"
                },

            }
        },
        {
            name: 'robot.script_procedure_edit',
            url: "/robot.script_procedure_edit/:type/:procedureId?tagId&nodeId&caseScenarioId&caseId",
            views: {
                "content@authorized": {
                    component: ScriptEditComponent
                }
            },
            data: {
                title: "Процедура",
                newObjectTitle: "Новая процедура",
                successfulSaveMessage: "Процедура сохранена",
                details: "",
                meta: {
                    parameters: ["procedureId"],
                    sectionFunction: ([]) => "Обучение робота",
                    subSectionFunction: ([scriptId]) => scriptId ? "Редактирование процедуры" : "Новая процедура"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ]
}