import RectangleElement from "./RectangleElement";
import {VaScriptNode} from "../../../../../data/va/Script";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {dia} from "@naumen/rappid/build/rappid";
import {STROKE_DASH} from "../../ScriptBuilderUtils";


export class ExitElement extends RectangleElement {

    constructor(attributes: {node: VaScriptNode}) {
        super(attributes);

        mergeDeep(this.attributes, {
            attrs: {
                body: {
                    fill: "#F8F8F8",
                    stroke: "#D53300",
                    strokeDasharray: STROKE_DASH
                },
                label: {
                    fill: "#D53300",
                }
            }
        });
    }


    get hasExternalLink(): boolean {
        return false;
    }

    /**
     * У выходного узла нет перехода к сущности
     * @param elementView
     */
    onContextMenu(elementView: dia.ElementView) {

    }
}