import {SmaModule} from "../components/va/sma/sma.module";
import {AnswerModule} from "../components/va/answer/answer.module";
import {AudioRecordModule} from "../components/media-libraries/audio-record/audio-record.module";
import {AttributeModule} from "../components/va/attribute/attribute.module";
import {ScenariosModule} from "../components/va/scenario/scenarios.module";
import {TextDataModule} from "../components/va/text-data/text-data.module";
import {ProjectModule} from "../components/project/project.module";
import {ProjectVersionModule} from "../components/project-version/project-version.module";
import {ExtractorModule} from "../components/va/extractor/extractor.module";
import {SettingsModule} from "../components/va/settings/settings.module";
import {ProcedureModule} from "../components/va/procedure/procedure.module";
import {ModelModule} from "../components/va/model/model.module";
import {CustomizationScriptModule} from "../components/va/customization-script/customization-script.module";
import {TranscriptionModule} from "../components/va/transcription/transcription.module";
import {DialogStatModule} from "../components/dialog-stat/dialog-stat.module";
import {ChatModule} from "../components/chat/chat.module";
import {TagModule} from "../components/va/tag/tag.module";
import {AttemptModule} from "../components/va/attempt/attempt.module";
import {AccountModule} from "../components/va/account/account.module";
import {FileModule} from "../components/va/file/file.module";
import {NotificationModule} from "../components/common/snackbar/notification/notification.module";
import {TagBuildingModule} from "../components/va/tagbuilding/tagbuilding.module";
import {JournalsModule} from "../components/journals/journals.module";
import {ChangesModule} from "../components/journals/changes/changes.module";
import {LoginsModule} from "../components/journals/logins/logins.module";
import {ReportsModule} from "../components/reports/reports.module";
import {MlModule} from "../components/va/ml/ml.module";
import {LicenseModule} from "../components/license/license.module";

const states = [
    ...AccountModule.states,
    ...AnswerModule.states,
    ...AttemptModule.states,
    ...AttributeModule.states,
    ...AudioRecordModule.states,
    ...ChangesModule.states,
    ...ChatModule.states,
    ...CustomizationScriptModule.states,
    ...DialogStatModule.states,
    ...ExtractorModule.states,
    ...FileModule.states,
    ...JournalsModule.states,
    ...LoginsModule.states,
    ...MlModule.states,
    ...LicenseModule.states,
    ...ModelModule.states,
    ...NotificationModule.states,
    ...ProcedureModule.states,
    ...ProjectModule.states,
    ...ProjectVersionModule.states,
    ...ReportsModule.states,
    ...ScenariosModule.states,
    ...SettingsModule.states,
    ...SmaModule.states,
    ...TagModule.states,
    ...TagBuildingModule.states,
    ...TextDataModule.states,
    ...TranscriptionModule.states,
];
export default states;