import {Component, Input, KeyValueDiffer, KeyValueDiffers, OnInit, SimpleChanges} from '@angular/core';
import {MacroPreview, MacroPreviewFile} from "./object/MacroPreview";
import {ChannelText} from "../../../../data/va/Formulation";
import {Observable, of, zip} from "rxjs";
import {Valued} from "../../../../data/va/Valued";
import {Macro, MacroTypeEnum} from "./object/Macro";
import {Track} from "../player/track.model";
import {AudioRecordService} from "../../media-libraries/audio-record/audio-record.service";
import {TextToSpeechService} from "../../media-libraries/text-to-speech/text-to-speech.service";
import {VaTtsSettings} from "../../../../data/va/Voice";


@Component({
    selector: 'macro-preview',
    template: require('./macro-preview.component.html'),
    styles: [require('./macro.component.less')]
})

export class MacroPreviewComponent implements OnInit {

    /**
     * Объект, в который надо записать данные
     */
    @Input()
    private channelText: ChannelText;

    /**
     * Можно ли редактировать
     */
    @Input()
    disabled: boolean;

    @Input()
    searchString: string;

    /**
     * Данные по tts
     */
    @Input()
    ttsObject: { settings: VaTtsSettings; enabled: boolean };

    /**
     * Данные для предпросмотра текста с макросами
     */
    previewItems: MacroPreview[];
    /**
     *  File по тексту (ид макроса или текст для ттс) - держим сдесь, чтоы не перезагружать при  лом изменении
     */
    private macroPreviewFiles: MacroPreviewFile[] = [];
    /**
     * Треки для плеера
     */
    playlist: Track[] = [];

    private customerDiffer: KeyValueDiffer<string, any>;

    /**
     * Файл, который надо проиграть
     */
    playableAudioFile: any;

    isPlayAllFiles: boolean = false;

    constructor(private differs: KeyValueDiffers, private audioRecordService: AudioRecordService, private textToSpeechService: TextToSpeechService) {

    }

    ngOnInit(): void {
        this.buildPreviewItems();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.channelText) {
            let channelText = changes.channelText.currentValue;
            if (channelText) {
                if (this.channelText.macros) {
                    this.channelText.macros = this.channelText.macros.map(object => new Macro(object));
                }
                // когда загрузится объект, начнем следить за его полями
                this.customerDiffer = this.differs.find(this.channelText).create();
                this.buildPreviewItems();
            }
        }
    }

    ngDoCheck(): void {
        if (this.customerDiffer) {
            const changes = this.customerDiffer.diff(this.channelText);
            if (changes) {
                changes.forEachChangedItem(item => {
                    // при изменении текста - пересоберем превью
                    if (item.key == 'text') {
                        this.buildPreviewItems();
                    }
                });
            }
        }
    }

    /**
     * Перестроить данные для превью
     */
    buildPreviewItems(): void {
        this.getPreviewItems().subscribe(value => {
            this.previewItems = value;
        });
    }

    /**
     * Собрать плейлист
     */
    private buildPlaylist() {
        return this.previewItems
            .filter(preview => preview.file)
            .map((preview, index) => {
                let trackName = preview.macro?.audioRecord ? preview.macro.audioRecord.text : `${preview.file.text}${index}`;
                return new Track(index, trackName, preview.file.file)
            });
    }

    /**
     * Сгенерировать список данных для превью
     */
    getPreviewItems(): Observable<MacroPreview[]> {
        if (!this.channelText.text) {
            return of([]);
        }
        // разбили помакросно
        const macroTexts: string[] = this.channelText.text
            .split(new RegExp("[${|}]"))
            .filter(text => text !== "");
        let charAmount = 0;
        const observables: Observable<MacroPreview>[] = [];
        macroTexts.forEach(macroText => {
            // \n выделим в отдельный объект списка, потому что плеер надо рисовать до перевода строки
            const textItems = macroText.split(new RegExp("\n"));
            const textItemsAmount = textItems.length;
            const needBr = textItemsAmount > 1;
            textItems.forEach((textItem, index) => {
                if (textItem !== "") {
                    let previewItem: MacroPreview = new MacroPreview(textItem, charAmount);
                    if (this.channelText.macros) {
                        previewItem.macro = this.channelText.macros.find(macro => macro.key.id === textItem);
                    }
                    charAmount += textItem.length;
                    this.setFile(previewItem);
                    observables.push(of(previewItem));
                }
                if (needBr && index !== textItemsAmount - 1) {
                    // у последнего не надо ставить перевод строки
                    observables.push(of(new MacroPreview("<br/>", charAmount)));
                }
            });
        });

        return zip(...observables);
    }

    isVoice(): boolean {
        return this.channelText.channelValueOption.apiKey === Valued.VOICE_API_KEY || this.channelText.channelValueOption.apiKey === Valued.ASSISTANT_VOICE_API_KEY;
    }

    async setFile(preview: MacroPreview, synthesize?: boolean): Promise<void> {
        if (!this.isVoice() || this.disabled) {
            // если канал не голосовой, то проигрывать ничего нельзя
            return;
        }
        if (preview.macro) {
            switch (preview.macro.type.name) {
                case MacroTypeEnum.MARKUP:
                    const attributeCondition: boolean = preview.macro.conditions
                        && preview.macro.conditions[MacroTypeEnum.ATTRIBUTE.toLocaleLowerCase()] != null;
                    // если в разметке есть attribute, то проиграть нельзя
                    if (this.ttsObject?.enabled && !attributeCondition) {
                        //todo tts get file
                        // audioFile = ???
                    }
                    break;
                case MacroTypeEnum.AUDIO_RECORD:
                    if (preview.macro.audioRecord?.fileId) {
                        // есть ссылка на айл, но самого айла нет
                        let macroFile = this.macroPreviewFiles.find(previewFile => previewFile.text === preview.macro.key.id);
                        if (!macroFile) {
                            macroFile = new MacroPreviewFile(preview.macro.key.id);
                            this.audioRecordService.loadAudioFile(preview.macro.audioRecord.fileId,
                                (audio) => macroFile.file = audio, () => {});
                            this.macroPreviewFiles.push(macroFile);
                            preview.file = macroFile;
                        }
                        preview.file = macroFile;
                    } else if (synthesize && this.ttsObject?.enabled && this.ttsObject?.settings?.audioRecordsUseTts) {
                        // если аудио без файла, то проиграть можем, но генерим только по запросу
                        let synthesisFile = new MacroPreviewFile(preview.macro.key.id);
                        synthesisFile.file = await this.textToSpeechService.synthesize(preview.macro.audioRecord.text, false, this.ttsObject.settings);
                        this.macroPreviewFiles.push(synthesisFile);
                        preview.file = synthesisFile;
                    }
                    break;
                default:
                    break;
            }
        } else {
            if (synthesize && this.ttsObject?.enabled && this.hasText(preview)) {
                // если просто кусок текста, то проиграть можем, но генерим только по запросу
                let synthesisFile = new MacroPreviewFile(preview.text);
                synthesisFile.file = await this.textToSpeechService.synthesize(preview.text, false, this.ttsObject.settings);
                this.macroPreviewFiles.push(synthesisFile);
                preview.file = synthesisFile;
            }
        }
    }


    /**
     * Удалить макру по клику по крестик в превью
     */
    removeMacro(preview: MacroPreview): void {
        const previewMacro = new Macro(preview.macro);
        const removableMacroIndex = this.channelText.macros.findIndex(macro => macro.key.id === previewMacro.key.id);
        this.channelText.macros.splice(removableMacroIndex, 1);
        let removableText = previewMacro.generateMacroString();
        const startIndex = this.channelText.text.indexOf(removableText, preview.index);
        this.channelText.text = this.channelText.text.substr(0, startIndex) +
            this.channelText.text.substr(startIndex + removableText.length, this.channelText.text.length);
    }

    /**
     * Проиграть file
     */
    playAudioFile(file: any) {
        this.playableAudioFile = file;
        this.isPlayAllFiles = false;
    }

    /**
     * Проиграть текст textToSpeech'eм
     * @param previewItem макро-объект
     */
    async playSynthesizedSpeech(previewItem: MacroPreview) {
        // Если файла нет или текст для которого синтезирован текст не совпадает с тем, что хотят проиграть - надо синтезировать
        if (MacroPreviewComponent.needToSynthesizeSpeech(previewItem)) {
            await this.setFile(previewItem, true);
        }
        this.playAudioFile(previewItem.file.file);
    }

    /**
     * Клик на проиграть все
     */
    async playAllFiles() {
        // Перед проигрыванием всего надо убедиться, что нет tts-кусков без сгенерённых файлов
        const macroPreviews = this.previewItems.filter(item => this.hasEmptySpeech(item));
        if (macroPreviews.length > 0) {
            // Если есть - нужно синтезировать речь
            for (const preview of macroPreviews) {
                await this.setFile(preview, true);
            }
        }
        // А потом общей кучей сделать из этого плейлист
        this.playlist = this.buildPlaylist();
        this.playableAudioFile = null;
        this.isPlayAllFiles = true;
    }


    /**
     * Доступна кнопка проиграть все
     */
    isPlayAllAvailable(): boolean {
        // у всех предзаписей есть file
        const files = this.previewItems.filter(preview => preview.file);
        const allFilesExist = files.length > 0 || this.previewItems.some(item => !item.macro && this.hasText(item));
        // подклчен ттс
        return this.ttsObject?.enabled && allFilesExist && !this.isPlayAllFiles;

    }

    hasText(preview: MacroPreview): boolean {
        // Если заменить все знаки препинания, то длина текста должна быть ненулевой
        let pureText = preview.text.replace(/([^a-zA-Zа-яА-Я0-9 ])/g, "").trim();
        return pureText.length > 0 && preview.text.trim() !== "<br/>" && preview.text.trim() !== `\n`;
    }

    static needToSynthesizeSpeech(preview: MacroPreview): boolean {
        return !preview.file || !preview.file?.file || preview.file?.text != preview.text;
    }

    private hasEmptySpeech(preview: MacroPreview) {
        return !preview.macro && this.hasText(preview) && MacroPreviewComponent.needToSynthesizeSpeech(preview) ||
            preview.macro?.audioRecord && !preview.macro.audioRecord.fileId && !preview.file;
    }

}


