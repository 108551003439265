import {NgModule} from "@angular/core";
import {MasterWrapperComponent} from "./master/master-wrapper.component";
import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {DetailWrapperComponent} from "./details/detail-wrapper.component";
import {FormControlModule} from "../../common/form/form-control.module";
import {TabsComponent} from "./tab/tabs.component";
import {TabComponent} from "./tab/tab.component";
import {HighlightPipe} from "./highlight.pipe";

@NgModule({
    declarations: [
        MasterWrapperComponent,
        DetailWrapperComponent,
        TabsComponent,
        TabComponent,
        HighlightPipe
    ],
    imports: [
        CommonModule,
        UIRouterModule,
        FormControlModule
    ],
    exports: [
        MasterWrapperComponent,
        DetailWrapperComponent,
        TabsComponent,
        TabComponent,
        HighlightPipe
    ]
})
export class BoilerplateModule {



}