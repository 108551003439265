import {EditableObject} from "../../io/components/common/editable-list/editable-list.model";
import {ExtractedValueType} from "../../io/components/dialog/model/extracted-value.model";
import {ValueOption, ValueTypeEnum} from "./Extractor";
import {EruditeElement} from "../../io/components/script-builder/element-view/EruditeElement";
import {escapeHtml} from "../../../util/Utils";
import {EdgeConditionComponent, SourcesKey} from "../../io/components/script-builder/edge-condition/edge-condition.component";
import {
    ComparisonOperators,
    DateArgumentOperands,
    DateFunctionOperands,
    DatetimeVariablesDescription,
    LogicalOperators,
    NowVariablesDescription,
    NumberVariableOperands,
    Operator,
    VariableOperand
} from "../../io/components/attribute/rule/attribute-rule.model";
import {StepLabelEntity, StepLabelTypeName, VaScriptNode} from "./Script";

export class VaScriptCondition extends EditableObject {
    type: ExtractedValueType;
    entityId: string = "0";
    entity: StepLabelEntity;
    values: string[] = [];
    options: ValueOption[] = [];
    exitNode: VaScriptNode;
    except: boolean = false;
    primitiveRule: PrimitiveRule;

    // все, что используется на фронте
    /**
     * Возможные атрибуты
     */
    attributes: StepLabelEntity[];
    /**
     * значения для селекта с выбором values, valueEntities
     */
    availableValueEntities: any[];
    /**
     * Показать все значения или нет
     */
    showAllOption: boolean = false;
    /**
     * для выражений с датой и цифрами
     */
    expression?: string = "";
    expressionType?: ExpressionType;
    expressionObject?: ExpressionObject;
    isExpression?: boolean;


    static newInstance(sourceElement: EruditeElement): VaScriptCondition {
        let condition = new VaScriptCondition();
        if (sourceElement.node.stepLabel.type.name === StepLabelTypeName.PROCEDURE) {
            // из ноды процедуры можно ставить условия только по этой процедуре
            condition.type = ExtractedValueType.PROCEDURE;
            condition.entity = sourceElement.node.entity;
            condition.entityId = sourceElement.node.entity.key.id;
        }
        return condition;
    }

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }
        this.type = obj.type;
        this.entityId = obj.entityId;
        this.values = obj.values;
        this.except = obj.except;
        this.primitiveRule = obj.primitiveRule;
        this.entity = obj.entity;
        this.options = obj.options;
        this.exitNode = obj.exitNode;
        this.setExpressionData();
    }

    setExpressionData(): void {
        // обнуляем выражение
        if (this.primitiveRule) {
            this.expression = this.primitiveRule.expressionString;
            this.expressionObject = this.primitiveRule.expressionObject;
        } else {
            this.expression = "";
        }
        if (!this.entity || !this.entity.valueType) {
            return;
        }
        // если у атрибута экстрактор одного из примитивных типов - проставим тип выражения
        switch (this.entity.valueType.name) {
            case ValueTypeEnum.INT:
                this.isExpression = true;
                this.expressionType = ExpressionType.INT;
                break;
            case ValueTypeEnum.DATE:
                this.isExpression = true;
                this.expressionType = ExpressionType.DATETIME;
                break;
            case ValueTypeEnum.FLOAT:
                this.isExpression = true;
                this.expressionType = ExpressionType.FLOAT;
                break;
            default:
                this.isExpression = false;
                this.expressionType = null;
                break;
        }
    }

    objectToTitle(): String {
        return "<b>" + this.getEntityTitle() + "</b>" + escapeHtml(this.getConditionText(false, false));
    }

    objectToTitleWithCondition(): string {
        return this.entity.title + " " + escapeHtml(this.getConditionText(false, false));
    }

    beforeEdit(sources: Map<string, any>): void {
        switch (this.type) {
            case ExtractedValueType.ATTRIBUTE:
                if (this.primitiveRule && this.expressionType) {
                    return;
                }
                EdgeConditionComponent.filterAvailableAttributes(this, sources);
                EdgeConditionComponent.filterAvailableValueOptions(this, sources);
                return;
            case ExtractedValueType.PROCEDURE:
                EdgeConditionComponent.filterAvailableExitNodes(this, sources);
                return;
            default:
                return;
        }
    }

    formToObject(sources: Map<string, any>): VaScriptCondition {
        if (this.isExpression) {
            this.primitiveRule = {
                expressionString: this.expression,
                type: {name: this.expressionType},
                expressionObject: this.expressionObject
            };
        }
        this.buildValuesByValuesEntities();
        return this;
    }

    buildValuesByValuesEntities(): void {
        switch (this.type) {
            case ExtractedValueType.PROCEDURE:
                // Собираем значения для объекта с условиями
                this.values = this.exitNode ? [this.exitNode.key.id] : [];
                break;
            case ExtractedValueType.ATTRIBUTE:
                // Собираем значения для объекта с условиями
                this.values = this.options ?
                    this.options.map((valueOption: ValueOption) => valueOption.key != null ? valueOption.key.id.toString() : valueOption.title) : [];
                break;
            default:
                break;
        }

    }

    canDoAction(isDelete: boolean, sources: Map<string, any>): boolean {
        const sourceElement = sources.get(SourcesKey.SOURCE_ELEMENT);
        return sourceElement != null;
    }

    beforeCreate(sources: Map<string, any>): void {
        const entity = sources.get(SourcesKey.SOURCE_ELEMENT).node.entity;
        if (entity.type.name === StepLabelTypeName.PROCEDURE) {
            this.type = ExtractedValueType.PROCEDURE;
            this.entity = entity;
            this.entityId = entity.key.id;
            EdgeConditionComponent.filterAvailableExitNodes(this, sources);
        } else {
            this.type = ExtractedValueType.ATTRIBUTE;
            EdgeConditionComponent.filterAvailableAttributes(this, sources);
            const isAttributeStartNode: boolean = entity.type.name === StepLabelTypeName.ATTRIBUTE_REQUEST;
            const isEmptyConditions: boolean = sources.get(SourcesKey.CURRENT_EDGE).conditions.length === 0;
            if (isAttributeStartNode && isEmptyConditions) {
                //если условий еще нет, то атрибут из ноды должен быть предвыбран
                this.entity = entity;
                this.entityId = entity.key.id;
                EdgeConditionComponent.filterAvailableValueOptions(this, sources);
                this.setExpressionData();
            }
        }
    }

    validateForm(currentIndex: number, sources: Map<string, any>): Map<string, string> {

        let validatorResult = new Map<string, string>();

        if (sources.get(SourcesKey.SOURCE_ELEMENT).node.stepLabel.type.name === StepLabelTypeName.PROCEDURE) {
            if (!this.values || this.values.length === 0) {
                validatorResult.set("exitNode", "Выходной узел не выбран");
            }
        } else {
            if (!this.entity) {
                validatorResult.set("entity", "Атрибут не задан");
            } else {
                const valueTypeName = this.entity.valueType.name;
                if (valueTypeName == ValueTypeEnum.ENUM) {
                    //У атрибута с перечислением должны быть выбраны во
                    if (!this.options || this.options.length === 0) {
                        validatorResult.set("options", "Значения не заданы");
                    }
                } else if (valueTypeName == ValueTypeEnum.DATE || valueTypeName == ValueTypeEnum.INT || valueTypeName == ValueTypeEnum.FLOAT) {
                    if (!this.expression) {
                        validatorResult.set("expression", "Ошибка в выражении");
                        return validatorResult;
                    }

                    let validWords = [];

                    if (valueTypeName == ValueTypeEnum.DATE) {
                        validWords.push(...Object.keys(DatetimeVariablesDescription));
                        validWords.push(...Object.keys(NowVariablesDescription));
                    } else {
                        // просто значение атрибута
                        validWords.push("X");
                    }

                    let wrongVars = this.expression.split(new RegExp("[&|\\-+*/<>=()!]"))
                        .map(s => s.trim())
                        //выкинем пустые, если было 2 знака (>= например)
                        .filter(expressionVar => expressionVar.length != 0)
                        //оставим те, что не содержатся в указанных переменных и не являются цифрами
                        .filter(expressionVar => !expressionVar.match(new RegExp("^[+-]?([0-9]*[.,])?[0-9]+$")) && validWords.indexOf(expressionVar) < 0);
                    if (wrongVars.length) {
                        validatorResult.set("expression", "Выражение задано некорректно. Необходимо использовать переменные из списка, " +
                            "а также знаки '&|-+*/<>=!()'");
                    }
                }
            }
        }
        return validatorResult;
    }

    equals(condition: VaScriptCondition): boolean {
        // Если не совпадают типы, то объекты не одинаковые
        if (this.type !== condition.type) {
            return false;
        }
        // Если совпадают типы, но не совпадают идентификаторы, то объекты не одинаковые
        if (this.entityId !== condition.entityId) {
            return false;
        }
        // Если совпадают типы и идентификаторы, но не режимы исключения значений, то объекты не одинаковые
        if (this.except !== condition.except) {
            return false;
        }
        // Отсортируем значения, если их строковые представления равны, то объекты равны
        return this.values.sort().toString() === condition.values.sort().toString();
    }

    /**
     * Проверить совпадает ли тип сущности и идентификатор сущности
     * @param {VaScriptCondition} condition
     * @returns {boolean}
     */
    idAndTypeEquals(condition: VaScriptCondition): boolean {
        if (this.primitiveRule && condition.primitiveRule) {
            return this.type == condition.type && this.entityId == condition.entityId &&
                this.primitiveRule.expressionString == condition.primitiveRule.expressionString;
        } else {
            return this.type == condition.type && this.entityId == condition.entityId;
        }
    }

    /**
     * Является ли переход безусловным
     * @returns {boolean}
     */
    isUnconditionalStep(): boolean {
        return this.values == null;
    }

    isProcedureCondition(): boolean {
        return this.type == ExtractedValueType.PROCEDURE;
    }

    /**
     * Проверить есть ли объект с value и не пустой ли он
     * @returns {boolean}
     */
    isValuePresent(): boolean {
        return (this.values != null && this.values.length > 0) || (this.primitiveRule != null);
    }

    /**
     * Проверить есть ли объект с primitive rule
     * @returns {boolean}
     */
    isPrimitive(): boolean {
        return this.primitiveRule != null;
    }

    /**
     * Сравнить два условия и проверить нет ли конфликтов для условий с флагом "Все значения, кроме указанных"
     * Например, если на атрибут А[1,2,3,4,5] есть условие:  Все, кроме [1,2], то нельзя добавить условие содержащее [3,4,5]
     * @param {VaScriptCondition} condition условие
     * @returns {boolean}   true -> есть несопоставимые условия, false -> всё в порядке
     */
    hasValueViolations(condition: VaScriptCondition): boolean {
        if (this.except && !condition.except) {
            // Если this с флагом except, то из сравниваемого условия выкидываем те значения, которые есть у this'a
            // Если список окажется пуст, то некорректных пересечений нет, а если не пуст - то ошибка
            return condition.values.filter(value => !this.containsValue(value)).length != 0;
        } else if (!this.except && condition.except) {
            // Аналогично, если сравниваемое условие с флагом except делаем тоже самое, но меняем переменные местами
            return this.values.filter(value => !condition.containsValue(value)).length != 0;
        }
        return false;
    }

    /**
     * Содержится ли указанное значение с списке значений условия
     * @param {string} value    значение
     * @returns {boolean}   true -> содержится, false -> не содержится
     */
    containsValue(value: string): boolean {
        return this.values.indexOf(value) != -1;
    }

    /**
     * Получить имя сущности для отображения
     */
    getEntityTitle(): string {
        switch (this.type) {
            case ExtractedValueType.ATTRIBUTE:
                return "АТРИБУТ  |  " + this.entity.title + ": ";
            case ExtractedValueType.PROCEDURE:
                return "";
            default:
                return "Не удалось получить имя сущности из условия"
        }
    }

    /**
     * Получить текст для отображения
     */
    getConditionText(edgeLabel: boolean, several: boolean): string {
        if (this.type == ExtractedValueType.PROCEDURE) {
            // процедура
            const exitNodeName = this.exitNode?.stepLabel?.name;
            return this.prefixWithTitle(`ВЫХОД | ${exitNodeName || this.values.join(", ")}`, several);
        }
        if (this.isPrimitive()) {
            // правило для простого типа данных
            const primitiveConditionText = this.getPrimitiveConditionText(several);
            if (primitiveConditionText.indexOf(this.entity.title) >= 0) {
                // название в тексте уже есть
                return primitiveConditionText;
            }
            // добавляем название сущности
            return `${this.entity.title}: ${primitiveConditionText}`;
        }
        if (this.isValuePresent()) {
            // Условия превращаем в valueOption'ы
            const optionsText = this.options.map(valueOption => valueOption != null ? valueOption.key != null ? valueOption.title : valueOption.name : "удалено").join(", ");
            if (edgeLabel) {
                return this.prefixWithTitle((this.except ? 'Все, кроме: ' : '') + optionsText, several);
            } else {
                return this.prefixWithTitle((this.except ? 'Все, кроме: ' : '') + "[" + optionsText + "]", several  );
            }
        } else if (this.entityId != null) {
            return this.prefixWithTitle("Любое значение", several);
        }
        return "";
    }

    /**
     * Добавить к строке название сущности, если надо
     */
    private prefixWithTitle(description: string, withEntityTitle: boolean): string {
        if (!withEntityTitle || !description) {
            // не надо добавлять
            return description;
        }
        if (description.indexOf(this.entity.title) >= 0) {
            // уже содержится название
            return description
        }
        // с названием сущности
        return `${this.entity.title}: ${description}`;
    }

    /**
     * Получить текст для отображения примитивного правила в условии по атрибуту
     */
    private getPrimitiveConditionText(several: boolean): string {
        if (!this.primitiveRule.expressionString) {
            // пусто
            return this.primitiveRule.expressionString;
        }
        // заменяем логические операторы на более читабельные
        let result = this.replaceAll(this.primitiveRule.expressionString, LogicalOperators);
        // теперь операторы сравнения
        result = this.replaceAll(result, ComparisonOperators);

        if (this.entity.valueType.name === ValueTypeEnum.DATE) {
            // доп. функции для дат
            result = this.replaceAll(result, DateFunctionOperands.map(operand => ({
                name: '_' + operand.name,
                displayName: ' ' + operand.displayName
            })));
            // их значения
            result = this.replaceAll(result, DateArgumentOperands.map(operand => ({
                name: operand.name,
                displayName: this.variableOperandDisplayName(operand)
            })));
        } else {
            // значения чисел
            result = this.replaceAll(result, NumberVariableOperands[0].map(operand => ({
                name: operand.name,
                displayName: this.variableOperandDisplayName(operand)
            })));
        }
        if (several && result.indexOf("или") >= 0) {
            // если несколько условий и внутри есть "или", то вокруг надо скобки
            result = `(${result})`;
        }
        return result;
    }

    /**
     * Заменить все вхождения в строке по массиву частей выражения
     */
    private replaceAll(text, displayables: VariableOperand[] | Operator[]) {
        displayables.forEach(displayable => text = text.replace(displayable.regex
            // есть регулярка, заменяем ей
            ? displayable.regex
            // делаем из имени регулярку, заменяем на отображаемое
            : new RegExp(displayable.name, 'g'), displayable.displayName));
        return text;
    }

    /**
     * Отображаемое значение для значения операнда выражения
     */
    private variableOperandDisplayName(operand: VariableOperand) {
        return operand.displayName == 'Значение' ? this.entity.title : operand.displayName;
    }
}

export enum ExpressionType {
    INT = 'INT',
    DATETIME = 'DATETIME',
    FLOAT = 'FLOAT'
}

type PrimitiveRuleType = "INT" | "FLOAT" | "DATETIME";

export interface PrimitiveRule {
    type: { name: PrimitiveRuleType };

    expressionString: string;

    expressionObject: ExpressionObject;
}

export interface ExpressionPart {

    leftOperand?: string;

    operator?: string;

    rightOperand?: string;

    isValid?: boolean;

}

interface ExpressionObject {
    operators: string[];
    parts: ExpressionPart[];
}
