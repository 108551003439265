import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ClusterBuildParams, ClusterInfoForm, DialogDumpLoadResult, DumpFileType, Info} from "../../../model/tb-params.model";
import {EruditeFileUploader} from "../../../../common/file-uploader/file-uploader.component";
import {StateService} from "@uirouter/core";
import {ClusterInfoService} from "./cluster-info.service";
import {MessageClustersComponent} from "../message-clusters.component";
import {FileItem} from "../../../../common/file-uploader/uploader-vendor/file-item.class";


@Component({
    selector: 'cluster-info',
    template: require('./cluster-info.component.html'),
    styles: [require('./cluster-info.component.less')]
})
export class ClusterInfoComponent implements OnDestroy, OnInit {

    access: boolean = true;
    projectVersionId: string;
    sourceTagId: number;
    sourceTagIdRequestParam: number;
    baseUrl: string;
    openParams: boolean;
    openFormat: boolean;
    openLoad: boolean;
    intervalId: any;
    clusterBuildParams: ClusterBuildParams;
    info: Info = new Info();
    dumpFileTypes: DumpFileType[] = [];
    file: File[];
    form: ClusterInfoForm;

    @ViewChild(`eruditeFileUploader`, {static: false})
    private loader: EruditeFileUploader;

    constructor(protected stateService: StateService,
                private dataService: ClusterInfoService,
                private parent: MessageClustersComponent) {

        // родительский контроллер для перезагрузки страницы после окончания кластеризации
        this.projectVersionId = stateService.params['projectVersionId'];
        // id кластеризуемой тематики или ничего, если кластеризуем дамп диалогов
        this.sourceTagId = stateService.params['sourceTagId'];
        this.sourceTagIdRequestParam = stateService.params['sourceTagId'];
    }

    async ngOnInit() {
        this.form = new ClusterInfoForm()
        this.clusterBuildParams = new ClusterBuildParams();
        // инициализируем хранилище информации о файле
        this.clearUploadInfo();

        // грузим список типов файлов для формы параметров кластеризации
        await this.getFileTypes();

        // грузим сохраненные параметры кластеризации
        await this.getClusterBuildParams();

        // запускаем апдейтер состояния процессов
        await this.checkIsProcessing();

        const that = this;
        this.intervalId = setInterval(function () {
            that.checkIsProcessing();
        }, 5000);
    }


    /**
     * Загрузка файла дампа на сервер
     * @param save true - сохранить диалоги, false - только распарсить файл и вывеси инфу на интерфейс
     */
    async importData(save) {

        this.form.saveDialogs = save;
        this.clearUploadInfo();

        let formData = new FormData();
        let file = this.file[0];

        formData.append('file', file, file.name);

        await this.saveParams();

        this.dataService.uploadMessagesFile(save, formData, this.form.fromRow, this.form.toRow, this.projectVersionId).then(response => {

            // если ок, достаем результат загрузки
            this.form.uploadInfo = response;
            if (this.form.saveDialogs) {
                // если сохраняли диалоги, форму загрузки закрываем
                this.openLoad = false;
            }

        }, response => {
            if (response.status == 'ERROR') {
                if (response.errors && response.errors.length && response.errors[0].message) {
                    // ошибка с сообщением
                    this.form.uploadInfo.errorMessage = "";
                    response.errors.forEach(error => {
                        this.form.uploadInfo.errorMessage += " " + error.message;
                    });
                } else {
                    // ошибка без сообщения
                    this.form.uploadInfo.errorMessage = "файл не загружен";
                }
            }
        });
        this.file = null;
        this.onFileChange(null);
        this.loader.removeFiles();
        await this.checkIsProcessing();

    }

    /**
     * Установка информации о файле в начальное значение
     */
    clearUploadInfo() {
        this.form.uploadInfo = new DialogDumpLoadResult();
    }

    /**
     * Функция обновления параметров кластеризации
     */
    async saveParams() {
        this.clearUploadInfo();
        this.clusterBuildParams = await this.dataService.rebuildClusterBuildParams(this.clusterBuildParams, this.sourceTagIdRequestParam);
        this.openParams = false;
        this.openFormat = false;
    }

    //обновление данных о состоянии кластеров
    async checkIsProcessing() {
        let infoTemp = await this.dataService.getInfo(this.sourceTagIdRequestParam);

        //если закончилось выполнение чего то с кластером, то должны перезагрузить данные в родительском контроллере
        if (this.info.processedOperation !== undefined && infoTemp == undefined) {
            await this.parent.loadPage();
        }
        this.info = infoTemp;
    }

    async getClusterBuildParams() {
        this.clusterBuildParams = await this.dataService.getClusterBuildParams(this.sourceTagIdRequestParam)
    }

    async getFileTypes() {
        this.dumpFileTypes = await this.dataService.getFileTypes();

    }

    // отображение состояния загрузки
    setLoading(loading) {
        this.form.isLoading = loading;
    };

    async clusterizeGet(script: string) {
        await this.dataService.clusterizeGet(this.sourceTagIdRequestParam, script);
    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     * @param $event
     */
    onImportFileAddFailed($event: string) {
        this.form.errors.set("#main", $event);
        setTimeout(() => this.form.errors.set("#main", null), 5000);
    }

    /**
     * При изменении контента файл-инпута
     */
    onFileChange(items: FileItem[]) {
        if (!items) {
            this.file = null;
        } else {
            this.file = items.map(item => item._file);
        }
    }

    // в деструкторе стопаем метод, не забываем отписаться
    ngOnDestroy(): void {
        clearInterval(this.intervalId);
    }

}