import {Component, Input, QueryList, ViewChildren} from "@angular/core";
import {StepLabelSectionComponent} from "../step-label-section/step-label-section.component";
import {StepLabelEntity, StepLabelType, StepLabelTypeName} from "../../../../data/va/Script";
import {setBlockView} from "../ScriptBuilderUtils";

@Component({
    selector: "entity-accordion",
    template: require('./entity-accordion.component.html')
})
export class EntityAccordionComponent {

    /**
     * Идентификатор тематики
     */
    @Input()
    private tagId: number;

    /**
     * Список сущностей для каждого StepLabel'a
     */
    @Input()
    private entities: { type: StepLabelType, entities: StepLabelEntity[] }[];

    /**
     * Ссылки на компоненты секций
     */
    @ViewChildren(StepLabelSectionComponent)
    private sections: QueryList<StepLabelSectionComponent>;

    /**
     * Поисковый запрос
     */
    private searchQuery: string;

    /**
     * Нужно ли прятать сообщение о том, что поиск не дал результатов?
     */
    private hideEmptySearchMessage: boolean = true;

    /**
     * Функция обработки начала drag'a блока
     */
    private onDragStart: (entity: StepLabelEntity) => void = setBlockView;


    constructor() {
    }

    ngAfterViewInit() {
        // Подпишемся на событие отрисовки всех сессий
        this.sections.changes
            .subscribe((sections) => this.handleSectionsInit(sections));
        
        this.handleSectionsInit(this.sections);
    }

    /**
     * Обработать отрисовку всех секций с сущностями
     * @param sections секции
     */
    private handleSectionsInit(sections: QueryList<StepLabelSectionComponent>) {
        sections.forEach(section => this.subscribeOnSectionVisibilityChange(section));
    }

    /**
     * Подписать родительскую компоненту (эту) на изменение видимости дочерних компонент в результате поиска
     * @param stepLabelSection дочерняя компонента
     */
    private subscribeOnSectionVisibilityChange(stepLabelSection: StepLabelSectionComponent) {
        stepLabelSection.visibilityChange.subscribe(() => {
            this.hideEmptySearchMessage = this.sections
                .filter(section => !section.isDraggable())
                .some(section => section.hasContent());
        })
    }

    private toggleAllSections(expand: boolean) {
        this.sections.forEach(section => section.rememberExpandState(expand));
    }

    ngOnDestroy() {
        this.sections.forEach(section => section.visibilityChange.unsubscribe());
    }

    private isDraggable(type: StepLabelTypeName) {
        return type == StepLabelTypeName.EXIT || type == StepLabelTypeName.TO_SUPPORT;
    }
}