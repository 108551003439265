import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

/**
 * Сервис для загрузки файлов
 */
@Injectable({
    providedIn: 'root',
})
export class FileUploadService {

    constructor(private http: HttpClient) {
    }

    /**
     * Загрзуить один файл
     * @param url        адрес загрузки
     * @param paramName  имя параметра, под которым будет лежать файл
     * @param file       файл
     */
    public uploadSingleFile(url: string, paramName: string, file: File): Observable<HttpEvent<any>> {
        let formData = new FormData();
        // Складываем файл
        formData.append(paramName, file, file.name);
        // Загружаем
        return this.upload(url, formData);
    }

    /**
     * Загрузить несколько файлов
     * @param url       адрес загрузки
     * @param paramName имя параметра, под которым будут лежать файлы
     * @param files     файлы
     */
    public uploadMultipleFiles(url: string, paramName: string, files: File[]): Observable<HttpEvent<any>> {
        let formData = new FormData();
        // Складываем полученные файлы
        files.forEach(file => formData.append(paramName, file, file.name));
        // Загружаем
        return this.upload(url, formData);
    }

    /**
     * Загрузить файл(ы)
     * @param url       адрес
     * @param formData  данные
     */
    private upload(url: string, formData: FormData): Observable<HttpEvent<any>> {
        let params = new HttpParams();
        const options = {
            params: params,
            reportProgress: true,
        };
        // Запрос
        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
    }

}