import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeNestedDataSource} from "@angular/material";
import {VaTag} from "../../../../../data/va/Tag";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {TagService} from "../tag.service";
import WebSocketService from "../../../../../services/WebSocketService";

@Component({
    selector: 'tag-tree',
    template: require('./tag-tree.component.html'),
    styles: [require('./tag-tree.component.less')]
})
export class TagTreeComponent implements OnInit {
    /**
     * Данные для построения дерева
     */
    dataSource = new MatTreeNestedDataSource<VaTag>();

    /**
     * Элементы управления деревом (экспанд-коллапс, etc)
     */
    treeControl = new NestedTreeControl<VaTag>(tag => tag.children);

    /**
     * Проверка на наличие детей у узла
     * @param level уровень вложенности
     * @param tag   узел
     */
    hasChildren: any = (level: number, tag) => tag?.children?.length > 0;

    /**
     * Дерево тематик: если не передано, то будет загружено
     */
    @Input()
    protected tagTree?: VaTag;

    /**
     * Отображать иконки
     */
    @Input()
    displayIcons: boolean = true;

    /**
     * Отображать иконки
     */
    @Input()
    searchEnabled: boolean = true;

    /**
     * Предвыбранная тематика
     */
    @Input()
    selectedTagId?: number;

    /**
     * Использовать ли двойной клик нажатие
     */
    @Input()
    private useDoubleClick?: boolean = false;

    /**
     * Внешнее обновление
     */
    @Input()
    private updateSubject?: Subject<number> = null;

    /**
     * Событие готовности компоненты
     */
    @Input()
    isScrollEnabled?: boolean = false;

    /**
     * Событие клика на узел (отдаём наружу)
     */
    @Output()
    private onTagNodeClick = new EventEmitter<VaTag>();

    /**
     * Событие готовности компоненты (внутри количество узлов)
     */
    @Output()
    private onReady = new EventEmitter<number>();

    /**
     * Поисковый запрос
     */
    searchQuery: string;

    /**
     * Пустой результат поиска
     */
    emptySearch: boolean;

    /**
     * Subject поискового запроса
     */
    private searchQueryChange = new Subject<string>();

    /**
     * Тематика по идентификатору (удобство для поиска)
     */
    private tagById: { [key: string]: VaTag } = {};

    /**
     * Подходящие под фильтрацию узлы
     */
    private filteredNodes: VaTag[];

    protected subscriptionId: string;

    /**
     * Опция развернуть всё дерево
     */
    isEverythingExpanded: boolean = false;

    constructor(public tagService: TagService,
                protected webSocketService: WebSocketService,
                public changeDetector: ChangeDetectorRef) {
    }

    async ngOnInit(): Promise<void> {
        this.searchQueryChange.pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(query => this.search(query));

        if (this.updateSubject != null) {
            this.updateSubject.subscribe(selectedTagId => this.render(selectedTagId));
        }

        let tree: VaTag;
        if (this.tagTree) {
            tree = Array.isArray(this.tagTree) ? this.tagTree[0] : this.tagTree;
        } else {
            tree = await this.tagService.getSmallTalksAndBusinessTagsTree();
        }

        this.initTree(tree, this.selectedTagId);
        this.onReady.emit(Object.keys(this.tagById).length - 1);

        this.tagService.getMsgCount().then(data =>
            this.fillMsgCount(tree, data)
        );

        // подписываемся на событие ожидания изменения количества сообщений в тематиках
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_MESSAGE_CHANGE",
            fn: (event) => {
                const changeEvent: any = JSON.parse(event.details);
                if (changeEvent.key == tree.children[0].key.projectVersionId) {
                    // если изменения были в текущем проекте, то проходим по дереву
                    this.fillMsgCount(tree, changeEvent.value);
                }
            }
        });
    }

    /**
     * Пробегает по дереву и обновляет количество сообщений в тематиках (при получении пуша)
     * @param tag - дерево тематик
     * @param msgCounts - мапа с количеством сообщений в тематиках
     */
    fillMsgCount(tag, msgCounts) {
        // msg count in this tag
        let msgCount = msgCounts[tag.key.id] == null ? 0 : Number.parseInt(msgCounts[tag.key.id]);

        // for each child
        if (tag.children) {
            for (let child of tag.children) {
                // fill msg count
                this.fillMsgCount(child, msgCounts);
                // sum msg count
                msgCount += Number.parseInt(child.messageCount);
            }
        }
        tag.messageCount = msgCount;
    }

    /**
     * Отрисовать дерево
     * @param selectedTagId выбранная группа
     */
    render(selectedTagId?: number) {
        // workaround для бага https://github.com/angular/components/issues/11381
        let tree: VaTag = this.dataSource.data[0];
        this.dataSource.data = null;
        this.initTree(tree, selectedTagId);
    }

    /**
     * Инициализация дерева
     * @param tree          данные
     * @param selectedTagId выбранная группа
     */
    protected initTree(tree: VaTag, selectedTagId: number) {
        TagTreeComponent.treeWalk(tree, this.markLastChild.bind(this));
        this.dataSource.data = [tree];
        this.treeControl.dataNodes = this.dataSource.data;
        this.treeControl.expand(this.treeControl.dataNodes[0]);
        if (!this.selectedTagId && this.treeControl.dataNodes[0].children) {
            this.treeControl.expand(this.treeControl.dataNodes[0].children[0]);
        }
        // если предвыбрана тематика
        if (selectedTagId) {
            // найдём её по id
            const selectedTag = this.tagById[selectedTagId];
            // соберём к ней путь и обновим вид дерева
            const parents = this.collectParentNodes([selectedTag]);
            TagTreeComponent.treeWalk(this.dataSource.data[0], (tag) => this.updateTree(tag, parents, false));
        }
    }

    /**
     * Поиск
     * @param query поисковый запрос
     */
    private search(query: string) {
        // если поисковую строку очистили, то восстановим дефолтный вид дерева
        if (query?.length == 0) {
            this.restoreTagTree();

            // обновим в bottom-sheet на всякий
            this.changeDetector.markForCheck();

            return;
        }
        this.filteredNodes = Object.values(this.tagById)
            .map(tag => TagTreeComponent.match(tag, query))
            .filter(node => node !== null);
        this.emptySearch = !this.filteredNodes.length;
        // собираем родителей сматчившихся узлов, чтобы их потом раскрыть
        const parentNodes = this.collectParentNodes(this.filteredNodes);
        // изменяем внешний вид дерева
        TagTreeComponent.treeWalk(this.dataSource.data[0], (tag) => this.updateTree(tag, parentNodes, true));
        this.onReady.emit(this.filteredNodes.length);

        // обновим в bottom-sheet на всякий
        this.changeDetector.markForCheck();
    }

    /**
     * Обновить вид дерева
     * @param tag           тематика
     * @param parentNodes   узлы, которые должны быть отображены в результате фильтрации
     * @param filter        нужно ли скрывать неподходящие узлы
     */
    private updateTree(tag: VaTag, parentNodes: { [key: string]: VaTag }, filter: boolean) {
        // если узел есть в списке, то его не прячем
        tag.hidden = filter && !parentNodes.hasOwnProperty(tag.key.id);
        if (tag.hidden) {
            tag.highlightedText = null;
            // если узлы скрывать не надо, то возможно надо раскрыть ветвь дерева
        } else if (!filter) {
            // раскрываем, если текущая тематика в списке родителей
            if (parentNodes.hasOwnProperty(tag.key.id)) {
                this.treeControl.expand(tag);
            }
        } else {
            // раскрываем
            this.treeControl.expand(tag);
        }
    }

    private static match(tag, query: string) {
        let regex = new RegExp(query, 'i');
        return regex.test(tag.text) ? tag : null;
    }

    /**
     * Восстановить первоначальный вид дерева
     */
    private restoreTagTree() {
        this.filteredNodes = [];
        this.emptySearch = false;
        this.treeControl.collapseAll();
        TagTreeComponent.treeWalk(this.dataSource.data[0], TagTreeComponent.restoreNode);
        this.onReady.emit(Object.keys(this.tagById).length - 1);
        // todo убрать при отказе от smalltalks
        if (this.treeControl.dataNodes[0].children != null) {
            // раскроем первый уровень
            this.treeControl.expand(this.treeControl.dataNodes[0]);
            this.treeControl.expand(this.treeControl.dataNodes[0].children[0]);
        }

    }

    /**
     * Отобразить узел тематики и удалить дополнительный текст
     * @param tag узел тематики
     */
    private static restoreNode(tag: VaTag) {
        tag.hidden = false;
        tag.highlightedText = null
    }

    /**
     * Для сматчившихся узлов собрать их родителей, которые возможно потребуется раскрыть
     */
    private collectParentNodes(nodes: VaTag[]) {
        const parents = {};
        nodes.forEach(tag => {
            let node: VaTag = tag;
            parents[node.key.id] = node;
            // пока не добрались до корневой ноды - собираем родителей
            while (!this.tagService.isFictiveRoot(node)) {
                const parentNode = this.tagById[node.parentId];
                const parentAlreadyProcessed = parents.hasOwnProperty(node.parentId);
                parents[parentNode.key.id] = parentNode;
                if (parentAlreadyProcessed) {
                    break;
                }
                node = parentNode;
            }
        });
        return parents;
    }

    /**
     * Переданный узел = корневой?
     * @param tag тематика
     */
    isRoot(tag: VaTag): boolean {
        return (tag.parentId == 0) || this.tagService.isSmallTalksDir(tag) || this.tagService.isFictiveRoot(tag);
    }

    /**
     * Обход дерева с указанного узла
     * @param tag      тематика
     * @param callback колбэк с текущим узлом
     */
    protected static treeWalk(tag: VaTag, callback: (tag: VaTag) => void) {
        callback(tag);
        if (tag.children?.length > 0) {
            tag.children.forEach(child => TagTreeComponent.treeWalk(child, callback));
        }
    }

    /**
     * Изменения строки поиска
     */
    onSearchQueryChange(value: string) {
        this.searchQuery = value;
        this.searchQueryChange.next(this.searchQuery);

        // обновим в bottom-sheet на всякий
        this.changeDetector.markForCheck();
        this.isEverythingExpanded = false;
    }


    /**
     * Отметить последние листы в списках детей у дерева
     * @param tag тематика
     */
    private markLastChild(tag: VaTag) {
        if (tag.children?.length > 0) {
            const index = tag.children.length - 1;
            tag.children[index].lastChild = true;
        }
        // заодно сложим по идентификатору
        this.tagById[tag.key.id] = tag;
    }

    /**
     * Обработчик клика по узлу
     * @param event
     * @param tag   тематика
     */
    onClick(event: any, tag: VaTag) {
        event.preventDefault();
        this.onTagNodeClick.emit(tag);
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    expandOrCollapseTree() {
        if (this.isEverythingExpanded) {
            this.treeControl.collapseAll();
            this.isEverythingExpanded = false;

            // отобразим нулевой уровень
            this.treeControl.expand(this.treeControl.dataNodes[0]);
        } else {
            this.treeControl.expandAll();
            this.isEverythingExpanded = true;
        }
    }
}
