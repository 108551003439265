import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {ExtractableValuesComponent} from "./extractable-values.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {EditableListModule} from "../../common/editable-list/editable-list.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {DatetimePickerModule} from "../../common/datetime-picker/datetime-picker.module";


@NgModule({
    declarations: [
        ExtractableValuesComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        UIRouterUpgradeModule,
        NgSelectModule,
        EditableListModule,
        FormControlModule,
        DatetimePickerModule
    ],
    exports: [
        ExtractableValuesComponent
    ],
    entryComponents: [
        ExtractableValuesComponent
    ]
})
export class ExtractableValuesModule {

}