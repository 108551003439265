import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {CollectionModule} from "../../common/collection/collection.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {ExpertModule} from "../../common/expert/expert.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {TagService} from "./tag.service";
import {TagsComponent} from "./list/tags.component";
import {TagEditComponent} from "./edit/tag-edit.component";
import {TagTreeModule} from "./tree/tag-tree.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {TagMessagesComponent} from "./message/tag-messages.component";
import {EruditeFileUploaderModule} from "../../common/file-uploader/erudite-file-uploader.module";
import {TaggedItemListComponent} from "./item-list/tagged-item-list.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDialogModule} from "@angular/material/dialog";
import {UIRouterModule} from "@uirouter/angular";
import {MacroModule} from "../../common/macro/macro.module";
import {FormulationModule} from "../../formulation/formulation.module";
import {TaggingItemModule} from "../../common/tagging-item/tagging-item.module";
import {NgbPaginationModule, NgbProgressbarModule} from "@ng-bootstrap/ng-bootstrap";
import {SmallTalksComponent} from "./small-talks/small-talks.component";
import {RobotAnswersComponent} from "./robot-answers/robot-answers.component";
import {DenyButtonModule} from "../../common/deny-button/deny-button.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MessageSearchComponent} from "./search/message-search.component";
import {MessageService} from "./message.service";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {EditableListModule} from "../../common/editable-list/editable-list.module";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {EruditeIconModule} from "../../common/erudite-icon/erudite-icon.module";
import {AnswerService} from "../answer/answer.service";

@NgModule({
    declarations: [
        TagEditComponent,
        TagsComponent,
        TaggedItemListComponent,
        TagMessagesComponent,
        RobotAnswersComponent,
        SmallTalksComponent,
        MessageSearchComponent
    ],
    imports: [
        BrowserModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatDialogModule,
        MatInputModule,
        UIRouterUpgradeModule,
        TagTreeModule,
        BoilerplateModule,
        NgSelectModule,
        EruditeFileUploaderModule,
        CollectionModule,
        UIRouterModule,
        CommonModule,
        ExpertModule,
        MacroModule,
        EntityUsageModule,
        FormControlModule,
        FormsModule,
        FormulationModule,
        TaggingItemModule,
        NgbPaginationModule,
        DenyButtonModule,
        NgbProgressbarModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        EditableListModule,
        ScrollingModule,
        EruditeIconModule
    ],
    exports: [],
    providers: [
        TagService,
        AnswerService,
        TagService,
        MessageService
    ],
    entryComponents: [
        MessageSearchComponent
    ]
})
export class TagModule {

    static states = [
        {
            name: "robot.tags",
            url: "/tags",
            views: {
                "content@authorized": {
                    component: TagsComponent
                }
            },
            data: {
                title: "Тематики",
                requiredProjectVersion: true
            }
        },
        {
            name: "robot.tags.edit",
            url: "/:tagId?query",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@robot.tags": {
                    component: TagEditComponent
                }
            },
            data: {
                title: "Тематики | ",
                newObjectTitle: "Новая тематика",
                editObjectTitle: "Редактирование тематики",
                successfulSaveMessage: "Тематика сохранена",
                meta: {
                    parameters: ["tagId"],
                    sectionFunction: function () {
                        return "Обучение робота";
                    },
                    subSectionFunction: function ([tagId]) {
                        return tagId ? "Редактирование тематики" : "Новая тематика";
                    }
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        },
        {
            name: "robot.tags.act",
            url: "/:tagId?query",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "details@robot.tags": {
                    component: SmallTalksComponent
                }
            },
            data: {
                title: "Тематики | ",
                editObjectTitle: "Редактирование акта",
                meta: {
                    parameters: ["tagId"],
                    sectionFunction: function () {
                        return "Обучение робота";
                    },
                    subSectionFunction: function ([tagId]) {
                        return tagId ? "Редактирование акта" : "Новый акт";
                    }
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
        ]
}
