export default class DashboardService {
    constructor($timeout) {
        const that = this;
        that.$timeout = $timeout;
        that.gridsterOptions = {
            columns: 12,
            rowHeight: 55,
            pushing: false,
            floating: true,
            swapping: false,
            sparse: true,
            margins: [20, 20],
            //isMobile: true, // stacks the grid items if true
            mobileBreakPoint: 1185,
            mobileModeEnabled: true,
            draggable: {
                enabled: false,
                handle: 'div'
            },
            resizable: {
                enabled: true,
                handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],
                start: function (event, $element, widget) {
                    if (widget.charts) widget.charts.forEach(function (chart) {
                        chart.api.update();
                        that.markPointsBeyondLimits(chart.api.getScope(), chart.api.getElement());
                    });
                },
                resize: function (event, $element, widget) {
                    if (widget.charts) widget.charts.forEach(function (chart) {
                        chart.api.update();
                        that.markPointsBeyondLimits(chart.api.getScope(), chart.api.getElement());
                    });
                },
                stop: function (event, $element, widget) {
                    that.$timeout(function () {
                        if (widget.charts) widget.charts.forEach(function (chart) {
                            that.markPointsBeyondLimits(chart.api.getScope(), chart.api.getElement());
                            chart.api.update();
                        });
                    }, 400)
                },
            },
        };
        that.chartOptions = {
            TAGS: {
                chart: {
                    type: 'multiBarHorizontalChart',
                    margin: {
                        top: 20,
                        right: 40,
                        bottom: 40,
                        left: 300
                    },
                    x: function (d) {
                        return d.label;
                    },
                    y: function (d) {
                        return d.value;
                    },
                    showControls: false,
                    showValues: true,
                    duration: 500,
                    xAxis: {
                        tickFormat: function (d) {
                            if (typeof this !== 'undefined') {
                                let el = d3.select(this);
                                let p = d3.select(this.parentNode);
                                p.append("foreignObject")
                                    .attr('y', -15)
                                    .attr('x', -300)
                                    .attr("width", 300)
                                    .attr("height", 30)
                                    .append("xhtml:div")
                                    .attr('style', 'display: table; ' +
                                        'width: 270; height: ' +
                                        '30px;margin: 0 0 0 30px;')
                                    .append("xhtml:span")
                                    .attr('style', 'word-wrap: break-word; ' +
                                        '    display: table-cell; ' +
                                        '    vertical-align: middle;' +
                                        '    text-align:left;' +
                                        '    font-style: normal;' +
                                        '    font-variant-ligatures: normal;' +
                                        '    font-variant-caps: normal;' +
                                        '    font-variant-numeric: normal;' +
                                        '    font-variant-east-asian: normal;' +
                                        '    font-weight: normal;' +
                                        '    font-stretch: normal;' +
                                        '    font-size: 12px;' +
                                        '    line-height: normal;' +
                                        '    font-family: Arial, sans-serif;')
                                    .html(d);
                                el.remove();
                                return d;
                            }
                        }
                    },
                    yAxis: {
                        tickFormat: function (d) {
                            return Number.isInteger(d) ? d : '';
                        }
                    },
                    valueFormat: function (d) {
                        return d.toFixed(0);
                    },
                    // Не отображать легенду
                    showLegend: false
                }
            },
            SESSIONS: {
                chart: {
                    type: 'lineChart',
                    // Не обрезаем по углам точки
                    clipEdge: false,
                    // Отступы
                    margin: {
                        top: 20,
                        right: 40,
                        bottom: 40,
                        left: 55
                    },
                    x: function (d) {
                        return d.x;
                    },
                    y: function (d) {
                        return d.y;
                    },
                    // Использовать вертикальную штангу с точными значениями
                    useInteractiveGuideline: true,
                    // Плавное перестроение графиков
                    duration: 500,
                    // Настройки осей
                    xAxis: {
                        tickFormat: function (d) {
                            return moment(d).format("HH:mm");
                        }
                    },
                    yAxis: {
                        tickFormat: function (d) {
                            return Number.isInteger(d) ? d : '';
                        }
                    }
                }
            },
            SENTIMENT: {
                chart: {
                    type: 'pieChart',
                    // Отступы
                    margin: {
                        top: 0,
                        right: 10,
                        bottom: 40,
                        left: 10
                    },
                    x: function (d) {
                        return d.sentiment.title;
                    },
                    y: function (d) {
                        return d.count;
                    },
                    showLabels: true,
                    labelThreshold: 0.01,
                    labelSunbeamLayout: false,
                    labelType: 'percent',
                    legendPosition: 'bottom',
                    noData: 'Нет диалогов',
                    legend: {
                        color: function (d) {
                            switch (d.sentiment.name) {
                                case "VERY_POSITIVE":
                                    return "#4DBA6E";
                                case "POSITIVE":
                                    return "#7FDB9B";
                                case "NEUTRAL":
                                    return "#D2D5D9";
                                case "NEGATIVE":
                                    return "#FD8754";
                                case "VERY_NEGATIVE":
                                    return "#ED3945";
                            }
                        }
                    },
                    color: function (d) {
                        switch (d.sentiment.name) {
                            case "VERY_POSITIVE":
                                return "#4DBA6E";
                            case "POSITIVE":
                                return "#7FDB9B";
                            case "NEUTRAL":
                                return "#D2D5D9";
                            case "NEGATIVE":
                                return "#FD8754";
                            case "VERY_NEGATIVE":
                                return "#ED3945";
                        }
                    },
                    // Плавное перестроение графиков
                    duration: 500
                },
                title: {
                    enable: true,
                    text: 'Кол-во диалогов: ',
                    css: {
                        textAlign: 'left',
                        fontSize: 14,
                        color: 'gray',
                        marginLeft: 15,
                        marginTop: 0
                    }
                }
            }
        }

    };

    markPointsBeyondLimits(scope, el) {
        const circle = "circle";
        const namespace = "http://www.w3.org/2000/svg";
        angular.element(el).find('svg').find('g.nv-groups').find('circle').remove();
        let chartArea = angular.element(el).find('svg').find('g.nv-groups');
        scope.data.forEach(function (dataItem) {
            if (dataItem.values) {
                for (let value of dataItem.values) {
                    if (value.y >= 0) {
                        let attributes = {
                            cx: scope.chart.xScale()(value.x),
                            cy: scope.chart.yScale()(value.y),
                            r: 4,
                            fill: dataItem.color
                        };
                        let circleElement = document.createElementNS(namespace, circle);
                        for (let attr in attributes)
                            circleElement.setAttribute(attr, attributes[attr])
                        chartArea.append(circleElement);
                    }
                }
            }
        });
    }

    getGridsterOptions() {
        const that = this;
        return that.gridsterOptions;
    }

    getChartOptions(key) {
        const that = this;
        return that.chartOptions[key];
    }
}

DashboardService.$inject = ["$timeout"];