import * as joint from '@naumen/rappid/build/rappid';
import {dia, ui} from '@naumen/rappid/build/rappid';
import {VaScriptEdge, VaScriptNode} from "../../../../data/va/Script";
import {calcOffset, getLinkColor, getNodeSize, getStrokeDash, HIGHLIGHT_COLOR} from "../ScriptBuilderUtils";
import EruditeLink from "../link-view/EruditeLink";
import ValidationController from "../controller/ValidationController";
import ElementView = dia.ElementView;
import Size = dia.Size;
import HandlePosition = ui.Halo.HandlePosition;

/**
 * Режим, в котором было нажатие на элемент
 */
export enum ClickMode {
    DEFAULT = "DEFAULT",
    CHECKPOINT = "CHECKPOINT"
}

/**
 * Базовый элемент в билдере сценариев
 */
export class EruditeElement extends dia.Element {

    /**
     * Callback для halo на создание link'a
     */
    public onLinkCreate: (link) => void;

    /**
     * Разметка
     */
    markup: any[] = [];

    /**
     * Элементы управления элементом (удаление/новый линк/etc)
     */
    protected halo: ui.Halo;

    protected freeTransform: ui.FreeTransform;

    public readonly erudite = true;

    protected handles: ui.Halo.Handle[] = [{
        name: 'remove',
        position: HandlePosition.NW,
        events: {pointerdown: () => this.onElementRemove()},
    }, {
        name: 'link',
        position: HandlePosition.S,
        events: {pointerdown: 'startLinking', pointermove: 'doLink', pointerup: 'stopLinking'},
    }];

    constructor(attributes: {node: VaScriptNode}) {
        super({id: attributes.node.key.id, node: attributes.node});
        const size: Size = getNodeSize(this.node.text, this.isRound());
        this.position(this.node.x || 0, this.node.y || 0);
        this.resize(this.attributes.width || size.width, this.attributes.height || size.height);
        this.prop('minSize', {
            width: size.width, height: size.height
        });
    }
    get node(): VaScriptNode {
        return this.attributes.node;
    }

    /**
     * Исходящие рёбра (линки)
     */
    get outgoingLinks(): EruditeLink[] {
        return this.collection
            .filter(element => element instanceof EruditeLink)
            .filter((link: EruditeLink) => link.sourceNode.key.id == this.node.key.id)
    }

    /**
     * Входящие рёбра (линки)
     */
    get incomingLinks(): EruditeLink[] {
        return this.collection
            .filter(element => element instanceof EruditeLink)
            .filter((link: EruditeLink) => link.targetNode.key.id == this.node.key.id)
    }

    /**
     * Исходящие рёбра (эджи)
     */
    get outgoingEdges(): VaScriptEdge[] {
        return this.outgoingLinks.map((link: EruditeLink) => link.scriptEdge);
    }

    /**
     * Действия при ЛКМ на элемент
     * @param elementView элемент
     * @param mode        режим, в котором нажали на элемент
     */
    public onElementClick(elementView: ElementView, mode: ClickMode): ui.Halo {
        // Минимальные размеры
        const minSize: { width: number, height: number } = this.prop('minSize');
        // рисуем штуку, с помощью которой можно менять размеры элемента
        this.freeTransform = new joint.ui.FreeTransform({
            cellView: elementView,
            allowRotation: false,
            minWidth: minSize.width,
            minHeight: minSize.height,
            preserveAspectRatio: !!this.get('preserveAspectRatio'),
            allowOrthogonalResize: this.get('allowOrthogonalResize') !== false
        });
        this.freeTransform.render();

        // Гало
        this.halo = new joint.ui.Halo({
            cellView: elementView,
            handles: this.handles,
            loopLinkWidth: 100,
            useModelGeometry: true,
            boxContent: ''
        });
        // Если элементу нельзя иметь исходящие рёбра - то кнопку нужно скрыть
        if (!ValidationController.isOutgoingEdgeAllowed(this.node, this.outgoingEdges)) {
            this.halo.removeHandle('link');
        } else {
            this.halo.on('action:link:add', (link) => this.onLinkCreate(link));
        }

        return this.halo.render();
    }

    /**
     * Скрыть/показать контролы элемента
     * @param paper
     * @param show
     */
    public toggleControlsVisibility(paper: dia.Paper, show: boolean) {
    }

    /**
     * Действия при ПКМ на элемент
     * @param elementView элемент
     */
    public onContextMenu(elementView: ElementView) {

    }

    /**
     * Удаление элемента
     */
    public onElementRemove() {
    }

    // noinspection JSMethodCanBeStatic
    private linkCallback() {
        return undefined;
    }

    /**
     * Можно ли удалять элемент
     */
    public isDeletable(): boolean {
        return true;
    }

    /**
     * Элемент - ровный круг
     */
    public isRound(): boolean {
        return false;
    }

    /**
     * Можно перейти к элементу в новой вкладке
     */
    public isNavigable(): boolean {
        return false;
    }

    /**
     * Удалить изломы со всех ребер узла
     */
    deleteVertices() {
        this.outgoingLinks.forEach(link => link.deleteVertices());
        this.incomingLinks.forEach(link => link.deleteVertices());
    }

    /**
     * Подвигать узел, чтобы все перерисовалось
     */
    redraw() {
        this.translate(1 , 1);
        this.translate(-1 , -1);
    }

    /**
     * Линки для подсветки при наведении на этот узел
     */
    get linksToHighlight(): EruditeLink[] {
        if (!this.collection) {
            return [];
        }
        return this.collection
            .filter(cell => cell instanceof EruditeLink &&
                (cell.sourceNode.key.id == this.node.key.id || cell.targetNode.key.id == this.node.key.id));
    }

    /**
     * Узлы для подсветки при наведении на этот узел
     */
    get elementsToHighlight(): EruditeElement[] {
        return [this];
    }

    /**
     * Переключить подсветку узла
     *
     * @param enable true для включить, false - в нормальный вид
     */
    switchHighlight(enable: boolean) {
        // новый цвет
        const newColor: string = enable ? HIGHLIGHT_COLOR : getLinkColor(this.node.stepLabel.type.name, false, false);

        // стиль обводки таргет-узла
        const strokeDash: string = enable ? '1,0' : getStrokeDash(this.node.stepLabel.type.name);

        // цвет обводки
        this.prop('attrs/body/stroke', newColor);
        this.prop('attrs/body/strokeDasharray', strokeDash);
    }

    /**
     * Пропорционально сместить узел относительго заданной точки
     */
    multiplyCoordinates(rootCenter: dia.Point, horizontalFactor: number, verticalFactor: number): void {
        // пропорционально изменяем расстояние до начального узла
        const center = this.getBBox().center();
        let dx = calcOffset(center.x, rootCenter.x, horizontalFactor);
        let dy = calcOffset(center.y, rootCenter.y, verticalFactor);
        this.translate(dx, dy);
    }

    /**
     * Проставить версию проекта
     */
    setProjectVersionId(projectVersionId: string) {
        this.node.key.projectVersionId = projectVersionId;
    }

    /**
     * Все использованные в элементе id, мапа (id сущности -> (id субсущности))
     */
    get allIds(): Map<any, Set<any>> {
        const ids = new Map<any, Set<any>>();
        if (this.node.stepLabel.entityId) {
            const subIds = new Set<any>();
            ids.set(this.node.stepLabel.entityId, subIds);
            if (this.node.stepLabel.subId) {
                subIds.add(this.node.stepLabel.subId);
            }
        }
        return ids;
    }

    /**
     * Проставляются ли чекпойнты
     */
    get isCheckpointable(): boolean {
        return false;
    }

    /**
     * Редактируемый ли элемент
     */
    get isDialogable(): boolean {
        return false;
    }

    /**
     * Есть ли формулировки
     */
    get isFormulable(): boolean {
        return false;
    }
}