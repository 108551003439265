/**
 * Created by semyon on 19.09.16.
 */

export default class UnloadService {

    constructor() {
        const that = this;
        that.listeners = [];
        $(window).bind("beforeunload", (event) => {
            let message;
            /*// uncomment for debug
            try {
                for (let i = 0; i < that.listeners.length; i++) {
                    const obj = that.listeners[i];
                    const result = obj(event);
                    console.log(result && typeof result === "string");
                    if (result && typeof result === "string") {
                        message = result;
                        break;
                    }
                }
            } catch (e) {
                console.log(e);
                return e.message;
            }*/
            for (let i = 0; i < that.listeners.length; i++) {
                const obj = that.listeners[i];
                const result = obj(event);
                if (result && typeof result === "string") {
                    message = result;
                    break;
                }
            }
            if (message) {
                return message;
            }
        });
    }

    addListener(listener) {
        this.listeners.push(listener);
    }

    removeListener(listener) {
        const id = this.listeners.indexOf(listener);
        if (id !== -1) {
            this.listeners.splice(id, 1);
        }
    }

}
