import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import * as urls from "../../../../../../js/workplace/urls";
import {CheckState, CheckStateEnum, Dialog} from "../model/dialog.model";


@Component({
    selector: 'dialog-header',
    template: require('./dialog-header.component.html'),
    styles: [require('./dialog-header.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class DialogHeaderComponent implements OnInit {

    /**
     * Диалог
     */
    @Input()
    dialog: Dialog;

    /**
     * Доступ к редактированию
     */
    @Input()
    access: boolean;

    /**
     * Является ли супер-экспертом
     */
    superAccess: boolean = false;

    /**
     * Событие обновления диалога
     */
    @Output()
    private dialogChange: EventEmitter<Dialog> = new EventEmitter();


    /**
     * Событие обновления состояния
     */
    @Output()
    private stateChange: EventEmitter<CheckState> = new EventEmitter();
    /**
     * Текущая оценка
     */
    expertRating: number;

    /**
     * Оценка Суперэксперта (супервизора)
     */
    superExpertRating: number;

    /**
     * Статусы диалога (левый блок кнопок)
     */
    checkStates: CheckState[];

    error: string;

    constructor(private http: HttpClient) {

    }

    async ngOnInit(): Promise<void> {
        // выставляем исходный рейтинг эксперта (если есть) и грузим список check-states
        this.expertRating = this.dialog.expertRating ? this.dialog.expertRating : 0;
        this.superExpertRating = this.dialog.superExpertRating ? this.dialog.superExpertRating : 0;
        this.checkStates = await this.http.get<CheckState[]>(`${urls.va.analysis}checkStates`).toPromise();
        this.superAccess = await this.http.get<boolean>(`${urls.va.dialog}/superAccess`).toPromise();
    }

    /**
     * Установить статус проверки диалога
     * @param checkState статус проверки
     */
    setCheckState(checkState: CheckState) {
        if (this.isReadonly()) {
            return;
        }
        this.save({
                expertRating: this.dialog.expertRating,
                superExpertRating: this.dialog.superExpertRating,
                checkState: checkState,
                autocheck: this.dialog?.trainState?.autocheck != null ? this.dialog.trainState.autocheck : false
            }
        );
        this.stateChange.emit(checkState);
    }

    /**
     * Установить рейтинг эксперта диалогу
     * @param event событие от компоненты рейтинга
     */
    setExpertRating(event: { oldValue: number; newValue: number; }) {
        // лишний раз обновлять не будем
        if (this.checkReadonlyOrDiff(event)) {
            return;
        }
        this.save({
                expertRating: event.newValue,
                superExpertRating: this.dialog.superExpertRating,
                checkState: this.dialog?.trainState?.checkState != null ? this.dialog.trainState.checkState : null,
                autocheck: this.dialog?.trainState?.autocheck != null ? this.dialog.trainState.autocheck : false
            }
        );
    }

    /**
     * Установить рейтинг супер-эксперта диалогу
     * @param event событие от компоненты рейтинга
     */
    setSuperExpertRating(event: { oldValue: number; newValue: number; }) {
        // лишний раз обновлять не будем
        if (this.checkReadonlyOrDiff(event)) {
            return;
        }
        this.save({
                expertRating: this.dialog.expertRating,
                superExpertRating: event.newValue,
                checkState: this.dialog?.trainState?.checkState != null ? this.dialog.trainState.checkState : null,
                autocheck: this.dialog?.trainState?.autocheck != null ? this.dialog.trainState.autocheck : false
            }
        );
    }


    /**
     * Установить флаг "для автоматической проверки" диалогу
     * @param autocheck
     */
    setAutocheck(autocheck: boolean) {
        if (this.isReadonly()) {
            return;
        }
        this.save({
                expertRating: this.dialog.expertRating,
                superExpertRating: this.dialog.superExpertRating,
                checkState: this.dialog?.trainState?.checkState,
                autocheck: autocheck
            }
        );
    }

    /**
     * Сохранить результат изменения полей диалога
     */
    save(body: any): void {
        this.error = "";
        this.http.patch(`${urls.va.dialog}/${this.dialog.id}/expertRating`, body).toPromise()
            .then((dialog: Dialog) => {
                this.dialog.trainState = dialog.trainState;
                this.dialog.expertRating = dialog.expertRating;
                this.dialog.superExpertRating = dialog.superExpertRating;
                // интересующиеся должны знать, что произошло
                this.dialogChange.emit(this.dialog);
            }, (data) => {
                if (data?.errors) {
                    data?.errors.forEach(error => {
                        this.error += (error.message + "\n");
                    });
                }
            });
    }

    /**
     * Получить класс для кнопки статуса диалога
     */
    stateButtonClass(checkState: CheckState): string {
        if (!this.dialog?.trainState?.checkState && checkState.name != CheckStateEnum.UNCHECKED ||
            this.dialog?.trainState?.checkState && this.dialog?.trainState?.checkState.name != checkState.name) {
            // кнопка не выбрана
            return 'btn-default';
        }
        // кнопка выбрана
        switch (checkState.name) {
            case CheckStateEnum.UNCHECKED:
                return 'btn-info';
            case CheckStateEnum.VALID:
                return 'btn-success';
            case CheckStateEnum.CORRECTIONS_ONLY:
                return 'btn-danger';
            default:
                throw new Error(checkState.name);
        }
    }

    /**
     * true, если диалог нередактируемый
     */
    isReadonly() {
        return !this.dialog.finished || !this.access;
    }

    private checkReadonlyOrDiff(event: { oldValue: number; newValue: number }) {
        return this.isReadonly() || event.newValue == event.oldValue;
    }
}