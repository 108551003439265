import {Component, Input, ViewEncapsulation} from "@angular/core";
import {StateService} from "@uirouter/core";
import {ClusterEditService} from "../cluster-edit.service";
import {VaMessageIf} from "../../../../../../data/va/Message";
import {VaDialog} from "../../../model/tb-dialog.model";

@Component({
    selector: 'show-dialog',
    template: require('./show-dialog.component.html'),
    styles: [require('./show-dialog.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class ShowDialogComponent {

    clusterId: any;

    //tb messages диалога
    messages: VaMessageIf[] = [];

    @Input()
    dialog: VaDialog;

    @Input()
    msg: string;


    constructor(private stateService: StateService,
                private dataService: ClusterEditService) {

        this.clusterId = stateService.params['clusterId'];


        if (this.dialog) {
            this.loadMessages();
        }
    }


    /**
     * Загрузить сообщения по диалоге
     */
    loadMessages() {
        this.dataService.getDialogMessages(this.dialog.key.id)
            .then(messages => {
                for (let msg of messages) {
                    this.messages[msg.tbReplyId] = msg;
                }
            });
        this.dialog.replyList.forEach(reply => reply.clusterId = this.clusterId);
    };

    /**
     * Выделить сообщение
     * @param message
     */
    selectMessage(message) {
        if (message.replyType === 'USER') {
            message.isSelected = true;
        }
    };

    /**
     * Снять выделение сообщения
     * @param message
     * @param $event
     */
    unselectMessage(message, $event) {
        message.isSelected = false;
        $event.stopPropagation();
    };

    /**
     * Удалить тематику
     * @param reply
     * @param $event
     */
    async removeTag(reply, $event) {
        $event.stopPropagation();
        await this.dataService.removeReplyTag(reply.id);
        this.messages[reply.id].tagId = null;
        reply.tagId = null;
    };


    /**
     * Есть ли тематика у сообщения
     * @param reply
     */
    hasTag(reply) {
        let tagId = null;
        if (reply.tagId) {
            tagId = reply.tagId;
        } else {
            const message = this.messages[reply.id];
            if (message) {
                tagId = message.tagId;
            }
        }
        return !!tagId;
    };

}