import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';
import {EditableForm, EditableItemData, EditableObject} from "./editable-list.model";


@Component({
    selector: 'editable-list',
    template: require('./editable-list.component.html'),
    styles: [require('./editable-list.component.less')],
    encapsulation: ViewEncapsulation.None
})

export class EditableListComponent implements OnInit, OnChanges {
    @ContentChild('firstItemTemplate', {static: false})
    firstItemTemplate: TemplateRef<ElementRef>;

    @Input()
    objectData: EditableItemData;

    @Input()
    objects: EditableObject[];

    /**
     * Если открываем форму редактирования посреди списка, то надо его разделить на 2 части -
     */
    beforeObjects: EditableObject[];
    afterObjects: EditableObject[];

    @Input()
    form: EditableForm<EditableObject>;

    /**
     * Мапа с объектами, которые будут нужны в дальнейшем для обработки
     */
    @Input()
    sources: Map<string, any>;

    @Output()
    externalLinkAction: EventEmitter<EditableObject> = new EventEmitter<EditableObject>();

    @Output()
    onShowFormEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onCloseFormEvent: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Когда сохраняем атрибут enum, вызвать метод родителя для фильтрации доступных атрибутов
     */
    @Output()
    onSelectedValueChangedFunc = new EventEmitter<void>();

    /**
     * Пустой объект - для создания
     */
    private newObject: EditableObject;

    constructor() {
    }

    ngOnInit(): void {
        // запомним нулевой объект, нужен для того, чтобы создать новый объект нужного класса
        this.newObject = cloneDeep(this.form.objectForm);
        this.beforeObjects = this.objects;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['objects']?.currentValue) {
            this.beforeObjects = this.objects;
        }
    }

    openForm(): void {
        this.form.show = true;
        this.onShowFormEvent.emit();
        this.onCloseFormEvent.emit();
    };

    hideForm(): void {
        this.form.show = false;
        this.form.errors = new Map<string, string>();
        this.onShowFormEvent.emit();
        this.onCloseFormEvent.emit();
        this.beforeObjects = this.objects;
        this.afterObjects = [];
    };

    create(): void {
        let object = cloneDeep(this.newObject);
        object.beforeCreate(this.sources);
        this.form.objectForm = object;
        this.form.new = true;
        this.form.index = null;
        this.openForm();
    };

    edit(index: number): void {
        this.beforeObjects = this.objects.slice(0, index);
        this.afterObjects = this.objects.slice(index + 1, this.objects.length);
        let object = cloneDeep(this.objects[index]);
        object.beforeEdit(this.sources);
        this.form.objectForm = object;
        this.form.new = false;
        this.form.index = index;
        this.openForm();
    };

    /**
     * Сохраняем редактируемое значение
     * @return true - ок, false - ошибка в значении
     */
    save(): boolean {
        if (!this.form.show) {
            this.onSelectedValueChangedFunc.emit();
            // уже все сохранено
            return true;
        }
        this.form.errors = new Map<string, string>();
        let formObject = this.form.objectForm;
        // валидируем форму, если есть функция валидации
        const validatorResult = formObject.validateForm(this.form.index, this.sources);
        if (validatorResult.size === 0) {
            let object = cloneDeep(formObject.formToObject(this.sources));
            if (this.form.new) {
                // если это создание нового элемента - просто положим в конец массива
                if (this.objects == null) {
                    this.objects = [];
                }
                this.objects.push(object);
            } else {
                // если это было редактирование - заменим соответствующий элемент массива
                this.objects[this.form.index] = object;
            }
            // теперь закроем форму
            this.hideForm();
            object.afterAction(false, this.sources);
            this.onSelectedValueChangedFunc.emit();
            return true;
        } else {
            this.form.errors = validatorResult;
            return false;
        }
    };

    canDoAction(index, isDelete): boolean {
        return this.objects?.[index]?.canDoAction(isDelete, this.sources);
    };

    canDoExternalAction(index): boolean {
        return this.objects?.[index]?.canDoExternalAction(this.sources);
    };

    onExternalActionClick(item: EditableObject) {
        this.externalLinkAction.emit(item);
    };

    /**
     * Открыта сущность с индексом i из списка
     */
    isShowObject(i: number): boolean {
        return this.form.index === i && this.form.show;
    }

    delete(index): void {
        this.hideForm();
        const object = this.objects[index];
        this.objects.splice(index, 1);
        object.afterAction(true, this.sources);
        this.onSelectedValueChangedFunc.emit();
    };

    moveUp(index): void {
        if (index > 0) {
            this.hideForm();
            const previousObject = this.objects[index - 1];
            this.objects[index - 1] = this.objects[index];
            this.objects[index] = previousObject;
        }
    };

    moveDown(index): void {
        if (index < this.objects.length - 1) {
            this.hideForm();
            const nextObject = this.objects[index + 1];
            this.objects[index + 1] = this.objects[index];
            this.objects[index] = nextObject;
        }
    };
}