import {CompositeKey} from "../../../../data/va/Common";

export class TranscriptionDialog {
    key: CompositeKey<number>;
    deleted: boolean;
    fileUuid: string;
    fileName: string;
    repliesText: string;
    totalTime: number;
    creationDate: Date;
    d: Date;
    expert: any;
    status: TranscriptionStatus;
    replies: TranscriptionReply[];
    formattedLength: string;
    editingExpertId: number;
}

export class TranscriptionStatus {
    constructor(statusEnum: TranscriptionStatusEnum) {
        this.name = statusEnum;
    }

    name: TranscriptionStatusEnum;
    title: string;
}

export enum TranscriptionStatusEnum {
    LOADED = "LOADED",
    CONVERTED = "CONVERTED",
    ERROR = "ERROR",
    SENT = "SENT",
    NORMAL = "NORMAL",
    CHECKED = "CHECKED"
}

export class TranscriptionReply {
    key: CompositeKey<number>;
    deleted: boolean;
    dialogId: boolean;
    creationDate: Date;
    d: Date;
    expert: any;
    startTime: number;
    checkedReply: boolean;
    channel: number;
    edit: boolean;
    text: string;
    textCopy: string;
    error: string;
    formattedLength: string;
}

