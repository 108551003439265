import {Injectable} from "@angular/core";
import {VersionedDataService} from "../base/versioned-data.service";
import {HttpClient} from "@angular/common/http";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {VaMessage} from "../../../../data/va/Message";
import {NamedEntitiesModel} from "../model/named-entities.model";

@Injectable()
export class MessageService extends VersionedDataService<VaMessage, number> {

    baseUrl: string = '/account/expert/msg';

    readonly entityClass: NamedEntitiesModel = NamedEntitiesModel.VA_MESSAGE;

    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    /**
     * Подсчитать общее кол-во сообщений в версии проекта
     */
    async count(): Promise<number> {
        return this.httpClient.get<number>(`${this.baseUrl}/getMessageCount`, {}).toPromise();
    }

    /**
     * Поиск сообщений по тексту
     * @param query запрос
     */
    async search(query: string): Promise<any> {
        return this.httpClient.post<any>(`${this.baseUrl}/search`, {}, {params: {query: query}}).toPromise();
    }

    /**
     * Удаление сообщения
     * @param id - идентификатор сообщения
     */
    remove(id: number): Promise<string> {
        return this.httpClient.post<string>(`${this.baseUrl}/${id}/remove`, {}).toPromise();
    }

}