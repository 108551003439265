import {Formulation, WithFormulation} from "./Formulation";

/**
 * Описание: {@link ru.naumen.chat.va.data.entity.answer.VATagAnswer}
 */
export class VATagAnswer extends WithFormulation {
    name: string;
    allowEmptyMacros: boolean;
    commandParams: string;
    externalCommandResults?: any;

    constructor(object?: any) {
        super();
        if (!object) {
            return;
        }
        this.key = object.key;
        this.name = object.name;
        this.allowEmptyMacros = object.allowEmptyMacros;
        this.commandParams = object.commandParams;
        this.externalCommandResults = object.externalCommandResults;
        this.expertId = object.expertId;
        this.d = object.d;
        this.deleted = object.deleted;
        this.expert = object.expert;
        this.formulations = object.formulations ? object.formulations.map(f => new Formulation(f)) : null;
        this.taggedInfo = object.taggedInfo;
        this.setExistAllAudioRecordFiles();
    }
}