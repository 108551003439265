import {NgModule} from "@angular/core";
import {AudioRecordMasterComponent} from "./master/audio-record-master.component";
import {AudioRecordImportDialogComponent} from "../import/audio-record-import-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {CommonModule} from "@angular/common";
import {FileSaverModule} from "ngx-filesaver";
import {AudioRecordService} from "./audio-record.service";
import {CollectionComponent} from "../../common/collection/collection.component";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AudioRecordEditComponent} from "./details/audio-record-edit.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AngularMyDatePickerModule} from "angular-mydatepicker";
import {BrowserModule} from "@angular/platform-browser";
import {CollectionModule} from "../../common/collection/collection.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {EruditeAudioPlayerModule} from "../../common/player/erudite-audio-player.module";
import {EruditeFileUploaderModule} from "../../common/file-uploader/erudite-file-uploader.module";
import {BoilerplateModule} from "../../va/base/boilerplate.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {ExpertModule} from "../../common/expert/expert.module";
import {EruditeNgbDatepickerModule} from "../../common/erudite-ngb-datepicker/erudite-ngb-datepicker.module";
import {DenyButtonModule} from "../../common/deny-button/deny-button.module";
import {MatRadioModule} from "@angular/material/radio";
import {EruditeIconModule} from "../../common/erudite-icon/erudite-icon.module";

@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatProgressBarModule,
        CommonModule,
        FileSaverModule,
        UIRouterUpgradeModule,
        CollectionModule,
        FormControlModule,
        NgbModule,
        AngularMyDatePickerModule,
        EruditeAudioPlayerModule,
        EruditeFileUploaderModule,
        BoilerplateModule,
        EntityUsageModule,
        ExpertModule,
        EruditeNgbDatepickerModule,
        DenyButtonModule,
        MatRadioModule,
        EruditeIconModule
    ],
    declarations: [
        AudioRecordMasterComponent,
        AudioRecordEditComponent,
        AudioRecordImportDialogComponent
    ],
    entryComponents: [
        AudioRecordImportDialogComponent,
        CollectionComponent
    ],
    providers: [
        AudioRecordService
    ]
})
export class AudioRecordModule {

    static states = [
        {
            name: 'robot.audio_records',
            url: "/audio_records",
            views: {
                "content@authorized": {
                    component: AudioRecordMasterComponent
                }
            },
            data: {
                title: "Аудиозаписи",
                requiredProjectVersion: true,
            }
        },
        {
            name: 'robot.audio_records.edit',
            url: "/:audioRecordId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.audio_records': {
                    component: AudioRecordEditComponent
                }
            },
            data: {
                title: 'Аудиозаписи | ',
                newObjectTitle: "Новая аудиозапись",
                editObjectTitle: "Редактирование аудиозаписи",
                successfulSaveMessage: "Аудиозапись сохранена",
                meta: {
                    parameters: ["audioRecordId"],
                    sectionFunction: ([audioRecordId]) => "Настройки",
                    subSectionFunction: ([audioRecordId]) => audioRecordId ? "Редактирование аудиозаписи" : "Новая аудиозапись"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];

}