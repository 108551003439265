function DashboardRatingDirective() {
    return {
        restrict: "EA",
        scope: {
            data: "=",
        },
        bindToController: true,
        templateUrl: "/pages/workplace/va/dashboard/dashboardRating.html",
        controller: ["$scope", function ($scope) {
            const that = this;
            that.$scope = $scope;
            const ratingGradations = 5;
            that.userRating = that.data.userRating;
            that.overall = that.data.overall;
            that.update = () => {
                that.stars = [];
                for (let i = 0; i < ratingGradations; i++) {
                    that.stars.push({});
                }
                that.containerWidth = that.userRating / ratingGradations * 100;
            };
            that.$scope.$watch("ctrl.userRating", function (oldVal, newVal) {
                if (newVal) {
                    that.update();
                }
            });
        }],
        controllerAs: "ctrl"
    }
}

DashboardRatingDirective.$inject = [];
export default DashboardRatingDirective;