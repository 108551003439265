/**
 * директива сообщений формы - общих ошибок и успешного выполнения
 * Created by Olesya
 */
function AccountListDirective() {
    return {
        restrict: "EA",
        scope: {
            accounts: "=",
            title: "@"
        },
        templateUrl: "/pages/workplace/elements/accountList.html",
        controller: function () {
            var that = this;
            that.header = [
                {title: "Имя", field: "account.fullName"}
            ];
            that.fullView = false;

            this.switchView = function () {
                this.fullView = !this.fullView;
            };
        },
        bindToController: true,
        controllerAs: "dCtrl"
    };
}
export default AccountListDirective;