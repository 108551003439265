export default function SelectExtractableTypeDirective() {
    return {
        restrict: 'EA',
        controllerAs: "setctrl",
        transclude: true,
        templateUrl: "/pages/workplace/components/selectExtractableType.html",
        scope: {
            object: "="
        },
        bindToController: true,
        controller: ["$scope", SelectExtractableTypeDirectiveController]
    }
}

class SelectExtractableTypeDirectiveController {

    private scope: angular.IScope;
    private object: string;

    constructor($scope: ng.IScope) {
        this.scope = $scope;
    }

    onSelectTypeChanged() {
        this.scope.$emit("SelectExtractableTypeDirective.onSelectTypeChanged", this.object);
    }

}