import {Component, Input, OnInit} from "@angular/core";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {BaseDetailsComponent, DetailsMode, Form} from "../../base/base-details.component";
import {TagsComponent} from "../list/tags.component";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {ValueOption} from "../../../../../data/va/Extractor";
import {SmaService} from "../../sma/sma.service";
import {VASimpleModuleAnswer} from "../../../../../data/va/SimpleModuleAnswer";
import {Account} from "../../../../../data/va/Account";

@Component({
    selector: 'robot-answers',
    template: require('./robot-answers.component.html'),
    styles: [require('./robot-answers.component.less')]
})
export class RobotAnswersComponent implements OnInit {

    @Input()
    channels: ValueOption[];

    @Input()
    sma: VASimpleModuleAnswer;

    access: boolean;

    account: Account;

    /**
     * Форма
     */
    public form = new Form<VASimpleModuleAnswer>();
    isLoading: boolean = true;

    constructor(public stateService: StateService,
                public smaService: SmaService,
                public master: TagsComponent,
                protected httpClient: HttpClient,
                protected titleService: Title,
                protected notificationService: NotificationService) {
    }

    async ngOnInit() {
        this.form.object = this.sma;
        this.access = await this.smaService.getAccessForObject(this.sma);
        this.isLoading = false;
    }

    async save() {
        if (!this.form.object.formulations || this.form.object.formulations.length === 0) {
            this.form.errors.clear();
            this.form.success = false;
            this.notificationService.error("Необходимо задать формулировку");
            return;
        }
        this.form.errors.clear();

        this.isLoading = true;

        let savePromise = this.smaService.update(this.form.object);

        await this.finishSave(savePromise);
    }

    /**
     * Окончание сохранения
     * @param savePromise   promise сохранения
     */
    async finishSave(savePromise) {
        try {
            this.form.object = await savePromise;
            this.form.success = true;
            this.notificationService.success('Успешно сохранено');
        } catch (e) {
            BaseDetailsComponent.showErrors(e, this.notificationService);
        }
        this.isLoading = false;
    }

    get entityTitle(): string {
        return this.form.object.vaAct.title as any;
    }

    generateFormObject(): VASimpleModuleAnswer {
        return new VASimpleModuleAnswer();
    }

    showRemove(): boolean {
        return false;
    }
}

