import {VaAttribute} from "../../../../data/va/Attribute";
import {VaTag} from "../../../../data/va/Tag";
import {ExtractedValue} from "./extracted-value.model";

export class DialogContext {
    id: number;
    projectVersionId: string;
    tagConfirmNeedExtractedInfo: NeedExtractedInfo;
    mainTagContext: TagContext;
    nestedTagContext: TagContext;
    questionAnswerExtracted: boolean;
    extractedValues: ExtractedValue[];
    prompter: boolean;
}

export class NeedExtractedInfo {
    infoId: number;
    qReplyId: number;
    type: InfoType;
    confirmedInfoValue: string;
    lastAttr: VaAttribute;
}

export class TagContext {
    tagId: number;
    needExtractedInfo: NeedExtractedInfo;
    tag: VaTag;
}

export class InfoType {
    maxLastLife: number;
    title: string;
    name: InfoTypeEnum;
}

export enum InfoTypeEnum {
    ATTRIBUTE = "ATTRIBUTE",
    ATTRIBUTE_CONFIRMATION = "ATTRIBUTE_CONFIRMATION",
    TAG_CONFIRMATION = "TAG_CONFIRMATION",
    PROCEDURE = "PROCEDURE"


}