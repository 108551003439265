import {Pipe, PipeTransform} from '@angular/core';

/**
 * Размер файла в стандартном формате: 213 Б, 2,3 МБ и т.д.
 */
@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {

    transform(size) {
        if (!size && size != 0) {
            return '';
        }
        const fullKilos = Math.floor(size / KILO);
        if (fullKilos == 0) {
            return size + ' Б';
        }
        const megas = +(fullKilos / KILO).toFixed(1);
        const fullMegas = Math.floor(megas);
        if (fullMegas == 0) {
            return fullKilos + ' КБ';
        }
        const gigas = +(fullMegas / KILO).toFixed(2);
        const fullGigas = Math.floor(gigas);
        if (fullGigas == 0) {
            return megas + ' МБ';
        }
        return gigas + ' ГБ';
    }
}

const KILO = 1024;

