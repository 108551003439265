import {Component} from "@angular/core";

@Component({
    selector: 'logins-master',
    template: require('./logins-master.component.html'),
    styles: [require('./logins-master.component.less')]
})
export class LoginsMasterComponent {

}
