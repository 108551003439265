import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Model} from "../../../../data/va/Model";
import {ModelStatusInfoDto, ModelStatusListAndAccessDto} from "../../../../controllers/va/ml/models/ml.model";

@Injectable()
export class MlService {
    loadModelsEvent: EventEmitter<any>  = new EventEmitter<any>();

    baseUrl: string = "/account/expert/ml/models";

    constructor(private httpClient: HttpClient) {
    }

    async getRebuildModel(typeName: string): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/rebuildModel/${typeName}`).toPromise();
    }

    async getRebuildModels(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/rebuildModels`).toPromise();
    }

    async getMlComponentTypesAndAccess(): Promise<ModelStatusListAndAccessDto> {
        return await this.httpClient.get<ModelStatusListAndAccessDto>(`${this.baseUrl}/mlComponentTypesAndAccess`).toPromise();
    }

    async getInitPython(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/initPython`).toPromise();
    }

    async getInitModels(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/initModels`).toPromise();
    }

    async getLastUpdateDate(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/lastUpdateDate`).toPromise();
    }

    async getModelsByType(modelType: string): Promise<Model[]> {
        return await this.httpClient.get<Model[]>(`${this.baseUrl}/models/${modelType}`).toPromise();
    }

    async createModelByType(modelType: string): Promise<Model> {
        return await this.httpClient.get<Model>(`${this.baseUrl}/models/${modelType}/create`).toPromise();
    }

    async runAutocheck(): Promise<any> {
        return await this.httpClient.post(`${this.baseUrl}/runAutocheck`, {}).toPromise();
    }

    async getAutocheckProgress(): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseUrl}/autocheckProgress`, {}).toPromise();
    }

    async getAccess(): Promise<boolean> {
        let data = await this.httpClient.get<any>(`${this.baseUrl}/access`).toPromise();
        return data.access;
    }
}