import {CompositeKey} from "../../../../data/va/Common";
import {ClusterVaTag, TreeOptions} from "./tb-params.model";

export class TbDialogModel {
    dialogId: number;
    viewMode: any;
}

export class VaDialog {
    bad: boolean;
    key: CompositeKey<number>;
    replyList: any[];
    startTime: number;
    sufficiency : boolean;
}

export class TbTagCreation{
    parent: ClusterVaTag;
    viewMode: any;
    treeOptions: TreeOptions;
}