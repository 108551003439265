import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {TagService} from "../tag.service";
import {SortField} from "../../../../../data/va/Common";
import {ItemTypeNumber, VATaggedItem, VATaggedItemType} from "../../model/tagged-item-list.model";


@Component({
    selector: 'tagged-item-list',
    template: require('./tagged-item-list.component.html'),
    styles: [require('./tagged-item-list.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class TaggedItemListComponent implements OnInit {

    @Input()
    tagId: number;

    sortFields: SortField[] = [new SortField('Название', 'text')];
    taggedItemsMap: any[] = [];
    title: string = "Связанные компоненты";
    icon: boolean[];

    constructor(protected tagService: TagService) {
    }

    ngOnInit(): void {
        this.tagService.getTaggedItems(this.tagId).then((data: VATaggedItem[]) => this.sortEntities(data));
    }

    click(typeNumber: any) {
        if (typeNumber) {
            typeNumber.isExpanded = !typeNumber.isExpanded;
        }
    }

    /**
     * Сортировка загруженных объектов по ключам
     */
    sortEntities(data: VATaggedItem[]) {
        data.forEach(el => {
            // Добавим массивы по ключам, чтобы потом туда складывать сущности
            let enumNumeric = this.getEnumNumeric(el.type);
            if (!this.taggedItemsMap[enumNumeric]) {
                this.taggedItemsMap[enumNumeric] = [];
            }
            this.taggedItemsMap[enumNumeric].push(el);
        });
        // Теперь для каждого ключа загрузим связанные сущности, чтобы показывать название ответа/атрибута/сущности
        Object.keys(this.taggedItemsMap).forEach(typeNumber => {
            this.loadTaggedEntities(Number.parseInt(typeNumber));
            this.taggedItemsMap[typeNumber].isExpanded = false;
        });
    }

    /**
     * Получение имени теггированной сущности
     */
    loadTaggedEntities(typeNumber: number) {
        let enumType = this.getEnumByNumber(typeNumber);
        if (this.taggedItemsMap[typeNumber]) {
            const entityIds: number[] = this.taggedItemsMap[typeNumber].map(element => element.itemId);
            this.tagService.loadTaggedEntities(this.getEntityUrl(enumType), entityIds)
                .then((data) => {
                    data.forEach(object => {
                        this.taggedItemsMap[typeNumber].find(element => element.itemId === object.key.id).title = object[this.getEntityNameKey(enumType)];
                    });
                });
        }
    }

    /**
     * Получить URL по типу теггированной сущности
     */
    getEntityUrl(type: string): string {
        switch (<any>VATaggedItemType[type]) {
            case VATaggedItemType.ANSWER:
                return "/account/expert/tagAnswer";
            case VATaggedItemType.ATTRIBUTE:
                return "/account/expert/attribute";
            case VATaggedItemType.DKB:
                return "/account/expert/dkb";
            case VATaggedItemType.PROCEDURE:
                return "/account/expert/procedure";
            default:
                break;
        }
    }

    /**
     * Получить ключ, по которому доступно имя сущности по типу теггированной сущности
     */
    getEntityNameKey(type: string): string {
        switch (<any>VATaggedItemType[type]) {
            case VATaggedItemType.ANSWER:
            case VATaggedItemType.ATTRIBUTE:
            case VATaggedItemType.PROCEDURE:
                return 'name';
            case VATaggedItemType.DKB:
                return `entityName`;
            default:
                break;
        }
    }

    /**
     * Получаем русскую интерпретацию типа данных
     * @param type - тип связанного элемента
     */
    getTitle(type: string): string {
        return VATaggedItemType[type];
    }

    /**
     * получаем стейт для редиректа на связанный элемент
     * @param type - тип элемента
     */
    getState(type: string): string {
        switch (<any>VATaggedItemType[type]) {
            case VATaggedItemType.ANSWER:
                return "robot.answer.edit";
            case VATaggedItemType.ATTRIBUTE:
                return "robot.attributes.edit";
            case VATaggedItemType.DKB:
                return "robot.dkb.edit";
            case VATaggedItemType.PROCEDURE:
                return "robot.script_procedure.view";
        }
    }

    /**
     * получаем параметры для редиректа на связанный элемент
     * @param type - тип связанного элемента
     * @param obj - сам элемент
     */
    getStateParams(type: string, obj: any): any {
        switch (<any>VATaggedItemType[type]) {
            case VATaggedItemType.ANSWER:
                return {answerId: obj.itemId};
            case VATaggedItemType.ATTRIBUTE:
                return {attrId: obj.itemId, mode: 'SHOW'};
            case VATaggedItemType.DKB:
                return {dkbEntityId: obj.itemId};
            case VATaggedItemType.PROCEDURE:
                return {procedureId: obj.itemId, type: 'procedure'};
        }
    }

    /**
     * есть ли связанные элементы с тематикой
     */
    isEmpty(): boolean {
        return !(this.taggedItemsMap && this.taggedItemsMap.length > 0);
    }

    /**
     * получаем порядковый номер типа связанного элемента
     * @param type - тип связанного элемента
     */
    getEnumNumeric(type: string): number {
        return ItemTypeNumber[type];
    }

    /**
     * получаем тип элемента по порядковому номеру типа
     * @param typeNumber - порядковый номер типа элемента
     */
    getEnumByNumber(typeNumber: number): string {
        return ItemTypeNumber[typeNumber];
    }

}


