export default function ExtractedEntityDescriptionTableDirective() {
    return {
        restrict: 'EA',
        controllerAs: "descctrl",
        transclude: true,
        templateUrl: "/pages/workplace/components/extractedEntityDescTable.html",
        scope: {
            description: "="
        },
        bindToController: true,
        controller: ["$scope", "$sce", ExtractedEntityDescriptionTableDirectiveController]
    }
}

class ExtractedEntityDescriptionTableDirectiveController {

    private description;

    private sce;

    constructor($scope: ng.IScope, $sce: ng.ISCEService) {
        this.sce = $sce;
    }

    isArray(obj: any): boolean {
        return obj instanceof Array;
    }

    trustAsHtml(obj: any) {
        return this.sce.trustAsHtml(obj);
    }

}