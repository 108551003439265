const VaTagNodeDroppableComponent = {
    templateUrl: "/pages/workplace/va/common/components/tagNodeDroppable.html",
    bindings: {
        node: "<",
        onDrop: "&",
        mode: "<",
        onFocusClick: "&",
        onRememberClick: "&",
        onCreateClick: "&",
        access: "<"
    },
    controller: ["Restangular", "$scope", "$stateParams", "$timeout", function (restangular, $scope, $stateParams, $timeout) {
        const that = this;

        that.restangular = restangular;
        that.$scope = $scope;
        that.droppedObject = {};

        that.dropOptions = {classes: {'ui-droppable-hover': 'highlight-drop'}};

        that.expandNode = function (nodeId) {
            const parentScopes = getScopePath(nodeId);
            for (let i = 0; i < parentScopes.length; i++) {
                parentScopes[i].expand();
            }
        };

        that.collapseNode = function (nodeId) {
            const parentScopes = getScopePath(nodeId);
            for (let i = 0; i < parentScopes.length; i++) {
                parentScopes[i].collapse();
            }
        };

        that.drop = function (event, ui) {
            that.onDrop({event: event, ui: ui, node: that.node, object: that.droppedObject});
        };

        that.dropInner = function (event, ui, node, object) {
            that.onDrop({event: event, ui: ui, node: node, object});
        };

        that.focus = function () {
            that.onFocusClick({tag: that.node});
        };

        that.focusInner = function (tag) {
            that.onFocusClick({tag: tag});
        };

        that.remember = function () {
            that.onRememberClick({tag: that.node});
        };

        that.rememberInner = function (tag) {
            that.onRememberClick({tag: tag});
        };

        that.create = function () {
            that.onCreateClick({parent: that.node});
        };

        that.createInner = function (parent) {
            that.onCreateClick({parent: parent});
        };

        function getRootNodesScope() {
            return angular.element(document.getElementById("tag-tree-root")).scope().$nodesScope.childNodes()[0];
        }

        function getScopePath(nodeId) {
            return getScopePathIter(nodeId, getRootNodesScope(), []);
        }

        function getScopePathIter(nodeId, scope, parentScopeList) {
            if (!scope) {
                return null;
            }

            var newParentScopeList = parentScopeList.slice();
            newParentScopeList.push(scope);

            if (scope.$modelValue && scope.$modelValue.key.id === nodeId) return newParentScopeList;

            var foundScopesPath = null;
            var childNodes = scope.childNodes();

            if (!childNodes) {
                return null;
            }

            for (var i = 0; foundScopesPath === null && i < childNodes.length; i++) {
                foundScopesPath = getScopePathIter(nodeId, childNodes[i], newParentScopeList);
            }

            return foundScopesPath;
        }

        that.depth = function () {
            return that.$scope.$parent.depth();
        };

        that.toggle = function () {
            that.$scope.$parent.toggle(that.$scope.$parent);
        };

        that.collapsed = function () {
            return that.$scope.$parent.collapsed;
        };

        that.toggleAll = function (node, collapse) {
            // node -- нода из библиотеки angular tree
            if (node.depth() === 1) {
                if (node.collapsed) {
                    that.expandAll();
                } else {
                    that.collapseAll();
                }
                that.toggle();
                return;
            }
            if (typeof collapse === "undefined") {
                node.toggle();
                collapse = node.collapse;
            } else if (node.collapsed !== collapse && node.childNodesCount() > 0) {
                node.toggle();
            }
            let childNodes = node.childNodes();
            for (let i = 0; i < node.childNodesCount(); i++) {
                let childNode = childNodes[i];
                that.toggleAll(childNode, collapse);
            }
        };

        that.expandAll = function () {
            that.$scope.$broadcast('angular-ui-tree:expand-all');
        };

        that.collapseAll = function () {
            that.$scope.$broadcast('angular-ui-tree:collapse-all');
        };

        that.$scope.$on('angular-ui-tree:collapse-all-but-root', function (event) {
            if (that.depth() === 1) {
                that.collapseAll();
            }
        });

        that.$scope.$on('angular-ui-tree:filter-nodes', function (event, args) {
            if (!args.hide && args.hasChildren) {
                that.expandNode(args.tagId);
            } else if (args.hide && args.hasChildren) {
                that.collapseNode(args.tagId);
            }
        });

        $timeout(function () {
            if ($scope.selectedId != null && $scope.selectedId != 0) {
                that.expandNode($scope.selectedId);
            }
        }, 100);
    }]
};

export default VaTagNodeDroppableComponent;