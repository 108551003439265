import {NgModule} from "@angular/core";
import {EruditeFileUploader} from "./file-uploader.component";
import {CommonModule} from "@angular/common";
import {FileUploadModule} from "./uploader-vendor/file-upload.module";
import {FormControlModule} from "../form/form-control.module";

@NgModule({
    declarations: [
        EruditeFileUploader
    ],
    imports: [
        FileUploadModule,
        CommonModule,
        FormControlModule
    ],
    exports: [
        EruditeFileUploader
    ]
})
export class EruditeFileUploaderModule {

}