// запись об обращении в истории
function HistoryBlockingDirective() {
    return {
        restrict: "EA",
        scope: {
            title: "@",
            timeFormat: "<",
            conversation: "="
        },
        templateUrl: "/pages/workplace/elements/historyBlocking.html",
        controller: function () {
        },
        bindToController: true,
        controllerAs: "$ctrl"
    };
}

export default HistoryBlockingDirective;