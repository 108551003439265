import {Component} from "@angular/core";
import {BaseDetailsComponent} from "../../base/base-details.component";
import {AttemptDTO, VaExtractionContext} from "../../../dialog/model/correction.model";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {AttemptService} from "../attempt.service";
import {HttpClient} from "@angular/common/http";
import {AttemptMasterComponent} from "../master/attempt-master.component";
import {Extractor} from "../../../../../data/va/Extractor";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'va-attempt-edit',
    template: require('./attempt-details.component.html'),
    styles: [require('./attempt-details.component.less')]
})

export class AttemptDetailsComponent extends BaseDetailsComponent<AttemptDTO, number> {
    objectIdKey = 'attemptId';
    private readonly extractorId: number;
    extractionContexts: VaExtractionContext[];
    info: Extractor;

    constructor(protected stateService: StateService,
                protected master: AttemptMasterComponent,
                protected httpClient: HttpClient,
                public dataService: AttemptService,
                protected titleService: Title,
                protected notificationService: NotificationService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
        this.extractorId = stateService.params['id'];
    }

    async ngOnInit() {
        super.ngOnInit();
        let data: any;
        data = await this.dataService.getInfoExtractor(this.extractorId);

        if (data.scope != null) {
            data.type = "ATTRIBUTE";
        } else if (data.name === "CONFIRMATION") {
            data.type = "CONFIRMATION";
        }
        this.info = data;

        // Если создаем новый пример и в фильтре было выбрано значение -> заносим его в этот details компонент
        let selectedValueOption = this.stateService.params['selectedValueOption'];
        if (this.isModeNew() && selectedValueOption && this.form.object) {
            this.form.object.value = data.valueOptions.find(value => value.apiKey == selectedValueOption);
            this.master.checkFilterData(this.form.object.value);
        }
    }

    get entityTitle(): string {
        if (this.isModeNew()) {
            return "Новый пример извлечения";
        }
        return this.form.object.fullText;
    }

    generateFormObject(): AttemptDTO {
        this.dataService.loadExtractionContexts().then((data) => {
            this.extractionContexts = data;
        });
        return new AttemptDTO();
    }

    showCancel(): boolean {
        return true;
    }

    get removeConfirmMessage() {
        return `Вы уверены, что хотите удалить пример?`;
    }

    onObjectLoaded() {
        this.form.object = new AttemptDTO(this.form.object);
        this.dataService.loadExtractionContexts().then((data) => {
            this.extractionContexts = data;
        });
    }

    validate(): boolean {
        this.form.errors.clear();
        if (!this.form.object.fullText) {
            this.form.errors.set('fullText', "Не заполнен текст реплики");
        }
        if (!this.form.object.value) {
            this.form.errors.set('value', "Не заполнено значение");
        }
        if (!this.form.object.extractionContext) {
            this.form.errors.set('extractionContext', "Не заполнен контекст извлечения");
        }
        return this.form.errors.size == 0;
    }

    async save(preserveState?: boolean): Promise<void> {
        if (this.validate()) {
            this.form.object.strValue = this.form.object.value.key.id.toString();
            this.form.object.extractorId = this.extractorId;
            await super.save(preserveState);
        }
    }
}