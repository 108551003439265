import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {JournalsFilter} from "../journals.model";

export abstract class JournalsDetailsComponent<T> {
    object: any[];
    filter: JournalsFilter;
    page = {pageNum: 1, totalSize: 0, pageSize: 8};

    /**
     * Происходит ли сейчас загрузка чего-нибудь
     */
    public isLoading: boolean = false;

    objects: T[];

    protected constructor(titleService: Title,
                          protected stateService: StateService) {
        const title: string = this.stateService.current.data.title;
        titleService.setTitle(title);
        if (stateService.params['filter']) {
            try {
                this.filter = JSON.parse(stateService.params['filter']);
                this.filter.from = this.dateToString(this.filter.fromDate);
                this.filter.to = this.dateToString(this.filter.toDate);
                this.filter.fromDate = null;
                this.filter.toDate = null;
                this.filter.page = this.page;
            } catch (e) {
            }
        }
    }

    dateToString(date: NgbDate) {
        if (date) {
            let dateObject = new Date(date.year, date.month - 1, date.day);
            return dateObject.getTime();
        }
        return null;
    }

    fromJson(object) {
        if (object) {
            try {
                return JSON.parse(object);
            } catch (e) {
                return null;
            }
        }
        return null;
    }
}
