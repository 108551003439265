import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DialogTapeComponent} from "./dialog-tape.component";
import {DialogService} from "./dialog.service";
import {ReplyTagComponent} from "./reply-tag/reply-tag.component";
import {ReplyExtractedValueComponent} from "./reply-extracted-value/reply-extracted-value.component";
import {ReplyCheckpointComponent} from "./reply-checkpoint/reply-checkpoint.component";
import {HighlightTextModule} from "../common/highlight-text/highlight-text.module";
import {MatListModule} from "@angular/material/list";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {ReplyTagCorrectionDialogComponent} from "./reply-tag/reply-tag-correction-dialog.component";
import {TagTreeModule} from "../va/tag/tree/tag-tree.module";
import {MatDialogModule} from "@angular/material/dialog";
import {ReplyExtractedValueCorrectionBottomSheetComponent} from "./reply-extracted-value/reply-extracted-value-correction-bottom-sheet.component";
import {CorrectionMenuBottomSheetComponent} from "./correction-menu/correction-menu-bottom-sheet.component";
import {DialogInfoPanelComponent} from "./info-panel/dialog-info-panel.component";
import {DialogBackToStatComponent} from "./back-to-stat/dialog-back-to-stat.component";
import {DialogHeaderComponent} from "./header/dialog-header.component";
import {DialogAutocheckComponent} from "./autocheck/dialog-autocheck.component";
import {FormControlModule} from "../common/form/form-control.module";
import {EruditeAudioPlayerModule} from "../common/player/erudite-audio-player.module";
import {RatingModule} from "ng-starrating";
import {AttemptModule} from "../va/attempt/attempt.module";
import {DialogCopyDialogComponent} from "./dialogcopy/dialog-copy-dialog.component";
import {PrompterOptionsComponent} from "./prompter-options/prompter-options.component";
import {ScriptErrorDialogComponent} from "./script-error/script-error-dialog.component";
import {EditableListModule} from "../common/editable-list/editable-list.module";

@NgModule({
    declarations: [
        DialogTapeComponent,
        ReplyExtractedValueComponent,
        ReplyTagComponent,
        ReplyCheckpointComponent,
        CorrectionMenuBottomSheetComponent,
        ReplyTagCorrectionDialogComponent,
        ReplyExtractedValueCorrectionBottomSheetComponent,
        DialogInfoPanelComponent,
        DialogBackToStatComponent,
        DialogHeaderComponent,
        DialogAutocheckComponent,
        DialogCopyDialogComponent,
        PrompterOptionsComponent,
        ScriptErrorDialogComponent,
    ],
    imports: [
        CommonModule,
        UIRouterModule,
        HighlightTextModule,
        MatListModule,
        NgSelectModule,
        FormsModule,
        TagTreeModule,
        MatDialogModule,
        FormControlModule,
        EruditeAudioPlayerModule,
        RatingModule,
        AttemptModule,
        DragDropModule,
        EditableListModule
    ],
    exports: [
        DialogTapeComponent,
        ReplyExtractedValueComponent,
        ReplyTagComponent,
        ReplyCheckpointComponent,
        CorrectionMenuBottomSheetComponent,
        ReplyTagCorrectionDialogComponent,
        ReplyExtractedValueCorrectionBottomSheetComponent,
        DialogInfoPanelComponent,
        DialogBackToStatComponent,
        DialogHeaderComponent,
        DialogAutocheckComponent,
        DragDropModule,
        PrompterOptionsComponent
    ],
    entryComponents: [
        DialogTapeComponent,
        ReplyExtractedValueComponent,
        ReplyTagComponent,
        ReplyCheckpointComponent,
        CorrectionMenuBottomSheetComponent,
        ReplyTagCorrectionDialogComponent,
        ReplyExtractedValueCorrectionBottomSheetComponent,
        DialogInfoPanelComponent,
        DialogBackToStatComponent,
        DialogHeaderComponent,
        DialogAutocheckComponent,
        DialogCopyDialogComponent,
        PrompterOptionsComponent,
        ScriptErrorDialogComponent
    ],
    providers: [
        DialogService
    ]
})
export class DialogModule {

}
