import restangular from "restangular";
import * as urls from "../../../../js/workplace/urls.js";
import * as common from "../../../../js/workplace/common.js";

enum WidgetType {
    ACTIVE_SESSIONS,
    FINISHED_SESSIONS,
    ROUTED_TO_OPERATOR,
    USER_RATING,
    PROCESSED_WITHOUT_OPERATOR,
    SESSIONS,
    TAGS,
    SESSIONS_WITH_PROMPTER,
    OPERATOR_OPTION,
    MAX_PROBABILITY_OPTION,
    NOT_MAX_PROBABILITY_OPTION,
    SENTIMENT
}

interface GridsterOptions {
    columns: number;
    rowHeight: number;
    pushing: boolean;
    floating: boolean;
    swapping: boolean
    sparse: boolean,
    margins: number[],
    mobileBreakPoint: number,
    mobileModeEnabled: boolean,
    draggable: any;
    resizable: any;
}

interface Widget {
    id: number,
    projectId: string,
    title: string;
    col: number,
    row: number,
    minSizeY: number,
    minSizeX: number,
    deleted: boolean,
    type: any,
    charts: any[];
    data: any;
    resizable: boolean;
    cssClass: string;
    value: any;
    text: string;
}

export default class VaDashboardController {
    // Интервал обновления в миллисекундах
    private readonly refreshInterval: number = 300000;
    // Временной интервал отображения на графике по сессиям в часах
    private timelineSize: number = 24;
    private dashboardService: any;
    private gridsterOptions: GridsterOptions;
    private widgets: Widget[];
    private availableWidgets: Widget[];
    private restAngular: restangular.IService;
    private tCommon: any;
    private tUrls: any;
    private baseUrl: string;
    private $timeout: any;
    private data: any;
    private $q: any;
    private events: any;
    private visible: boolean;
    private $scope: any;
    private isLoading: boolean;
    private $stateParams: any;
    private lastUpdatedDate: Date;
    private $interval: any;
    private $state: ng.ui.IStateService;
    static $inject = ["Restangular", "$state", "$scope", "$timeout", "TitleService", "$q", "DashboardService", "$stateParams", "$interval"];

    constructor(Restangular, $state, $scope, $timeout, TitleService, $q, DashboardService, $stateParams, $interval) {
        TitleService.setTitle();
        const that = this;
        that.dashboardService = DashboardService;
        that.restAngular = Restangular;
        that.$timeout = $timeout;
        that.tUrls = urls;
        that.baseUrl = that.tUrls.va.dashboard;
        this.$state = $state;
        that.tCommon = common;
        that.gridsterOptions = that.dashboardService.getGridsterOptions();
        that.data = {};
        that.$q = $q;
        that.$scope = $scope;
        that.$stateParams = $stateParams;
        that.$interval = $interval;
        that.visible = false;

        // При событии изменения размера - апдейтим график
        that.events = {
            resize: (e, scope) => {
                that.$timeout(() => {
                    scope.api.update();
                }, 200)
            }
        };

        angular.element(window).on('resize', function (e) {
            $scope.$broadcast('resize');
        });

        that.loadDashboard(() => {
            that.$interval(() => that.loadWidgetsData(true), that.refreshInterval);
        })
    }

    /**
     * Загрузка dashboard'a
     */
    public loadDashboard(callback: any): void {
        const that = this;
        that.isLoading = true;
        // Загрузка данных для виджетов
        let dataPromise = that.loadWidgetsData(false);
        // Загрузка виджетов, которые могут быть добавлены на dashboard
        let availableWidgetsPromise = that.loadAvailableWidgets();
        // После загрузки данных и виджетов, грузим сам dashboard и добавляем к ним загруженные данные
        that.$q.all([dataPromise, availableWidgetsPromise]).then(() => {
            that.tCommon.loadValues(that, that.baseUrl, "widgets", (data: Widget[]) => {
                // Добавляем к виджетам данные
                data.forEach((widget: Widget) => that.addDataToWidget(widget));
                that.widgets = that.restAngular.stripRestangular(data);
                that.isLoading = false;
                that.lastUpdatedDate = new Date();
                callback();
            }, error => {
                if (error.status == 403) {
                    this.$state.go('^');
                }
            }, true);
        });
    }

    /**
     * Собрать данные и другую инфу для виджетов
     */
    public addDataToWidget(widget: Widget): void {
        const that = this;
        switch (<any>WidgetType[widget.type.name]) {
            case WidgetType.ACTIVE_SESSIONS:
                widget.data = that.data[widget.type.name];
                widget.cssClass = 'fa-comments purple';
                widget.value = widget.data.value;
                break;
            case WidgetType.FINISHED_SESSIONS:
                widget.data = that.data[widget.type.name];
                widget.cssClass = 'fa-lock aqua';
                widget.value = widget.data.overall === 0 ? '0%' : (widget.data.value / widget.data.overall * 100).toFixed(0) + '%';
                widget.text = 'Завершено: ' + widget.data.value + ' из ' + widget.data.overall + ' сессий';
                break;
            case WidgetType.ROUTED_TO_OPERATOR:
                widget.data = that.data[widget.type.name];
                widget.cssClass = 'fa-user orange';
                widget.value = widget.data.overall === 0 ? '0%' : (widget.data.value / widget.data.overall * 100).toFixed(0) + '%';
                widget.text = 'Переведено на оператора: ' + widget.data.value + ' из ' + widget.data.overall + ' сессий';
                break;
            case WidgetType.USER_RATING:
                widget.data = that.data[widget.type.name];
                break;
            case WidgetType.PROCESSED_WITHOUT_OPERATOR:
                widget.data = that.data[widget.type.name];
                widget.cssClass = 'fa-android blue';
                widget.value = widget.data.overall === 0 ? '0%' : (widget.data.value / widget.data.overall * 100).toFixed(0) + '%';
                widget.text = 'Обработано без оператора: ' + widget.data.value + ' из ' + widget.data.overall + ' сессий';
                break;
            case WidgetType.SESSIONS:
            case WidgetType.TAGS:
                widget.charts = [{
                    data: that.filterDataByTimelineSize(widget),
                    options: that.dashboardService.getChartOptions(widget.type.name),
                    api: {},
                    onReady: ({api}) => {
                        // nvd3-angular fix https://github.com/krispo/angular-nvd3/issues/454#issuecomment-478047172
                        let el: JQuery = api.getElement();
                        let svgElement = el.find("svg");

                        // подождём, пока у элемента SVG появится нормальная ширина в пикселях (а не дефолтные 100%)
                        // и рефрешнем его, чтобы нарисовать
                        let refresher = () => {
                            this.$timeout(() => {
                                if (svgElement.css('width') == '100%') {
                                    refresher();
                                } else {
                                    api.refresh();
                                }
                            }, 100);
                        };
                        refresher();
                    }
                }];
                break;
            case WidgetType.SENTIMENT:
                const chartOptions = that.dashboardService.getChartOptions(widget.type.name);
                chartOptions.chart.valueFormat = (d) => `${Math.round(d)}`;

                widget.charts = [{
                    data: that.data[widget.type.name],
                    options: chartOptions,
                    api: {},
                    onReady: ({api}) => {
                        // nvd3-angular fix https://github.com/krispo/angular-nvd3/issues/454#issuecomment-478047172
                        let el: JQuery = api.getElement();
                        let svgElement = el.find("svg");

                        // подождём, пока у элемента SVG появится нормальная ширина в пикселях (а не дефолтные 100%)
                        // и рефрешнем его, чтобы нарисовать
                        let refresher = () => {
                            this.$timeout(() => {
                                if (svgElement.css('width') == '100%') {
                                    refresher();
                                } else {
                                    api.refresh();
                                }
                            }, 100);
                        };

                        // Добавим к названию виджета количество диалогов
                        const dialogCount: number = that.data[widget.type.name].reduce((a, b) => a + Number(b.count), 0);
                        widget.charts[0].options.title.text = dialogCount > 0 ? 'Кол-во диалогов: ' + dialogCount : '';

                        refresher();
                    }
                }];
                break;
            case WidgetType.SESSIONS_WITH_PROMPTER:
                widget.data = that.data[widget.type.name];
                widget.cssClass = 'fa-exchange shakespeare';
                widget.value = widget.data.value;
                break;
            case WidgetType.MAX_PROBABILITY_OPTION:
                widget.data = that.data[widget.type.name];
                widget.cssClass = 'fa-pie-chart cerulean';
                widget.value = widget.data.overall === 0 ? '0%' : (widget.data.value / widget.data.overall * 100).toFixed(0) + '%';
                break;
            case WidgetType.NOT_MAX_PROBABILITY_OPTION:
                widget.data = that.data[widget.type.name];
                widget.cssClass = 'fa-pie-chart boston-blue';
                widget.value = widget.data.overall === 0 ? '0%' : (widget.data.value / widget.data.overall * 100).toFixed(0) + '%';
                break;
            case WidgetType.OPERATOR_OPTION:
                widget.data = that.data[widget.type.name];
                widget.cssClass = 'fa-pie-chart bright-turquoise';
                widget.value = widget.data.overall === 0 ? '0%' : (widget.data.value / widget.data.overall * 100).toFixed(0) + '%';
                break;
            default:
                break;
        }
    }

    /**
     * Добавление виджета на dashboard
     */
    public addWidget(widget: Widget): void {
        const that = this;
        that.isLoading = true;
        // Сохраняем
        that.restAngular.one(that.baseUrl)
            .post("", widget)
            .then((data: Widget) => {
                // Очистим от прелестей restangular'a
                let newWidget: Widget = that.restAngular.stripRestangular(data);
                // Снабжаем данным (их мы всё время держим)
                that.addDataToWidget(newWidget);
                // Удалим предыдущее положение
                VaDashboardController.clearPosition(newWidget);
                // После того, как добавили виджет, его местоположение определит gridster
                that.widgets.push(newWidget);
                // Но делает он это не сразу - этого нужно дождаться
                let unbind = that.$scope.$watch(() => newWidget.row,
                    (newValue, oldValue) => {
                        if (!angular.isUndefined(newValue)) {
                            // Поэтому обновим виджет
                            that.updateWidget(newWidget);
                            // Подсветим границы, если добавление произошло в режиме настройки dashboard'a
                            that.switchWidgetHighlight(that.gridsterOptions.draggable.enabled);
                            // И уберём watch
                            unbind();
                        }
                    });

                // Перезагрузим список доступных виджетов
                that.loadAvailableWidgets();
                that.isLoading = false;
            });
    }

    /**
     * Изменить интервал времени, за который отображается информация на графике по сессиям
     **/
    public changeTimelineSize(widget: Widget, timelineSize: number): void {
        const that = this;
        that.timelineSize = timelineSize;
        widget.charts.forEach(chart => {
            chart.api.updateWithData(that.filterDataByTimelineSize(widget));
            chart.api.refresh();
        });
    }

    /**
     * Отфильтровать данные по времени
     */
    public filterDataByTimelineSize(widget: Widget): any {
        const that = this;
        if (!(that.timelineSize < 24)) {
            return that.data[widget.type.name];
        }
        let dataCopy = angular.copy(that.data[widget.type.name]);
        // Найдём последнюю вычисленную точку, для этого из общих данных достанем только значения
        let lastTimestamp = dataCopy.map(obj => obj.values)
            // Получим последние (нам всё равно какие, потому что везде timestamp одинаковый)
            .pop()
            // Из пары значений сделаем просто массив timestamp'ов
            .map(values => values.x)
            .pop();
        let after = new Date(lastTimestamp - 1000);
        after.setHours(after.getHours() - that.timelineSize);
        dataCopy.forEach(series => {
            series.values = series.values.filter(point => new Date(point.x) >= after);
        });
        return dataCopy;
    }

    /**
     * Удаление виджета с dashboard
     */
    public deleteWidget(widget: Widget): void {
        const that = this;
        that.isLoading = true;
        // Сохраняем
        that.restAngular.one(that.baseUrl, widget.id)
            .remove()
            .then((success: any) => {
                // После того, как отметили удалённым в базе - удалим с интерфейса
                that.widgets.splice(that.widgets.indexOf(widget), 1);
                // И проапдейтим все виджеты, потому что их позиция могла измениться
                that.$timeout(() => that.updateAllWidgets(), 200);
                // Перезагрузим список доступных к добавлению виджетов
                that.loadAvailableWidgets();
                that.isLoading = false;
            }, (error: any) => {
            });
    }

    /**
     * Обновить один виджет
     */
    public updateWidget(widget: Widget): void {
        const that = this;
        that.restAngular.one(that.baseUrl)
            .patch(widget)
            .then((data: Widget) => {
            });
    }

    /**
     * Обновить все виджеты
     */
    public updateAllWidgets(): void {
        const that = this;
        that.restAngular.one(that.baseUrl + '/updateAll')
            .patch(that.widgets)
            .then((successData) => {
            }, (errorData) => {
            })
    }

    /**
     * Загрузить данные для виджетов
     * @param {boolean} reload если это перезагрузка, то нужно ещё рассортировать её и записать время последнего обновления
     */
    public loadWidgetsData(reload: boolean): any {
        const that = this;
        that.isLoading = true;
        return that.restAngular.one(that.baseUrl, 'data').get()
            .then((data: any) => {
                let purifiedData = that.restAngular.stripRestangular(data);
                Object.keys(purifiedData).forEach(key => {
                    that.data[key] = purifiedData[key];
                });
                if (reload) {
                    that.widgets.forEach(widget => that.addDataToWidget(widget));
                    that.isLoading = false;
                    that.lastUpdatedDate = new Date();
                }
                that.visible = true;
            }, error => {
                if (error.status == 403) {
                    this.$state.go('^');
                }
            });
    }

    /**
     * Загрузить список доступных к добавлению виджетов
     */
    public loadAvailableWidgets(): any {
        const that = this;
        return that.restAngular.one(that.baseUrl, 'availableWidgets').get()
            .then((widgets: Widget[]) => {
                that.availableWidgets = that.restAngular.stripRestangular(widgets);
            });
    }

    /**
     * Включить/выключить режим настройки dashboard'a
     */
    public configureDashboard(): void {
        const that = this;
        that.gridsterOptions.draggable.enabled = !that.gridsterOptions.draggable.enabled;
        that.gridsterOptions.pushing = !that.gridsterOptions.pushing;
        that.gridsterOptions.swapping = !that.gridsterOptions.swapping;
        that.switchWidgetHighlight(that.gridsterOptions.draggable.enabled);
        // Если закончили настройку, то апдейтим все виджеты
        if (!that.gridsterOptions.draggable.enabled) {
            that.updateAllWidgets();
        }
    };

    /**
     * Переключить подсветку у виджета
     */
    public switchWidgetHighlight(enable: boolean): void {
        angular.forEach(angular.element('li.gridster-widget'), element => {
            if (enable) {
                angular.element(element).addClass('move-widget')
                    .addClass("setup-widget");
            } else {
                angular.element(element).removeClass('move-widget')
                    .removeClass("setup-widget");
            }
        });
    }

    /**
     * Сбросить сохранённую позицию у виджета
     */
    public static clearPosition(widget: Widget): void {
        delete widget.col;
        delete widget.row;
    }

    get getProjectId(): string {
        return this.$stateParams['projectVersionId'].replace(new RegExp("\\.(.*)"), '')
    }
}
