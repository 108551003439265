export default class TitleService {

    constructor($rootScope, $state, $window, $transitions) {
        const that = this;
        that.$state = $state;
        that.$window = $window;
        that.$rootScope = $rootScope;

        $transitions.onSuccess({}, (transition) => {
            let toState = transition.$to();
            if (toState.self.title) {
                this.$window.document.title = toState.title;
            }
        });


    }

    setTitle(subTitle) {
        const that = this;
        that.$window.document.title = this.$state.current.title + (subTitle ? subTitle : '');
    }
}

TitleService.$inject = ["$rootScope", "$state", "$window", "$transitions"];