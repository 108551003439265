import {Component} from "@angular/core";

@Component({
    selector: 'changes-master',
    template: require('./changes-master.component.html'),
    styles: [require('./changes-master.component.less')]
})
export class ChangesMasterComponent {

}
