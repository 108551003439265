import {BaseFormController} from "../../../js/workplace/controllers/BaseFormController";
import TabService from "../../../js/workplace/services/TabService";
import * as restangular from "restangular";
import TitleService from "../../../js/workplace/services/TitleService";

export default class LoginController extends BaseFormController {

    private tabService: TabService;
    private loginRest: restangular.ICollection;
    private stateParams: angular.ui.IStateParamsService;
    
    private reason: string;

    private form: {[key: string]: any};
    private $scope: angular.IScope;
    private $state: angular.ui.IStateService;

    constructor(scope: angular.IScope, rootScope: angular.IScope, state: angular.ui.IStateService,
                stateParams: angular.ui.IStateParamsService, restangular: restangular.IService,
                tabService: TabService, titleService: TitleService) {
        super(scope, state);

        titleService.setTitle();
        this.tabService = tabService;
        this.loginRest = restangular.all("/account/login");
        this.stateParams = stateParams;

        let initialStateParamsString = this.stateParams["params"];
        let initialStateParams: any = {};
        if (initialStateParamsString) {
            initialStateParams = JSON.parse(initialStateParamsString);
            this.reason = initialStateParams.reason;
        }
    }

    login() {
        // будем отображать что мы сейчас что-то грузим
        this.setLoading(true);
        
        // пробуем запостить данные логина
        this.loginRest.post(this.form)
            .then((data) => {
                this.handleSuccess(data);
                // рассылаем событие логина
                this.tabService.broadcast("login", data);
                let parentController = this.$scope['rCtrl'];
                // отправить главному контроллеру сообщение об авторизации
                parentController.onAuthorization(data, () => {
                    // по умолчанию переходим к первому пункту меню
                    let initialState = this.stateParams["nextUrl"];
                    let initialStateParamsString = this.stateParams["params"];
                    let initialStateParams = {};
                    if (initialStateParamsString) {
                        initialStateParams = JSON.parse(initialStateParamsString);
                    }

                    if (!initialState || initialState.startsWith("login")) {
                        const menu = parentController.menu[0];
                        initialState = menu.submenu[0]?.url ? `${menu.url}.${menu.submenu[0].url}` : menu.url;
                    }
                    this.$state.go(initialState, initialStateParams);
                });
            }, (data) => {
                this.handleError(data);
            });
    }
}
// логин контроллер получает профиль пользователя и меню
LoginController.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "Restangular", "TabService", "TitleService"];