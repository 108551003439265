function NgStickyDirective($window) {

    let firstStart;
    
    return {
        restrict: 'EA',
        link: function (scope, element) {
            let start,
                $win = angular.element($window);

            let parent = element.parent();

            firstStart = element.offset().top;

            element.addClass('stuck');

            element.width(parent.width());

            let jsElement = element[0];
            
            jsElement.style.top = `${firstStart}px`;

            $win.on('scroll', function () {

                const scroll = $win.scrollTop();

                if (scroll > firstStart) {
                    jsElement.style.top = "0";
                } else {
                    jsElement.style.top = `${firstStart}px`;
                }
            });

            $win.on('resize', function recheckPositions() {
                element.width(parent.width());
            });
        }
    }
}

NgStickyDirective.$inject = ["$window"];

export default NgStickyDirective;