import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {VersioningProgressBarComponent} from "./versioning-progress-bar.component";
import {NgbProgressbarModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        VersioningProgressBarComponent
    ],
    imports: [
        BrowserModule,
        NgbProgressbarModule,
    ],
    exports: [
        VersioningProgressBarComponent
    ]
})
export class VersioningProgressBarModule {

}