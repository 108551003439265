import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {CollectionModule} from "../../common/collection/collection.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {EruditeFileUploaderModule} from "../../common/file-uploader/erudite-file-uploader.module";
import {MatDialogModule} from "@angular/material/dialog";
import {SourceSelectorComponent} from "./source-selector/source-selector.component";
import {SourceSelectorService} from "./source-selector/source-selector.service";
import {MessageClustersComponent} from "./message-clusters-list/message-clusters.component";
import {ExpertModule} from "../../common/expert/expert.module";
import {ClusterInfoComponent} from "./message-clusters-list/cluster-info/cluster-info.component";
import {MessageClustersService} from "./message-clusters-list/message-clusters.service";
import {ClusterInfoService} from "./message-clusters-list/cluster-info/cluster-info.service";
import {ClusterEditService} from "./cluster-edit/cluster-edit.service";
import {ClusterEditComponent} from "./cluster-edit/cluster-edit.component";
import {ShowDialogComponent} from "./cluster-edit/show-dialog/show-dialog.component";
import {TagTreeModule} from "../tag/tree/tag-tree.module";
import {ClusterTagTreeComponent} from "./cluster-edit/cluster-tag-tree/cluster-tag-tree.component";
import {MatTreeModule} from "@angular/material/tree";
import {ClusterTagNodeComponent} from "./cluster-edit/cluster-tag-tree/cluster-tree-node/cluster-tag-node.component";
import {EditableListModule} from "../../common/editable-list/editable-list.module";
import {ClusterTagTreeService} from "./cluster-edit/cluster-tag-tree/cluster-tag-tree.service";
import {DndModule} from "ngx-drag-drop";
import {TagDialogComponent} from "./cluster-edit/cluster-create-node/tag-dialog.component";


@NgModule({
    declarations: [
        SourceSelectorComponent,
        MessageClustersComponent,
        ClusterInfoComponent,
        ClusterEditComponent,
        TagDialogComponent,
        ShowDialogComponent,
        ClusterTagTreeComponent,
        ClusterTagNodeComponent
    ],
    imports: [
        BrowserModule,
        CollectionModule,
        FormsModule,
        FormControlModule,
        EruditeFileUploaderModule,
        NgSelectModule,
        BoilerplateModule,
        UIRouterUpgradeModule,
        MatDialogModule,
        ExpertModule,
        TagTreeModule,
        MatTreeModule,
        EditableListModule,
        DndModule
    ],
    exports: [TagDialogComponent],
    providers: [
        SourceSelectorService,
        MessageClustersService,
        ClusterInfoService,
        ClusterEditService,
        MessageClustersComponent,
        ClusterTagTreeService
    ],
    entryComponents: [
        TagDialogComponent
    ]
})
export class TagBuildingModule {

    static states = [
        {
            name: "robot.tb_source_selector",
            url: "/tb_source_selector",
            views: {
                "content@authorized": {
                    component: SourceSelectorComponent
                }
            },
            data: {
                title: "Построение тематик",
                requiredProjectVersion: true
            }
        },
        {
            name: "robot.tb_message_clusters",
            url: "/:sourceTagId/tb_message_clusters",
            views: {
                "content@authorized": {
                    component: MessageClustersComponent
                }
            },
            data: {
                title: "Построение тематик",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        },
        {
            name: "robot.tb_message_clusters_edit",
            url: "/:sourceTagId/tb_message_clusters/:workMode/:clusterId/:modifyProcessState?smi=",
            params: {notifySuccess: {dynamic: true}},
            views: {
                "content@authorized": {
                    component: ClusterEditComponent
                }
            },
            data: {
                title: "Построение тематик",
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }]

}