import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {FileItem} from "../../common/file-uploader/uploader-vendor/file-item.class";
import {
    CheckImportScriptResult,
    CheckImportScriptResultType,
    ImportingComponent,
    ImportScriptResult,
    ScriptImportIntersectionResolveType
} from "./script-import-util";
import {readErrorDescription} from "../../../../../util/Utils";
import StatisticsService from "../../../../services/StatisticsService";
import {StateService} from "@uirouter/core";

export interface ScriptImportDialogData {
    dialogTitle: string;
    baseUrl: string;
    importer: ImportingComponent;
}

@Component({
    selector: 'script-import-dialog',
    template: require('./script-import-dialog.component.html'),
    styles: [require('./script-import-dialog.component.less')]
})
export class ScriptImportDialogComponent {

    /**
     * Файл со скриптом
     */
    importFile: File;

    /**
     * Данные о загрузке для прогресс-бара
     */
    isLoading: boolean;

    form: any = {
        success: false,
        errors: new Map<string, string>()
    };

    /**
     * Результат проверки скрипта перед импортом
     */
    checkResult: CheckImportScriptResult;

    /**
     * Результат импорта
     */
    result: ImportScriptResult;

    constructor(private http: HttpClient,
                public dialogRef: MatDialogRef<ScriptImportDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ScriptImportDialogData,
                private statisticsService: StatisticsService,
                private stateService: StateService) {
    }

    /**
     * В инпут файла выбрали файл
     */
    onImportFileChange(items: FileItem[]) {
        if (!items) {
            this.importFile = null;
        } else {
            this.importFile = items.pop()._file;
        }
    }

    /**
     * Загрузка файла со сценарием/процедурой
     */
    upload() {
        const formData = new FormData();
        formData.append('importFile', this.importFile);

        this.isLoading = true;
        this.http.post(`${this.data.baseUrl}/import`, formData).toPromise()
            // успех
            .then((data: CheckImportScriptResult) => {
                    this.checkResult = data;
                    if (data.type == CheckImportScriptResultType.OK) {
                        // все ок, продолжаем импорт
                        this.continueImport(ScriptImportIntersectionResolveType.UPDATE_IMPORTED)
                    }
                },
                // ошибка
                (data) => this.processImportError(data))
            .catch(reason => {
                // неотловленная ошибка
                this.onImportError(reason);
            })
            .finally(() => this.isLoading = false)
    }

    /**
     * Произошла ошибка при импорте
     */
    private processImportError(data) {
        readErrorDescription(data, errorDescription => {
            if (errorDescription) {
                this.onImportError(errorDescription);
            } else {
                this.onImportError(data)
            }
        });
    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     */
    onFileAddFailed($event: string) {
        this.form.errors.set("#main", $event);
        setTimeout(() => this.form.errors.set("#main", null), 5000);
    }

    /**
     * Отобразить результаты загрузки
     */
    private showResults(result: ImportScriptResult) {
        this.result = result;
        if (result.errors && result.errors.length > 0) {
            // отображаем ошибку
            const errorText = result.errors.map(error => error.message).join('\n');
            this.onImportError(errorText);
            return;
        }
        // после закрытия диалога при успешном импорте переходим на редактирование скрипта
        this.data.dialogTitle = 'Импорт завершен';
        this.data.importer.afterSuccessfulImport(this.result.meta.mainId);
    }

    /**
     * Ошибка импорта
     */
    onImportError(errorText: string) {
        this.dialogRef.close();
        this.data.importer.showError(errorText)
    }

    /**
     * Продолжить загрузку
     */
    continueImport(resolveType: string) {
        const params = {dir: this.checkResult.dir, resolveType: resolveType};
        this.checkResult = null;
        this.isLoading = true;
        this.http.post(`${this.data.baseUrl}/continueImport`, {}, {params: params}).toPromise()
            .then((data: ImportScriptResult) => {
                    // успех
                    this.showResults(data);
                    // статистика
                    this.statisticsService.registerOpenEvent(this.stateService.current, this.stateService.params, {
                        action: 'script_import',
                        type: data.meta.targetMainEntityType.name == 'SCENARIOS' ? 'scenario' : 'procedure',
                        scriptId: data.meta.mainId
                    });
                },
                // ошибка
                (data) => this.processImportError(data))
            .catch(reason => {
                // неотловленная ошибка
                this.onImportError(reason);
            })
            .finally(() => this.isLoading = false)
    }

    /**
     * Получить описание типа пересечения уникальных полей с уже существующими сущностями
     */
    getIntersectionDesc(type: CheckImportScriptResultType) {
        switch (type) {
            case CheckImportScriptResultType.API_KEYS_INTERSECT:
                return 'API ключи';
            case CheckImportScriptResultType.NAMES_INTERSECT:
                return 'названия';
            case CheckImportScriptResultType.NAMES_API_KEYS_INTERSECT:
                return 'API ключи и названия';
            case CheckImportScriptResultType.OK:
            default:
                throw Error(type);
        }
    }
}
