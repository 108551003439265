import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {CollectionModule} from "../../common/collection/collection.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {ExpertModule} from "../../common/expert/expert.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {TextDataService} from "./text-data.service";
import {TextDataMasterComponent} from "./master/text-data-master.component";
import {TextDataEditComponent} from "./details/text-data-edit.component";
import {EditableListModule} from "../../common/editable-list/editable-list.module";


@NgModule({
    declarations: [
        TextDataMasterComponent,
        TextDataEditComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        UIRouterUpgradeModule,
        CollectionModule,
        FormControlModule,
        ExpertModule,
        BoilerplateModule,
        EditableListModule
    ],
    exports: [
        TextDataMasterComponent,
        TextDataEditComponent,
    ],
    providers: [
        TextDataService
    ]
})
export class TextDataModule {

    static states = [
        {
            name: "robot.text_data",
            url: "/text_data/:groupName",
            views: {
                'content@authorized': {
                    component: TextDataMasterComponent
                }
            },
            data: {
                title: "Настройки",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.text_data.edit',
            url: "/:dataId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.text_data': {
                    component: TextDataEditComponent
                }
            },
            data: {
                title: "Настройки | ",
                newObjectTitle: "",
                editObjectTitle: "Редактирование настройки",
                successfulSaveMessage: "Настройка сохранена",
                meta: {
                    parameters: ["dataId"],
                    sectionFunction: ([dataId]) => "Обучение робота",
                    subSectionFunction: ([dataId]) => dataId ? "Редактирование настройки" : ""
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];

}