import urls from "../../urls";
import common from "../../common";

export class VABaseListController {

    loadObjectsFunction;
    loadCallback;
    loading = false;

    constructor(TitleService, Restangular, $stateParams, baseUrl, loadObjectsFunction = null, loadCallback = null) {
        this.loadObjectsFunction = loadObjectsFunction;
        this.loadCallback = loadCallback;
        this.$stateParams = $stateParams;
        const that = this;
        TitleService.setTitle();
        // список
        that.objects = [];
        // базовый url
        that.baseUrl = baseUrl;
        // рестангуляр
        that.restAngular = Restangular;

        that.loadAccount();


        this.loadList();
    }

    loadList() {
        if (this.loadObjectsFunction != null) {
            this.loadObjectsFunction(this);
        } else {
            if (this.loadObjectsFunction != null) {
                this.loadObjectsFunction(this);
            } else {
                this.loading = true;
                this.restAngular.all(this.baseUrl).getList()
                    .then((objects) => {
                        this.objects = objects;
                        this.loadCallback && this.loadCallback(objects);
                    }, (data) => {
                    }).finally(() => this.loading = false);
            }
        }
    }

    // добавить ответ в список
    addNewObj(o) {
        this.objects.push(angular.copy(o));
    }

    // заменить ответ по ID (например после обновление в форме редактирования
    replaceObj(o) {
        for (let i = 0; i < this.objects.length; i++) {
            const obj = this.objects[i];
            let eq = this.objectEquals(obj, o);
            if (eq) {
                // копируем объект, чтобы отвязать объект в списке, от объекта в форме редактирования
                angular.copy(o, this.objects[i]);
                break;
            }
        }
    }

    // удалить ответ из списка
    deleteObj(o) {
        for (let i = 0; i < this.objects.length; i++) {
            const obj = this.objects[i];
            let eq = this.objectEquals(obj, o);
            if (eq) {
                // удалить объект из массива
                this.objects.splice(i, 1);
                break;
            }
        }
    }

    objectEquals(obj1, obj2) {
        if (obj1.key) {
            return obj1.key.id === obj2.key.id;
        } else if (obj1.name) {
            return obj1.name === obj2.name;
        } else {
            return obj1.id === obj2.id;
        }
    }

    // получение текущего аккаунта
    loadAccount() {
        const that = this;
        that.restAngular.one(urls.va.account).one("current").get()
            .then(function success(data) {
                that.account = data;
                that.account.isJunior = function () {
                    return that.account.expertType.name == 'JUNIOR';
                };
            }, function error(data) {
            });
    }

    getAccess() {
        common.getAccess(this.restAngular, this.baseUrl, this);
    }

    exportUsagesFile($http, fileSaver, ctrl) {
        ctrl.exportUsagesError = false;
        $http.post(this.baseUrl + 'exportUsages?projectVersionId=' + this.projectVersionId, {}, {
            responseType: 'arraybuffer'
        }).success((data, status, headers) => {
            const file = new Blob([data], {type: "application/vnd.ms-excel"});
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const disposition = headers('Content-Disposition');
            const matches = filenameRegex.exec(disposition);
            let fileName;
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            } else {
                fileName = "usages.xlsx";
            }
            fileSaver.saveAs(file, fileName);
        }).error(() => {
            //покажем сообщение об ошибке при загрузке файла
            ctrl.exportUsagesError = true;
        });
    }

}

VABaseListController.$inject = ["TitleService", "Restangular", "$stateParams"];
