import {Component, Input} from "@angular/core";
import {VaTag, TagIdsEnum} from "../../../../../data/va/Tag";

@Component({
    selector: 'tag-node',
    template: require('./tag-node.component.html'),
    styles: [require('./tag-node.component.less')]
})
export class TagNodeComponent {

    @Input()
    tag: VaTag;

    @Input()
    isSelected: boolean = false;

    @Input()
    displayIcons: boolean = true;

    @Input()
    searchString: string;

    SMALLTALK_ID: number = TagIdsEnum.SMALL_TALK_ID_ROOT;

    constructor() {
    }

}
