import urls from "../../urls";

const VaTrendDetectionOutputComponent = {
        templateUrl: "/pages/workplace/va/common/components/vaTrendDetectionOutput.html",
        bindings: {
            data: "<",
        },
        controller: ["Restangular", "$stateParams", function (restangular, $stateParams) {
            const that = this;

            that.treeOptions = {
                nodeChildren: "children",
                dirSelectable: true
            };

            that.restAngular = restangular;

            that.display = false;

            that.data.keywords[0].children = [
                {
                    text: "test 231",
                    occurredDialogIds: [7571559507099648, 7572193787576320, 7571644609527808]
                },
                {
                    text: "Zdraste",
                    occurredDialogIds: [7571559507099648, 7572193787576320, 7571644609527808],
                    children: [{
                        text: "Randomly generated reply",
                        occurredDialogIds: [7571559507099648, 7572193787576320, 7571644609527808]
                    }, {
                        text: "What?",
                        occurredDialogIds: [7571559507099648, 7572193787576320, 7571644609527808]
                    }]
                },
                {
                    text: "Gepard",
                    occurredDialogIds: [7571559507099648, 7572193787576320, 7571644609527808]
                }
            ];

            that.formatTime = function (timestamp) {
                return moment(timestamp).format("DD.MM.YYYY");
            };


        }]
    }
;

export default VaTrendDetectionOutputComponent;