import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {VaScriptNode} from "../../../../../data/va/Script";
import * as urls from "../../../../../../../js/workplace/urls";
import {HttpClient} from "@angular/common/http";
import {RerouteParam, TextData} from "../../../va/text-data/text-data.model";

@Component({
    selector: 'reroute-params-dialog',
    template: require('./reroute-params-dialog.component.html'),
    styles: [require('./reroute-params-dialog.component.less')]
})
export class RerouteParamsDialogComponent {

    /**
     * Выбранные параметры перенаправления
     */
    public rerouteParams: RerouteParam;

    /**
     * Список возможных параметров перенаправления
     */
    public rerouteParamsList: RerouteParam[] = [];

    public configurable: boolean;

    /**
     * Если мы кликнули на серую область вне окна, то шлем флаг deny: true
     * @private
     */
    private clickOnCdkOverlayBackDrop: boolean = true;

    public isLoading: boolean = true;

    constructor(private http: HttpClient, public dialogRef: MatDialogRef<RerouteParamsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { node: VaScriptNode }) {

    }

    ngOnInit(): void {
        this.init();
    }

    ngOnDestroy(): void {
        if (this.clickOnCdkOverlayBackDrop) {
            this.dialogRef.close({deny: true});
        }
    }

    onDeny(): void {
        this.clickOnCdkOverlayBackDrop = false;
        this.dialogRef.close({deny: true});
    }

    onSave(): void {
        this.clickOnCdkOverlayBackDrop = false;
        this.dialogRef.close(this.rerouteParams);
    }

    private async init() {
        this.http.get(`${urls.va.textdata}/rerouteParams`).toPromise().then((textData: TextData) => {
            this.rerouteParamsList = (new TextData(textData)).getRerouteParams(false);
            this.configurable = this.rerouteParamsList.length > 0;
            if (this.data.node.stepLabel.commandParams) {
                this.rerouteParams = this.rerouteParamsList.find(param => param.id == this.data.node.stepLabel.commandParams);
                if (!this.rerouteParams) {
                    this.rerouteParams = new RerouteParam(this.data.node.stepLabel.commandParams, "Удалено")
                }
            }
            this.isLoading = false;
        });
    }
}
