import {Component, Input} from "@angular/core";


@Component({
    selector: 'tab',
    template: require('./tab.component.html'),
    styles: [require('./tab.component.less')]
})
export class TabComponent {

    @Input()
    title: string;

    @Input()
    id: string;

    @Input()
    active: boolean;
}