/**
 * директива работы с редактируемыми списками
 * Created by Olesya
 */
function EditableListDirective() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            // как мы будем называть наши объектах в заголовках и на кнопках
            itemTypeTitle: "@",
            // css класс для панели
            boxClass: "@",
            // коллекция, которую мы изменяем
            listModel: "=",
            // объект формы (передаем снаружи, т.к. к нему привязаны поля формы)
            formModel: "=",
            // модель данных, необходимых для построения/разборки объектов при перекладывании их из коллекции в форму и обратно
            modelScope: "=",
            // показывать ли футер (состав футера - в <span>)
            useListFooter: "=",
            // функция, которая превращает элемент коллекции в текст item'а
            formatFunction: "=",
            // функция, которая может валидировать данные из формы
            validateFunction: "=",
            // эти две функции позволяют иметь разные модели объектов в коллекции и в форме
            // builder собирает из объекта формы элемент для коллекции, а parse - наоборот
            // в обе функции передается modelScope
            builderFunction: "=",
            /**
             *  функция преобразования объекта массива в объект формы
             */
            parseFunction: "=",
            /**
             *  функция для преобразования пустого объекта при добавлении нового объекта в список
             */
            preAddFunction: "=",
            /**
             *  функция для преобразования пустого объекта при добавлении нового объекта в список
             */
            preEditFunction: "=",
            /**
             * нельзя удалять
             */
            blockDelete: "=",
            /**
             * нельзя редактировать
             */
            blockEdit: "=",
            /**
             * нельзя создавать
             */
            blockCreate: "=",
            /**
             * нельзя передвигать
             */
            blockMove: "=",
            /**
             * выделять ли выбранное на редактирование
             */
            showSelected: "=",
            /**
             * добавляем строку, а не объект
             * надо удалить
             */
            simpleCollection: "=",
            /**
             * исходный объект для проброса в вызывающий сервис
             * надо удалить,Ю везде пробрасывается ссылкана контроллер текущий, из него можно найти вызывающий сервис
             */
            srcObject: "=",
            /**
             * функция постобработки после сохранения/удаления элемента списка
             */
            postFunction: "=",
            /**
             * функция для проверки возможности редактировать конкретный элемент
             */
            canChangeItemFunction: "=",
            /**
             * нужно ли закрыть заголовок формы с названием действия (добавить изменить)
             */
            hideActionTitle: "=",
            /**
             * тип извлекаемой сущности
             */
            externalLinkAction: "&?",
            externalLinkIcon: "@?"
        },
        transclude: {
            'formBody': 'div',
            'listFooter': '?span'
        },
        templateUrl: "/pages/shared/elements/editableList.html",
        controller: ["$scope", function ($scope) {
            let that = this;
            that.$scope = $scope;
            // дефолтный класс для панели
            if (that.boxClass == null) {
                that.boxClass = "success";
            }
            var validate = this.validateFunction;
            var build = this.builderFunction;
            var parse = this.parseFunction;
            var post = this.postFunction;

            that.showForm = function () {
                that.formModel.show = true;
                that.formModel.errors = {hasErrors: false};
            };

            that.hideForm = function () {
                that.formModel.show = false;
            };

            that.addItem = function () {
                if (that.simpleCollection) {
                    that.formModel.data = "";
                } else {
                    that.formModel.data = {};
                }
                if (this.preAddFunction) {
                    //если есть функция предобработки, то запустим ее
                    this.preAddFunction(that.formModel.data, that);
                }
                that.formModel.new = true;
                that.formModel.idx = null;
                that.showForm();
            };

            that.editItem = function (idx) {
                that.loadData(idx);
                that.showForm();
            };

            that.loadData = function (idx) {
                // положим в модель копию объекта, запомним, из какой позиции мы его взяли
                // если есть функция преобразования объекта массива в объект формы - вызовем ее, иначе - просто скопируем объект
                that.formModel.data = parse ? parse(that.listModel[idx], that.modelScope, that) : angular.copy(that.listModel[idx]);
                that.formModel.new = false;
                that.formModel.idx = idx;
            };

            that.saveItem = function () {
                // валидируем форму, если есть функция валидации
                // передадим также имеющуюся коллекцию
                that.formModel.errors = validate ? validate(that.formModel, that.listModel, that.srcObject, that) : {hasErrors: false};

                // если ошибок нет - соберем объект и зафигачим его в массив
                if (!that.formModel.errors.hasErrors) {
                    // если есть функция, которая должна преобразовать модель формы в объект для массива - вызвать ее, если такой функции нет - просто передать данные формы
                    var itemElement = build ? build(that.formModel.data, that.modelScope, that) : that.formModel.data;
                    if (that.formModel.new) {
                        // если это создание нового элемента - просто положим в конец массива
                        if (that.listModel == null) {
                            that.listModel = [];
                        }
                        that.listModel.push(itemElement);
                    } else {
                        // если это было редактирование - заменим соответствующий элемент массива
                        that.listModel[that.formModel.idx] = itemElement;
                    }
                    // теперь закроем форму
                    that.hideForm();
                    if (post) {
                        post(that);
                    }
                }
            };

            that.onLinkClick = function (item) {
                this.externalLinkAction({item: item});
            };

            /**
             * можно ли редактировать элемент
             * @param idx индекс элемента
             * @param isDelete удаление или редактирование
             */
            that.canChangeItem = function (idx, isDelete) {
                //если функция передана
                if (!this.canChangeItemFunction) {
                    return true;
                }
                const item = that.listModel[idx];
                return this.canChangeItemFunction(item, isDelete, this);
            };

            that.removeItem = function (idx) {
                that.hideForm();
                that.listModel.splice(idx, 1);
                if (post) {
                    post(that);
                }
            };

            that.moveUpItem = function (idx) {
                if (idx > 0) {
                    that.hideForm();
                    var previous = that.listModel[idx - 1];
                    that.listModel[idx - 1] = that.listModel[idx];
                    that.listModel[idx] = previous;
                    close();
                }
            };

            that.moveDownItem = function (idx) {
                if (idx < that.listModel.length - 1) {
                    that.hideForm();
                    var next = that.listModel[idx + 1];
                    that.listModel[idx + 1] = that.listModel[idx];
                    that.listModel[idx] = next;
                    close();
                }
            };

            $scope.$on("editable-list-reload-obj", (event) => {
                if (that.formModel.data) {
                    that.loadData(that.formModel.idx);
                }
            });
        }],
        bindToController: true,
        controllerAs: "dCtrl"
    };
}

export default EditableListDirective;