import {Component, EventEmitter, Input, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {MatExpansionPanel} from "@angular/material";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, first} from "rxjs/operators";
import {StepLabelEntity, StepLabelType, StepLabelTypeName} from "../../../../data/va/Script";
import {setBlockView} from "../ScriptBuilderUtils";

@Component({
    selector: "step-label-section",
    template: require('./step-label-section.component.html')
})
export class StepLabelSectionComponent implements OnInit {

    /**
     * Тип перехода
     */
    @Input()
    private stepLabelType: StepLabelType;

    /**
     * Список всех доступных сущностей данного типа
     */
    @Input()
    private entities: StepLabelEntity[];

    /**
     * Идентификатор текущей тематики
     */
    @Input()
    private tagId: number;

    /**
     * Текущая тематика
     */
    private currentTag: StepLabelEntity;

    /**
     * Поисковый запрос из родительской компоненты
     */
    @Input()
    private searchQuery: string;

    /**
     * Компонента текущей секции из accordion'a
     */
    @ViewChild(`stepLabelSection`, {static: true})
    private section: MatExpansionPanel;

    /**
     * Данные для отображения в секции в раскрытом виде
     */
    private content: any[];

    /**
     * Спрятана ли текущая секция? (изменяется при поиске, когда внутри секции нет подходящих результатов)
     */
    private visible: boolean = true;

    /**
     * Раскрыта ли текущая секция пользователем?
     */
    private expanded: boolean = false;

    /**
     * Пользователь нажал на опцию "Показать всё"?
     */
    private showAllClicked: boolean = false;

    /**
     * Есть ли в данной секции тэггированные сущности
     */
    private hasTaggedEntities: boolean;

    /**
     * Событие схлопывания секции
     */
    private collapseEvent: EventEmitter<void>;

    /**
     * Событие изменения видимости секции
     */
    public visibilityChange: EventEmitter<void> = new EventEmitter();

    /**
     * Observer поискового запроса из родительской компоненты
     */
    private searchQueryObserver: Subject<string> = new Subject();

    /**
     * Анимация закрытия  секции в процессе?
     */
    private isCloseAnimationInProgress: boolean = false;

    /**
     * Поиск в активной стадии?
     */
    private isSearchActive: boolean = false;

    /**
     * Функция обработки начала drag'a блока
     */
    private onDragStart: (entity: StepLabelEntity) => void = setBlockView;

    constructor() {

    }

    ngOnInit(): void {
        this.setContent(!this.hasTaggedEntities);
        this.collapseEvent = this.section.afterCollapse;
        this.searchQueryObserver.pipe(
            debounceTime(200),
            distinctUntilChanged())
            .subscribe(searchQuery => {
                // Если поисковый запрос меньше 2х символов, то ничего не делаем
                this.isSearchActive = searchQuery != null && searchQuery.length > 2;
                // Пользователь начал искать - и эта секция не перевод в ТП, то фильтруем содержимое
                if (this.isSearchActive && !this.isDraggable()) {
                    this.handleSearchQuery(searchQuery)
                    // Если поиск закончился, восстановим вид секции "Перевод в ТП"
                } else if (this.isDraggable()) {
                    this.setVisibility(!this.isSearchActive);
                } else {
                    // Во всех других случаях нужно кроме вида ещё и внутренний контент восстановить
                    this.restoreLayout();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        // Если это секция "Тематики", то нужно скрыть выбранную тематику для сценария, чтобы не показ
        if (changes.tagId && this.stepLabelType.name === StepLabelTypeName.TO_TAG) {
            this.handleTagChange(changes.tagId.currentValue);
        }
        // Сменился идентификатор тематики
        if (changes.tagId) {
            let tagId = changes.tagId.currentValue;
            // Проверяем есть ли в данной секции тэггированные этой тематикой сущности
            this.hasTaggedEntities = changes.tagId.currentValue ? this.entities
                .some(entity => this.isTaggedById(entity, tagId)) : false;
            // Сбрасываем текущее состояние опции "Показать всё"
            this.resetTaggedOption();
        }
        // Обновился поисковый запрос - передаём value observer'у
        if (changes.searchQuery) {
            this.searchQueryObserver.next(changes.searchQuery.currentValue);
        }
    }

    /**
     * При смене тематики нужно спрятать или показать спрятанную тематику
     * @param tagId идентификатор тематики
     */
    private handleTagChange(tagId) {
        // Если идентификатор проставлен, то ищем тематику и скрываем её
        if (tagId) {
            const index = this.entities.findIndex(tag => tag.key.id === tagId);
            const stepLabelEntity = {...this.entities[index]};
            // В первый раз можем добавить в список не то, поэтому смотрим есть ли "запомненая тематика"
            if (this.currentTag) {
                this.entities.push(this.currentTag);
            }
            this.currentTag = stepLabelEntity;
            this.entities.splice(index, 1);
        } else if (this.currentTag) {
            // Если идентификатор сброшен, то возвращаем в список тематику, которую скрыли
            this.entities.push(this.currentTag);
            this.currentTag = null;
        }
    }

    private handleSearchQuery(searchQuery) {
        // Если запроса никакого нет, то ничего делать не надо
        if (searchQuery == null) {
            return;
            // Если запрос есть, но он менее двух символов, то
        } else if (searchQuery.length < 2) {
            this.restoreLayout();
            return;
        }
        // Ищем совпадения
        this.content = this.entities
            .filter(item => StepLabelSectionComponent.search(item, searchQuery));
        let hasContent: boolean = this.hasContent();
        // Выставляем флаг видимости секции
        this.setVisibility(hasContent);
        // Если нашли совпадения - раскрываем секцию
        if (hasContent) {
            this.expandSection();
            // Если свопадений нет - закрываем секцию
        } else {
            this.collapseSection();
        }
    }

    /**
     * Запомнить состояние секции, которое задал пользователь
     * @param expand true -> раскрыть секцию, false -> схлопнуть
     */
    public rememberExpandState(expand: boolean): void {
        if (this.isDraggable() || !this.hasContent()) {
            return;
        }
        this.expanded = this.isSearchActive ? this.expanded : expand;
        if (expand) {
            this.expandSection();
        } else {
            this.collapseSection(() => {
                if (this.hasTaggedEntities) {
                    this.resetTaggedOption();
                }
            });
        }
    }

    /**
     * Есть ли элементы для отображения?
     */
    public hasContent(): boolean {
        // У отправки в тех.поддержку есть только формулировки, но отображать их не надо - контента нет
        return this.content.length > 0;
    }

    /**
     * Можно ли это секцию drag'n'dropнуть
     */
    public isDraggable(): boolean {
        return this.stepLabelType.name == StepLabelTypeName.TO_SUPPORT || this.stepLabelType.name == StepLabelTypeName.EXIT;
    }

    /**
     * Поиск
     * @param item         сущность
     * @param searchQuery  поисковый запрос
     */
    private static search(item: StepLabelEntity, searchQuery) {
        return item.title.toLowerCase().indexOf(searchQuery.toLocaleLowerCase()) !== -1;
    }

    /**
     * Установить содержимое секции
     * @param showAll флаг - true: отображать всё, false: отображать только тэггированные сущности
     */
    private setContent(showAll: boolean): void {
        this.content = showAll ? this.entities.map(entity => ({...entity})) :
            this.entities
                .filter(entity => this.isTaggedById(entity, this.tagId))
                .map(entity => ({...entity}));
    }

    /**
     * Изменить видимость секции, сообщить об этом родительской компоненте
     * @param visible флаг видимости
     */
    private setVisibility(visible: boolean): void {
        this.visible = visible;
        this.visibilityChange.emit();
    }

    /**
     * Привести вид секции к дефолтному: каким он был перед началом поиска
     */
    private restoreLayout() {
        // Отображаем секцию
        this.setVisibility(true);
        // Содержимое секции: всё или только тэггированное?
        let showAll: boolean = this.hasTaggedEntities ? this.showAllClicked : true;
        // Если текущая секция раскрыта, но раскрыта не пользователем, а поиском, то нужно её закрыть
        if (this.section.expanded && !this.expanded) {
            this.collapseSection(() => this.setContent(!this.hasTaggedEntities))
            // Если пользователь перед поиском раскрыл секцию, а она была закрыта поиском - нужно раскрыть
        } else if (this.expanded) {
            this.expandSection(() => this.setContent(showAll));
            // В остальных случаях просто обновить контент
        } else {
            this.setContent(showAll);
        }
    }

    /**
     * Тэгированна ли сущность данной тематикой
     * @param entity    сущность
     * @param tagId     идентификатор тематики
     */
    private isTaggedById(entity: StepLabelEntity, tagId: number) {
        return entity.taggedInIds && entity.taggedInIds.some(id => id == tagId);
    }

    /**
     * Сбросить данные по опции "Показать всё"
     */
    private resetTaggedOption() {
        if (!this.isSearchActive) {
            this.setContent(!this.hasTaggedEntities);
        }
        this.showAllClicked = false;
    }

    /**
     * Обработать нажатие на опции "Показать всё"
     */
    private handleShowAllOptionClick(): void {
        this.setContent(true);
        this.showAllClicked = true;
    }

    /**
     * Нужно ли отображать опцию "Показать всё"?
     */
    get displayShowAll(): boolean {
        if (this.isCloseAnimationInProgress || this.isSearchActive) {
            return false;
        } else if (this.hasTaggedEntities && !this.showAllClicked) {
            return true;
        }
        return false;
    }

    /**
     * Раскрыть секцию
     * @param beforeExpandAction действие перед началом аниации раскрытия
     */
    private expandSection(beforeExpandAction?: () => void): void {
        if (beforeExpandAction != null) {
            beforeExpandAction();
        }
        this.section.open()
    }

    /**
     * Схлопнуть секцию
     * @param afterCollapseAction действие после завершения аниации схлопывания
     */
    private collapseSection(afterCollapseAction?: () => void): void {
        // Если секция видна (т.е. не скрыта в поиске)
        if (this.visible) {
            // То подпишемся на событие схлопывания и afterCollapseAction вызовем после завершения схлопывания
            this.isCloseAnimationInProgress = true;
            this.collapseEvent
                .pipe(first())
                .subscribe(() => {
                    if (afterCollapseAction != null) {
                        afterCollapseAction();
                    }
                    this.isCloseAnimationInProgress = false
                });
        } else {
            if (afterCollapseAction != null) {
                afterCollapseAction();
            }
        }
        // Закрываем
        this.section.close();
    }
}