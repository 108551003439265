import {ClickMode, EruditeElement} from "../EruditeElement";
import {VaScriptNode} from "../../../../../data/va/Script";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {dia, ui} from '@naumen/rappid';
import ElementView = dia.ElementView;


export class CircleElement extends EruditeElement {

    constructor(attributes: {node: VaScriptNode}) {
        super(attributes);
        mergeDeep(this.attributes, {
            type: 'standard.Circle',
            attrs: {
                body: {
                    refCx: "50%",
                    refCy: "50%",
                    refR: "50%",
                    strokeWidth: 2,
                    rx: 10,
                    ry: 10
                },
                label: {
                    textVerticalAnchor: "middle",
                    textAnchor: "middle",
                    refX: "50%",
                    refY: "50%",
                    fontSize: 14,
                    textWrap: {
                        text: attributes.node.text,
                        width: -20,
                        ellipsis: true
                    },
                    fontWeight: "bold"
                }
            }
        });

        this.markup = [{
            tagName: 'circle',
            selector: 'body',
        }, {
            tagName: 'text',
            selector: 'label'
        }];

    }

    public onElementClick(elementView: ElementView, mode: ClickMode): ui.Halo {
        if (mode == ClickMode.CHECKPOINT) {
            return null;
        }
        // Создаём дефолтный гало
        super.onElementClick(elementView, mode);
        // Круговые элементы нельзя удалять
        this.halo.removeHandle('remove');
        return this.halo.render();
    }

    public isDeletable(): boolean {
        return false;
    }

    public isRound(): boolean {
        return true;
    }
}