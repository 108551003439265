import {Injectable} from "@angular/core";
import {ProjectVersionInterceptor} from "./ProjectVersionInterceptor";
import {ScriptEditComponent} from "./script-builder/script-edit.component";
import {HttpEvent, HttpResponse} from "@angular/common/http";
import {VAScript, VaScriptEdge} from "../../data/va/Script";
import {VaScriptCondition} from "../../data/va/VaScriptCondition";

/**
 * Response-экстрактор, чтобы оборачивать пришедшие с бэка condition'ы в классы с фронта
 */
@Injectable({
    providedIn: ScriptEditComponent
})
export class ScriptBuilderResponseExtractor extends ProjectVersionInterceptor {

    public extractResponse(event: HttpEvent<any>): any {
        if (event instanceof HttpResponse && event.body && event.body.result && event.body.result.edges) {
            let script: VAScript = event.body.result;
            script.edges.forEach(edge => this.handleConditions(edge));
            // Подставляем в body обёрнутые condition'ы
            return event.clone({body: script});
        }
        return super.extractResponse(event);
    }

    /**
     * Обернуть условия с ребра в класс с фронта
     * @param edge ребро
     */
    public handleConditions(edge: VaScriptEdge,) {
        // Условия, которые пришли с бэка превратим в экземпляры класса на фронте, чтобы вызывать для них методы
        if (edge.conditions.length != 0) {
            edge.conditions = edge.conditions.map((condition: VaScriptCondition) => new VaScriptCondition(condition));
        }
    }
}