import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {VaScriptNode} from "../../../../../data/va/Script";

@Component({
    selector: 'procedure-exit-name-dialog',
    template: require('./procedure-exit-name-dialog.component.html')
})
export class ProcedureExitNameDialogComponent {

    private name: string;

    constructor(public dialogRef: MatDialogRef<ProcedureExitNameDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { node: VaScriptNode }) {
        this.name = data.node.stepLabel.name;
    }

    onDeny(): void {
        this.dialogRef.close({deny: true});
    }

    onSave() {
        let name = this.name ? this.name : "";
        this.dialogRef.close({name: name})
    }
}
