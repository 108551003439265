import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of, Subject, throwError} from "rxjs";
import {catchError, debounceTime, flatMap} from "rxjs/operators";
import {Operator, LogicalOperators, DatetimeVariablesDescription, NowVariablesDescription} from "./attribute-rule.model";
import * as moment from 'moment';
import {ExpressionPart, ExpressionType, VaScriptCondition} from "../../../../data/va/VaScriptCondition";


@Component({
    selector: 'attribute-rule-editor',
    template: require('./attribute-rule-editor.component.html'),
    styles: [require('./attribute-rule-editor.component.less')]
})
export class AttributeRuleEditorComponent implements OnInit, OnChanges, OnDestroy {

    parts: ExpressionPart[] = [{}];

    operators: string[] = [];

    @Input()
    condition: VaScriptCondition;

    valueForCheck: (number | Date);

    checkResult: string = "";

    formulaHidden: boolean = true;

    formulaEdit: boolean = false;

    @Input()
    type: ExpressionType;

    @Input()
    errors: Map<string, string>;

    availableOperators: Operator[] = LogicalOperators;

    private partChangeSubject = new Subject<void>();

    private formula: string;

    constructor(private httpClient: HttpClient) {
    }

    /**
     * Подсказка для ввода
     */
    get expressionHelpTextHtml(): string {
        let res = "<table><tr><td>&& - И</td><td>|| - ИЛИ</td></tr>";
        switch (this.type) {
            case ExpressionType.INT:
                res += "<tr><td>X - целое число</td></tr>";
                break;
            case ExpressionType.DATETIME:
                let html = "<tr><td>";
                Object.entries(DatetimeVariablesDescription).forEach((data, index) => {
                    html += (data[0] + " - " + data[1] + "<br/>");
                });
                html += ("</td><td>");
                Object.entries(NowVariablesDescription).forEach((data, index) => {
                    html += (data[0] + " - " + data[1] + "<br/>");
                });
                html += ("</td></tr>");
                res += html;
                break;
            case ExpressionType.FLOAT:
                res += "<tr><td>X - дробное число</td></tr>";
                break;
        }
        return res += "</table>";
    }

    ngOnInit(): void {
        this.formula = this.condition.expression;

        if (this.condition.primitiveRule && this.condition.primitiveRule.expressionObject) {
            // распарсим объект, который хранится для выражений, созданных через билдер
            const {operators, parts} = this.condition.primitiveRule.expressionObject;
            if (operators) {
                this.operators = operators;
            }
            if (parts) {
                this.parts = parts;
            }
        } else if (this.formula) {
            // объекта с выражением нет, а формула есть, значит это редактировали руками
            this.formulaHidden = false;
            this.formulaEdit = true;
        }

        this.partChangeSubject
            .pipe(
                debounceTime(200), // не спамим изменениями
                flatMap(value => {
                    // если ошибка -- возвращаем пустой объект
                    return this.toFormula().pipe(catchError(err => {
                        return of(null);
                    }));
                }))
            .subscribe((value) => {
                if (!value) {
                    // произошла ошибка, зануляем всё, чтобы валидация не проходила
                    this.formula = null;
                    this.condition.expression = null;
                    this.condition.expressionObject = null;
                    return;
                }

                this.errors.delete('expression');

                let {formula, operators, parts} = value;
                this.formula = formula;

                this.condition.expression = formula;
                this.condition.expressionObject = {operators: operators, parts: parts};
            });
    }

    /**
     * Проверка выражения
     */
    check() {
        if (!this.formula) {
            this.checkResult = "Некорректная формула";
            if (!this.errors) {
                this.errors = new Map<string, string>();
            }
            this.errors.set('expression', 'Ошибка в выражении');
            return;
        }
        if (!this.valueForCheck) {
            this.checkResult = "Введите значение для проверки";
            return;
        }

        let value: string;
        switch (this.type) {
            case ExpressionType.INT:
            case ExpressionType.FLOAT:
                value = this.valueForCheck.valueOf() + "";
                break;
            case ExpressionType.DATETIME:
                const dateMoment = isNaN(+this.valueForCheck)
                    // строка, проставленная пикером
                    ? moment(this.valueForCheck, "DD.MM.YYYY HH:mm:SS")
                    // если в поле число, считаем, что это unix time
                    : moment.unix(+this.valueForCheck / 1000);

                // переводим в требуемый формат
                value = dateMoment.format("YYYY-MM-DD,HH:mm:ss.SSS");
                break;
        }

        this.httpClient.post<string>(`/account/expert/script/checkExpression`, {
            expression: this.formula,
            attributeType: this.type,
            value: value
        }).subscribe(result => {
            this.checkResult = result;
        });
    }

    /**
     * Коллбэк на изменение частей выражения
     */
    onPartChanged() {
        this.partChangeSubject.next();
    }

    /**
     * Добавить новую строку
     * @param i
     */
    addRow(i: number) {
        this.parts.splice(i, 0, {});
        this.operators.splice(i - 1, 0, this.availableOperators[0].name);

        // выражение изменилось
        this.onPartChanged();
    }

    /**
     * Удаление строки
     * @param i
     */
    removeRow(i: number) {
        this.parts.splice(i, 1);

        if (i === 0) {
            // добавляем новый первый элемент, пустой
            this.parts.unshift({});
        } else {
            // удаляем связанный оператор
            this.operators.splice(i - 1, 1);
        }

        // выражение изменилось
        this.onPartChanged();
    }

    edit() {
        this.formulaEdit = true;
        this.formulaHidden = false;
        this.condition.expressionObject = null;
    }

    /**
     * Получить объект с формулой
     */
    private toFormula(): Observable<{ formula: string, operators: string[], parts: ExpressionPart[] }> {
        if (this.parts.length !== (this.operators.length + 1)) {
            return throwError(new Error("Not valid expression"));
        }

        let formula = "";

        let result = [];
        for (let i = 0; i < this.parts.length; i++) {
            if (i > 0) {
                // добавляем оператор, который стоит между частями выражения
                let operatorIndex = i - 1;
                result.push(this.operators[operatorIndex]);
                formula = formula + ` ${this.operators[operatorIndex]} `;
            }

            const part = this.parts[i];

            result.push(part);
            if (!part.operator || !part.rightOperand || !part.leftOperand || !part.isValid) {
                // в части выражения не хватает кусков
                return throwError(new Error("Not valid expression"));
            }

            formula += `${part.leftOperand} ${part.operator} ${part.rightOperand}`;
        }
        return of({formula: formula, operators: this.operators.slice(), parts: this.parts.slice()});
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type) {
            if (this.type == ExpressionType.DATETIME) {
                this.valueForCheck = new Date();
            } else {
                this.valueForCheck = 0;
            }

            this.parts = [{}];
            this.formula = "";
        }
    }

    ngOnDestroy(): void {
    }

    onFormulaEdited() {
        if (this.formulaEdit) {
            this.condition.expression = this.formula;
        }
    }

    get hasExpressionErrors() {
        return this.errors && this.errors.get('expression');
    }

}