import {Injectable} from '@angular/core';
import {VAScenario} from "../../../../data/va/Script";
import {VersionedDataService} from "../base/versioned-data.service";
import {HttpClient} from "@angular/common/http";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {NamedEntitiesModel} from "../model/named-entities.model";
import {VaAttribute} from "../../../../data/va/Attribute";
import * as urls from "../../../../../../js/workplace/urls";
import {ExtractorSuppliedTypeEnum} from "../../../../data/va/Extractor";


@Injectable({
    providedIn: 'root',
})
export class ScenarioService extends VersionedDataService<VAScenario, number> {

    baseUrl = '/account/expert/script';
    usageEntityType: AppliedEntityType = AppliedEntityType.SCENARIO;

    readonly entityClass = NamedEntitiesModel.VA_SCENARIO;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getUsedAttributes(scenarioId): Promise<VaAttribute[]> {
        return await this.httpClient.get<Promise<VaAttribute[]>>(`${this.baseUrl}/getAllUsedAttributes?scenarioId=${scenarioId}`).toPromise();
    }

}
