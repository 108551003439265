import {Component, Input, OnInit} from '@angular/core';
import {Form} from "../../../va/base/base-details.component";


@Component({
    selector: 'form-message',
    template: require('./form-message.component.html')
})

export class FormMessageComponent<T> implements OnInit {

    @Input()
    form: Form<T>;

    @Input()
    successMessage: string;

    constructor() {
    }

    ngOnInit(): void {
        if (!this.successMessage) {
            this.successMessage = "Успешно сохранено";
        }
    }


}