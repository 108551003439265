import {BaseFormController} from "../../BaseFormController";
import urls from "../../../urls";
import common from "../../../common";

// контроллер страницы редактирования правила
export default class AntifraudBlockUserEditController extends BaseFormController {

    constructor(Restangular, $scope, $stateParams, $state, $filter, TitleService) {
        super($scope, $state);
        var that = this;
        this.initFormMode();
        that.$filter = $filter;
        that.restAngular = Restangular;

        // контроллер списка правил
        that.blockUserController = $scope.$parent.ctrl;

        that.form.blockUser = {
        };

        var ip = $stateParams['ip'];
        if (ip) {
            that.setLoading(true);
            that.setModeEdit();
            TitleService.setTitle("Редактирование");
            that.restAngular.one(that.blockUserController.baseUrl + "/ip?ip=" + ip).get()
                .then(function success(data) {
                    that.form.blockUser = data;
                }, function error(data) {
                }).finally(function () {
                that.setLoading(false);
            });
        } else {
            that.setModeCreate();
            TitleService.setTitle("Новая блокировка");
        }

        common.getAccess(that.restAngular, that.blockUserController.baseUrl,  that);
    }

    remove() {
        var that = this;
        that.restAngular.one(that.blockUserController.baseUrl  + "/ip?ip=" + that.form.blockUser.ip).remove().then(function success(data) {
            that.handleSuccess(data);
            // меняем текущее состояние контроллера
            that.$state.go('settings.antifraudblockusers', {}, {reload: true});

        }, function error(data) {
            that.handleError(data);
        });
    }
}

AntifraudBlockUserEditController.$inject = ["Restangular", "$scope", "$stateParams", "$state", "$filter", "TitleService"];
