import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {AppliedEntityType, EntityUsage} from "../../entity-usage/entity-usages.model";
import * as urls from "../../../../../../js/workplace/urls";
import {ReplaySubject} from "rxjs";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {ServerCollectionDataService, ServerCollectionFilter} from "../../common/collection/server-collection.component";
import {EventEmitter} from "@angular/core";
import {nullsFirst} from "../../../../../util/Utils";
import {NamedEntitiesModel} from "../model/named-entities.model";

export class ChangePushEvent {
    projectVersionId: string;
    className: string;
    object: any;
    eventType: ChangeEventType
}

export enum ChangeEventType {
    POST_CREATE = "POST_CREATE",
    POST_UPDATE = "POST_UPDATE",
    POST_DELETE = "POST_DELETE",
}

export abstract class BaseDataService<T, ID> implements ServerCollectionDataService<T> {

    abstract baseUrl: string;

    usageEntityType: AppliedEntityType = null;

    loadInProgress = false;

    readonly itemsUpdateSubject = new ReplaySubject<T[]>(1);

    readonly itemChange: EventEmitter<ChangePushEvent> = new EventEmitter<ChangePushEvent>();

    /**
     * Название класс сущности на бэке, которую отслеживаем по пушам
     */
    abstract readonly entityClass: NamedEntitiesModel;

    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        if (this.stateService.params["projectVersionId"]) {
            webSocketService.subscribeOnEvents({
                eventType: "VA_ENTITY_CHANGE",
                fn: (event) => this.changeReceived(event)
            });
        }
    }

    /**
     * Получено обновление данных через пуш
     */
    private changeReceived(pushEvent: any) {
        // парсим событие изменения данных из пуша
        const changeEvent: ChangePushEvent = JSON.parse(pushEvent.details);
        if (this.entityClass != changeEvent.className) {
            // класс не тот
            return;
        }
        if (this.stateService.params["projectVersionId"] != changeEvent.projectVersionId) {
            // версия не та
            return;
        }
        // отправляем апдейт тому, кто подписался
        this.itemChange.emit(changeEvent);
        if (this.itemsUpdateSubject.observers.length) {
            // если есть подписчики на обновление списка, обновляем его
            this.load();
        }
    }

    /**
     * Загрузить весь список данных
     */
    load() {
        this.loadInProgress = true;
        this.httpClient.get<T[]>(this.baseUrl).subscribe(items => {
            this.defaultSort(items);
            this.itemsUpdateSubject.next(items);
            this.loadInProgress = false;
        });
    }

    /**
     * Сортировка по умолчанию
     */
    private defaultSort(list: T[]): T[] {
        // если это массив длиной более 1, то отсортируем
        if (Array.isArray(list) && list.length > 1) {
            // по дате изменения (сначала новые), потом по id
            list.sort((entity1, entity2) => -nullsFirst(entity1, entity2,
                entity => entity.d, entity => entity.key?.id, entity => entity.id));
        }
        return list;
    }

    private checkAndLoad() {
        if (!this.loadInProgress) {
            this.load();
        }
    }

    subFindAll(callback: (entities: T[]) => void): void {
        this.checkAndLoad();
        this.itemsUpdateSubject.subscribe((entities: T[]) => {
            callback(entities);
        });
    }

    async findAll(params?: HttpParams | {
        [param: string]: string | string[];
    }): Promise<T[]> {
        return await this.httpClient.get<T[]>(this.baseUrl, {params: params}).toPromise()
            .then(this.defaultSort);
    }

    async getObject(id: string): Promise<T> {
        return await this.httpClient.get<T>(`${this.baseUrl}/${id}`).toPromise();
    }

    async getAccess(): Promise<{ access: boolean }> {
        return await this.httpClient.get<{ access: boolean }>(`${this.baseUrl}/access`).toPromise();
    }

    async getAccessForObject(object: T): Promise<boolean> {
        let accessForm = await this.httpClient.get<any>(`${this.baseUrl}/${this.getObjectId(object)}/access`).toPromise();
        return accessForm?.access;
    }

    async save(object: T): Promise<T> {
        return await this.httpClient.post<T>(`${this.baseUrl}`, object).toPromise();
    }

    async update(object: T): Promise<T> {
        return await this.httpClient.patch<T>(`${this.baseUrl}`, object).toPromise();
    }

    async delete(object: T): Promise<string> {
        return await this.httpClient.delete<string>(`${this.baseUrl}/${this.getObjectId(object)}`).toPromise();
    }

    async deleteById(id: string): Promise<string> {
        return await this.httpClient.delete<string>(`${this.baseUrl}/${id}`).toPromise();
    }

    async getObjectUsages(object: T): Promise<EntityUsage[]> {

        if (this.usageEntityType == null) {
            return new Promise(() => []);
        }

        return this.httpClient.get<EntityUsage[]>(`${urls.va.entityUsages}/${this.usageEntityType}`, {
            params: {entityId: this.getObjectId(object)}
        }).toPromise();
    }

    getObjectId(object: T): string {
        return (object as any).id;
    }

    exportUsagesFile(): Promise<HttpResponse<Blob>> {
        return this.httpClient.post(`${this.baseUrl}/exportUsages`, {}, {
            responseType: 'blob',
            observe: "response"
        }).toPromise();
    }

    /**
     * Загрузить аудиофайл по id из erudite-файлов
     *
     * @param fileUri часть урл запроса относительно базового урла
     * @param params параметры запроса
     * @param onLoadCallback колбек на загрузку
     * @param onError колбек на вывод ошибок
     */
    loadAudio(fileUri: string, params: HttpParams | { [param: string]: string | string[] },
              onLoadCallback: (dataUrl: string | ArrayBuffer) => void, onError: (error) => void) {
        try {
            let reader = new FileReader();
            this.httpClient.get(`${this.baseUrl}${fileUri}`, {
                params: params,
                observe: 'response',
                responseType: 'blob'
            }).subscribe(response => {
                if (response.body.type == "text/plain") {
                    return;
                }
                let blob = new Blob([response.body], {type: 'audio/ogg'});
                reader.readAsDataURL(blob);
                reader.onloadend = () => onLoadCallback(reader.result as ArrayBuffer);
            }, error => {
                let blob = new Blob([error], {type: 'application/json'});
                reader.onload = function() {
                    let json = reader.result;
                    let errors = JSON.parse(json as string);
                    onError(errors?.errors[0]?.message);
                    onLoadCallback(null);
                }
                reader.readAsText(blob);
            })
        } catch (e) {
            onError('Произошла ошибка');
        }
    }

    /**
     * Базовый урл получения данных серверной коллекции
     */
    get serverCollectionUrl() {
        return this.baseUrl;
    }

    async count(filter: ServerCollectionFilter): Promise<number> {
        return await this.httpClient.post<number>(`${this.serverCollectionUrl}/count`, filter).toPromise().then(value => +value);
    }

    async filter(filter: ServerCollectionFilter): Promise<T[]> {
        return await this.httpClient.post<T[]>(`${this.serverCollectionUrl}/filter`, filter).toPromise();
    }

    async filterIds(filter: ServerCollectionFilter): Promise<number[]> {
        return await this.httpClient.post<number[]>(`${this.serverCollectionUrl}/filterIds`, filter).toPromise();
    }
}