import {VaAsrSettings, VaTtsSettings} from "./Voice";
import {VaTag} from "./Tag";
import {Extractor, ExtractorSuppliedType, ExtractorSuppliedTypeEnum, ValueTypeEnum} from "./Extractor";
import {Formulation, WithFormulation} from "./Formulation";
import {MacroObject} from "../../io/components/common/macro/object/MacroObject";

/**
 * Описание: {@link ru.naumen.chat.va.data.entity.attribute.VAAttribute}
 */

export class VaAttribute extends WithFormulation implements MacroObject {keyId: number;
    name?: string;
    description?: string;
    scope?: Scope;
    suppliedType?: AttributeSuppliedType;
    dontUseExtractors?: boolean;
    confirmAfterPlain?: boolean;
    confirmWhenReuse?: boolean;
    apiKey?: string;
    ttsSettings?: VaTtsSettings;
    asrSettings?: VaAsrSettings;
    repeatReactionEnabled?: boolean = true;
    extractorId?: number;
    extractor: Extractor;
    requestAmount?: number;


    constructor(object?: any) {
        super();
        if (!object) {
            return;
        }
        this.key = object.key;
        this.name = object.name;
        this.description = object.description;
        this.scope = object.scope;
        this.suppliedType = object.suppliedType;
        this.expertId = object.expertId;
        this.d = object.d;
        this.deleted = object.deleted;
        this.dontUseExtractors = object.dontUseExtractors;
        this.confirmAfterPlain = object.confirmAfterPlain;
        this.confirmWhenReuse = object.confirmWhenReuse;
        this.apiKey = object.apiKey;
        this.expert = object.expert;
        this.formulations = object.formulations ? object.formulations.map(f => new Formulation(f)) : null;
        this.ttsSettings = object.ttsSettings;
        this.asrSettings = object.asrSettings;
        this.taggedInfo = object.taggedInfo;
        this.extractorId = object.extractorId;
        this.extractor = object.extractor;
        this.requestAmount = object.requestAmount;
        this.setExistAllAudioRecordFiles();
    }

    getTitle(): string {
        return this.name;
    }

    getId(): string {
        return this.key.id + "";
    }

    getTaggedInfo(): VaTag[] {
        return this.taggedInfo;
    }

}

export interface Scope {
    id: number;
    name: string;
    title: string;
}

export enum AttributeSuppliedTypeEnum {
    CHANNEL = "CHANNEL",
    CITY = "CITY"
}

export class AttributeSuppliedType {
    name: AttributeSuppliedTypeEnum;
    title: string;
    required: boolean;
    extractorSuppliedType: ExtractorSuppliedType;
}

/**
 * Экстрактор с перечислением
 */
export function isEnumExtractor(attribute: VaAttribute): boolean {
    if (!attribute.extractor) {
        return false;
    }
    let suppliedType = attribute.extractor.suppliedType;
    return attribute.extractor.valueType.name === ValueTypeEnum.ENUM && (suppliedType == null || suppliedType.name !== ExtractorSuppliedTypeEnum.CHANNEL);
}

/**
 * Экстрактор без перечисления
 */
export function isVarExtractor(attribute: VaAttribute): boolean {
    if (!attribute.extractor) {
        return false;
    }
    return attribute.extractor.valueType.name === ValueTypeEnum.ANY;
}