import {Injectable} from "@angular/core";
import {VersionedDataService} from "../base/versioned-data.service";
import {HttpClient} from "@angular/common/http";
import {TextDataGroup, TextDataTypeGroup} from "../text-data/text-data.model";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";


@Injectable()
export class SettingsService extends VersionedDataService<TextDataGroup, TextDataTypeGroup> {

    baseUrl: string = "/account/expert/textdatagroup";

    readonly entityClass = null;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    getObjectId(object: TextDataGroup): string {
        return `${object.key.id.name}`;
    }

    async getBargeInWords(): Promise<string> {
        return await this.httpClient.get<string>(`${this.baseUrl}/bargeInWords`).toPromise();
    }
}