export enum Valued {
    /**
     * Id опции "Да"
     */
    YES = "111",

    /**
     * Id опции "Нет"
     */
    NO = "222",

    /**
     * Id опции "Динамическое значение"
     */
    DYNAMIC_VALUE = "333",

    /**
     * Название опции "Да"
     */
    YES_TITLE = "Да",

    /**
     * Название опции "Нет"
     */
    NO_TITLE = "Нет",

    /**
     * API-key опции "Да"
     */
    YES_API_KEY = "YES",

    /**
     * API-key опции "Нет"
     */
    NO_API_KEY = "NO",

    /**
     * API-key опции "Динамическое значение"
     */
    DYNAMIC_OPTION_API_KEY = "DYNAMIC_OPTION_API_KEY",

    /**
     * API-key опции канала "голос"
     */
    VOICE_API_KEY = "voice",

    /**
     * API-key опции канала "Витрина на саите"
     */
    WEB_API_KEY = "web",

    /**
     * API-key опции канала "Голосовой помощник"
     */
    ASSISTANT_API_KEY = "assistant",

    /**
     * API-key опции голосовой части канала "Голосовой помощник"
     */
    ASSISTANT_VOICE_API_KEY = "assistant_voice",

    /**
     * Заголовок опции канала "голос"
     */
    VOICE_TITLE = "Звонки",


    /**
     * Заголовок опции канала "Витрина на саите"
     */
    WEB_TITLE = "Витрина на сайте",

    /**
     * Заголовок опции канала "Голосовой помощник"
     */
    ASSISTANT_TITLE = "Голосовой помощник",

    /**
     * Заголовок опции голосовой версии канала "Голосовой помощник"
     */
    ASSISTANT_VOICE_TITLE = "Голосовая реплика",

    /**
     * Идентификатор поставляемого бинарного экстрактора
     */
    BINARY_EXTRACTOR = `10001`

}