import {Component, OnDestroy, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {BaseMasterComponent} from "../../va/base/base-master.component";
import {VAProjectVersion} from "../../../../data/va/VAProjectVersion";
import {ProjectVersionService} from "../project-version.service";
import WebSocketService from "../../../../services/WebSocketService";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {SortField} from "../../../../data/va/Common";


@Component({
    selector: 'project-version',
    template: require('./project-version-master.component.html'),
    styles: [require('./project-version-master.component.less')]
})
export class ProjectVersionMasterComponent extends BaseMasterComponent<VAProjectVersion, string> implements OnInit, OnDestroy {

    private subscriptionId: string;
    educatedProjectVersionId: string;
    nextUrl: string;

    constructor(protected titleService: Title,
                protected stateService: StateService,
                protected dataService: ProjectVersionService,
                protected httpClient: HttpClient,
                notificationService: NotificationService,
                private webSocketService: WebSocketService) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`d`), new SortField(`Название`, `id`));
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        // Подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_VERSIONING_PROGRESS_FINISH",
            fn: (event) => {
                switch (event.type) {
                    case "VA_VERSIONING_PROGRESS_FINISH":
                        this.loadList();
                        break;
                }
            }
        });

        this.educatedProjectVersionId = this.stateService.params['projectVersionId'];
        this.nextUrl = this.stateService.params['nextUrl'] ? this.stateService.params['nextUrl'] : "robot";

        if (this.educatedProjectVersionId && this.stateService.params['editProjectVersionId'] != '') {
            // автоматический переход на текущую обучаемую версию
            this.stateService.go('robot.project_versions.edit', {
                editProjectVersionId: this.educatedProjectVersionId,
            })
        }
    }

    ngOnDestroy(): void {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    // On start edu need call server function
    async onStartEducation() {
        await this.httpClient.get(`${this.dataService.baseUrl}/onStartEdu`).toPromise();
    }
}
