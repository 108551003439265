import {NgModule} from "@angular/core";
import {EruditeAudioPlayerComponent} from "./erudite-audio-player.component";
import {SecondsToMinutesPipe} from "./seconds-to-minutes";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatSliderModule} from "@angular/material/slider";
import {CommonModule} from "@angular/common";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {EruditeAdvancedAudioPlayerComponent} from "./erudite-advanced-audio-player.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {AudioPlayerService} from "./audio-player.service";

@NgModule({
    declarations: [
        SecondsToMinutesPipe,
        EruditeAudioPlayerComponent,
        EruditeAdvancedAudioPlayerComponent
    ],
    imports: [
        NgSelectModule,
        NgbPaginationModule,
        FormsModule,
        CommonModule,
        MatIconModule,
        MatCardModule,
        MatSliderModule,
        CommonModule,
        MatButtonModule,
        MatExpansionModule,
        MatTableModule,
        MatPaginatorModule
    ],
    exports: [
        EruditeAudioPlayerComponent,
        EruditeAdvancedAudioPlayerComponent,
        SecondsToMinutesPipe
    ],
    entryComponents: [
        EruditeAudioPlayerComponent,
        EruditeAdvancedAudioPlayerComponent
    ],
    providers: [
        AudioPlayerService
    ]
})
export class EruditeAudioPlayerModule {

}