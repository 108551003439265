import * as urls from "../../../../../js/workplace/urls.js";
import * as restangular from "restangular";
import getMultiChartOptions from "./SuffuciencyMultiChartAdapter";
import getBarChartOptions from "./SuffuciencyBarChartAdapter";
import getLineChartOptions from "./SufficiencyLineChartAdapter";
import * as moment from 'moment';


/**
 * Класс с данными для использования на графике
 */
export class SufficiencyLine {
    key: string;
    type?: string;
    fillOpacity?: number;
    area?: boolean;
    yAxis?: number;
    color: string;
    values: Point[];
}

/**
 * Класс с бека с данными для использования на графике
 */
export class VATBSufficiencyMeasure {
    // Дата
    created: number;
    // Дата в строковом формате
    label: string;
    // Количество тематик в обучении
    trainTags: number;
    // Количество сообщений в обучении
    trainMessages: number;
    // Процент сообщений, у которых изменилась тематика или уверенность
    classificationChanged: number;
    // Процент сообщений, у которых изменилась тематика
    tagChanged: number;
    // Процент сообщений, у которых удалось определить тематику
    classified: number;
    // Процент сообщений, у которых увеличилась уверенность определения тематики
    confidenceIncreased: number;
    // Процент сообщений, у которых уменьшилась уверенность
    confidenceDecreased: number;
    // Процент сообщений тест-сета классификатора, у которых удалось опредеделить тематику
    testSetClassified: number;
}

/**
 * Точка на графике
 */
export class Point {
    x: number;
    y: number;
}

/**
 * Настройки графика
 */
export class SufficiencyChart {
    title: string;
    options: any;
    data: SufficiencyLine[];
    api: any;
    tooltip?: string;
}

export default class SufficiencyController {

    private restAngular: restangular.IService;
    private webSocketService: any;

    private charts: SufficiencyChart[];

    /**
     * Текст ошибки или null
     */
    private error: string;

    /**
     * id подписки на push
     */
    private readonly subscriptionId: string;

    /**
     * Текущая операция на интефрейсе кластеризации
     */
    private currentProcessDescription: string;

    /**
     * Другой процесс выполняется сейчас
     */
    private otherProcessInProgress: boolean;

    private progressData;

    private intervalService: angular.IIntervalService;

    private intervalPromise: any;

    private sufficiencyMeasures: VATBSufficiencyMeasure[];

    /**
     * Флаги выключения линий в графике размеров датасета
     */
    private datasetChartDataEnabled: {
        tags: boolean,
        messages: boolean
    } = {
        tags: true,
        messages: true
    };

    static $inject = ["Restangular", "$scope", "TitleService", "WebSocketService", "$interval"];


    constructor(Restangular: restangular.IService, $scope: ng.IScope, TitleService: any, WebSocketService: any, intervalService: angular.IIntervalService) {
        TitleService.setTitle('');
        this.restAngular = Restangular;
        this.webSocketService = WebSocketService;
        this.intervalService = intervalService;

        this.loadProgressData();
        this.loadSufficiencyData();
        this.loadCurrentProcessDescription();

        // по пушу об окончании процесса в сервисе перегружаем замеры и описание текущей операции
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: `VA_CLUSTERING_PROCESS_CHANGED,VA_SUFFICIENCY_MEASURE_STAGE`,
            fn: (event) => {
                switch (event.type) {
                    case `VA_CLUSTERING_PROCESS_CHANGED`:
                        this.loadCurrentProcessDescription();
                        this.loadSufficiencyData();
                        break;
                    case `VA_SUFFICIENCY_MEASURE_STAGE`:
                        // Получаем данные для прогресс-бара
                        this.progressData = JSON.parse(event.details);
                        this.handleProgressData();
                        break;
                }
            }
        });

        // в деструкторе отписываемся от пушей
        $scope.$on("$destroy", () => this.webSocketService.removeListener(this.subscriptionId));
    }

    /**
     * Загрузить описание текущей операции в сервисе кластеризации
     */
    loadCurrentProcessDescription(): void {
        this.restAngular.one(`${urls.va.tagbuilding}/info`).get()
            .then(data => {
                // текстовое описание
                this.currentProcessDescription = data.processedOperation != null ? data.processedOperation.title : '';

                // флаг "сейчас выполняется не замер достаточности датасета"
                this.otherProcessInProgress = data.processedOperation != null ? data.processedOperation.name != 'SUFFICIENCY_MEASURE' : false;
            });
    }

    /**
     * Загрузить данные для прогресс-бара
     */
    loadProgressData(): void {
        this.restAngular.one(`${urls.va.tagbuilding}/loadProgressData`).get()
            .then(data => {
                this.progressData = data;
                if (this.progressData) {
                    this.handleProgressData();
                }
            });
    }

    /**
     * Удалить данные для прогресс-бара на бэке
     */
    removeProgressData() {
        this.restAngular
            .one(`${urls.va.tagbuilding}/removeProgressData`)
            .get()
            .then(() => this.progressData = null);
    }

    /**
     * Обработать полученные данные для прогресс-бара
     */
    handleProgressData(): void {
        // Если сейчас переобучается модель и нет автоапдейта на прогрессбар, то нужно подписаться
        if (!this.intervalPromise && this.progressData.stage == 'REBUILD_TAG_MODEL') {
            this.intervalPromise = this.intervalService(() => {
                if (this.progressData.progress < 90) {
                    this.progressData.progress = this.progressData.progress + 1;
                }
            }, 3000);
        } else if (this.intervalPromise && this.progressData.stage == 'FINISH') {
            this.intervalService.cancel(this.intervalPromise);
            this.intervalPromise = null;
            // Если закончили вычисления - удаляем данные и сбрасываем их на фронте
            this.removeProgressData();
        }
    }

    /**
     * Загрузить данные по замерам достаточности обучения тематик
     */
    loadSufficiencyData(): void {
        this.restAngular.one(`${urls.va.tagbuilding}/sufficiencyMeasureData`).get()
            .then((measures: VATBSufficiencyMeasure[]) => {

                this.sufficiencyMeasures = this.restAngular.stripRestangular(measures);

                // создаем конфиг графика по замерам на неразмеченных сообщениях
                let tbChart = this.createTbChart(this.sufficiencyMeasures);

                // конфиг графика замеров на тест-сете
                let testSetChart = this.createTestSetChart(this.sufficiencyMeasures);

                // конфиг графика размеров датасета
                let dataSizeChart = this.createDataSizeChart(this.sufficiencyMeasures);

                this.charts = [tbChart, testSetChart, dataSizeChart];
            });
    }

    /**
     * Запустить замер достаточности обучения тематик
     */
    launchSufficiency(): void {
        this.restAngular.one(`${urls.va.tagbuilding}/sufficiencyMeasure`).get();
        this.loadCurrentProcessDescription();
    }

    /**
     * Создать конфиг графика по замерам на неразмеченных сообщениях
     */
    createTbChart(measures: VATBSufficiencyMeasure[]): SufficiencyChart {
        let data = [
            {
                key: "Общее изменение результата классификации",
                color: "#F7C64A",
                values: SufficiencyController.measuresToValues(measures, 'classificationChanged', null, true)
            },
            {
                key: "Изменилась тематика",
                color: "#0000FF",
                values: SufficiencyController.measuresToValues(measures, 'tagChanged', null, true)
            },
            {
                key: "Тематика не определена",
                color: "#288739",
                values: SufficiencyController.measuresToValues(measures, 'classified', (classified) => 100 - classified, true)
            },
            {
                key: "Увеличилась уверенность",
                color: "#F7FB50",
                values: SufficiencyController.measuresToValues(measures, 'confidenceIncreased', null, true)
            },
            {
                key: "Уменьшилась уверенность",
                color: "#F46D6D",
                values: SufficiencyController.measuresToValues(measures, 'confidenceDecreased', null, true)
            }
        ];
        const indexCallback = (index) => this.sufficiencyMeasures.map(measure => moment(measure.created).format("DD.MM HH:mm"))[index];
        indexCallback.bind(this);
        return {
            title: `Влияние на результат классификации`,
            data: data,
            options: getLineChartOptions(indexCallback),
            api: {},
            tooltip: `Чтобы определить, требуется ли продолжать кластеризацию диалогов, сравните результаты последних проверок в диаграмме. \n\nЕсли с момента последней проверки показатели изменились несущественно, значит новые обучающие данные не влияют на работу модели и кластеризованных диалогов достаточно.`
        };
    }

    /**
     * Создать конфиг графика замеров на тест-сете
     */
    createTestSetChart(measures: VATBSufficiencyMeasure[]): any {
        let data = [
            {
                key: "Точность классификации",
                values: SufficiencyController.measuresToValues(measures, 'testSetClassified')
            }
        ];
        return {
            title: `Точность классификации`,
            data: data,
            options: getBarChartOptions(),
            api: {},
            tooltip: `Метрика показывает, для какой доли диалогов классификатор верно выбрал тематику.`
        };
    }

    /**
     * Создать конфиг графика размеров датасета
     */
    createDataSizeChart(measures: VATBSufficiencyMeasure[]): SufficiencyChart {
        let data = [{
            key: "Количество тематик",
            yAxis: 1,
            type: "bar",
            color: "#f7fb50",
            values: SufficiencyController.measuresToValues(measures, 'trainTags')
        }, {
            key: "Количество сообщений в тематиках",
            yAxis: 2,
            type: "bar",
            color: "#6196c7",
            values: SufficiencyController.measuresToValues(measures, 'trainMessages')
        }];
        // Максимальные значения, чтобы знать чем оси ограничивать
        let tagQuantityMaxValue = Math.max(...measures.map(measure => measure.trainTags), 0);
        let trainMessagesMaxValue = Math.max(...measures.map(measure => measure.trainMessages), 0);
        return {
            title: `Изменение классификатора`,
            data: data,
            options: getMultiChartOptions(tagQuantityMaxValue, trainMessagesMaxValue),
            api: {},
        }
    }

    private static measuresToValues(measures: VATBSufficiencyMeasure[], yKey: string, beforeAction?: (value: any) => any, useIndex?: boolean) {
        return measures.map((measure: VATBSufficiencyMeasure, index: number) => {
            let value;
            if (beforeAction != null) {
                value = beforeAction(measure[yKey]);
            } else {
                value = measure[yKey];
            }
            return {
                x: useIndex ? index : measure.created,
                y: value
            }
        });
    }
}