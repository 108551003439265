import {CompositeKey} from "./Common";
import {VaAttribute} from "./Attribute";
import * as common from "../../../../js/workplace/common";
import {VaTag} from "./Tag";
import {MacroObject} from "../../io/components/common/macro/object/MacroObject";
import {EditableObject} from "../../io/components/common/editable-list/editable-list.model";

export class DKBEntity {
    key: CompositeKey<number>;
    name: string;
    attributes: number[];
    expertId: number;
    deleted: boolean;
    d: Date;
    fields: DKBField[];
    expert: any;
}

export class DKBField implements MacroObject {
    key: CompositeKey<number>;
    entityId: number;
    name: string;
    expertId: number;
    deleted: boolean;
    d: Date;
    values: DKBValue[];
    expert: any;
    attributes: VaAttribute[];


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.entityId = object.entityId;
        this.name = object.name;
        this.expertId = object.expertId;
        this.deleted = object.deleted;
        this.d = object.d;
        this.values = object.values;
        this.expert = object.expert;
        this.attributes = object.attributes;
    }

    getTitle(): string {
        return this.name;
    }

    getId(): string {
        return this.key.id + "";
    }

    getTaggedInfo(): VaTag[] {
        return [];
    }


}

export class DKBValue extends EditableObject {
    key: CompositeKey<number>;
    entityId: number;
    fieldId: number;
    propertyValue: string;
    expertId: number;
    deleted: boolean;
    d: Date;
    attributeCondition: string[];
    attributeConditionsDTOs: ExtractableValuesSerDTO[];
    expert: any;


    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }
        this.key = obj.key;
        this.entityId = obj.entityId;
        this.fieldId = obj.fieldId;
        this.propertyValue = obj.propertyValue;
        this.expertId = obj.expertId;
        this.deleted = obj.deleted;
        this.d = obj.d;
        this.attributeCondition = obj?.attributeCondition?.length > 0 ? obj.attributeCondition : [];
        this.attributeConditionsDTOs = obj?.attributeConditionsDTOs?.length > 0 ? obj.attributeConditionsDTOs.map(condition => new ExtractableValuesSerDTO(condition)) : [];
    }
}

export class ExtractableValuesSerDTO extends EditableObject {
    info: VaAttribute;
    values: any[];
    value: any;

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }
        this.info = obj.info;
        this.values = obj.values;
        this.value = obj.value;
    }

    objectToTitle(sources: Map<string, any>): String {
        return common.formatExtractableValues(this);
    }

    beforeEdit(sources: Map<string, any>): void {
        if (this.values && this.values.length === 1) {
            this.value = this.values[0];
        }
    }

    validateForm(currentIndex: number, sources: Map<string, any>): Map<string, string> {
        let errors = new Map();
        // нужно выбрать info
        if (this.info == null) {
            errors.set('info', "Необходимо выбрать атрибут");
        }
        if (sources.get("valueIsOptional") == false) {
            // а потом выбрать его значение
            if (this.value == null && (this.values?.length == 0 || this.values[0] == null)) {
                errors.set('values', "Необходимо выбрать значение");
            }
        }
        return errors;
    }
}
