import * as vars from "./VaModelVariables";
import common from "../../../common";
import * as urls from "../../../urls";
import * as mf from "../../../controllers/va/ml/models/VaModelStatusFunctions";

function VaModelPhaseDirective() {
    return {
        restrict: "E",
        scope: {
            phase: "=",
            modelStatus: "<",
            modelName: "<",
            enums: "<",
            buildCallback: "&",
            access: "="
        },
        bindToController: true,
        templateUrl: "/pages/workplace/va/ml/models/phase.html",
        controller: ["Restangular", "$scope", "$stateParams", "$http", "FileSaver", function (restangular, scope, $stateParams, $http, FileSaver) {
            const that = this;

            that.restAngular = restangular;
            const templatePrefix = "/pages/workplace/va/ml/models/";

            // сохраняем дополнительную информацию по этому этапу в переменную info
            that.info = vars.MODEL_CONFIG[that.modelName][that.phase.phaseType];
            that.phaseName = that.phase.phaseType.toLowerCase();
            that.$stateParams = $stateParams;
            that.$http = $http;
            that.fileSaver = FileSaver;
            that.projectVersionId = that.$stateParams['projectVersionId'];

            // массив параметров, для генерации инпутов
            that.phaseParameters = {};

            that.isArray = function (obj) {
                if (obj instanceof Array) {
                    return true
                } else {
                    return false;
                }
            };

            // метод для инициазилации поля that.getResultTemplatePath
            that.initResultTemplatePath = function () {
                // урл с шаблоном для отображения результатов
                if (that.info.result == null) {
                    // никак не задано - берем шаблон для результатов по умолчанию
                    that.getResultTemplatePath = templatePrefix + that.modelName + "/" + that.phaseName + "/result.html";
                } else {
                    if (that.info.result.empty) {
                        // если задан но пустой - значит пустой шаблон для результатов
                        that.getResultTemplatePath = templatePrefix + "result-empty.html";
                    } else {
                        // если задан и указано из какой компоненты брать, то так и делаем
                        that.getResultTemplatePath =
                            templatePrefix + (that.info.result.fromComponent == null ? that.modelName : that.info.result.fromComponent) + "/"
                            + that.phaseName + "/"
                            + (that.info.result.resultPrefix == null ? '' : that.info.result.resultPrefix) + "_result.html";
                    }
                }
            };

            // функция для инициализации пути that.getTestQueryPath и that.getTestResultsPath
            that.initTestPaths = function () {
                if (that.info.test != null) {
                    // создаём объект для тестового запроса
                    // форма
                    const initObj = that.info.test.customQuery ? {} : "";
                    that.test = {
                        object: initObj, form: {
                            isLoading: false,
                            // не нужно выводить успех операции
                            hideSuccess: true
                        }
                    };
                    if (that.info.test.customQuery) {
                        // кастомная форма для формирования запроса
                        that.getTestQueryPath = templatePrefix + that.modelName + "/" + that.phaseName + "/testQuery.html";
                    } else {
                        // дефолтное поле ввода
                        that.getTestQueryPath = templatePrefix + "testQuery.html";
                    }
                    // урл с шаблоном для отображения результатов тестового запроса
                    that.getTestResultsPath = templatePrefix + that.modelName + "/" + that.phaseName + "/testResult.html";
                }
            };

            // функция для заполнения массива phaseParameters
            that.initParamsTemplatePath = function () {
                if (that.info.params != null) {
                    $.each(that.info.params, function (idx, p) {
                        // название шаблона
                        const name = templatePrefix +
                            (p.fromComponent == null ? that.modelName : p.fromComponent) +
                            "/" +
                            (p.forPhase == null ? that.phaseName : p.forPhase) +
                            "/" +
                            (p.paramsPrefix == null ? '' : (p.paramsPrefix + "_")) + "params.html";

                        // параметры, которые соответствуют этому шаблону
                        that.phaseParameters[name] = p.linkVariable == null
                            ? that.phase.params : that.phase.params[p.linkVariable];
                        // а так же не забываем скопировать функцию, которая говорит о том, когда эти параметры нужно показывать
                        // например, параметр T_REG нужно показывать только если выбран метод обучени T_REG
                        if (p.f_display != null) {
                            if (that.phaseParameters[name].f_display == null) {
                                that.phaseParameters[name].f_display = {}
                            }
                            that.phaseParameters[name].f_display[name] = p.f_display;
                        }
                        that.phaseParameters[name].access = that.access;
                    });
                }
            };

            // все методы инициализации объединяем в общий метод init
            that.init = function () {
                that.initParamsTemplatePath();
                that.initResultTemplatePath();
                that.initTestPaths();
            };

            // вызываем метод инит
            that.init();

            // проверка пуст или null ли словарь dict
            that.dictIsEmpty = function (dict) {
                return dict == null || Object.keys(dict).length == 0;
            };

            // обработчик нажатия кнопки построения этапа
            that.build = function (phase) {
                that.buildCallback({phase: phase});
            };

            // функция для определения иконки статуса этапа
            that.getPhaseStatusIcon = function (status) {
                return mf.getPhaseIcon(status);
            };

            // функция для определения цвета отображения статуса этапа
            that.getPhaseStatusColor = function (status) {
                return mf.getPhaseColor(status);
            };

            // функция получения пути к изображениям
            that.getImageUrl = function () {
                return urls.va.ml + that.modelName + "/"
                    + that.phase.modelId + "/image/" + "?phaseType=" + that.phaseName.toUpperCase() + "&projectVersionId="
                    + that.projectVersionId;
            };

            // функция выполнения тестового запроса
            that.testQuery = function (event) {
                if (event != null) {
                    // не пускаем event дальше - будет перезагрузка формы
                    event.preventDefault();
                }
                common.formOperationWithLoading(
                    that.restAngular.one(urls.va.ml + "/" + that.modelName + "/" + that.phase.modelId + "/")
                        .post(that.phaseName + "Test", that.test.object),
                    that.test.form, function (data) {
                        // берем plain данные, чтобы итерация по ним нормально работала в html-е
                        that.test.results = data.plain();
                    }
                );
            };

            that.phaseReport = function () {
                that.$http.post(urls.va.ml + that.modelName + "/" + that.phase.modelId + "/" + that.phaseName + "Report?projectVersionId=" + that.projectVersionId, {}, {
                    responseType: 'arraybuffer'
                }).success(function (data) {
                    const file = new Blob([data], {type: "application/vnd.ms-excel"});
                    that.fileSaver.saveAs(file, that.modelName + "_" + that.phaseName + "_" + that.phase.modelId + ".xlsx");
                }).error(function (data) {
                    alert("Не удалось получить отчет");
                });
            };

            // функция для отображения времени обучения этапа
            that.getPhaseBuildTime = function () {
                if (that.phase.rebuildTimeMs != null) {
                    // кастим стринг обратно в лонг, инече moment не понимает
                    const time = moment.duration(+that.phase.rebuildTimeMs);
                    return time.minutes() + " min " + time.seconds() + " sec";
                }
            };

            // ставим watch на изменение phase
            scope.$watch("ctrl.phase", function () {
                that.init();
            });


            that.getDatasetParamsTest = function () {
                if (that.phase.params.train + that.phase.params.dev > 1) {
                    that.phase.params.dev =  Math.floor((1 - that.phase.params.train) * 1000) / 1000;
                }
                return Math.floor((1 - that.phase.params.train - that.phase.params.dev) * 1000) / 1000;
            }

        }],
        controllerAs: "ctrl",
    };
}

export default VaModelPhaseDirective;