import {VaMessageIf, VaTBMessage} from "../../../../../data/va/Message";
import {VaTag} from "../../../../../data/va/Tag";
import {Component, Input, ViewChild, ViewEncapsulation} from "@angular/core";
import {StateService} from "@uirouter/core";
import {TagService} from "../tag.service";
import {HttpClient, HttpEventType, HttpResponse} from "@angular/common/http";
import {FileSaverService} from "ngx-filesaver";
import {FileItem} from "../../../common/file-uploader/uploader-vendor/file-item.class";
import {Title} from "@angular/platform-browser";
import {BaseDetailsComponent} from "../../base/base-details.component";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {MatDialog} from "@angular/material/dialog";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {ServerCollectionBridge} from "../../../common/collection/server-collection.bridge";
import {MassOperationButton} from "../../../common/collection/collection.component";
import {MassOperationDialogComponent} from "../../mass-operation/dialog/mass-operation-dialog.component";
import {MassOperation, MassOperationType} from "../../../../../data/va/MassOperation";
import {ServerCollectionFilter} from "../../../common/collection/server-collection.component";
import {Subscription} from "rxjs";

@Component({
    selector: 'tag-messages',
    template: require('./tag-messages.component.html'),
    styles: [require('./tag-messages.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class TagMessagesComponent extends BaseDetailsComponent<VaMessageIf, CompositeKey<number>> {

    @Input()
    tag: VaTag;
    objectIdKey: string;
    destTags: VaTag[];
    file: File[];
    sortFields: SortField[] = [SortField.date(`d`), new SortField("Текст сообщения", "text")];
    showCustomAdd: boolean = false;
    showAddPanel: boolean = false;
    fileUploadSubscription: Subscription;

    readonly massOperationButtons: MassOperationButton[] = [
        {
            title: "Перенести",
            class: 'btn btn-accent',
            operation: selectedItems => this.confirmMove(selectedItems)
        },
        {
            title: "Удалить выбранное",
            class: 'btn btn-plain',
            operation: selectedItems => this.confirmExclude(selectedItems)
        }
    ];

    /**
     * Текст в строке поиска по коллекции
     */
    @Input()
    searchString: string;

    /**
     * Хелпер серверной коллекции
     */
    serverCollectionBridge: ServerCollectionBridge<VaMessageIf, MessageServerCollectionFilter>;

    addExample: any = [{title: 'Загрузить .xlsx и .txt', format: '.txt'},
        {title: 'Добавить вручную', format: 'custom'}];

    /**
     * сколько процентов файла успели передать на бек
     * (юзается в file-uploader'e)
     */
    progressUploading: number;

    /**
     * является ли родительская компонента для SmallTalk'ов
     */
    @Input()
    isSmallTalk: boolean = false;

    @ViewChild("textAreaElement", {static: false}) textAreaElement;

    constructor(protected stateService: StateService,
                protected httpClient: HttpClient,
                protected titleService: Title,
                protected tagService: TagService,
                private dialog: MatDialog,
                protected fileSaver: FileSaverService,
                protected notificationService: NotificationService) {
        super(stateService, null, httpClient, tagService as any, titleService, notificationService);

        this.serverCollectionBridge = new ServerCollectionBridge<VaMessageIf, MessageServerCollectionFilter>({
            tagId: stateService.params['tagId'],
            orderBy: "d",
            orderAsc: false,
            projectVersionId: this.stateService.params['projectVersionId']
        });
    }

    async ngOnInit() {
        await super.ngOnInit();
        this.titleService.setTitle(this.stateService.current.data.title + this.entityTitle);
        // загружаем тематики, в которые можно перенести сообщения
        this.destTags = await this.tagService.loadDestTags(this.tag.key.id);

        if (this.searchString) {
            // инициализируем строку поиска внутри серверной коллекции
            this.serverCollectionBridge.filter.text = this.searchString;
            this.serverCollectionBridge.onFilterChange();
        }
    }

    /**
     * При неуспешном добавлении файла - вывести сообщение об ошибке
     * @param $event
     */
    onImportFileAddFailed($event: string) {
        this.notificationService.error($event)
    }

    /**
     * При изменении контента файл-инпута
     */
    onFileChange(items: FileItem[]) {
        if (!items) {
            this.file = null;
        } else {
            this.file = items.map(item => item._file);
        }
    }

    /**
     * Подтверждение исключения отмеченных сообщений из тематики
     */
    async confirmExclude(selectedItems: number[]) {
        const selectedIds = selectedItems;
        // окончание для "N пример(ов)"
        let wordEnding = "";
        const count = selectedItems.length < 21 ? selectedItems.length : selectedItems.length % 10;
        if (count == 0 && selectedItems.length > 10) {
            wordEnding = 'ов';
        } else if (count > 1) {
            if (count < 5) {
                wordEnding = 'a';
            } else if (count < 21) {
                wordEnding = 'ов';
            }
        }
        // открываем диалог
        const operation = await this.dialog.open(MassOperationDialogComponent, {
            width: '500px',
            data: {
                operation: {ids: selectedIds, type: MassOperationType.DELETE},
                titleText: `Удалить ${selectedItems.length} пример${wordEnding}?`
            }
        }).afterClosed().toPromise<MassOperation>();

        if (!operation) {
            // отмена
            return;
        }
        if (selectedIds.length !== 0) {
            this.isLoading = true;
            await this.tagService.excludeSelected(this.tag.key.id, selectedIds);
            this.serverCollectionBridge.selectedIds.clear();
            this.isLoading = false;
            this.notificationService.success(`<b>Обучающие примеры удалены</b></br>Количество: ${selectedIds.length}`);
        }
    };

    /**
     * Подтвердить перенос
     * @param selectedItems список сообщений
     */
    async confirmMove(selectedItems: number[]) {
        const selectedIds = selectedItems;
        // открываем диалог
        const operation = await this.dialog.open(MassOperationDialogComponent, {
            width: '500px',
            data: {
                operation: {ids: selectedIds, type: MassOperationType.CONFIRM_MOVE},
                tags: this.destTags
            }
        }).afterClosed().toPromise<MassOperation>();

        if (!operation) {
            // отмена
            return;
        }
        if (operation.tagId) {
            // если он не пуст, то переносим выбранные сообщения
            this.isLoading = true;
            await this.tagService.confirmMove(this.tag.key.id, operation.tagId, selectedIds);
            this.serverCollectionBridge.selectedIds.clear();
            this.isLoading = false;
            this.notificationService.success(`<b>Обучающие примеры перенесены</b></br>Количество: ${selectedIds.length}`);
        }
    };

    /**
     * Метод для добавления сообщений в тематику
     */
    add(option: any) {
        this.showCustomAdd = option.format == "custom";
        this.showAddPanel = true;
        this.form.object.text = null;
    }

    /**
     * Создание нового примера
     */
    async create() {
        if (this.showCustomAdd) {
            if (this.form.object.text) {
                this.tagService.addMessage(this.tag.key.id, this.form.object.text).then(() => {
                    this.form.success = true;
                    this.form.object.text = null;
                    this.notificationService.success("Обучающий пример добавлен");
                }).catch(data => {
                    this.handleError(data);
                });
            } else {
                this.notificationService.error("Введите текст сообщения")
            }
        } else {
            if (this.file) {
                await this.addMessagesFromFileToTag(this.file[0]);
            } else {
                this.notificationService.error("Добавьте файл")
            }
        }
    };

    /**
     * Закрыть панель добавления примера
     */
    close() {
        this.showAddPanel = false;
        this.cancelFileUploading();
    }

    /**
     * Выбор/снятие выбора с сообщения
     * @param msg сообщение
     */
    toggle(msg: VaTBMessage) {
        msg.selected = !msg.selected;
        this.serverCollectionBridge.itemUpdate.emit(msg);
    };

    /**
     * Выбор/снятие выбора с сообщения
     * @param msg сообщение
     */
    async delete(msg: VaTBMessage) {
        await this.tagService.excludeSelected(this.tag.key.id, [msg.key.id]);
        this.serverCollectionBridge.selectedIds.clear();
    };

    /**
     * Загрузка файла с сообщениями по выбранной тематике
     */
    downloadMessages(isXlsx: boolean) {
        this.tagService.getMessagesFile(this.tag.key.id, isXlsx).then((data: HttpResponse<ArrayBuffer>) => {
            const file = new Blob([data.body], {type: "text/csv"});
            const fileFormat = isXlsx ? ".xlsx" : ".txt";
            const fileName = "messages_group_id_" + this.tag.key.id + "_project_version_id_" + this.tag.key.projectVersionId + fileFormat;
            this.fileSaver.save(file, fileName);
        }, () => {
            this.notificationService.error('Ошибка загрузки файла');
        });
    };

    /**
     * Метод загрузки файла с сообщениями
     */
    private async addMessagesFromFileToTag(file: File) {

        const formData = new FormData();
        formData.append('file', file, file.name);
        this.progressUploading = 0;
        //Шлем файл
        this.fileUploadSubscription = this.tagService.uploadMessagesFile(formData, this.tag.key.id)
            .subscribe((event: any) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.progressUploading = Math.round(event.loaded / event.total * 100);
                        break;
                    case HttpEventType.Response: {
                        // файл отправили - закрываем форму
                        setTimeout(() => {
                            this.progressUploading = null;
                            this.form.success = true;
                            this.file = null;
                            this.onFileChange(null);
                            this.notificationService.success('Обучающие примеры добавлены');
                            this.showAddPanel = false;
                        }, 1000);
                    }
                }
            }, (data) => {
                this.progressUploading = null;
                this.handleError(data);
            });
    };

    /**
     * Отменить загрузку файла, если она активна
     */
    cancelFileUploading() {
        if (this.fileUploadSubscription && !this.fileUploadSubscription.closed) {
            // если подписывались на событие загрузки, то отменим
            this.fileUploadSubscription.unsubscribe();
        }

        // сбросим данные о файле
        this.progressUploading = null;
        this.form.success = true;
        this.file = null;
        this.onFileChange(null);
    }

    get entityTitle(): string {
        return this.tag.text;
    }

    generateFormObject(): VaMessageIf {
        return new VaMessageIf();
    }

    resizeTextArea() {
        this.textAreaElement.nativeElement.style.height = 'auto';
        this.textAreaElement.nativeElement.style.height = (this.textAreaElement.nativeElement.scrollHeight + 2) + 'px';
    }
}

/**
 * Фильтр серверной коллекции сообщений тематик
 */
export interface MessageServerCollectionFilter extends ServerCollectionFilter {
    tagId: number;
}
