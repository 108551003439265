import {Subject} from "rxjs";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";
import WebSocketService from "../../../../../services/WebSocketService";
import {HttpClient} from "@angular/common/http";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {BaseMasterComponent} from "../../base/base-master.component";
import {TagService} from "../tag.service";
import {VaTag} from "../../../../../data/va/Tag";
import {CompositeKey} from "../../../../../data/va/Common";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {FileItem} from "../../../common/file-uploader/uploader-vendor/file-item.class";
import {getProgressDataPercent, ProgressData, ProgressDataOperation} from "../../../../../data/va/ProgressData";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MessageSearchComponent} from "../search/message-search.component";
import {MessageService} from "../message.service";


@Component({
    selector: 'tags',
    template: require('./tags.component.html'),
    styles: [require('./tags.component.less')]
})
export class TagsComponent extends BaseMasterComponent<VaTag, CompositeKey<number>> implements OnDestroy, OnInit {

    projectVersionId: string;
    treeUpdateSubject: Subject<unknown> = new Subject();
    scriptedTagIds: number[];
    tagsTree: VaTag;
    subscriptionId: string;
    tagIsLoading: boolean = true;
    showImportForm: boolean;
    private importTags: boolean;
    importForm = {
        success: false,
        errors: new Map<string, string>()
    };
    progressData: ProgressData;
    tagsCount: number;

    constructor(protected stateService: StateService,
                protected httpClient: HttpClient,
                protected titleService: Title,
                protected webSocketService: WebSocketService,
                protected tagService: TagService,
                protected notificationService: NotificationService,
                protected bottomSheet: MatBottomSheet,
                private messageService: MessageService) {
        super(titleService, stateService, tagService, httpClient, notificationService);

        this.projectVersionId = stateService.params['projectVersionId'];
    }

    async loadAll() {
        // загружаем скрипты
        this.scriptedTagIds = await this.tagService.getScripts();
        //загружаем дерево тегов
        this.tagsTree = await this.tagService.getSmallTalksAndBusinessTagsTree();
        this.setScriptedFlags(this.tagsTree);
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        // подписываемся на событие ожидания сохранения данных
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "FAQ_PROGRESS",
            fn: (event) => this.receiveProgressPush(event)
        });
        // загружаем все данные
        await Promise.all([this.loadAll(), this.getAccess(), this.loadProgressData()]);
    }

    ngOnDestroy() {
        this.webSocketService.removeListener(this.subscriptionId);
    }

    /**
     * Показать форму импорта FAQ
     *
     * @param forTags для тематик
     */
    displayImportForm(forTags: boolean) {
        this.showImportForm = true;
        this.importTags = forTags;
    }

    /**
     * Выбран ли импорт тематик FAQ (а не сообщений)
     */
    public isTagsImport(): boolean {
        if (!this.progressData?.operation) {
            // импорт не начат
            return this.importTags;
        }
        // импорт в процессе
        switch (this.progressData.operation) {
            case ProgressDataOperation.FAQ_TAGS_IMPORT:
                return true;
            case ProgressDataOperation.FAQ_MESSAGES_IMPORT:
                return false;
            default:
                throw Error('unsupported ' + this.progressData.operation);
        }
    }

    // добавить тематику в дерево
    addNewObj(tag: VaTag): void {
        //могли уже добавить (так как изменения могут быть локальными и от другого пользователя)
        if (!this.getTagById(tag.key.id)) {
            // найти родителя по tag.parentId и к нему в дети добавить тематику
            const parentTag = this.getTagById(tag.parentId);
            if (parentTag.children == null) {
                parentTag.children = [];
            }
            parentTag.children.unshift(tag);
            //при добавлении тематики надо перестроить дерево
            this.treeUpdateSubject.next(tag.key.id);
        }
    }

    // заменить тематику в дерево по ID (например после обновления в форме редактирования)
    replaceObj(tag): void {
        // находим изменяемую тематику в дерево по id
        const oldTag = this.getTagById(tag.key.id);
        // сразу, чтобы не забыть подвесим детей к новой тематике
        tag.children = oldTag.children;

        // если обновлялась корневая тематика, то вернем parentId обратно
        if (oldTag.parentId == this.tagService.FICTIVE_ROOT_TAG_ID) {
            tag.parentId = this.tagService.FICTIVE_ROOT_TAG_ID;
        }

        // если родительская тематика не изменилась
        if (oldTag.parentId === tag.parentId) {
            // просто заменяем старую тематику на новую, не забыв про msgCount
            replaceTag();
        } else {
            // изменилась родительская тематику:
            // нужно удалить тематику из массива у старого родителя
            // и добавить в массив к новому родителю
            // находим старого родителя
            const oldParentTag = this.getTagById(oldTag.parentId);
            // из его детей отфильтровываем наш объект
            oldParentTag.children = oldParentTag.children.filter((el) => {
                return el.key.id !== oldTag.key.id;
            });

            replaceTag();
            // а теперь тогда просто добавим новый объект
            this.addNewObj(oldTag);

            // и пересчитаем кол-во сообщений
            this.setScriptedFlags(this.tagsTree);

            //при изменении родителя надо перестроить дерево
            this.treeUpdateSubject.next();
        }

        function replaceTag() {
            tag.msgCount = oldTag.msgCount;
            tag.isScripted = oldTag.isScripted;
            tag.isScriptedTag = oldTag.isScriptedTag;
            Object.assign(oldTag, tag);
        }
    }

    // удалить тематику из дерева
    deleteObj(tag) {
        if (this.getTagById(tag.key.id)) {
            //могли уже удалить (так как изменения могут быть локальными и от другого пользователя)

            // найти тематику в дереве по tag.key.id и заменить
            const parentTag = this.getTagById(tag.parentId);

            // найти тематику в дереве по tag.key.id и удалить
            for (let i = 0; i < parentTag.children.length; i++) {
                const obj = parentTag.children[i];
                if (obj.key.id === tag.key.id) {
                    // удалить объект из массива
                    parentTag.children.splice(i, 1);
                    break;
                }
            }

            const tagMessages = (tag.messageCount ? Number.parseInt(tag.messageCount) : 0);
            for (let parent = parentTag; parent.parentId; parent = this.getTagById(parent.parentId)) {
                const parentMessages = (parent.messageCount ? Number.parseInt(parent.messageCount) : 0);
                parent.messageCount = "" + (parentMessages - tagMessages);
            }
            this.setScriptedFlags(this.tagsTree);

            //при удалении тематики надо перестроить дерево
            this.treeUpdateSubject.next();
        }
    }

    // поиск тематики по id в дереве
    getTagById(tagId: number | string): any {
        return this.findTagById(this.tagsTree, tagId);
    }

    // поиск тематики по id в дереве - рекурсивная функция
    findTagById(tree: VaTag, tagId: number | string) {
        if (tree.key.id === tagId) {
            // нашли
            return tree;
        } else if (tree.children != null) {
            for (const child of tree.children) {
                // ищем в этой ветке
                const find = this.findTagById(child, tagId);
                if (find != null) {
                    // если нашли - возвращаем
                    return find;
                }
            }
        }
    }

    setScriptedFlags(tag) {
        // mark tag as scripted if it has script
        tag.isScripted = !!this.scriptedTagIds.find(tagId => tagId === tag.key.id);
        tag.isScriptedTag = this.scriptedTagIds ? this.scriptedTagIds.includes(tag.key.id) : false;
    }

    openDetails(tag: VaTag) {
        const state = tag?.userAct ? `robot.tags.act` : `robot.tags.edit`;
        this.stateService.go(state, {
            tagId: tag ? tag.key.id : null,
            query: null
        });
        if (tag?.key.id != null) {
            this.selectedId = `${tag.key.id}`;
        }
    }

    onReady(nodeCount: number) {
        this.tagIsLoading = false;
        this.tagsCount = nodeCount;
    }

    /**
     * Добавили файл для импорта
     */
    onImportFileChange(items: FileItem[]) {
        this.showImportForm = false;
        // шлем файл на сервер
        const formData = new FormData();
        formData.append('file', items.pop()._file);
        this.httpClient.post(`${this.dataService.baseUrl}/faq/${this.importTags ? 'tags' : 'messages'}`, formData)
            .toPromise()
            .catch((reason => {
                let message;
                if (reason.errors?.[0].message) {
                    message = reason.errors.map(error => error.message).join('\n');
                } else {
                    message = JSON.stringify(reason);
                }
                this.notificationService.error(message);
            }));
    }

    /**
     * Неверный формат файла импорта
     */
    onImportFileAddFailed(message: string) {
        this.notificationService.error(message, 5000);
    }

    /**
     * Есть ли операция в процессе
     */
    isProgressDataAvailable(): boolean {
        return this.progressData?.operation && !this.progressData?.finished
    }

    /**
     * Получить пуш с данными о прогрессе обработки
     */
    private async receiveProgressPush(event) {
        const data: ProgressData = JSON.parse(event.details);
        if (data.projectVersionId !== this.stateService.params["projectVersionId"]) {
            // другая версия
            return;
        }
        if (!data.finished) {
            // в процессе
            this.progressData = data;
            return
        }
        // обработка закончена, обновляем данные
        await this.stateService.go('.', {}, {reload: true});
        // отображаем уведомление
        if (data.success) {
            this.notificationService.success(data.success, 30000);
        }
        if (data.info) {
            this.notificationService.info(data.info);
        } else if (data.warning) {
            this.notificationService.warning(data.warning);
        } else if (data.error) {
            this.notificationService.error(data.error);
        }
    }

    /**
     * Загрузить данные о прогрессе обработки
     */
    private async loadProgressData() {
        this.progressData = await this.httpClient.get<ProgressData>(`${this.dataService.baseUrl}/faq/progressData`, {}).toPromise();
    }

    /**
     * @return процент сделанного
     */
    getProgressPercent() {
        return getProgressDataPercent(this.progressData);
    }

    /**
     * Открыть слой с поиском сообщений
     */
    async openSearchLayer() {
        const messageCount = await this.messageService.count();
        this.bottomSheet.open(MessageSearchComponent, {
            panelClass: 'message-search',
            backdropClass: 'backdrop',
            data: {messageCount: messageCount}
        });
    }

}

