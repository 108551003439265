function VaTagNodeDirective() {

    return {
        restrict: "E",
        bindToController: true,
        templateUrl: "/pages/workplace/va/common/directives/tagNode.html",
        controller: ["Restangular", "$scope", "$timeout", function (restangular, $scope, $timeout) {
            const that = this;
            that.restangular = restangular;
            that.$scope = $scope;

            that.expandNode = function (nodeId) {
                var parentScopes = getScopePath(nodeId);
                for (var i = 0; i < parentScopes.length; i++) {
                    parentScopes[i].expand();
                }
            }

            function getRootNodesScope() {
                return angular.element(document.getElementById("tag-tree-root")).scope().$nodesScope.childNodes()[0];
            }

            function getScopePath(nodeId) {
                return getScopePathIter(nodeId, getRootNodesScope(), []);
            }

            function getScopePathIter(nodeId, scope, parentScopeList) {
                if (!scope) {
                    return null;
                }

                var newParentScopeList = parentScopeList.slice();
                newParentScopeList.push(scope);

                if (scope.$modelValue && +scope.$modelValue.key.id === nodeId) return newParentScopeList;

                var foundScopesPath = null;
                var childNodes = scope.childNodes();

                if (!childNodes) {
                    return null;
                }

                for (var i = 0; foundScopesPath === null && i < childNodes.length; i++) {
                    foundScopesPath = getScopePathIter(nodeId, childNodes[i], newParentScopeList);
                }

                return foundScopesPath;
            }

            that.toggleAll = function (node, collapse) {
                if (node.depth() === 1) {
                    if (node.collapsed) {
                        that.expandAll();
                    } else {
                        that.collapseAll();
                    }
                    return;
                }
                if (typeof collapse === "undefined") {
                    node.toggle();
                    collapse = node.collapse;
                } else if (node.collapsed !== collapse && node.childNodesCount() > 0) {
                    node.toggle();
                }
                for (let i = 0; i < node.childNodesCount(); i++) {
                    that.toggleAll(node.childNodes()[i], collapse);
                }
            };

            that.expandAll = function () {
                that.$scope.$broadcast('angular-ui-tree:expand-all');
            };

            that.collapseAll = function () {
                that.$scope.$broadcast('angular-ui-tree:collapse-all');
            };

            $timeout(function () {
                if ($scope.selectedId != null && $scope.selectedId != 0) {
                    that.expandNode($scope.selectedId);
                }
            }, 100);
        }],
        controllerAs: "ctrl",
    };
}


export default VaTagNodeDirective;