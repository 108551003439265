import {VABaseFormController} from "../../../../../../js/workplace/controllers/va/VABaseFormController";
import * as common from "../../../../../../js/workplace/common";
import * as restangular from "restangular";
import {DKBEntity} from "../../../../data/va/Dkb";

// контроллер страницы редактирования дкб поля
export default class VaDkbEditController extends VABaseFormController<DKBEntity> {

    static $inject = ["Restangular", "$scope", "$stateParams", "$state", "TitleService"];

    constructor(Restangular: restangular.IService, $scope: ng.IScope, $stateParams: ng.ui.IStateParamsService,
                $state: ng.ui.IStateService, TitleService: any) {
        // id объекта из state
        const objectId = $stateParams['dkbEntityId'];
        if (!objectId) {
            TitleService.setTitle("Новая сущность базы знаний")
        }
        // объект для формы
        const formObj = {
            name: "",
            attributes: [],
            fields: []
        };

        // параметры формы
        const formOptions = {"create": {title: "Новая сущность базы знаний"}};

        // инициализируем родительский контроллер
        super(Restangular, $scope, $state, $stateParams, objectId, formObj, formOptions, (entity) => {
            TitleService.setTitle(entity.name);
        });

        if (this.objId) {
            // доступ
            this.getAccess();
            // список значений
            this.loadFields();
        }

        // подгружаем атрибуты для селекта
        common.loadValues(this, this.listController.baseUrl, "attributes");
    }

    save() {
        this.commonSave((isNew, data) => {
            if (isNew) {
                this.$state.go('robot.dkb.edit', {dkbEntityId: data.key.id, notifySuccess: true});
            }
        });
    }

    remove() {
        this.commonRemove(() => {
            this.$state.go('robot.dkb', {}, {reload: true});
        });
    }
}
