import {NgModule} from "@angular/core";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {TableComponent} from "./table.component";
import {CommonModule} from "@angular/common";
import {CellProgressComponent} from "./cell-progress.component";
import {EditableListModule} from "../../common/editable-list/editable-list.module";

@NgModule({
    imports: [
        MatTableModule,
        MatSortModule,
        CommonModule,
        EditableListModule,
    ],
    declarations: [TableComponent, CellProgressComponent],
    exports: [TableComponent],
    entryComponents: [TableComponent],
    providers: []
})

export class TableModule {

}
