import {NgModule} from "@angular/core";

import {UIRouterModule} from "@uirouter/angular";
import {CommonModule} from "@angular/common";
import {ExpertModule} from "../../common/expert/expert.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {IntentModelEditComponent} from "./intent/intent-model-edit.component";
import {ModelQueryTestModule} from "../../query-test/model-query-test.module";
import {EruditeFileUploaderModule} from "../../common/file-uploader/erudite-file-uploader.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {AceEditorModule} from "ng2-ace-editor";

@NgModule({
    declarations: [
        IntentModelEditComponent,
    ],
    imports: [
        UIRouterModule,
        CommonModule,
        ExpertModule,
        FormControlModule,
        FormsModule,
        NgSelectModule,
        ModelQueryTestModule,
        EruditeFileUploaderModule,
        MatProgressBarModule,
        AceEditorModule
    ],
    exports: [
        IntentModelEditComponent
    ],
    providers: []
})
export class ModelModule {

    static states = [
        {
            name: 'robot.intent_model',
            url: "/:type/:modelId",
            views: {
                'content@authorized': {
                    component: IntentModelEditComponent
                }
            },
            data: {
                title: "Сценарий",
                newObjectTitle: "Новый сценарий",
                successfulSaveMessage: "Сценарий сохранен",
                meta: {
                    parameters: ["scriptId"],
                    sectionFunction: ([scriptId]) => "Обучение робота",
                    subSectionFunction: ([scriptId]) => scriptId ? "Редактирование сценария" : "Новый сценарий"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ]
}