import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {VersionedDataService} from "../base/versioned-data.service";
import {AppliedEntityType} from "../../entity-usage/entity-usages.model";
import {StateService} from "@uirouter/core";
import WebSocketService from "../../../../services/WebSocketService";
import {TagIdsEnum, VaTag} from "../../../../data/va/Tag";
import {VAScenario} from "../../../../data/va/Script";
import {VaMessage, VaMessageIf} from "../../../../data/va/Message";
import {NamedEntitiesModel} from "../model/named-entities.model";
import {Observable} from "rxjs";
import {ClusterVaTag} from "../model/tb-params.model";


@Injectable()
export class TagService extends VersionedDataService<VaTag, number> {


    baseUrl: string = '/account/expert/tag';

    accountUrl: string = '/account/expert/current';

    baseMsgUrl: string = '/account/expert/msg';

    baseScriptUrl: string = '/account/expert/script';

    baseDialogUrl: string = '/account/expert/tagbuilding/dialog';

    usageEntityType: AppliedEntityType = AppliedEntityType.TAG;

    readonly entityClass = NamedEntitiesModel.VA_MESSAGE;

    constructor(protected httpClient: HttpClient,
                protected webSocketService: WebSocketService,
                protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }

    async getTagList(): Promise<VaTag[]> {
        return await this.httpClient.get<VaTag[]>(`${this.baseUrl}/customList`).toPromise();
    }

    async getTag(tagId: number): Promise<VaTag> {
        return await this.httpClient.get<VaTag>(`${this.baseUrl}/${tagId}`).toPromise();
    }

    async getTagsWithoutScenario(): Promise<VaTag[]> {
        return await this.httpClient.get<VaTag[]>(`${this.baseUrl}/getTagsWithoutScenario`).toPromise();
    }

    async loadAccount(): Promise<any> {
        return await this.httpClient.get(`${this.accountUrl}`).toPromise();
    }

    async getMsgCount(): Promise<any> {
        return await this.httpClient.get(`${this.baseUrl}/msgCounts`).toPromise();
    }

    async getTagsTree(): Promise<VaTag> {
        return await this.httpClient.get<VaTag>(this.baseUrl).toPromise();
    }

    async getRobotAnswers(objId: string): Promise<any> {
        return await this.httpClient.get<any>(`${this.baseMsgUrl}/${objId}/moduleAnswers`).toPromise();
    }

    async getScripts(): Promise<number[]> {
        return await this.httpClient.get<number[]>(`${this.baseUrl}/scripts`).toPromise();
    }

    async getAutocompleteMessage(objId: string): Promise<VaMessageIf> {
        return await this.httpClient.get<VaMessage>(`${this.baseMsgUrl}/${objId}/getAutocompleteMessage`).toPromise();
    }

    async getGroupStatsPreview(objId: string): Promise<any> {
        return await this.httpClient.get(`${this.baseUrl}/${objId}/groupStatsPreview`).toPromise();
    }

    async sendAutocompleteMessage(objId: string, vaMessage: VaMessageIf): Promise<VaMessageIf> {
        return await this.httpClient.post<VaMessageIf>(`${this.baseMsgUrl}/${objId}/autocompleteMessage`, vaMessage).toPromise();
    }

    async update(act: any): Promise<any> {
        return await this.httpClient.patch<any>(`${this.baseUrl}`, act).toPromise();
    }

    async sendCases(keyId: string, versionId: string, boringFormat: boolean): Promise<any> {
        return await this.httpClient.post(`${this.baseScriptUrl}/generate_cases/${keyId}/${boringFormat}`, {}, {
            responseType: 'arraybuffer'
        }).toPromise()
    }

    async sendGroupStats(objId: string) {
        return await this.httpClient.post(`${this.baseUrl}/${objId}/groupStats`, {}, {
            responseType: 'arraybuffer',
            observe: 'response'
        }).toPromise()
    }

    async loadLinkedTags(objId: string): Promise<VaTag[]> {
        return await this.httpClient.get<VaTag[]>(`${this.baseUrl}/${objId}/tags`).toPromise();
    }

    async loadAllTags(): Promise<VaTag[]> {
        return await this.httpClient.get<VaTag[]>(`${this.baseUrl}/tags`).toPromise();
    }

    async loadScript(objId: string): Promise<VAScenario> {
        return await this.httpClient.get<VAScenario>(`${this.baseUrl}/${objId}/script`).toPromise();
    }

    async getMessagesFile(id: any, xlsx: boolean = false) {
        return await this.httpClient.post(`${(this.baseMsgUrl)}/${id}/download`, {}, {
            responseType: 'arraybuffer',
            observe: 'response',
            params: {xlsx: `${xlsx}`}
        }).toPromise();
    }

    async excludeSelected(id: number, messageIds: number[]): Promise<void> {
        return await this.httpClient.post<void>(`${(this.baseMsgUrl)}/${id}/exclude`, messageIds).toPromise();
    }

    async addMessage(id: number, text: string): Promise<VaMessage> {
        return await this.httpClient.post<VaMessage>(`${(this.baseMsgUrl)}/${id}/add`, `${text}`).toPromise();
    }

    async confirmMove(id: number, tagId: any, messageIds: number[]) {
        return await this.httpClient.post<void>(`${(this.baseMsgUrl)}/${id}/move?destTagId=${tagId}`, messageIds).toPromise();
    }

    async loadDestTags(id: number): Promise<VaTag[]> {
        return await this.httpClient.get<VaTag[]>(`${(this.baseMsgUrl)}/${id}/destTags`).toPromise();
    }

    uploadMessagesFile(file: FormData, id: number): Observable<any> {
        return this.httpClient.post<any>(`${(this.baseMsgUrl)}/${id}/upload`, file, {
            reportProgress: true,
            observe: "events"
        });
    }

    async loadTaggedEntities(entityUrl: string, ids: number[]): Promise<any> {
        return await this.httpClient.post(`${entityUrl}/getByListedIds`, ids).toPromise();
    }

    async getTaggedItems(tagId: number): Promise<any[]> {
        return await this.httpClient.get<any[]>(`${this.baseUrl}/${tagId}/taggedItems`).toPromise();
    }

    async getDialog(dialogId: number): Promise<any> {
        return this.httpClient.get<any>(`${this.baseDialogUrl}/${dialogId}`).toPromise();
    }

    public isSmallTalksDir(tag: VaTag): boolean {
        return tag.key.id == TagIdsEnum.SMALL_TALK_ID_ROOT;
    }

    /**
     * Является ли тематика корнем (фиктивным, бизнес-тематик или смолтолков)?
     * @param tag
     */
    public isRoot(tag: VaTag): boolean {
        return tag.key.id == this.FICTIVE_ROOT_TAG_ID || tag.parentId == this.FICTIVE_ROOT_TAG_ID;
    }

    public isFictiveRoot(tag: VaTag): boolean {
        return tag.key.id == this.FICTIVE_ROOT_TAG_ID;
    }

    public isFictiveRootId(tagId: number): boolean {
        return tagId == this.FICTIVE_ROOT_TAG_ID;
    }

    public async getSmallTalksAndBusinessTagsTree(): Promise<VaTag> {
        let tree = await this.getTagsTree();
        const smallTalksDirPosition = tree.children.findIndex(this.isSmallTalksDir);
        // Если в проекте есть корневая тематика смоллтолков, отображаем её на одном уровне с корневой бизнес-тематикой
        if (smallTalksDirPosition == -1) {
            return tree;
        }
        const smallTalksDir = tree.children[smallTalksDirPosition];

        // Отсортируем smallTalks в нужном порядке
        smallTalksDir.children.sort((a, b) => {
            if (a.key.id < b.key.id) {
                return -1;
            }
            if (a.key.id > b.key.id) {
                return 1;
            }
        });

        tree.children.splice(smallTalksDirPosition, 1);
        return this.createRoot([tree, smallTalksDir]);
    }


    public createRoot(children: VaTag[]): VaTag {
        const newRoot = new VaTag({key: {id: this.FICTIVE_ROOT_TAG_ID}, children: children});
        for (let node of newRoot.children) {
            node.parentId = newRoot.key.id;
        }
        return newRoot;
    }

    public createRootCluster(children: ClusterVaTag[]): ClusterVaTag {
        const newRoot = new ClusterVaTag({key: {id: this.FICTIVE_ROOT_TAG_ID}, children: children});
        for (let node of newRoot.children) {
            node.parentId = newRoot.key.id;
        }
        return newRoot;
    }

    /**
     * Запрос на смену использования акта в обучении
     * @param useInTrain - маркер использования в обучении
     * @param act - акт
     */
    changeTrainingState(useInTrain: boolean, act: string): Promise<any> {
        return this.httpClient.get<any>(`${this.baseUrl}/${useInTrain}/${act}/changeTrainingState`).toPromise();
    }

    /**
     * Удаление примера акта
     * @param id - id примера акта
     */
    remove(id: number): Promise<string> {
        return this.httpClient.post<string>(`${this.baseMsgUrl}/${id}/remove`, {}).toPromise();
    }

    get serverCollectionUrl(): string {
        return this.baseMsgUrl;
    }
}
