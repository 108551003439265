import {CompositeKey} from "../../../../../data/va/Common";
import {MarkupSetting, MarkupType} from "./Markup";
import {VaAttribute} from "../../../../../data/va/Attribute";
import {ValueOption, ValueTypeEnum} from "../../../../../data/va/Extractor";
import {CustomizationScript} from "../../../../../data/va/CustomizationScript";
import {DKBField} from "../../../../../data/va/Dkb";
import {MacroObject} from "./MacroObject";
import {AudioRecord} from "../../../../../data/va/AudioRecord";

export class Macro {
    key: CompositeKey<string>;
    channelTextId: number;
    entityId: string;
    type: MacroType;
    markupType: MarkupType;
    conditions: {};
    deleted: boolean;
    attributes: VaAttribute[];
    attributeValues: AttributeValue[];
    script: CustomizationScript;
    dkbField: DKBField;
    dkbValueId: number;
    audioRecord: AudioRecord;

    // view
    /**
     * Выбранный объект
     */
    selectedEntity: MacroObject;
    /**
     * Настройки разметки
     */
    markupSettings: MarkupSetting;


    constructor(object?: any) {
        if (!object) {
            return;
        }
        this.key = object.key;
        this.channelTextId = object.channelTextId;
        this.entityId = object.entityId;
        this.type = object.type;
        if (object.markupType) {
            this.markupType = new MarkupType(object.markupType);
        }
        this.conditions = object.conditions;
        this.deleted = object.deleted;
        this.attributes = object.attributes;
        this.attributeValues = object.attributeValues;
        this.script = object.script;
        this.dkbField = object.dkbField;
        this.dkbValueId = object.dkbValueId;
        this.selectedEntity = object.selectedEntity;
        this.audioRecord = object.audioRecord;
    }

    generateMacroString(): string {
        return "${" + this.key.id + "}";
    }

    setId(macroId: string): void {
        if (this.key && this.key.id) {
            // уже все есть
            return;
        }
        this.key = new CompositeKey<string>();
        this.key.id = macroId;
    }

    /**
     * Читабельная текстовая репрезентация макры
     */
    getTextReplacement() {
        let replacement;
        switch (this.type.name) {
            case MacroTypeEnum.ATTRIBUTE:
                replacement = this.attributes[0].name;
                break;
            case MacroTypeEnum.DKB_FIELD:
                replacement = this.dkbField.name;
                break;
            case MacroTypeEnum.CSCRIPT:
                replacement = this.entityId;
                break;
            case MacroTypeEnum.MARKUP:
                replacement = '';
                break;
            case MacroTypeEnum.AUDIO_RECORD:
                replacement = this.audioRecord.text;
                break;
            default:
                throw Error('unsupported macro type: ' + this.type.name);
        }
        return ` ${replacement} `;
    }

    public isFile(): boolean {
        return this.attributes?.some(attribute => attribute.extractor.valueType.name == ValueTypeEnum.FILE);
    }
}

export enum MacroTypeEnum {
    ATTRIBUTE = "ATTRIBUTE",
    DKB_FIELD = "DKB_FIELD",
    CSCRIPT = "CSCRIPT",
    MARKUP = "MARKUP",
    AUDIO_RECORD = "AUDIO_RECORD"
}

export class AttributeValue {
    left: VaAttribute;
    right: ValueOption;

    constructor(left: VaAttribute, right: ValueOption) {
        this.left = left;
        this.right = right;
    }
}

export class MacroType {
    name: MacroTypeEnum;

    constructor(name: MacroTypeEnum) {
        this.name = name;
        MacroType.BY_NAME.set(name, this);
    }

    static readonly BY_NAME: Map<MacroTypeEnum, MacroType> = new Map<MacroTypeEnum, MacroType>();

    static readonly ATTRIBUTE = new MacroType(MacroTypeEnum.ATTRIBUTE);
    static readonly DKB_FIELD = new MacroType(MacroTypeEnum.DKB_FIELD);
    static readonly CSCRIPT = new MacroType(MacroTypeEnum.CSCRIPT);
    static readonly MARKUP = new MacroType(MacroTypeEnum.MARKUP);
    static readonly AUDIO_RECORD = new MacroType(MacroTypeEnum.AUDIO_RECORD);

}


