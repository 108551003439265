import {NgModule} from "@angular/core";
import {CollectionModule} from "../../common/collection/collection.module";

import {UIRouterModule} from "@uirouter/angular";
import {CommonModule} from "@angular/common";
import {ExpertModule} from "../../common/expert/expert.module";
import {MacroModule} from "../../common/macro/macro.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {FormsModule} from "@angular/forms";
import {FormulationModule} from "../../formulation/formulation.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {AttributeMasterComponent} from "./master/attribute-master.component";
import {AttributeService} from "./attribute.service";
import {ExtractorPropertiesModule} from "../../common/extractor-properties/extractor-properties.module";
import {VaAsrModule} from "../../common/va-asr/va-asr.module";
import {AttributeEditComponent} from "./edit/attribute-edit.component";
import {TaggingItemModule} from "../../common/tagging-item/tagging-item.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {AttributeViewComponent} from "./view/attribute-view.component";
import {AttributeDetailComponent} from "./detail/attribute-detail.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {MatIconModule} from "@angular/material/icon";
import {EruditeIconModule} from "../../common/erudite-icon/erudite-icon.module";

@NgModule({
    declarations: [
        AttributeMasterComponent,
        AttributeDetailComponent,
        AttributeEditComponent,
        AttributeViewComponent
    ],
    imports: [
        CollectionModule,
        UIRouterModule,
        CommonModule,
        ExpertModule,
        MacroModule,
        EntityUsageModule,
        FormControlModule,
        FormsModule,
        FormulationModule,
        NgSelectModule,
        ExtractorPropertiesModule,
        VaAsrModule,
        TaggingItemModule,
        BoilerplateModule,
        AngularSvgIconModule,
        MatIconModule,
        EruditeIconModule
    ],
    exports: [
        AttributeMasterComponent,
        AttributeDetailComponent,
        AttributeEditComponent,
        AttributeViewComponent
    ],
    providers: [
        AttributeService
    ]
})
export class AttributeModule {

    static states = [
        {
            name: "robot.attributes",
            url: "/attributes",
            views: {
                'content@authorized': {
                    component: AttributeMasterComponent
                }
            },
            data: {
                title: "Атрибуты",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.attributes.edit',
            url: "/:mode/:attrId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.attributes': {
                    component: AttributeDetailComponent
                }
            },
            data: {
                title: "Атрибуты | ",
                newObjectTitle: "Новый атрибут",
                editObjectTitle: "Редактирование атрибута",
                successfulSaveMessage: "Атрибут сохранён",
                meta: {
                    parameters: ["attrId"],
                    sectionFunction: ([attrId]) => "Обучение робота",
                    subSectionFunction: ([attrId]) => attrId ? "Редактирование атрибута" : "Новый атрибут"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];

}