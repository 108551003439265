import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";
import {NotificationService} from "../common/snackbar/notification/notification.service";
import {HttpClient} from "@angular/common/http";
import {LicenseService} from "./license.service";
import {LicenseDisplayedType, LicenseUsages} from "./models/license.model";
import {LicensePeriodSummaryParams} from "./models/license-period-summary-params.model";
import {CheckState, LicenseGraphFilterModel} from "./models/license-graph-filter.model";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {
    LicenseChartCounteds,
    LicenseChartPools, LicenseWholeChartModel
} from "./models/license-whole-chart.model";
import {dateStringObject} from "../../../data/va/Common";


@Component({
    selector: 'license',
    template: require('./license.component.html'),
    styles: [require('./license.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class LicenseComponent implements OnInit {
    licenses: LicenseUsages;
    isIncrementalLicenseEnabled: boolean;

    // ******************* МОНИТОРИНГ *******************
    // данные по всем графикам
    chartData: LicenseWholeChartModel;
    // структура для группы кнопок
    licenseType: CheckState[] = [{title: LicenseDisplayedType.INCREMENTAL, checked: true},
        {title: LicenseDisplayedType.PERIODIC, checked: false},
        {title: LicenseDisplayedType.CHANNEL, checked: false},
        {title: LicenseDisplayedType.PROMPTER, checked: false}];
    // фильтр дат
    filter: LicenseGraphFilterModel = new LicenseGraphFilterModel();
    // структура отображения кривых на графике
    lineStylesData: any;
    // основные настройки для графика
    basicOptions = {};
    // цветовая палитра для графика
    colors = ['#3260EC', '#ED3945', '#FE7235', '#F2BB22', '#4DBA6E', '#7B829A',
        '#B2C3FD', '#FFC2C2', '#FFB89A', '#FFE49A', '#BAF2CB', '#D2D5D9'];
    access: boolean;

    constructor(protected stateService: StateService,
                protected httpClient: HttpClient,
                protected licenseService: LicenseService,
                protected titleService: Title,
                protected notificationService: NotificationService) {
        const title = this.stateService.current.data.title;
        this.titleService.setTitle(title);

        // значение по умолчанию (последняя неделя) для фильтра дат
        const today = new Date();
        const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        this.filter.fromDate = new NgbDate(lastWeek.getFullYear(), lastWeek.getMonth() + 1, lastWeek.getDate());
        this.filter.toDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());

    }

    async ngOnInit() {
        this.licenses = await this.licenseService.currentSummary();
        this.isIncrementalLicenseEnabled = await this.licenseService.isIncrementalLicenseEnabled();

        await this.loadChartData();
        this.access = await this.licenseService.getAccess();
    }
    
    /**
     * Загрузить и обновить данные на графике
     */
    async loadChartData() {
        const fromDate = new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day);
        const toDate = new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day);

        const params: LicensePeriodSummaryParams = new LicensePeriodSummaryParams();
        params.fromDate.dateString = dateStringObject(fromDate).dateString;
        params.toDate.dateString = dateStringObject(toDate).dateString;

        this.chartData = await this.licenseService.periodSummary(params);

        this.chartDataChanged();
    }

    chartDataChanged(): void {
        // выбранный тип лицензий
        const selectedLicenseDisplayedType = this.licenseType.find(item => item.checked).title;

        if (this.chartData) {
            switch (selectedLicenseDisplayedType) {
                case LicenseDisplayedType.INCREMENTAL:
                    if (this.chartData.incrementals) {
                        let incrementals = this.getCountedCoordinates(this.chartData.incrementals);
                        this.initializedChartData(incrementals[0], incrementals[1]);
                    } else {
                        this.lineStylesData = null;
                    }
                    break;
                case LicenseDisplayedType.PERIODIC:
                    if (this.chartData.periodics) {
                        let periodics = this.getCountedCoordinates(this.chartData.periodics);
                        this.initializedChartData(periodics[0], periodics[1]);
                    } else {
                        this.lineStylesData = null;
                    }
                    break;
                case LicenseDisplayedType.CHANNEL:
                    if (this.chartData.channels) {
                        let channels = this.getPoolCoordinates(this.chartData.channels);
                        this.initializedChartData(channels[0], channels[1], true);
                    } else {
                        this.lineStylesData = null;
                    }
                    break;
                case LicenseDisplayedType.PROMPTER:
                    if (this.chartData.prompters) {
                        let prompters = this.getPoolCoordinates(this.chartData.prompters);
                        this.initializedChartData(prompters[0], prompters[1], true);
                    } else {
                        this.lineStylesData = null;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Return array of date string (dd.mm.yyyy hh:mm)
     * @param dateTimestampArray - array of timestamps
     */
    dateArrayConverter(dateTimestampArray: any): [] {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };

        return dateTimestampArray.map(dateTimestamp => new Date(Number(dateTimestamp)).toLocaleDateString("ru-RU", options).replace(',', ''));
    }

    /**
     * Конвертируем данные с бека из LicenseChartPools в библиотечный формат
     */
    getPoolCoordinates(data: LicenseChartPools): any[] {
        let xCoordinates = this.dateArrayConverter(data.times);
        let yCoordinates = data.pools.map(dataset => {
            return [{
                type: 'line',
                label: "среднее " + dataset.poolId + " " + dataset.configId,
                data: dataset.averages.map(num=>num.toFixed(2)),
                fill: false,
                tension: .4
            }, {
                type: 'bar',
                label: "макс " + dataset.poolId + " " + dataset.configId,
                data: dataset.maximums,
                fill: true,
                tension: .4
            }];
        });

        return [xCoordinates, yCoordinates.reduce((acc, val) => acc.concat(val), [])];
    }

    /**
     * Конвертируем данные с бека из LicenseChartCounteds в библиотечный формат
     */
    getCountedCoordinates(data: LicenseChartCounteds): any[] {
        let xCoordinates = this.dateArrayConverter(data.times);
        let yCoordinates = data.lines.map(dataset => {
            return {
                type: 'line',
                label: dataset.configId,
                data: dataset.totals,
                fill: false,
                tension: .4
            }
        });

        return [xCoordinates, yCoordinates];
    }

    /**
     * Заполнить график данными
     * @param xs - координаты по оси абсцисс
     * @param ys - координаты для оси ординат
     * @param monoColor - для канальных и суфлера line и bar должны быть одного цвета
     */
    initializedChartData(xs: any[], ys: any[], monoColor?: boolean): void {
        // по кругу инициализируем графики цветами из палитры
        let color;
        ys.map((item, index) => {
            if (monoColor && item.type == 'line' || !monoColor) {
                index = index > this.colors.length ? index - this.colors.length : index;
                color = this.colors[index];
            }

            item.backgroundColor = color;
            item.borderColor = color;

        })
        this.lineStylesData = {
            labels: xs,
            datasets: ys
        };
    }

    /**
     * Отправить на бек значение ПИКОВЫЕ ЛИЦЕНЗИИ вкл/выкл
     * @param enable
     */
    pushSwitchIncremental(enable: boolean): void {
        this.licenseService.switchIncremental(enable).then(() => {
        }, () => {
        });
    }

    /**
     * Стиль для каждой кнопочки из group-btn
     */
    stateButtonClass(state: CheckState): string {
        switch (state.checked) {
            case true:
                return 'group-btn-active';
            case false:
                return 'group-btn';
            default:
                throw new Error();
        }
    }

    /**
     * Получить новые данные для графика после выбора кнопки из group-btn
     * @param state
     */
    setCheckState(state: string): void {
        this.licenseType.map(item => {
            item.checked = item.title == state;
        })
        this.chartDataChanged();
    }

    /**
     * Проверить период из фильтра по количеству дней и построить новые графики в случае успеха
     */
    selectDate(): void {
        if (this.filter.toDate != null) {
            const from = new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day);
            const to = new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day);
            const diffDays = Math.ceil(Math.abs(from.getTime() - to.getTime()) / (1000 * 3600 * 24));

            if (diffDays >= 31) {
                this.notificationService.warning("Нельзя указать период больше 31 дня");
                return;
            } else {
                this.loadChartData().then(() => {
                });
            }
        }
    }
}
