var ServerCollectionComponent = {

    templateUrl: "/pages/workplace/elements/collection/serverCollection.html",
    replace: true,
    transclude: {
        // инструменты для добавления в шапку коллекции
        'toolbox': '?collectionToolbox',
        // инструменты поиска и сортировки коллекции
        'filter': '?collectionFilter',
        // тело коллекции
        'body': 'collectionBody'
    },
    bindings: {
        // заголовок коллекции
        title: "=",

        // можно ли сворачивать блок
        collapsible: "<",

        // сворачивать ли body
        collapseBody: "<",

        // вся коллекция размещается на одной карточке
        oneCard: "<",

        // вообще не нужно делать карточку, она уже есть где-то снаружи
        withoutCard: "<",

        // информация о странице - текущая + сколько всего элементов, сколько элементов на странице
        page: "=",

        // метод, который надо вызывать при выборе страницы
        onPage: "&",

        // для листания снаружи
        currentPage: "<"
    },

    controllerAs: "collection",

    controller: ["$scope", "$timeout", "$transclude", function (scope, timeout, transclude) {
        var that = this;
        // отображать ли сейчас коллекцию
        that.isVisible = true;
        that.currentPage = that.page.pageNum;

        // переключение видимости содержимого
        that.toggleVisibility = function () {
            if (!this.collapsible) {
                return;
            }
            this.isVisible = !this.isVisible;
        };

        that.toolboxShow = function () {
            return transclude.isSlotFilled('toolbox');
        };
        // если фильтр не задан, то обертка вокруг него тоже не нужна
        that.filterShow = function () {
            return transclude.isSlotFilled('filter');
        };

        that.paginationInput = that.currentPage;

        that.onPageSelect = function () {
            that.page.pageNum = that.currentPage;
            that.paginationInput = that.currentPage;
            timeout(function () {
                if (that.onPage) {
                    that.onPage();
                }
            });
        };

        that.goToPage = function () {
            let page = parseInt(that.paginationInput);
            if (!isNaN(page)) {
                that.currentPage = page;
                that.onPageSelect();
            }
        }

    }]
};

export default ServerCollectionComponent;