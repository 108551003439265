import {Component, Input, OnInit} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {EventType, JournalsFilter} from "../journals.model";
import {Account} from "../../../../data/va/Account";
import {AccountService} from "../../va/account/account.service";
import {Form} from "../../va/base/base-details.component";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'journals-master',
    template: require('./journals-master.component.html'),
    styles: [require('./journals-master.component.less')]
})
export class JournalsMasterComponent implements OnInit {

    filterForm = new Form<JournalsFilter>();

    accounts: Account[] = [];
    sections: string[] = [];
    actionTypes: EventType[] = [];

    @Input()
    uiButtonRoute: string = "journals.actions.events";

    @Input()
    journalType: string = "changes";

    constructor(titleService: Title,
                protected stateService: StateService,
                protected httpClient: HttpClient,
                protected accountService: AccountService) {
        const title: string = this.stateService.current.data.title;
        titleService.setTitle(title);
        let filterData = stateService.params['filter'];
        if (filterData) {
            this.filterForm.object = JSON.parse(stateService.params['filter']);
        } else {
            this.filterForm.object = new JournalsFilter();
        }
    }

    async ngOnInit() {
        this.accounts = (await this.accountService.findAll()).map(chatAccountData => chatAccountData.account);
        let baseUrl = '/account/journal';
        if (this.journalType == 'changes') {
            this.sections = await this.httpClient.get<any>(`${baseUrl}/sections`).toPromise();
            this.actionTypes = await this.httpClient.get<any>(`${baseUrl}/actionTypes`).toPromise();
        }

        if (this.journalType == 'logins') {
            baseUrl = '/account/loginjournal';
            this.actionTypes = await this.httpClient.get<any>(`${baseUrl}/eventTypes`).toPromise();
        }
    }

    filter() {
        if (this.filterForm.object?.fromDate && this.filterForm.object?.toDate) {
            this.filterForm.errors.clear();
            this.stateService.go(this.uiButtonRoute, {filter: JSON.stringify(this.filterForm.object)});
        } else {
            this.filterForm.errors.set("date", "Необходимо заполнить диапазон дат");
        }
    }
}
