import {ChangeDetectorRef, Component, Inject, ViewEncapsulation} from "@angular/core";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MessageService} from "../message.service";
import {VaMessage} from "../../../../../data/va/Message";

@Component({
    selector: 'message-search',
    template: require('./message-search.component.html'),
    styles: [require('./message-search.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class MessageSearchComponent {

    /**
     * Количество сообщений
     */
    messageCount: number;

    /**
     * Поисковый запрос
     */
    searchQuery: string;

    /**
     * Результаты поиска по запросу
     */
    searchResult: MessageSearchResult[] = [];

    /**
     * Флаг выполнения поискового запроса
     */
    requestInProgress: boolean = false;

    constructor(private messageService: MessageService,
                private bottomSheetRef: MatBottomSheetRef<MessageSearchComponent>,
                private changeDetectorRef: ChangeDetectorRef,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: { search: boolean, messageCount: number }) {
        this.messageCount = this.data.messageCount;
    }

    /**
     * Переключить видимость прогресс-бара
     */
    toggleProgressBarVisibility() {
        this.requestInProgress = !this.requestInProgress;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Удалить соообщение
     * @param group группа результатов
     * @param id    идентификатор сообщения
     */
    async remove(group: MessageSearchResult, id: number) {
        await this.messageService.remove(id);
        // удаляем из списка сообщений
        const messageIndex = group.messages.findIndex((message: any) => message.id == id);
        group.messages.splice(messageIndex, 1);
        // если группа оказалось пустой, то тоже удаляем
        if (group.messages.length == 0) {
            const groupIndex = this.searchResult.findIndex(result => result.tagId == group.tagId);
            this.searchResult.splice(groupIndex, 1);
        }
        this.messageCount = await this.messageService.count();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Закройся, ёпт
     */
    close() {
        this.bottomSheetRef.dismiss();
    }

    /**
     * Оптимизация рендера вложенных ngFor
     * @param index
     * @param item
     */
    trackByFn(index, item) {
        return item.id;
    }

    /**
     *
     * @param index
     * @param item
     */
    tagTrackByFn(index, item){
        return item.tagId;
    }

    /**
     * Поиск on-demand
     */
    async search() {
        if (this.searchQuery?.length < 1) {
            return;
        }
        this.toggleProgressBarVisibility();
        // спрашиваем у сервера
        this.searchResult = await this.messageService.search(this.searchQuery);
        this.toggleProgressBarVisibility();
    }
}

/**
 * Результаты поиска
 */
interface MessageSearchResult {

    tagName: string;
    tagId: number;
    messages: VaMessage[]

}