import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
    ComparisonOperators,
    DateVar,
    DateVariableOperands,
    NumberVariableOperands,
    Operator,
    VariableOperand
} from "../attribute-rule.model";
import {ExpressionPart, ExpressionType} from "../../../../../data/va/VaScriptCondition";


@Component({
    selector: 'expression-part',
    template: require('./expression-part.component.html'),
    styles: [require('./expression-part.component.less')]
})
export class ExpressionPartComponent implements OnInit {

    @Input()
    type: ExpressionType;

    @Input()
    part: ExpressionPart;

    @Output()
    partChanged = new EventEmitter<void>();

    @Input()
    showErrors = false;

    operators: Operator[] = ComparisonOperators;

    leftOperandList: VariableOperand[][];

    selectedLeftOperands: VariableOperand[] = [];

    isCompoundVariable: boolean = false;

    ngOnInit(): void {
        // настроим значения для выбора левого операнда
        switch (this.type) {
            case ExpressionType.DATETIME:
                this.leftOperandList = DateVariableOperands;
                this.isCompoundVariable = true;
                break;
            case ExpressionType.INT:
            case ExpressionType.FLOAT:
                this.leftOperandList = NumberVariableOperands;
                this.selectedLeftOperands = this.leftOperandList[0].slice();
                this.isCompoundVariable = false;

                this.generateLeftOperand();
                // говорим, что что-то изменилось
                this.notifyChanged();
                break;
        }


        if (this.part.leftOperand) {
            // распарсим левый операнд
            const operandsList = this.part.leftOperand.split(/_(.+)/).filter(v => v.length > 0);
            if (operandsList.length > 1) {
                this.selectedLeftOperands = operandsList.map((operandString: string, index: number) => {
                    return this.leftOperandList[index].filter((op: VariableOperand) => op.name == operandString)[0];
                });
            }
        }

        if (this.part.rightOperand) {
            // если есть правый операнд -- валидируем его
            this.validateRightOperand();
        }
    }

    /**
     * Валидация введённого значения
     */
    validateRightOperand() {
        if (this.part.rightOperand === undefined || this.part.rightOperand === '') {
            this.part.isValid = false;
            return;
        }
        let value = +this.part?.rightOperand.replace(',', '.');

        if (isNaN(value)) {
            this.part.isValid = false;
            return;
        }

        let valid = true;
        if (this.type == ExpressionType.DATETIME) {
            if (this.selectedLeftOperands.length < 2) {
                valid = false;
            } else {
                // проверим что введены правильные числа для дат
                const secondPart: DateVar = this.selectedLeftOperands[1].name as DateVar;
                if (secondPart) {
                    switch (secondPart) {
                        case "day_of_year":
                            if (value < 1 || value > 365) {
                                valid = false;
                            }
                            break;
                        case "month":
                            if (value < 1 || value > 12) {
                                valid = false;
                            }
                            break;
                        case "year":
                            if ((value + "").length < 4) {
                                valid = false;
                            }
                            break;
                        case "hour":
                            if (value < 0 || value > 23) {
                                valid = false;
                            }
                            break;
                        case "minute":
                        case "second":
                            if (value < 0 || value > 59) {
                                valid = false;
                            }
                            break;
                        case "day":
                            if (value < 1 || value > 31) {
                                valid = false;
                            }
                            break;

                    }
                }
            }
        }
        this.part.isValid = valid;
    }

    onLeftOperandNameChange() {
        if (this.leftOperandList.length !== this.selectedLeftOperands.length) {
            // выбраны не все значения
            return;
        }

        this.generateLeftOperand();

        // говорим, что что-то изменилось
        this.notifyChanged();
    }

    private generateLeftOperand() {
        // получаем имя переменной
        this.part.leftOperand = this.selectedLeftOperands.map(operandPart => operandPart.name).join("_");
    }

    notifyChanged() {
        this.partChanged.emit();
    }

}
