import * as urls from "../../../../../../js/workplace/urls.js";
import * as common from "../../../../../../js/workplace/common.js";
import restangular from "restangular";
import {CompositeKey} from "../../../../data/va/Common";
import {SimilarMessage} from "../../../../data/va/TagMessageDistribution";
import {VaAttribute} from "../../../../data/va/Attribute";
import {VaTag} from "../../../../data/va/Tag";
import {ValueOption} from "../../../../data/va/Extractor";
import {AttemptDTO} from "../../../../io/components/dialog/model/correction.model";
import {InfoType} from "../../../../io/components/dialog/model/dialog-context.model";
import {ConfirmInfoType, ExtractedValueType} from "../../../../io/components/dialog/model/extracted-value.model";


interface ClassifyResult {
    tags: any[];
    preprocessedExtractionResult?: string;
    tagModel: {
        key: CompositeKey<number>;
    }
}

interface ExtractingEntity {
    key?: CompositeKey<number>;
    extractingInfoType?: ExtractedValueType;
    infoType?: InfoType;
    entity?: {
        key: CompositeKey<number>
    };
    value?: ValueOption;
}

interface ExtractedEntityDescRow {
    left: string;
    middle: string;
    right: string;
}

interface Form {
    //текст реплики
    reply: string,
    isLoading: boolean,
    // не нужно выводить успех операции
    hideSuccess: boolean,
    errors: any,
    //извлекать определенную сущность
    showExtractEntity: boolean,
    //извлекаемая сущность
    extractingEntity: VaAttribute | AttemptDTO
}

interface CheckReplyFormFilling {
    action: CheckReplyAction;
    text: string
    extractedValueType: ExtractedValueType;
    attributeId: number;
    confirmInfoType: ConfirmInfoType;
    confirmInfoId: number;
}

enum CheckReplyAction {
    CLASSIFY = 'CLASSIFY',
    EXTRACT = 'EXTRACT'
}

export default class VaMlCheckReplyController {

    restAngular: restangular.IService;
    promiseService: angular.IQService;
    state: ng.ui.IStateService;
    stateParams: ng.ui.IStateParamsService;

    baseUrl: string = urls.va.ml;
    form: Form;
    tUrls: any;
    tCommon: any;
    titleService: any;

    classifyResult: ClassifyResult;
    extractResult: ExtractedEntityDescRow[];
    similarResult: SimilarMessage[];
    toSimilarButton: boolean;


    private type: ExtractedValueType = ExtractedValueType.ATTRIBUTE;
    // атрибуты
    private attributes: any[] = [];
    // тематики
    private tags: VaTag[] = [];
    // данные для извлечения
    private currentExtractables: VaAttribute[] = [];

    static $inject = ["Restangular", "$scope", "$stateParams", "$state", "TitleService", "$q"];

    constructor(Restangular: restangular.IService,
                $scope: angular.IScope,
                $stateParams: ng.ui.IStateParamsService,
                $state: ng.ui.IStateService,
                TitleService,
                promiseService: angular.IQService) {
        this.tUrls = urls;
        this.tCommon = common;
        this.titleService = TitleService;

        this.baseUrl = this.tUrls.va.ml;
        this.restAngular = Restangular;
        this.promiseService = promiseService;
        this.state = $state;
        this.stateParams = $stateParams;
        this.toSimilarButton = false;

        // форма
        this.form = {
            reply: $stateParams['reply'],
            isLoading: false,
            hideSuccess: true,
            showExtractEntity: false,
            errors: {},
            extractingEntity: null

        };

        // при изменении типа извлекаемой сущности
        $scope.$on("SelectExtractableTypeDirective.onSelectTypeChanged", (event, type: string) => {
            // надо обнулить извлечение
            this.form.extractingEntity = null;
            this.extractResult = null;
            // выбрать нужный источник для селекта
            if (type === ExtractedValueType.ATTRIBUTE) {
                this.currentExtractables = this.attributes;
            } else {
                this.currentExtractables = null;
            }
        });
        this.titleService.setTitle("Проверка моделей по реплике");

        this.loadData();
    }

    /**
     *  загрузка всех необходимых данных:
     *  решений, атрибутов, тематик
     */
    private loadData() {
        this.promiseService.all([this.loadAttributes(), this.loadTags()])
            .then(([attributes, tags]: [VaAttribute[], VaTag[]]) => {
                this.attributes = this.restAngular.stripRestangular(attributes);
                this.tags = this.restAngular.stripRestangular(tags);
                this.currentExtractables = this.attributes;
                if (this.form.reply) {
                    // если текст задан при открытии страницы, сразу запускаем классификацию
                    this.classify();
                } else {
                    const replyId = this.stateParams['replyId'];
                    if (replyId) {
                        this.fillForm(replyId);
                    }
                }
            });


    }


    /**
     * Заполнение данных формы по реплике из диалога
     */
    private fillForm(replyId: any) {
        this.restAngular.one(this.baseUrl + "fillCheckReply?replyId=" + replyId).get().then((filling: CheckReplyFormFilling) => {
            // текст реплики
            this.form.reply = filling.text;
            if (!this.form.reply) {
                // нет текста
                return;
            }
            this.type = filling.extractedValueType;
            if (this.type) {
                // заполняем данные для проверки извлечения
                this.fillExtract(filling);
            }
            // проверяем
            this.classifyOrExtract();
        });
    }

    /**
     * Заполнение формы данными по извлечению в реплике
     */
    private fillExtract(filling: CheckReplyFormFilling) {
        switch (this.type) {
            // извлекаем атрибут
            case ExtractedValueType.ATTRIBUTE:
                if (filling.attributeId) {
                    this.form.extractingEntity = this.attributes.find(attribute => attribute.key.id == filling.attributeId);
                }
                break;
            case ExtractedValueType.CONFIRMATION:
                // извлекаем подтверждение
                if (!filling.confirmInfoType || !filling.confirmInfoId) {
                    break
                }
                switch (filling.confirmInfoType.name) {
                    case ConfirmInfoType.ATTRIBUTE.name:
                        this.form.extractingEntity = this.attributes.find(attribute => attribute.key.id == filling.confirmInfoId);
                        break;
                    case ConfirmInfoType.TAG.name:
                        const entity = this.tags.find(tag => tag.key.id == filling.confirmInfoId);
                        this.form.extractingEntity = {
                            confirmInfoType: ConfirmInfoType.TAG,
                            confirmInfoId: filling.confirmInfoId,
                            entity: entity
                        } as AttemptDTO;
                        break;
                    default:
                        throw new Error('unexpected filling.confirmInfoType ' + filling.confirmInfoType);
                }
                break;
            default:
                throw new Error('unexpected type ' + this.type);
        }
        // показываем селект извлекаемых сущностей
        this.form.showExtractEntity = true;
    }

    /**
     * Загрузка атрибутов
     */
    private loadAttributes(): restangular.ICollectionPromise<VaAttribute> {
        return this.restAngular.one(urls.va.attributes).getList();
    }

    /**
     * Загрузка тематик
     */
    private loadTags(): restangular.ICollectionPromise<VaTag> {
        return this.restAngular.one(`${urls.va.tags}customList`).getList();
    }


    public classify() {
        this.clearData();
        if (this.form.reply === '') {
            this.form.errors["reply"] = "Необходимо ввести реплику";
            return;
        }
        this.tCommon.formOperationWithLoading(
            this.restAngular.one(this.baseUrl)
                .post("classify", this.form.reply), this.form, (data) => {
                this.classifyResult = data;
            });
    };

    public extract() {
        this.clearData();
        const form = this.form;
        if (form.reply === '') {
            form.errors["reply"] = "Необходимо ввести реплику";
            return;
        }
        let query = {};
        if (form.showExtractEntity && form.extractingEntity) {
            if (this.type === ExtractedValueType.CONFIRMATION) {
                const attempt = form.extractingEntity as AttemptDTO;
                query = {
                    type: 'CONFIRMATION',
                    confirmInfoType: attempt.confirmInfoType != null ? attempt.confirmInfoType.name : null,
                    confirmInfoId: attempt.entity != null ? attempt.entity.key.id : null,
                };
            } else {
                const attribute = form.extractingEntity as VaAttribute;
                if (!attribute.key) {
                    form.errors["extractingEntity"] = "Необходимо выбрать объект";
                    return;
                }
                query = {
                    type: 'ATTRIBUTE',
                    attributeId: attribute.key.id
                };
            }
        }

        this.tCommon.formOperationWithLoading(
            this.restAngular.one(this.baseUrl)
                .post("extract", form.reply, query), form, (data) => {
                this.extractResult = data;
            });
    };

    /**
     * Классифицировать или извлечь в зависимости от выбранных в форме данных
     */
    classifyOrExtract() {
        if (this.form.showExtractEntity && this.form.extractingEntity) {
            this.extract();
        } else {
            this.classify();
        }
    }

    private clearData() {
        this.classifyResult = null;
        this.extractResult = null;
        this.similarResult = null;
        this.toSimilarButton = false;
    }
}