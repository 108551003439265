import * as moment from 'moment';
import * as urls from "../../../../../js/workplace/urls.js";
import restangular from "restangular";
import * as common from "../../../../../js/workplace/common.js";
import TitleService from "../../../../../js/workplace/services/TitleService";
import {DatePickerOptions, dateStringObject} from "../../../data/va/Common";
import {MLTaskType} from "../../analysis/Analysis";
import {DialogCorrectionType} from "../../../io/components/dialog/model/correction.model";
import {CheckState} from "../../../io/components/dialog/model/dialog.model";

export default class VaTrendDetectionFilterController {
    trendUrl: string;
    private baseUrl: string;
    private restAngular: restangular.IService;
    private state: ng.ui.IStateService;
    private scope: ng.IScope;
    private stateParams: ng.ui.IStateParamsService;
    private datepickerOptions: DatePickerOptions;
    private projectVersionId: string;
    private displayDialogsFilter: boolean;
    private resultDisplayState: boolean;
    private object: any;
    private checkStates: CheckState[] = [];
    private correctionTypes: DialogCorrectionType[] = [];
    private mlTasks: MLTaskType[] = [];
    private projectTypes: any[];
    private extendedFilter: boolean;
    private ratings: number[];
    private experts: any[];
    private access: boolean;
    private dialogFilterObject: any;
    private phrasesWithChangedImportance: any[];

    constructor(Restangular: restangular.IService, $scope: ng.IScope, $state: ng.ui.IStateService, $stateParams: ng.ui.IStateParamsService, TitleService: TitleService) {
        TitleService.setTitle("");
        this.restAngular = Restangular;
        this.baseUrl = urls.va.analysis;
        this.trendUrl = urls.va.trend_detection;
        this.state = $state;
        this.scope = $scope;
        this.stateParams = $stateParams;

        // Параметры для datepicker-а
        this.datepickerOptions = common.rangeDatepickerOpts;

        this.displayDialogsFilter = false;
        this.resultDisplayState = false;

        this.projectVersionId = $stateParams['projectVersionId'];

        const JAVA_MAX_INTEGER = Math.pow(2, 31) - 1;

        // если есть - то берём объект из statе-а
        const object = this.objFromJson($state.params["filter"]);
        if (object !== null) {
            // он там лежит как json, так что попарсим его
            this.object = object;
        } else {
            // иначе создаём новый объект
            this.object = {
                date: {
                    // minus week from now
                    startDate: moment().subtract(7, 'day'),
                    // now
                    endDate: moment().subtract(1, 'day'),
                    enabled: true
                },
                projectVersionId: this.projectVersionId,
                applyTrainData: false,
                minSupport: 0.0,
                minCorrelation: 0.0,
                minCount: 0,
                applyMaxPatterns: false,
                lengthCountRelation: "",
                applyBasePeriod: false,
                interval: "",
                confidenceLevel: 0.0,
                unlikelinessThreshold: 0.0,
                estimation: "",
                orderType: "",
                buildType: "",
                filters: {
                    date: {
                        // minus week from now
                        startDate: moment(),
                        // now
                        endDate: moment(),
                        enabled: true
                    },
                    // оценки пользователей
                    userRatings: {value: [], enabled: false},
                    // участвовал ли робот?
                    isVa: {value: false, enabled: false},
                    // оценки эксперта
                    expertRatings: {value: [], enabled: false},
                    // эксперты
                    expertIds: {value: [], enabled: false},
                    // состояние (проверки) диалога
                    checkStates: {value: [], enabled: false},
                    autocheck: {value: false, enabled: false},
                    correctionTypes: {value: [], enabled: false},
                    redirectedToSupport: {value: false, enabled: false},
                    withoutTag: {value: false, enabled: false},
                    dialogTypes: {value: [], enabled: false},
                    hadSuccessfulSolutions: {value: false, enabled: false},
                    userKeyWords: {value: "", types: [], enabled: false},
                    channels: {value: [], enabled: false},
                    viewType: "",
                    sortParams: {field: 'DATE', order: 'DESC'},
                    pageConfig: {pageNumber: 0, pageSize: JAVA_MAX_INTEGER},
                    projectVersionId: this.projectVersionId
                },
                trendPage: {
                    pageNumber: 1,
                    pageSize: 2
                },
                dialogPage: {
                    pageNumber: 1,
                    pageSize: 5
                },
                showPhrasesWithChangedImportance: false,
                // Флаг заполнения значениями по умолчанию
                loaded: false
            }
        }

        this.checkStates = [];
        this.correctionTypes = [];
        this.mlTasks = [];
        this.experts = [];
        this.projectTypes = [];
        // возможные оценки диалогу (от эксперта)
        this.ratings = [1, 2, 3, 4, 5];
        this.extendedFilter = false;
        // Загрузим данные
        this.loadValues();

        $scope.$on('change-importance-upwards', (event, node) => {
            common.loadValues(this, this.trendUrl, "phrasesWithChangedImportance");
        });

        this.restAngular.one(this.trendUrl, "access").get()
            .then((data) => {
                this.access = data.access;
            }, (data) => {
            });
    }

    /**
     * Конвертация объекта фильтра в json
     */
    objFromJson(object) {
        try {
            const obj = JSON.parse(object);
            obj.date.startDate = moment(obj.date.startDate);
            obj.date.endDate = moment(obj.date.endDate);
            obj.filters.date.startDate = moment(obj.filters.date.startDate);
            obj.filters.date.endDate = moment(obj.filters.date.endDate);
            return obj;
        } catch (e) {
            return null;
        }
    }

    /**
     * Получение текущего фильтра в JSON-виде, чтобы положить в state
     */
    objAsJson() {
        const filter = angular.copy(this.object);
        filter.date.startDate = this.object.date.startDate.format();
        filter.date.endDate = this.object.date.endDate.format();
        return JSON.stringify(filter);
    }

    /**
     * Получение фильтра для выполнения запроса на сервер
     * @returns {{}}
     */
    getFilter() {
        const filter: any = {};
        for (let key in this.object) {
            if (!this.object.hasOwnProperty(key)) {
                continue;
            }
            // если фильтр включён (или это
            if (this.object[key].enabled || key === "date") {
                if (key === "date") {
                    // дату в формате unix
                    filter.baseFromDate = dateStringObject(this.object[key].startDate);
                    filter.baseToDate = dateStringObject(this.object[key].endDate);
                }
            } else if (key === "filters") {
                const dialogFilter: any = {};
                this.dialogFilterObject = this.object[key];
                for (let filterKey in  this.dialogFilterObject) {
                    if (!this.dialogFilterObject.hasOwnProperty(filterKey)) {
                        continue;
                    }
                    if (filterKey === "viewType" || filterKey === "tagIdForSubtreeFiltering"|| filterKey === "singleTagIdForFiltering" || filterKey === "projectVersionId") {
                        dialogFilter[filterKey] = this.dialogFilterObject[filterKey];
                    } else if (filterKey === "pageConfig") {
                        // тут объект - копируем его мы потом страницу на -1 откатим (чтобы сделать нумерацию с 0 как на сервере)
                        dialogFilter[filterKey] = angular.copy(this.dialogFilterObject[filterKey]);
                    } else {
                        // если фильтр включён (или это
                        if (this.dialogFilterObject[filterKey].enabled || filterKey === "date") {
                            if (filterKey === "date") {
                                // дату в формате строки
                                dialogFilter.fromDate = dateStringObject(this.dialogFilterObject[filterKey].startDate);
                                dialogFilter.toDate = dateStringObject(this.dialogFilterObject[filterKey].endDate);
                            } else if (filterKey === "userKeyWords") {
                                // берем значение, а так же keyword sources
                                dialogFilter[filterKey] = this.dialogFilterObject[filterKey].value;
                                dialogFilter["keywordsSources"] = this.dialogFilterObject[filterKey].types;
                            } else {
                                // у остальных параметров берём значение
                                dialogFilter[filterKey] = this.dialogFilterObject[filterKey].value;
                            }
                        }
                    }
                }
                dialogFilter["sortParams"] = this.dialogFilterObject["sortParams"];
                filter[key] = dialogFilter;
            } else {
                // у остальных параметров берём значение
                filter[key] = this.object[key];
            }
        }
        return filter;
    }

    /**
     * Положить текущий объект фильтр в state
     */
    pushFilterToState() {
        this.state.go('robot.trend_detection_wrapper.trend_detection.data', {filter: this.objAsJson()});
    }

    /**
     * Обработчик кнопки применить фильтр
     */
    applyFilter() {
        this.object.trendPage.pageNumber = 1;
        this.object.dialogPage.pageNumber = 1;
        this.displayDialogsFilter = false;
        // переходим в состояние
        this.state.go('robot.trend_detection_wrapper.trend_detection.data', {filter: this.objAsJson()}, {notify: true});
    }

    /**
     * Загрузчик информации
     */
    loadValues() {
        // Интервалы разбиения
        common.loadValues(this, this.trendUrl, "intervals");
        common.loadValues(this, this.trendUrl, "lengthCountRelations");
        common.loadValues(this, this.trendUrl, "estimations");
        common.loadValues(this, this.trendUrl, "orderTypes");
        common.loadValues(this, this.trendUrl, "buildTypes");
        common.loadValues(this, this.trendUrl, "phrasesWithChangedImportance");

        // Дефолтные значения
        common.loadValues(this, this.trendUrl, "defaultValues", (data) => {
            if (!this.object.loaded) {
                this.object.minSupport = data[0].minSupport;
                this.object.minCorrelation = data[0].minCorrelation;
                this.object.minCount = data[0].minCount;
                this.object.confidenceLevel = data[0].confidenceLevel;
                this.object.unlikelinessThreshold = data[0].unlikelinessThreshold;
                this.object.applyBasePeriod = data[0].applyBasePeriod;
                this.object.applyMaxPatterns = data[0].applyMaxPatterns;
                this.object.applyTrainData = data[0].applyTrainData;
                this.object.estimation = data[0].estimation;
                this.object.interval = data[0].interval;
                this.object.lengthCountRelation = data[0].lengthCountRelation;
                this.object.orderType = data[0].orderType;
                this.object.buildType = data[0].buildType;
                this.object.loaded = true;
            }
        });
        // load check states
        common.loadValues(this, this.baseUrl, "checkStates");
        common.loadValues(this, this.baseUrl, "correctionTypes");
        // load ml tasks
        common.loadValues(this, this.baseUrl, "mlTasks");
        // load experts
        common.loadValues(this, this.baseUrl, "experts");
    }

    changeImportance(node) {
        node.isImportant = !node.isImportant;
        this.restAngular.one("/account/expert/trenddetection").post("markAsImportant", {
            phrase: node.phrase,
            isImportant: node.isImportant
        });
        this.scope.$broadcast('change-importance-downwards', node);
    }

    removeImportantItem(node) {
        node.isImportant = !node.isImportant;
        this.restAngular.one("/account/expert/trenddetection/deleteImportant/", node.key.id)
            .get()
            .then((data) => {
                this.phrasesWithChangedImportance = this.phrasesWithChangedImportance.filter(item => item.key.id !== node.key.id);
                this.scope.$broadcast('change-importance-downwards', node);
            }, (data) => {
            })

    }
}

VaTrendDetectionFilterController.$inject = ["Restangular", "$scope", "$state", "$stateParams", "TitleService"];
