import EruditeLink from "../link-view/EruditeLink";
import {CheckpointableElement} from "../element-view/interface/CheckpointableElement";
import JointAdapter from "../JointAdapter";

/**
 * Логика работы с чекпоинтами
 */
export default class CheckpointController {

    constructor(private adapter: JointAdapter) {
    }

    /**
     * Переключить чекпоинт на блоке
     * @param viewModel представление блока
     */
    onNodeCheckpointClicked(viewModel: CheckpointableElement) {
        if (viewModel.isCheckpointable) {
            this.adapter.changesController.initBatchChange();
            viewModel.checkpoint = !viewModel.node.checkpoint;

            // обновление представления
            viewModel.adjustCheckpointIcon();
            this.adapter.changesController.storeBatchChange();
        }
    }

    /**
     * Переключить чекпоинт на ветке
     * @param viewModel представление ветки
     */
    onEdgeCheckpointClicked(viewModel: EruditeLink) {
        // показываем контролы
        this.adapter.changesController.disableLog();
        viewModel.toggleControlsVisibility(this.adapter.component.paper, true);
        this.adapter.changesController.enableLog()

        this.adapter.changesController.initBatchChange();
        viewModel.checkpoint = !viewModel.scriptEdge.checkpoint;

        // обновляем представление
        viewModel.hasCheckpoint = viewModel.scriptEdge.checkpoint;
        this.adapter.changesController.storeBatchChange();

        // скрываем контролы обратно контролы
        this.adapter.changesController.disableLog();
        viewModel.toggleControlsVisibility(this.adapter.component.paper, false);
        this.adapter.changesController.enableLog()
    }

}