export class ModelStatusInfoDto {
    type: MLComponentType;
    acceptedT: Date;
    acceptedInited: boolean;
    lastT: Date;
    lastStatus: ModelStatus;
    building: boolean;
    projectVersionId: string;
}

export class ModelStatusListAndAccessDto {
    modelList: ModelStatusInfoDto[];
    access: boolean;
    mlAccess: boolean;
}

export enum MLComponentTypeEnum {
    TAG = "TAG",
    STEP = "STEP",
    EXTRACT_AFTER_Q_ATTR = "EXTRACT_AFTER_Q_ATTR",
    EXTRACT_PLAIN_ATTR = "EXTRACT_PLAIN_ATTR"
}

export class MLComponentType {
    name: MLComponentTypeEnum;
    shortName: string;
    title: string;
    desc: string;
}

export enum ModelStatusEnum {
    IN_PROGRESS = "IN_PROGRESS",
    PHASE_BUILDING = "PHASE_BUILDING",
    FINISH = "FINISH",
    ACCEPTED = "ACCEPTED",
    FAILED = "FAILED",
    EMPTY = "EMPTY"
}

export class ModelStatus {
    name: ModelStatusEnum;
    desc: string;
}