import {Component, OnInit} from "@angular/core";
import {StateService} from "@uirouter/core";
import {TextDataTypeGroupEnum} from "../../text-data/text-data.model";
import {ExtractorService} from "../../extractor/extractor.service";
import {Valued} from "../../../../../data/va/Valued";
import {Extractor} from "../../../../../data/va/Extractor";


@Component({
    selector: 'settings-master',
    template: require('./settings-master.component.html')
})
export class SettingsMasterComponent implements OnInit {

    public isVoice: boolean = null;

    constructor(private stateService: StateService, private extractorService: ExtractorService) {
    }

    async ngOnInit(): Promise<void> {
        await this.loadChannel();
        if (this.stateService.current.name === "robot.settings") {
            // если находимся на странице мастера, то надо переити к конкретнои настроике
            // Если выбран тип канала Голос, показываем блок Настройки - Голосовые настройки.
            // Если выбран тип канала только Текст, Голосовые настройки не показываем. Сразу открываем Настройки обработки диалога"
            let groupName = this.isVoice ? TextDataTypeGroupEnum.VOICE : TextDataTypeGroupEnum.DIALOG_HANDLE;
            this.stateService.go('robot.settings.edit', {groupName: groupName}, {notify: false});
        }
    }

    async loadChannel() {
        const extractor: Extractor = await this.extractorService.getChannel();
        this.isVoice = extractor.valueOptions.some(option => option.apiKey === Valued.VOICE_API_KEY || option.apiKey === Valued.ASSISTANT_VOICE_API_KEY);
    }

}