import {StateService} from "@uirouter/core";
import {StepLabelTypeName, VaScriptNode} from "../../../../data/va/Script";
import JointAdapter from "../JointAdapter";

/**
 * Контроллер для перехода на страницу с сущностью
 */
export default class EntityNavigationController {

    constructor(private stateService: StateService, private readonly adapter: JointAdapter) {
    }

    /**
     * Перейти от блока элемента на страницу с сущностью
     * @param viewModel
     */
    navigateTo(viewModel: any) {
        if (!viewModel.isNavigable()) {
            return;
        }
        const node: VaScriptNode = viewModel.node;

        const prVId = node.entity.key.projectVersionId;
        const entityId = node.entity.key.id;

        let href = '';

        switch (node.stepLabel.type.name) {
            case StepLabelTypeName.ATTRIBUTE_REQUEST:
                href = this.stateService.href('robot.attributes.edit', {attrId: entityId, projectVersionId: prVId, mode: 'SHOW'});
                break;
            case StepLabelTypeName.ANSWER_RESPONSE:
                href = this.stateService.href('robot.answer.edit', {answerId: entityId, projectVersionId: prVId});
                break;
            case StepLabelTypeName.TO_TAG:
                href = this.stateService.href('robot.tags.edit', this.addCaseViewParams({
                    tagId: entityId,
                    projectVersionId: prVId
                }));
                break;
            case StepLabelTypeName.PROCEDURE:
                href = this.stateService.href('robot.script_procedure_edit', this.addCaseViewParams({
                    procedureId: entityId,
                    type: 'procedure',
                    projectVersionId: prVId
                }));
                break;
            case StepLabelTypeName.TO_SUPPORT:
                href = this.stateService.href('robot.sma.edit', {smaId: entityId, projectVersionId: prVId});
                break;
        }
        if (href) {
            window.open(href, '_blank');
        }
    }

    /**
     * Перейти от блока элемента на страницу с сущностью
     * @param scenarioId id сценария
     */
    navigateToScenario(scenarioId: number) {
        if (!scenarioId) {
            return;
        }

        let href  = this.stateService.href('robot.script_edit', this.addCaseViewParams({
                    scriptId: scenarioId,
                    type: 'scenario'
                }));
        if (href) {
            window.open(href, '_blank');
        }
    }

    /**
     * Если есть параметры для просмотра кейсов / диагностики доступности, пробрасываем их
     */
    addCaseViewParams(params: any) {
        if (this.adapter.script.caseView) {
            params.caseScenarioId = this.adapter.script.caseView.scenarioId;
            if (this.adapter.component.caseId) {
                params.caseId = this.adapter.component.caseId;
            }
        }
        return params;
    }
}