import {Component, EventEmitter} from "@angular/core";
import {BaseMasterComponent} from "../../base/base-master.component";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Extractor, ExtractorSuppliedTypeEnum, ValueType} from "../../../../../data/va/Extractor";
import {ExtractorService} from "../extractor.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'extractor-master',
    template: require('./extractor-master.component.html'),
})
export class ExtractorMasterComponent extends BaseMasterComponent<Extractor, CompositeKey<number>> {

    /**
     * Фильтр сверху коллекции
     */
    filter: ExtractorFilter = new ExtractorFilter();
    valueTypes: ValueType[] = [];
    clearSearchStringChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(titleService: Title,
                stateService: StateService,
                protected dataService: ExtractorService,
                notificationService: NotificationService,
                httpClient: HttpClient) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(SortField.date(`d`), new SortField(`Название`, `name`), new SortField(`Эксперт`, `expert.fullName`));
        this.getAccess();
    }

    async ngOnInit() {
        await super.ngOnInit();
        this.valueTypes = await this.dataService.getValueTypes();
    }

    async loadList(): Promise<void> {
        this.setLoading(true);
        await this.dataService.findAll().then(extractors => {
            this.objects = extractors.filter(extractor => extractor.suppliedType?.name !== ExtractorSuppliedTypeEnum.CHANNEL);
        });
        this.setLoading(false);
    }

    filterChange(searchString: string, item: Extractor, filter: ExtractorFilter): boolean {
        const typeMatch = !filter.type || item.valueType.name == filter.type.name;
        const searchMatch = !searchString || item?.name?.toLowerCase().indexOf(searchString) >= 0;

        return typeMatch && searchMatch;
    }

    public isUsingFilter(filter: ExtractorFilter): boolean {
        return filter.type != null;
    }

    updateFilter(value: any, key: string) {
        this.filter[key] = value;
        this.filter = Object.assign({}, this.filter);
    }

    /**
     * Сбросить текущие фильтры в дефолтное положение
     */
    clearFilters() {
        this.clearSearchStringChange.next();
        this.filter = new ExtractorFilter();
    }
}

export class ExtractorFilter {
    type: ValueType = null;

    constructor() {
    }
}
