import {Component, Input} from "@angular/core";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";


@Component({
    selector: 'license-table',
    template: require('./table.component.html'),
    styles: [require('./table.component.less')]
})

export class TableComponent {
    @Input() rows: any;
    @Input() headers: string[];

    constructor(protected stateService: StateService,
                protected titleService: Title) {
    }
}
