import common from "../../../../common";
import urls from "../../../../urls";
import * as mf from "./VaModelStatusFunctions";

export default class VaModelListController {

    constructor(Restangular, scope, WebSocketService, $stateParams, state, TitleService) {
        const that = this;

        that.baseUrl = urls.va.ml;
        that.restAngular = Restangular;
        that.webSocketService = WebSocketService;
        that.state = state;
        that.$stateParams = $stateParams;

        // id просмотренной модели
        that.modelId = $stateParams['modelId'];
        that.typeShortName = $stateParams['type'];

        if (!that.modelId) {
            TitleService.setTitle("Новая модель")
        } else {
            TitleService.setTitle(that.typeShortName.toUpperCase() + " #" + that.modelId);
        }


        // форма
        that.form = {
            isLoading: false,
            // не нужно выводить успех операции
            hideSuccess: true,
        };

        that.restAngular.one(that.baseUrl + "/access").get()
            .then(function success(data) {
                that.access = data.access;
            }, function error(data) {
            });

        // подписываемся на событие ожидания создания диалога
        that.subscriptionId = that.webSocketService.subscribeOnEvents({
            eventType: "VA_MODEL_CHANGE",
            fn: function (event) {
                if (event.details == that.modelId) {
                    that.loadModel();
                }
            }
        });

        // в деструкторе не забываем отписаться
        scope.$on("$destroy", function handler() {
            that.webSocketService.removeListener(that.subscriptionId);
        });

        // загружаем модель
        that.loadModel();

        // загружаем варианты моделей - кандидатов в "текущая модель зависит от", если нужно
        if (that.getDependsOnTypes() != null) {
            that.dependsCandidate = {};
            const types = that.getDependsOnTypes();
            for (let i = 0; i < types.length; i++) {
                const type = types[i];
                common.formOperationWithLoading(
                    that.restAngular.one(that.baseUrl + "/" + that.typeShortName + "/dependsCandidate", type)
                        .get(),
                    that.form, function (data) {
                        that.dependsCandidate[type] = data;
                    }
                );
            }
        }
    }

    /**
     * @returns string[] модели для выбора модели, от которой будет зависеть данная модель
     */
    getDependsOnTypes() {
        const that = this;

        switch (that.typeShortName) {
            default:
                return null;
        }
    }

    /**
     * @returns {boolean} true, если для модели установлены все необходимые зависимости
     */
    checkDependency() {
        const that = this;

        const types = that.getDependsOnTypes();
        if (types != null) {
            for (let i = 0; i < types.length; i++) {
                if (that.form.model.dependsOnModelIdMap == null || that.form.model.dependsOnModelIdMap[types[i]] == null) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Выставление модели, от которой будет зависеть текущая модель
     */
    setDependsOn() {
        const that = this;

        // подготовим map-у type(str)->modelId
        const depends = {};
        angular.forEach(that.form.model.dependsOnModelMap, function (value, key) {
            depends[key] = value.id;
        });

        // задаём зависимость для данной модели
        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName + "/" + that.modelId + "/")
                .post("setDependsOn", depends),
            that.form, function (data) {
                that.form.model = data;
            }
        );
    }


    /**
     * Загрузка модели
     */
    loadModel() {
        const that = this;

        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName, that.modelId)
                .get(), that.form, (data) => {
                that.form.model = data;
            }, (data) => {
                // если произошла ошибка при загрузке модели, то редирект на тип компоненты
                that.state.go("robot.model_types.list", {"modelType": that.typeShortName.toUpperCase()});
            });
    }

    /**
     * Принудительная инициализация модели
     */
    initModel() {
        const that = this;

        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName + "/" + that.modelId, "init")
                .get(), that.form, function () {
                that.form.model.init = true;
            }
        );
    }

    /**
     * Принудительная выгрузка модели
     */
    unloadModel() {
        const that = this;

        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName + "/" + that.modelId, "unload")
                .get(), that.form, function () {
                that.form.model.init = false;
            }
        );
    }


    /**
     * Построение этапа
     * @param phase этап
     */
    buildPhase(phase) {
        const that = this;

        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName, that.modelId)
                .post("buildPhase", phase),
            that.form, function (data) {
                that.form.model = data;
            }
        );
    }

    /**
     * Сохранение модели (простых её параметров, таких как ИМЯ)
     */
    saveModel() {
        const that = this;

        // обновление модели
        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName)
                .patch(that.form.model),
            that.form, function (data) {
                that.form.model = data;
            }
        );
    }

    /**
     * Создание новой модели на основе выбранной
     */
    copyModel() {
        const that = this;

        // обновление модели
        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName + "/" + that.modelId, "copyModel")
                .get(),
            that.form, function (data) {
                // переходим к просмотру созданной модели
                that.state.go('robot.model', {
                    modelId: data,
                    type: that.typeShortName
                });
            }
        );
    }

    /**
     * Сделать модель рабочей
     */
    acceptModel() {
        const that = this;

        // сохранение модели как рабочей
        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName + "/" + that.modelId, "acceptModel")
                .get(),
            that.form, function (data) {
                that.form.model = data;
            }
        );
    }

    /**
     * Полное переобучение модели
     */
    rebuildModel() {
        const that = this;

        common.formOperationWithLoading(
            that.restAngular.one(that.baseUrl + "/" + that.typeShortName, that.modelId)
                .post("rebuild", that.form.model),
            that.form, function (data) {
                that.form.model = data;
            }
        );
    }

    /**
     * Получение иконки для отображения статуса модели
     */
    getModelStatusIcon(status) {
        return mf.getModelIcon(status);
    }

    /**
     * Получение цвета, которым отображается текущий статус модели
     */
    getModelStatusColor(status) {
        return mf.getModelColor(status);
    }

    /**
     * Получение ~ времени обучения модели
     */
    getModelBuildTime() {
        const that = this;

        let time = 0;
        // считаем время построения каждого этапа
        for (let pt in that.form.model.phasesMap) {
            if (that.form.model.phasesMap.hasOwnProperty(pt)) {
                if (that.form.model.phasesMap[pt].rebuildTimeMs != null) {
                    time += +that.form.model.phasesMap[pt].rebuildTimeMs;
                }
            }
        }
        if (time != 0) {
            // показываем его в удобном виде
            time = moment.duration(time);
            return "~ " + time.minutes() + " min " + time.seconds() + " sec";
        }
    }
}

VaModelListController.$inject = ["Restangular", "$scope", "WebSocketService", "$stateParams", "$state", "TitleService"];
