import {Component, Inject, OnInit} from "@angular/core";
import {Form} from "../../../base/base-details.component";
import {TreeOptions} from "../../../model/tb-params.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TbTagCreation} from "../../../model/tb-dialog.model";
import {VaTag} from "../../../../../../data/va/Tag";
import {TagService} from "../../../tag/tag.service";

@Component({
    selector: 'tag-dialog',
    template: require('./tag-dialog.component.html'),
    styles: [require('./tag-dialog.component.less')],
})
export class TagDialogComponent implements OnInit {

    tagTreeOptions: TreeOptions;
    form: Form<VaTag> = new Form<VaTag>();


    constructor(public dialogRef: MatDialogRef<TagDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: TbTagCreation, private dataService: TagService) {
    }

    generateNewClusterTag(): VaTag {
        let tag = new VaTag();
        tag.text = "";
        tag.parentId = this.data.parent.key.id;
        tag.useInTrain = true;
        tag.access = true;
        return tag;
    }

    ngOnInit(): void {
        this.form.object = this.generateNewClusterTag();
    }

    async save() {
        await this.dataService.save(this.form.object);
        this.dialogRef.close();
    }
}