import {ValueTypeEnum} from "../../ts/workplace/data/va/Extractor";
import {escapeHtml} from "../../ts/util/Utils";

var common = {};

common.rangeDatepickerOpts = {
    locale: {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Применить",
        "cancelLabel": "Отмена",
        "fromLabel": "С",
        "toLabel": "По",
        "customRangeLabel": "Произвольный",
        "weekLabel": "W",
        "daysOfWeek": [
            "ВС",
            "ПН",
            "ВТ",
            "СР",
            "ЧТ",
            "ПТ",
            "СБ"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    }
    ,
    ranges: {
        'Прошлый месяц': [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
        'Этот месяц': [moment().startOf('month'), moment()],
        'Прошлая неделя': [moment().subtract(7, "days").startOf("week"), moment().subtract(7, "days").endOf("week")],
        'Эта неделя': [moment().startOf('week'), moment()],
        'Вчера': [moment().subtract(1, "days").startOf("day"), moment().subtract(1, "days").endOf("day")],
        'Сегодня': [moment().startOf("day"), moment()]
    }
};

common.singleDatePickerOpts = {
    locale: {
        "format": "DD.MM.YYYY",
        "daysOfWeek": [
            "ВС",
            "ПН",
            "ВТ",
            "СР",
            "ЧТ",
            "ПТ",
            "СБ"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
    singleDatePicker: true
};

common.singleDateTimePickerOpts = {
    locale: {
        "format": "DD.MM.YYYY HH:mm",
        "daysOfWeek": [
            "ВС",
            "ПН",
            "ВТ",
            "СР",
            "ЧТ",
            "ПТ",
            "СБ"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Мая",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
    singleDatePicker: true,
    timePicker: true,
    timePicker24Hour: true
};

/**
 * Загрузка значений по url
 * @param that контроллер с restAngular-ом
 * @param baseUrl урл, по которому нужно отгружать значения: baseUrl/valueName
 * @param valueName имя значений, участвует в формирование url-а, а так же в переменную с таким именем в контроллер that сохраняется результат
 * @param callback callback, который вызывает после успешного сохранения values
 * @param ignoreValueNameInUrl если true, то необходимо проигнорировать valueName при формировании url-а
 * необходимо для получения каких-то списков из "базовых" контроллеров (просто all методов)
 */
common.loadValues = function (that, baseUrl, valueName, callback = null, ignoreValueNameInUrl = false) {
    var rest = that.restAngular.all(baseUrl);
    if (!ignoreValueNameInUrl) {
        rest = rest.all(valueName);
    }
    return rest.getList()
        .then(function success(data) {
            that[valueName] = that.restAngular.stripRestangular(data);
            if (callback != null) {
                callback(that.restAngular.stripRestangular(data));
            }
        }, function error(data) {

        });
};

common.loadOneValue = function (that, baseUrl, valueName) {
    return that.restAngular.one(baseUrl, valueName)
        .get()
        .then(function success(data) {
            that[valueName] = data;
        }, function error(data) {
        });
};

common.getStateParams = function ($stateParams, optionalParams) {
    if ($stateParams) {
        const params = {};
        if (optionalParams) {
            optionalParams
                .filter(key => $stateParams[key])
                .forEach(key => params[key] = $stateParams[key]);
        }
        return params;
    }
};

common.loadLinkedValues = function (that, baseUrl, objId, valueName, callback) {
    // alert("ready on " + baseUrl + " " + valueName);
    that.restAngular.one(baseUrl, objId).getList(valueName)
        .then(function success(data) {
            // alert("success on " + baseUrl + " " + valueName);
            that[valueName] = data;
            if (callback != null) {
                callback(data);
            }
        }, function error(data) {
            // alert("error on " + baseUrl + " " + valueName);
        });
};

common.handleError = function (data) {
    var errors = data.errors;

    var result = {};

    // пришла 503, 502, 501, 500........ ошибка
    if (!errors) {
        if (data.status === 403) {
            result["#main"] = "Доступ запрещен";
        } else if (data.status === 'IN_PROCESS' || data.status === 409) {
            result["#main"] = "Уже в процессе";
        } else if (data.status === 'NOT_ACCEPTABLE' || data.status === 406) {
            result["#main"] = "Уже есть в системе";
        } else if (data.status === 'EXPECTATION_FAILED' || data.status === 417) {
            result["#main"] = "Недопустимый формат файла";
        } else {
            result["#main"] = "Внутренняя ошибка системы, повторите запрос позже";
        }
        return result;
    }
    // список ошибок от сервера
    for (var i = 0; i < errors.length; i++) {
        var error = errors[i];
        var fieldKey = error.field;
        var msg = error.message;
        if (!fieldKey) {
            // ошибка без привязки к полю
            result["#main"] = msg;
        } else {
            result[fieldKey] = msg;
        }
    }
    return result;
};

/**
 * Получение значения атрибута в человекочитаемом виде или в виде для отправки на сервер
 * @param value значение атрибута
 * @param extractor экстрактор
 * @param humanView человекочитаемый вид?
 */
common.getAttributeValue = function (value, extractor, humanView) {
    // определяем в каком поле искать человеческого вида значение атрибута
    if (extractor.valueType.name === ValueTypeEnum.ENUM) {
        if (humanView) {
            return value.title;
        } else {
            return value.key.id;
        }
    }
    return value;
};

common.extractingValueType = {
    ATTRIBUTE: "ATTRIBUTE",
    CONFIRMATION: "CONFIRMATION"
};

common.typeOfExtractableValue = function (info) {
    if (info.scope != null) {
        return common.extractingValueType.ATTRIBUTE;
    } else if (info.name === common.extractingValueType.CONFIRMATION) {
        return common.extractingValueType.CONFIRMATION;
    }
};

common.formatExtractableValues = function (extractableValues) {
    let text = extractableValues.info.name;
    if (extractableValues.values.length > 0) {
        text += " [";
        for (var i = 0; i < extractableValues.values.length; i++) {
            if (common.typeOfExtractableValue(extractableValues.info) === common.extractingValueType.ATTRIBUTE) {
                text += common.getAttributeValue(extractableValues.values[i], extractableValues.info.extractor, true);
            }

            if (i !== extractableValues.values.length - 1) {
                text += ", ";
            }
        }
        text += "]";
        return text;
    }
    if (extractableValues.value == null) {
        return `<b>АТРИБУТ | ${text}</b>`;
    }
    
    const valueTitle = extractableValues.value.title != null ? extractableValues.value.title : extractableValues.value.toString();
    const entityName = extractableValues.info ? extractableValues.info.name + ":" : "";
    return `<b>АТРИБУТ | ${entityName}</b> [${escapeHtml(valueTitle)}]`;
};

common.formStartLoading = function (form) {
    // если это первый запрос - то скидываем список ошибок в пусто
    if (form.loadingCounter == null || form.loadingCounter == 0) {
        form.errors = {};
        form.success = null;
    }
    // увеличиваем счётчик количества операций загрузки
    form.loadingCounter = (form.loadingCounter != null ? form.loadingCounter : 0) + 1;
    form.isLoading = true;
};

common.formFinishLoading = function (form) {
    // уменьшаем счётчик количества операций загрузки
    form.loadingCounter = (form.loadingCounter != null ? form.loadingCounter : 0) - 1;
    if (form.loadingCounter == 0) {
        // больше загрузок нет
        form.isLoading = false;
    }
};

common.formOnSuccess = function (form) {
    // если статус операции не задан - значит это первая завершившаяся
    // проставим успешный статус
    // в другом случае просто не меняем статус (form.success && true always = true)
    if (form.success == null) {
        form.success = true;
    }
    common.formFinishLoading(form);
};

common.formOnError = function (form, data) {
    // операция завершилась провалом - проставляем статус операции
    form.success = false;
    common.formFinishLoading(form);
    //
    $.extend(form.errors, common.handleError(data));
};

/**
 * Выполнение операции с rest angular
 * @param action действией
 * @param form форма
 * @param successCallback success callback
 * @param errorCallback error callback
 */
common.formOperationWithLoading = function (action, form, successCallback, errorCallback) {
    common.formStartLoading(form);
    action
        .then(function success(data) {
            successCallback(data);
            // успех
            common.formOnSuccess(form);
        }, function error(data) {
            // неудача
            common.formOnError(form, data);
            if (errorCallback != null) {
                errorCallback();
            }
        });
};

common.removeItemFromArray = function (array, item, equalsFunc) {
    for (var i = 0; i < array.length; i++) {
        var equal;
        if (equalsFunc != null) {
            equal = equalsFunc(array[i], item);
        } else {
            equal = array[i] == item;
        }
        if (equal) {
            array.splice(i, 1);
            break;
        }
    }
};

common.removeItemsFromArray = function (removeFromArray, removableItems, equalsFunc) {
    for (var i = 0; i < removableItems.length; i++) {
        common.removeItemFromArray(removeFromArray, removableItems[i], equalsFunc);
    }
};
/**
 * Функция получения доступа (по url: .../access) и сохранение его в поле .access
 */
common.getAccess = function (restAngular, baseUrl, ctrl) {
    restAngular.one(baseUrl).one("access").get()
        .then(data => {
            ctrl.access = data.access;
        }, data => {
            ctrl.access = false;
        });
};

/**
 * Переход на родительский стейт
 */
common.goToParentState = function ($state) {
    $state.go($state.$current.parent, {});
};

common.getShowAllOptionId = () => {
    return 'showall';
};

common.removeShowAllOption = (options) => {
    if (!options || options.length === 0) {
        return;
    }

    // Ищем индекс опции "Показать всё"
    let showAllOption = options.find(item => item.key && item.key.id === common.getShowAllOptionId());
    if (!showAllOption) {
        return;
    }
    let index = options.indexOf(showAllOption);
    // Удаляем её из выбранных значений ручками
    options.splice(index, 1);
};


module.exports = common;