import {Component} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";
import {ScenarioService} from "./scenario.service";
import {CompositeKey, SortField} from "../../../../data/va/Common";
import {VAScenario, VAScript} from "../../../../data/va/Script";
import {BaseMasterComponent} from "../base/base-master.component";
import {FileSaverService} from "ngx-filesaver";
import {extractFileName, readErrorDescription} from "../../../../../util/Utils";
import {MatDialog} from "@angular/material/dialog";
import {ScriptImportDialogComponent, ScriptImportDialogData} from "../script-import/script-import-dialog.component";
import {ImportingComponent} from "../script-import/script-import-util";
import StatisticsService from "../../../../services/StatisticsService";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {ServerCollectionBridge} from "../../common/collection/server-collection.bridge";

@Component({
    selector: 'scenarios',
    template: require('./scenario-master.component.html')
})
export class ScenarioMasterComponent extends BaseMasterComponent<VAScenario, CompositeKey<number>> implements ImportingComponent {

    constructor(http: HttpClient,
                stateService: StateService,
                titleService: Title,
                public dataService: ScenarioService,
                private fileSaver: FileSaverService,
                private dialog: MatDialog,
                notificationService: NotificationService,
                private statisticsService: StatisticsService
    ) {
        super(titleService, stateService, dataService, http, notificationService);
        this.sortFields.push(SortField.date(`d`), new SortField(`Название`, `name`));
        this.serverCollectionBridge = new ServerCollectionBridge({
            orderBy: "d",
        }, null);
        this.getAccess();
    }

    /**
     * Экспортировать сценарий
     */
    exportScript(item: VAScript): void {
        if (item.hasToTag) {
            this.showError('Ошибка экспорта: сценарий содержит переходы в другие тематики. Удалите их и повторите экспорт.');
            return;
        }
        this.httpClient.post(`${this.dataService.baseUrl}/${item.key.id}/export`, {}, {
            params: {},
            observe: 'response',
            responseType: 'blob'
        }).subscribe((data) => {
            const fileName = extractFileName(data.headers.get('Content-Disposition'));
            this.fileSaver.save(data.body, fileName)

            // статистика
            this.statisticsService.registerOpenEvent(this.stateService.current, this.stateService.params, {action: 'script_export', type: 'scenario', scriptId: item.key.id});
        }, (data) => {
            readErrorDescription(data, error => this.showError(error))
        });

    }

    /**
     * Открыть диалог загрузки
     */
    openUpload() {
        const data: ScriptImportDialogData = {
            baseUrl: this.dataService.baseUrl,
            dialogTitle: 'Импорт сценария',
            importer: this
        };
        this.dialog.open(ScriptImportDialogComponent, {
            width: '600px',
            data: data
        });
    }

    /**
     * После успешного импорта переходим на импортированный сценарий
     */
    afterSuccessfulImport(mainId: string) {
        this.stateService.go('robot.script_edit',
            {
                scriptId: mainId,
                type: 'scenario',
                projectVersionId: this.stateService.params['projectVersionId']
            });
    }
}
