/**
 * Форма единиц измерения в зависимости от числа
 */
export enum UnitCase {
    ONE= 'ONE',
    TWO_TO_FOUR = 'TWO_TO_FOUR',
    FIVE_AND_MORE = 'FIVE_AND_MORE'
}

/**
 * Определить форму единиц измерения по числу
 */
export function getUnitCase(num: number): UnitCase {
    const hundredRemainder = Math.abs(num) % 100;
    if (hundredRemainder > 4 && hundredRemainder < 21) {
        return UnitCase.FIVE_AND_MORE;
    }
    switch (hundredRemainder % 10) {
        case 1:
            return UnitCase.ONE;
        case 2:
        case 3:
        case 4:
            return UnitCase.TWO_TO_FOUR;
        default:
    }       return UnitCase.FIVE_AND_MORE;
}