import {CompositeKey} from "./Common";
import {VaTag} from "./Tag";
import {VaDialog} from "../../io/components/va/model/tb-dialog.model";

/**
 * Описание: {@link ru.naumen.chat.va.data.entity.questions.VAMessage}
 */

export class VaMessageIf {
    key: CompositeKey<number>;
    tagId: number;
    text: string;
    dialogId: number;
    expertId?: number;
    d?: Date;
    tbReplyId?: number;
    standard?: boolean;
    expert?: any;
    selected: boolean;
}

export class VaMessage extends VaMessageIf {
    userReplyId?: number;
    tagAttribution?: TagAttribution;
    va: boolean;
    useForAutocomplete: boolean;
    tbDialogId?: number;
    correctionId?: number;
    tag?: VaTag;
    textTokens?: string[];
    highlightedText?: string;
}

export enum TagAttribution {
    USER_CONFIRMED = "USER_CONFIRMED",
    EXPERT_CHOSEN = "EXPERT_CHOSEN",
    AUTOMATICALLY = "AUTOMATICALLY"
}

export class VaTBMessage extends VaMessageIf{
    clusterId: number;
    linkedMessageId: number;
    t: Date;
    vector: number[];
    keyPhrases: string;
    allRepliesTexts: string;
}

export class VaTBMessageCluster extends VaTBMessage{
    messageCount: number;
    state: any;
    clusterEditor: any;
    dialog: VaDialog;
    messageList: any[];
    justSentToTag: boolean;
}