/**
 * Created by semyon on 28.10.16.
 */

export default class NotificationManager {

    constructor() {
        this.isActive = true;
        let that = this;

        var hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") {
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        if (typeof document.addEventListener === "undefined" || typeof document[hidden] === "undefined") {
            window.onfocus = function () {
                that.isActive = true;
            };

            window.onblur = function () {
                that.isActive = false;
            };
        } else {
            this.isActive = !document[hidden];

            // Handle page visibility change
            document.addEventListener(visibilityChange, () => that.isActive = !document[hidden], false);
        }

    }

    showNotification(data, showWhenTabActive) {
        if (this.isActive && !showWhenTabActive) {
            return;
        }

        let icon = "";
        switch (data.type) {
            case "CONVERSATION_MESSAGE":
            default:
                icon = require('../../../img/new-message.jpeg');
                break;
        }

        if (navigator.serviceWorker && navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage) {
            navigator.serviceWorker.controller.postMessage({
                title: data.title,
                text: data.text,
                type: "NOTIFICATION",
                icon: icon,
                href: data.href
            });
        }
    }

}