export enum VATaggedItemType {
    ANSWER = "Ответы",
    ATTRIBUTE = "Атрибуты",
    DKB = "База знаний",
    PROCEDURE = "Процедуры"
}

export enum ItemTypeNumber {
    ANSWER ,
    ATTRIBUTE ,
    DKB ,
    PROCEDURE
}

export class VAProjectVersionIdEntityKey {
    id: number;
    projectVersionId: string;
}

export class VATaggedItem {
    key: VAProjectVersionIdEntityKey;
    itemId: number;
    tagId: number;
    expertId: number;
    type: VATaggedItemType;
    title: string;
}