import {Component} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import {Title} from "@angular/platform-browser";
import {ProcedureService} from "./procedure.service";
import {CompositeKey, SortField} from "../../../../data/va/Common";
import {VAProcedure, VAScript} from "../../../../data/va/Script";
import {BaseMasterComponent} from "../base/base-master.component";
import {extractFileName, readErrorDescription} from "../../../../../util/Utils";
import {ScriptImportDialogComponent, ScriptImportDialogData} from "../script-import/script-import-dialog.component";
import {FileSaverService} from "ngx-filesaver";
import {MatDialog} from "@angular/material/dialog";
import {ImportingComponent} from "../script-import/script-import-util";
import StatisticsService from "../../../../services/StatisticsService";
import {NotificationService} from "../../common/snackbar/notification/notification.service";
import {ServerCollectionBridge} from "../../common/collection/server-collection.bridge";
import {EntityUsageService} from "../../entity-usage/entity-usage.service";

@Component({
    selector: 'procedures',
    template: require('./procedure-master.component.html')
})
export class ProcedureMasterComponent extends BaseMasterComponent<VAProcedure, CompositeKey<number>> implements ImportingComponent {

    constructor(http: HttpClient,
                stateService: StateService,
                titleService: Title,
                public dataService: ProcedureService,
                public fileSaver: FileSaverService,
                public dialog: MatDialog,
                notificationService: NotificationService,
                private statisticsService: StatisticsService,
                public entityUsagesService: EntityUsageService) {
        super(titleService, stateService, dataService, http, notificationService);
        this.sortFields.push(SortField.date(`d`), new SortField(`Название`, `name`));
        this.serverCollectionBridge = new ServerCollectionBridge({
            orderBy: "d",
            orderAsc: false
        }, null);
    }

    async ngOnInit() {
        await super.ngOnInit();

        await this.getAccess();
    }

    /**
     * Экспортировать сценарий
     */
    exportScript(item: VAScript): void {
        this.httpClient.post(`${this.dataService.baseUrl}/${item.key.id}/export`, {}, {
            params: {},
            observe: 'response',
            responseType: 'blob'
        }).subscribe((data) => {
            const fileName = extractFileName(data.headers.get('Content-Disposition'));
            this.fileSaver.save(data.body, fileName);

            // статистика
            this.statisticsService.registerOpenEvent(this.stateService.current, this.stateService.params, {
                action: 'script_export',
                type: 'procedure',
                scriptId: item.key.id
            });
        }, (data) => {
            readErrorDescription(data, error => this.showError(error))
        })
    }

    /**
     * Открыть диалог загрузки
     */
    openUpload() {
        const data: ScriptImportDialogData = {
            baseUrl: this.dataService.baseUrl,
            dialogTitle: 'Импорт процедуры',
            importer: this
        };

        this.dialog.open(ScriptImportDialogComponent, {
            width: '600px',
            data: data
        });
    }

    /**
     * После успешного импорта переходим на импортированную процедуру
     */
    afterSuccessfulImport(mainId: string) {
        this.stateService.go('robot.script_procedure_edit',
            {
                procedureId: mainId,
                type: 'procedure',
                projectVersionId: this.stateService.params['projectVersionId']
            });
    }
}