/**
 * Created by semyon on 21.09.16.
 */

export default function LinkyWithHtml($filter) {

    // NB! Do not touch!
    const INFO = CLIENT_INFO_KEY;

    return function (value) {
        if (!value) {
            return value;
        }

        const linked = $filter('linky')(value, '_blank', {rel: 'nofollow'});
        let replaced = linked.replace(/\&gt;/g, '>').replace(/\&lt;/g, '<');

        // убираем img
        replaced = replaced.replace(/<img.*?>/, '');
        // убираем div
        replaced = replaced.replace(/<div[\s\S]*?\/div>/, '');
        // убираем span
        replaced = replaced.replace(/<span[\s\S]*?\/span>/, '');

        return replaced;
    };
}

LinkyWithHtml.$inject = ["$filter"];