import {Component, EventEmitter, Input, Output} from "@angular/core";
import {
    NgbCalendar,
    NgbDate,
    NgbDateParserFormatter,
    NgbDatepickerI18n,
    NgbDatepickerNavigateEvent
} from "@ng-bootstrap/ng-bootstrap";
import {
    CustomDatepickerI18n,
    CustomForwardSlashYMDDateParserFormatter,
    I18n
} from "./erudite-ngb-datepicker-customizer";

@Component({
    selector: 'erudite-ngb-range-datepicker',
    template: require('./erudite-ngb-range-datepicker.component.html'),
    styles: [require('./erudite-ngb-range-datepicker.component.less')],
    providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: NgbDateParserFormatter, useClass: CustomForwardSlashYMDDateParserFormatter}]
})
export class EruditeNgbRangeDatepickerComponent {

    hoveredDate: NgbDate | null = null;

    @Input()
    object: any;

    @Input()
    disabled: boolean;

    @Input()
    fromDateFieldName: string = "fromDate";

    @Input()
    toDateFieldName: string = "toDate";

    @Input()
    showFromDateField: boolean = true;

    @Input()
    showToDateField: boolean = true;

    @Input()
    showIcon: boolean = true;

    private navigateMonth;

    /**
     * Передача сигнала об изменении дат наружу
     */
    @Output()
    private modelChange: EventEmitter<void> = new EventEmitter();

    // клик на второи даты
    toDateClick: boolean = false;
    // ожидаем изменение второи даты
    private toDateChange: boolean = false;

    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    }

    clear(): void {
        this.object[this.fromDateFieldName] = null;
        this.object[this.toDateFieldName] = null;
    }

    private getDate(fieldName: string): NgbDate | null {
        return this.object[fieldName];
    }

    private setDate(fieldName: string, date: NgbDate | null): void {
        this.object[fieldName] = date;
    }

    getFromDate(): NgbDate | null {
        return this.getDate(this.fromDateFieldName);
    }

    setFromDate(date: NgbDate | null): void {
        this.setDate(this.fromDateFieldName, date);
    }

    getToDate(): NgbDate | null {
        return this.getDate(this.toDateFieldName);
    }

    setToDate(date: NgbDate | null): void {
        this.setDate(this.toDateFieldName, date);
    }

    onDateSelection(date: NgbDate) {
        if (!this.getFromDate() && !this.getToDate()) {
            // если ничего не заполнено, проставляем дат from
            this.setFromDate(date);
        } else if (this.getFromDate() && !this.getToDate() && this.isValidToDate(date)) {
            // если не заполнена дата to, то провверяем ее на корректность, должна быть после from и проставляем
            this.setToDate(date);
        } else {
            if (this.toDateClick || this.toDateChange) {
                // если хотели поменят датy после
                if (this.isValidToDate(date)) {
                    // вот были даты 20.05 - 25.05 , кликаешь на вторую дату, выбираешь 22.05, она ставится во вторую, первая остается
                    this.setToDate(date);
                } else {
                    this.setFromDate(date);
                }
                this.toDateChange = false;
            } else {
                this.setFromDate(date);
                if (date && date.after(this.getToDate())) {
                    // вот были даты 20.05 - 25.05 , кликаешь на первую дату, выбираешь 30.05, она ставится впервую, вторая сбрасывается
                    this.setToDate(null);
                } else {
                    this.toDateChange = true;
                }
            }
        }
        this.onModelChange();
    }

    private isValidToDate(date: NgbDate): boolean {
        return date && (date.after(this.getFromDate()) || date.equals(this.getFromDate()))
    }

    onClosed() {
        if (!this.getToDate()) {
            this.setToDate(this.getFromDate());
        }
    }

    isHovered(date: NgbDate): boolean {
        return this.getFromDate() && !this.getToDate() && this.hoveredDate && date.after(this.getFromDate()) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate): boolean {
        return this.getToDate() && date.after(this.getFromDate()) && date.before(this.getToDate());
    }

    isRange(date: NgbDate): boolean {
        return date.equals(this.getFromDate()) || (this.getToDate() && date.equals(this.getToDate())) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    isOutside(date: NgbDate): boolean {
        if (!this.navigateMonth) {
            return false;
        }
        // не равно мин месяц в ренже и след после него
        const currentMonth = date.month === this.navigateMonth;
        const nextMonth = date.month === this.navigateMonth + 1 || (this.navigateMonth === 12 && date.month === 1);
        return !currentMonth && !nextMonth;
    }

    onNavigate(event: NgbDatepickerNavigateEvent): void {
        this.navigateMonth = event.next.month;
    }

    /**
     * Передача сигнала об изменении дат наружу
     */
    onModelChange() {
        this.modelChange.emit();
    }
}