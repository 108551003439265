import {NgModule} from "@angular/core";
import {AnswerEditComponent} from "./details/answer-edit.component";
import {AnswerMasterComponent} from "./master/answer-master.component";
import {CollectionModule} from "../../common/collection/collection.module";
import {AnswerService} from "./answer.service";
import {UIRouterModule} from "@uirouter/angular";
import {CommonModule} from "@angular/common";
import {ExpertModule} from "../../common/expert/expert.module";
import {MacroModule} from "../../common/macro/macro.module";
import {EntityUsageModule} from "../../entity-usage/entity-usage.module";
import {FormControlModule} from "../../common/form/form-control.module";
import {FormsModule} from "@angular/forms";
import {FormulationModule} from "../../formulation/formulation.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {TaggingItemModule} from "../../common/tagging-item/tagging-item.module";
import {BoilerplateModule} from "../base/boilerplate.module";
import {TagTreeModule} from "../tag/tree/tag-tree.module";

@NgModule({
    declarations: [
        AnswerEditComponent,
        AnswerMasterComponent
    ],
    imports: [
        CollectionModule,
        UIRouterModule,
        CommonModule,
        ExpertModule,
        MacroModule,
        EntityUsageModule,
        FormControlModule,
        FormsModule,
        FormulationModule,
        NgSelectModule,
        TaggingItemModule,
        BoilerplateModule,
        TagTreeModule
    ],
    exports: [
        AnswerEditComponent,
        AnswerMasterComponent
    ],
    providers: [
        AnswerService
    ]
})
export class AnswerModule {

    static states = [
        {
            name: "robot.answer",
            url: "/answer",
            views: {
                'content@authorized': {
                    component: AnswerMasterComponent
                }
            },
            data: {
                title: "Ответы",
                requiredProjectVersion: true,
            },
        },
        {
            name: 'robot.answer.edit',
            url: "/edit/:answerId",
            params: {notifySuccess: {dynamic: true}},
            views: {
                'details@robot.answer': {
                    component: AnswerEditComponent
                }
            },
            data: {
                title: "Ответы | ",
                newObjectTitle: "Новый ответ",
                editObjectTitle: "Редактирование ответа",
                successfulSaveMessage: "Ответ сохранён",
                meta: {
                    parameters: ["scriptId"],
                    sectionFunction: ([scriptId]) => "Обучение робота",
                    subSectionFunction: ([scriptId]) => scriptId ? "Редактирование ответа" : "Новый ответ"
                },
                requiredProjectVersion: true,
                isCheckObjectExist: true
            }
        }
    ];

}