import * as joint from '@naumen/rappid';
import {dia, util} from '@naumen/rappid';
import JointAdapter from "../JointAdapter";
import {EruditeElement} from "../element-view/EruditeElement";
import {StepLabelEntity, StepLabelTypeName} from "../../../../data/va/Script";
import EruditeLink from "../link-view/EruditeLink";
import {getNodeText, isRootNode} from "../ScriptBuilderUtils";
import {VaScriptCondition} from "../../../../data/va/VaScriptCondition";

/**
 * Выбор и копи-паста элементов
 */
export default class CopyPasteController {

    /**
     * Инструмент выбора
     */
    public selection: joint.ui.Selection;

    /**
     * Инструмент копирования
     */
    public clipboard = new joint.ui.Clipboard();

    /**
     * Хранение вставленных элементов на время вставки для обработки вставляемых линков
     */
    public pastedNodesByOldId: Map<string, EruditeElement> = new Map<string, EruditeElement>();

    /**
     * Мапа всех имеющихся (id сущности -> (id формулировок и опций) для проверке при вставке, есть ли такие сущности в версии
     */
    private allIds: Map<any, Set<any>>;

    /**
     * Все сущности в системе по id
     */
    private entitiesById: Map<any, StepLabelEntity>;

    /**
     * Координаты вставки, устанавливаются по клику на пустой пейпер
     */
    private pasteCoordinates: { x: number; y: number };

    /**
     * Центр видимой области при нажатии ЛКМ
     */
    private pointerDownPaperCenter: dia.Point;

    constructor(private adapter: JointAdapter) {
        // инициализируем инструменты выбора и копирования
        this.initTools();

        // собираем все идшники для проверке узлов и линков при вставке
        this.collectAllIds();
    }

    /**
     * Инициализация инструментов выбора и копирования
     */
    private initTools() {
        // тул выбора элементов
        this.selection = new joint.ui.Selection({
            paper: this.adapter.component.paper,
            boxContent: () => ''
        });
        this.selection.removeHandle('rotate');
        this.selection.removeHandle('remove');

        // по клику с контролом добавляем элемент
        this.adapter.component.paper.on('element:pointerup', (cellView, evt) => {
            if (evt.shiftKey) {
                this.selection.collection.add(cellView.model);
            }
        });
        // по клику с контролом на выбранном элементе убираем из выборки
        this.selection.on('selection-box:pointerdown', (elementView, evt) => {
            if (evt.shiftKey) {
                this.selection.collection.remove(elementView.model);
            }
        });
    }

    /**
     * Начало выбора элементов рамкой. Если есть уже выбранное - добавляем новое
     */
    startSelecting(evt: any) {
        // запоминаем, что было выбрано до этого
        const selectedBeforeCurrentSelection = this.selection.collection? this.selection.collection.map(element => element) : [];

        // переопределим метод окончания выбора
        const originalStopSelecting = this.selection.stopSelecting;
        this.selection.stopSelecting = (evt) => {
            try {
                // исходный метод выбора элементов
                originalStopSelecting(evt);
                // добавляем в выборку выбранное до текущего выбора
                selectedBeforeCurrentSelection.forEach(element => this.selection.collection.add(element));
            } finally {
                // возвращаем оригинальный метод
                this.selection.stopSelecting = originalStopSelecting;
            }
        }
        // начинаем рамочый выбор
        this.selection.startSelecting(evt);
    }

    /**
     * Собрать данные по имеющимся в версии сущностям в нужный для валидации при вставке формат
     */
    private collectAllIds() {
        // мапа (id сущности -> (сет id субсущностей - формулировок, опций, выходных узлов процедур)
        this.allIds = new Map<any, Set<any>>();
        // мапа сущностей по id
        this.entitiesById = new Map<any, StepLabelEntity>();

        // раскладываем все сущности в версии по этим мапам
        this.adapter.component.entities.map(entry => entry.entities).forEach(entities => entities.forEach(entity => {
            this.entitiesById.set(entity.key.id, entity);
            const entityIds = new Set<any>();
            this.allIds.set(entity.key.id, entityIds);
            if (entity.options) {
                entity.options.forEach(option => entityIds.add(option.key.id));
            }
            if (entity.formulations) {
                entity.formulations.forEach(formulation => entityIds.add(formulation.key.id));
            }
        }));
    }

    /**
     * Задать координаты вставки
     */
    onStartPanning(x: number, y: number, center: dia.Point) {
        this.pasteCoordinates = {x: x, y: y}
        this.pointerDownPaperCenter = center;
    }

    /**
     * Отпустили ЛКМ на пустой области
     */
    onStopPanning(newCenter: dia.Point) {
        if (this.pointerDownPaperCenter.x == newCenter.x && this.pointerDownPaperCenter.y == newCenter.y) {
            // если отпустили там же, где нажали, сбрасываем выделение
            this.selection.cancelSelection();
        }
    }

    /**
     * Выбрать все элементы
     */
    selectAll() {
        this.selection.collection.reset(this.adapter.getGraph().getElements());
        this.adapter.component.clickStat('select_all', true);
    }


    /**
     * Рамка выбранного иногда неправильно прорисовывается, восстанавливается, если подвигать элементы
     */
    restoreSelectionFrame() {
        this.selection.collection.at(0).translate(.1, 0);
        this.selection.collection.at(0).translate(-.1, 0);
    }

    /**
     * Скопировать ячейки
     */
    copyCells() {
        const selectedElements = this.getSelectedElements();
        const success = this.copyToClipboard(selectedElements);
        if (success) {
            this.adapter.component.showMessage('Данные скопированы в буфер обмена', 'success');
        }
        this.adapter.component.clickStat('copy', true);
    }


    /**
     * Вырезать ячейки
     */
    cutCells() {
        // копируем все, что выбрано,
        const selectedElements = this.getSelectedElements();
        const success = this.copyToClipboard(selectedElements);

        // а удаляем только удаляемое
        const elementsToRemove = selectedElements.filter(element => element.isDeletable());
        this.adapter.changesController.initBatchChange();
        elementsToRemove.forEach(element => element.remove());
        this.adapter.changesController.storeBatchChange();
        this.selection.collection.reset([]);

        if (success) {
            this.adapter.component.showMessage('Данные перенесены в буфер обмена', 'success');
        }
        this.adapter.component.clickStat('cut', true);
    }

    /**
     * Копируем элементы в клипборд
     */
    copyToClipboard(selectedElements: EruditeElement[]): boolean {
        try {
            if (!selectedElements.length) {
                // ничего не выбрано
                return false;
            }
            // копируем в сторадж
            this.clipboard.copyElements(selectedElements, this.adapter.component.paper.model);
            return true
        } catch (e) {
            // длина json с элементами и линками может оказаться больше размера стораджа (несколько мегабайт),
            // хорошо проявляется на линках с условиями по городам (от 3 шт.)
            if (e.toString().indexOf('exceeded the quota') > 0) {
                this.adapter.component.showMessage('Не удалось скопировать данные, уменьшите количество выделенных элементов');
                return false;
            } else {
                throw e;
            }
        }
    }

    /**
     * Получить выбранные элементы
     */
    private getSelectedElements(): EruditeElement[] {
        let collection = this.selection.collection;
        if ((!collection || collection.length < 2) && this.adapter.component.nowEditedElement) {
            // если ничего не выбрано или выбран лишь один элемент, но есть кликнутый элемент, выбираем его
            collection.reset([this.adapter.component.nowEditedElement]);
        }
        // в ноды запишем актуальные данные из элементов графа
        collection.forEach(element => element.attributes.node = JointAdapter.toScriptNode(element))
        return collection;
    }

    /**
     * Вставить ячейки
     */
    pasteCells() {
        try {
            // начинаем пакетные изменения
            this.adapter.changesController.initBatchChange();

            // конвертируем содержимое стораджа в наши классы
            const cellsFromStorage = this.convertCopiedToEruditeCells();
            // отбираем, что можно вставить
            const {pastableCells, elementsSkipped, linksSkipped} = this.pasteValidation(cellsFromStorage);

            if (pastableCells.length) {
                // добавляем элементы в граф
                this.adapter.getGraph().addCells(pastableCells);
                // выбираем вставленные элементы
                this.selection.collection.reset(pastableCells.filter(cell => cell.isElement()));
                this.restoreSelectionFrame();
            }
            // показываем сообщение про не вставленное, если есть
            this.showSkippedMessage(elementsSkipped, linksSkipped);

            // запомненные при вставке элементы зачищаем
            this.pastedNodesByOldId.clear();
            // стата
            this.adapter.component.clickStat('paste', true);
        } finally {
            // заканчиваем пакетные изменения
            this.adapter.changesController.storeBatchChange();
        }
    }


    /**
     * Первый этап вставки: из стораджа в клипборд в объектах наших классов.
     * Сделано на основе joint.ui.Clipboard.pasteCells()
     */
    private convertCopiedToEruditeCells() {
        // парсим содержимое стораджа
        const cells = JSON.parse(localStorage.getItem(this.clipboard.LOCAL_STORAGE_KEY));
        if (cells) {
            const cellNamespace = this.adapter.getGraph().get('cells').cellNamespace;
            const tmpGraph = new dia.Graph([], {cellNamespace: cellNamespace}).fromJSON({cells: cells}, {sort: false, dry: true});
            this.clipboard.reset(tmpGraph.getCells());
        }
        // вычисляем смещение при вставке
        const opt = {
            translate: this.getPasteTranslate(),
        }
        // инстанцируем наши классы
        return this.clipboard.map(cell => {
            if (cell.attributes.node) {
                // если это узел, делаем эрудит-элемент из десереализованных из json данны
                return this.convertToEruditeElement(cell);
            }
            if (cell.attributes.edge) {
                // восстанавливаем эрудит-линк
                return this.convertToEruditeLink(cell);
            }
            return cell;
            // далее вызываем оригинальный метод, который проставляет координаты вставки
        }).map((cell => this.clipboard.modifyCell(cell, opt)));
    }

    /**
     * Сделать элемент нашего класса из десериализованных данных при вставке
     */
    private convertToEruditeElement(cell): EruditeElement {
        // генерируем новый id
        const node = cell.attributes.node;
        const oldId = node.key.id;
        node.key.id = util.uuid();
        node.key.projectVersionId = this.adapter.component.projectVersionId;
        // проставляем id скрипта, если есть
        node.scriptId = this.adapter.script?.key?.id;
        if (node.stepLabel.entityId) {
            // проставляем новую сущность и текст, в другой версии может быть уже отредактировано
            const entity = this.entitiesById.get(node.stepLabel.entityId);
            if (entity) {
                node.entity = entity;
                node.text = getNodeText(node);
            }
        }
        // создаем эрудит-элемент
        const eruditeElement = this.adapter.createEruditeElement(node);
        // кладем в мапу для использования при вставке ребер
        this.pastedNodesByOldId.set(oldId, eruditeElement);
        return eruditeElement;
    }

    /**
     * Сделать линк нашего класса из десериализованных данных при вставке
     */
    private convertToEruditeLink(cell): EruditeLink {
        // генерируем новый id
        const edge = cell.attributes.edge;
        edge.key.id = util.uuid();
        edge.key.projectVersionId = this.adapter.component.projectVersionId;
        // проставляем id скрипта, если есть
        edge.scriptId = this.adapter.script?.key?.id;

        // проставляем новые сущности в условия
        if (edge.conditions) {
            edge.conditions = edge.conditions.map((condition: VaScriptCondition) => {
                const scriptCondition = new VaScriptCondition(condition);
                if (scriptCondition.entityId) {
                    scriptCondition.entity = this.entitiesById.get(scriptCondition.entityId);
                }
                return scriptCondition;
            });
        }
        // подсоединяем вставленные элементы (id новые)
        const source = this.pastedNodesByOldId.get(cell.attributes.edge.fromNodeId);
        edge.fromNodeId = source.node.key.id;
        const target = this.pastedNodesByOldId.get(cell.attributes.edge.toNodeId);
        edge.toNodeId = target.node.key.id;

        // делаем линк
        return this.adapter.createEruditeLink(edge, source, target);
    }

    /**
     * Рассчитать смещение при вставке, чтобы получилась вставка в последнюю кликнутую точку
     */
    private getPasteTranslate() {
        // дефолтное смещение
        const defaultTranslate = 20;
        const translate = {dx: defaultTranslate, dy: defaultTranslate};
        if (!this.pasteCoordinates) {
            // не было кликов, по умолчанию делаем смещение вправо-вниз на дефолтное расстояние
            return translate;
        }
        // достаем скопированное из клипборда
        const serializedCells = window.localStorage.getItem(this.clipboard.LOCAL_STORAGE_KEY);
        if (!serializedCells) {
            // ничего нет
            return translate;
        }
        // ищем верхнюю левую точку
        let point = this.getCopiedLeftTopCorner(serializedCells);
        if (!point) {
            // ничего не нашлось
            return translate;
        }
        // смещение для пасты в заданные координаты
        translate.dx = this.pasteCoordinates.x - point.x;
        translate.dy = this.pasteCoordinates.y - point.y;

        // смещаем точку вставки, чтобы в следующий раз вставить правее-ниже
        this.pasteCoordinates.x += defaultTranslate;
        this.pasteCoordinates.y += defaultTranslate;
        return translate;
    }

    /**
     * Десериализовать скопированное и найти верхнюю левую точку из
     */
    private getCopiedLeftTopCorner(serializedCells: string) {
        let point = null;
        JSON.parse(serializedCells)
            // старт пропускаем, он будет заменен на уже имеющийся
            .filter(cell => cell.node && !isRootNode(cell.node))
            .map(cell => cell.position)
            .forEach(position => {
                if (!point) {
                    point = position;
                } else {
                    if (point.x > position.x) {
                        point.x = position.x;
                    }
                    if (point.y > position.y) {
                        point.y = position.y;
                    }
                }
            });
        return point;
    }

    /**
     * Дообработка/валидация/фильтрация вставленного из клипборда
     */
    private pasteValidation(sourceCells: dia.Cell[]): { pastableCells: EruditeElement[], elementsSkipped: number, linksSkipped: number } {
        // для id удаленных после валидации узлов и линков
        const skippedNodeIds = new Set<string>();
        const skippedEdgeIds = new Set<string>();

        // выходы процедуры не вставляем в сценарий
        let cells = this.checkPastedProcedureExits(sourceCells, skippedNodeIds);
        // старт/вход не вставляем, вместо него используем существующий
        cells = this.checkPastedRoot(cells);
        // не вставляем узлы, для которых нет сущностей, опций, формулировок и т. д. в этой версии
        cells = this.checkPastedEntityElements(cells, skippedNodeIds);
        // не вставляем линки, для условий которых нет сущностей, опций и т. д. в этой версии или которые соединяют такие узлы
        cells = this.checkPastedLinks(cells, skippedNodeIds, skippedEdgeIds);

        return {pastableCells: cells, elementsSkipped: skippedNodeIds.size, linksSkipped: skippedEdgeIds.size};
    }

    /**
     * Если это не процедура, удаляем узлы выходов из процедуры
     */
    private checkPastedProcedureExits(cells: dia.Cell[], skippedNodeIds: Set<string>) {
        if (this.adapter.isProcedure) {
            return cells;
        }
        return cells.filter(cell => {
            if (!cell.isElement()) {
                return true;
            }
            if (cell.node.stepLabel.type.name != StepLabelTypeName.EXIT) {
                return true;
            }
            skippedNodeIds.add(cell.node.key.id);
            return false;
        });
    }

    /**
     * Старт/вход не вставляем
     */
    private checkPastedRoot(cells: dia.Cell[]) {
        // если есть старт/вход,
        const pastedRoot = cells.find(cell => isRootNode(cell.node));
        if (!pastedRoot) {
            return cells;
        }
        // ребра от него подсоединяем на имеющийся старт/вход
        cells.filter(cell => cell.isLink())
            .filter((link: EruditeLink) => link.sourceNode.key.id == pastedRoot.node.key.id)
            .forEach(link => link.sourceElement = this.adapter.rootElement);

        // и не вставляем его
        return cells.filter(cell => !isRootNode(cell.node));
    }

    /**
     * Чекаем узлы на наличие сущностей в версии
     */
    private checkPastedEntityElements(cells: dia.Cell[], skippedNodeIds: Set<string>) {
        return cells.filter(cell => {
            if (!cell.isElement()) {
                return true;
            }
            if (this.isOkToPaste(cell)) {
                return true;
            }
            skippedNodeIds.add(cell.node.key.id);
            return false;
        });
    }

    /**
     * Чекаем ребра на наличие сущностей в версии
     */
    private checkPastedLinks(cells: dia.Cell[], skippedNodeIds: Set<string>, skippedEdgeIds: Set<string>) {
        return cells.filter(cell => {
            if (!cell.isLink()) {
                return true;
            }
            if (this.isOkToPaste(cell) && !skippedNodeIds.has(cell.edge.fromNodeId) && !skippedNodeIds.has(cell.edge.toNodeId)) {
                return true;
            }
            skippedEdgeIds.add(cell.scriptEdge.key.id);
            return false;
        });
    }

    /**
     * Проверить, можем ли вставлять элемент или линк (все ли id есть в этой версии)
     */
    private isOkToPaste(cell: EruditeElement | EruditeLink): boolean {
        // получаем id всех сущностей и субсущностей, используемых элементом/линком
        const cellEntityIds = cell.allIds;
        let present = true;
        cellEntityIds.forEach((subIds, entityId) => {
            // по id сущности достаем имеющиеся в этой версии id субсущностей
            const presentSubIds = this.allIds.get(entityId);
            if (!presentSubIds) {
                // нет такой сущности
                present = false;
            } else {
                subIds.forEach(subId => {
                    if (!presentSubIds.has(subId)) {
                        // нет такой субсущности
                        present = false;
                    }
                })
            }
        });
        // готово
        return present;
    }

    /**
     * Показать сообщение о пропущенном при вставке
     */
    private showSkippedMessage(elementsSkipped: number, linksSkipped: number) {
        if (elementsSkipped || linksSkipped) {
            // если что-то не вставить, выводим про это сообщение
            let message = 'Пропущено ';
            if (elementsSkipped) {
                message += 'элементов: ' + elementsSkipped;
            }
            if (linksSkipped) {
                if (elementsSkipped) {
                    message += ', ';
                }
                message += 'переходов: ' + linksSkipped;
            }
            this.adapter.component.showMessage(message);
        }
    }
}