/**
 * Created by semyon on 21.11.16.
 */

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}


export function mergeArrays(to, from, equalsFunction) {
    if (to.length === 0) {
        for (let o of from) {
            to.push(o);
        }
        return;
    }
    for (let newObj of from) {
        let set = false;
        for (let i = to.length - 1; i >=0; i--) {
            let existingObj = to[i];
            if (equalsFunction(newObj, existingObj)) {
                to[i] = newObj;
                set = true;
                break;
            }
        }
        if (!set) {
            to.push(newObj);
        }
    }
}

export function mergeUrlParts(...parts) {
    return parts.reduce((left, right) => {
        if (!left.endsWith("/") && !right.startsWith("/")) {
            return left + "/" + right;
        }
        if (left.endsWith("/") && right.startsWith("/")) {
            return left + right.substring(1);
        }
        return left + right;
    });
}

export function TemplateCacheBuilder(scanMap) {
    const templateUrls = {};
    for (let scanFolder in scanMap) {
        let scanResults = scanMap[scanFolder];
        scanResults.keys().forEach(function (key) {
            templateUrls[scanFolder + key.substring(1)] = scanResults(key);
        });
    }
    return ['$templateCache', function ($templateCache) {
        for (let key in templateUrls) {
            $templateCache.put(key, templateUrls[key]);
        }
    }];
}
