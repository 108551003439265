import {Component, OnInit} from "@angular/core";
import {BaseDetailsComponent} from "../../base/base-details.component";
import {CompositeKey, ViewTab} from "../../../../../data/va/Common";
import {isEnumExtractor, isVarExtractor, VaAttribute} from "../../../../../data/va/Attribute";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {AttributeMasterComponent} from "../master/attribute-master.component";
import {AttributeService} from "../attribute.service";
import {VaAsrSettings, VaTtsSettings} from "../../../../../data/va/Voice";
import {TextToSpeechService} from "../../../media-libraries/text-to-speech/text-to-speech.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";


@Component({
    selector: 'attribute-view',
    template: require('./attribute-view.component.html'),
    styles: [require('./attribute-view.component.less')]
})
export class AttributeViewComponent extends BaseDetailsComponent<VaAttribute, CompositeKey<number>> implements OnInit {

    objectIdKey = 'attrId';
    defaultTtsSettings: VaTtsSettings;
    viewTab: ViewTab = ViewTab.COMMON;
    hasVoice: boolean;

    constructor(stateService: StateService,
                public master: AttributeMasterComponent,
                httpClient: HttpClient,
                public dataService: AttributeService,
                titleService: Title,
                protected notificationService: NotificationService,
                private ttsService: TextToSpeechService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    get entityTitle(): string {
        return this.form.object.name;
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        await this.init();
        this.hasVoice = await this.dataService.hasVoice();
    }

    async init() {
        this.defaultTtsSettings = await this.ttsService.getDefaultTtsSettings();
    }

    generateFormObject(): VaAttribute {
        // no need, only view
        return undefined;
    }

    /**
     * Экстрактор с перечислением
     */
    isEnumExtractor(): boolean {
        return isEnumExtractor(this.form.object);
    }

    /**
     * Экстрактор без перечисления
     */
    isAnyExtractor(): boolean {
        return isVarExtractor(this.form.object);
    }

    onObjectLoaded() {
        this.httpClient.get<VaAsrSettings>(`/account/expert/extractor/defaultAsrSettings`).toPromise().then(value => {
            if (!this.form.object.extractor.asrSettings) {
                this.form.object.extractor.asrSettings = value;
            }
        });
    }

}