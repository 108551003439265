/**
 * Импортирующая компонента
 */
export interface ImportingComponent {

    /**
     * Вызывается после успешного импорта
     */
    afterSuccessfulImport(importedId: string);

    /**
     * Вызывается после неуспешного импорта
     */
    showError(error: string);
}


/**
 * Результат импорта скрипта
 */
export class ImportScriptResult {
    // описания импорта частей
    resultGroups: ImportEntityByResultGroup[];
    otherMessages: string[];
    // информация об импотре
    meta?: {
        // id сущности
        mainId: string;
        // сценарий или процедура
        targetMainEntityType: {
            name: 'SCENARIOS' | 'PROCEDURES'
        }
    }

    // ошибки импорта
    errors: {
        message: string;
    }[]
}

export interface CheckImportScriptResult {
    dir: string;
    type: CheckImportScriptResultType;
}

export enum CheckImportScriptResultType {
    OK = 'OK',
    API_KEYS_INTERSECT = 'API_KEYS_INTERSECT',
    NAMES_INTERSECT = 'NAMES_INTERSECT',
    NAMES_API_KEYS_INTERSECT = 'NAMES_API_KEYS_INTERSECT'
}

export enum ScriptImportIntersectionResolveType {
    REMEMBER = 'REMEMBER',
    UPDATE_EXISTING = 'UPDATE_EXISTING',
    UPDATE_IMPORTED = 'UPDATE_IMPORTED'
}


/**
 * Тип результата импорта сущности
 */
interface ImportEntityResultType {
    name: string;
    title: string;
}

/**
 * Группа по типу результата
 */
interface ImportEntityByResultGroup {
    type: ImportEntityResultType;
    groups: ImportEntityByEntityGroup[];
}

/**
 * Сущность для скрипта
 */
interface ExportableEntityType {
    title: string;
}

/**
 * Группа по типу сущности
 */
interface ImportEntityByEntityGroup {
    type: ExportableEntityType;
    results: ImportEntityResult[];
}

/**
 * Результат импорта сущности
 */
class ImportEntityResult {
    resultType: ImportEntityResultType;
    entityType: ExportableEntityType;
    title: string;
    subEntityCounts: ImportEntitySubEntityCount[];
}

/**
 * Счетчик добавленных суб-сущностей
 */
interface ImportEntitySubEntityCount {
    type: ExportableSubEntityType;
    count: number;
}

/**
 * Суб-сущность (формулировки, опции и т. д.)
 */
interface ExportableSubEntityType {
    title: string;
}
