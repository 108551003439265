import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";


@Pipe({name: 'highlight'})
export class HighlightPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }

    transform(fieldValue: string, searchString: string): SafeHtml {

        // если строка пустая - нечего проверять
        if (!fieldValue) {
            return;
        }

        // если строка поиска пустая или состоит только из любого количества пробелов - ничего не делаем
        if (!searchString || /^\s+$/.test(searchString)) {
            return fieldValue;
        }

        // заменяем в строке поиска все спецсимволы на чистое совпадение и кладем в RegExp
        let regex = new RegExp(searchString.replace(/[^a-zA-Z0-9]/g,"\\$&"), "ig");

        // если в строке есть совпадение - подсвечиваем
        if (regex.test(fieldValue)) {
            // заменяем чистое совпадения в строке учитывая регистр на обернутое в span
            fieldValue = fieldValue.replace(regex, "<span class='highlight-text-background'>$&</span>");
            return this.sanitized.bypassSecurityTrustHtml(fieldValue);
        }
        return fieldValue;
    }
}
