import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VaExtractionContext} from "../../../dialog/model/correction.model";
import {MassOperation, MassOperationType} from "../../../../../data/va/MassOperation";
import {VaTag} from "../../../../../data/va/Tag";

@Component({
    template: require('./mass-operation-dialog.component.html'),
    styles: [require('./mass-operation-dialog.component.less')]
})
export class MassOperationDialogComponent {

    allContexts: VaExtractionContext[];
    errorMessage: string;

    constructor(private dialogRef: MatDialogRef<MassOperationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { operation: MassOperation,
                                                        contexts: VaExtractionContext[],
                                                        tags: VaTag[],
                                                        titleText: string}) {
    }

    /**
     * Выполнить операцию
     */
    ok() {
        if (this.data.operation.type == MassOperationType.CHANGE_CONTEXT && !this.data.operation.context) {
            this.errorMessage = 'Выберите контекст извлечения';
            return;
        }
        if (this.data.operation.type == MassOperationType.CONFIRM_MOVE && !this.data.operation.tagId) {
            this.errorMessage = 'Выберите тематику';
            return;
        }
        this.dialogRef.close(this.data.operation);
    }

    /**
     * Отмена
     */
    cancel() {
        this.dialogRef.close();
    }

    /**
     * Заголовок диалога
     */
    get dialogTitle() {
        if (this.data.titleText) {
            // если был предзаданный текст, то используем его
            return this.data.titleText;
        }
        switch (this.data.operation.type) {
            case MassOperationType.CHANGE_CONTEXT:
                return 'Изменить контекст извлечения';
            case MassOperationType.CONFIRM_MOVE:
                return 'Перенести примеры в другую тематику';
            case MassOperationType.DELETE:
                return 'Удалить выбранное?';
            default:
                throw Error('unsupported type ' + this.data.operation.type);
        }
    }

    /**
     * Текст кнопки действия
     */
    get okButtonTitle() {
        switch (this.data.operation.type) {
            case MassOperationType.CHANGE_CONTEXT:
                return 'Сохранить';
            case MassOperationType.CONFIRM_MOVE:
                return 'Перенести';
            case MassOperationType.DELETE:
                return 'Удалить';
            default:
                throw Error('unsupported type ' + this.data.operation.type);
        }
    }

    /**
     * Текст кнопки отмены
     */
    get cancelButtonTitle() {
        switch (this.data.operation.type) {
            case MassOperationType.CHANGE_CONTEXT:
                return 'Закрыть без изменений';
            case MassOperationType.CONFIRM_MOVE:
                return 'Закрыть без изменений';
            case MassOperationType.DELETE:
                return 'Не удалять';
            default:
                throw Error('unsupported type ' + this.data.operation.type);
        }
    }
}