import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AudioRecord} from "../../../../../../data/va/AudioRecord";
import {Macro, MacroTypeEnum} from "../../object/Macro";
import {MacroService} from "../../macro.service";
import {AudioRecordService} from "../../../../media-libraries/audio-record/audio-record.service";

@Component({
    selector: 'macro-audio-record',
    template: require('./macro-audio-record.component.html'),
    styles: [require('../dialog.component.less')]
})

export class MacroAudioRecordComponent {

    records: AudioRecord[];
    selectedRecord: AudioRecord;
    fileByRecord: Map<number, any> = new Map<number, any>();

    constructor(protected dialogRef: MatDialogRef<MacroAudioRecordComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { macro: Macro },
                protected macroService: MacroService, private  audioRecordService: AudioRecordService) {

        const audioText = data.macro.audioRecord.text;
        this.data.macro.audioRecord = null;
        this.data.macro.entityId = null;

        this.macroService.getEntities().subscribe(result => {
            // records with selected text
            this.records = result.get(MacroTypeEnum.AUDIO_RECORD).groupedEntities.get(audioText).map(object => {
                const audioRecord = new AudioRecord(object);
                if (!audioRecord.comment) {
                    audioRecord.comment = "-";
                }
                return audioRecord;
            });
            // load audio files
            this.records.filter(record => record.fileId)
                .forEach(record =>
                    this.audioRecordService.loadAudioFile(record.fileId,
                        (audioUrl) => this.fileByRecord.set(record.key.id, audioUrl), () => {}));
        });
    }

    onDeny(): void {
        this.dialogRef.close();
    }

    onSave(): void {
        this.data.macro.audioRecord = this.selectedRecord;
        this.data.macro.entityId = this.selectedRecord.key.id + "";
        this.dialogRef.close(this.data.macro);
    }

}


