import {Component} from "../../../backports";
import {DatePickerOptions} from "../../data/va/Common";
import * as urls from "../../../../js/workplace/urls";
import * as common from "../../../../js/workplace/common"
import restangular from "restangular";
import {DialogFilterParam, FilterForm, SelectionModalMode} from "../../controllers/analysis/Analysis";
import VaAnalysisFilterController from "../../controllers/analysis/VaAnalysisFilterController";

/**
 * Компонента для фильтрации выдачи во вкладке "Статистика" (переиспользуется в выявлении трендов)
 */
@Component({
    bindings: {
        object: "=",
        trendDetectionFilter: "<"
    },
    templateUrl: "/pages/workplace/va/common/components/analysisFilter.html",
})
export default class VaAnalysisFilterComponent {

    /**
     * Объект фильтра
     */
    private object: FilterForm;

    /**
     * Подгружаемая с бэка инфомрация (витрины, каналы, оценки, выборки, etc)
     */
    private filterData: any;

    /**
     * Флаг:
     * true -> нужно скрыть некоторые поля (для отображения на вкладке "Выявление трендов")
     * false -> полное отображение
     */
    private trendDetectionFilter: boolean;

    private readonly datePickerOptions: DatePickerOptions = common.rangeDatepickerOpts;

    private readonly baseUrl: string = `${urls.va.analysis}`;

    private restangular: restangular.IService;

    private scope: ng.IScope;

    private state: ng.ui.IStateService;

    private parentController: VaAnalysisFilterController;

    private $uibModal: any;

    static $inject = ["Restangular", "$state", "$scope", "$uibModal"];

    constructor(Restangular: restangular.IService, $state, $scope: ng.IScope, $uibModal) {
        this.scope = $scope;
        this.restangular = Restangular;
        this.parentController = ($scope as any).$parent.ctrl;
        this.$uibModal = $uibModal;
        this.state = $state;
        this.loadFilterData();

        // Следим за изменением типа случайной выборки: процент/количество и обнуляем заданное значение
        this.scope.$watch('$ctrl.object.randomizerMode', ((newValue, oldValue) => {
            if (newValue != oldValue) {
                this.object.randomizer.value = 0;
            }
        }));

        this.scope.$on("VaAnalysisFilterComponent::openSelectionModal",
            (event, args) => this.openSelectionModal(args.mode, args.filter))
    }

    /**
     * Подгрузить информацию для фильтров
     */
    loadFilterData(): void {
        this.restangular.one(this.baseUrl, `loadFiltersData`)
            .get()
            .then((data: any) => {
                this.filterData = this.restangular.stripRestangular(data);
                if (!this.object.restored) {
                    this.setupDefaultValues();
                }
            }, error => {
                if (error.status == 403) {
                    this.state.go('^');
                }
            });
    }

    setupDefaultValues() {
        if (this.object.userKeyWords) {
            this.object.userKeyWords.types = this.filterData.keywordsSources.slice();
        }
    }

    /**
     * Изменить порядок сортировки
     */
    changeOrder(): void {
        this.object.sortParams.order = this.object.sortParams.order == 'DESC' ? 'ASC' : 'DESC'
    }

    /**
     * Проставить в форму единственное значение (автоматическое заполнение единственного чекбокса)
     *
     * @param key       ключ в фильтре (изменяемый объект this.object из VaAnalysisFilterController.ts)
     * @param valueKey  ключ значения (если хотим не сам объект, а его поле)
     * @param value     значение
     */
    setSingleValue(key: string, valueKey: string, value: any): void {
        this.object[key].value = [valueKey ?
            // Т.к. valueKey - строка, а нужное значение может лежать глубже, чем на 1 уровне, то нужно добраться до нужного значения
            valueKey.split('.').reduce((object, path) => object[path], value)
            : value];
    }

    /**
     * Редактирование выборок (сейчас доступно только удаление)
     */
    editSelections() {
        let filter: DialogFilterParam = this.parentController.getFilter();
        this.openSelectionModal(SelectionModalMode.EDIT, filter)
    }

    /**
     * Открывать модальное окошко с выборками
     * @param mode      режим модального окна
     * @param filter    фильтр
     */
    openSelectionModal(mode: SelectionModalMode, filter: DialogFilterParam) {
        this.$uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: '/pages/workplace/modal/statSelectionModal.html',
            controller: 'VaStatSelectionModalController',
            controllerAs: '$ctrl',
            size: 'md',
            appendTo: angular.element(document).find("body"),
            resolve: {
                mode: () => mode,
                filter: () => filter,
                selections: () => this.filterData.selections
            }
        });
    }

    /**
     * Есть ли голос в каналах
     */
    isVoiceChannelPresent() {
        return this.filterData?.channels.some(channel => channel.apiKey === 'voice');
    }
}
