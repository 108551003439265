/**
 * Открытие модального окна с TBDialog-ом по id
 * @param dialogId id tb-dialog-а
 */
export function openTBDialogModal(that, url, dialogId, messageId, viewMode) {
    const modalInstance = that.uibModal.open({
        controllerAs: '$ctrl',
        controller: function () {
            var ctrl = this;
            const messageUrl = "/account/expert/tagbuilding/messages";

            ctrl.viewMode = viewMode;

            // получение диалога с сервера
            that.restAngular.one(url, dialogId)
                .get()
                .then(function success(data) {
                    ctrl.dialog = data;
                }, function error(data) {
                });

            if (messageId) {
                that.restAngular.one(messageUrl, "getByLinkedMessageId")
                    .one(messageId)
                    .get()
                    .then(function success(data) {
                        ctrl.tbMessage = that.restAngular.stripRestangular(data);
                    }, function error(data) {
                    });
            }

            // кнопка отмена
            ctrl.cancel = function () {
                modalInstance.dismiss('cancel');
            };
        },
        templateUrl: '/pages/workplace/va/tagbuilding/tbDialog.html',
        openedClass: 'blur-main modal-open',
        windowClass: 'modal-default'
    });
}