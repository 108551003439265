import {Injectable} from "@angular/core";
import {VersionedDataService} from "../base/versioned-data.service";
import {TextData} from "./text-data.model";
import {HttpClient} from "@angular/common/http";
import WebSocketService from "../../../../services/WebSocketService";
import {StateService} from "@uirouter/core";
import {NamedEntitiesModel} from "../model/named-entities.model";


@Injectable()
export class TextDataService extends VersionedDataService<TextData, number> {

    baseUrl: string = "/account/expert/textdata";

    readonly entityClass = NamedEntitiesModel.VA_TEXT_DATA;

    constructor(protected httpClient: HttpClient, protected webSocketService: WebSocketService, protected stateService: StateService) {
        super(httpClient, webSocketService, stateService);
    }
}