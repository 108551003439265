import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {UIRouterModule} from "@uirouter/angular";
import {BrowserModule} from "@angular/platform-browser";
import {ChangesModule} from "./changes/changes.module";
import {LoginsModule} from "./logins/logins.module";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        UIRouterModule,
        ChangesModule,
        LoginsModule
    ],
    declarations: [],
    entryComponents: [
    ]
})
export class JournalsModule {

    static states = [
        {
            name: 'journals',
            url: "/journals",
            parent: "authorized"
        }
    ];

}