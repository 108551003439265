import {NgModule} from "@angular/core";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {CommonModule} from "@angular/common";
import {LicensePageComponent} from "./license-page.component";
import {TableModule} from "../table/table.module";

@NgModule({
    imports: [
        MatTableModule,
        MatSortModule,
        CommonModule,
        TableModule
    ],
    declarations: [LicensePageComponent],
    exports: [LicensePageComponent],
    entryComponents: [LicensePageComponent],
    providers: []
})

export class LicensePageModule {

}
