import {VaScriptNode} from "../../../../../data/va/Script";
import {mergeDeep} from "../../../../../functions/UtilFunctions";
import {FormulableElement} from "../interface/FormulableElement";
import {CheckpointableElement} from "../interface/CheckpointableElement";
import {applyMixins} from "../../ScriptBuilderUtils";
import {dia, ui} from "@naumen/rappid/build/rappid";
import {ClickMode} from "../EruditeElement";
import RectangleElement from "./RectangleElement";
import {ElementAttributeId, setElementAttribute} from "../../controller/ScriptAttributeUtils";
import HandlePosition = ui.Halo.HandlePosition;

export class AttributeElement extends RectangleElement {

    constructor(attributes: {node: VaScriptNode}) {
        super(attributes);

        mergeDeep(this.attributes, {
            attrs: {
                body: {
                    fill: "#00B3CB",
                    stroke: "#00B3CB",
                },
                label: {
                    fill: "#FFFFFF",
                }
            }
        });
        this.addCheckpointMarkUp();
        this.addFormulationMarkUp();
        this.addFollowingExtractionMarkup();
    }

    /**
     * Вёрстка отметки извлечения из предыдущей реплики
     */
    public addFollowingExtractionMarkup() {
        mergeDeep(this.attributes, {
            attrs: {
                followingExtractionIcon: {
                    fill: '#fff',
                    refX: '100%',
                    refX2: -25,
                    refY: '100%',
                    refY2: -28,
                    visibility: this.node.stepLabel.followingExtraction ? 'visible' : 'hidden'
                }
            }
        });
        this.markup.push({
            tagName: 'g',
            selector: 'followingExtractionIcon',
            children: [
                {
                    tagName: 'path',
                    attributes: {
                        'd': 'm106.667 85.12v149.547h42.666v-149.547h64l-85.333-85.12-85.333 85.12z',
                        'transform': 'matrix(.06 0 0 .06 0 0)'
                    }
                },
                {
                    tagName: 'path',
                    attributes: {
                        'd': 'm234.667 149.333v149.547h-64l85.333 85.12 85.333-85.12h-64v-149.547z',
                        'transform': 'matrix(.06 0 0 .06 0 0)'
                    }
                }]
        });
        this.handles.push({
            name: 'following-extraction-icon',
            position: HandlePosition.SE,
            events: {pointerdown: event => this.switchFollowingExtraction()},
        });
    }

    /**
     * Переключить возможность извлечения из предыдущей реплики
     */
    switchFollowingExtraction() {
        this.onInitBatchChange();
        this.node.stepLabel.followingExtraction = !this.node.stepLabel.followingExtraction;
        setElementAttribute(this, ElementAttributeId.FOLLOWING_EXTRACTION, this.node.stepLabel.followingExtraction);
        this.adjustCheckpointIcon();
        this.attr({
            followingExtractionIcon: {
                visibility: this.node.stepLabel.followingExtraction ? 'visible' : 'hidden'
            }
        });
        this.onStoreBatchChange();
    }

    /**
     * Клик на элемент
     */
    onElementClick(elementView: dia.ElementView, mode: ClickMode): ui.Halo {
        switch (mode) {
            case ClickMode.DEFAULT:
                // включаем гало
                return this.onFormulableElementClick(elementView, mode);
            case ClickMode.CHECKPOINT:
                // переключаем чек-пойнт
                return this.onCheckpointableElementClick();
        }
    }

    public isNavigable(): boolean {
        return true;
    }
}

export interface AttributeElement extends FormulableElement, CheckpointableElement {
}

applyMixins(AttributeElement, [FormulableElement, CheckpointableElement]);