import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {SortField} from "../../../../data/va/Common";
import {ReportsService} from "../reports.service";
import {Report} from "../report.model";
import {AttachmentDialogComponent} from "../../common/attachment-dialog/attachment-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../common/snackbar/notification/notification.service";

@Component({
    selector: 'reports-master',
    template: require('./reports-master.component.html'),
    styles: [require('./reports-master.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class ReportsMasterComponent implements OnInit {

    public isLoading: boolean = false;

    public standardReports: Report[];

    public customReports: Report[];

    public sortFields: SortField[] = [new SortField('Название', 'reportName', false)];

    public selectedReportDirectoryName: string;

    public isCustomReportSelected: boolean = false;

    public searchString: string;

    constructor(titleService: Title,
                protected stateService: StateService,
                protected httpClient: HttpClient,
                protected dataService: ReportsService,
                private notificationService: NotificationService,
                public dialog: MatDialog) {
        const title: string = this.stateService.current.data.title;
        titleService.setTitle(title);

        this.selectedReportDirectoryName = this.stateService.params['directoryName'];
    }

    async ngOnInit() {
        this.isLoading = true;
        await this.loadReports();
        this.isLoading = false;
    }

    async loadReports() {
        let reports = await this.dataService.getList();
        this.standardReports = reports.filter(report => report.reportType && report.reportType != 'CUSTOM');
        this.customReports = reports.filter(report => report.reportType == 'CUSTOM' || !report.reportType);
    }

    /**
     * Нажали на отчёт
     * Выделим его и откроем в details
     */
    async selectReport(directoryName: string) {
        this.selectedReportDirectoryName = directoryName;
        this.stateService.go('robot.reports.request', {
            directoryName: directoryName
        }, {reload: false});
    }

    /**
     * Метод, который будем вызывать в AttachmentDialogComponent
     * Выполняет загрузку пользовательского отчёта
     */
    async dialogUploadMethod(formData: FormData, dialogRef: MatDialogRef<AttachmentDialogComponent>) {
        let files: any = formData.get('files');

        if (!files.name.endsWith(".jrxml")) {
            this.notificationService.error('Неверное расширение файла')
            return null;
        }
        try {
            let fileForm = new FormData();
            fileForm.append('file', files);

            await this.httpClient.post<any>('/account/expert/report/uploadTemplate', fileForm, {}).toPromise();
            dialogRef.close(true);
        } catch (data) {
            return data;
        }
        return null;
    }

    showImportDialog() {
        const dialogRef = this.dialog.open(AttachmentDialogComponent, {
            width: '500px',
            data: {
                attachmentsCount: 1,
                multiple: false,
                uploadMethod: this.dialogUploadMethod,
                allowedMimeTypes: ['.jrxml']
            }
        });

        dialogRef.afterClosed()
            .subscribe((isChanged: boolean) => {
                if (isChanged == true) {
                    this.loadReports();
                }
            })

    }

    async deleteReport(fileName: string) {
        const response = await this.dataService.deleteTemplate(fileName);

        if (response == 'OK') {
            let deletedReport = this.customReports.filter(report => report.fileName == fileName)[0];
            if (deletedReport) {
                this.notificationService.success(`Шаблон "${deletedReport.reportName}" удалён.`);
            }
            await this.loadReports();

            this.closeDetails();
        }
    }

    /**
     * закроем details, если переключили вкладку
     */
    closeDetails() {
        if (this.stateService.params['directoryName']) {
            this.selectedReportDirectoryName = null;
            this.stateService.go('^');
        }
    }
}
