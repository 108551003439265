import * as urls from "../urls";

export default class MenuController {

    constructor($scope, $rootScope, $state, $stateParams, AccountService, WebSocketService, notificationManager, Restangular, SnackbarService, $http, TabService, $q, $transitions) {
        var that = this;
        that.restAngular = Restangular;
        that.notificationManager = notificationManager;
        that.$rootScope = $rootScope;
        that.SnackbarService = SnackbarService;
        that.$state = $state;
        that.$transitions = $transitions;
        that.$stateParams = $stateParams;
        that.accountService = AccountService;
        that.newMessageCount = 0;
        that.$http = $http;
        that.$scope = $scope;
        that.appVersion = `ver. ${APPLICATION_VERSION} ${REVISION.substr(0, 8)}`;

        this.currentProjectVersion = $stateParams['projectVersionId'];

        this.getModelsStatus();

        if (this.currentProjectVersion) {
            this.cropProjectName();
        }

        this.menu = {
            visibility: false,
            selectedIndex: -1,
            selectedIndexLeft: -1,
            activeMenu: {},
            activeMenuTitle: "",
            selectedSubmenuIndex: -1
        };
        this.timeoutStatusForm = {
            show: false,
            data: {}
        };

        const rCtrl = $scope.rCtrl;

        let projectIds = rCtrl.projectVersions ? [...new Set(rCtrl.projectVersions.map(it => it.projectId))] : [...new Set()];

        // если проект всего один, а версий проекта дофига => не сворачиваем их в подсписок
        this.initializeDropDownMenu(projectIds, rCtrl, that);
        if (!this.currentProjectVersion) {
            this.currentProjectVersion = rCtrl.projectVersions && rCtrl.projectVersions.length > 0 ? rCtrl.projectVersions[0].id : null;
        }

        that.menus = rCtrl.menu;
        that.accountData = that.accountService.getAccountData();
        if (that.accountData && that.accountData.user) {
            let userAccount = that.accountData.user.account;
            that.isExpert = userAccount.role.name === 'EXPERT' || userAccount.role.name === 'SUPER_EXPERT';
            that.isSupervisor = userAccount.role.name === 'SUPERVISOR';
            that.isLikeOperator = that.isExpert;
            that.isSupervisorOrOperator = that.isSupervisor;
            that.isSupervisorOrLikeOperator = that.isSupervisor || that.isLikeOperator;
            that.isProviderSuperExpert = userAccount.role.name === 'SUPER_EXPERT' && userAccount.expertType.name === 'PROVIDER';
            that.needHideTestButton = that.isExpert && that.accountService.getExpertAlwaysTestWS();
        } else {
            that.isLikeOperator = false;
            that.isExpert = false;
            that.isSupervisor = false;
            that.isSupervisorOrOperator = false;
            that.isSupervisorOrLikeOperator = false;
            that.isProviderSuperExpert = false;
            that.needHideTestButton = false;
        }
        that.initMenu();

        if (this.isExpert && this.currentProjectVersion) {
            this.checkProjectVersionChannelsAndHideTranscription(rCtrl.projectVersions);
        }

        var subscriptionId = null;

        subscriptionId = WebSocketService.subscribeOnEvents({
            eventType: "ACCOUNT_BLOCKED,VA_PROJECT_VERSIONS_CHANGED,VA_REBUILD_MODEL_FINISHED",
            fn: function (event) {
                switch (event.type) {
                    case "ACCOUNT_BLOCKED":
                        // если пользователя заблокировали - разлогинить его
                        $state.go("login", {params: JSON.stringify({reason: "Аккаунт заблокирован"})});
                        break;
                    case "VA_PROJECT_VERSIONS_CHANGED":
                        let projectIds = [...new Set(rCtrl.projectVersions.map(it => it.projectId))];
                        that.initializeDropDownMenu(projectIds, rCtrl, that);
                        break;
                    case "VA_REBUILD_MODEL_FINISHED":
                        that.getModelsStatus();
                        break;
                }

            }
        }, subscriptionId);

        $scope.$on("$destroy", function handler() {
            WebSocketService.removeListener(subscriptionId);
        });

    }

    getModelsStatus() {
        if (!this.currentProjectVersion) {
            return;
        }
        this.restAngular.one(`${urls.va.ml}/getModelsStatus`).get().then((status) => {
            this.modelsStatus = status.name;
        });
    }

    initializeDropDownMenu(projectIds, rCtrl, that) {
        if (projectIds.length === 1 && rCtrl.projectVersions.length > 1) {
            this.initializeOneProjectMenu(that, rCtrl);
        } else {
            this.initializeHidingDropDownMenu(that, projectIds, rCtrl);
        }
    }

    /**
     * Инициализация дропдауна с версиями проектов, когда проект всего один и есть только его версии
     * @param that
     * @param rCtrl
     */
    initializeOneProjectMenu(that, rCtrl) {
        that.dropDownMenu = rCtrl.projectVersions.map((it, index) => {
            const item = {};
            item.projectVersions = rCtrl.projectVersions[index];
            item.count = 1;
            item.projectOrVersionId = item.projectVersions.id;
            return item;
        });
    }

    /**
     * Инициализация дропдауна с версиями проектов, когда есть несколько проектов
     */
    initializeHidingDropDownMenu(that, projectIds, rCtrl) {
        that.dropDownMenu = projectIds.map(projectId => {
            const item = {};
            item.projectVersions = rCtrl.projectVersions.filter(item => projectId === item.projectId);
            item.count = item.projectVersions.length;
            if (item.count === 1) {
                item.projectOrVersionId = item.projectVersions.map(version => version.id);
            } else {
                item.projectOrVersionId = projectId;
            }
            return item;
        });
    }

// переход по менюшке сверху
    selectTopLevel(i) {
        if (this.menu.selectedIndex === i) {
            this.menu.selectedIndex = -1;
        } else {
            this.menu.selectedIndex = i;
            if (!this.menus[i].submenu.length) {
                this.menu.activeMenu = this.menus[i];
                this.$state.go(this.menu.activeMenu.url, this.menu.activeMenu.parameters);
            }
        }
    };

    selectTopLevelInternal(i) {
        this.menu.selectedIndex = i;
    }

    isTopLevelSelected(i) {
        return this.menu.selectedIndex === i;
    };

    toggleSidebar() {
        this.menu.visibility = !this.menu.visibility;
    };

    /**
     *
     * @param parentIndex индекс родительского меню или null, если показываем submenu для active menu
     * @param index
     * @returns {boolean}
     */
    isSubmenuSelected(parentIndex, index) {
        return (parentIndex === null || this.isTopLevelSelected(parentIndex)) && this.menu.selectedSubmenuIndex === index;
    };

    isConversationTypeSelected(tag) {
        return this.$stateParams['type'] === tag;
    }

    // инициализировать меню, когда есть для этого данные
    // и назначить обработчики переключения пунктов
    initMenu() {
        if (this.menus) {
            this.$transitions.onSuccess({}, () => {
                this.onStateChange();
            });
            this.menu.activeMenu = this.menus[this.menu.selectedIndex];
            if (this.menu.activeMenu) {
                this.menu.activeMenuTitle = this.menu.activeMenu.name;
            }
        }
    }

    goToProjectVersion(projectVersionId) {
        let params = {...this.$stateParams};
        params["projectVersionId"] = projectVersionId;
        this.$state.go('.', params);
    }

    goToProject(projectVersionId, count) {
        if (count === 1) {
            this.goToProjectVersion(projectVersionId);
        }
    }

    cropProjectName() {
        let projectAndVersion = this.currentProjectVersion.split('.');
        if (projectAndVersion[0].length > 16) {
            this.shortProjectVersionName = this.currentProjectVersion.substr(0, 16) + "..." + projectAndVersion[1] + '.' + projectAndVersion[2];
        }
    }

    onStateChange() {
        let that = this;
        // обновим project version id
        const $stateParams = this.$stateParams;

        this.currentProjectVersion = $stateParams['projectVersionId'];
        let projectVersions = this.$scope.rCtrl.projectVersions;

        this.getModelsStatus();

        if (this.currentProjectVersion) {
            this.cropProjectName();
        }

        // в начало страницы
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        if (!this.currentProjectVersion && this.isExpert && projectVersions.length > 0) {
            // находим первую активную версию
            let activeVersion = projectVersions.find(pv => pv.active);
            let versionIdToGo = activeVersion != null ? activeVersion.id : projectVersions[0].id;
            this.goToProjectVersion(versionIdToGo);
            return;
        }

        if (this.isExpert && this.currentProjectVersion) {
            this.checkProjectVersionChannelsAndHideTranscription(projectVersions);
        }

        if (that.menus) {
            let justTopLevel = false;
            let activeMenuFound = false;
            for (let i = 0; i < that.menus.length; i++) {
                let menu = that.menus[i];
                if (that.$state.includes(menu.url)) {
                    if (that.$state.current.name === menu.url) {
                        justTopLevel = true;
                    }
                    that.selectTopLevelInternal(i);
                    that.menu.activeMenu = menu;
                    activeMenuFound = true;
                    break;
                }
            }
            let inSubmenu = false;
            let activeMenu = that.menu.activeMenu;
            if (!activeMenu || !activeMenuFound) {
                // если текущее не нашлось - выберем первое
                that.selectTopLevelInternal(0);
                justTopLevel = true;
                activeMenu = that.menu.activeMenu;
            }

            if (activeMenu && activeMenu.submenu) {
                this.menu.activeMenuTitle = activeMenu.name;

                for (let i = 0; i < activeMenu.submenu.length; i++) {
                    let obj = activeMenu.submenu[i];
                    if (that.$state.includes(activeMenu.url + "." + obj.url)) {
                        inSubmenu = true;
                        that.menu.selectedSubmenuIndex = i;
                        that.menu.visibility = false;
                        if (obj.name) {
                            this.menu.activeMenuTitle = obj.name;
                        }
                        break;
                    }
                }
            }

            if (activeMenu) {
                if (!inSubmenu && justTopLevel) {
                    // отправляемся на первый стейт в списке
                    let defaultState = this.menu.activeMenu.url;
                    if (this.menu.activeMenu.submenu.length) {
                        defaultState = defaultState + "." + this.menu.activeMenu.submenu[0].url;
                    }
                    this.$state.go(defaultState);
                }
            } else {
                let defaultState = this.menus[0].url + "." + this.menus[0].submenu[0].url;
                this.$state.go(defaultState);
            }

            if (that.$state.includes("conversations.online")) {
                let type = that.$stateParams["type"];
                if (type) {
                    const filter = that.FILTERS[type];
                    if (filter) {
                        that.menu.activeMenuTitle = filter.label;
                    }
                }
            }
        }
    }

    /**
     * Скрыть подпункт меню "Транскрибирование" если версия проекта без голосового канала
     * @param projectVersions - список версий проекта
     */
    checkProjectVersionChannelsAndHideTranscription(projectVersions) {

        let foundProjectVersion = projectVersions.find(projectVersion => projectVersion.id === this.currentProjectVersion);
        let currentProjectVersionChannels;
        if (foundProjectVersion) {
            currentProjectVersionChannels = foundProjectVersion.channels;
        }
        if (!currentProjectVersionChannels) {
            // не нашли типы каналов -> сделаем запрос
            this.restAngular.one(`${urls.va.attributes}/channels`).get().then((channels) => {
                currentProjectVersionChannels = channels;
                this.hideIfNecessary(currentProjectVersionChannels)
            });
            return;
        }
        this.hideIfNecessary(currentProjectVersionChannels);
    }

    hideIfNecessary(currentProjectVersionChannels) {
        if (currentProjectVersionChannels) {
            let menu = this.menus.find(menu => menu.name === "Асессинг");
            let transcription = menu.submenu.find(submenu => submenu.name === "Транскрибирование");
            transcription.hidden = (currentProjectVersionChannels.voice == null && currentProjectVersionChannels.assistant == null);
        }
    }

    togglemenu() {
        this.menu.visibility = !this.menu.visibility;
    }

    showTimeoutStatusForm() {
        this.timeoutStatusForm.show = true;
    }

    hideTimeoutStatusForm() {
        this.timeoutStatusForm.data = {};
        this.timeoutStatusForm.show = false;
    }

    setStatus(statusName) {
        this.accountService.setStatus({status: statusName});
        this.hideTimeoutStatusForm();
    }

    setStatusWithTimeout(statusName) {
        this.accountService.setStatus({status: statusName, timeout: this.timeoutStatusForm.data.timeout});
        this.hideTimeoutStatusForm();
    }

    logout() {
        this.accountService.logout();
    }

    async clearDbCache(event, element) {
        if (!(event.ctrlKey || event.metaKey)) {
            return;
        }
        await this.$http.get('/util/clear_db_cache');
        alert('OK');
    }

    leftPad(num, q) {
        let s = num + "";
        let b = q - s.length;
        for (let i = 0; i < b; i++) {
            s = "0" + s;
        }
        return s;
    }

    menuParams(menuItem) {
        return Object.assign({}, menuItem.parameters, {projectVersionId: this.$stateParams['projectVersionId']});
    }

}

MenuController.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "AccountService", "WebSocketService", "NotificationManager", "Restangular", "SnackbarService", "$http", "TabService", "$q", "$transitions"];
