/**
 * Сервис с кастомизациями
 */
import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
    'ru': {
        weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    }
    // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
    language = 'ru';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

    constructor(private _i18n: I18n) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }

    getMonthShortName(month: number): string {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

export class CustomDateDelimiter {

    delimiter: string;

    constructor(delimiter: string) {
        this.delimiter = delimiter;
    }

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.delimiter);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? (date.day < 10 ? "0" : "") + date.day + this.delimiter + (date.month < 10 ? "0" : "") + date.month + this.delimiter + date.year : '';
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomForwardSlashYMDDateParserFormatter extends NgbDateParserFormatter {

    readonly delimiter: CustomDateDelimiter = new CustomDateDelimiter('/');

    parse(value: string): NgbDateStruct | null {
        return this.delimiter.parse(value);
    }

    // dd/mm/yyyy
    format(date: NgbDateStruct | null): string {
        return this.delimiter.format(date);
    }
}

@Injectable()
export class CustomDashDMYDateParserFormatter extends NgbDateParserFormatter {

    readonly delimiter: CustomDateDelimiter = new CustomDateDelimiter('-');

    parse(value: string): NgbDateStruct | null {
        return this.delimiter.parse(value);
    }

    // dd/mm/yyyy
    format(date: NgbDateStruct | null): string {
        return this.delimiter.format(date);
    }
}


