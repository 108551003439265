import {Component, Input, OnInit} from "@angular/core";
import {TextDataAsrSettings, TextDataGroup} from "../../text-data/text-data.model";
import {Form} from "../../base/base-details.component";
import {LevitanVendor} from "../../../../../data/va/Voice";
import {SettingsService} from "../settings.service";
import {ExtractorService} from "../../extractor/extractor.service";


@Component({
    selector: 'settings-asr',
    template: require('./settings-asr.component.html'),
    styles: [require('../details/settings-edit.component.less')]
})
export class SettingsAsrComponent implements OnInit{

    @Input()
    form: Form<TextDataGroup>;

    @Input()
    access: boolean;

    @Input()
    grammarName: string;

    @Input()
    vendorName: string;

    @Input()
    asrFieldName: string = "";

    @Input()
    vendors: LevitanVendor[];

    @Input()
    isOpenAdvancedSettings: boolean = false;

    @Input()
    bargeInWords: boolean = false;

    defaultBargeInWords: string;

    grammars: string[];

    constructor(public dataService: SettingsService, private extractorService: ExtractorService) {
    }

    async ngOnInit(): Promise<void>  {
        await this.loadGrammars(false);
        this.defaultBargeInWords = await this.dataService.getBargeInWords();

        this.form.object.asrSettings.bargeInWords = this.form.object.asrSettings.bargeInWords === undefined ?
            this.defaultBargeInWords : this.form.object.asrSettings.bargeInWords;
    }

    /**
     * Загрузить грамматики
     *
     * @param reload сбросить текущий выбор
     */
    async loadGrammars(reload: boolean): Promise<void> {
        if (!this.asrSettings.vendor) {
            // вендор не выбран
            return;
        }
        // загружаем список грамматик
        this.grammars = await this.extractorService.getGrammars(this.asrSettings.vendor);
        if (reload) {
            // сбрасываем текущий выбор
            this.asrSettings.grammar = null;
        }
        if (this.asrSettings.grammar) {
            // грамматика уже выбрана
            return;
        }
        if (this.isAnswerSettings() && this.isCrt()) {
            // грамматика подтверждения для ЦРТ
            this.asrSettings.grammar = 'yes_no.srgs.xml'
            return;
        }
        // выбираем первую в списке
        this.asrSettings.grammar = this.grammars[0];
    }

    /**
     * Вендор ли ЦРТ
     */
    isCrt(): boolean {
        return this.asrSettings.vendor == 'CRT_VOICE_KEY' || this.asrSettings.vendor == 'CRT_VOICE_NAVIGATOR';
    }

    /**
     * Выбранный вендор
     */
    get asrSettings(): TextDataAsrSettings {
        return this.form.object[this.asrFieldName];
    }

    /**
     * Настройки ли это подтверждения, а не общие
     */
    isAnswerSettings(): boolean {
        return this.asrFieldName.startsWith("answer");
    }

}