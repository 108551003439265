import {EventEmitter, Injectable} from "@angular/core";
import {Dialog} from "../dialog/model/dialog.model";
import {ChatForm} from "./chat-panel/chat-panel.component";
import {Candidate} from "./prompter/prompter.model";
import {DialogChangeData} from "./chat.model";


@Injectable({
    providedIn: 'root',
})
export class ChatControlService {
    public closeDialog : EventEmitter<any> = new EventEmitter<any>();
    public onControlSend: EventEmitter<any> = new EventEmitter<any>();
    public onDialogChange: EventEmitter<DialogChangeData> = new EventEmitter<DialogChangeData>();
    public finishEdit: EventEmitter<void> = new EventEmitter<void>();
    public candidate: EventEmitter<Candidate> = new EventEmitter<Candidate>();
    public form: EventEmitter<ChatForm> = new EventEmitter<ChatForm>();

    /**
     * У трубочки клиент или оператор
     */
    public operator: boolean = false;

    /**
     * Блокировка инпута при отправке
     */
    public inputDisabled: boolean = false;

    /**
     * Текущий диалог
     */
    public dialog: Dialog = undefined;

    constructor() {
    }
}