import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSlider} from '@angular/material/slider';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {BaseAudioPlayerFunctions} from "./base-audio-player-components";
import {Track} from "./track.model";
import {AudioPlayerService} from "./audio-player.service";
import {skipWhile, switchMap} from "rxjs/operators";
import {EMPTY, fromEvent} from "rxjs";
import {va} from "../../../../../../js/workplace/urls";

@Component({
    selector: 'erudite-advanced-audio-player',
    template: require('./erudite-advanced-audio-player.component.html'),
    styles: [require('./erudite-audio-player.component.less')]
})
export class EruditeAdvancedAudioPlayerComponent extends BaseAudioPlayerFunctions implements OnInit {

    displayedColumns: string[] = ['title', 'status'];
    timeLineDuration: MatSlider;

    dataSource = new MatTableDataSource<Track>();

    paginator: MatPaginator;

    playlistData: Track[];

    @Input()
    displayTitle = true;

    @Input()
    displayPlaylist = true;

    @Input()
    pageSizeOptions = [10, 20, 30];

    @Input()
    expanded = true;

    @Input()
    autoPlay = false;

    @Input()
    repeatPlaylist = false;

    @Input()
    displayVolumeControls = true;

    @Input()
    showCloseButton: boolean = false;

    @Output()
    onClose = new EventEmitter();

    @Output()
    onPlaylistEnded = new EventEmitter();

    playlistTrack: Track[];

    constructor(private playlistService: AudioPlayerService) {
        super();
    }

    close() {
        if (this.showCloseButton) {
            this.playlistTrack = null;
            this.playlistService.clearPlaylist();
            this.onClose.emit(null);
        }
    }

    ngOnInit() {
        this.setDataSourceAttributes();
        this.bindPlayerEvent();
        fromEvent(this.player.nativeElement, 'ended')
            .pipe(skipWhile(() => this.player.nativeElement.currentTime == 0))
            .subscribe(() => this.nextSong());

        this.playlistService.setPlaylist(this.playlistData);
        this.playlistService.getSubjectCurrentTrack().subscribe((playlistTrack) => {
            this.playlistTrack = playlistTrack;
        });
        this.player.nativeElement.currentTime = 0;
        this.playlistService.init();
        if (this.autoPlay) {
            super.play();
        }
    }

    @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    setDataSourceAttributes() {
        let index = 1;
        if (this.playlistData) {
            this.playlistData.forEach(data => {
                data.index = index++;
            });
            this.dataSource = new MatTableDataSource<Track>(this.playlistData);
            this.dataSource.paginator = this.paginator;
        }
    }

    nextSong(): void {
        if (this.displayPlaylist == true
            && (((this.playlistService.indexSong + 1) % this.paginator.pageSize) === 0
                || (this.playlistService.indexSong + 1) === this.paginator.length)) {
            if (this.paginator.hasNextPage()) {
                this.paginator.nextPage();
            } else if (!this.paginator.hasNextPage()) {
                this.paginator.firstPage();
            }
        }
        let songIndex = this.playlistService.getIndexSong();
        let playlist = this.playlistService.getPlaylist();
        if ((songIndex + 1) >= playlist.length && !this.repeatPlaylist) {
            this.player.nativeElement.pause();
            this.player.nativeElement.currentTime = 0;
            this.onPlaylistEnded.emit();
            return;
        }
        this.currentTime = 0;
        this.duration = 0.01;
        this.playlistService.nextSong();
        this.play();
    }

    previousSong(): void {
        this.currentTime = 0;
        this.duration = 0.01;
        if (!this.checkIfSongHasStartedSinceAtleastTwoSeconds()) {
            if (this.displayPlaylist == true
                && (((this.playlistService.indexSong) % this.paginator.pageSize) === 0
                    || (this.playlistService.indexSong) === 0)) {
                if (this.paginator.hasPreviousPage()) {
                    this.paginator.previousPage();
                } else if (!this.paginator.hasPreviousPage()) {
                    this.paginator.lastPage();
                }
            }
            this.playlistService.previousSong();
        } else {
            this.resetSong();
        }
        this.play();
    }

    resetSong(): void {
        this.player.nativeElement.src = this.playlistTrack[1].file;
    }

    selectTrack(index: number): void {
        this.playlistService.selectATrack(index);
        setTimeout(() => {
            this.player.nativeElement.play();
        }, 0);
    }

    checkIfSongHasStartedSinceAtleastTwoSeconds(): boolean {
        return this.player.nativeElement.currentTime > 2;
    }

    @Input()
    set playlist(playlist: Track[]) {
        this.playlistData = playlist;
        this.ngOnInit();
    }
}