import {Component} from "../../../backports";

// noinspection TypeScriptUMDGlobal
/**
 * Компонента для отображения списка диалогов в табличном виде на вкладке "Статистика"
 */
@Component({
    bindings: {
        rows: "<",
        totalCount: "<",
        maxCount: "<"
    },
    templateUrl: "/pages/workplace/va/common/components/analysisGrid.html",
})
export default class VaAnalysisGridComponent {

    private static readonly GRID_STATE_STORAGE_KEY = "eruditeStatGridState";
    private static readonly VISIBLE_IDS_STORAGE_KEY = "eruditeStatFilteredRows";

    static $inject = ["$scope", "$element", "uiGridConstants"];
    private readonly $scope: ng.IScope;

    private $element: ng.IRootElementService;

    /**
     * Настройки ui-grid'a
     */
    private gridOptions: uiGrid.IGridOptions;

    /**
     * Константы ui-grid'a
     */
    private gridConstants: uiGrid.IUiGridConstants;

    /**
     * API ui-grid'a
     */
    private gridApi: uiGrid.IGridApi;

    /**
     * Строки, выбранные из таблицы
     */
    private rows: any[];

    constructor($scope: ng.IScope, $element: ng.IRootElementService, uiGridConstants: uiGrid.IUiGridConstants) {
        this.$scope = $scope;
        this.gridConstants = uiGridConstants;
        this.$element = $element;
        this.initGrid();

        // без этого таблица не скроллится колесом
        $scope.$watch('isDisplayed', () => {
          const viewport = $element.find('.ui-grid-render-container');

          ['touchstart', 'touchmove', 'touchend','keydown', 'wheel', 'mousewheel', 'DomMouseScroll', 'MozMousePixelScroll'].forEach(function (eventName) {
            viewport.unbind(eventName);
          });
        });
    }

    /**
     * Восстановить данные грида
     */
    private restoreGridState() {
        setTimeout(() => {
            const saved = localStorage.getItem(VaAnalysisGridComponent.GRID_STATE_STORAGE_KEY);
            if (!saved) {
                return;
            }
            const state = JSON.parse(saved);
            this.gridApi.saveState.restore(this.$scope, state);
        });
    }

    /**
     * Стереть сохраненное состояние грида
     */
    public static clearSavedGridState() {
        localStorage.removeItem(VaAnalysisGridComponent.GRID_STATE_STORAGE_KEY);
        localStorage.removeItem(VaAnalysisGridComponent.VISIBLE_IDS_STORAGE_KEY);
    }

    /**
     * Сохранить состояние грида
     */
    private saveGridState() {
        const value = this.gridApi.saveState.save();
        localStorage.setItem(VaAnalysisGridComponent.GRID_STATE_STORAGE_KEY, JSON.stringify(value));
    }

    /**
     * Сохранение видимого списка диалогов
     */
    private saveVisibleIds(): void {
        const dialogIds = [];
        for (let i = 0; i < this.gridOptions.data.length; i++) {
            const dialog = this.gridOptions.data[i];
            if (this.gridApi.grid.getRow(dialog)?.visible) {
                dialogIds.push(dialog.key.id);
            }
        }
        localStorage.setItem(VaAnalysisGridComponent.VISIBLE_IDS_STORAGE_KEY, JSON.stringify(dialogIds));
    }

    /**
     * @return id видимых диалогов
     */
    public static getVisibleIds(): number[] {
        const saved = localStorage.getItem(VaAnalysisGridComponent.VISIBLE_IDS_STORAGE_KEY);
        return saved ? JSON.parse(saved) : [];
    }

    /**
     * Изменение индекса просматриваемого диалога, сдвигаем сохраненный номер текущей страницы, если надо
     */
    public static onPositionChange(position: number) {
        const saved = localStorage.getItem(VaAnalysisGridComponent.GRID_STATE_STORAGE_KEY);
        if (!saved) {
            return;
        }
        const state = JSON.parse(saved);
        const pageSize = state?.pagination?.paginationPageSize;
        if (pageSize) {
            state.pagination.paginationCurrentPage = Math.floor(position / pageSize) + 1;
        }
        localStorage.setItem(VaAnalysisGridComponent.GRID_STATE_STORAGE_KEY, JSON.stringify(state));
    }

    /**
     * Добавить сохранение состояния грида при его изменении
     */
    private addChangeStateCallbacks() {
        this.gridApi.core.on.columnVisibilityChanged(this.$scope, () => this.saveGridState());
        this.gridApi.core.on.scrollEnd(this.$scope, () => this.saveGridState());
        this.gridApi.pagination.on.paginationChanged(this.$scope, () => this.saveGridState());
        this.gridApi.colMovable.on.columnPositionChanged(this.$scope, () => this.saveGridState());
        this.gridApi.colResizable.on.columnSizeChanged(this.$scope, () => this.saveGridState());
        this.gridApi.core.on.filterChanged(this.$scope, () => this.saveGridState());
        this.gridApi.core.on.sortChanged(this.$scope, () => this.saveGridState());
        this.gridApi.core.on.rowsVisibleChanged(this.$scope, () => this.saveVisibleIds());
    }

    /**
     * Ициализация грида
     */
    private initGrid(): void {
        this.gridOptions = {
            // данные, по которым строится отчет
            data: this.rows,
            // доступ к grid api (для обработки предвыбранной тематики)
            onRegisterApi: (gridApi) => {
                this.gridApi = gridApi;
                // восстанавливаем состояние отображения, если есть сохраненное
                this.restoreGridState();
                // сохраняем стейт при изменении параметров отображения
                this.addChangeStateCallbacks();
                // список видимых id
                this.saveVisibleIds();
            },
            virtualizationThreshold: 1000,
            // можно изменять размер колонок
            enableColumnResizing: true,
            // Можно фильтровать
            enableFiltering: true,
            // Достуно меню грида справа сверху в углу
            enableGridMenu: true,
            // Показыаать футер грида (с общим числом элементов)
            showGridFooter: true,
            // Быстрый просмотр (т. к. данные ее меняются динамически)
            fastWatch: true,
            // Минимальное кол-во отображаемых строк
            minRowsToShow: 5,
            // Scope-provider для доступа к методам контроллера
            appScopeProvider: this,
            // Настройки постраничного перехода (варианты для отображения в дропдауне)
            paginationPageSizes: [5, 10, 25, 50],
            // Настройки размера страницы по умолчанию
            paginationPageSize: 10,
            // Включить вертикальный скроллбар, когда он нужен
            enableVerticalScrollbar: this.gridConstants.scrollbars.WHEN_NEEDED,
            // Включить горизонтальный скроллбар, когда он нужен
            enableHorizontalScrollbar: this.gridConstants.scrollbars.WHEN_NEEDED,
            // описание колонок
            columnDefs: [
                {
                    name: 'ФИО эксперта',
                    field: 'expertName',
                    visible: false,
                    width: 300,
                    cellTemplate: require("raw-loader!./gridCellTemplates/expertName.html")
                },
                {
                    name: 'Время начала',
                    field: 'startTime',
                    type: 'date',
                    width: 150,
                    cellTemplate: require("raw-loader!./gridCellTemplates/startTimeGridCell.html")
                },
                {
                    name: 'Время окончания',
                    field: 'finishTime',
                    type: 'date',
                    width: 160,
                    cellTemplate: require("raw-loader!./gridCellTemplates/finishTimeGridCell.html")
                },
                {
                    name: 'Перевод в ТП',
                    field: 'rerouteToOperator',
                    type: 'date',
                    width: 140,
                    visible: false,
                    cellTemplate: require("raw-loader!./gridCellTemplates/rerouteToOperatorGridCell.html"),
                    filter: {
                        condition: (searchTerm, cellValue, row) => {
                            let value: string = row.entity.rerouteToOperator ? "Да" : "Нет";
                            return value.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1;
                        }
                    }
                },
                {
                    name: 'Тематика',
                    field: 'tagsInDialog',
                    width: 300,
                    cellTemplate: require("raw-loader!./gridCellTemplates/tagsInDialogGridCell.html")
                },
                {
                    name: 'Первая реплика',
                    field: 'firstReply',
                    width: 400,
                    cellTemplate: require("raw-loader!./gridCellTemplates/firstReplyGridCell.html")
                },
                {
                    name: 'Типы ошибок',
                    field: 'correctionTypes',
                    width: 260,
                    visible: false,
                    cellTemplate: require("raw-loader!./gridCellTemplates/correctionTypesGridCell.html")
                },
                {
                    name: 'Успешные решения',
                    field: 'hadSuccessfulSolutions',
                    width: 175,
                    visible: false,
                    cellTemplate: require("raw-loader!./gridCellTemplates/hadSuccessfulSolutionsGridCell.html"),
                    filter: {
                        condition: (searchTerm, cellValue, row) => {
                            let value: string = row.entity.hadSuccessfulSolutions ? "Да" : "Нет";
                            return value.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1;
                        }
                    }
                }
                ,
                {
                    name: 'Версия',
                    field: 'key.projectVersionId',
                    width: 130,
                    cellTemplate: require("raw-loader!./gridCellTemplates/projectVersionIdGridCell.html")
                },
                {
                    name: 'Статус',
                    field: 'checkState',
                    width: 130,
                    visible: false,
                    cellTemplate: require("raw-loader!./gridCellTemplates/checkStateIdGridCell.html")
                },
                {
                    name: 'Оценка супер-эксперта',
                    field: 'superExpertRating',
                    visible: false,
                    width: 210,
                    cellTemplate: require("raw-loader!./gridCellTemplates/superExpertRating.html")
                },
                {
                    name: 'Тональность диалога',
                    field: 'sentiment',
                    visible: false,
                    width: 210,
                    cellTemplate: require("raw-loader!./gridCellTemplates/sentiment.html")
                }
            ],
        };
    }
}
