import {UserAct, VAAct, VASimpleModuleAnswer} from "../../../../data/va/SimpleModuleAnswer";
import {VaTag} from "../../../../data/va/Tag";
import {Formulation} from "../../../../data/va/Formulation";
import {VATagAnswer} from "../../../../data/va/TagAnswer";
import {VaAttribute} from "../../../../data/va/Attribute";
import {ExtractedValue} from "./extracted-value.model";
import {VAProcedure, VAScript, VaScriptEdge, VaScriptNode} from "../../../../data/va/Script";
import {DialogCorrectionDTO} from "./correction.model";
import {AudioRecord} from "../../../../data/va/AudioRecord";

export class Reply {
    id: number;
    projectVersionId: string;
    text: string;
    t: Date;
    dialogId: number;
    currentProcessedTagId: number;
    numberInDialog: number;
    repeatAmount: number;
    nestedTag: boolean;
    checkpointNodeIds: string;
    checkpointEdgeIds: string;
    externalDialogLink: string;
    externalDialogProjectVersionId: string;
    externalDialogId: string;
    irrelevant: boolean;
    isUser: boolean;
    script: VAScript;
    enteredProcedures: VAProcedure[];
    exitedProcedures: VAProcedure[];
    prompter: boolean;
    scriptCalls: ScriptCall[]

    // VARobotReply
    vaAct: VAAct;
    buttons: VAReplyButton[];
    commandParams: string;
    commandParamsDescription: string;
    nodeId: string;
    procedureCallNodeIds: string;
    edgeIds: string;
    rerouteReason: RerouteReason;
    formulationId: number;
    tagId: number;
    answerId: number;
    attrId: number;
    attributeValue: string;
    rerouteStatus: ChatRobotCommand;
    tag: VaTag;
    formulation: Formulation;
    sma: VASimpleModuleAnswer;
    answer: VATagAnswer;
    attribute: VaAttribute;
    enteredProcedureIds: string;
    checkpointNodes: VaScriptNode[];
    checkpointEdges: VaScriptEdge[];
    userReplyId: number;
    isInterrupted: boolean;

    //VAUserReply
    userAct: UserAct;
    confidence: number;
    classifiedTagId: number;
    interrupted: number;
    tagState: TagState;
    extractedValues: ExtractedValue[];
    resetedValues: ExtractedValue[];
    externalMessageId: string;
    needTryExtract: boolean;
    errorCode?: string

    //VACommandUserReply
    apiTagId: number;
    audioFile: DialogFile;
    attachments?: DialogFile[];
    prompterConfidence: number;
}

export interface DialogFile {
    id: string;
    fileName: string;
    url: string
}

export interface ScriptCall {
    id: number;
    dialogId: number;
    replyId: number;
    scriptId: number;
    projectVersionId: string;
    error: string;
    callDate: number;
    scriptName: string;
    scriptDeleted: boolean;
}

export class VAReplyButton {
    number: number;
    key: string;
    value: string;
}

export class RerouteReason {
    name: RerouteReasonEnum;
    title: string;
}

export enum RerouteReasonEnum {
    UNABLE_TO_PROCESS = "UNABLE_TO_PROCESS",
    DISCONTENT = "DISCONTENT",
    REROUTE_TO_OPERATOR_BY_REQUEST = "REROUTE_TO_OPERATOR_BY_REQUEST",
    REROUTE_TO_OPERATOR_BY_SCENARIO = "REROUTE_TO_OPERATOR_BY_SCENARIO",
    REQUEST_NOT_PROCESSED_BY_ROBOT = "REQUEST_NOT_PROCESSED_BY_ROBOT",
    TAG_WITH_NO_SCENARIO = "TAG_WITH_NO_SCENARIO",
    INTERNAL_ERROR = "INTERNAL_ERROR",
    SCRIPT_EXECUTION_FAILED = 'SCRIPT_EXECUTION_FAILED',
    TAG_UNSUCCESS_MAX_LIMIT_EXCEEDED = "TAG_UNSUCCESS_MAX_LIMIT_EXCEEDED",
    MAX_TAG_UNKNOWN_LIMIT_EXCEEDED = "MAX_TAG_UNKNOWN_LIMIT_EXCEEDED",
    MAX_TAG_CONFIRM_LIMIT_EXCEEDED = "MAX_TAG_CONFIRM_LIMIT_EXCEEDED",
    REQUESTS_COUNT_LIMIT_EXCEEDED = "REQUESTS_COUNT_LIMIT_EXCEEDED",
    ATTRIBUTE_REQUESTS_COUNT_LIMIT_EXCEEDED = "ATTRIBUTE_REQUESTS_COUNT_LIMIT_EXCEEDED",
    ACT_COUNT_LIMIT_EXCEEDED = "ACT_COUNT_LIMIT_EXCEEDED",
    UNKNOWN_ACT_COUNT_LIMIT_EXCEEDED = "UNKNOWN_ACT_COUNT_LIMIT_EXCEEDED",
    RECEIVED_ATTACH = "RECEIVED_ATTACH"
}

export enum ChatRobotCommand {
    REROUTE = "REROUTE", REROUTE_STRICT = "REROUTE_STRICT", RESOLVE = "RESOLVE", REPLY = "REPLY"
}

export enum TagState {
    SURE = "SURE",
    UNSURE = "UNSURE",
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED"
}

export class ReplyView {
    reply: Reply;
    wordOrAudio: WordOrAudio[];
    missedExtractedValues: DialogCorrectionDTO[];
    initExtractedValues: ExtractedValue[];
    resetedValues: ExtractedValue[];
    extractedValues: ExtractedValueWithCorrection[];
    actCorrection: DialogCorrectionDTO;
    wrongTagCorrection: DialogCorrectionDTO;
    missedTagCorrection: DialogCorrectionDTO;
    checkpointNodes: VaScriptNode[];
    checkpointEdges: VaScriptEdge[];
    correctionAllowed: boolean;
    lastRobotReply: boolean;
    outgoingAttachments?: [{ name: string, url: string }];
    orderedValues: OrderableExtractedValue[]
    userActTitle: string;
    prompterConfidence: number;

    constructor(reply: Reply, correctionAllowed: boolean) {
        this.reply = reply;
        this.correctionAllowed = correctionAllowed;
    }
}

/**
 * Контейнер для всех видов DTO извлеченных значений, чтобы сложить их в один список и отобразить в исходном порядке
 */
export class OrderableExtractedValue {

    constructor(public extractedValue: ExtractedValueWithCorrection,
                public resetedValue?: ExtractedValue,
                public missedExtractedValue?: DialogCorrectionDTO,
                public enteredProcedure?: VAProcedure) {
    }

    /**
     * id для упорядочивания
     */
    get id(): number {
        if (this.extractedValue) {
            // упорядочиваем по id извлеченного значения
            return this.extractedValue.extractedValue.id;
        }
        if (this.resetedValue) {
            // упорядочиваем по id сброса, если есть, или проcто по id сбрасывающей реплики
            return this.resetedValue.resetId ? this.resetedValue.resetId : this.resetedValue.id;
        }
        if (this.missedExtractedValue) {
            // упорядочиваем по id примера в корректировке
            return this.missedExtractedValue.extractionAttempt.id
        }
        if (this.enteredProcedure) {
            // упорядочиваем по id вхождения в процедуру, если есть, или просто по id процедуры
            return this.enteredProcedure.enterId ? this.enteredProcedure.enterId : this.enteredProcedure.key.id;
        }
        return 0;
    }
}

export class WordOrAudio {
    text: string;
    audio: AudioRecord;

    constructor(text: string, audio: AudioRecord) {
        this.text = text;
        this.audio = audio;
    }
}


export class ExtractedValueWithCorrection {
    extractedValue: ExtractedValue;
    wrongValueCorrection: DialogCorrectionDTO;
    excessValueCorrection: DialogCorrectionDTO;

    constructor(extractedValue: ExtractedValue, wrongValueCorrection: DialogCorrectionDTO, excessValueCorrection: DialogCorrectionDTO) {
        this.extractedValue = extractedValue;
        this.wrongValueCorrection = wrongValueCorrection;
        this.excessValueCorrection = excessValueCorrection;
    }
}
