/**
 * Created by semyon on 30.06.17.
 */
import * as urls from "../../../../../js/workplace/urls.js";
import restangular from "restangular";

export default class VaTrendDetectionWrapperController {
    private foundDialogs: any[];
    private totalDocs: number;
    private baseUrl: string;
    private restangular: restangular.IService;
    private state: ng.ui.IStateService;
    private filter: any;
    private form: { isLoading: boolean; hideSuccess: boolean };
    private hasNextPage: boolean;
    private hasPrevPage: boolean;

    constructor(restangular: restangular.IService, state: ng.ui.IStateService) {
        this.foundDialogs = [];
        this.totalDocs = 0;
        this.baseUrl = urls.va.trend_detection;
        this.restangular = restangular;
        this.state = state;

        this.filter = {};


        // форма для отображения результатов загрузки
        this.form = {
            isLoading: false,
            // не нужно выводить успех операции
            hideSuccess: true
        };

        this.hasNextPage = false;
        this.hasPrevPage = false;
    }

    setFilter(filter, totalItemsCount) {
        this.filter = filter;
        const pageNumber = this.filter.pageNumber;
        const pageSize = this.filter.pageSize;
        this.hasNextPage = pageNumber > 0;
        this.hasPrevPage = pageNumber * pageSize < totalItemsCount;
    }
}


VaTrendDetectionWrapperController.$inject = ["Restangular", "$state"];