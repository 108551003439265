import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ExtractableValuesSerDTO} from "../../../../../../data/va/Dkb";
import {AttributeValue, Macro} from "../../object/Macro";


@Component({
    selector: 'macro-dkb',
    template: require('./macro-dkb.component.html'),
    styles: [require('./macro-dkb.component.less'), require('../dialog.component.less')]
})

export class MacroDkbComponent implements OnInit {

    /**
     * Условия для дкб
     */
    extractedValues: ExtractableValuesSerDTO[] = [];

    constructor(public dialogRef: MatDialogRef<MacroDkbComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { macro: Macro }) {

    }

    ngOnInit(): void {
    }

    onDeny(): void {
        this.dialogRef.close();
    }

    onSave(): void {
        if (this.extractedValues) {
            if (!this.data.macro.attributeValues) {
                this.data.macro.attributeValues = [];
            }
            if (!this.data.macro.conditions) {
                this.data.macro.conditions = {};
            }
            this.extractedValues.forEach(value => {
                this.data.macro.conditions[value.info.key.id + ""] = value.value.key.id;
                this.data.macro.attributeValues.push(new AttributeValue(value.info, value.value));
            })
        }
        this.dialogRef.close(this.data.macro);
    }

}


