import {BaseFormController} from "../../BaseFormController";
import {SubnetUtils} from "./SubnetUtils"
import common from "../../../common";

// контроллер страницы редактирования региона
export default class SecurityEditController extends BaseFormController {

    constructor(Restangular, $scope, $stateParams, $state, TitleService) {
        super($scope, $state);
        var that = this;
        this.initFormMode({"create": {title: "Правила (IP-адреса)"}});

        that.restAngular = Restangular;

        // контроллер списка регионов
        that.securityController = $scope.$parent.ctrl;

        that.securityUrl = that.securityController.baseUrl;

        that.form.rule = {
            ips: []
        };

        that.form.subnetUtil = null;

        that.roles = null;

        common.getAccess(that.restAngular, that.securityController.baseUrl,  that);
        that.restAngular.one(that.securityUrl + "/roles").get()
            .then(function success(data) {
                that.roles = Restangular.stripRestangular(data);
            }, function error(data) {
            }).finally(function () {
            that.setLoading(false);
        });


        // получаем из URL id продукта
        if ($stateParams['role']) {
            that.setModeEdit();
            that.setLoading(true);
            TitleService.setTitle("Редактирование");
            // по ID загружаем категорию
            let role = $stateParams['role'];
            that.restAngular.one(that.securityUrl, role).get()
                .then(function success(data) {
                    that.form.rule = data;
                }, function error(data) {
                }).finally(function () {
                that.setLoading(false);
            });

        } else {
            // нет id - создание продукта
            that.setModeCreate();
            TitleService.setTitle("Новое правило доступа")
        }
    }

    addIp() {
        let ip = this.form.newIp;
        this.form.rule.ips.push({ip: ip});
        this.form.newIp = "";
        this.form.subnetUtil = null;
    }

    removeIp(id) {
        this.form.rule.ips.splice(id, 1);
    }

    onNewIpChanging() {
        const address = this.form.newIp;
        if (address.indexOf("/") === -1) {
            let reg = new RegExp(SubnetUtils.IP_ADDRESS + "$");
            if (reg.test(address)) {
                this.form.subnetUtil = {
                    low: address,
                    high: address
                };
            } else {
                this.form.subnetUtil = null;
            }
            return;
        }

        let util = null;
        try {
            util = new SubnetUtils(address);
        } catch (err) {
            this.form.subnetUtil = null;
            return;
        }
        const info = util.getInfo();
        if (util.netmask === -1) {
            // это ip/32
            this.form.subnetUtil = {
                low: info.getNetworkAddress(),
                high: info.getNetworkAddress()
            };
        } else {
            this.form.subnetUtil = info.getLowAndHigh();
        }
    }

    save() {
        var that = this;
        // сохранить или обновить
        var rulesRest = that.restAngular.all(that.securityUrl);
        var saveFunc = that.mode.new ? rulesRest.post : rulesRest.patch;


        if (this.form.rule.role && this.form.rule.role.name === "ADMINISTRATOR") {
            // редактируются адреса для админки. Нельзя дать заблокировать самого себя
            if (!this.checkIp()) {
                this.form.errors["#main"] = "Ваш текущий IP-адрес не в списке";
                return;
            }
        }


        saveFunc(this.form.rule)
            .then(function success(data) {
                that.handleSuccess(data);
                that.form.category = data;


                if (that.mode.new) {
                    that.setModeEdit();
                    //если новый объект, добавляем в список объектов в мастере
                    that.securityController.addNewRule(that.form.rule);
                    //меняем текущее состояние, добавляя в него id объекта
                    that.$state.go('settings.security.edit', {role: data.role.name, notifySuccess: true})
                } else {
                    // заменяем в мастере объект, который редактировали
                    that.securityController.replaceRule(that.form.rule);
                }
            }, function error(data) {
                that.handleError(data);
            });
    }

    checkIp() {
        let ips = this.form.rule.ips;
        let ip = this.securityController.ip;

        let currentUserFoundInRules = false;
        for (let newAddr of ips) {
            try {
                const util = new SubnetUtils(newAddr.ip);
                const info = util.getInfo();
                let res = info.isInRange(ip);
                if (res) {
                    currentUserFoundInRules = true;
                    break;
                }
            } catch (err) {
                console.log(err)
            }
        }
        return currentUserFoundInRules;
    }

    remove() {
        var that = this;
        that.restAngular.one(that.securityUrl, that.form.rule.role.name).remove().then(function success(data) {
            that.handleSuccess(data);
            // меняем текущее состояние контроллера
            that.$state.go('settings.security', {}, {reload: true});

        }, function error(data) {
            that.handleError(data);
        });
    }

    close() {
        let that = this;
        // меняем текущее состояние контроллера
        that.$state.go('settings.security', {}, {reload: true});
    }
}

SecurityEditController.$inject = ["Restangular", "$scope", "$stateParams", "$state", "TitleService"];
