import {Component} from "@angular/core";
import {CompositeKey} from "../../../../../data/va/Common";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {BaseDetailsComponent} from "../../base/base-details.component";
import {Title} from "@angular/platform-browser";
import {CustomizationScriptMasterComponent} from "../master/customization-script-master.component";
import {CustomizationScriptService} from "../customization-script.service";
import {
    CustomizationScript,
    CustomizationScriptExecuteParams,
    CustomizationScriptExecutionPoint,
    CustomizationScriptLanguage
} from "../../../../../data/va/CustomizationScript";
import {VaAttribute} from "../../../../../data/va/Attribute";
import * as ace from 'brace';
// для подсветки синтаксиса
import 'brace/mode/python';
import 'brace/mode/javascript';
// цветовая тема
import 'brace/theme/xcode';
import 'brace/ext/language_tools';
import {AttributeService} from "../../attribute/attribute.service";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";

@Component({
    selector: 'customization-script-edit',
    template: require('./customization-script-edit.component.html'),
    styles: [require('./customization-script-edit.component.less')]
})
export class CustomizationScriptEditComponent extends BaseDetailsComponent<CustomizationScript, CompositeKey<number>> {

    objectIdKey = 'scriptId';

    languages: CustomizationScriptLanguage[] = CustomizationScriptLanguage.VALUES;
    executionPoints: CustomizationScriptExecutionPoint[] = CustomizationScriptExecutionPoint.VALUES;

    /**
     * Опции редактора
     */
    options: any = {
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        minLines: 17,
        maxLines: 50
    };
    scriptMode: string;

    /**
     * Автокомплит в редакторе
     */
    private completer = {
        getCompletions: function (editor, session, pos, prefix, callback) {
            callback(null, CustomizationScriptExecutionPoint.VALUES.map(function (point) {
                return {
                    caption: point.name + " (" + point.text + ")",
                    value: point.name,
                    meta: "enum"
                };
            }));

        }
    };
    attributes: VaAttribute[] = [];

    /**
     * Данные для тестирования скрипта
     */
    testParams: CustomizationScriptExecuteParams = new CustomizationScriptExecuteParams();
    testResult: string;

    constructor(stateService: StateService,
                master: CustomizationScriptMasterComponent,
                httpClient: HttpClient,
                dataService: CustomizationScriptService,
                titleService: Title,
                notificationService: NotificationService,
                private attributeService: AttributeService) {
        super(stateService, master, httpClient, dataService, titleService, notificationService);
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        const langTools = ace.acequire('ace/ext/language_tools');
        langTools.addCompleter(this.completer);
        this.loadAttributes();
    }

    onObjectLoaded() {
        this.form.object = new CustomizationScript(this.form.object);
        if (!this.form.object.script) {
            this.form.object.script = CustomizationScript.BASE_JS_SCRIPT;
        }
        this.onChangePoints();
        this.fillLanguage();
        if (this.form.object.attribute && !this.testParams.attribute) {
            // проставим выбранный для теста атрибут, если есть привязка скрипта к атрибуту
            this.testParams.attribute = this.form.object.attribute;
            this.testParams.entityName = this.form.object.attribute.name;
        }
    }

    async finishSave(savePromise, isNew: boolean, preserveState: boolean): Promise<void> {
        await super.finishSave(savePromise, isNew, preserveState);
        this.form.object = new CustomizationScript(this.form.object);
    }

    generateFormObject(): CustomizationScript {
        this.scriptMode = "python";
        const script = new CustomizationScript();
        script.environmentVersion = 2;
        script.language = CustomizationScriptLanguage.PYTHON;
        script.script = CustomizationScript.BASE_PYTHON_SCRIPT;
        return script;
    }

    get entityTitle(): string {
        return this.form.object.name;
    }

    get removeConfirmMessage(): string {
        return `Вы уверены, что хотите удалить скрипт?`;
    }

    /**
     * По загрузке заполняем доп-поля для обработки языка
     */
    fillLanguage() {
        switch (this.form.object.language.name) {
            case CustomizationScriptLanguage.JS.name:
                this.form.object.language = CustomizationScriptLanguage.JS;
                this.scriptMode = "javascript";
                break;
            case CustomizationScriptLanguage.PYTHON.name:
                this.form.object.language = CustomizationScriptLanguage.PYTHON;
                this.scriptMode = "python";
                break;
            default:
                throw new Error("Unsupported script type");
        }
    }

    /**
     * Обработчик изменения языка
     */
    onChangeLanguage() {
        this.fillLanguage();
        this.insertTemplateScript();
    }

    /**
     * Обработчик изменения точек вызова
     */
    onChangePoints(): void {
        if (!this.form.object.viewPoints) {
            return;
        }
        // на фронте заполняем viewPoints
        this.form.object.points = this.form.object.viewPoints.map(point => point.name);
        if (this.testParams.point) {
            //  если в данных для тестирования проставлена точка во3ыва, то проверяем , есть ли она в списке
            if (this.form.object.points.indexOf(this.testParams.point) >= 0) {
                return;
            }
        }
        if (this.form.object.points.indexOf(CustomizationScriptExecutionPoint.BEFORE_ATTR_QUESTION.name) >= 0) {
            // иначе проставляем BEFORE_ATTR_QUESTION, если он есть в списке
            this.testParams.point = CustomizationScriptExecutionPoint.BEFORE_ATTR_QUESTION.name;
        } else {
            this.testParams.point = this.form.object.points[0];
        }
    }

    /**
     * Кнопка "вставить шаблон"
     */
    insertTemplateScript(): void {
        switch (this.form.object.language.name) {
            case CustomizationScriptLanguage.JS.name:
                this.form.object.environmentVersion = 2;
                this.form.object.script = CustomizationScript.BASE_JS_SCRIPT;
                break;
            case CustomizationScriptLanguage.PYTHON.name:
                this.form.object.environmentVersion = 2;
                if (this.form.object.module) {
                    this.form.object.script = CustomizationScript.MODULE_PYTHON_SCRIPT;
                } else {
                    this.form.object.script = CustomizationScript.BASE_PYTHON_SCRIPT;
                }
                break;
            default:
                throw new Error("Unsupported script type");
        }
    }

    async save(preserveState?: boolean): Promise<void> {
        this.insertEmptyLine();
        await super.save(preserveState);
    }

    /**
     * Отправка скрипта на тестирование
     */
    test() {
        this.insertEmptyLine();

        // @ts-ignore
        const object = angular.copy(this.form.object);

        const attributes = {};
        this.testParams.attributeKeyValueList
            .filter(entry => entry.key)
            .forEach(entry => {
                // группируем в массив значения с одним ключом, но если значение одно оставляем не-массив
                let value = attributes[entry.key];
                if (value) {
                    if (!Array.isArray(value)) {
                        // был один элемент - делаем массив
                        value = [value];
                    }
                    // добавляем элемент
                    value.push(entry.value);
                } else {
                    // один элемент
                    value = entry.value;
                }
                // проставляем значение
                return attributes[entry.key] = value;
            });
        this.testParams.attributeValues = attributes;

        this.httpClient.post(`${this.dataService.baseUrl}/test`, {
            script: object,
            params: this.testParams
        }).toPromise()
            .then((res: string) => {
                this.testResult = res;
            }, (error) => {
                const errors = error.errors;
                if (errors && errors[0]) {
                    this.testResult = errors[0].message;
                }
            });
    }

    /**
     * Вставить пустую строку в начале, если надо (иначе не скомпилируется)
     */
    private insertEmptyLine() {
        if (this.form.object.script?.startsWith('# coding=utf-8')) {
            this.form.object.script = '\n' + this.form.object.script;
        }
    }

    /**
     * Загрузка атрибутов
     */
    loadAttributes(): void {
        this.attributeService.findAll().then((attributes: VaAttribute[]) => {
            this.attributes = attributes;
            const objectId = this.stateService.params[this.objectIdKey];
            if (objectId) {
                const firstUsingAttribute = attributes
                    .find(attribute => attribute.extractor && attribute.extractor.extractorScriptName === objectId);
                if (firstUsingAttribute) {
                    this.testParams.entityName = firstUsingAttribute.name;
                }
            }
        })
    }

    /**
     * Добавить ключ значение в тестовые данные по атрибутам
     */
    addAttributeValue(): void {
        this.testParams.attributeKeyValueList.push({key: ``, value: ``});
    }

    /**
     * Удалить ключ значение из тестовых данных по атрибутам
     */
    removeAttributeValue(idx: number): void {
        this.testParams.attributeKeyValueList.splice(idx, 1);
    }

    /**
     *  Заголовок точки вызова
     */
    getPointTitle(pointName: string): string {
        return CustomizationScriptExecutionPoint.getByName(pointName).title;
    }

}