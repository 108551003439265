import {Component} from "../../../../backports";
import restangular from "restangular";
import * as urls from "../../../../../js/workplace/urls";

@Component({
    bindings: {
        // id модели, для которой выводится отчет
        modelId: "<",
        // функция для перехода к отчету по репликам для выбранной группы
        goToTag: "&"
    },
    templateUrl: "/pages/workplace/va/common/components/grid/tagGrid.html"
})
export default class VaTagReplyGridComponent {

    static $inject = ["Restangular", "uiGridConstants"];

    private restangular: restangular.IService;

    /**
     * Данные для отчета по тематикам
     */
    private tagGrid: TagGridItem[];

    private gridConst: any;

    /**
     * Настройки для отображения отчета
     */
    private gridOptions: any;

    /**
     * ID модели
     */
    private modelId: number;

    /**
     * Идет ли загрузка данных?
     */
    private loading: boolean = true;

    /**
     * Функция для перехода к отчету по репликам с предзаданной группой
     */
    private goToTag: any;

    constructor(restangular: restangular.IService, gridConst) {
        this.restangular = restangular;
        this.gridConst = gridConst;
        // загружаем данные для отчета
        this.loadData();
    }

    /**
     * Функция для раскраски от красного (чем меньше процентов) до белого (чем больше процентов)
     * @param percent проценты от 0 до 100
     */
    private redColor(percent: number): string {
        // внутри квадратичное сглаживание, для более плавного изменения цвета
        return 'hsl(' + 0 + ', 100%, ' + (50 + (Math.sqrt(percent) * 10 / 2)) + '%, 0.3)';
    }

    /**
     * Загрузка данных для отчета
     */
    private loadData() {
        this.restangular.one(urls.va.tag_message_distribution + "/" + this.modelId + "/tagGrid")
            .getList()
            .then((replyGrid: TagGridItem[]) => {
                // сохраняем данные
                this.tagGrid = this.restangular.stripRestangular(replyGrid);
                // переключаем флаг загрузки
                this.loading = false;
                // инициализируем параметры грида
                this.initGrid();
            });
    }

    /**
     * Ициализация грида
     */
    private initGrid() {
        this.gridOptions = {
            // данные, по которым строится отчет
            data: this.tagGrid,
            // можно изменять размер колонок
            enableColumnResizing: true,
            // можно фильтровать
            enableFiltering: true,
            // достуно меню грида справа сверху в углу
            enableGridMenu: true,
            // показыаать футер грида (с общим числом элементов)
            showGridFooter: true,
            // быстрый просмотр (т. к. данные ее меняются динамически)
            fastWatch: true,
            // минимальное кол-во отображаемых строк
            minRowsToShow: 25,
            // scope-provider для доступа к методам контроллера
            appScopeProvider: this,
            // описание колонок
            columnDefs: [
                {
                    name: 'Всего реплик',
                    field: 'total',
                    filters: [{
                        condition: this.gridConst.filter.LESS_THAN_OR_EQUAL,
                        placeholder: 'Меньше чем'
                    }]
                },
                {
                    name: 'Тестовых реплик',
                    field: 'testing',
                    filters: [{
                        condition: this.gridConst.filter.LESS_THAN_OR_EQUAL,
                        placeholder: 'Меньше чем'
                    }]
                },
                {
                    name: 'Правильных классификаций (%)',
                    field: 'testingCorrect',
                    cellTemplate: require("raw-loader!./testingCorrect.html"),
                    filters: [
                        {
                            condition: this.gridConst.filter.LESS_THAN_OR_EQUAL,
                            placeholder: 'Меньше чем'
                        }]
                },
                {
                    name: 'Неверная тематика (%)',
                    field: 'wrongTag',
                    cellTemplate: require("raw-loader!./wrongTag.html"),
                    filters: [
                        {
                            condition: this.gridConst.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: 'Больше чем'
                        }]
                },
                {
                    name: 'Низкая уверенность',
                    field: 'lowConfidence',
                    cellTemplate: require("raw-loader!./lowConfidence.html"),
                    filters: [
                        {
                            condition: this.gridConst.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: 'Больше чем'
                        }]

                },
                {
                    name: '% с ошибками и соседями в ближайшей тематике',
                    cellTemplate: require("raw-loader!./toNearestTagOffset.html"),
                    field: 'toNearestTagOffset',
                    filters: [
                        {
                            condition: this.gridConst.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: 'Больше чем'
                        }]
                },
                {
                    name: '% с ошибками и соседями в разных тематиках',
                    cellTemplate: require("raw-loader!./toOtherTagsOffset.html"),
                    field: 'toOtherTagsOffset',
                    filters: [
                        {
                            condition: this.gridConst.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: 'Больше чем'
                        }]
                },
                {
                    name: 'Тематика',
                    field: 'tagName',
                    cellTemplate: require("raw-loader!./tag.html"),
                },
                {
                    name: 'Ближайшяя тематика',
                    field: 'nearestTagName',
                    cellTemplate: require("raw-loader!./nearestTag.html")
                }
            ],
        };
    }
}

interface TagGridItem {
    tagId: number;
    tagName: string;
    total: number,
    testingCorrectColor: string;
    testing: number;
    testingCorrect: number;
    wrongTag: number;
    lowConfidence: number,
    toNearestTagOffset: number;
    toOtherTagsOffset: number;
    nearestTagId?: number,
    nearestTagName?: string;
}