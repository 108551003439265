/**
 * Created by semyon on 27.09.16.
 */

export default class SnackbarService {

    constructor($interval) {
        var that = this;

        this.snacks = [];

        this.onDismiss = function (snack) {
            var idx = this.snacks.indexOf(snack);
            if (idx !== -1) {
                this.snacks.splice(idx, 1);
            }
        };

    }

    addSnack(text, fn, btnText) {
        if (this.snacks.length > 2) {
            this.snacks.splice(0, 1);
        }
        this.snacks.push({text: text, fn: fn, btnText: btnText});
        console.log("Show new snack");
    }

}

SnackbarService.$inject = ["$interval"];
