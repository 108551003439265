/**
 * Директива, которая разрешает ввод значений только из диапазона
 */

export default function MinMaxNumberDirective() {
    return {
        require: 'ngModel',
        link: (scope, element, attr, ngModelCtrl) => {
            const min = attr["min"];
            const max = attr["max"];
            function fromUser(value: number) {
                if (value != null) {
                    let transformedInput = value;

                    if (transformedInput < min) {
                        transformedInput = min;
                    }

                    if (transformedInput > max) {
                        transformedInput = max;
                    }

                    if (transformedInput !== value) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }

            ngModelCtrl.$parsers.push(fromUser);
        }
    };
}