import {StateService} from "@uirouter/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {FileSaverService} from "ngx-filesaver";
import {Component, ViewEncapsulation} from "@angular/core";
import {TagIdsEnum, VaTag} from "../../../../../data/va/Tag";
import {BaseDetailsComponent, DetailsMode} from "../../base/base-details.component";
import {CompositeKey} from "../../../../../data/va/Common";
import {TagService} from "../tag.service";
import {TagsComponent} from "../list/tags.component";
import {VAScript} from "../../../../../data/va/Script";
import {VaMessageIf} from "../../../../../data/va/Message";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {ChangePushEvent} from "../../base/base-data.service";
import WebSocketService from "../../../../../services/WebSocketService";
import {AnswerService} from "../../answer/answer.service";
import {VATagAnswer} from "../../../../../data/va/TagAnswer";


@Component({
    selector: 'tag-edit',
    template: require('./tag-edit.component.html'),
    styles: [require('./tag-edit.component.less')],
    encapsulation: ViewEncapsulation.None
})
export class TagEditComponent extends BaseDetailsComponent<VaTag, CompositeKey<number>> {

    projectVersionId: string;
    objectIdKey: string = 'tagId';
    defaultQuery: string;
    autocompleteMessage: VaMessageIf = new VaMessageIf();
    casesMessage: string;
    groupStatsPreview: any;
    mode: DetailsMode;
    script: VAScript;
    tags: VaTag[] = [];
    businessTags: VaTag[] = [];
    tag: VaTag;
    questionText: string = "Вы уверены, что хотите удалить тематику? Все дочерние тематики будут так же удалены.";
    scriptEditLink: string = 'robot.script_edit';
    answers: VATagAnswer[];
    private subscriptionId: string;

    /**
     * True - нажали создать ответ через формулировку
     * False - нажали создать ответ через возврат к сценарию
     */
    answerCreationSource: boolean;

    constructor(protected stateService: StateService,
                protected tagService: TagService,
                protected httpClient: HttpClient,
                protected fileService: FileSaverService,
                private webSocketService: WebSocketService,
                protected master: TagsComponent,
                protected titleService: Title,
                protected answerService: AnswerService,
                protected notificationService: NotificationService) {

        super(stateService, master, httpClient, tagService, titleService, notificationService);

        this.objId = stateService.params['tagId'];
        this.projectVersionId = stateService.params['projectVersionId'];
        this.defaultQuery = stateService.params['query'];
        if (Number(this.objId) == TagIdsEnum.SMALL_TALK_ID_ROOT) {
            this.stateService.go('robot.tags');
        }

        if (Number(this.objId) > TagIdsEnum.SMALL_TALK_ID_ROOT && Number(this.objId) < TagIdsEnum.SMALL_TALK_ID_UPPER_LIMIT) {
            this.stateService.go('robot.tags.act', {tagId: this.objId, query: stateService.params['query']});
        }

        if (!this.objId) {
            titleService.setTitle("Новая тематика");
        }

        // подписка на изменения ответа
        this.subscriptionId = this.webSocketService.subscribeOnEvents({
            eventType: "VA_ENTITY_CHANGE",
            fn: (event) => {
                const changeEvent: ChangePushEvent = JSON.parse(event.details);
                if (this.projectVersionId === changeEvent.projectVersionId && changeEvent.className === "VATagAnswer") {
                    this.loadAnswers(items => {
                        const answer: VATagAnswer = changeEvent.object;
                        if (answer && this.answerCreationSource != null) {
                            // если доступен ответ, который только что создан и мы нажимали на кнопку создать - сразу вставим его в поле
                            const newAnswer = items.find(item => {
                                return item.key.id === answer.key.id
                            });
                            if (newAnswer) {
                                if (this.answerCreationSource) {
                                    this.form.object.confirmQuestionId = newAnswer.key.id;
                                    this.answerCreationSource = null;
                                } else {
                                    this.form.object.returnAnswerId = newAnswer.key.id;
                                    this.answerCreationSource = null;
                                }
                            }
                        }
                    }).then(() => {
                    });
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        this.isLoading = true;
        await this.loadAccount();

        if (this.account.isJunior() && this.isModeNew()) {
            // младшему эксперту нельзя создавать тематику
            this.stateService.go('robot.tags');
        }

        await this.loadAnswers();

        if (this.objId) {
            this.loadTags();
            this.loadScripts();
            this.loadGroupStatsPreview();
        } else {
            this.setModeCreate();
            // тематики кандидаты в родители новой тематики
            this.tagService.loadAllTags().then(value => {
                this.tags = value;
                this.businessTags = this.tags
                    .filter(tag => tag.key.id < TagIdsEnum.SMALL_TALK_ID_ROOT || tag.key.id > TagIdsEnum.SMALL_TALK_ID_UPPER_LIMIT);
            });
        }

        // загружаем сообщение для автоподстановки
        if (!this.isModeNew()) {
            this.getAutoCompleteMessage();
        }
        this.isLoading = false;
    }

    isModeNew() {
        return this.mode === DetailsMode.NEW;
    }

    openAnswersTab(answerCreationSource: boolean) {
        this.answerCreationSource = answerCreationSource;
        let url = this.stateService.href("robot.answer.edit", {answerId: null, mode: 'EDIT'});
        window.open(url, '_blank');
    }

    async loadAnswers(callback?: (items: VATagAnswer[]) => void) {
        let params = {};
        if (!this.isModeNew()) {
            params["forVATagAnswer"] = this.objId;
        }
        this.answers = await this.answerService.findAll(params);

        if (callback) {
            callback(this.answers);
        }
    }

    /**
     * Загрузка сообщения для автоподстановки
     */
    getAutoCompleteMessage() {
        this.tagService.getAutocompleteMessage(this.objId)
            .then(data => {
                this.autocompleteMessage = data;
            });
    }

    /**
     * Загрузка связанных тематик (по url: .../${id}/tags) и сохранение его в поле .tags
     */
    loadTags() {
        this.tagService.loadLinkedTags(this.objId).then(value => {
            this.tags = value;
            this.businessTags = this.tags
                .filter(tag => tag.key.id < TagIdsEnum.SMALL_TALK_ID_ROOT || tag.key.id > TagIdsEnum.SMALL_TALK_ID_UPPER_LIMIT);
        });
    }

    /**
     * Загрузка скрипта по тематике
     */
    loadScripts() {
        this.tagService.loadScript(this.objId).then(value => {
            this.script = value;
        });
    }

    /**
     * Получние превью групповой статистики
     */
    loadGroupStatsPreview() {
        this.tagService.getGroupStatsPreview(this.objId).then(data => {
            this.groupStatsPreview = data;
        }, (error) => {
        });
    }

    /**
     * Возвращает словесное представление логической переменной
     */
    yesForTrue(val) {
        return val ? "Да" : "Нет";
    }

    /**
     * Проверяет есть ли дочерние тематики у текущей
     */
    hasChildren() {
        if (this.master.tagsTree) {
            let node = this.master.getTagById(this.objId);
            if (node) {
                return node.children;
            }
        }
        return false;
    }

    /**
     * функция выгрузки статистики сообщений с вложенными тематиками
     */
    groupStats() {
        this.tagService.sendGroupStats(this.objId).then((response: HttpResponse<ArrayBuffer>) => {
            const file = new Blob([response.body], {type: "application/vnd.ms-excel"});
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let disposition = response.headers.get('Content-Disposition');
            let matches = filenameRegex.exec(disposition);
            let fileName;
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            } else {
                fileName = "group_stats.xlsx";
            }
            this.fileService.save(file, fileName);
        });
    }

    /**
     * Сгенерировать кейсы прохождения сценария
     */
    generateCases(event) {
        const loadingMessage = 'Генерация кейсов...';
        if (!event.ctrlKey && !event.metaKey || !this.script || loadingMessage === this.casesMessage) {
            // нужно кликать с зажатым контролом и чтобы у группы был сценарий и загрузка не уже в процессе
            return;
        }
        // без информации о прохождении сценария и расцветки, если не зажат шифт
        const boringFormat = !event.shiftKey;

        this.casesMessage = loadingMessage;
        this.tagService.sendCases(this.script.key.id.toString(), this.projectVersionId, boringFormat).then((data) => {
            const file = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const tagName = this.form.object.text.replace(/[^A-Za-zА-Яа-яЁё0-9]/, "_").replace(/_{2,}/, "_");
            const fileName = `Кейсы_${this.projectVersionId}_${tagName}_${this.objId}.xlsx`;
            this.fileService.save(file, fileName);
            this.casesMessage = '';
        });
    }

    get entityTitle(): string {
        return this.form.object ? this.form.object.text : "Новая тематика";
    }

    /**
     * надпись на форме в зависимости от режима
     */
    get formTitle(): string {
        switch (this.mode) {
            case DetailsMode.SHOW:
            case DetailsMode.EDIT:
                return "Редактирование";
            case DetailsMode.NEW:
                return "Создание тематики";

        }
    }

    /**
     * Сохранить новую тематику и сообщение для автокомплита
     */
    async save(preserveState?: boolean): Promise<void> {
        const isRootTag = this.form.object.parentId == this.tagService.FICTIVE_ROOT_TAG_ID;
        if (isRootTag) {
            // если это корневая тематика, то временно вернем id родителя в 0, чтобы -1 не пошло в бд
            this.form.object.parentId = 0;
        }

        if (this.autocompleteMessage.text) {
            await this.tagService.sendAutocompleteMessage(this.objId, this.autocompleteMessage).then(data => {
                this.autocompleteMessage = data;
            });
        } else {
            if (this.autocompleteMessage?.key) {
                await this.tagService.remove(this.autocompleteMessage.key.id).then(() => {
                    this.autocompleteMessage = new VaMessageIf();
                });
            }
        }
        await super.save(preserveState);

        if (isRootTag) {
            // вернем обратно фиктивного родителя корневой тематики
            this.form.object.parentId = this.tagService.FICTIVE_ROOT_TAG_ID;
        }
    }

    /**
     * Инициирование объекта тематики
     */
    generateFormObject(): VaTag {
        let tag = new VaTag();
        tag.text = "";
        tag.isTopic = false;
        tag.useInTrain = true;
        tag.access = true;
        return tag
    }

    get removeConfirmMessage(): string {
        return "Вы уверены, что хотите удалить тематику? Все дочерние тематики будут так же удалены.";
    }

    checkRepeatOnlyLastReply(nestable: boolean): void {
        if (!nestable) {
            this.form.object.repeatOnlyLastReply = false;
            this.form.object.returnAnswerId = null;
        }
    }

    get allowJuniorUpdate() {
        return !this.account || !this.account?.isJunior();
    }
}
