import {NgModule} from "@angular/core";
import {ExpertAndDateComponent} from "./expert-and-date/expert-and-date.component";
import {CommonModule} from "@angular/common";


@NgModule({
    declarations: [
        ExpertAndDateComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ExpertAndDateComponent
    ]
})
export class ExpertModule {

}