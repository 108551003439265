import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'erudite-icon',
    template: `
        <svg-icon *ngIf="name != null"
                  [class]="styleClass"
                  [name]="name">
        </svg-icon>
    `,
    styles: [require('./erudite-icon.component.less')],
    encapsulation: ViewEncapsulation.None,
})
export class EruditeIconComponent {

    /**
     * Код иконки
     */
    @Input()
    name: string;

    /**
     * Дополнительный класс для управления стилями иконки
     */
    @Input()
    styleClass: string;

    constructor() {

    }

}
