import {Component, Input, OnInit} from "@angular/core";
import {Extractor, ValueOption} from "../../../../data/va/Extractor";
import {StateService} from "@uirouter/core";
import {ExtractorService} from "../../va/extractor/extractor.service";
import {EditableForm, EditableItemData} from "../editable-list/editable-list.model";

@Component({
    selector: 'extractor-properties',
    template: require('./extractor-properties.component.html'),
    styles: [require('./extractor-properties.component.less')]
})
export class ExtractorPropertiesComponent implements OnInit {

    @Input()
    extractor: Extractor;

    editableItemData: EditableItemData = new EditableItemData("", false, false, false, false, true, false, "fa-cubes");

    /**
     * Форма для условий
     */
    form: EditableForm<ValueOption> = new EditableForm(new ValueOption(null));
    valueOptions: ValueOption[] = [];


    constructor(private stateService: StateService, private extractorService: ExtractorService) {
    }

    ngOnInit() {
        const valueOpts = this.extractor.valueOptions;
        if (valueOpts) {
            this.valueOptions = valueOpts.map(value => new ValueOption(value));
        }
    }

    filterAttempts(valueOption: any): void {
        this.extractorService.filterAttempts(this.stateService, valueOption as ValueOption);
    }

    /**
     * Описание обязательных полей адреса
     */
    necessaryFields() {
        let result;
        if (this.extractor.houseNecessary) {
            result = 'Дом';
        }
        if (this.extractor.apartmentNecessary) {
            result += ', Квартира';
        }
        return result || '-';

    }
}