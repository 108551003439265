import {Component} from "@angular/core";
import {StateService} from "@uirouter/core";
import {AttributeMasterComponent} from "../master/attribute-master.component";
import {DetailsMode} from "../../base/base-details.component";

@Component({
    selector: 'attribute-detail',
    template: require('./attribute-detail.component.html')
})
export class AttributeDetailComponent {
    private mode: DetailsMode;

    constructor(stateService: StateService, master: AttributeMasterComponent) {
        this.mode = stateService.params["mode"];
    }

}