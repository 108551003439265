import {BaseMasterComponent} from "../../base/base-master.component";
import {CompositeKey, SortField} from "../../../../../data/va/Common";
import {VaTag} from "../../../../../data/va/Tag";
import {Title} from "@angular/platform-browser";
import {StateService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import WebSocketService from "../../../../../services/WebSocketService";
import {NotificationService} from "../../../common/snackbar/notification/notification.service";
import {SourceSelectorService} from "./source-selector.service";
import {Component} from "@angular/core";

@Component({
    selector: 'tb-source-select',
    template: require('./source-selector.component.html'),
    styles: [require('./source-selector.component.less')]
})
export class SourceSelectorComponent extends BaseMasterComponent<VaTag, CompositeKey<number>> {

    constructor(protected stateService: StateService,
                protected httpClient: HttpClient,
                protected titleService: Title,
                protected webSocketService: WebSocketService,
                protected dataService: SourceSelectorService,
                protected notificationService: NotificationService) {
        super(titleService, stateService, dataService, httpClient, notificationService);
        this.sortFields.push(new SortField("Название", "text"))
    }

    async loadList() {
        this.setLoading(true);
        let dialogsWithOperators = new VaTag();
        dialogsWithOperators.text = "Диалоги с операторами";
        this.objects.push(dialogsWithOperators);
        let sources = await this.dataService.getSources();
        sources.forEach(source => this.objects.push(source));
        this.setLoading(false);
    }

}