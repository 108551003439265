function AssessorSelectorDirective() {
    return {
        restrict: "EA",
        scope: {
            selectorCollection: "=",
            selectorModel: "=",
            selectorFieldName: "=",
            disabled: "="
        },
        templateUrl: "/pages/workplace/selectors/assessorSelector.html",
        controller: function () {
        },
        bindToController: true,
        controllerAs: "aCtrl"
    };

}

AssessorSelectorDirective.$inject = [];
export default AssessorSelectorDirective;