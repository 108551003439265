import {Component, ContentChild, ElementRef, Host, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {BaseMasterComponent} from "../base-master.component";


@Component({
    selector: 'master',
    template: require('./master-wrapper.component.html'),
    styles: [require('./master-wrapper.component.less')]
})
export class MasterWrapperComponent {

    @ContentChild('masterBody', {static: false})
    masterBody: TemplateRef<ElementRef>;

    @Input()
    md: number = 6;

    @Input()
    xs: number = 12;

    @Input()
    contentClass;

    public masterComponent: BaseMasterComponent<any, any>;

    constructor(private _view: ViewContainerRef) {
        this.masterComponent = this._view['_view'].component;
    }

    detailSize(masterSize: number): number {
        let sz = 12 - masterSize;
        return sz === 0 ? 12 : sz;
    }

}