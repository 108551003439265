import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList, SimpleChanges
} from "@angular/core";
import {TabComponent} from "./tab.component";


@Component({
    selector: 'tabs',
    template: require('./tabs.component.html'),
    styles: [require('./tab.component.less')]
})
export class TabsComponent implements AfterContentInit, OnChanges {

    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

    @Output()
    private onTabChanged: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    formulationObject;

    // contentChildren are set
    ngAfterContentInit() {
        // get all active tabs
        let activeTabs = this.tabs.filter((tab) => tab.active);

        // if there is no active tab set, activate the first
        if (activeTabs.length === 0) {
            this.selectTab(this.tabs.first);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.formulationObject && this.tabs) {
            this.selectTab(this.tabs.first);
        }
    }

    selectTab(tab: TabComponent): void {
        // deactivate all tabs
        this.tabs.toArray().forEach(tab => tab.active = false);

        // activate the tab the user has clicked on.
        tab.active = true;

        this.onTabChanged.emit(tab.id);
    }
}
