import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import * as joint from '@naumen/rappid';
import {dia, g, ui} from '@naumen/rappid';
import {StepLabelEntity, StepLabelType, VAScript} from "../../../data/va/Script";
import JointAdapter from "./JointAdapter";
import "@naumen/rappid/build/rappid.css";
import {NavigatorLinkView} from "./Navigator";
import {fromEvent} from "rxjs";
import {map} from 'rxjs/operators';
import {CdkDragDrop, CdkDragEnter} from "@angular/cdk/drag-drop";
import EruditeLink from "./link-view/EruditeLink";
import {StateService, TransitionService} from "@uirouter/core";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material";
import {ClickMode, EruditeElement} from "./element-view/EruditeElement";
import {ExportScenario} from "./dialog/export-scenario/export-scenario.component";
import RectangleElement from "./element-view/rectangle/RectangleElement";
import ScriptKeyboardController from "./controller/ScriptKeyboardController";
import {ceil, ChangeSpacingParams, ClickAction, GRID_SIZE} from "./ScriptBuilderUtils";
import {logString} from "./controller/ScriptChangesController";
import ValidationController from "./controller/ValidationController";
import {mxgraph, mxgraphFactory} from "ts-mxgraph";
import StatisticsService from "../../../services/StatisticsService";
import LinkView = dia.LinkView;
import Point = dia.Point;
import Size = dia.Size;
import ElementView = dia.ElementView;

/**
 * Для undo/redo удаления
 */
window.joint = joint;

@Component({
    selector: 'script-viewer',
    template: require('./script-viewer.component.html')
})
export class ScriptViewerComponent implements OnInit, OnDestroy {

    /**
     * Множитель изменения координат расстояний (1/4 за раз)
     */
    private readonly SPACING_OFFSET_STEP = Math.pow(2, .25);

    @Input()
    public onDisplaySnackBar: EventEmitter<{ message: string, styleClass: string, duration?: number }>;

    @Input()
    public onDismissSnackBar: EventEmitter<void>;
    /**
     * Данные сценария/процедуры или undefined, если это новый сценарий/процедура
     */
    @Input()
    script: VAScript;
    /**
     * Элемент с мини-картой
     */
    @Input()
    private navigatorRef: ElementRef;

    /**
     * Список сущностей для каждого StepLabel'a
     */
    @Input()
    entities: { type: StepLabelType, entities: StepLabelEntity[] }[];

    /**
     * Элемент с билдером
     */
    @ViewChild(`viewer`, {static: true})
    private viewerElement: ElementRef;

    /**
     * Сохранить скрипт
     */
    @Output()
    private saveChange = new EventEmitter<void>();

    /**
     * Элемент, редактируемый в данный момент (кликнутый)
     */
    public nowEditedElement: EruditeElement;

    /**
     * Линк, редактируемый в данный момент (кликнутый)
     */
    public nowEditedLink: EruditeLink;

    /**
     * Окно для скролла (враппер графа)
     */
    public paperScroller: ui.PaperScroller;

    /**
     * Граф-с
     */
    graph: dia.Graph;

    /**
     * Подсвеченный сейчас линк или узел
     */
    private nowHighlightedCell: LinkView | ElementView;

    private snaplines: joint.ui.Snaplines

    /**
     * id кейса прохождения
     */
    public caseId;

    public adapter: JointAdapter;

    public paper: dia.Paper;

    private currentMousePosition: Point;

    private nodeSize: Size;

    public checkpointMode: boolean = false;

    public hasUndo: boolean = false;

    public hasRedo: boolean = false;

    private navigator: ui.Navigator;

    private treeLayoutView: ui.TreeLayoutView;

    private keyboardController: ScriptKeyboardController;

    public readonly projectVersionId;

    constructor(private stateService: StateService,
                private http: HttpClient,
                private dialog: MatDialog,
                private transitionService: TransitionService,
                private statisticsService: StatisticsService) {
        this.projectVersionId = this.stateService.params['projectVersionId'];
    }

    ngOnInit() {
        // загружаем данные и показываем граф
        this.buildGraph(this.script);
        // Следим за курсором, чтобы в момент когда drag сущности закончился узнать точку
        fromEvent(document.body, 'mousemove')
            .pipe(map((event: MouseEvent) => ({x: event.pageX, y: event.pageY})))
            .subscribe((position: Point) => this.currentMousePosition = position);

        // При изменении размера страницы - ресайзим навигатор
        fromEvent(window, 'resize')
            .pipe()
            .subscribe(() => this.configNavigator());
    }

    /**
     * Обработка сброса блока с превью узла на paper
     * @param $event
     */
    onEntityDrop($event: CdkDragDrop<string[]>) {
        // Если курсор над paper'ом
        if ($event.isPointerOverContainer) {
            // Трансферим координаты сброса в координаты paper'a
            let point: g.Point = this.paper.snapToGrid(this.currentMousePosition);
            // Данные сброшенной сущности
            let node = $event.item.data;
            // Создаём узел
            const eruditeElement = this.adapter.createNode(node, point, this.nodeSize);
            if (this.nowHighlightedCell?.model?.isLink()) {
                // дроп на ребро, "вставляем" узел в него
                this.adapter.dropOnLink(eruditeElement, this.nowHighlightedCell.model);
            }
            this.clickOnElement(this.paper.findViewByModel(eruditeElement), null);
        }
    }

    /**
     * Обработка входа блока с превью узла на paper
     * @param $event событие
     */
    onEnter($event: CdkDragEnter<string[]>) {
        // Запоминаем размеры ноды
        let element: Element = document.querySelector('.cdk-drag-preview');
        this.nodeSize = {width: ceil(element.clientWidth, 2 * GRID_SIZE), height: ceil(element.clientHeight, 2 * GRID_SIZE)};
    }

    /**
     * Получить текущий сценарий из графа
     */
    public getCurrentScript(): VAScript {
        return this.adapter.getCurrentScript()
    }

    /**
     * Пометить текущее состояние как неизмененное
     */
    public markUnchanged() {
        this.adapter.changesController.markUnchanged();
    }

    public undo(hotkey: boolean) {
        this.adapter.changesController.undo();
        this.clickStat('undo', hotkey);
    }

    public redo(hotkey: boolean) {
        this.adapter.changesController.redo();
        this.clickStat('redo', hotkey);
    }

    /**
     * Конфигурирование клика и ховера элемента
     * @param paper вью графа
     */
    configElementTools(paper: dia.Paper) {
        paper.on({
            "element:contextmenu": elementView => {
                if (this.checkpointMode) {
                    // в режиме чекпоинта не надо показывать контекстное меню
                    return;
                }

                const element: EruditeElement = elementView.model;
                element.onContextMenu(elementView);
            },
            // клик на элемент
            'element:pointerdown': (elementView: ElementView, event: JQuery.Event) => {
                this.clickOnElement(elementView, event);
            },
            'element:open-dialog-button:pointerdown': (elementView: ElementView, event: JQuery.Event) => {

                if (this.checkpointMode) {
                    // в режиме чекпоинта не надо открывать диалоги
                    return;
                }

                // Перехватываем событие, чтобы не отработал element:pointerdown
                event.stopPropagation();
                const element: RectangleElement = elementView.model as any;
                element.onDialogOpen(element);
            }
        }, this);

    }

    /**
     * Клик элемент или линк
     *
     * @param cell элемент или линк
     */
    public selectCell(cell: dia.Cell) {
        const view = this.paper.findViewByModel(cell);
        if (cell.isElement()) {
            this.clickOnElement(view, null);
        } else if (cell.isLink()) {
            this.clickOnLink(view);
        }
    }

    /**
     * Клик на элемент
     *
     * @param elementView вью элемента
     * @param event
     */
    private clickOnElement(elementView, event: JQuery.Event) {
        this.nowEditedLink = null;
        // запоминаем текущий редактируемый элемент
        this.nowEditedElement = elementView.model;

        const element: EruditeElement = elementView.model;
        const mode: ClickMode = this.checkpointMode ? ClickMode.CHECKPOINT : ClickMode.DEFAULT;
        // В зависимости от mode и элемента, на который нажали - он сам знает что ему делать
        element.onElementClick(elementView, mode);
    }

    /**
     * Клик на линк
     *
     * @param linkView вью линка
     */
    private clickOnLink(linkView: LinkView) {
        this.nowEditedElement = null;
        // запоминаем текущий редактируемый линк
        this.nowEditedLink = linkView.model;

        if (this.checkpointMode) {
            if (linkView.model.constructor.name === EruditeLink.name) {
                // говорим link'y, что на него навели мышь
                let linkElement: EruditeLink = linkView.model as EruditeLink;
                linkElement.onCheckpointToggled(linkView);
            }
            return;
        }

        // удалим все показанные тулы
        this.paper.removeTools();

        const ns = joint.linkTools;
        const toolsView = new joint.dia.ToolsView({
            name: 'link-pointerdown',
            tools: [
                new ns.Vertices(),
                new ns.SourceAnchor(),
                new ns.TargetAnchor(),
                new ns.TargetArrowhead(),
                new ns.Segments,
                new ns.Boundary({padding: 15}),
                new ns.Remove({offset: -20, distance: '10%'})
            ]
        });

        linkView.addTools(toolsView);
    }

    /**
     * Конфигурирование работы с мышкой (зум) и клавиуатурой (хоткеи)
     */
    configControls() {
        this.paper.on('blank:mousewheel', (evt, x, y, delta) => this.onMousewheel(null, evt, x, y, delta), this);
        this.paper.on('cell:mousewheel', this.onMousewheel.bind(this), this);
    }

    /**
     * Конфигурирование клика и ховера линка
     */
    configLinkTools() {
        this.paper.on({
            // мышка над линком
            'element:mouseenter': (elementView: ElementView, event) => this.switchHoverHighlight(elementView, true),

            // мышка над элементом
            'link:mouseenter': (linkView: LinkView, event) => this.switchHoverHighlight(linkView, true),

            // мышка над пустой областью
            'blank:mouseover': () => this.switchHoverHighlight(this.nowHighlightedCell, false),

            // мышка ушла с поля билдера
            'paper:mouseleave': () => this.switchHoverHighlight(this.nowHighlightedCell, false),

            // кликнули на линк
            'link:pointerdown': (linkView: LinkView) => {
                this.clickOnLink(linkView);
            },

            // кликнули на плюс = добавить условие или на шеврон = редактировать условие
            'link:condition-button:pointerdown': (linkView: LinkView, event) => {

                if (this.checkpointMode) {
                    // в режиме чекпоинта не надо открывать диалоги
                    return;
                }

                event.stopPropagation();
                // говорим link'y, что на него навели мышь
                let linkElement: EruditeLink = linkView.model as EruditeLink;
                linkElement.onConditionButton();
            }
        }, this);
    }

    /**
     * Переключить подсветку части скрипта при наведении мыши на линк
     */
    switchHoverHighlight(view: LinkView | ElementView, enable: boolean) {
        if (view == null || !view.model.erudite) {
            // опоздавший эвент или не наш элемент (временный линк), выходим
            return;
        }
        const current: EruditeLink | EruditeElement = view.model;
        const previous = this.nowHighlightedCell;
        if (enable) {
            // при включении подсветки
            if (previous) {
                // если повторный эвент на тот же линк/элемент, выходим
                if (previous.id == current.id) {
                    return;
                } else {
                    // если уже подсвечен другой, выключаем там
                    this.switchHoverHighlight(previous, false);
                }
            }
            // запоминаем линк, для которого включаем подсветку
            this.nowHighlightedCell = view;
        } else {
            // не будет подсветки
            this.nowHighlightedCell = null;
        }

        // выключаем лог изменений
        this.adapter.changesController.disableLog();

        // подсвечиваем заданную и смежные с ней части
        current.linksToHighlight.forEach(link => link.switchHighlight(enable));
        current.elementsToHighlight.forEach(element => element.switchHighlight(enable));

        // включаем лог изменений
        this.adapter.changesController.enableLog()
    }

    /**
     * Конфигурирование мини-карты
     */
    configNavigator() {
        // Обновляем параметры старого навигатора
        if (this.navigator) {
            (this.navigator.options as any).width = this.navigatorRef.nativeElement.clientWidth;
            (this.navigator.options as any).height = this.navigatorRef.nativeElement.clientHeight;
            // Очищаем текущий навигатор и рендерим ещё раз с новыми параметрами высоты и ширины
            $(this.navigator.el).empty();
            this.navigator.render();
            return;
        }
        this.navigator = new joint.ui.Navigator({
            width: this.navigatorRef.nativeElement.clientWidth,
            height: this.navigatorRef.nativeElement.clientHeight,
            paperScroller: this.paperScroller,
            zoom: {
                // @ts-ignore
                grid: 0.2,
                min: 0.2,
                max: 5
            },
            paperOptions: {
                async: true,
                linkView: NavigatorLinkView,
                cellViewNamespace: { /* no other views are accessible in the navigator */}
            }
        });
        // Крепим навигатор к элементу
        $(this.navigatorRef.nativeElement).append(this.navigator.el);
        this.navigator.render();
    }

    onMousewheel(cellView, evt, x, y, delta) {
        if (this.keyboardController.keyboard.isActive('сtrl', evt)) {
            evt.preventDefault();
            this.paperScroller.zoom(delta * 0.2, {min: 0.2, max: 5, grid: 0.2, ox: x, oy: y});
        }
    }

    toggleCheckpointMode() {
        this.adapter.changesController.disableLog();
        this.checkpointMode = !this.checkpointMode;

        if (this.checkpointMode) {
            this.paper.hideTools();
            this.toggleElementsAndLinksControls(false);
        } else {
            this.paper.showTools();
            this.toggleElementsAndLinksControls(true);
        }

        this.paper.setInteractivity(!this.checkpointMode);
        this.adapter.changesController.enableLog();
    }

    /**
     * Функция для того чтобы скрывать/показывать контролы элементов
     */
    private toggleElementsAndLinksControls(show: boolean) {
        // прячем кнопки на элементах
        let elements = (this.graph.getElements() as EruditeElement[]);
        elements.forEach((value: EruditeElement) => {
            value.toggleControlsVisibility(this.paper, show);
        });

        // прячем кнопки на линках
        let links = (this.graph.getLinks() as EruditeLink[]);
        links.forEach((value: EruditeLink) => {
            value.toggleControlsVisibility(this.paper, show);
        });
    }


    exportPng() {
        const paper = this.paper;

        // обратная совместимость со старыми браузерами
        const stylesheet = '.scalable * { vector-effect: non-scaling-stroke }';

        // скроем тулы и контролы, чтобы они не нарисовались
        this.toggleElementsAndLinksControls(false);
        paper.hideTools();

        // получим dataURI нашего графа
        try {
            paper.toPNG((dataURL) => {
                // покажем диалог с картинкой и предложением сохранить
                this.dialog.open(ExportScenario, {
                    data: {image: dataURL, scenarioName: this.script.name}
                });

                // после того как получили картинку можно обратно показывать тулы и контролы
                paper.showTools();
                this.toggleElementsAndLinksControls(true);
            }, {
                padding: 300,
                useComputedStyles: false,
                backgroundColor: '#f8f8f8',
                stylesheet: stylesheet
            });
        } catch (e) {
            if (e.toString().indexOf('raster size exceeded') >= 0) {
                this.showMessage(`Превышен максимальный размер. Для экспорта попробуйте расположить компоненты ${this.stateService.params['type'] == 'procedure' ? 'процедуры' : 'сценария'} компактнее.`);
            }
        }
        // стата
        this.clickStat('picture_download');
    }

    /**
     * Отобразить снекбар с сообщением
     */
    showMessage(message: string, styleClass: 'warning' | 'success' = 'warning') {
        this.onDisplaySnackBar.emit({
            message: message,
            styleClass: styleClass,
            duration: ValidationController.SNACK_DURATION
        });
    }

    /**
     * Построить граф
     */
    private buildGraph(script: VAScript) {
        // адаптер для данных, превращает сущности в данные для джойнта
        this.adapter = new JointAdapter(script, this.stateService, this.onDisplaySnackBar, this.http, this.dialog, this.entities, this.onDismissSnackBar, this.transitionService, this);

        // получим граф
        this.graph = this.adapter.getGraph();

        // виртуальный размер графа (внутри скроллера)
        const config = {
            width: 2000,
            height: 1000
        };

        // вью для графа
        this.paper = new dia.Paper({
            model: this.graph,
            defaultConnector: {
                name: 'jumpover',
                radius: 10 // в триал-версии этого нет
            },
            defaultConnectionPoint: { name: 'boundary', args: { sticky: true, stroke: true }},
            width: config.width,
            height: config.height,
            gridSize: GRID_SIZE,
            drawGrid: true,
            // без этого не работает(л) скроллер ¯\_(ツ)_/¯
            async: true,
            background: {
                color: '#F8F8F8'
            },
            // используем обычный роутер (располагатель линков на графе)
            defaultRouter: {name: 'normal'},
            // запрещаем линки, которые не привязаны к элементам
            linkPinning: false,
            // функция-фабрика для линков
            defaultLink: (sourceElement, magnet) => this.adapter.defaultLink(sourceElement)
        } as dia.Paper.Options);

        this.snaplines = new joint.ui.Snaplines({paper: this.paper});
        this.snaplines.startListening();
        this.adapter.initTools();

        // коллбэк создания нод
        this.adapter.nodeHandler = node => {

        };

        // коллбэк создания линков
        this.adapter.linkHandler = link => {

        };

        // запускаем адаптер
        this.adapter.adapt();

        // враппер-скроллер
        this.paperScroller = new joint.ui.PaperScroller({
            paper: this.paper,
            autoResizePaper: true,
            cursor: 'grab'
        });

        // присоединяем скроллер к дом-дереву
        $(this.viewerElement.nativeElement).append(this.paperScroller.render().el);
        // отрисуем центр скроллера
        this.paperScroller.render().center();

        // включаем прокрутку скроллера
        this.paper.on('blank:pointerdown', (evt, x, y) => {
            if (evt.shiftKey) {
                this.adapter.copyPasteController.startSelecting(evt);
            } else {
                this.adapter.copyPasteController.onStartPanning(x, y, this.paperScroller.getVisibleArea().center());
                this.paperScroller.startPanning(evt);
                this.paper.removeTools();
                this.nowEditedElement = null;
                this.nowEditedLink = null;
            }
        }, this);
        
        this.paperScroller.on('pan:stop', (evt) => {
            // если отпустили там же, где нажали, сбрасываем выделение
            this.adapter.copyPasteController.onStopPanning(this.paperScroller.getVisibleArea().center());
        })

        // настроить ховер и клик на элемент
        this.configElementTools(this.paper);

        // настроить ховер и клик на линк
        this.configLinkTools();

        // настроить мини-карту
        this.configNavigator();

        this.configControls();

        // стили "модЭрн"
        joint.setTheme("modern");

        let logChangesEnabled;
        if (this.script.autoLayoutApplied) {
            // Скрипт уже автоупорядочивался, показываем на весь экран
            this.zoomToFitScript(true);
            logChangesEnabled = false;
        } else {
            // Упорядочиваем
            logChangesEnabled = this.autoLayoutGraph(false, true);
            // Проставляем флаг
            this.script.autoLayoutApplied = true;
        }

        // регистрируем листенер ухода
        this.adapter.changesController.registerLeaveConfirm();

        // клавиатурный обработчик
        this.keyboardController = new ScriptKeyboardController(this, this.adapter);

        if (!logChangesEnabled) {
            // включаем отслеживание изменений, если еще не включено
            this.adapter.changesController.enableLog();
        }
    }

    /**
     * Установка начального обзора пейпера
     */
    zoomToFitScript(applyOnInit: boolean) {
        if (applyOnInit) {
            // ищем узел для центрирования, если задан его id
            const centerNodeId = this.stateService.params['nodeId'] || this.script.caseView?.currentNodeId;
            let centerElement: dia.Element = centerNodeId ? this.adapter.findNodeElement(centerNodeId) : null;

            if (centerElement) {
                // Скроллим в узел
                this.paperScroller.centerElement(centerElement);
                // Клик на него
                this.clickOnElement(this.paper.findViewByModel(centerElement), null);
                return;
            }
        }
        if (this.graph.getElements().length == 0) {
            // Скроллим в центр paper'a, если контента нет
            this.paperScroller.scrollToContent();

        } else if (this.graph.getElements().length == 1) {
            // Скроллим под элемент, если он один
            let box = this.graph.getElements()[0].getBBox();
            this.paperScroller.scroll(box.x + box.width / 2, box.y + this.paperScroller.getVisibleArea().height / 2.5);

        } else {
            // Зумим в контент
            this.zoomToFit();
        }
    }


    /**
     * Зум в контент
     *
     * Сделано из PaperScroller.zoomToFit()
     * Убран translate в конце Paper.scaleContentToFit, он дублирует PaperScroller.center() и вызвает смещение обзора при последующем перетаскивании элемента
     */
    zoomToFit() {
        // Находим прямоугольник, вмещающий все узлы
        let contentBox = this.graph.getElements().map(e => e.getBBox())
            .reduce((e1, e2) => e1.union(e2), this.graph.getElements()[0].getBBox());
        let contentBBox = this.paper.localToPaperRect(contentBox);

        // Вписывающий прямоугольник (wut)
        let $el = this.paperScroller.$el;
        let origin = this.paper.options.origin;
        let fittingBBox = new g.Rect(origin.x, origin.y, $el.width(), $el.height()).inflate(-20);

        // Считаем новый масштаб
        let newSx = fittingBBox.width / contentBBox.width * this.paper.scale().sx;
        let newSy = fittingBBox.height / contentBBox.height * this.paper.scale().sy;
        let newS = Math.min(1.2, Math.min(newSx, newSy));

        // Зумим и ставим центр
        this.paper.scale(newS, newS);
        this.paperScroller.center(contentBox.center().x, contentBox.center().y);
    }

    /**
     * Автовыстраивание графа
     * @return true, если отслеживание измененений включено внутри метода
     */
    public autoLayoutGraph(horizontal: boolean = false, applyOnInit: boolean = false): boolean {
        logString(`AUTO LAYOUT, horizontal: ${horizontal}, incline: ${this.script.incline}`);

        let logChangesEnabled: boolean;
        if (this.script.incline) {
            // лейаут для режима наклонных ребер
            this.hierarchicalLayout(horizontal);
            logChangesEnabled = false;
        } else {
            // лейаут для режима ортогональных ребер
            logChangesEnabled = this.treeLayout(horizontal, applyOnInit);
        }
        // зумимся в контент
        this.zoomToFitScript(applyOnInit);

        if (!applyOnInit) {
            // если это не выравнивание при загрузке, регистрируем клик
            const action: ClickAction = horizontal
                ? this.script.incline
                    ? 'layout_horizontal_incline'
                    : 'layout_horizontal_orthogonal'
                : this.script.incline
                    ? 'layout_vertical_incline'
                    : 'layout_vertical_orthogonal';
            this.clickStat(action);
        }

        return logChangesEnabled;
    }

    /**
     * Автолейаут алгоритмом для дерева
     * @return true, если отслеживание измененений включено внутри метода
     */
    private treeLayout(horizontal: boolean, isInit: boolean): boolean {
        this.adapter.changesController.initBatchChange();

        // собираем ребра, которые входят в узлы, в которые уже входят другие ребра, чтобы спрятать их перед выстраиванием
        let linksToHide: EruditeLink[] = [];
        // по каждому узлу берем входящие ребра от второго до последнего
        this.graph.getElements().forEach((element: EruditeElement) => linksToHide.push(...(element.incomingLinks.filter((link, index) => index > 0))));

        if (linksToHide.length) {
            // временно удаляем запутывающие автолейаут ребра
            if (!isInit) {
                this.adapter.changesController.disableLog();
            }
            this.graph.removeCells(linksToHide);
            if (!isInit) {
                this.adapter.changesController.enableLog();
            }
        }
        // лейаут
        new joint.layout.TreeLayout({
            graph: this.graph,
            parentGap: 160,
            siblingGap: 160,
            direction: horizontal ? 'R' : 'B'
        }).layout();

        if (linksToHide.length) {
            // возвращаем ребра
            if (!isInit) {
                this.adapter.changesController.disableLog();
            }
            this.graph.addCells(linksToHide);
            linksToHide.forEach((link: EruditeLink) => link.deleteVertices());
            if (!isInit) {
                this.adapter.changesController.enableLog();
            }
        }
        if (!linksToHide.length) {
            // линки не прятались, отмечаем конец изменений
            this.adapter.changesController.storeBatchChange();
            if (isInit) {
                this.adapter.changesController.enableLog();
            }
            return false;
        }
        // прятавшиеся линки перереисовываются с учетом новых местоположений узлов
        window.setTimeout(() => {
            linksToHide.forEach(link => link.redraw());
            if (isInit) {
                this.markUnchanged();
            }
            this.adapter.changesController.storeBatchChange();
            if (isInit) {
                this.adapter.changesController.enableLog();
            }
        }, 1);
        return true;
    }

    /**
     * При уходе со страницы убираем листенер ухода
     */
    ngOnDestroy() {
        this.dialog.closeAll();
        this.adapter.changesController.onDestroy();
        this.keyboardController.onDestroy();
    }

    /**
     * При обновлении страницы браузера с несохранёнными изменениями - выдать предупреждение
     * (кастомное сообщение не работает в Chrome)
     */
    @HostListener('window:beforeunload', ['$event'])
    public beforeunloadHandler($event) {
        this.adapter.changesController.confirmChanges($event);
    }

    /**
     * Увеличить расстояние между узлами
     */
    increaseSpacing(event: MouseEvent) {
        this.changeSpacing(true, event);
    }

    /**
     * Уменьшить расстояние между узлами
     */
    decreaseSpacing(event: MouseEvent) {
        this.changeSpacing(false, event);
    }

    /**
     * Пропорционально изменить расстояние между узлами
     */
    private changeSpacing(increase: boolean, event: MouseEvent) {
        // параметры изменения расстояния
        const {horizontalFactor, verticalFactor, action} = this.getChangeSpacingParams(increase, event);
        // центр старта/входа
        const center = this.adapter.rootElement.getBBox().center();

        // перемножаем смещения координат относительно центра на заданные коэффициенты
        this.adapter.changesController.initBatchChange();
        this.graph.getLinks().forEach((link: EruditeLink) => {
            link.offsetCoordinates(center, horizontalFactor, verticalFactor);
        });
        this.graph.getElements().forEach((element: EruditeElement) => {
            element.multiplyCoordinates(center, horizontalFactor, verticalFactor);
        });
        this.adapter.changesController.storeBatchChange();

        // зумимся в контент
        this.zoomToFit();
        // стата клика
        this.clickStat(action);
    }

    /**
     * Получить параметры изменения расстояний между узлами
     */
    private getChangeSpacingParams(increase: boolean, event: MouseEvent): ChangeSpacingParams {
        // пропорционально смещаем позиции относительно центра старта/входа
        const factor = increase ? this.SPACING_OFFSET_STEP : 1 / this.SPACING_OFFSET_STEP;

        if (event.altKey && !event.ctrlKey && !event.metaKey) {
            // если зажат альт - меняем только вертикально
            return {
                horizontalFactor: 1,
                verticalFactor: factor,
                action: increase ? 'spacing_increase_vertical' : 'spacing_decrease_vertical'
            };
        } else if ((event.ctrlKey || event.metaKey) && !event.altKey) {
            // если зажат контрол - меняем только горизонтально
            return {
                horizontalFactor: factor,
                verticalFactor: 1,
                action: increase ? 'spacing_increase_horizontal' : 'spacing_decrease_horizontal'
            };
        }
        // оба направления
        return {
            horizontalFactor: factor,
            verticalFactor: factor,
            action: increase ? 'spacing_increase' : 'spacing_decrease'
        };
    }

    /**
     * Переключить режим ортогональности
     */
    toggleIncline() {
        this.script.incline = !this.script.incline;
        this.adapter.changesController.initBatchChange();

        this.graph.getLinks().forEach((link: EruditeLink) => {
            link.toggleIncline(this.script.incline);
        });

        this.adapter.changesController.storeBatchChange();
        this.clickStat(this.script.incline ? 'incline_enable' : 'incline_disable');
    }

    /**
     * Лейаут с наклонными ребрами (иерархический)
     */
    private hierarchicalLayout(horizontal: boolean): void {
        // создаем mx-структуры данных для упорядочивания
        const graph: mxgraph.mxGraph = new mxGraph(null, new mxGraphModel());
        const defaultParent = graph.getDefaultParent();
        graph.getModel().beginUpdate();

        // конвертируем части графа скрипта в mx-формат, узлы
        const nodesById = new Map<string, mxgraph.mxCell>();
        this.graph.getElements().forEach((element: EruditeElement) => {
            const box = element.getBBox();
            const mxNode = graph.insertVertex(defaultParent, element.node.key.id, element.id, box.x, box.y, box.width, box.height);
            nodesById.set(mxNode.id, mxNode);
        });
        // конвертируем ребра
        const edgesById = new Map<string, mxgraph.mxCell>();
        this.graph.getLinks().forEach((link: EruditeLink) => {
            const fromNode = nodesById.get(link.scriptEdge.fromNodeId);
            const toNode = nodesById.get(link.scriptEdge.toNodeId);
            const mxEdge = graph.insertEdge(defaultParent, link.scriptEdge.key.id, link.id, fromNode, toNode);
            edgesById.set(mxEdge.id, mxEdge);
        });
        // направление упорядочивания
        const direction = horizontal ? mxConstants.DIRECTION_WEST : mxConstants.DIRECTION_NORTH;
        // коэффициент для умножения дефолтных расстояний, больше или меньше 1
        // в горизонталь надо больше пространства, так как тексты горизонтальные
        const directionCoeff = horizontal ? 2 : 1;

        // рассчитываем дефолтные дистанции
        const layout: mxgraph.mxHierarchicalLayout = new mxHierarchicalLayout(graph, direction, null);
        layout.interRankCellSpacing = layout.interRankCellSpacing * directionCoeff;
        // выстраиваем
        layout.execute(defaultParent);
        graph.getModel().endUpdate();

        // начинаем изменения
        this.adapter.changesController.initBatchChange();
        // переносим получившиеся координаты в раппидовый граф, ноды
        this.graph.getElements().forEach((element: EruditeElement) => {
            const mxCell = nodesById.get(element.node.key.id);
            element.position(mxCell.geometry.x, mxCell.geometry.y);
        });
        // точки на реберах
        this.graph.getLinks().forEach((link: EruditeLink) => {
            const mxEdge = edgesById.get(link.scriptEdge.key.id);
            const points = mxEdge.geometry.points;
            if (points && points.length > 0) {
                link.vertices(points.map(mxPoint => {
                    return {x: mxPoint.x, y: mxPoint.y};
                }));
            } else {
                link.deleteVertices();
            }
        });
        // закончили изменения
        this.adapter.changesController.storeBatchChange();
    }

    /**
     * Статистика клика
     * @param action действие
     * @param hotkey активация хотекеем?
     */
    clickStat(action: ClickAction, hotkey: boolean = false) {
        const details = {
            action: action,
            hotkey: hotkey,
        };
        this.statisticsService.registerOpenEvent(this.stateService.current, this.stateService.params, details);
    }

    /**
     * Сохранить скрипт
     */
    save() {
        this.saveChange.emit();
    }
}

/**
 * Используем mx для автолейаута
 */
const { mxGraph, mxGraphModel, mxHierarchicalLayout, mxConstants, mxHierarchicalEdgeStyle } = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: false,
});
