function KeyphraseNodeDirective() {
    return {
        restrict: "E",
        bindToController: true,
        scope: {
            node: "=",
            projectVersionId: "=",
            chosenKeyphrase: "=",
            dialogsWithChosenKeyphrase: "=",
            chartKeyphrase: "=",
            chartData: "=",
            result: "=",
            access: "="
        },
        templateUrl: "/pages/workplace/va/common/directives/keyphraseNode.html",
        controller: ["Restangular", "$scope", function (Restangular, $scope) {
            const that = this;
            that.$scope = $scope;
            that.node.showUnimportantChildren = false;
            that.displayDialogs = function (dialogs, phrase) {
                that.dialogsWithChosenKeyphrase = dialogs;
                that.chosenKeyphrase = phrase;
            };

            // Проверка на "неважных" детей
            that.anyUnImportantChildren = function (node) {
                return node.children.filter(child => !child.important).length > 0
            };

            /**
             * Может так получиться, что все дети конкретного родителя окажутся unimportant. Несмотря на это - отображаем
             * "плюсик" и при нажатии на него раскроем неважных детей, пусть по умолчанию этого и не делаем
             */
            that.toggle = function (node) {
                node.display = !node.display;
                let unImportantChildrenLength = node.children.filter(child => !child.important).length;
                if (unImportantChildrenLength === node.children.length) {
                    that.node.showUnimportantChildren = node.display;
                }
            };

            that.toggleUnimportant = function (node) {
                that.node.showUnimportantChildren = !that.node.showUnimportantChildren;
                let unImportantChildrenLength = node.children.filter(child => !child.important).length;
                if (!node.display) {
                    node.display = that.node.showUnimportantChildren;
                } else if (node.display && (unImportantChildrenLength === node.children.length)) {
                    node.display = !node.display;
                }
            };

            /**
             * Не будем строить график, если была накоплена только одна пара X,Y.
             * Это нужно проверить и на основе этого добавить (или не нет) нужный класс, чтобы span казался кликабельным
             */
            that.validateChartCreationAbility = function (keyphrase) {
                let storedKeyphrase = that.chartData.filter(item => item.text === keyphrase)[0];
                return storedKeyphrase.counts.length > 1;
            };

            /**
             * Чтобы избежать попыток построить график по одной точке, то перед назначением charKeyphrase провалидируем
             * количество точек
             */
            that.displayChart = function (keyphrase) {
                if (that.validateChartCreationAbility(keyphrase)) {
                    that.chartKeyphrase = keyphrase;
                }
            };


            that.changeImportance = function (node) {
                let important = !node.important;
                that.result.forEach(pageRes => that.findNodeByText(pageRes, node.keyphrase.text, important));
                Restangular.one("/account/expert/trenddetection").post("markAsImportant", {
                    phrase: node.keyphrase.text,
                    isImportant: that.node.important
                });
                that.$scope.$emit('change-importance-upwards', node);
            };

            that.findNodeByText = function (node, text, important) {
                if (!node.keyphrase && node.children) {
                    node.children.forEach(child => that.findNodeByText(child, text, important));
                    return;
                }

                if (node.keyphrase.text === text) {
                    node.important = important;
                }

                if (node.children) {
                    node.children.forEach(child => that.findNodeByText(child, text, important));
                }
            }
        }],
        controllerAs: "kCtrl",
    };
}

export default KeyphraseNodeDirective;