import {Injectable} from "@angular/core";
import * as urls from "../../../../../../js/workplace/urls";
import {HttpClient} from "@angular/common/http";
import {LevitanVendor, TtsSynthesisRequest, VaTtsSettings} from "../../../../data/va/Voice";


@Injectable()
export class TextToSpeechService {

    constructor(private httpClient: HttpClient) {

    }

    /**
     * Возвращает синтезированное аудио по тексту
     * @param text - текст для синтеза
     * @param readFile - true если надо вернуть blob; false - если вернуть url
     * @param settings - tts настройки
     */
    public async synthesize(text: string, readFile?: boolean, settings?: VaTtsSettings) {
        try {
            const body: TtsSynthesisRequest = {
                text: text,
                settings: settings
            }
            const response = await this.httpClient.post(`${urls.va.tts}/synthesize`, body,
                {
                    observe: 'response',
                    responseType: 'blob'
                }).toPromise();
            if (response.body.type == "text/plain") {
                return;
            }
            let blob = new Blob([response.body], {type: 'audio/ogg'});

            // Если нам нужны только данные, а не url, то вернем их
            if (readFile) {
                return blob;
            }
            return await this.readFile(blob);
        } catch (e) {
            return null;
        }
    }

    public readFile(blob: Blob) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result)
            };
        })
    }

    async getDefaultTtsSettings(): Promise<VaTtsSettings> {
        return await this.httpClient.get<VaTtsSettings>(`${urls.va.tts}/defaultTtsSettings`).toPromise();
    }

    async isTtsServerEnabled(): Promise<boolean> {
        return await this.httpClient.get<boolean>(`${urls.va.tts}/ttsServerEnabled`).toPromise();
    }

    async getVendors(isTts: boolean): Promise<LevitanVendor[]> {
        try {
            return await this.httpClient.get<LevitanVendor[]>(`${urls.va.tts}/vendors/${isTts}`).toPromise();
        } catch (error) {
            // если ошибка не "сервис не доступен" = бросить исключение
            if (error?.status != 418) {
                throw Error(JSON.stringify(error));
            }
            return null;
        }
    }

    async getVoices(vendor: string): Promise<string[]> {
        return await this.httpClient.get<string[]>(`${urls.va.tts}/voices/${vendor}`).toPromise();
    }

    async getEmotions(vendor: string): Promise<string[]> {
        return await this.httpClient.get<string[]>(`${urls.va.tts}/emotions/${vendor}`).toPromise();
    }
}