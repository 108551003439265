import {Injectable} from "@angular/core";
import {AppliedEntity, AppliedEntityType} from "./entity-usages.model";

@Injectable()
export class EntityUsageService {

    /**
     * Получить state для перехода
     * @param item использующая сущность
     */
    public getLink(item: AppliedEntity) {
        switch (item.type) {
            case AppliedEntityType.ANSWER:
                return 'robot.answer.edit';
            case AppliedEntityType.ATTRIBUTE:
                return 'robot.attributes.edit';
            case AppliedEntityType.SCENARIO:
                return 'robot.script_edit';
            case AppliedEntityType.PROCEDURE:
                return 'robot.script_procedure_edit';
            case AppliedEntityType.SIMPLE_MODULE_ANSWER:
                return 'robot.sma.edit';
            case AppliedEntityType.TAG:
                return 'robot.tags.edit';
            case AppliedEntityType.EXTRACTOR:
                return 'robot.extractors.edit';
            case AppliedEntityType.CUSTOMIZATION_SCRIPT:
                return 'robot.customization_script.edit';
            default:
                throw new Error(`Unsupported item type ${item.type}`)

        }
    }

    /**
     * Получить параметры перехода
     * @param entity сущность
     */
    public getParams(entity: AppliedEntity) {
        switch (entity.type) {
            case AppliedEntityType.ANSWER:
                return {answerId: entity.id};
            case AppliedEntityType.ATTRIBUTE:
                return {attrId: entity.id, mode: 'SHOW'};
            case AppliedEntityType.SCENARIO:
                return {scriptId: entity.id, type: 'scenario'};
            case AppliedEntityType.PROCEDURE:
                return {procedureId: entity.id, type: 'procedure'};
            case AppliedEntityType.SIMPLE_MODULE_ANSWER:
                return {smaId: entity.id};
            case AppliedEntityType.TAG:
                return {tagId: entity.id};
            case AppliedEntityType.EXTRACTOR:
                return {extractorId: entity.id, mode: 'SHOW'};
            case AppliedEntityType.CUSTOMIZATION_SCRIPT:
                return {scriptId: entity.id};
            default:
                throw new Error(`Unsupported item type ${entity.type}`)
        }
    }
}
