module.exports =
    {
        va: {
            access: "/account/expert/access/",
            account: "/account/expert/",
            acts: "/account/expert/ml/acts/",
            allowed_chars: "/account/expert/allowedchars",
            analysis: "/account/expert/analysis/",
            annotations: "/account/expert/annotation/",
            answers: "/account/expert/tagAnswer/",
            assessor_report: "account/expert/assessorWorkReport",
            attribute_scripts: "/account/expert/customizationscript",
            attributes: "/account/expert/attribute/",
            audio_records: "/account/expert/audio_record",
            chat: "/account/expert/chat",
            commands: "/account/expert/commands",
            correction: "/account/expert/correction",
            dashboard: "/account/expert/dashboard",
            dialog: "/account/expert/dialog",
            dkb: "/account/expert/dkb/",
            dkbField: "/account/expert/dkb/field/",
            dkbValue: "/account/expert/dkb/value/",
            entityUsages: "/account/expert/entityusage",
            extractor: "/account/expert/extractor",
            file: "/file",
            macro: "/account/expert/macro",
            ml: "/account/expert/ml/models/",
            ml_acts: "/account/expert/ml/acts/",
            msg: "/account/expert/msg/",
            operations: "/account/expert/operations",
            procedure: "/account/expert/procedure/",
            project: "/account/expert/project",
            projectVersion: "/account/expert/projectversion",
            prompter: "/account/expert/prompter",
            report: "/account/expert/report",
            script: "/account/expert/script/",
            sma: "/account/expert/sma",
            solutions: "/account/expert/tagAnswer/solutions",
            tag_message_distribution: "/account/expert/tag_messages_distribution",
            tagbuilding: "/account/expert/tagbuilding",
            tagbuilding_messages: "/account/expert/tagbuilding/messages",
            tagbuilding_sources: "/account/expert/tagbuilding/sources",
            tags: "/account/expert/tag/",
            textdata: "/account/expert/textdata",
            tokens: "/account/expert/token/",
            trend_detection: "/account/expert/trenddetection",
            tts: "/account/expert/tts",
            util: "/util",
            versions_of_project: "/account/expert/projectversion/versions_of_project",
            visitor: "/eruditevisitor/"
        }
    };