export default class PushStatusDetailController {

    constructor($rootScope, $scope, Restangular, $stateParams, TitleService) {
        var that = this;
        TitleService.setTitle();
        that.restAngular = Restangular;
        that.parentCtrl = $scope.$parent.ctrl;

        that.init = function() {

            that.restAngular.one("/account/push/getRecipients/", $stateParams['messageID']).get()
                .then(function success(data) {
                    that.data = data;
                    }, function error(data) {
                    }
                )
        };
        that.init();
    }

}

PushStatusDetailController.$inject = ["$rootScope", "$scope", "Restangular", "$stateParams", "TitleService"];

