/**
 * Created by semyon on 27.09.16.
 */
import "../../../less/shared/snackbar.less";

function SnackbarDirective() {
    return {
        scope: {
            snack: "<",
            onDismiss: "&"
        },
        bindToController: true,
        controller: function () {
            var that = this;

            that.hide = function () {
                that.onDismiss({snack: that.snack});
            }

        },
        templateUrl: "/pages/shared/elements/snackbar.html",
        controllerAs: "$ctrl"
    }

}

SnackbarDirective.$inject = [];

export default SnackbarDirective;
