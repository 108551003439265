import {AfterViewInit, Component, Input, ViewChild, ViewEncapsulation} from "@angular/core";
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from "@angular/material/table";
import {StateService} from "@uirouter/core";

@Component({
    selector: 'analysis-table',
    template: require('./analysis-table.component.html'),
    styles: [require('./analysis-table.component.less')],
    encapsulation: ViewEncapsulation.None
})

export class AnalysisTableComponent implements AfterViewInit {
    @Input()
    result: AnalysisTag[];
    totalResult: number;
    displayedColumns = ['text', 'processedByRobot', 'tagViewStats.dialogsCount', 'avgExpertRating'];

    dataSource = new MatTableDataSource<AnalysisTag>();

    @ViewChild(MatSort, {static: false}) sort: MatSort;

    constructor(protected stateService: StateService) {
    }

    ngAfterViewInit(): void {
        if (this.result != null) {
            this.totalResult = this.result.length;

            this.dataSource.data = this.result;

            this.dataSource.sortingDataAccessor = (item: any, property) => {
                if (property == 'tagViewStats.dialogsCount') {
                    return item.tagViewStats.dialogsCount;
                }
                if (property == 'text') {
                    return item.text;
                }
            };
            this.dataSource.sort = this.sort;
        }
    }

    /**
     * Переход к выборке диалогов по заданной тематике
     * @param tagId id выбранной тематики
     */
    goToDialogsByTag(tagId: number): void {
        this.stateService.go('robot.analysis.data', {
            filter: this.stateService.params['filter'],
            tagIdForSubtreeFiltering: null,
            singleTagIdForFiltering: tagId
        }, {notify: true});
    }
}

export interface AnalysisTag {
    text: string;
    processedByRobot: boolean;
    tagViewStats: {
        ratedDialogsCount: number;
        dialogsCount: number;
        avgExpertRating: number;
    }
    key: {
        id: string;
        projectVersionId: string;
    }
}