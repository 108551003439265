import {HttpClient} from "@angular/common/http";
import {StateService} from "@uirouter/core";
import {EventType, FilterResult} from "./journals.model";


export abstract class JournalsService<T extends FilterResult> {

    baseUrl: string = "/account/journal";

    protected constructor(protected httpClient: HttpClient, protected stateService: StateService) {
    }

    async getSections(): Promise<string[]> {
        return await this.httpClient.get<any>(`${this.baseUrl}/sections`).toPromise();
    }

    async getActionTypes(): Promise<EventType[]> {
        return await this.httpClient.get<any>(`${this.baseUrl}/actionTypes`).toPromise();
    }

    async getChanges(filter: any): Promise<T> {
        return await this.httpClient.post<T>(`${this.baseUrl}/filter`, filter).toPromise();
    }
}